<template>
  <div>
    <div class="ie11-alert information">
      <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'info-circle' }" size="2x" />
      We've noticed you are using Internet Explorer. We recommend using Trove in a
      <a href="/help/using-trove/browser-and-operating-system">different browser</a>
      for a better experience.
    </div>
    <div>
      <b-container class="outer-container p-0">
        <b-row class="full-width-row">
          <section class="search-section d-flex flex-row justify-content-center align-items-center">
            <div class="search-container">
              <div class="headline">{{ headline }}</div>
              <BasicSearch
                :collections="collections"
                :displayCollections="true"
                :displayAdvancedSearch="true"
                @collection-changed="collectionChanged"
                @search="search" />
            </div>
          </section>

          <section class="partners-section d-flex justify-content-center align-items-center">
            <div class="partners-container">
              <b-img
                class="nla"
                :src="footerLogoLeft"
                :alt="footerLogoLeftAlt"
                v-if="footerLogoLeft"></b-img>
              <div class="text">
                {{ footerText }}
                <div>
                  <a
                    v-if="footerTextCallToActionText && footerTextCallToActionLink"
                    :href="footerTextCallToActionLink"
                    >{{ footerTextCallToActionText }}</a
                  >
                </div>
              </div>
              <b-img
                class="partners"
                :src="footerLogoRight"
                :alt="footerLogoRightAlt"
                v-if="footerLogoRight"></b-img>
            </div>
          </section>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ie11autofix {
  -ms-flex: 1 0 auto;
}

.ie11-alert {
  display: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .ie11-alert {
    display: block;
    border-radius: 3px;
    padding: 0.75rem 1.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &.information {
      background-color: colour(yellow);
      color: black;

      a {
        color: black;
        font-weight: 600;
        margin-left: 0;
      }

      svg {
        vertical-align: middle;
        margin-right: 0.5rem;
      }
    }

    .content {
      flex: 1;
    }
  }
}

.outer-container {
  .full-width-row {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;

    background-color: colour(purple);
    max-height: 288px;

    @media (max-width: 575px) {
      max-height: 353px;
    }
  }
  .search-section {
    width: 100%;
    border-bottom: 2px solid colour(yellow);
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    .search-container {
      max-width: 854px;
      width: 100%;
      padding: 1rem 0;

      .headline {
        margin-bottom: 1rem;
        text-align: center;
        font-family: 'Source Serif Pro', serif;
        font-size: 2.25rem;
        font-weight: 600;
        color: white;

        @media (max-width: 575px) {
          font-size: 1.625rem;
        }
      }

      @supports (font-variation-settings: normal) {
        .headline {
          font-family: 'Source Serif Variable', serif;
        }
      }
    }
  }

  .partners-section {
    text-align: center;
    background-color: colour(grey);
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 100px;

    @media (max-width: 767px) {
      height: 130px;
    }

    @media (max-width: 575px) {
      height: 165px;
    }

    .partners-container {
      max-width: 854px;
      display: flex;
      align-items: center;

      @media (max-width: 575px) {
        height: 160px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
          'text text'
          'nla partners';
        justify-items: center;
        align-items: initial;
        padding-bottom: 1rem;
        padding-top: 1rem;
      }
    }

    .text {
      padding-left: 2rem;
      padding-right: 2rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1rem;
      text-align: center;

      a {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        width: fit-content;
        &:hover,
        &:active {
          color: colour(blue);
        }
      }

      @supports (font-variation-settings: normal) {
        a {
          font-family: 'Source Sans Variable', sans-serif;
        }
      }

      @media (max-width: 575px) {
        font-size: 1rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0.5rem;
        grid-area: text;
      }
    }

    @supports (font-variation-settings: normal) {
      .text {
        font-family: 'Source Sans Variable', sans-serif;
      }
    }

    img.nla,
    img.partners {
      height: 2rem;
    }

    img.partners {
      grid-area: partners;
      justify-self: right;
    }

    img.nla {
      grid-area: nla;
      justify-self: left;
    }
  }
}
</style>

<script>
import utils from '@/mixins/utils'
import colours from '@/mixins/colours'
import BasicSearch from '@/components/search/BasicSearch.vue'

export default {
  name: 'SearchKeyhole',
  components: {
    BasicSearch,
  },
  mixins: [utils, colours],
  props: {
    collections: {
      type: Array,
      required: false,
    },
    headline: {
      type: String,
      required: false,
      default: 'Search. Discover. Australia.',
    },
    footerText: {
      type: String,
      required: false,
    },
    footerTextCallToActionText: {
      type: String,
      required: false,
    },
    footerTextCallToActionLink: {
      type: String,
      required: false,
    },
    footerLogoLeft: {
      type: String,
      required: false,
    },
    footerLogoLeftAlt: {
      type: String,
      required: false,
    },
    footerLogoRight: {
      type: String,
      required: false,
    },
    footerLogoRightAlt: {
      type: String,
      required: false,
    },
    keyholeImage: {
      type: String,
      required: false,
    },
    keyholeImageAlt: {
      type: String,
      required: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    collectionChanged(selectedCollection) {
      this.$emit('collection-changed', selectedCollection)
    },
    search(query) {
      this.$emit('search', query)
    },
  },
}
</script>
