import { mapGetters, mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations('trove', {
      addToRouteHistory: 'ADD_TO_ROUTE_HISTORY',
      removeRouteHistoryHead: 'REMOVE_ROUTE_HISTORY_HEAD',
    }),
  },
  computed: {
    ...mapGetters('trove', [
      'findCollectionBySearchRoute',
      'latestBreadCrumbRoute',
      'latestRoute',
      'latestSearchRoute',
      'routeHistory',
    ]),
  },
}
