<template>
  <div>
    <a v-if="isActive('href')" :href="link">
      <slot></slot>
    </a>
    <router-link v-if="isActive('routerLink')" :to="link">
      <slot></slot>
    </router-link>
    <template v-if="noneActive">
      <slot></slot>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'

var typeMapping = [
  {
    predicate: (link) => _.isString(link),
    type: 'href',
  },
  {
    predicate: (link) => link.name,
    type: 'routerLink',
  },
]

export default {
  name: 'MultiLink',
  props: {
    link: {
      required: true,
    },
  },
  methods: {
    isActive(type) {
      return type === this.activeType
    },
  },
  computed: {
    noneActive() {
      return !this.activeType
    },
    activeType() {
      var active = _.find(typeMapping, (mapping) => mapping.predicate(this.link))
      return active ? active.type : null
    },
  },
}
</script>
