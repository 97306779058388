<template>
  <div>
    <b-modal
      id="culturalModal"
      size="lg"
      centered
      @hide="dismiss"
      :visible="displayModal">
      <div slot="modal-header" class="px-4 w-100">
        <h1 class="bevel heading float-left">{{ culturalAdvice.title }}</h1>
        <div class="close-button">
          <b-button variant="link" @click="hideCulturalWarnings" aria-label="Close">
            <span><font-awesome-icon :icon="{ prefix: 'fal', iconName: 'times' }"/></span>
          </b-button>
        </div>
      </div>
      <b-container fluid>
        <b-row class="px-4 summary">
          <div class="col-4 col-md-2">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 200 200"
              width="100%">
              <title>Message stick</title>
              <!-- eslint-disable max-len -->
              <g id="Message-stick" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path
                  d="M17.8947368,138.947368 L63.1578947,178.947368 C60,181.052632 57.8027915,182.999003 55.7894737,184.210526 C42.6929687,192.091406 37.8947368,194.736842 27.3684211,196.842105 C12.6315789,200 2.10526316,191.578947 3.15789474,175.789474 C3.15789474,172.631579 4.16789262,169.45988 5.26315789,166.315789 C7.65542763,159.448492 11.8659539,150.325685 17.8947368,138.947368 Z"
                  id="Path"
                  fill="#000000"
                  fill-rule="nonzero"></path>
                <path
                  d="M133.852539,27.6054688 C133.852539,27.6054688 140.113965,32.561248 144.210526,36.1386719 C146.506348,38.1435547 148.262721,47.2463551 143,50.6855469 C132.999023,57.2211914 131.179232,58.9665908 128.159668,62.7211914 C122.299546,70.0078125 121.86855,74.5121009 120.609375,82.7875977 C119.3502,91.0630944 113.289474,90.7976345 105.977322,90.7976345 C98.6651704,90.7976345 96.7781953,90.3807599 91.5646701,91.6382378 C86.2037737,92.931261 81.6453401,97.1021858 82.4675746,104.646105 C83.3680017,112.907433 85.5730064,118.508721 83.553127,122.890649 C81.5119553,127.318768 77.0512695,130.375488 66.1762695,132.155762 C55.3012695,133.936035 51.2236328,134.949707 44.2105263,142.105263 C38.5800781,137.014893 33.8232422,132.98877 27.3684211,127.368421 C42.5214844,109.26416 61.0171218,89.9752578 77.3466797,74.7143555 C95.8354492,57.4355469 100,51.6860352 133.852539,27.6054688 Z M62.5,110 C59.4624339,110 57,112.462434 57,115.5 C57,118.537566 59.4624339,121 62.5,121 C65.5375661,121 68,118.537566 68,115.5 C68,112.462434 65.5375661,110 62.5,110 Z M104.5,67 C101.462434,67 99,69.4624339 99,72.5 C99,75.5375661 101.462434,78 104.5,78 C107.537566,78 110,75.5375661 110,72.5 C110,69.4624339 107.537566,67 104.5,67 Z"
                  id="Combined-Shape"
                  fill="#000000"
                  fill-rule="nonzero"></path>
                <path
                  d="M156.432129,46.362793 C168.078613,56.2830404 173.901855,61.2431641 173.901855,61.2431641 C154.467285,91.8666992 146.04218,99.2253266 126.534505,121 C107.02683,142.774673 90.3717448,158.046224 75.7894737,169.473684 C68.4210526,163.157895 61.0526316,156.842105 53.6842105,150.526316 C52.6315789,149.473684 52.6315789,147.368421 53.6842105,146.315789 L54.2341017,145.767283 C56.1635658,143.852353 58.1693364,142.01373 61.0526316,141.052632 C66.3157895,138.947368 72.1010058,138.125507 77.5634766,136.635742 C89.1424239,133.477847 95.7894737,123.157895 92.6315789,110.526316 C92.376311,109.505244 91.4312066,105.931241 91.4312066,104.440972 C91.4312066,100.230446 93.6842105,98.9473684 100,98.9473684 C105.263158,98.9473684 111.063345,99.4263152 116.205566,98.2924805 C123.838867,96.609375 127.242187,93.2211914 129.893012,86.9226345 C131.183109,83.8572682 131.494128,79.9228312 132.107964,75.6259766 C133.160596,68.2575555 136.428602,65.2729634 144.210526,60 C152.358261,54.4791667 156.432129,49.933431 156.432129,46.362793 Z M108.5,107 C105.462434,107 103,109.462434 103,112.5 C103,115.537566 105.462434,118 108.5,118 C111.537566,118 114,115.537566 114,112.5 C114,109.462434 111.537566,107 108.5,107 Z M149.5,67 C146.462434,67 144,69.4624339 144,72.5 C144,75.5375661 146.462434,78 149.5,78 C152.537566,78 155,75.5375661 155,72.5 C155,69.4624339 152.537566,67 149.5,67 Z"
                  id="Combined-Shape"
                  fill="#000000"
                  fill-rule="nonzero"></path>
                <path
                  d="M144.210526,20 C156.842105,12.6315789 167.368421,5.26315789 180,2.10526316 C190.526316,1.75415238e-14 198.11562,3.80376234 197.062988,14.3300781 C194.957725,26.9616571 188.139905,39.6978053 180.771484,50.2241211 L144.210526,20 Z"
                  id="Path"
                  fill="#000000"
                  fill-rule="nonzero"></path>
              </g>
              <!-- eslint-enable max-len -->
            </svg>
          </div>
          <div class="col-8 col-md-10">
            {{ culturalAdvice.summary }}
          </div>
        </b-row>
        <b-row class="px-4 body" v-if="showBody && culturalAdvice.body">
          {{ culturalAdvice.body }}

          <div class="instructions">
            By selecting "{{ culturalAdvice.showAdvice }}", please be advised that you will continue
            to receive subsequent cultural advice notices before viewing materials on Trove that may
            be considered culturally sensitive. You can opt out at any time.
          </div>
        </b-row>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-row class="p-4">
          <b-col>
            <b-btn
              class="dismissButton inverse left"
              @click="displayCulturalWarnings"
              v-if="showBody">
              <span>
                {{ culturalAdvice.showAdvice }}
              </span>
            </b-btn>
            <b-btn class="dismissButton left" @click="toggleShowBody" v-else>
              <span> Find out more... </span>
            </b-btn>
          </b-col>
          <b-col>
            <b-btn class="dismissButton right" @click="hideCulturalWarnings">
              <span>
                {{ culturalAdvice.hideAdvice }}
              </span>
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.body {
  padding-top: 0.5rem;
}

.show-more {
  padding-left: 3rem;
}

.heading {
  font-size: 1.375rem !important;

  &:before {
    top: 0.14em !important;
  }
}

.instructions {
  margin-top: 1.5rem;
  padding-top: 1em;
  font-size: 0.9rem;
  border-top: 1px solid colour(grey-6);
}

.dismissButton {
  width: 100%;
  white-space: normal;
  color: colour(grey-5);
  background-color: colour(purple);
  height: 80px;
  font-size: 16px;
  font-weight: 600;
}

.close-button {
  margin-bottom: 1rem;
  text-shadow: none;
  text-align: right;
  float: right;
  button {
    color: colour(purple);
    padding-right: 0;
    padding-top: 0;
    height: 2.5em;
    min-width: 6em;
    &:hover,
    &:focus,
    &:active {
      color: colour(magenta);
      text-decoration: underline;
    }
    svg {
      font-size: 22px !important;
      margin-left: 5px;
      position: relative;
      top: 2px;
    }
  }
}

</style>

<script>
import cultural from '@/mixins/cultural'
import service from '@/service/service'

export default {
  name: 'CulturalModal',
  mixins: [cultural],
  data() {
    return {
      culturalAdvice: {
        body: '',
        summary: '',
        title: '',
        hideAdvice: '',
        showAdvice: '',
      },
      showBody: false,
    }
  },
  created() {
    let self = this

    service.config.getCulturalAdvice().then((ca) => {
      self.culturalAdvice.title = ca.title
      self.culturalAdvice.summary = ca.summary
      self.culturalAdvice.body = ca.body
      self.culturalAdvice.hideAdvice = ca.action
      self.culturalAdvice.showAdvice = ca.action1
    })
  },
  computed: {
    displayModal() {
      return !this.primaryWarningDisplayed
    },
  },
  methods: {
    displayCulturalWarnings() {
      this.dismissPrimaryWarning({ show: true, vm: this })
    },
    hideCulturalWarnings() {
      this.dismissPrimaryWarning({ show: false, vm: this })
    },
    dismiss(event) {
      if (event.trigger === 'esc' || event.trigger === 'backdrop') {
        this.dismissPrimaryWarning({ show: false, vm: this })
      }
    },
    toggleShowBody() {
      this.showBody = !this.showBody
    },
  },
}
</script>
