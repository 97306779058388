import _ from 'lodash'
import navigation from '@/mixins/navigation'

const PAGE_QUERY_PARAM = 'page'
const PAGE_CHANGED_EVENT_NAME = 'page-changed'

export default {
  mixins: [navigation],
  created() {
    this.addQueryWatcher(PAGE_QUERY_PARAM, this.paginationChanged)
  },
  computed: {
    currentPage() {
      let page = this.currentPageRaw
      let maxPage = _.ceil(this.pageRows / this.pageSize)

      if (page >= 1 && page <= maxPage) {
        return page
      }

      if (page > maxPage) {
        return maxPage
      }

      return 1
    },
    currentPageRaw() {
      if (
        this.currentRoute &&
        this.currentRoute.query &&
        _.isNumber(parseInt(this.currentRoute.query[PAGE_QUERY_PARAM]))
      ) {
        return parseInt(this.currentRoute.query[PAGE_QUERY_PARAM])
      }

      return 1
    },
  },
  methods: {
    paginationChanged() {
      this.$emit(PAGE_CHANGED_EVENT_NAME)
    },
    paginationLinkGenerator(newPage) {
      let link = this.generateCurrentPageLink()

      if (newPage === 1) {
        delete link.query[PAGE_QUERY_PARAM]
      } else {
        link.query[PAGE_QUERY_PARAM] = newPage
      }

      return link
    },
  },
}
