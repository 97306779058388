<template>
  <b-container class="root-container" id="workContainer" ref="workContent">
    <b-row class="section-container">
      <b-col class="basic-search">
        <BasicSearch
          :collections="collections"
          :display-collections="true"
          :displayAdvancedSearch="true"
          @collection-changed="selectedCollectionUpdated"
          @search="search" />
      </b-col>
    </b-row>

    <Stubbable :stubbed="loadingWork">
      <template v-slot:content>
        <div class="error" v-if="error && error.summary">
          <Alert :alert="error" :autohide="false"></Alert>

          <div class="delete-container" v-if="deleteDetails && deleteDetails.record && isDataManager">
            <b-button variant="link"
                      class="show-deleted-record-link"
                      @click="deleteCollapsed = !deleteCollapsed">
              {{ deleteCollapsed ? 'Show ' : 'Hide ' }} record
            </b-button>
            <div class="delete-details" :class="{'expanded': !deleteCollapsed}">
              <Field title="Deleted on"
                  :content="deleteDetails.deletedOn"
                  grid-layout/>
              <Field class="deleted-record"
                  title="Deleted record"
                  :content="deleteDetails.record"
                  code="xml"
                  grid-layout/>
            </div>
          </div>
        </div>

        <div class="error" v-if="splitPending">
          <Alert
            :alert="{
              summary:
                // eslint-disable-next-line max-len
                'Separation Pending. One or more editions will be removed from this work soon.  This could take up to a few minutes.',
              impact: 'info',
            }"></Alert>
        </div>

        <div class="error" v-if="mergePending">
          <Alert
            :alert="{
              summary:
                // eslint-disable-next-line max-len
                'Merge Pending. One or more editions will be added to this work soon.  This could take up to a few minutes.',
              impact: 'info',
            }"></Alert>
        </div>

        <template v-if="!error || !error.summary || error.showContent">
          <CulturalSensitivityWarning
            v-if="work.culturallySensitive"
            :isThumbnail="isThumbnail"
            :identifier="work.id" />
          <CulturalModal v-else />

          <template v-if="loadingVersion">
            <div class="work-loading">
              <div class="loading-spinner spinner-lg"></div>
              Loading - please wait...
            </div>
          </template>

          <b-row class="section-container">
            <b-col>
              <h1 ref="workOrVersionTitle" v-html="title"></h1>
              <div class="versions-subtitle" v-if="showVersions && !versionId">
                {{ allVersions.length }} Editions under this title
              </div>
              <span class="format" v-if="version"
                ><font-awesome-icon v-if="formatIcon" :icon="formatIcon" />{{ format }}</span
              >
              <template v-if="yearRange">
                - <span class="yearRange">{{ yearRange }}</span></template
              >
              <FirstAustraliansIndicator
                v-if="
                  (version && version.firstAustraliansInd) || work.firstAustraliansInd
                "></FirstAustraliansIndicator>
            </b-col>
          </b-row>

          <b-row v-if="version" class="split-container">
            <b-col>
              <b-button variant="primary" v-if="canSplit && !splitMergePending" v-b-modal.splitModal
                >Separate this version</b-button
              >
              <b-button variant="primary" v-if="isDataManager" @click="metadataRedirect"
                >View Raw Metadata</b-button
              >
            </b-col>
          </b-row>
          
          <b-row v-else-if="isDataManager" class="split-container">
            <b-col>
              <b-button variant="primary" @click="metadataRedirect"
                >View Raw Metadata</b-button
              >
            </b-col>
          </b-row>

          <b-row class="image-container">
            <b-col>
              <template v-if="!isPicture && showVersions">
                <PerspectiveShelf
                  v-if="allVersions && !version"
                  :items="versionCovers"
                  :key="work.id + '_' + versionCovers.length" />
                <PerspectiveShelf
                  v-if="version"
                  :items="[
                    {
                      id: version.id,
                      img: version.thumbnailUrl,
                      title: version.title,
                      blur: blurWork,
                    },
                  ]"
                  :key="version.id" />
              </template>
              <template v-else-if="!isPicture">
                <PerspectiveShelf
                  :items="[
                    {
                      id: work.id,
                      img: work.thumbnailUrl,
                      title: work.title,
                      blur: blurWork,
                    },
                  ]"
                  :key="work.id" />
              </template>
              <template v-else-if="isPicture">
                <Thumbnail
                  :url="work.thumbnailUrl"
                  class="image"
                  :title="'Thumbnail for ' + work.title"
                  type="image"
                  size="large"
                  :blur="blurWork"
                  @load-failed="showThumbnail = false"
                  @load-succeeded="showThumbnail = true">
                </Thumbnail>
              </template>
            </b-col>
          </b-row>

          <b-row class="section-container">
            <b-col>
              <WorkActions
                v-if="work && work.id"
                :key="versionId"
                ref="workActions"
                :allow-get="true"
                :allow-buy="canBuy"
                :allow-borrow="true"
                :format="format || (version ? version.format : work.format)"
                :available-urls="availableUrls"
                :subscribing-libraries="subscribingLibraries"
                :related-material-urls="relatedMaterialUrls"
                :thumbnail-url="versionId && version ? version.thumbnailUrl : work.thumbnailUrl"
                :borrow-option-count="
                  (versionId && version ? version.borrowOptionCount : work.borrowOptionCount) || 0
                "
                :google-books-preview="googleBooksPreview"
                :copies-direct-params="copiesDirectParams"
                :other-suppliers="otherSuppliers"
                :work-id="work.id"
                :work-title="title"
                :version-id="versionId"
                :bookshop-ids="bookshopIds"
                :work-categories="work.categories"
                :work-type="work.workType"
                :digital-only="work.digitalOnly || (version && version.digitalOnly)"
                :citation-version-id="citationId"
                @available-actions-updated="availableActionsUpdated" />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="9">
              <div class="d-flex flex-column content-container">
                <div class="summary-header">
                  <div
                    class="authors-panel"
                    :class="{ expanded: !creatorsLimited }"
                    v-if="creators && creators.length > 0">
                    <div class="close">
                      <b-button
                        variant="link"
                        @click="toggleShowAllCreators"
                        v-if="!creatorsLimited"
                        >Close
                        <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'times' }" />
                      </b-button>
                    </div>
                    <font-awesome-icon
                      :icon="{ prefix: 'fas', iconName: 'user' }"
                      class="author-icon" />
                    <ul class="authors first list-inline">
                      <li v-for="creator in creators" :key="creator" class="list-inline-item">
                        <PossibleLink :linkResolver="authorRoute()" :label="creator">
                          <span class="author">{{ creator }}</span>
                        </PossibleLink>
                      </li>
                      <li
                        v-if="creatorsLimited && creatorCount - creatorDisplayLimit > 0"
                        class="list-inline-item">
                        <b-button
                          pill
                          size="sm"
                          variant="primary"
                          @click="toggleShowAllCreators"
                          class="show-authors-link">
                          + {{ creatorCount - creatorDisplayLimit }}
                        </b-button>
                      </li>
                    </ul>
                  </div>

                  <span class="contributor" v-if="showContributor"
                    ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'location-arrow' }" />{{
                      contributorText
                    }}</span
                  >

                  <span class="contributor" v-if="showCorrectableText"
                    ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit' }" />Item can be
                    text corrected</span
                  >

                  <CopyrightStatus
                    v-if="bibliographicRecordId"
                    :bibliographicRecordId="bibliographicRecordId"
                    :can-borrow="availableActions.canBorrow"
                    :can-buy="availableActions.canBuy"
                    @go-to-borrow="openWorkAction('borrow')"
                    @go-to-buy="openWorkAction('buy')"></CopyrightStatus>
                </div>

                <div v-if="summary">
                  <Collapse class="summaryCollapse" :collapsed="!showSummary" minHeight="15em">
                    <Field
                      :class="'summary-container'"
                      title="Summary"
                      :content="summary"
                      :try-href="true"
                      grid-layout></Field>
                  </Collapse>

                  <template v-if="showCollapse">
                    <div
                      :class="!showSummary ? 'summaryCollapseLink' : 'summaryExpandLink'"
                      class="read-more-action">
                      <b-link>
                        <div @click="showSummary = !showSummary">
                          <span v-if="showSummary"
                            >Collapse summary
                            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-up' }"
                          /></span>
                          <span v-if="!showSummary"
                            >Read full summary
                            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }"
                          /></span>
                        </div>
                      </b-link>
                    </div>
                  </template>
                </div>

                <div v-if="!version" class="more-details">
                  <Field
                    class="details-container"
                    v-for="field in workFields"
                    :key="field.key"
                    :title="field.title"
                    :content="field.content"
                    :routeResolver="field.routeResolver"
                    :try-href="field.tryhref"
                    :code="field.code"
                    grid-layout></Field>
                </div>

                <div v-if="version" class="more-details">
                  <Collapse
                    class="workDetailsCollapse"
                    :collapsed="!showDetails"
                    minHeight="30em"
                    @fully-visible="detailsCollapseFullyVisibleChanged">
                    <Field
                      class="details-container"
                      v-for="field in versionFields"
                      :key="field.key"
                      :title="field.title"
                      :content="field.content"
                      :routeResolver="field.routeResolver"
                      :try-href="field.tryhref"
                      :show-html="field.showHtml"
                      :code="field.code"
                      grid-layout></Field>
                    <br />
                  </Collapse>

                  <div
                    v-if="showDetailsCollapse"
                    :class="!showDetails ? 'collapseLink' : 'expandLink'"
                    class="read-more-action">
                    <b-link>
                      <div @click="showDetails = !showDetails">
                        <span v-if="showDetails"
                          >Read less
                          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-up' }"
                        /></span>
                        <span v-if="!showDetails"
                          >Read more
                          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }"
                        /></span>
                      </div>
                    </b-link>
                  </div>
                </div>

                <Tags :work="version || work" />

                <ContainingLists
                  type="work"
                  :target-id="work.id"
                  :format="format"></ContainingLists>

                <AnnotationList
                  :type="version ? 'version' : 'work'"
                  :target-id="version ? version.id : work.id"
                  heading="Work notes"></AnnotationList>

                <Versions
                  v-if="showVersions"
                  :key="versionId"
                  :all-versions="allVersions"
                  :parent-work="work"
                  :selected-version-id="versionId"
                  :date-range="versionDateRange"
                  :includeraw="includeraw" />
              </div>
            </b-col>
            <b-col md="3" class="aside-container">
              <CulturalSensitivityFeedback />
            </b-col>
          </b-row>
        </template>
      </template>
      <template v-slot:stub>
        <div class="stub">
          <div class="work-stub">
            <div class="button pill title"></div>
            <div class="button pill editions"></div>
            <div class="button pill thumbnail"></div>
            <div class="actions">
              <div class="button pill action"></div>
              <div class="button pill action"></div>
              <div class="button pill action"></div>
            </div>
            <div class="button pill summary-title"></div>
            <div class="button pill summary"></div>
          </div>
        </div>
      </template>
    </Stubbable>

    <b-modal
      id="splitModal"
      v-if="version"
      @ok="splitVersionFromWork"
      @close="cancelSplit"
      @cancel="cancelSplit">
      <template v-slot:modal-title>Separate this edition from this work?</template>

      <h4>Are you certain?</h4>
      <p>Are you sure you want to separate this edition from this work?</p>

      <div class="results">
        <div class="result">
          <div v-if="version.title" class="d-flex">
            <div class="fieldName">Title:</div>
            <div class="flex-grow-1">
              <b>{{ version.title }}</b>
            </div>
          </div>
          <div v-if="version.creators" class="d-flex">
            <div class="fieldName">Author:</div>
            <div class="flex-grow-1">
              <b>{{ version.creators.join(', ') }}</b>
            </div>
          </div>
          <div v-if="version.published" class="d-flex">
            <div class="fieldName">Published:</div>
            <div class="flex-grow-1">
              <b>{{ versionPublished }}</b>
            </div>
          </div>
        </div>
      </div>

      <p>
        You should only proceed if you are <b>COMPLETELY CERTAIN</b> that this edition does not
        belong in this work, and has been mis-catalogued or incorrectly grouped.
      </p>

      <p>
        For help and more information,
        <a
          href="http://help.nla.gov.au/trove/using-trove/creating-contributing/splitting-merging"
          target="_new"
          >view our guidelines <i>(opens new window)</i></a
        >
        for separating editions from a work.
      </p>

      <p>
        Write a note here if you think it will help others understand why you've decided to separate
        this edition from this work:
      </p>
      <p>
        <b-form-textarea v-model="modalText" rows="4" :maxlength="200"></b-form-textarea>
      </p>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="link" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="ok"
          >I am certain! This edition doesn't belong here!</b-button
        >
      </template>
    </b-modal>
  </b-container>
</template>

<style lang="scss">
.basic-search .advanced-search {
  div {
    select,
    .down {
      color: colour(purple);
    }
  }
}
</style>

<style scoped lang="scss">
.root-container {
  margin-bottom: 3rem;

  .error {
    margin-top: 2rem;
  }

  .work-loading {
    padding-top: 3rem;
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .delete-container {
    .show-deleted-record-link {
      margin-left: -0.75rem;
      margin-top: -0.75rem;
    }

    .delete-details {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease;

      &.expanded {
        max-height: 1000px;
      }
    }
  }

  h1,
  h2 {
    font-family: 'Source Serif Pro', serif;
    font-weight: 700;
  }

  @supports (font-variation-settings: normal) {
    h1,
    h2 {
      font-family: 'Source Serif Variable', serif;
    }
  }

  h1.bevel {
    font-family: inherit;
    font-size: 1.375rem;
  }

  .singleThumbnail {
    max-height: 15rem;
    border: 1px solid blue;
  }

  .more-details {
    border-bottom: 1px solid black;
    padding-bottom: 1rem;
  }

  .format {
    font-weight: 600;
    font-size: 1.375rem;
    color: colour(grey-2);

    svg {
      margin-right: 0.5em;
    }
  }

  .yearRange {
    color: colour(grey-2);
    font-size: 1.375rem;
  }

  .authors-panel {
    .close button {
      color: colour(purple);
      padding-right: 0;
      padding-top: 0;
      height: 2.5em;
      min-height: 2.5em;
      min-width: 6em;

      &:hover,
      &:active {
        color: #c0286f;
        text-decoration: underline;
      }

      svg {
        font-size: 22px !important;
        margin-left: 5px;
        position: relative;
        top: 2px;
      }
    }

    &.expanded {
      background-color: colour(grey);
      padding: 0.5rem;
      margin-top: -40px;

      .author-icon {
        vertical-align: top;
        margin-top: 2px;
        float: left;
      }

      .authors {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 15px;

        @media (max-width: 991px) {
          grid-template-columns: repeat(3, 1fr);

          @media (max-width: 475px) {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        .list-inline-item {
          word-wrap: break-word;
          word-break: break-word;
        }
      }
    }

    .authors {
      display: inline-block;
      max-width: 95%;

      .list-inline-item {
        padding-bottom: 0.5rem;

        a {
          text-decoration: underline;
        }
      }
    }

    .author-icon {
      margin-right: 1rem;
      display: inline-block;
      color: colour(blue);
      font-weight: 600;
    }

    .author {
      color: colour(blue);
      margin-right: 1rem;
      font-weight: 600;

      svg {
        margin-right: 0.2em;
      }
    }

    .show-authors-link {
      font-weight: bold;
      min-width: 0;
      font-size: 0.75rem;
    }
  }

  .contributor {
    color: colour(violet);
    display: block;
    margin-right: 2em;
    margin-top: 0.5em;
    float: left;

    svg {
      margin-right: 0.5em;
    }
  }

  .versions-subtitle {
    color: colour(violet);
    font-weight: 600;
    font-size: 1.375rem;
  }

  .content-type {
    display: block;
  }

  .get-links {
    div {
      position: relative;
      width: 100%;

      div::after {
        @include bevel-bar(100%, $primary-button-height, 0em, 0em, colour(grey));
      }
    }
  }

  .collapseLink {
    position: relative;
    bottom: 3rem;
    margin-bottom: -3rem;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    );
    width: 100%;
    height: 5em;
    pointer-events: none;

    svg {
      margin-left: 0.3em;
    }

    button,
    a {
      padding-left: 0;
      position: absolute;
      bottom: 0;
      pointer-events: auto;
    }
  }

  .expandLink {
    svg {
      margin-left: 0.3em;
    }
  }

  .stub {
    .title {
      margin-top: 2rem;
      width: 80%;
    }

    .thumbnail {
      height: 200px;
      width: 133.333px;
    }

    .actions {
      width: 60%;
      margin-top: 1rem;

      .action {
        min-width: 9em;
        height: 3em;
        margin-right: 1em;
        display: inline-block;
      }
    }

    .editions {
      width: 30%;
      height: 1.5rem;
      margin-bottom: 2rem;
    }

    .summary {
      width: 80%;
      margin-top: 2rem;
      height: 10rem;
    }

    .summary-title {
      width: 50%;
      margin-top: 6rem;
    }
  }

  .split-pending {
    font-size: xx-large;
    color: colour(yellow);
  }
}

// Global containers
.content-container {
  margin-right: 8%;

  @media (max-width: 575px) {
    margin-right: 0px;
  }
}

.section-container,
.find-more-container,
.resources-container {
  margin-top: 4em;
}

.split-container {
  margin-top: 1rem;
}

.image-container {
  margin-top: 2em;
}

.summaryCollapse {
  .summary-container {
    margin-top: 1.5em;
    max-width: 880px;
    margin-right: 30px;
  }
}

.summaryCollapseLink,
.summaryExpandLink {
  height: 4em;
  position: relative;
  bottom: 3rem;
  margin-bottom: -2rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    color-stop(white),
    to(white)
  );
  background-image: linear-gradient(rgba(255, 255, 255, 0), white, white);
  width: 100%;
  pointer-events: none;

  svg {
    margin-left: 0.3em;
  }
  & > a {
    padding-left: 0;
    position: absolute;
    bottom: 10%;
    pointer-events: auto;
    font-weight: 400;
  }
}
.summaryExpandLink {
  height: 6em;
  background-image: none;
}

.details-container {
  margin-top: 1.5em;
  max-width: 880px;
  margin-right: 30px;
}

.summary-header {
  margin-top: 3em;
  margin-bottom: 0.5em;
}

.aside-container {
  margin-top: 2.5em;
}

#splitModal {
  .results {
    margin-bottom: 1rem;
    max-height: 20rem;
    overflow: scroll;

    .result {
      background-color: colour(grey);
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      width: 100%;

      .fieldName {
        width: 80px;
      }

      .flex-grow-1 {
        flex-basis: 80%;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import scroll from 'vue-scrollto'
import colours from '@/mixins/colours'
import utils from '@/mixins/utils'
import mappings from '@/mixins/mappings'
import cultural from '@/mixins/cultural'
import currentRoute from '@/mixins/currentRoute'
import auth from '@/mixins/auth'
import service from '@/service/service'
import BasicSearch from '@/components/search/BasicSearch.vue'
import PerspectiveShelf from '@/components/PerspectiveShelf.vue'
import Collapse from '@/components/common/Collapse.vue'
import Tags from '@/components/work/Tags.vue'
import Versions from '@/components/work/Versions.vue'
import Field from '@/components/work/Field.vue'
import CopyrightStatus from '@/components/work/CopyrightStatus.vue'
import CulturalSensitivityFeedback from '@/components/work/CulturalSensitivityFeedback.vue'
import WorkActions from '@/components/work/workActions/WorkActions.vue'
import PossibleLink from '@/components/common/PossibleLink.vue'
import Stubbable from '@/components/Stubbable.vue'
import CulturalSensitivityWarning from '@/components/cultural/CulturalSensitivityWarning.vue'
import CulturalModal from '../components/cultural/CulturalModal'
import Thumbnail from '@/components/Thumbnail.vue'
import Alert from '@/components/Alert.vue'
import FirstAustraliansIndicator from '@/components/FirstAustraliansIndicator.vue'
import AnnotationList from '@/components/annotations/AnnotationList.vue'
import ContainingLists from '@/components/lists/ContainingLists.vue'

export default {
  name: 'WorksView',
  mixins: [colours, utils, mappings, auth, cultural, currentRoute],
  components: {
    FirstAustraliansIndicator,
    BasicSearch,
    PerspectiveShelf,
    Collapse,
    Tags,
    Alert,
    Versions,
    Field,
    CopyrightStatus,
    WorkActions,
    PossibleLink,
    Stubbable,
    CulturalSensitivityFeedback,
    CulturalSensitivityWarning,
    CulturalModal,
    Thumbnail,
    AnnotationList,
    ContainingLists,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    versionId: {
      type: String,
      required: false,
    },
    includeraw: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  metaInfo() {
    if (!this.version) {
      return { title: this.title || '' + ' - Details' }
    } else {
      if (!this.title) {
        return { title: 'Work not found' }
      } else {
        return { title: this.title || '' + ' - Version details' }
      }
    }
  },
  data() {
    return {
      work: {},
      collections: [],
      selectedCollection: null,
      covers: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
      showDetails: false,
      showSummary: false,
      showThumbnail: false,
      googleBooksPreview: '',
      otherSuppliers: [],
      bookshopIds: [],
      version: null,
      loadingWork: false,
      loadingVersion: false,
      creatorDisplayLimit: 3,
      creatorsLimited: true,
      error: undefined,
      modalText: '',
      versionSeparated: false,
      availableActions: {
        canGet: false,
        canBorrow: false,
        canBuy: false,
        canCite: false,
      },
      showDetailsCollapse: true,
      deleteDetails: null,
      deleteCollapsed: true
    }
  },
  created() {
    service.landing.getCategories().then((collections) => (this.collections = collections))
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.updateWork()
      },
    },
    versionId: {
      handler() {
        this.updateVersion()
      },
    },
    work: {
      handler() {
        this.updateVersion()
      },
    },
  },
  methods: {
    detailsCollapseFullyVisibleChanged(fullyVisible) {
      this.showDetailsCollapse = !fullyVisible
    },
    updateWork() {
      let self = this
      self.error = {}
      self.deleteDetails = null;
      self.loadingWork = true

      return service.work
        .get(self.id, self.includeraw)
        .then((work) => {
          self.work = work
          self.loadingWork = false

          self.updateOtherSuppliers(work)
          self.updateGoogleBooksPreview(work)
          self.updateBookshopSearches(false)
          self.updateBreadcrumbs()
          self.track()
        })
        .catch((error) => {
          self.loadingWork = false;
          self.handleError(error, 'work', self.id, 'retrieve the requested work');
          if (error.response.status === 410 && error.response.data && self.isDataManager) {
            self.deleteDetails = {
              deletedOn: error.response.headers.deletedon,
              record: error.response.data
            }
          } else {
            self.deleteDetails = null;
          }
        })
    },
    track() {
      let self = this
      service._gtm.interaction(this, {
        category: 'work',
        action: 'view',
        value: self.holdingLibrariesNuc(),
        format: self.work.primaryFormat,
        year: _.isUndefined(self.versionDateRange) ? '' : self.versionDateRange.start,
        title: self.work.title,
      })
    },
    updateVersion() {
      let self = this

      let version = null
      self.error = {}
      if (self.versionId && self.work && self.work.items && self.work.items.length > 0) {
        version = _(self.work.items).find((item) => item.id === self.versionId)
      } else if (
        self.work.items &&
        self.work.items.length === 1 &&
        (!self.versionId || self.versionId === self.work.items[0].id)
      ) {
        version = self.work.items[0]
        version.scope = 'full'
      }

      let done = () => {
        self.updateOtherSuppliers(version)
        self.updateGoogleBooksPreview(version)
        self.updateBookshopSearches(self.versionId, self.work.searchableBookshops)
        self.updateBreadcrumbs()

        // google conversion
        var cs = _.map(version.contributors, 'name')
        var contribs = _.join(cs, ',')
        service._gtm.conversion(self, {
          category: 'work',
          action: 'view item',
          label: contribs,
        })
      }

      if (self.versionId) {
        if (!version || version.scope !== 'full') {
          self.loadingVersion = true
          self.versionSeparated = false

          if (!version) {
            version = {}
          }

          service.work
            .getVersionDetails(self.id, self.versionId, self.includeraw)
            .then((v) => {
              _.forOwn(v, function (val, key) {
                self.$set(version, key, val)
              })
              version.scope = 'full'
              self.loadingVersion = false

              done()
              self.scrollToTop(false)
              self.updateBookshopSearches(false)
            })
            .catch((error) => {
              self.loadingVersion = false

              if (error && error.response && error.response.status === 301 && error.response.data) {
                self.versionSeparated = true
                self.error = {
                  summary:
                    'Version with id: ' + self.versionId + ' has been separated from this work.',
                  impact: 'info',
                  routerLink: {
                    name: 'Work',
                    params: {
                      id: error.response.data,
                    },
                    query: this.currentQuery,
                  },
                  action: 'Click here to go to the new work',
                  showContent: false,
                }
              } else {
                self.handleError(error, 'version', self.versionId, 'retrieve the requested version')
                self.error.showContent = true
              }

              self.scrollToTop(true)
            })
        } else {
          done()
          self.scrollToTop(false)
        }
      } else {
        self.scrollToTop(true)
      }

      self.version = version
      self.updateBreadcrumbs()
    },
    updateGoogleBooksPreview(workOrItem) {
      let self = this
      if (workOrItem.googleBooksQueryString) {
        let promise = service.google.lookupBooks(workOrItem.googleBooksQueryString)
        if (promise) {
          promise.then((googleBooks) => {
            let ids = _.map(_.split(workOrItem.googleBooksQueryString, ' OR '), (id) => {
              return _.replace(id, /[^\d]/g, '')
            })
            let firstViewable = _.find(googleBooks.items, (item) => {
              return (
                _.find(item.volumeInfo.industryIdentifiers, (id) => {
                  return _.indexOf(ids, id.identifier) > -1
                }) &&
                (item.accessInfo.viewability === 'PARTIAL' ||
                  item.accessInfo.viewability === 'FULL')
              )
            })

            if (firstViewable) {
              self.googleBooksPreview =
                'https://books.google.com.au/books?id=' +
                firstViewable.id +
                '&printsec=frontcover&source=gbs_ge_summary_r&cad=0#v=onepage&q&f=false'
            } else {
              self.googleBooksPreview = ''
            }
          })
        }
      } else {
        self.googleBooksPreview = ''
      }
    },
    updateBookshopSearches(versionId, bookshopSearches) {
      let self = this

      self.bookshopIds.splice(0, self.bookshopIds.length)

      let bookshopIds = []
      if (versionId) {
        if (bookshopSearches) {
          bookshopIds = _.map(bookshopSearches, 'id')
        }
      } else if (self.work.showBuy) {
        bookshopIds = _.map(self.work.searchableBookshops, 'id')
      }

      _.forEach(bookshopIds, (id) => self.bookshopIds.push(id))
    },
    updateOtherSuppliers(workOrItem) {
      let self = this
      self.otherSuppliers.splice(0, self.otherSuppliers.length)
      _.forEach(workOrItem.contributingInstitutions, (os) => self.otherSuppliers.push(os))
    },
    selectedCollectionUpdated(selectedCollection) {
      this.selectedCollection = selectedCollection
    },
    toggleShowAllCreators() {
      this.creatorsLimited = !this.creatorsLimited
    },
    search(query) {
      var self = this

      if (!query || !query.keyword || _.isEmpty(_.trim(query.keyword))) {
        return
      }

      this.$router.push({
        name: self.selectedCollection ? 'Search' : 'SearchAll',
        params: self.selectedCollection
          ? {
              urlNamespace: self.selectedCollection.urlNamespace,
              urlKey: self.selectedCollection.urlKey,
            }
          : {},
        query: query,
      })
    },
    safeJoin(list) {
      if (list && list.length > 0) {
        return list.join('; ')
      }

      return ''
    },
    updateBreadcrumbs() {
      let crumbs = []
      if (this.versionId) {
        let link = {
          name: 'Work',
          params: {
            id: this.id,
          },
          query: this.includeraw
            ? Object.assign({}, this.currentQuery, { includeraw: 'y' })
            : this.currentQuery,
        }

        crumbs.push({
          link: link,
          value: this.work.title,
          untruncatedSuffix: ' (all editions)',
        })
        if (this.version && !_.isEmpty(this.version)) {
          crumbs.push({
            value: this.version.title,
            untruncatedSuffix: ' (this edition)',
          })
        } else {
          crumbs.push({ value: 'Version not found' })
        }
      } else if (this.title === undefined) {
        crumbs.push({ value: 'Work not found' })
      } else {
        crumbs.push({ value: this.work.title })
      }
      this.$emit('update-breadcrumbs', crumbs, true)
    },
    scrollToTop(notAVersion) {
      this.$nextTick(() => {
        notAVersion ? scroll.scrollTo('body', 500) : scroll.scrollTo(this.$refs['workContent'], 500)
      })
    },
    authorRoute() {
      return service.work.getAuthorRoute
    },
    splitVersionFromWork() {
      var self = this

      service.work
        .splitVersionFromWork(this.work.id, this.version.id, this.modalText)
        .then(() => {
          self.cancelSplit()
          return self.updateWork()
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            self.error = {
              summary: error.response.data.message,
              impact: 'error',
              showContent: true,
            }
          }
        })
    },
    cancelSplit() {
      this.modalText = ''
    },
    metadataRedirect() {
      const currentRoute = this.$route;
      const queryParams = { ...currentRoute.query, includeraw: 'y' }
      this.$router.push({ path: currentRoute.path, query: queryParams })
      this.$router.go()
    },
    availableActionsUpdated(available) {
      _.assign(this.availableActions, available)
    },
    openWorkAction(action) {
      this.$refs.workActions.open(action, true)
    },
    filterFields(toFilter) {
      return _(toFilter)
        .filter(
          (field) =>
            (!field.predicate || field.predicate()) && field.content && !_.isEmpty(field.content)
        )
        .value()
    },
    augmentWithRelatedVersionMetadata(collection) {
      var self = this

      return _.map(collection, (item) => {
        var version = _.find(self.work.items || [], (version) =>
          _.includes(version.id, item.versionId)
        )

        let getFirst = (possibleArray) => {
          return _.isArray(possibleArray) && !_.isEmpty(possibleArray)
            ? possibleArray[0]
            : possibleArray
        }

        if (version) {
          return _.assign({}, item, {
            format: getFirst(version.format),
            language: version.language !== 'english' ? version.language : null,
            year: getFirst(version.year),
          })
        }

        return item
      })
    },
    holdingLibrariesNuc() {
      return _.map(this.work.allHoldings || [], (holding) =>
        holding.library ? `"${holding.library.nuc}"` : 'unknown'
      ).join(',')
    },
  },
  computed: {
    availableUrls() {
      return this.work
        ? this.augmentWithRelatedVersionMetadata(
            this.versionId && this.version
              ? this.version.availableUrls
              : this.work.availableUrls || []
          )
        : []
    },
    subscribingLibraries() {
      return this.work
        ? this.versionId && this.version
          ? this.version.subscribingLibraries
          : this.work.subscribingLibraries || []
        : []
    },
    relatedMaterialUrls() {
      return this.work
        ? this.versionId && this.version
          ? this.version.relatedMaterials
          : this.work.relatedMaterials || []
        : []
    },
    versionPublished() {
      if (!this.version) {
        return ''
      }

      if (_.isArray(this.version.published)) {
        return this.version.published.join(', ')
      } else {
        return this.version.published
      }
    },
    canSplit() {
      return this.authenticated && this.isDataManager && (this.work.records || []).length > 1
    },
    splitMergeDiffs() {
      if (!this.work) {
        return null
      }

      var itemIds = _(this.work.items || [])
        .map((item) => (item.id || '').split(' '))
        .flatten()
        .value()
      var recordIds = _(this.work.records || [])
        .map((record) => record.id)
        .value()

      return {
        split: _.difference(itemIds, recordIds),
        merge: _.difference(recordIds, itemIds),
      }
    },
    splitMergePending() {
      return this.splitPending || this.mergePending
    },
    splitPending() {
      var diffs = this.splitMergeDiffs

      if (!diffs) {
        return false
      }

      return !_.isEmpty(diffs.split)
    },
    mergePending() {
      var diffs = this.splitMergeDiffs

      if (!diffs) {
        return false
      }

      return !_.isEmpty(diffs.merge)
    },
    blurWork() {
      return (
        (this.version ? this.version.culturallySensitive : this.work.culturallySensitive) &&
        this.suppressContent(this.id)
      )
    },
    creatorsAsArray() {
      let creators = []
      if (this.version && this.version.creators) {
        creators = this.version.creators
      } else if (this.version && this.version.creator) {
        creators = _.split(this.version.creator, ';')
      } else if (this.work && this.work.author) {
        creators = _.split(this.work.author, ';')
      }
      return creators
    },
    creatorCount() {
      return this.creatorsAsArray.length
    },
    creators() {
      return _.take(
        this.creatorsAsArray,
        this.creatorsLimited ? this.creatorDisplayLimit : this.creatorCount
      )
    },
    copiesDirectParams() {
      return this.versionId && this.version
        ? this.version.copiesDirectParameters
        : this.work.firstItemWithCopiesDirectParameters
        ? this.work.firstItemWithCopiesDirectParameters.copiesDirectParameters
        : ''
    },
    canBuy() {
      return (
        (!_.isUndefined(this.copiesDirectParams) && this.copiesDirectParams.length > 0) ||
        (this.otherSuppliers && this.otherSuppliers.length > 0) ||
        (this.version ? this.version.showBuy : this.work.showBuy)
      )
    },
    formatIcon() {
      return this.mapFormatIcon(this.format)
    },
    format() {
      return this.safeJoin(this.version ? this.mapFormats(this.version.format) : [])
    },
    yearRange() {
      let range = ''

      if (this.version && this.version.yearRange && this.version.yearRange.length > 0) {
        range = _.join(
          _.sortBy(this.version.yearRange, (y) => -y),
          ', '
        )
      } else if (this.version && this.version.year && this.version.year.length > 0) {
        range = _(this.version.year)
          .sortBy((year) => year)
          .values()
          .join(' - ')
      }

      return range
    },
    summary() {
      return this.work.summary
    },
    showCollapse() {
      return this.summary.length > 900 ? true : false
    },
    versionDateRange() {
      let match = (this.work.yearRange || '').match(/^(\d+)-(\d+)$/)
      return match && match.length > 1
        ? { start: match[1], end: match.length > 2 ? match[2] : '' }
        : undefined
    },
    title() {
      return !this.version ? this.work.title : this.version.title
    },
    versionCovers() {
      let self = this
      let blur = self.blurWork
      return _(this.allVersions)
        .map((version) => {
          return {
            id: version.id,
            img: version.thumbnailUrl,
            title: version.title,
            blur: version.culturallySensitive && blur,
          }
        })
        .value()
    },
    allVersions() {
      let self = this
      return self.work.items ? _.filter(self.work.items, (item) => item.id !== self.versionId) : []
    },
    showVersions() {
      return this.work.items && this.work.items.length > 1
    },
    citationId() {
      if (this.version) {
        if (this.version.bibliographicRecordId) {
          return this.version.bibliographicRecordId
        } else if (this.version.records && this.version.records.length > 0) {
          return this.version.records[0].recordId
        }
      }

      return null
    },
    bibliographicRecordId() {
      return this.version ? this.version.bibliographicRecordId : null
    },
    showContributor() {
      return this.work.numHoldings === 1 && this.work.singleHoldingName
    },
    contributorText() {
      if (this.work.numHoldings === 1) {
        return 'At ' + this.work.singleHoldingName
      }

      return ''
    },
    showCorrectableText() {
      return this.work.correctableText
    },
    isPicture() {
      return (
        [
          'art work',
          'picture',
          'map',
          'photograph',
          'poster, chart, other',
          'artefact',
          'object',
        ].indexOf(_.toLower(this.work.format)) > -1
      )
    },
    isThumbnail() {
      return this.isPicture && !_.isUndefined(this.work.thumbnailUrl) && this.showThumbnail
    },
    workFields() {
      let self = this
      let w = this.work

      let fields = [
        {
          title: 'Raw Record' + (w.rawRecords && w.rawRecords.length === 1 ? '' : 's'),
          predicate: () =>
            self.includeraw && !self.version && w.rawRecords && w.rawRecords.length > 0,
          content: w.rawRecords,
          code: 'xml',
        },
      ]

      let filteredFields = self.filterFields(fields)

      let key = 1
      _.forEach(filteredFields, (f) => (f.key = 'field' + key++))

      return filteredFields
    },
    versionFields() {
      let self = this
      let v = this.version

      let groupValues = (title, values) => {
        let grouped = ''
        if (values && !_.isEmpty(values)) {
          grouped = title + ' ' + (_.isArray(values) ? values.join(', ') : values)
        }
        return grouped
      }

      const ISSN_REGEX = /.*?([0-9]{4}-?[0-9X]{4})/
      let getIssnSearchRoute = (text) => {
        let route = undefined

        if (_.isString(text)) {
          let matches = text.match(ISSN_REGEX)
          if (matches && matches.length > 0) {
            route = {
              name: 'SearchAll',
              query: { keyword: 'issn:' + matches[1].replace('-', '') },
            }
          }
        }

        // eslint-disable-next-line
                 return Promise.resolve(route);
      }

      let identifiers = [
        { content: groupValues('ISBN', v.isbn) },
        { content: groupValues('ISSN', v.issn) },
        { content: groupValues('ISMN', v.ismn) },
        { content: groupValues('Dewey Number', v.deweyNumber) },
        { content: groupValues('Govt Doc Number', v.govtDocNumber) },
        { content: groupValues('Standard Number', v.standardNumber) },
        { content: groupValues('Music Publisher No.', v.musicPublisherNumber) },
        {
          content: groupValues(this.isPicture ? 'Image Number' : 'Identifier', v.identifier),
        },
        {
          content: groupValues(
            '',
            _.map(
              v.laRecordId,
              (a) => "<a href='" + v.laRecordUrl + a + "' target='_blank'>" + a + '</a>'
            )
          ),
          inlineTitle: 'Libraries Australia ID',
        },
      ]

      let fields = [
        { title: 'Title', content: v.titles, tryLangDetect: true },
        {
          title: 'Uniform Title',
          content: v.uniformTitle,
          tryLangDetect: true,
        },
        {
          title: 'Also Titled',
          content: v.alsoTitled,
          ulclass: 'shortenul',
          tryLangDetect: true,
        },
        {
          title: 'Translation Of:',
          content: v.translationOf,
          ulclass: 'shortenul',
          tryLangDetect: true,
          routeResolver: getIssnSearchRoute,
        },
        {
          title: 'Translated As:',
          content: v.translatedAs,
          ulclass: 'shortenul',
          tryLangDetect: true,
          routeResolver: getIssnSearchRoute,
        },
        {
          title: 'Later Title',
          content: v.laterTitle,
          routeResolver: getIssnSearchRoute,
          tryLangDetect: true,
        },
        {
          title: 'Former Title',
          content: v.formerTitle,
          routeResolver: getIssnSearchRoute,
          tryLangDetect: true,
        },
        {
          title: 'Appears In',
          content: v.partOfPublication,
          routeResolver: getIssnSearchRoute,
        },
        {
          title: 'Author',
          content: v.creators,
          predicate: () => v.hasAuthor,
          routeResolver: service.work.getAuthorRoute,
        },
        {
          title: 'Other Authors',
          content: v.otherCreators,
          predicate: () => v.hasAuthor,
          routeResolver: service.work.getAuthorRoute,
        },
        {
          title: 'Creator',
          content: v.creators,
          predicate: () => !v.hasAuthor,
          routeResolver: service.work.getAuthorRoute,
        },
        {
          title: 'Other Creators',
          content: v.otherCreators,
          predicate: () => !v.hasAuthor,
          routeResolver: service.work.getAuthorRoute,
        },
        { title: 'Other Contributors', content: v.otherContributors },
        { title: 'Edition', content: v.editions },
        { title: 'Key', content: v.musicalKey },
        { title: 'Produced', content: v.produced },
        { title: 'Published', content: v.published },
        { title: 'Distributed', content: v.distributed },
        { title: 'Manufactured', content: v.manufactured },
        { title: 'Copyright', content: v.copyright },
        { title: 'Medium', content: v.medium },
        { title: 'Content Types', content: v.contentTypes },
        { title: 'Carrier Types', content: v.carrierTypes },
        { title: 'Physical Description', content: v.physicalDescription },
        {
          title: 'Series',
          content: _.map(v.seriesPairKeys, (p) => {
            return {
              content: p,
              route: {
                name: 'SearchAll',
                query: { keyword: 'series:"' + p.replace(/["']/g, '') + '"' },
              },
            }
          }),
        },
        {
          title: 'Sub-series Of',
          content: v.subSeriesOf,
          routeResolver: getIssnSearchRoute,
        },
        {
          title: 'Part Of',
          content: v.partOfPairKeys,
          routeResolver: getIssnSearchRoute,
          linkmap: v.partOfPair,
          predicate: () => v.partOfPairKeys && v.partOfPairKeys.length > 0,
        },
        {
          title: 'Part Of',
          content: v.partOf,
          predicate: () => !(v.partOfPairKeys && v.partOfPairKeys.length > 0),
        },
        {
          title: 'Part/s',
          content: v.partsPairKeys,
          routeResolver: getIssnSearchRoute,
          linkmap: v.partsPair,
          limit: 5,
        },
        {
          title: 'Subjects',
          content: v.annotatedSubjectsKeys,
          linkindex: 'subject',
          ndlinkindex: 'subject',
          linkmap: v.annotatedSubjectsKeys,
          predicate: () => v.getSupportsAnnotatedSubjects,
        },
        { title: 'Date or Place', content: v.dateOrPlace },
        { title: 'Time Period', content: v.timePeriod },
        { title: 'Form', content: v.form },
        { title: 'Presentation', content: v.presentation },
        { title: 'Map Data', content: v.mapData },
        { title: 'Playing Time', content: v.playingTime },
        { title: 'Geospatial References', content: v.geospatialReferences },
        { title: 'Planar Coordinates', content: v.planarCoordinates },
        {
          title: 'Contents',
          content: v.contents,
          tryhref: true,
          limit: 15,
          showlist: !v.contentsIsNumberedList,
          ddclass: 'contents-list',
        },
        { title: 'Credits', content: v.credits },
        { title: 'Performer', content: v.performer },
        { title: 'Biography', content: v.biography, tryhref: true },
        { title: 'Notes', content: v.notes, tryhref: true },
        { title: 'Target Audience', content: v.targetAudience },
        { title: 'Cited In', content: v.citedIn, tryhref: true },
        { title: 'Numbering Note', content: v.numberingNote },
        { title: 'Journal Dates', content: v.journalDates },
        {
          title: 'Has Supplement',
          content: v.hasSupplement,
          routeResolver: getIssnSearchRoute,
        },
        {
          title: 'Supplement To',
          content: v.supplementTo,
          routeResolver: getIssnSearchRoute,
        },
        {
          title: 'Has Sub-series',
          content: v.hasSubSeries,
          routeResolver: getIssnSearchRoute,
        },
        {
          title: 'Other Editions',
          content: v.otherEditions,
          routeResolver: getIssnSearchRoute,
        },
        {
          title: 'Issued With',
          content: v.issuedWith,
          routeResolver: getIssnSearchRoute,
        },
        {
          title: 'Related to',
          content: v.relatedToPairKeys,
          routeResolver: getIssnSearchRoute,
          linkmap: v.relatedToPair,
          limit: 5,
        },
        { title: 'Frequency', content: v.frequency },
        { title: 'Previous Frequency', content: v.previousFrequency },
        { title: 'Numbering Note', content: v.findingAid },
        { title: 'Access Conditions', content: v.accessConditions },
        { title: 'Source', content: v.source, tryhref: true },
        { title: 'Other Form', content: v.otherForm },
        {
          title: 'Terms of Use',
          content: this.mapCreativeCommonsImagePath(v.termsOfUse),
          tryhref: true,
          showHtml: true,
        },
        {
          title: 'Technical Details',
          content: v.technicalDetails,
          tryhref: true,
        },
        { title: 'Language', content: _.join(v.languages, ', ') },
        { title: 'Country of Origin', content: v.countryOfOrigin },
        {
          title: 'Related Resource',
          content: v.relatedResource,
          tryhref: true,
        },
        {
          title: 'Identifiers',
          content: _.filter(identifiers, (i) => {
            return !_.isUndefined(i) && !_.isEmpty(i.content)
          }),
        },
        { title: 'Managed by', content: v.managedBy },
        { title: 'Database', content: v.vendorDatabase },
        {
          title: 'Contributed by',
          content: _(v.contributors)
            .map((contrib) => {
              return {
                value: contrib.name,
                url: contrib.url,
                logo: contrib.logo,
              }
            })
            .value(),
        },
        { title: 'Merge History', content: v.mergeHistory },
        {
          title: 'Raw Record' + (v.rawRecords && v.rawRecords.length === 1 ? '' : 's'),
          predicate: () => self.includeraw && v.rawRecords && v.rawRecords.length > 0,
          content: v.rawRecords,
          code: 'xml',
        },
      ]

      let filteredFields = self.filterFields(fields)

      let key = 1
      _.forEach(filteredFields, (f) => (f.key = 'field' + key++))

      return filteredFields
    },
  },
}
</script>
