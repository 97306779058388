<template>
  <div class="result" :key="result.name">
    <div class="sub-container">
      <Thumbnail
        :url="result.img"
        :title="'Thumbnail for ' + result.name"
        :blur="blurThumbnail"
        type="image"></Thumbnail>

      <span class="format">{{ mapReltype(result) }}</span>

      <div class="caption">
        <PossibleLink :linkResolver="linkResolver()" :label="result.name">{{
          result.name
        }}</PossibleLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$container-absolute-width: 200px;
$container-margin: 5px;
$container-absolute-height: 250px;

$arrow-height: 20px;

$thumbnail-dimension: 200px;

.result {
  margin: 0;
  padding: 0;

  .sub-container {
    width: $container-absolute-width - ($container-margin * 2);
    @media (max-width: 575px) {
      //30px for bootstrap container padding
      width: calc(100vw / 2 - 30px);
    }

    margin: $container-margin;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    .thumbnail {
      height: $thumbnail-dimension;
      border-radius: 5px;

      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      margin-left: auto;
      margin-right: auto;
      margin-top: $container-margin;
      margin-bottom: $container-margin;
    }

    .thumbnail-substitute {
      width: 100%;
      height: 100%;

      div {
        width: 100%;
        height: 10em;
        background-color: colour(grey-5);
        font-size: small;
        padding: $container-margin;

        .source {
          svg {
            margin-right: 0.5em;
          }
        }
      }
    }

    .caption {
      width: 100%;
      font-size: small;
      padding-top: 0.2em;
      padding-bottom: 0.2em;
    }

    .format {
      font-size: small;
    }
  }
}
</style>

<script>
import _ from 'lodash'

import colours from '@/mixins/colours'
import cultural from '@/mixins/cultural'
import service from '@/service/service'
import PossibleLink from '@/components/common/PossibleLink.vue'
import Thumbnail from '@/components/Thumbnail.vue'

export default {
  name: 'RelatedItem',
  components: {
    PossibleLink,
    Thumbnail,
  },
  mixins: [colours, cultural],
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    blurThumbnail() {
      return (
        this.result.thumbnailIsCulturallySensitive &&
        this.suppressContent(this.result.id) &&
        (!this.result.parentId || this.suppressContent(this.result.parentId))
      )
    },
  },
  methods: {
    mapReltype(name) {
      let mapping = {
        isMemberOf: 'member of',
        superior: 'member of',
        'hierarchical-parent': 'member of',
        'hierarchical-child': 'member',
        'temporal-earlier': 'earlier',
        'temporal-later': 'later',
        associative: 'see also',
      }

      return _([name.reldesc, mapping[name.reltype], 'see also'])
        .filter((v) => v)
        .map((v) => v.replace(/<\/?[^>]+(>|$)/g, ''))
        .head()
    },
    linkResolver() {
      return service.work.getAuthorRoute
    },
  },
}
</script>
