<template>
  <div class="profile-dev">
    <template v-if="hasAPIKey">
      <APIManage
        :apiDetails="profileInfo.apiDetails || {}"
        :contactUsUrl="contactUsUrl"
        :apiKeyRateIncreaseRequestUrl="apiKeyRateIncreaseRequestUrl"
        @update-details="updateDetails" />
    </template>
    <template v-else>
      <APIApply :contactUsUrl="contactUsUrl" @update-details="updateDetails" />
    </template>
  </div>
</template>

<style scoped lang="scss">
.profile-dev {
  padding: 1rem 1rem 0;
}
</style>

<script>
import _ from 'lodash'
import service from '@/service/service'
import APIApply from '@/components/profile/APIApply.vue'
import APIManage from '@/components/profile/APIManage.vue'
import profile from '@/mixins/profile'

export default {
  name: 'UserProfileDev',
  mixins: [profile],
  components: {
    APIApply,
    APIManage,
  },
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactUsUrl: '',
      apiKeyRateIncreaseRequestUrl: ''
    }
  },
  created() {
    let self = this

    service.config.environment().then((environment) => {
      self.contactUsUrl = environment.contactUsUrl;
      self.apiKeyRateIncreaseRequestUrl = environment.apiKeyRateIncreaseRequestUrl;
    });
  },
  computed: {
    hasAPIKey() {
      return this.profileInfo && this.profileInfo.hasAPIKey
    },
  },
  methods: {
    updateDetails(updatedDetails) {
      this.$emit(
        'update-profile',
        _.assign({}, this.profileInfo, {
          hasAPIKey: !_.isEmpty(updatedDetails),
          apiDetails: updatedDetails,
        })
      )
    },
  },
  mounted() {
    if (!this.isMyProfile) {
      this.$router.push('/')
    }
  },
}
</script>
