<template>
  <div class="profile-settings">
    <b-row>
      <b-col md="6" sm="12" class="profile-details">
        <h2 class="bevel">Account</h2>
        <div class="email">
          <div><span class="label">Email address</span></div>
          <div>{{ profileInfo.email }}</div>
          <div class="email-form" :class="{ open: showChangeEmailForm }" v-if="!isStaff">
            <ValidationObserver
              ref="changeEmailForm"
              tag="div"
              key="changeEmail"
              v-if="showChangeEmailForm">
              <ValidationProvider rules="required|email" v-slot="{ errors }" name="Email">
                <b-form-group label="New email address" label-class="font-weight-bold">
                  <b-form-input
                    type="email"
                    v-model="newEmailAddress"
                    :required="true"></b-form-input>
                </b-form-group>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
              <div class="buttons">
                <b-button variant="link" @click="cancelChangeEmail">Cancel</b-button>
                <b-button @click="saveEmail" :disabled="inProgress">Save</b-button>
              </div>
            </ValidationObserver>
          </div>
          <b-link class="change-email-link" v-if="isMyProfile && !isStaff && !showChangeEmailForm"
            ><div @click="changeEmail()">Change my email address</div></b-link
          >
        </div>
        <div v-if="isMyProfile" class="password">
          <div><span class="label">Password</span></div>
          <b-link class="change-password-link"
            ><div @click="changePassword()">Change my password</div></b-link
          >
        </div>
      </b-col>

      <b-col md="4" sm="12">
        <h2 class="bevel">Privacy</h2>
        <AchievementNotice :user-achievement="profileInfo.userAchievement"></AchievementNotice>
        <div class="profile-visibility">
          <font-awesome-icon
            class="private-icon"
            :icon="{
              prefix: 'fa',
              iconName: profileInfo.publiclyVisible ? 'lock-open' : 'lock',
            }" />
          <div class="details">
            <h4>User profile</h4>
            <span v-if="profileInfo.publiclyVisible"
              >Your user profile is public. Anyone can see:</span
            >
            <span v-if="!profileInfo.publiclyVisible"
              >Your user profile is private. Only you can see:</span
            >
            <ul>
              <li>your Trove username</li>
            </ul>
          </div>
          <div class="select">
            <b-form-checkbox
              v-model="profileInfo.publiclyVisible"
              switch
              :disabled="!isMyProfile"
              @change="confirmPrivacyUpdate('publiclyVisible')"></b-form-checkbox>
          </div>
        </div>
        <div class="activity-visibility">
          <font-awesome-icon
            class="private-icon"
            :icon="{
              prefix: 'fa',
              iconName: profileInfo.activityVisible ? 'lock-open' : 'lock',
            }" />
          <div class="details">
            <h4>User activity</h4>
            <span v-if="profileInfo.activityVisible"
              >Your user activity is public. Anyone can see:</span
            >
            <span v-if="!profileInfo.activityVisible"
              >Your user activity is private. Only you can see:</span
            >
            <ul>
              <li>Text corrections</li>
              <li>Tags</li>
              <li>Notes</li>
              <li>Lists (including collaborative lists)</li>
            </ul>
          </div>
          <div class="select">
            <b-form-checkbox
              v-model="profileInfo.activityVisible"
              switch
              :disabled="!isMyProfile && !profileInfo.publiclyVisible"
              @change="confirmPrivacyUpdate('activityVisible')"></b-form-checkbox>
          </div>
        </div>
        <div class="feeds-enabled">
          <font-awesome-icon
            class="private-icon"
            :icon="{
              prefix: 'fa',
              iconName: profileInfo.feedEnabled ? 'bell' : 'bell-slash',
            }" />
          <div class="details">
            <h4>Feeds</h4>
            <span v-if="profileInfo.feedEnabled"
              >Your activity feed is enabled.<br />
              If you disable your feed, your feed history will be deleted.</span
            >
            <span v-if="!profileInfo.feedEnabled">Your activity feed is disabled.</span>
          </div>
          <div class="select">
            <b-form-checkbox
              v-model="profileInfo.feedEnabled"
              switch
              :disabled="!isMyProfile"
              @change="confirmPrivacyUpdate('feedEnabled')"></b-form-checkbox>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="!isStaff">
      <b-col sm="10">
        <div class="delete-account-container">
          <b-link class="delete-account-link" v-if="!deleteAccountPanelOpen"
            ><div @click="toggleDeleteAccountPanel()">
              I would like to delete my account
            </div></b-link
          >

          <div class="delete-account" :class="{ open: deleteAccountPanelOpen }">
            <h2>
              <font-awesome-icon
                :icon="{ prefix: 'fa', iconName: 'exclamation-triangle' }" />Caution
            </h2>
            <p class="bold"> You are about to permanently delete your Trove account! </p>
            <p> It will not be possible to restore your account once it has been deleted. </p>
            <p>When you delete your account:</p>
            <ul>
              <li>Your personal details will be deleted</li>
              <li>All notes you have added will be deleted</li>
              <li>All tags you have added will be deleted</li>
              <li>
                All lists you have created, including your own collaborative lists, will be deleted
              </li>
              <li> All contributions you have made to collaborative lists will be deleted </li>
              <li>All text corrections you have made will be anonymised</li>
              <li>
                If you have a Trove API key, this will be deleted and will no longer be able to be
                used with the Trove API
              </li>
            </ul>
            <!-- eslint-disable vue/no-unused-vars -->
            <ValidationObserver
              ref="deleteAccountForm"
              v-slot="{ invalid, classes }"
              tag="div"
              key="deleteConfirmation"
              v-if="deleteAccountPanelOpen">
              <ValidationProvider
                :rules="{ required: { allowFalse: false } }"
                v-slot="{ errors }"
                name="confirmation">
                <label for="deleteConfirmation" class="sr-only">Delete confirmation</label>
                <b-form-checkbox
                  id="deleteConfirmation"
                  v-model="deleteConfirmed"
                  :required="true"
                  :class="classes"
                  class="bold">
                  I understand that my data will be deleted and cannot be restored if I proceed
                </b-form-checkbox>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>

              <div class="buttons">
                <b-button variant="link" @click="toggleDeleteAccountPanel">Cancel</b-button>
                <b-button @click="deleteAccount" :disabled="deleting">Delete my account</b-button>
              </div>
            </ValidationObserver>
            <!-- eslint-enable vue/no-unused-vars -->
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal centered no-close-on-esc no-close-on-backdrop id="deletingAccountModal">
      <div slot="modal-header"></div>
      <div class="wait-content">
        <img
          src="https://assets.nla.gov.au/favicons/trove/favicon-64.png"
          class="rotate"
          alt="Deleting your account" />
        <div class="processing-text">Deleting your account...</div>
      </div>
      <div slot="modal-footer"></div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.wait-content {
  text-align: center;
}

.processing-text {
  padding-top: 1rem;
}

.rotate {
  animation: waiter-rotation 2s infinite linear;
}

.profile-settings {
  padding: 1rem 1rem 0;

  h2.bevel {
    font-family: 'Source Sans Pro', sans-serif;
    &:before {
      top: 0.11rem;
    }
  }

  @supports (font-variation-settings: normal) {
    h2.bevel {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }

  .profile-details {
    .email {
      padding-top: 1rem;
    }

    .password {
      padding-top: 2rem;

      .change-password-link {
        padding-top: 1rem;
        display: inline-block;
      }
    }

    .label {
      font-weight: bold;
      margin-right: 1rem;
    }

    .email-form {
      margin-top: 1rem;
      max-height: 0;
      height: 0;
      background-color: colour(white);
      overflow: hidden;

      &.open {
        height: auto;
        max-height: 200px;
        border-radius: 3px;
        transition: max-height 0.5s ease;
        margin-top: 0.5rem;
      }

      .form-group {
        margin-bottom: 0.5rem;
      }

      .invalid {
        border: solid 1px colour(form-field-error);
      }

      .error {
        color: colour(form-field-error);
      }

      .buttons {
        text-align: right;
      }

      .list-field-label {
        font-weight: bold !important;
      }

      .hint {
        font-size: 0.8rem;
        color: colour(grey-1);
      }

      .custom-control-inline {
        margin-right: 2rem !important;
      }
    }
  }

  .profile-visibility,
  .biography-visibility,
  .activity-visibility,
  .feeds-enabled,
  .rewards-enabled {
    padding-top: 1rem;

    h4 {
      font-family: 'Source Sans Pro', sans-serif !important;
      font-size: 1.1rem;
    }

    @supports (font-variation-settings: normal) {
      h4 {
        font-family: 'Source Sans Variable', sans-serif !important;
      }
    }

    svg {
      display: inline-block;
      vertical-align: top;
      margin-right: 1rem;
    }

    .details {
      display: inline-block;
      max-width: 80%;
    }

    .select {
      display: inline-block;
      vertical-align: top;
      float: right;
    }
  }

  .delete-account-container {
    .delete-account-link {
      color: colour(magenta);
    }

    .delete-account {
      max-height: 0;
      overflow: hidden;
      background-color: colour(yellow-tint60);
      font-weight: 600;
      margin-top: 1.5rem;

      &.open {
        padding: 1rem;
        max-height: 500px;
        transition: max-height ease-in-out 300ms;

        h2 {
          margin-bottom: 1rem;
          font-family: 'Source Sans Pro', sans-serif;

          svg {
            margin-right: 0.5rem;
          }
        }
        @supports (font-variation-settings: normal) {
          h2 {
            font-family: 'Source Sans Variable', sans-serif;
          }
        }

        ul {
          margin-bottom: 2rem;
        }

        label {
          font-weight: bold;
        }

        .buttons {
          text-align: right;
          margin-top: 2rem;
        }

        .bold {
          font-weight: 700;
        }

        .btn-link {
          font-weight: 600;
        }

        .invalid {
          border: solid 1px colour(form-field-error);
        }

        .error {
          color: colour(form-field-error);
          margin-left: 1.5rem;
        }
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import auth from '@/mixins/auth'
import utils from '@/mixins/utils'
import profile from '@/mixins/profile'
import service from '@/service/service'
import { required } from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import AchievementNotice from '@/components/common/AchievementNotice.vue'

setInteractionMode('eager')
extend('required', {
  ...required,
})
export default {
  name: 'UserProfileSettings',
  mixins: [auth, profile, utils],
  components: {
    ValidationProvider,
    ValidationObserver,
    AchievementNotice,
  },
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showChangeEmailForm: false,
      newEmailAddress: undefined,
      inProgress: false,
      deleteConfirmed: undefined,
      deleteAccountPanelOpen: false,
      deleting: false,
    }
  },
  metaInfo() {
    if (!_.isEmpty(this.usernameParam)) {
      return { title: this.cleanUsername() }
    } else {
      return { title: 'My profile' }
    }
  },
  mounted() {
    this.updateBreadcrumbs()
  },
  computed: {},
  methods: {
    updateBreadcrumbs() {
      let crumbs = []
      crumbs.push({ link: '/userProfile/', value: 'My profile' })

      this.$emit('update-breadcrumbs', crumbs, true)
    },
    changeEmail() {
      this.showChangeEmailForm = true
    },
    cancelChangeEmail() {
      this.newEmailAddress = undefined
      this.showChangeEmailForm = false
    },
    saveEmail() {
      let self = this
      self.$refs.changeEmailForm.validate().then((isValid) => {
        if (isValid) {
          self.inProgress = true

          service.profile
            .updateEmailAddress(self.newEmailAddress)
            .then((profile) => {
              self.profileInfo = profile
              self.inProgress = false
              self.newEmailAddress = undefined
              self.showChangeEmailForm = false
            })
            .catch((error) => {
              self.inProgress = false
              self.handleError(error, undefined, undefined, 'update your email address')
            })
        }
      })
    },
    changePassword() {
      service.config
        .environment()
        .then((environment) => {
          location.href =
            environment.kcInit.url + '/realms/' + environment.kcInit.realm + '/account/password'
        })
        .catch((error) => {
          self.saving = false
          self.handleError(error, undefined, undefined, 'change your password')
        })
    },
    saveProfile() {
      let self = this
      service.profile
        .updateProfile(self.profileInfo)
        .then((response) => {
          self.inProgress = true
          self.$emit('update-profile', response)
        })
        .catch((error) => {
          self.inProgress = false
          self.$emit('error', {
            error: error,
            contextMessage: 'update your profile',
          })
        })
    },
    toggleDeleteAccountPanel() {
      this.deleteAccountPanelOpen = !this.deleteAccountPanelOpen
      this.deleteConfirmed = false
    },
    deleteAccount() {
      let self = this
      self.$refs.deleteAccountForm.validate().then((isValid) => {
        if (isValid) {
          self.$bvModal
            .msgBoxConfirm('This will permanently delete your account. Do you wish to proceed?', {
              cancelVariant: 'link',
            })
            .then((result) => {
              if (result) {
                self.deleting = true
                this.$bvModal.show('deletingAccountModal')
                service.profile
                  .deleteProfile(self.fullUsername)
                  .then(() => {
                    self.$router.push({ name: 'UserAccountDeleted' })
                  })
                  .catch((error) => {
                    self.handleError(error, 'profile', undefined, 'delete your profile')
                  })
              }
            })
        }
      })
    },
    confirmPrivacyUpdate(privacySettingProperty) {
      let self = this
      self.showConfirmMsgBox(self.privacyUpdateMsg(privacySettingProperty), privacySettingProperty)
    },
    privacyUpdateMsg(privacySettingProperty) {
      let self = this
      let htmlMsg
      switch (privacySettingProperty) {
        case 'publiclyVisible':
          htmlMsg = !self.profileInfo.publiclyVisible
            ? 'Your user profile will be set to Private. No-one can see:' +
              '<ul><li>your Trove username</li><li>your Trove profile</li></ul> ' +
              'Switching to Private will remove all access to ' +
              // eslint-disable-next-line max-len
              '<a href="https://trove.nla.gov.au/help/become-voluntrove/lists/collaborative-lists" target="_new">Collaborative lists</a>.' +
              // eslint-disable-next-line max-len
              '<p>If you own a Collaborative list it will be set to Private, all community contributions will be removed.</p>'
            : 'Your user profile will be set to Public. Anyone can see:' +
              '<ul><li>your Trove username</li><li>your Trove profile</li></ul>' +
              'Please confirm that you have read and understood ' +
              '<a href="https://trove.nla.gov.au/about/policies/privacy" target="_new">Trove\'s privacy policy</a>'
          break
        case 'biographyVisible':
          htmlMsg = !self.profileInfo.biographyVisible
            ? 'Your biography will be set to Private. No-one can see:'
            : 'Your biography will be set to Public. Anyone can see:'
          htmlMsg +=
            '<ul><li>your profile picture</li><li>your biography</li><li>social media links</li></ul> ' +
            'Make sure you read ' +
            '<a href="https://trove.nla.gov.au/about/policies/privacy" target="_new">Trove\'s privacy policy</a>'
          break
        case 'activityVisible':
          htmlMsg = !self.profileInfo.activityVisible
            ? 'Your user activity will be set to Private. No-one can see:'
            : 'Your user activity will be set to Public. Anyone can see:'
          htmlMsg +=
            '<ul><li>Text corrections</li><li>Tags</li><li>Notes</li>' +
            '<li>Lists (including collaborative lists)</li></ul> ' +
            // eslint-disable-next-line max-len
            'Make sure you read <a href="https://trove.nla.gov.au/about/policies/privacy" target="_new">Trove\'s privacy policy</a>'
          break
        case 'feedEnabled':
          htmlMsg = !self.profileInfo.feedEnabled
            ? '<p>Are you sure? Disabling your feed will delete your feed history.</p>' +
              // eslint-disable-next-line max-len
              '<p>Your feed history cannot be recovered and Trove will not collate information for your feed while it is disabled.</p>'
            : 'Your activity feed will be enabled.'
          break
        default:
          break
      }
      return self.$createElement('div', { domProps: { innerHTML: htmlMsg } })
    },
    showConfirmMsgBox(msg, property) {
      let self = this
      self.$bvModal
        .msgBoxConfirm(msg, {
          title: 'Change Settings',
          okTitle: 'Change',
          okVariant: 'success',
          cancelVariant: 'link',
        })
        .then((result) => {
          if (result) {
            self.saveProfile()
          } else {
            _.set(self.profileInfo, property, !_.get(self.profileInfo, property))
          }
        })
    },
  },
}
</script>
