<template>
  <b-col tag="article" v-if="horizontal" md="4" sm="4" xs="12" class="horizontal">
    <div class="support-info">
      <div class="p-2 flex-fill">
        <h3 class="support-title">{{ article.title }}</h3>
        <p class="support-heading flex-grow-1">{{ article.catchline }}</p>
        <div v-if="hasSummary">
          <p id="article-summary">{{ article.summary }}</p>
          <div class="d-flex flex-row body-text-links" v-if="hasCallToAction">
            <b-button variant="primary" class="inverse" :href="article.externalUrl">{{
              article.callToAction
            }}</b-button>
          </div>
        </div>
        <div v-if="hasArtworkUrl">
          <div v-if="hasExternalUrl">
            <b-link :href="article.externalUrl">
              <b-img
                :alt="article.artworkImageAlt"
                :src="article.artworkUrl"
                class="support-article-img"
                aria-hidden="true" />
            </b-link>
          </div>
          <div v-else>
            <b-img
              :alt="article.artworkImageAlt"
              :src="article.artworkUrl"
              class="support-article-img"
              aria-hidden="true" />
          </div>
        </div>
        <div v-if="hasVideoId">
          <div class="video-wrapper">
            <iframe
              :width="videoDimensions.width"
              :height="videoDimensions.height"
              :src="videoURL"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              :title="embedTitle"></iframe>
          </div>
        </div>
      </div>
    </div>
  </b-col>
  <b-row v-else tag="article" class="spotlight-row" aria-role="listitem">
    <b-col xl="6" sm="12" lg="12" md="12" class="col-6">
      <b-link :href="article.externalUrl" aria-hidden="true" tabindex="-1">
        <b-img
          :alt="article.artworkImageAlt"
          :src="article.artworkUrl"
          class="support-article-img"></b-img>
      </b-link>
    </b-col>
    <b-col
      xl="6"
      lg="12"
      md="12"
      sm="12"
      class="support-info d-flex flex-column justify-content-start col-6">
      <b-link :href="article.externalUrl">
        <h3 class="support-title">{{ article.title }}</h3>
        <p class="support-heading flex-grow-1">{{ article.catchline }}</p>
      </b-link>
      <div class="small-bevel" :class="colourClass || randomColourName()"></div>
    </b-col>
  </b-row>
</template>

<style lang="scss" scoped>
.support-info {
  align-content: stretch;
  margin-bottom: 1.5rem;

  .support-title {
    font-weight: 700;
    font-size: 1.25rem;
  }
  .support-heading {
    text-transform: uppercase;
    color: colour(blue);
    font-weight: 400;
    font-size: 0.875rem;
    overflow: hidden;
  }
  a:hover,
  a:focus {
    text-decoration: none;

    h3.support-title {
      color: colour(black) !important;
      text-decoration: underline;
    }
  }

  .small-bevel {
    width: 100%;
    margin-top: auto;
  }

  #article-summary {
    word-wrap: break-word;
    word-break: break-word;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.support-article-img {
  width: 100%;
  object-fit: cover;
  max-height: 300px;
}
.horizontal {
  .support-info {
    h3 {
      font-size: 1.375rem;
    }
    .support-title {
      display: flex;
      align-items: flex-end;
      min-height: 44px;
    }
    .support-heading {
      color: colour(grey-3);
      font-size: 1rem;
      text-transform: initial;
      margin-bottom: 0.5rem;
    }
    .btn {
      line-height: 1.9;
      height: auto;
    }
  }
}

.spotlight-row {
  margin-bottom: 2em;

  @media (max-width: 1199px) {
    margin-right: 0;
    width: 33%;
    flex-wrap: wrap;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
}
</style>

<script>
import _ from 'lodash'
import colours from '@/mixins/colours'

const VIDEO_EMBED_URL_PREFIX = 'https://www.youtube-nocookie.com/embed'
const VIDEO_WIDTH = 560
const VIDEO_HEIGHT = 315
const YOUTUBE_EMBED_TITLE_PREFIX = 'YouTube video'

export default {
  name: 'SupportArticle',
  mixins: [colours],
  props: {
    article: {
      required: true,
      type: Object,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    colourClass: {
      type: String,
      required: false,
    },
  },
  computed: {
    embedTitle() {
      return `${YOUTUBE_EMBED_TITLE_PREFIX} - ${this.article.title}`
    },
    hasSummary() {
      return !_.isEmpty(this.article.summary)
    },
    hasCallToAction() {
      return !_.isEmpty(this.article.callToAction) && !_.isEmpty(this.article.externalUrl)
    },
    hasExternalUrl() {
      return !_.isEmpty(this.article.externalUrl)
    },
    hasArtworkUrl() {
      return !_.isEmpty(this.article.artworkUrl)
    },
    hasVideoId() {
      return !_.isEmpty(this.article.videoId)
    },
    videoURL() {
      return `${VIDEO_EMBED_URL_PREFIX}/${this.article.videoId}`
    },
    videoDimensions() {
      return {
        width: VIDEO_WIDTH,
        height: VIDEO_HEIGHT,
      }
    },
  },
}
</script>
