<script>
import NewspaperResultCommon from '@/components/search/NewspaperResultCommon'
import SearchResult from '@/components/search/SearchResult.vue'

export default {
  name: 'NewspaperResult',
  extends: SearchResult,
  mixins: [NewspaperResultCommon],
}
</script>
