<script>
import ListResultCommon from '@/components/search/ListResultCommon'
import SearchResult from '@/components/search/SearchResult.vue'

export default {
  name: 'ListResult',
  extends: SearchResult,
  mixins: [ListResultCommon],
}
</script>
