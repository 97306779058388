<template>
  <div>
    <div v-if="!dataLoaded">Loading Please wait...</div>
    <div v-else>
      <h1 v-if="lists.length > 0" class="bevel">Trending Lists</h1>
      <ListSummary
        v-for="list in lists"
        :key="list.id"
        :list="list"
        :compact="true"
        :header-route="viewListRoute(list)"
        :show-summary="true"></ListSummary>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import service from '@/service/service'
import ListSummary from '@/components/lists/ListSummary.vue'

export default {
  name: 'TrendingLists',
  components: {
    ListSummary,
  },
  data() {
    return {
      dataLoaded: false,
      lists: [],
    }
  },
  created() {
    let self = this
    self.lists.splice(0, self.lists.length)
    service.list
      .trendingLists()
      .then((trendingLists) => {
        _.each(trendingLists, (list) => {
          self.lists.push(list)
        })
        self.dataLoaded = true
      })
      .catch(
        // ignore error and don't display trending lists
        (self.dataLoaded = true)
      )
  },
  methods: {
    viewListRoute(list) {
      return {
        name: 'List',
        params: { id: list.id },
      }
    },
  },
}
</script>
