<script>
import CollectionSearchResult from '@/components/search/CollectionSearchResult.vue'
import service from '@/service/service'

export default {
  name: 'CollectionExploreResult',
  extends: CollectionSearchResult,
  methods: {
    executeSearch(self, limits, pageSize) {
      return service.explore.queryCollection(
        self.collection.id,
        self.queryToString(self.currentQuery),
        limits,
        self.currentQuery.startPos,
        pageSize,
        self.currentQuery.pageTotals,
        self.currentQuery.sortBy
      )
    },
  },
}
</script>
