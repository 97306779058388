<template>
  <div class="people-actions">
    <b-row>
      <b-col cols="12">
        <div class="d-flex">
          <b-button
            variant="primary"
            :pressed="showOptions === 'cite' && optionsOpened"
            @click="toggle('cite')"
            v-if="canCite"
            class="people-actions-cite"
            >Cite this
          </b-button>
          <div class="bevel"></div>
        </div>
      </b-col>
    </b-row>
    <Collapse
      :collapsed="!optionsOpened"
      :class="'options-panel row'"
      :full-width="true"
      ref="collapsePanel">
      <b-col cols="12" v-if="showOptions === 'cite'">
        <CiteAction
          :id="personId"
          :work-type="workType"
          :title="personName"
          @recalc-height="updateHeight">
        </CiteAction>
      </b-col>
    </Collapse>
  </div>
</template>

<style scoped lang="scss">
.people-actions {
  .btn-primary {
    flex-grow: 1;
    transition: 0.5s ease-in-out;
    @media (max-width: 575px) {
      margin-right: 0;
    }
    .active {
      background: colour(white) !important;
      color: colour(purple);
    }
    &:not(.disabled).active:focus {
      box-shadow: none;
      background: colour(white);
      color: colour(purple);
    }
    &:not(.disabled).active:hover {
      box-shadow: none;
    }
    &:not(.disabled).active {
      background: colour(white) !important;
      color: colour(purple) !important;
    }
    &:hover {
      background: colour(white);
      color: colour(purple);
    }
    &:focus {
      box-shadow: none;
    }
    .borrow-count {
      font-weight: 500;
      opacity: 0.7;
      margin-left: 0.3em;
    }
  }
}
.bevel {
  flex-grow: 2;
  width: 100%;
  height: 0;
  border-left: 0 solid #f4f4f4;
  border-top: 48px solid #f4f4f4;
  border-right: 0.80001em solid transparent;

  @media (max-width: 575px) {
    display: none;
  }
}
</style>

<script>
import _ from 'lodash'
import Collapse from '@/components/common/Collapse.vue'
import CiteAction from '@/components/citations/CiteAction.vue'
import workPeopleActions from '@/mixins/work-people-actions'

export default {
  name: 'PeopleActions',
  components: {
    Collapse,
    CiteAction,
  },
  mixins: [workPeopleActions],
  props: {
    personId: {
      type: String,
      required: true,
      default: '',
    },
    personName: {
      type: String,
      required: true,
      default: '',
    },
    workType: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      showOptions: undefined,
      optionsOpened: false,
    }
  },
  watch: {
    personOrgId() {
      this.close()
    },
  },
  computed: {
    canCite() {
      return !_.isEmpty(this.personId)
    },
  },
}
</script>

<style lang="scss" scoped>
.people-actions {
  .options-panel {
    padding-top: 20px;
    width: 100%;
  }
}
</style>
