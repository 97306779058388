<template>
  <div class="user-notification">
    <div class="date">{{ formattedDate }}</div>
    <div class="details">
      <font-awesome-icon class="tag-icon" :icon="{ prefix: 'fas', iconName: 'list' }" />
      The owner of the list "{{ details.listTitle }}" is no longer accepting contributions to this
      list. Your contributions have been removed from their list.
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-notification {
}
</style>

<script>
import AbstractNotificationDetail from './AbstractNotificationDetail'

export default {
  name: 'CollabListNoLongerCollaborative',
  extends: AbstractNotificationDetail,
}
</script>
