<template>
  <div class="versions-container">
    <h2 class="header">
      {{ isVersionSelected ? 'Other' : 'All' }} editions of this
      {{ workTypeName }}
    </h2>
    <router-link v-if="isVersionSelected" :to="allVersionsRoute" class="all-versions-link"
      >View all editions</router-link
    >

    <template v-if="allVersions">
      <FilterContainer
        :items="allVersions"
        :propertyFilterSpecifications="propertyFilterSpecifications"
        :componentFilterSpecifications="componentFilterSpecifications"
        @filter-updated="filterUpdated" />
    </template>

    <div class="versions">
      <div class="d-flex flex-wrap">
        <Version
          v-for="version in displayVersions"
          :key="version.id + 'v'"
          :version="version"
          :parentWorkId="parentWork.id"
          :includeraw="includeraw" />
      </div>
      <div class="d-flex justify-content-center mt-5">
        <b-button
          variant="primary"
          class="inverse"
          v-if="!allVersionsShown"
          @click="showAllVersions = !showAllVersions"
          >{{ showHideText }}</b-button
        >
      </div>

      <div v-if="noVersions">
        <Alert
          :alert="{
            summary: 'No versions match the selected filter criteria',
            impact: 'info',
          }"></Alert>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$arrow-height: 20px;
$button-spacing: 1rem;

.versions-container {
  margin-top: 2rem;

  .header {
    display: inline-block;
  }

  .all-versions-link {
    margin-left: 2rem;
    display: inline-block;
  }

  .versions {
    margin-top: 2rem;
  }

  .versions-buttons {
    margin-top: 1em;
    flex-wrap: wrap;
    button {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      transition: 0.5s ease-in-out;
      font-weight: 600;
      outline: none;
      &:focus {
        box-shadow: none;
      }
      .count {
        font-weight: 500;
        opacity: 0.7;
        margin-left: 0.3em;
      }
    }

    //overwrite bootstrap for responsiveness
    button.btn-primary {
      @media (max-width: 575px) {
        height: auto;
      }
    }
    .button-container {
      button {
        margin-right: 1rem;
        background: transparent !important;
        border-color: transparent !important;
        color: colour(purple);
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      &:last-child {
        button {
          margin-right: 0rem;
        }
        .arrow-container {
          margin-right: 0rem;
        }
      }

      .arrow-container {
        margin-right: 1rem;

        .arrow {
          width: 0;
          height: 0;
          border-left: 16px solid transparent;
          border-bottom: $arrow-height solid colour(grey);
          border-right: 16px solid transparent;
          margin-top: 0;
          position: relative;
          margin-left: auto;
          margin-right: auto;
        }
      }

      @media (max-width: 575px) {
        margin-top: 1.3rem;
        text-align: left;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import colours from '@/mixins/colours'
import currentRoute from '@/mixins/currentRoute'
import filtering from '@/mixins/filtering'
import mappings from '@/mixins/mappings'
import Version from '@/components/work/Version.vue'
import Alert from '@/components/Alert.vue'
import LanguageFilter from '@/components/work/LanguageFilter.vue'
import DateFilter from '@/components/work/DateFilter.vue'
import FilterContainer from '@/components/work/FilterContainer.vue'

let versionsToShow = 9

export default {
  name: 'Versions',
  mixins: [colours, filtering, mappings, currentRoute],
  components: {
    Version,
    Alert,
    FilterContainer,
  },
  props: {
    allVersions: {
      type: Array,
      required: true,
    },
    parentWork: {
      type: Object,
      required: true,
    },
    selectedVersionId: {
      type: String,
      required: false,
    },
    dateRange: {
      type: Object,
      required: false,
    },
    includeraw: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAllVersions: false,
      filteredVersions: this.allVersions,
    }
  },
  methods: {
    defaultFilterValues(query, limit, first) {
      let values = _(query)
        .at(limit)
        .filter((val) => val)
        .value()
      return _.isEmpty(values) ? [] : first ? values[0] : values
    },
    defaultDateRange(query, decadeLimit, yearLimit, fullDateRange) {
      let years = _(this.defaultFilterValues(query, yearLimit)).compact().flatten().value()
      let decades = _(this.defaultFilterValues(query, decadeLimit))
        .compact()
        .flatten()
        .map((d) => [d + '0', d + '9'])
        .flatten()
        .value()

      years = _.union(years, decades)
      years.sort()

      if (!_.isEmpty(years)) {
        return { start: _.head(years), end: _.last(years) }
      } else {
        return Object.assign({}, fullDateRange, { full: true })
      }
    },
    filterUpdated(filteredVersions) {
      this.filteredVersions = filteredVersions
    },
    versionsByType(versions) {
      var allFormats = _.reduce(
        versions,
        (formats, version) => {
          return _(formats).concat(version.format).uniq().value()
        },
        []
      )

      return _.map(allFormats, (format) => {
        var matchingVersions = _.filter(versions, (version) => _.includes(version.format, format))

        return {
          type: format,
          versions: matchingVersions,
          count: matchingVersions.length,
          display: format + ' (' + matchingVersions.length + ')',
        }
      })
    },
    getLanguageFieldValues(field) {
      return _(this.defaultFilterValues(this.currentQuery, field))
        .flatten()
        .map((v) => _.toLower(v))
        .compact()
        .value()
    },
    getTypeFieldValues(field) {
      var self = this
      return _(this.defaultFilterValues(this.currentQuery, field))
        .compact()
        .flatten()
        .map((v) => decodeURIComponent(v))
        .map((type) => {
          return _.find(self.allVersionsByType, (version) => version.type === type)
        })
        .filter((type) => type)
        .value()
    },
  },
  computed: {
    workTypeName() {
      if (this.parentWork && this.parentWork.format) {
        return this.mapWorkTypeName(this.parentWork.format)
      }

      return 'work'
    },
    isVersionSelected() {
      return !_.isUndefined(this.selectedVersionId)
    },
    allVersionsRoute() {
      return {
        name: 'Work',
        params: {
          id: this.parentWork.id,
        },
        query: this.includeraw
          ? Object.assign({}, this.currentQuery, { includeraw: 'y' })
          : this.currentQuery,
      }
    },
    propertyFilterSpecifications() {
      var typeDefaultSelected = _.concat(
        this.getTypeFieldValues('l-format'),
        this.getTypeFieldValues('l-advformat')
      )

      return [
        {
          name: 'Type',
          options: this.allVersionsByType,
          placeholder: 'Filter by type',
          label: 'display',
          valueField: 'type',
          defaultSelected: typeDefaultSelected,
          filter: (item, selectedOptions) => {
            if (_.isEmpty(selectedOptions)) {
              return true
            }

            return !_.isEmpty(
              _.filter(selectedOptions, (selectedOption) =>
                _.includes(item.format, selectedOption.type)
              )
            )
          },
        },
      ]
    },
    componentFilterSpecifications() {
      var self = this
      let languageDefaultSelected = _.concat(
        this.getLanguageFieldValues('l-language'),
        this.getLanguageFieldValues('l-advlanguage')
      )
      let dateDefaultSelected = this.defaultDateRange(
        this.currentQuery,
        'l-decade',
        'l-year',
        this.dateRange
      )

      return [
        {
          name: 'Languages',
          component: LanguageFilter,
          props: {
            languages: self.languages,
            defaultSelected: languageDefaultSelected,
          },
          predicate: () => !_.isEmpty(self.languages) && _.keys(self.languages).length > 1,
          filter: (item, selectedLanguages) => {
            return self.languageFiltering(selectedLanguages, item)
          },
        },
        {
          name: 'Date range',
          component: DateFilter,
          props: {
            defaultSelected: dateDefaultSelected,
          },
          filter: (item, startYear, endYear) => {
            return self.dateFiltering(startYear, endYear, item)
          },
        },
      ]
    },
    filteredLanguages() {
      return !this.filteredVersions
        ? []
        : _.countBy(
            _(this.filteredVersions)
              .map((version) => version.language)
              .value()
          )
    },
    languages() {
      return !this.allVersions
        ? []
        : _.countBy(
            _(this.allVersions)
              .map((version) => version.language)
              .value()
          )
    },
    noVersions() {
      return _.isEmpty(this.displayVersions)
    },
    displayVersions() {
      let numVersionsToShow = this.showAllVersions
        ? this.filteredVersions.length
        : Math.min(versionsToShow, this.filteredVersions.length)
      return _(this.filteredVersions).slice(0, numVersionsToShow).value()
    },
    allVersionsByType() {
      return this.versionsByType(this.allVersions)
    },
    filteredVersionsByType() {
      return this.versionsByType(this.filteredVersions)
    },
    allVersionsShown() {
      return this.filteredVersions.length <= versionsToShow
    },
    someVersionsNotShown() {
      return this.filteredVersions.length > versionsToShow && !this.showAllVersions
    },
    showHideText() {
      return this.someVersionsNotShown ? 'Show all' : 'Hide all'
    },
  },
}
</script>
