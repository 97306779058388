<template>
  <div class="user-notification">
    <div class="date">{{ formattedDate }}</div>
    <div class="details">
      <font-awesome-icon class="tag-icon" :icon="{ prefix: 'fas', iconName: 'list' }" />
      <span v-if="details.newNotesCount === 1">Someone has added a note</span>
      <span v-if="details.newNotesCount > 1"
        >{{ details.newNotesCount }} notes have been added</span
      >
      to the list "<router-link :to="listRoute" @click.native="clearNotification">{{
        details.listTitle
      }}</router-link
      >".
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-notification {
  ul {
    padding-left: 3rem;
  }
}
</style>

<script>
import AbstractNotificationDetail from './AbstractNotificationDetail'

export default {
  name: 'NotesChanged',
  extends: AbstractNotificationDetail,
  computed: {
    listRoute() {
      return {
        name: 'List',
        params: { id: this.details.listId },
      }
    },
  },
}
</script>
