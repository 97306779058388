<template>
  <div class="results-container d-flex flex-wrap">
    <template>
      <div
        class="d-flex flex-row flex-wrap"
        :style="rowStyle"
        v-for="(row, rowIndex) in chunkedResults"
        :key="rowIndex">
        <component
          :is="resultComponent(result)"
          v-for="(result, colIndex) in row"
          :key="result ? result.id : colIndex"
          :result="result"
          :isSummary="isSummary"
          :isResultSelected="isResultSelected(result)"
          :canSelect="canSelect"
          @selected="resultSelected" />

        <div style="width: 1000%"></div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>

<script>
import _ from 'lodash'

import ImageResult from '@/components/search/ImageResult.vue'
import RelatedItem from '@/components/people/RelatedItem.vue'
import NewspaperImageResult from '@/components/search/NewspaperImageResult.vue'
import PeopleImageResult from '@/components/search/PeopleImageResult.vue'
import ListImageResult from '@/components/search/ListImageResult.vue'

const imageWidth = 200

const resultMapping = {
  newspaper: { component: NewspaperImageResult },
  people: { component: PeopleImageResult },
  list: { component: ListImageResult },
}

export default {
  name: 'ImageResultContainer',
  isContainer: true,
  components: {
    ImageResult,
    RelatedItem,
  },
  props: {
    resultTemplate: {
      type: String,
      required: false,
    },
    results: {
      type: Array,
      required: true,
    },
    isSummary: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedResultIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    canSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      containerWidth: 0,
      lastCols: 0,
      isMobile: false,
    }
  },
  mounted() {
    this.updateWidths()
    this.isMobileDevice()
    window.addEventListener('resize', this.updateWidths)
    window.addEventListener('resize', this.isMobileDevice)
  },
  computed: {
    chunkedResults() {
      return _(this.results).chunk(this.cols).value()
    },
    rows() {
      return this.chunkedResults.length || 1
    },
    cols() {
      return Math.floor(this.containerWidth / imageWidth) || 1
    },
    rowWidth() {
      if (this.isMobile) {
        return '50%'
      }
      return this.cols * imageWidth + 'px'
    },
    rowStyle() {
      return {
        width: this.rowWidth,
        'margin-top': '1.5rem',
        position: 'relative',
      }
    },
    noResults() {
      return !this.chunkedResults || this.chunkedResults.length === 0
    },
  },
  methods: {
    resultComponent(work) {
      if (this.resultTemplate) {
        return this.resultTemplate
      }

      var mapping = resultMapping[work ? work.workType : null]
      return mapping ? mapping.component : ImageResult
    },
    updateWidths() {
      this.containerWidth = this.$el.offsetWidth
    },
    isResultSelected(result) {
      return Boolean(_.find(this.selectedResultIds, (id) => id === result.id))
    },
    resultSelected(result, selected) {
      this.$emit('selected', result, selected)
    },
    isMobileDevice() {
      if (document.documentElement.clientWidth < 575) {
        return (this.isMobile = true)
      }
      return (this.isMobile = false)
    },
  },
}
</script>
