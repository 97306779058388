<template>
  <div class="list-item-note-container mt-4">
    <h4 class="heading">Notes</h4>
    <b-link class="toggle-note"
      ><div @click="toggleNote">{{ toggleNoteLabel }}</div></b-link
    >
    <div class="note" :style="containerStyle" ref="note">
      <div class="content">
        <div>
          <span class="author">
            <UserThumbnail
              v-if="item.createdBy"
              :user-summary="item.createdBy"
              size="small"
              :link-username="!item.createdBy.anonymous"></UserThumbnail>
          </span>
          <span class="date"
            ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'calendar' }" />
            {{ formattedDate(item.updated) }}</span
          >

          <span class="edit">
            <b-button
              variant="link"
              v-if="item.noteDeletable"
              @click="deleteNote"
              title="Delete note"
              :disabled="disableControls">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'minus-circle' }" />
            </b-button>
            <b-button
              variant="link"
              v-if="item.noteEditable"
              @click="toggleEditPanel"
              title="Edit note"
              :disabled="disableControls">
              <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'edit' }" />
            </b-button>
          </span>
          <div v-if="!editPanelOpen">
            <span v-html="sanitizeHtml(item.note)"></span>
          </div>
          <div v-if="editPanelOpen">
            <div class="error" v-if="error && error.summary">
              <Alert :alert="error"></Alert>
            </div>
            <EditListItemNote
              :existing="item"
              @save="save"
              :in-progress="saving"
              @cancel="toggleEditPanel"></EditListItemNote>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import auth from '@/mixins/auth'
import lists from '@/mixins/lists'
import utils from '@/mixins/utils'
import UserThumbnail from '@/components/profile/UserThumbnail'
import EditListItemNote from './EditListItemNote'
import service from '@/service/service'
import Alert from '@/components/Alert.vue'

export default {
  name: 'ListItemNote',
  mixins: [auth, lists, utils],
  components: {
    Alert,
    EditListItemNote,
    UserThumbnail,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showNote: true,
      containerStyle: {},
      initialHeight: 0,
      editPanelOpen: false,
      error: undefined,
      saving: false,
      disableControls: false,
    }
  },
  mounted() {
    let self = this

    self.initialHeight = self.$refs.note ? self.$refs.note.scrollHeight + 'px' : 'auto'
    self.updateContainerStyle()

    if (window.MutationObserver) {
      let observer = new MutationObserver(() => {
        self.updateContainerStyle()
      })

      observer.observe(self.$el.querySelector('.content'), {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
        attributeFilter: ['class'],
      })
    }
  },
  computed: {
    toggleNoteLabel() {
      return this.showNote ? 'Hide notes' : 'Show notes'
    },
  },
  methods: {
    toggleNote() {
      this.showNote = !this.showNote
    },
    toggleEditPanel() {
      this.editPanelOpen = !this.editPanelOpen
      this.disableControls = this.editPanelOpen
    },
    deleteNote() {
      let self = this
      self.disableControls = true
      self.$bvModal
        .msgBoxConfirm('Are you sure you wish to remove this note?', {
          cancelVariant: 'link',
        })
        .then((result) => {
          if (result) {
            self.item.note = ''
            self.updateNote(self.item)
          } else {
            self.disableControls = false
          }
        })
    },
    save(item) {
      let self = this
      self.saving = true
      this.updateNote(item)
    },
    updateNote(item) {
      let self = this
      service.list
        .updateListItemNote(item.listId, item.id, item)
        .then((result) => {
          _.assign(self.item, result)
          self.saving = false
          self.toggleEditPanel()
        })
        .catch((error) => {
          self.saving = false
          self.handleError(error, 'note', undefined, 'saving your note')
        })
    },
    updateContainerStyle() {
      this.containerStyle = {
        display: 'block',
        overflow: 'hidden',
        height: this.showNote
          ? this.disableControls
            ? this.$refs.note.scrollHeight + 'px'
            : this.initialHeight
          : 0,
        transition: 'height 1s ease',
      }
    },
  },
  watch: {
    showNote() {
      Vue.nextTick(() => {
        this.updateContainerStyle()
      })
    },
    disableControls() {
      Vue.nextTick(() => {
        this.updateContainerStyle()
      })
    },
  },
}
</script>

<style scoped lang="scss">
h4 {
  font-size: 1.375rem;
  font-weight: 400;
}

.heading {
  display: inline-block;
}

.toggle-note {
  font-weight: 400;
  margin-left: 2rem;
}

.note {
  overflow: auto;
  font-size: 1rem;
  background-color: colour(grey);

  .content {
    padding: 1em;
  }

  .author,
  .date {
    color: colour(black);
    padding-left: 0;
    padding-right: 1.5em;
    font-weight: 600;

    svg {
      margin-right: 0.5em;
    }
  }

  .body {
    margin-top: 1rem;
  }
}
</style>
