<template>
  <fragment>
    <div class="list-item-container" v-if="loading">
      <div class="d-flex stub">
        <div class="thumbnail"></div>
        <div class="body">
          <div class="line" style="width: 30%"></div>
          <div class="line" style="width: 60%"></div>
          <div class="block"></div>
          <div class="line" style="width: 20%"></div>
        </div>
      </div>
    </div>
    <div class="list-item-container" v-else>
      <div class="result d-flex flex-column">
        <div class="d-flex">
          <template v-if="item.missing">
            <div class="body">
              <div
                class="list-seq d-flex flex-inline align-items-end handle sequence"
                v-if="canReorder">
                <EditableText
                  :value="String(item.listSeq)"
                  :validationRules="sequenceValidationRules"
                  inputWidth="3rem"
                  @input="sequenceChanged"></EditableText>
                <div>
                  <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'arrows-alt' }" />
                </div>
              </div>
              <div>
                <span class="format"
                  ><font-awesome-icon :icon="formatIcon" />{{ missingItemType }}</span
                >
                <span class="meta missing">
                  - The resource described by this item (id:
                  {{ missingIdentifier }}) has been deleted from Trove.</span
                >
              </div>
            </div>
          </template>
          <template v-else>
            <div class="thumbnail-column">
              <b-link v-if="!isWebsite && deliverySystemLink" :href="deliverySystemLink">
                <Thumbnail
                  :url="item.imageUrl"
                  :title="'Thumbnail for ' + title"
                  size="small"
                  :show-placeholder="false"
                  :blur="blurThumbnail"></Thumbnail>
              </b-link>
              <router-link :to="linkDescriptor" v-if="!isWebsite && !deliverySystemLink">
                <Thumbnail
                  :url="item.imageUrl"
                  :title="'Thumbnail for ' + title"
                  size="small"
                  :show-placeholder="false"
                  :blur="blurThumbnail"></Thumbnail>
              </router-link>
            </div>
            <div class="body">
              <template v-if="isWebsite">
                <div
                  class="list-seq d-flex flex-inline align-items-end handle sequence"
                  v-if="canReorder">
                  <EditableText
                    :value="String(item.listSeq)"
                    :validationRules="sequenceValidationRules"
                    inputWidth="3rem"
                    @input="sequenceChanged"></EditableText>
                  <div>
                    <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'arrows-alt' }" />
                  </div>
                </div>
                <h1 v-if="linkStartsWith">
                  <b-link
                    v-if="deliverySystemLink "
                    :href="deliverySystemLink"  
                    :rel="'ugc'"
                    v-html="title"></b-link>
                </h1>
                <h1 v-else>
                  {{title}}
                </h1>
                <div>
                  <span class="format"><font-awesome-icon :icon="formatIcon" />{{ format }}</span>
                  <span class="meta url">- {{ deliverySystemLink }}</span>
                </div>

                <ListItemNote v-if="hasNote" :item="item" />
                <AddListItemNote
                  v-if="!hasNote && item.noteEditable"
                  :item="item"
                  @item-updated="update" />
              </template>
              <template v-else>
                <div
                  class="list-seq d-flex flex-inline align-items-end handle sequence"
                  v-if="canReorder">
                  <EditableText
                    :value="String(item.listSeq)"
                    :validationRules="sequenceValidationRules"
                    inputWidth="3rem"
                    @input="sequenceChanged"></EditableText>
                  <div>
                    <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'arrows-alt' }" />
                  </div>
                </div>
                <h1>
                  <b-link
                    v-if="deliverySystemLink"
                    :href="deliverySystemLink"
                    v-html="title"                    
                    v-line-clamp="2"></b-link>
                  <router-link
                    v-if="!deliverySystemLink"
                    :to="linkDescriptor"
                    v-html="title"
                    v-line-clamp="2"></router-link>
                </h1>
                <div :class="item.type">
                  <span class="format"><font-awesome-icon :icon="formatIcon" />{{ format }}</span>
                  <template v-if="isNewspaper">
                    <span class="meta newspaper" v-if="isNewspaper">- {{ item.newspaper }}:</span>
                    <span class="meta date"
                      ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'calendar' }" />
                      {{ item.date }}</span
                    >
                    <span class="meta page">- Page {{ item.page }}</span>
                  </template>
                  <template v-if="isWork">
                    <span> - </span
                    ><span class="meta num-editions" v-if="hasEditions">{{ editionText }} </span
                    ><span class="meta date" v-if="yearRange">{{ yearRange }}</span>
                    <span class="author" v-if="author && showAuthor"
                      ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'user' }" />{{
                        author
                      }}</span
                    >
                    <div class="meta">
                      <OnlineAvailability :availability="item.onlineText" />
                      <span class="contributor" v-if="showContributor"
                        ><font-awesome-icon
                          :icon="{ prefix: 'fas', iconName: 'location-arrow' }" />{{
                          contributorText
                        }}</span
                      >
                      <FirstAustraliansIndicator
                        v-if="item.firstAustraliansInd"></FirstAustraliansIndicator>
                    </div>
                  </template>
                  <template v-if="isPerson">
                    <span class="meta occupations" v-if="hasOccupations">- {{ occupations }}</span>
                  </template>

                  <div
                    class="description meta"
                    v-if="hasDescription"
                    v-html="item.description"></div>
                  <div class="description meta" v-if="hasAbstract" v-html="abstract"></div>

                  <Tags :work="item" :read-only="true" :headerless="true" :verbose="false" />

                  <ListItemNote v-if="hasNote" :item="item" />
                  <AddListItemNote
                    v-if="!hasNote && item.noteEditable"
                    :item="item"
                    @item-updated="update" />
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="buttons">
          <div class="metadata" v-if="canModify">
            <span v-if="notMe">
              This item was added to the list by
              <UserThumbnail
                :user-summary="item.createdBy"
                v-if="item.createdBy"
                :show-image="false"
                :link-username="!item.createdBy.anonymous"></UserThumbnail>
            </span>
            <span v-if="!notMe">You added this item to the list</span>
            on {{ item.created }}
          </div>
          <b-button variant="link" @click="remove" v-if="canRemove"
            ><span class="remove"
              >Remove from list
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'trash' }" /></span
          ></b-button>
        </div>
      </div>
    </div>
  </fragment>
</template>

<style scoped lang="scss">
$thumbnail-dimension: 80px;
$thumbnail-max-aspect-ratio: 1.5;

.stub {
  .thumbnail {
    width: $thumbnail-dimension;
    height: $thumbnail-dimension * $thumbnail-max-aspect-ratio;
    margin-right: 1em;
  }

  .body {
    width: 100%;
  }
}

.result {
  .buttons {
    .remove {
      svg {
        margin-left: 0.3rem;
      }
    }

    .metadata {
      padding-top: 0.75rem;
      padding-left: 0.75rem;
    }
  }

  margin-bottom: 1.5em;
  padding-bottom: 1.3em;
  border-bottom: 1px solid colour(grey-5);

  .format {
    font-weight: 700;
    color: colour(black);

    svg {
      margin-right: 0.5em;
      font-size: 1.1rem;
    }
  }

  .body {
    width: 100%;

    .sequence {
      div {
        margin-left: 0.5rem;
      }
    }
  }

  .thumbnail-column {
    margin-right: 1em;
  }

  h1 {
    font-family: 'Source Serif Pro', serif;
    font-size: 1.375rem;
    font-weight: 600;
    color: colour(black);
    line-height: 1.3;

    a {
      word-break: break-word !important;
    }
  }

  @supports (font-variation-settings: normal) {
    h1 {
      font-family: 'Source Serif Variable', serif;
    }
  }

  .description {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: colour(grey-3);

    em {
      font-weight: bold;
      font-style: normal;
    }
  }

  .date {
    color: colour(black);
  }

  .newspaper,
  .url,
  .date,
  .page,
  .occupations,
  .missing {
    padding-left: 0;
    padding-right: 0.5em;
    font-weight: 400;
  }

  .meta {
    margin-top: 1em;
  }

  .newspaper {
    .meta.date {
      color: colour(violet);
      font-weight: 600;
    }
  }

  .online-availability {
    margin-right: 1rem;
  }

  .list-seq {
    float: right;
    font-size: 1.6em;
    color: colour(grey-4);
    padding: 0 0.5em 0 0.5em;
  }
}
</style>

<script>
import _ from 'lodash'
import pluralize from 'pluralize/pluralize'
import utils from '@/mixins/utils'
import auth from '@/mixins/auth'
import mappings from '@/mixins/mappings'
import colours from '@/mixins/colours'
import cultural from '@/mixins/cultural'
import Thumbnail from '@/components/Thumbnail.vue'
import UserThumbnail from '@/components/profile/UserThumbnail'
import ListItemNote from '@/components/lists/ListItemNote'
import Tags from '@/components/work/Tags.vue'
import FirstAustraliansIndicator from '@/components/FirstAustraliansIndicator.vue'
import OnlineAvailability from '@/components/search/OnlineAvailability'
import AddListItemNote from './AddListItemNote'
import service from '../../service/service'
import EditableText from '@/components/EditableText'
import { extend } from 'vee-validate'
import { between } from 'vee-validate/dist/rules'

extend('sequenceValid', {
  ...between,
  message: () => 'List sequence must be valid',
})

export default {
  name: 'ListItem',
  mixins: [mappings, utils, colours, auth, cultural],
  components: {
    AddListItemNote,
    OnlineAvailability,
    ListItemNote,
    Tags,
    UserThumbnail,
    Thumbnail,
    FirstAustraliansIndicator,
    EditableText,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    listSize: {
      type: Number,
      required: true,
    },
    listAuthor: {
      type: String,
      required: true,
    },
    troveNewspapersUrlPrepend: {
      type: String,
      required: false,
      default: '/newspaper/article/',
    },
    listCollaborator: {
      type: Boolean,
      required: false,
    },
    listAdministrator: {
      type: Boolean,
      required: false,
    },
    listOwner: {
      type: Boolean,
      required: false,
    },
    canModify: {
      type: Boolean,
      required: false,
      default: false,
    },
    canReorder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    loading() {
      return _.isEmpty(this.item) || _.isEmpty(this.item.type)
    },
    sequenceValidationRules() {
      return {
        sequenceValid: {
          min: 1,
          max: this.listSize,
        },
      }
    },
    isWebsite() {
      return this.item.type === 'web page'
    },
    isNewspaper() {
      return this.item.type === 'newspaper'
    },
    isWork() {
      return this.item.type === 'work'
    },
    isPerson() {
      return this.item.type === 'person'
    },
    isVersion() {
      return this.item.type === 'version'
    },
    missingIdentifier() {
      return this.item.target.substring(1)
    },
    missingItemType() {
      return _.startCase(this.item.type)
    },
    showAuthor() {
      return true
    },
    author() {
      let self = this
      let author = ''

      if (self.item.author) {
        if (self.item.author.indexOf(';') > -1) {
          let authors = _.split(self.item.author, ';')
          if (authors.length > this.creatorDisplayLimit) {
            author =
              _.join(_.take(authors, this.creatorDisplayLimit), '; ') +
              ' +' +
              (authors.length - this.creatorDisplayLimit) +
              ' more'
          } else {
            author = _.join(_.take(authors, this.creatorDisplayLimit), '; ')
          }
        } else {
          author = self.item.author
        }
      }

      return author
    },
    yearRange() {
      return this.item.yearRange
    },
    hasDescription() {
      return !_.isEmpty(this.item.description)
    },
    hasAbstract() {
      return this.item.abstrct
    },
    abstract() {
      return (
        '<strong>Abstract</strong> ... ' +
        this.item.abstrct +
        ' ...' +
        (this.item.wordCount ? ' ' + this.item.wordCount + ' words' : '')
      )
    },
    hasOccupations() {
      return this.item.occupations
    },
    title() {
      if (this.isWebsite) {
        return this.item.description ? this.item.description : ''
      }

      if (this.isPerson) {
        return this.item.primaryDisplayName ? this.item.primaryDisplayName : ''
      }

      return this.item.title ? this.item.title : ''
    },
    occupations() {
      let self = this
      let occupations = ''

      if (self.item.occupations) {
        return this.item.occupations.join('; ')
      }

      return occupations
    },
    blurThumbnail() {
      return this.item.thumbnailIsCulturallySensitive && this.suppressContent(this.item.workId)
    },
    deliverySystemLink() {
      if (this.isNewspaper) {
        return this.troveNewspapersUrlPrepend + this.item.target.substring(1)
      }

      return this.item.deliverySystemLink ? this.item.deliverySystemLink : null
    },
    linkStartsWith() {
      const regexp = new RegExp('^https?:\/\/(webarchive|pandora)\.nla.gov.au\/', 'i');
      if (regexp.test(this.item.deliverySystemLink)) {
        return true;
      }

      return false;
    },
    linkDescriptor() {
      if (this.isPerson) {
        return {
          name: 'People',
          params: {
            id: this.item.workId,
          },
        }
      }

      if (this.isVersion) {
        return {
          name: 'VersionRedirect',
          params: {
            id: this.item.workId,
          },
        }
      }

      if (this.item.missing) {
        return {
          name: 'Work',
          params: {
            id: this.missingIdentifier,
          },
        }
      }

      return {
        name: 'Work',
        params: {
          id: this.item.workId,
        },
      }
    },
    format() {
      return this.mapFormat(this.item.formatDisplay)
    },
    formatIcon() {
      return this.mapFormatIcon(this.format, this.defaultFormatIcon)
    },
    defaultFormatIcon() {
      if (this.isNewspaper) {
        return { prefix: 'fas', iconName: 'newspaper' }
      }

      return { prefix: 'fas', iconName: 'list' }
    },
    hasNote() {
      return !_.isEmpty(this.item.note)
    },
    showContributor() {
      return this.item.numHoldings === 1 && this.item.singleHoldingName
    },
    onlineText() {
      return this.item.onlineText
    },
    contributorText() {
      if (this.item.numHoldings === 1) {
        return 'At ' + this.item.singleHoldingName
      }

      return ''
    },
    hasEditions() {
      return this.item.numItems > 1
    },
    editionText() {
      return this.item.numItems + ' ' + pluralize('edition', this.item.numItems) + ':'
    },
    canRemove() {
      return this.canModify && (this.addedByMe || this.listAdministrator || this.listOwner)
    },
    addedByMe() {
      return (
        this.item.createdBy &&
        (this.item.createdBy.fullUserId === this.simpleUsername ||
          this.item.createdBy.fullUserId === this.fullUsername)
      )
    },
    notMe() {
      return !this.item.createdBy || !this.addedByMe
    },
    creatorRoute() {
      return {
        name: 'PublicProfile',
        params: { username: this.item.createdBy.fullUserId },
      }
    },
  },
  methods: {
    remove() {
      this.$emit('item-removed', this.item)
    },
    sequenceChanged(newSequence) {
      this.$emit('item-moved', this.item, parseInt(newSequence))
    },
    update(item) {
      let self = this
      self.saving = true
      service.list
        .updateListItemNote(item.listId, item.id, item)
        .then((result) => {
          _.assign(self.item, result)
          self.saving = false
        })
        .catch((error) => {
          self.saving = false
          self.handleError(error, 'note', undefined, 'saving item note')
        })
    },
  },
}
</script>
