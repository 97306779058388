<template>
    <b-form-group v-if="display" :label="display" label-cols="12" :label-cols-sm="labelWidth">
        <div class="d-flex flex-wrap subFieldContainer">
            <SubField v-for="(subField, i) in subFields"
                      :class="{ 'flex-grow-1': subField.grow, 'last': isLastSubField(i) }"
                      :key="subField.name"
                      :subField="subField"
                      @updated="updated"
                      @clear-handler="clearHandler"/>
        </div>
    </b-form-group>
</template>

<style scoped lang="scss">
 legend {
     font-weight: bold;
 }

 .second-col {
     margin-left: 1rem;
 }

 .subFieldContainer {
     div {
         margin-right: 1rem;
     }

     .last {
         margin-right: 0;
     }
 }
</style>


<script>
 import SubField from '@/components/search/advanced/SubField.vue'

 export default {
     name: 'SearchField',
     components: {
         SubField
     },
     props: {
         display: {
             type: String,
             required: true
         },
         subFields: {
             type: Array,
             required: true
         },
         labelWidth: {
             type: Number,
             required: false,
             default: 2
         }
     },
     data() {
         return {};
     },
     computed: {
         hasModifier() {
             return this.type === "phrase";
         }
     },
     methods: {
         clearHandler(handler) {
             this.$emit("clear-handler", handler);
         },
         isLastSubField(i) {
             return i === this.subFields.length - 1;
         },
         updated(subFieldName, subFieldValue) {
             this.$emit("updated", subFieldName, subFieldValue);
         }
     }
 }
</script>
