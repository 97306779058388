<template>
  <section>
    <h2 v-if="homePage" class="bevel blue">Trove Spotlight</h2>
    <h2 v-else class="bevel blue">{{ title }}</h2>

    <b-container fluid class="spotlight-container">
      <b-row>
        <b-col xl="8" md="12" sm="12">
          <article class="main-article-container">
            <div class="main-article-image-container">
              <div>
                <div class="main-image-overlay" :class="wedgeColour">
                  <b-link :href="mainArticle.externalUrl">
                    <h3 class="support-title">{{ mainArticle.title }}</h3>
                  </b-link>
                  <p class="support-heading flex-grow-1">
                    {{ mainArticle.catchline }}
                  </p>
                </div>
                <b-img
                  :alt="mainArticle.artworkImageAlt"
                  :src="mainArticle.artworkUrl"
                  class="support-main-img"
                  aria-hidden="true"></b-img>
              </div>
            </div>
            <div class="partner-logos" v-if="multipleMainArticleLogos">
              <b-img
                v-for="(logo, index) in partnerLogos"
                class="logo"
                :src="logo"
                :alt="mainArticle.logoAlt[index]"
                :key="mainArticle.logoAlt[index] + index"></b-img>
            </div>
            <div class="description mt-4 mb-4" v-if="mainArticle.summary">
              <div class="single-partner-logo" v-if="singleMainArticleLogo">
                <b-img
                  class="logo"
                  :src="mainArticle.logoImage[0]"
                  :alt="mainArticle.logoAlt[0]"></b-img>
              </div>
              <div class="article-summary">
                <p v-html="mainArticle.summary"></p>
                <a class="p-0" variant="link" :href="mainArticle.externalUrl"
                  ><span class="sr-only"> {{ mainArticle.title }} - </span>Read more
                  <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'arrow-right' }"
                /></a>
              </div>
            </div>
          </article>
        </b-col>
        <b-col xl="4" md="12" sm="12">
          <div class="responsive-article" aria-role="list">
            <SupportArticle
              v-for="(article, index) in items.slice(0, 3)"
              :article="article"
              :key="article.id"
              :colour-class="articleColours[index]" />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="canDisplayPreviousFeatures" class="previous d-flex align-content-center">
      <div sm="8" class="bevel flex-grow-1"></div>
      <div sm="4" class="previous-spotlights">
        <b-link :href="newsLink">See previous spotlights</b-link>
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash'
import service from '@/service/service'
import colours from '@/mixins/colours'
import SupportArticle from '@/components/landing/SupportArticle'

export default {
  name: 'FeaturedArticles',
  mixins: [colours],
  components: {
    SupportArticle,
  },
  props: {
    mainArticle: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    items: {
      type: Array,
      required: true,
    },
    homePage: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      globalConfig: {
        navigation: [],
      },
    }
  },
  created() {
    let self = this
    service.config.global().then(function (gc) {
      _.forOwn(gc, function (val, key) {
        self.$set(self.globalConfig, key, val)
        _.forOwn(val, function (val2, key2) {
          self.$set(self.globalConfig[key], key2, val2)
        })
      })
    })
  },
  computed: {
    canDisplayPreviousFeatures() {
      return !!_.find(this.globalConfig.navigation, { title: 'News' }) && this.homePage
    },
    newsLink() {
      const newsNav = _.find(this.globalConfig.navigation, { title: 'News' })
      return newsNav.url
    },
    singleMainArticleLogo() {
      return this.mainArticle.logoImage && this.mainArticle.logoImage.length === 1
    },
    multipleMainArticleLogos() {
      return this.mainArticle.logoImage && this.mainArticle.logoImage.length > 1
    },
    partnerLogos() {
      return this.multipleMainArticleLogos ? _.take(this.mainArticle.logoImage, 5) : []
    },
    articleColours() {
      return ['green', 'light-blue', 'yellow', 'magenta']
    },
    wedgeColour() {
      switch (this.mainArticle.bannerColours) {
        case 'yellow-purple':
          return 'purple'
        case 'lightblue-purple':
          return 'blue'
        case 'magenta-purple':
          return 'magenta'
        case 'green-purple':
          return 'green'
        default:
          return 'purple'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.landing-heading {
  padding: 10px;
  background-color: colour(yellow);
  color: colour(purple);
  font-family: 'Source Sans Pro', sans-serif;
}

@supports (font-variation-settings: normal) {
  .landing-heading {
    font-family: 'Source Sans Variable', sans-serif;
  }
}

h2.bevel {
  margin-bottom: 2em;
  font-size: 1.5rem;
  font-family: 'Source Sans Pro', sans-serif;
  &:before {
    top: 0.14em !important;
  }
}
@supports (font-variation-settings: normal) {
  h2.bevel {
    font-family: 'Source Sans Variable', sans-serif;
  }
}

.spotlight-container {
  padding: 0;

  .responsive-article {
    @media (max-width: 1199px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 3rem;
      justify-content: space-between;
    }

    @media (min-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    //test
    @media (min-width: 1200px) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
    }
  }

  .main-article-container {
    .main-article-image-container {
      position: relative;

      .logo {
        max-height: 2rem;
        max-width: 10rem;
      }
    }

    .partner-logos {
      padding-top: 2rem;
      padding-bottom: 2rem;

      .logo {
        max-width: 120px;
        margin-right: 2rem;
      }
    }

    .description {
      font-size: large;
      padding-left: 52px;
      padding-right: 2em;
      display: flex;
      flex-direction: row;
      p {
        max-height: 110px;
        overflow: hidden;
        max-width: 680px;
      }
      a {
        font-size: 18px;
        &:hover,
        &:focus {
          color: colour(blue);
          text-decoration: underline;
        }
        svg {
          margin-left: 0.3em;
        }
      }

      .single-partner-logo {
        margin-right: 2rem;
        flex: 0;

        .logo {
          max-width: 120px;
        }
      }

      .article-summary {
        flex: 1;
      }

      @media (max-width: 575px) {
        padding: 0 15px 0 15px;
      }
    }
  }

  .main-image-overlay {
    @include bevel-div();

    z-index: 1;
    width: 40%;
    position: absolute;
    bottom: 0em;
    padding: 2rem;
    padding-right: 0rem;
    padding-left: 52px;
    color: colour(white);

    h2,
    h3 {
      color: colour(white);
    }
    .support-title {
      font-weight: 600;
      font-size: 2.3125rem;
    }
    a {
      &:hover,
      &:focus {
        text-decoration: underline;
        color: colour(white);
      }
    }

    @media (max-width: 991px) {
      width: 70%;
    }

    @media (max-width: 575px) {
      position: relative;
      padding: 1.5rem;
      width: 100%;
      overflow: hidden;
    }
  }

  .support-main-img {
    height: 600px;
    width: 100%;
    object-fit: cover;

    @media (max-width: 575px) {
      height: auto;
    }
  }

  .support-article-img {
    height: 150px;
    width: 100%;
    object-fit: cover;
  }
}
.previous {
  .bevel {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    height: 0;
    border-left: 0 solid colour(grey);
    border-top: 48px solid colour(grey);
    border-right: 0.80001em solid transparent;
  }

  .previous-spotlights {
    margin-left: 2rem;
    margin-top: auto;
    margin-bottom: auto;

    a {
      font-size: 1.2rem;
      font-weight: 700;
      color: colour(magenta);
      &:hover,
      &:focus {
        color: colour(magenta);
      }
    }
  }
}
</style>
