<template>
  <section v-if="homePage">
    <h2 class="bevel magenta">{{ title }}</h2>
    <div class="discover-more-band d-flex flex-row justify-content-around" aria-role="list">
      <div var class="link d-flex" v-for="link in items" :key="link.id" aria-role="listitem">
        <b-link :href="link.externalUrl">
          <div>{{ link.title }}</div>
          <KeyholeThumbnail
            class="thumbnail"
            :imageUrl="link.artworkUrl"
            :title="link.artworkImageAlt" />
        </b-link>
      </div>
      <div class="background"></div>
    </div>
  </section>
  <div v-else>
    <section class="section" v-for="component in items" :key="component.name">
      <h2 class="bevel purple">{{ component.name }}</h2>
      <div v-if="component.supportArticles" class="article-wrapper d-flex flex-row flex-wrap">
        <SupportArticle
          v-for="article in component.supportArticles.slice(0, 3)"
          :article="article"
          :key="article.id"
          :horizontal="true" />
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
h2.bevel {
  margin-bottom: 2em;
  font-size: 1.5rem;
  font-family: 'Source Sans Pro', sans-serif;
  &:before {
    top: 0.14em !important;
  }
}
@supports (font-variation-settings: normal) {
  h2.bevel {
    font-family: 'Source Sans Variable', sans-serif;
  }
}

h2.bevel.purple {
  margin-bottom: 0.5rem;
}

//IE 11 fix for discover more keyholes as it doesn't support negative margins
_:-ms-fullscreen,
:root .discover-more-band .link a .thumbnail {
  left: 20%;
  margin-left: 0;

  @media (min-width: 992px) {
    left: 25%;
  }
}
.discover-more-band {
  margin-top: 224px;
  padding-top: 3rem;
  padding-bottom: 1rem;
  position: relative;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    margin-left: calc(50% - 50vw);
    width: 100vw;
    background-color: colour(magenta);
  }

  .link {
    position: relative;
    width: 14rem;
    text-align: center;

    @media (max-width: 576px) {
      &:nth-child(n + 3) {
        display: none !important;
      }
    }

    a {
      margin-top: 1rem;
      color: white;
      font-family: 'Source Serif Pro', serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.2;
      color: white;
      text-align: center;

      .thumbnail {
        position: absolute;
        top: -224px;
        margin-left: -55px;
        transition: transform 0.3s ease-in-out;
        transform-origin: center bottom;
      }

      &:hover .thumbnail {
        transform: scale(1.15);
      }
    }
    @supports (font-variation-settings: normal) {
      a {
        font-family: 'Source Serif Variable', serif;
      }
    }
  }
}
</style>

<script>
import KeyholeThumbnail from '@/components/KeyholeThumbnail.vue'
import SupportArticle from '@/components/landing/SupportArticle'

export default {
  name: 'AdditionalComponents',
  components: {
    KeyholeThumbnail,
    SupportArticle,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Discover more',
    },
    items: {
      type: Array,
      required: true,
    },
    homePage: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
