import _ from 'lodash'
import ResizeSensor from 'resize-sensor'
import pluralize from 'pluralize/pluralize'
import DOMPurify from 'dompurify'
import numbro from 'numbro'

var IMAGE_MIN_WIDTH = 2
var IMAGE_MIN_HEIGHT = 2

export default {
  computed: {
    pluralize() {
      return pluralize
    },
  },
  methods: {
    sanitizeHtml(h) {
      return DOMPurify.sanitize(h)
    },
    onResize(el, f) {
      new ResizeSensor(el, f)
    },
    createResizeWatch(el, f) {
      var self = this

      if (el) {
        f()
        self.onResize(el, f)
      }
    },
    imageExists(src) {
      return this.imageInfo(src).then((info) => {
        return (
          info.exists &&
          info.dimensions &&
          info.dimensions.width > IMAGE_MIN_WIDTH &&
          info.dimensions.height > IMAGE_MIN_HEIGHT
        )
      })
    },
    imageDimensions(src) {
      return this.imageInfo(src).then((info) => {
        return info.dimensions
      })
    },
    imageInfo(src) {
      var body = document.getElementsByTagName('body')[0]

      if (!body) {
        return false
      }

      return new Promise((resolve) => {
        var img = document.createElement('img')
        img.style.display = 'none'

        img.onload = function () {
          var info = {
            exists: true,
            dimensions: {
              width: img.naturalWidth,
              height: img.naturalHeight,
            },
          }

          body.removeChild(img)
          resolve(info)
        }

        img.onerror = function () {
          var info = {
            exists: false,
          }

          body.removeChild(img)
          resolve(info)
        }

        img.src = src

        body.appendChild(img)
      })
    },
    concatToLimit(list, limit) {
      if (!list) {
        return ''
      }

      var text = ''
      var slicedList = list.slice(0, Math.min(list.length, limit)).map((item) => _.upperFirst(item))

      if (list.length > 0) {
        text += slicedList.join(', ')

        if (list.length > limit) {
          text += ' +' + (list.length - limit) + ' more'
        }
      }

      return text
    },
    treeEach(node, getChildren, forEach) {
      return this.treeSearch(node, getChildren, () => true, forEach)
    },
    treeSearch(node, getChildren, matches, onMatch, parent, depth, ancestors) {
      if (typeof node === 'undefined') {
        return
      }

      if (_.isArray(node)) {
        _(node).each((node) =>
          this.treeSearch(node, getChildren, matches, onMatch, parent, depth, ancestors)
        )
        return
      }

      if (matches(node, parent)) {
        onMatch(node, parent, depth || 0, ancestors || [])
      }

      _(getChildren(node) || []).each((child) =>
        this.treeSearch(
          child,
          getChildren,
          matches,
          onMatch,
          node,
          (depth || 0) + 1,
          [node].concat(ancestors || [])
        )
      )
    },
    after(delay, response) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(response)
        }, delay)
      })
    },
    isElementInViewport(el) {
      var rect = el.getBoundingClientRect()

      return (
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    isTruthy(val) {
      return (
        (_.isBoolean(val) && val) ||
        (_.isString(val) && !_.isEmpty(_.trim(val)) && !_.isUndefined(val))
      )
    },
    quotedSplit(content, delimiter, quote) {
      var result = []
      var inQuotedSection = false
      var previousDelimiterPosition = 0

      if (!content) {
        return result
      }

      for (var i = 0; i < content.length; i++) {
        var atEndOfString = i === content.length - 1

        if (content[i] === quote) {
          if (!inQuotedSection) {
            inQuotedSection = true
          } else {
            inQuotedSection = false
          }

          if (!atEndOfString) {
            continue
          }
        }

        if ((!inQuotedSection && content[i] === delimiter) || atEndOfString) {
          var length = i - previousDelimiterPosition
          if (atEndOfString) {
            length++
          }

          var splitSection = content.substr(previousDelimiterPosition, length)
          if (splitSection) {
            result.push(_.trim(_.replace(splitSection, new RegExp(quote, 'g'), '')))
          }

          previousDelimiterPosition = i + 1
        }
      }

      return result
    },
    formatOrdinal(number) {
      const suffix = ['th', 'st', 'nd', 'rd']
      let idx = number % 100

      return (
        numbro(number).format({ thousandSeparated: true }) +
        (suffix[(idx - 20) % 10] || suffix[idx] || suffix[0])
      )
    },
    convertSmartCharacters(text) {
      return text
          .replace(/[\u2018\u2019\u201A\uFFFD]/g, "'")
          .replace(/[\u201C\u201D\u201E]/g, '"')
          .replace(/[\u2013\u2014]/g, '-');
    }
  },
}
