<template>
  <span v-if="list">
    <b-link
    ><div @click="showExportModal">
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'file-download' }"/>
        Download this list
      </div></b-link
    >

    <b-modal id="exportModal">
      <template v-slot:modal-title>Download list</template>

      <p>Download {{ list.title }} as which format?</p>

      <b-form-select v-model="format" :options="formats"></b-form-select>

      <template v-if="selectedFormat && selectedFormat.optionsComponent">
        <component :is="selectedFormat.optionsComponent" @options-updated="optionsUpdated"
                   :item-types="list.itemTypes"/>
      </template>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="link" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="ok" :href="exportLink" target="_blank">Download</b-button>
      </template>
    </b-modal>
  </span>
</template>

<style scoped lang="scss"></style>

<script>
import _ from 'lodash'
import service from '@/service/service'
import CSVOptions from '@/components/lists/CSVOptions.vue'
import ExportCitationsOptions from "@/components/lists/ExportCitationsOptions.vue";

var FORMATS = [
  {
    value: 'csv',
    text: 'Comma separated values (excel)',
    optionsComponent: CSVOptions,
    optionsMapper(options) {
      return 'delimiter=' + options.delimiter + '&quoted=' + options.quoted
    },
  },
  {
    value: 'json',
    text: 'JSON',
  },
  {
    value: 'xml',
    text: 'XML',
  },
  {
    value: 'citation',
    text: 'Citations',
    optionsComponent: ExportCitationsOptions,
    optionsMapper(options) {
      return "format=" + options.format;
    },
  },
]

export default {
  name: 'ExportList',
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      format: FORMATS[0].value,
      formats: FORMATS,
      formatOptions: {},
    }
  },
  computed: {
    selectedFormat() {
      return _.find(FORMATS, (format) => format.value === this.format)
    },
    exportLink() {
      var queryParams = ''

      if (
          this.selectedFormat &&
          this.selectedFormat.optionsComponent &&
          _.isFunction(this.selectedFormat.optionsMapper) &&
          !_.isEmpty(this.formatOptions)
      ) {
        queryParams = '?' + this.selectedFormat.optionsMapper(this.formatOptions)
      }

      return (
          service.config.baseAPIURL + '/list/' + this.list.id + '/export/' + this.format + queryParams
      )
    },
  },
  watch: {
    selectedFormat: {
      immediate: true,
      handler() {
        this.formatOptions = {}
      },
    },
  },
  methods: {
    showExportModal() {
      this.$bvModal.show('exportModal')
    },
    optionsUpdated(options) {
      this.formatOptions = options
    },
  },
}
</script>
