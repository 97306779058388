<template>
  <div class="user-notification">
    <div class="date">{{ formattedDate }}</div>
    <div class="details" v-if="details.status === 'new'">
      <UserThumbnail :user-summary="details.user" size="small"></UserThumbnail>
      has offered to collaborate on the list "<router-link
        :to="manageListRoute"
        @click.native="clearNotification"
        >{{ details.listTitle }}</router-link
      >".
    </div>

    <div class="details" v-if="details.status === 'accepted'">
      Your offer to collaborate on the list "<router-link
        :to="manageListRoute"
        @click.native="clearNotification"
        >{{ details.listTitle }}</router-link
      >" has been accepted.
    </div>

    <div class="details" v-if="details.status === 'declined'">
      Your offer to collaborate on the list "<router-link
        :to="listRoute"
        @click.native="clearNotification"
        >{{ details.listTitle }}</router-link
      >" has been declined.
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-notification {
}
</style>

<script>
import UserThumbnail from '@/components/profile/UserThumbnail'
import AbstractNotificationDetail from './AbstractNotificationDetail'

export default {
  name: 'CollaborationRequest',
  components: {
    UserThumbnail,
  },
  extends: AbstractNotificationDetail,
  computed: {
    manageListRoute() {
      return {
        name: 'ManageList',
        params: { id: this.details.listId },
      }
    },
    listRoute() {
      return {
        name: 'List',
        params: { id: this.details.listId },
      }
    },
  },
}
</script>
