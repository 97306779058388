<template>
  <b-modal :id="modalId" class="modal" @ok="ok" @close="cancel" @cancel="cancel">
    <template v-slot:modal-title>Add these results to a list?</template>

    <h4>Are you certain?</h4>
    <p>
      Are you sure you want to add the following
      {{ selectedResults.length }} work(s) to a list?
    </p>

    <div class="results">
      <div v-for="result in selectedResults" :key="result.id" class="result">
        <div v-if="result.title" class="d-flex">
          <div class="fieldName">Title:</div>
          <div class="flex-grow-1">
            <b>{{ result.title }}</b>
          </div>
        </div>
        <div v-if="result.primaryName">
          <div class="fieldName">Name:</div>
          <div class="flex-grow-1">
            <b>{{ result.primaryName }}</b>
          </div>
        </div>
        <div v-if="result.author" class="d-flex">
          <div class="fieldName">Author:</div>
          <div class="flex-grow-1">
            <b>{{ result.author }}</b>
          </div>
        </div>
      </div>
    </div>

    <AddToList
      target-id="123"
      type="work"
      :available-lists="lists"
      :panel-open="true"
      :show-buttons="false"
      @validator="receiveValidator"
      @new-list-updated="newListUpdated"
      @selected-lists-updated="selectedListsUpdated"
      @reason-updated="reasonUpdated"
      @user-achievement="userAchievement">
    </AddToList>

    <div class="d-flex justify-content-center" v-if="saving">
      <div class="loading-spinner spinner-md"></div>
    </div>

    <Alert v-show="hasAlert" ref="alert" :alert="alert"></Alert>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="link" @click="cancel" :disabled="saving">Cancel</b-button>
      <b-button variant="primary" @click="ok" :disabled="saving"
        >Add these results to a list</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import service from '@/service/service'
import auth from '@/mixins/auth'
import lists from '@/mixins/lists'
import Alert from '@/components/Alert.vue'
import AddToList from '@/components/lists/AddToList.vue'
import BulkOperation from '@/components/bulk/BulkOperation.vue'

const EXCLUDED_WORK_TYPES = ['website', 'list']

export default {
  name: 'BulkAddToList',
  extends: BulkOperation,
  mixins: [auth, lists],
  components: {
    Alert,
    AddToList,
  },
  data() {
    return {
      lists: [],
      available: [],
      listsLoading: false,
      newList: {},
      selectedLists: [],
      reason: '',
      alert: {},
    }
  },
  computed: {
    operationId: () => 'addToList',
    buttonText: () => 'Add to list',
    canPerformOperation() {
      if (!_.isArray(this.allResults) || _.includes(this.allResults, null)) {
        return false
      }

      if (
        _(this.allResults)
          .map((result) => result.workType)
          .filter((type) => _.includes(EXCLUDED_WORK_TYPES, type))
          .value().length > 0
      ) {
        return false
      }

      return this.authenticated
    },
    hasAlert() {
      return !_.isEmpty(this.alert)
    },
  },
  methods: {
    showModal() {
      let self = this

      this.lists = []
      this.available = []
      this.newList = {}
      this.selectedLists = []
      this.reason = ''

      this.listsLoading = true
      service.list
        .getListSummariesForUser(this.fullUsername)
        .then((lists) => {
          self.lists = lists
          self.listsLoading = false
        })
        .catch(() => {
          self.listsLoading = false
        })

      this.$bvModal.show(this.modalId)
    },
    ok(event) {
      let self = this
      event.preventDefault()

      this.checkValidation().then((isValidated) => {
        if (isValidated) {
          self.saving = true

          let targets = _.map(self.selectedResults, (result) => {
            return {
              id: result.id,
              type: self.typeMap(result),
              format: result.format,
            }
          })

          let lists = _.concat(
            self.selectedLists || [],
            !_.isEmpty(self.newList) ? [self.newList] : []
          )

          service.list
            .addItemsToLists(targets, lists, self.reason)
            .then((data) => {
              self.saving = false
              let statusMessage = self.getListStatusMessage(
                data.items,
                data.errors,
                self.lists,
                self.selectedResults
              )

              if (!_.isEmpty(data.errors)) {
                self.alert = {
                  summary: statusMessage,
                  impact: 'info',
                }
              } else {
                self.alert = {}
                self.closeModal()
                self.$emit('bulk-operation-succeeded', statusMessage)
              }

              if (data.userAchievement && data.userAchievement.achievements.length > 0) {
                self.$emit('user-achievement', data.userAchievement)
              }
            })
            .catch((error) => {
              self.saving = false
              self.alert = {
                summary: error.response.data.message,
                impact: 'error',
              }
            })
        }
      })
    },
    newListUpdated(newList) {
      this.newList = newList
    },
    selectedListsUpdated(selectedLists) {
      this.selectedLists = selectedLists
    },
    reasonUpdated(reason) {
      this.reason = reason
    },
    userAchievement(ua) {
      this.$emit('user-achievement', ua)
    },
  },
}
</script>
