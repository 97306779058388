import _ from 'lodash'
import service from '@/service/service'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  mounted() {
    this.updateMyProfile()
  },
  computed: {
    ...mapGetters('trove', ['myUserProfile']),
    auth() {
      return this.$keycloak
    },
    authenticated() {
      return this.auth && this.auth.authenticated
    },
    fullUsername() {
      return this.auth.tokenParsed
        ? this.legacyUsername(this.auth.tokenParsed.preferred_username)
        : undefined
    },
    username() {
      return this.auth && this.fullUsername && this.fullUsername.startsWith('user:')
        ? _.toLower(this.fullUsername.substring(5))
        : undefined
    },
    simpleUsername() {
      return this.cleanUsername(this.fullUsername)
    },
    isQAer() {
      return this.authenticated && this.auth.hasRealmRole('ndp:ops:qa')
    },
    isDataManager() {
      return this.authenticated && this.auth.hasRealmRole('trove:dataManagement')
    },
    isStaff() {
      return this.authenticated && this.fullUsername.indexOf('public:') === -1
    },
    myProfileIsPublic() {
      return this.authenticated && this.myUserProfile && this.myUserProfile.publiclyVisible
    },
    hasActiveApiKey() {
      return this.authenticated && this.myUserProfile && this.myUserProfile.hasAPIKey && this.myUserProfile.apiDetails.keyStatus === 'C';
    },
    maximumExportSize() {
      return this.authenticated && this.myUserProfile ? this.myUserProfile.maximumExportSize : 0;
    },
  },
  methods: {
    login() {
      // keycloak's login function does not retain a history entry for the current page,
      // so hitting Back from the login page takes you
      // back two pages.
      history.pushState(history.state, window.title, window.location)
      window.location.replace(
        this.$kcAuthUrl + '/login?backurl=' + encodeURIComponent(window.location.href)
      )
    },
    logout() {
      window.location.replace(
        this.$kcAuthUrl + '/logout?backurl=' + encodeURIComponent(window.location.href)
      )
    },
    register() {
      // keycloak's register function does not retain a history entry for the current page,
      // so hitting Back from the login page takes you
      // back two pages.
      history.pushState(history.state, window.title, window.location)
      window.location.replace(
        this.$kcAuthUrl + '/signup?backurl=' + encodeURIComponent(window.location.href)
      )
    },
    cleanUsername(username) {
      return username && username.indexOf(':') > -1
        ? username.substring(username.lastIndexOf(':') + 1)
        : username
    },
    legacyUsername(username) {
      const STAFF_PREFIX = 'staff:'
      return username && username.indexOf(STAFF_PREFIX) > -1
        ? 'user:' + username.substring(username.indexOf(STAFF_PREFIX) + STAFF_PREFIX.length)
        : 'user:public:' + username
    },
    updateMyProfile() {
      let self = this
      if (self.authenticated) {
        service.profile.getMyProfile(self.fullUsername).then(() => {
          // nothing to do: the service call populates the profile info in vuex
        })
      }
    },
  },
}
