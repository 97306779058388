<template>
  <div class="no-search-results">
    <h2 class="heading">Hey now, hey now...don't dream it's over.</h2>

    <figure class="image-and-caption">
      <img
        src="@/assets/no-search-results.jpg"
        alt="Three hikers on a hill looking towards coast, New South Wales, 8 August 1932." />
      <figcaption class="caption">
        Fairfax Corporation (1932).
        <em>Three hikers on a hill looking towards coast, New South Wales, 8 August 1932</em>.
        <br />
        http://trove.nla.gov.au/work/184210230
      </figcaption>
    </figure>

    <div class="text">
      <p>There's no results for this search, but how about:</p>
      <ul>
        <li>trying a different spelling (eg. "Fin" instead of "Finn").</li>
        <li>removing quotes or extra filters to widen your search.</li>
      </ul>
      <p>Check our <SearchHelpLink></SearchHelpLink> for more tips.</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.no-search-results {
  padding-bottom: 6rem;

  .heading {
    font-family: inherit;
    font-size: 1.375rem;
    margin-bottom: 1em;
  }

  .image-and-caption {
    padding-bottom: 2rem;
    max-width: 40rem;

    img {
      max-width: 100%;
      height: auto;
    }

    .caption {
      font-size: 0.8rem;
    }
  }
}
</style>

<script>
import SearchHelpLink from '@/components/search/SearchHelpLink.vue'

export default {
  name: 'NoResultsPanel',
  components: {
    SearchHelpLink,
  },
  props: {},
  computed: {},
}
</script>
