<template>
  <b-form @submit.prevent="search" @reset.prevent="clear" class="advanced-search-form">
    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div" key="removeTag">
      <div class="accent-line" style="margin-bottom: 1rem"></div>

      <div class="d-flex justify-content-end search-button">
        <b-button class="clear-button" type="reset" variant="link" :disabled="!canClear"
          >clear</b-button
        >
        <div class="search-icon">
          <b-button type="submit" variant="link" :disabled="!canPerformSearch(query, currentQuery)">
            <span class="sr-only">Search</span>
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'search' }" />
          </b-button>
        </div>
      </div>

      <div class="fields">
        <SearchField
          v-for="field in fieldConfig"
          :key="field.name"
          :display="field.display"
          :subFields="field.subFields"
          :labelWidth="field.labelWidth || 2"
          @updated="fieldUpdated"
          @clear-handler="addClearHandler" />
      </div>

      <div class="limits" v-if="limitFieldConfig && limitFieldConfig.length > 0">
        <b-card>
          <SearchField
            v-for="field in limitFieldConfig"
            :key="field.name"
            :display="field.display"
            :subFields="field.subFields"
            :labelWidth="field.labelWidth || 2"
            @updated="fieldUpdated"
            @clear-handler="addClearHandler" />
        </b-card>
      </div>

      <div class="d-flex justify-content-end search-button">
        <b-button class="clear-button" type="reset" variant="link" :disabled="!canClear"
          >clear</b-button
        >
        <div class="search-icon">
          <b-button
            type="submit"
            variant="link"
            :disabled="invalid || !canPerformSearch(query, currentQuery)">
            <span class="sr-only">Search</span>
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'search' }" />
          </b-button>
        </div>
      </div>
    </ValidationObserver>
  </b-form>
</template>

<style scoped lang="scss">
.advanced-search-form {
  .search-icon {
    img {
      height: 50px;
    }
  }

  .fields {
    padding-top: 1rem;
  }

  .limits {
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.search-button {
  margin-bottom: 0.5rem;

  .clear-button {
    &:disabled {
      cursor: not-allowed;
      pointer-events: auto;
    }
  }
}

.accent-line {
  height: 4px;
  background: #000;
}
</style>

<script>
import _ from 'lodash'
import scroll from 'vue-scrollto'
import moment from 'moment'
import { ValidationObserver } from 'vee-validate'
import search from '@/mixins/search'
import currentRoute from '@/mixins/currentRoute'
import service from '@/service/service'
import SearchField from '@/components/search/advanced/SearchField.vue'
import FieldGenerators from '@/components/search/advanced/FieldGenerators.js'

export default {
  name: 'AdvancedSearch',
  mixins: [currentRoute, search, FieldGenerators],
  components: {
    SearchField,
    ValidationObserver,
  },
  props: {
    collections: {
      type: Array,
      required: false,
    },
    selectedCollectionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      query: null,
      facets: [],
      clearHandlers: [],
      scrolling: false,
      keywordSubfields: [],
    }
  },
  computed: {
    selectedCollection() {
      return _(this.collections || []).find(
        (collection) => collection.id === this.selectedCollectionId
      )
    },
    fieldConfig() {
      return [
        this.generateModifiableTextField('Keyword', 'keyword', true),
        this.generateModifiableTextField('Title', 'title', true),
        this.generateModifiableTextField('Creator', 'creator', true),
        this.generateModifiableTextField('Subject', 'subject', true),
      ]
    },
    limitFieldConfig() {
      return []
    },
    canClear() {
      return !_.isEmpty(this.trimQuery(this.query))
    },
    combinedFieldConfigs() {
      return _.concat(this.fieldConfig, this.limitFieldConfig, this.facets)
    },
  },
  methods: {
    addClearHandler(handler) {
      this.clearHandlers.push(handler)
    },
    getFacetFromCode(facetCode) {
      return _(this.facets).find((facet) => facet.code === facetCode)
    },
    getTermsFromFacet(facetCode) {
      var facet = this.getFacetFromCode(facetCode)
      var terms = facet && facet.terms ? facet.terms : []

      var flattenTerms = function (terms, depth) {
        var setDepth = function (terms, depth) {
          return _(terms)
            .map((term) => {
              term.depth = depth
              return term
            })
            .value()
        }

        return !terms
          ? []
          : _.reduce(
              setDepth(terms, depth || 0),
              (allTerms, term) =>
                allTerms.concat([term], flattenTerms(term.subTerms, (depth || 0) + 1)),
              []
            )
      }

      return [].concat(
        _(flattenTerms(terms))
          .map((term) => {
            return {
              text: term.displayTerm,
              value: term.term,
              depth: term.depth,
            }
          })
          .value()
      )
    },
    fieldUpdated(field, value) {
      if (this.$refs.observer) {
        this.$refs.observer.validate()
      }

      var newQuery = _.cloneDeep(this.query)

      let normaliseWhitespace = (input) => {
        if (_.isArray(input)) {
          return _.map(input, normaliseWhitespace)
        }

        if (_.isString(input)) {
          return input.trim().replace(/\s+/g, ' ')
        }

        return input
      }

      if (value) {
        newQuery[field] = normaliseWhitespace(value)
      } else {
        delete newQuery[field]

        var split = field.split(this.getFieldDelimiter())
        if (split.length == 1) {
          var root = split[0]

          _(this.query)
            .pickBy((val, key) => {
              return key.startsWith(root)
            })
            .each((val, key) => {
              if (!(root === 'keyword' && _.includes(this.keywordSubfields, key))) {
                delete newQuery[key]
              }
            })
        }
      }

      this.query = newQuery
    },
    search() {
      const trimmedQuery = this.trimQuery(this.query)

      if (!this.isEmptyQuery(trimmedQuery)) {
        this.$emit('search', this.removePaginationFromQuery(trimmedQuery))
      } else {
        this.scrollToAlert()
      }
    },
    clear() {
      _(this.clearHandlers).each((handler) => {
        handler()
      })
    },
    multiselectMapper(subField, value) {
      var values = _.isArray(value || []) ? value : [value]

      return _(values)
        .map((val) => {
          return _(subField.options || []).find((option) => option.value === val)
        })
        .value()
    },
    multiselectReverseMapper(subField, value) {
      var values = _.isArray(value || []) ? value : [value]
      return _(values)
        .filter((val) => val)
        .map((val) => val.value)
        .value()
    },
    dateMapper(subField, value) {
      var date = moment(value, 'YYYY-MM-DD')
      return date.isValid() ? date.toDate() : null
    },
    dateReverseMapper(subField, value) {
      var date = moment(value)
      return date.isValid() ? moment(value).format('YYYY-MM-DD') : null
    },
    scrollToAlert() {
      var self = this

      if (self.scrolling) {
        return
      }

      self.scrolling = true
      scroll.scrollTo(self.$refs.alert, 1000, {
        force: true,
        cancelable: false,
        onDone: () => {
          self.scrolling = false
        },
      })
    },
  },
  watch: {
    fieldConfig: {
      immediate: true,
      handler() {
        this.$emit('field-config-updated', this.combinedFieldConfigs)
      },
    },
    limitFieldConfig: {
      immediate: true,
      handler() {
        this.$emit('field-config-updated', this.combinedFieldConfigs)
      },
    },
    facets: {
      immediate: true,
      handler() {
        this.$emit('field-config-updated', this.combinedFieldConfigs)
      },
    },
    currentQuery: {
      immediate: true,
      handler() {
        this.query = this.currentQuery
      },
    },
    selectedCollectionId: {
      immediate: true,
      handler() {
        var self = this

        var facets = _(this.limitFieldConfig || []).reduce((facets, fieldConfig) => {
          var fieldFacets = _(fieldConfig.subFields || [])
            .filter((subFieldConfig) => subFieldConfig.facet)
            .map((subFieldConfig) => subFieldConfig.facet)
            .value()
          return facets.concat(fieldFacets)
        }, [])

        facets = _.flattenDeep(facets)

        if (this.selectedCollectionId) {
          self.facets = []
          service.search
            .getFacetsForCollection(this.selectedCollectionId, facets)
            .then((facets) => {
              self.facets = facets
            })
        }
      },
    },
  },
}
</script>
