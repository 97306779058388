import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import Cookies from 'js-cookie'
import _ from 'lodash'

import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

Vue.use(Vuex)

const vuexSessionStorage = new VuexPersist({
  key: 'trove-redesign',
  reducer: (state) => {
    return _.omit(state, [
      'route',
      'trove.fieldsDisplayConfig',
      'trove.myUserProfile',
      'trove.routeHistory',
      'trove.startAdvancedOpen',
    ])
  },
  restoreState: (key /*, storage */) => Cookies.getJSON(key),
  // eslint-disable-next-line max-len
  saveState: (key, state /*, storage */) => Cookies.set(key, state, { sameSite: 'strict' }), // cookie with no expire = browser session
})

export default new Vuex.Store({
  modules: {
    trove: {
      namespaced: true,

      state: {
        primaryWarningDisplayed: false,
        showCulturalWarnings: true,
        isHuman: false,
        unsuppressedIdentifiers: {},
        profileActivity: '',
        apiLastCriticalFail: null,
        myUserProfile: {},
        routeHistory: [],
        startAdvancedOpen: false,
        fieldsDisplayConfig: {},
      },
      getters,
      mutations,
      actions,
    },

    //
    // route: { namespaced:true, state: {..}} is installed by vuex-router-sync!
    //
  },

  plugins: [vuexSessionStorage.plugin],
  strict: process.env.NODE_ENV !== 'production',
})
