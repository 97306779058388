<template>
  <div>
    <div class="collection-result mt-4">
      <ImageResultContainer
        resultTemplate="RelatedItem"
        :results="visibleNames"
        v-if="visibleNames && visibleNames.length > 0"
        :isSummary="false"></ImageResultContainer>
      <h4 v-else>No names were found</h4>
    </div>

    <div v-if="moreLinkVisible" class="d-flex justify-content-center">
      <b-button v-if="moreLink" @click="toggleRelated" variant="primary" class="inverse"
        >Show all</b-button
      >
      <b-button v-if="!moreLink" @click="toggleRelated" variant="primary" class="inverse"
        >Show less</b-button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import _ from 'lodash'

import colours from '@/mixins/colours'
import ImageResultContainer from '@/components/search/ImageResultContainer.vue'

export default {
  name: 'PeopleRelated',
  components: {
    ImageResultContainer,
  },
  mixins: [colours],
  props: {
    relatedNames: {
      type: Object,
      required: true,
    },
    partners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      moreLink: true,
      pageSize: 10,
    }
  },
  methods: {
    toggleRelated() {
      this.moreLink = !this.moreLink
    },
  },
  computed: {
    filteredNames() {
      return _(this.relatedNames)
        .flatMap((o) => o)
        .filter((o) => this.partners.length == 0 || _.includes(this.partners, o.partner))
        .sortBy(['name'])
        .value()
    },
    visibleNames() {
      return this.moreLink ? this.filteredNames.slice(0, this.pageSize) : this.filteredNames
    },
    moreLinkVisible() {
      return this.filteredNames.length > this.pageSize
    },
  },
}
</script>
