<template>
  <div class="search-error">
    <div class="text">
      <p>The search failed, details are:</p>
      <p v-html="message"></p>
      <p> Check our <SearchHelpLink format="within-alert"></SearchHelpLink> for more tips. </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.search-error {
  padding-bottom: 6rem;
  max-width: 927px;

  @media (min-width: 767.98px) {
    margin-right: 30px;
  }

  .heading {
    font-family: inherit;
    font-size: 1.375rem;
    margin-bottom: 1em;
  }

  .text {
    background-color: colour(yellow);
    color: black;
    padding: 0.5rem;

    .help-link {
      color: black !important;
      font-weight: 600;
      a {
        color: black !important;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import SearchHelpLink from '@/components/search/SearchHelpLink.vue'

export default {
  name: 'SearchError',
  components: {
    SearchHelpLink,
  },
  props: {
    searchError: {
      type: [String, Object],
      required: true,
    },
    defaultMessage: {
      type: String,
      required: false,
      default: 'An unexpected error has occurred while searching',
    },
  },
  computed: {
    message() {
      let self = this
      let message

      if (self.searchError) {
        if (_.isString(self.searchError)) {
          message = self.searchError
        } else if (_.isObject(self.searchError)) {
          message = self.searchError.message
        }
      }

      return message || self.defaultMessage
    },
  },
}
</script>
