import _ from 'lodash'
import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'

// TODO: use the facet prefix constant everywhere
const FACET_PREFIX = 'l-'
const FIELD_DELIMITER = '.'
const MODIFIER_SUFFIX = FIELD_DELIMITER + 'mod'
const START_POS = 'startPos'
const PAGE_SIZE = 'pageSize'
const PAGE_TOTALS = 'pageTotals'
const SORT_BY = 'sortBy'
const EXPLAIN = 'explain'
const DATE = 'date'

// Search limits that apply to all categories and should be retained when switching between categories
const SEARCH_PAGINATION_PARAMS = [START_POS, PAGE_SIZE, PAGE_TOTALS]
const QUERY_TO_STRING_EXCLUDES = [START_POS, PAGE_SIZE, PAGE_TOTALS, SORT_BY, EXPLAIN]
const NEWSPAPER_EXCLUDES = [START_POS, PAGE_SIZE, PAGE_TOTALS, SORT_BY, EXPLAIN, DATE]

const WEBSITE_HANDLER = 'WEBSITE'
const NEWSPAPER_AND_GAZETTE_HANDLER = 'NEWSPAPER_AND_GAZETTE'
const ADVANCED_SEARCH_EXCLUDES = [{ searchHandler: 'LIST', urlNamespace: 'category', key: 'list' }]
const EQUALS_ON_NON_RANGE_INCLUDE = ['batchId']

const NON_LIMIT_TERMS = [FACET_PREFIX, START_POS, PAGE_SIZE, PAGE_TOTALS, SORT_BY, EXPLAIN]

export default {
  computed: {
    ...mapGetters('trove', ['startAdvancedOpen']),
    collectionsForAdvancedSearch() {
      return _.filter(this.collections, (collection) => {
        return collection.searchHandler !== 'LIST';
      })
    },
    isWebsites() {
      if (this.collection) {
        return this.collection.searchHandler === WEBSITE_HANDLER
      }

      if (
        _.isArray(this.collectionsToSearch) &&
        this.collectionsToSearch.length === 1 &&
        this.collectionsToSearch[0].searchHandler === WEBSITE_HANDLER
      ) {
        return true
      }

      return false
    },
    isNewspaperAndGazette() {
      if (this.collection) {
        return this.collection.searchHandler === NEWSPAPER_AND_GAZETTE_HANDLER
      }
      return false
    },
    searchPaginationParams() {
      return SEARCH_PAGINATION_PARAMS
    },
    nonLimitQueryParams() {
      if (this.isWebsites || this.isNewspaperAndGazette) {
        return NEWSPAPER_EXCLUDES
      }
      return QUERY_TO_STRING_EXCLUDES
    },
    facetPrefix() {
      return FACET_PREFIX
    },
  },
  methods: {
    ...mapMutations('trove', {
      updateStartAdvancedOpen: 'UPDATE_START_ADVANCED_OPEN',
    }),
    isNonLimitQueryParam(param) {
      return _.includes(this.nonLimitQueryParams, param)
    },
    removePaginationFromQuery(query) {
      return _.omit(query, SEARCH_PAGINATION_PARAMS)
    },
    getLimitsFromQuery(query) {
      let self = this

      let limits = _(query)
        .pickBy((val, key) => {
          return key && val && key.startsWith('l-')
        })
        .mapKeys((value, key) => {
          return key.substr(2)
        })
        .mapValues((value) => {
          return _.isArray(value) ? value : [value]
        })
        .value()

      if (self.isWebsites || self.isNewspaperAndGazette) {
        limits['date.from'] = [query['date.from']]
        limits['date.to'] = [query['date.to']]
      }

      return limits
    },
    trimQuery(query) {
      if (typeof query === 'object') {
        return _.omitBy(query, (value) => {
          return _.isEmpty(_.trim(value))
        })
      } else {
        return _.trim(query)
      }
    },
    canPerformSearch(query, initialQuery) {
      let canPerformSearch
      if (_.isUndefined(query) || _.isNull(query) || _.isEmpty(this.trimQuery(query))) {
        canPerformSearch = false
      } else if (_.isUndefined(initialQuery) || _.isNull(initialQuery) || _.isEmpty(initialQuery)) {
        canPerformSearch = true
      } else {
        canPerformSearch = _.isString(query)
          ? query !== initialQuery.keyword
          : !_.isEqual(this.trimQuery(query), initialQuery)
      }

      return canPerformSearch
    },
    getSearchRouteName(collection, advanced) {
      if (collection) {
        if (advanced && !this.excludedFromAdvancedSearch(collection)) {
          return 'AdvancedSearch'
        } else {
          return 'Search'
        }
      } else {
        return 'SearchAll'
      }
    },
    excludedFromAdvancedSearch(collection) {
      if (collection) {
        if (collection.searchHandler) {
          return _.find(
            ADVANCED_SEARCH_EXCLUDES,
            (c) => c.searchHandler === collection.searchHandler
          )
        }

        return _.find(
          ADVANCED_SEARCH_EXCLUDES,
          (c) => c.urlNamespace === collection.urlNamespace && c.urlKey === collection.urlKey
        )
      } else {
        return true
      }
    },
    nonLimitTermsChanged(newQuery, oldQuery) {
      var exclude = NON_LIMIT_TERMS

      var omit = function (query) {
        return _.omitBy(query, (value, key) => {
          return (
            _(exclude)
              .map((e) => key.startsWith(e))
              .filter((e) => e)
              .value().length > 0
          )
        })
      }

      return !_.isEqual(omit(newQuery), omit(oldQuery))
    },
    groupQuery(query) {
      // TODO: Why were we doing this again?
      var deArray = function (value) {
        return value
        //return _.isArray(value) ? value[0] : value;
      }

      return _(query)
        .toPairs()
        .groupBy((pair) => pair[0].split(FIELD_DELIMITER)[0])
        .mapValues((value, key) => {
          return _(value).reduce((result, subfield) => {
            if (subfield[0] === key) {
              result['value'] = deArray(subfield[1])
            } else {
              result[subfield[0].split(FIELD_DELIMITER)[1]] = deArray(subfield[1])
            }
            return result
          }, {})
        })
        .value()
    },
    fieldValuesFromQuery(query, fields) {
      return _(fields)
        .map((field) => this.fieldValueFromQuery(query, field.name))
        .value()
    },
    fieldValueFromQuery(query, fieldName) {
      var groupedParams = this.groupQuery(query)
      var split = fieldName.split(FIELD_DELIMITER)
      var key = 'value'
      var rootFieldName = fieldName

      if (split.length === 2) {
        rootFieldName = split[0]
        key = split[1]
      }

      return groupedParams[rootFieldName] ? groupedParams[rootFieldName][key] : null
    },
    queryToString(query) {
      var exclude = this.nonLimitQueryParams

      var fieldPrefix = function (fieldName) {
        return fieldName === 'keyword' ? '' : `${fieldName}:`
      }

      var modMapping = {
        phrase: function (fieldValue, fieldName) {
          return `${fieldPrefix(fieldName)}"${fieldValue.replace(/^"|"$/g, '')}"`
        },
        any: function (fieldValue, fieldName) {
          return fieldPrefix(fieldName) + '(' + fieldValue.split(' ').join(' OR ') + ')'
        },
        not: function (fieldValue, fieldName) {
          return '-' + fieldPrefix(fieldName) + '(' + fieldValue.split(' ').join(' OR ') + ')'
        },
      }

      var doThenReturn = function (toDo, toReturn) {
        toDo()
        return toReturn
      }

      // quote for targetted queries (nuc:"anl:dl" etc)
      var quoter = function (fieldname, str) {
        if (!str) return
        if ('keyword' === fieldname || !str.match(/[: ]/) || str.match(/^"\S*"$/)) {
          // eslint-disable-line no-useless-escape
          return str // already quoted, or doesn't need quoting
        }
        return '"' + str + '"'
      }

      /* eslint-disable no-unused-vars */
      var transformers = [
        {
          matcher: (field, fieldName) => {
            return _.isArray(field.value) && field.value.length === 1
          },
          transform: (query, field, fieldName) => {
            field.value = field.value[0]
          },
        },
        {
          matcher: (field, fieldName) => {
            return field.mod
          },
          transform: (query, field, fieldName) => {
            if (modMapping[field.mod]) {
              return doThenReturn(() => {
                delete field.mod
                delete field.value
              }, modMapping[field.mod](field.value, fieldName))
            }
          },
        },
        {
          matcher: (field, fieldName) => {
            return field.phrase
          },
          transform: (query, field, fieldName) => {
            return doThenReturn(() => {
              delete field.phrase
            }, modMapping['phrase'](field.phrase, fieldName))
          },
        },
        {
          matcher: (field, fieldName) => {
            return field.any
          },
          transform: (query, field, fieldName) => {
            return doThenReturn(() => {
              delete field.any
            }, modMapping['any'](field.any, fieldName))
          },
        },
        {
          matcher: (field, fieldName) => {
            return field.not
          },
          transform: (query, field, fieldName) => {
            return doThenReturn(() => {
              delete field.not
            }, modMapping['not'](field.not, fieldName))
          },
        },
        {
          matcher: (field, fieldName) => {
            return _.isArray(field.value) && !_.isEmpty(field.value)
          },
          transform: (query, field, fieldName) => {
            return doThenReturn(
              () => {
                delete field.value
              },
              '(' +
                _(field.value)
                  .map((val) => `${fieldName}:${quoter(fieldName, val)}`)
                  .value()
                  .join(' OR ') +
                ')'
            )
          },
        },
        {
          matcher: (field, fieldName) => {
            return field.value
          },
          transform: (query, field, fieldName) => {
            // Explicitly include a space after the parentheses we add, to ensure correct parsing by Solr
            return doThenReturn(() => {
              delete field.value
            }, (fieldName === 'nuc') ? fieldPrefix(fieldName) + '( ' + `${quoter(fieldName, field.value)}` + ' )' :
                `${fieldPrefix(fieldName)}( ${field.value} )`)
          },
        },
        {
          // number range query
          matcher: (field, fieldName) => {
            return !isNaN(field.from) || !isNaN(field.to)
          },
          transform: (query, field, fieldName) => {
            let equalsOnNonRange =
              _.includes(EQUALS_ON_NON_RANGE_INCLUDE, fieldName) &&
              (isNaN(field.from) || isNaN(field.to))

            return doThenReturn(() => {
              delete field.from
              delete field.to
              // eslint-disable-next-line max-len
            }, fieldPrefix(fieldName) + (!equalsOnNonRange ? '[' + (field.from || '*') + ' TO ' + (field.to || '*') + ']' : field.from || field.to))
          },
        },
        {
          matcher: (field, fieldName) => {
            return field.from || field.to
          },
          transform: (query, field, fieldName) => {
            var getDatePeriod = function (rawValue) {
              let timePeriod = ''
              let result = []

              if (rawValue) {
                /* eslint-disable no-cond-assign */
                if ((result = rawValue.match(/^(\d+)$/))) {
                  // only year
                  timePeriod = 'year'
                } else if ((result = rawValue.match(/^(\d+)-(\d+)$/))) {
                  // year and month
                  timePeriod = 'month'
                } else if ((result = rawValue.match(/^(\d+)-(\d+)-(\d+)$/))) {
                  // year, month, and day
                  timePeriod = 'day'
                }
                /* eslint-enable no-cond-assign */
              }

              return timePeriod
            }

            var solriseDate = function (date, defaultToEndOfRange) {
              var timePeriod = getDatePeriod(date)
              var original = moment(date, 'YYYY-MM-DD', true)
              if (original.isValid()) {
                if (timePeriod) {
                  original = defaultToEndOfRange
                    ? original.endOf(timePeriod)
                    : original.startOf(timePeriod)
                }

                return original.format('YYYY-MM-DD[T]HH:mm:SS[Z]')
              } else {
                return date
              }
            }

            return doThenReturn(() => {
              delete field.from
              delete field.to
              // eslint-disable-next-line max-len
            }, `${fieldPrefix(fieldName)}[${solriseDate(field.from, false) || '*'} TO ${solriseDate(field.to, true) || '*'}]`)
          },
        },
      ]
      /* eslint-enable no-unused-vars */

      var filteredQuery = _(this.groupQuery(query))
        .pickBy(
          (field, fieldName) =>
            !fieldName.startsWith(FACET_PREFIX) && !_.includes(exclude, fieldName)
        )
        .value()

      var output = []

      _(transformers).each((transformer) => {
        _(filteredQuery).each((field, fieldName) => {
          if (transformer.matcher(field, fieldName)) {
            var transformed = transformer.transform(filteredQuery, field, fieldName)
            if (transformed) {
              output.push(transformed)
            }
          }
        })
      })

      return output.join(' ')
    },
    getModifierSuffix() {
      return MODIFIER_SUFFIX
    },
    getFieldDelimiter() {
      return FIELD_DELIMITER
    },
    isEmptyQuery(query) {
      var exclude = [START_POS, PAGE_SIZE, PAGE_TOTALS, SORT_BY, EXPLAIN]
      return _(query)
        .omitBy((value, key) => _.includes(exclude, key))
        .isEmpty()
    },
  },
}
