<!-- People and Organisation view -->
<template>
  <b-container class="people-work">
    <div class="section-container">
      <div class="basic-search">
        <BasicSearch
          :collections="collections"
          :display-collections="true"
          :displayAdvancedSearch="true"
          @collection-changed="selectedCollectionUpdated"
          @search="search" />
      </div>

      <Stubbable :stubbed="stubbed">
        <template v-slot:content>
          <CulturalSensitivityWarning
            v-if="person.culturallySensitive"
            :isThumbnail="isThumbnail"
            :identifier="person.id"></CulturalSensitivityWarning>
          <CulturalModal v-else />
        </template>
      </Stubbable>
    </div>
    <b-row v-if="!notfound" class="section-container">
      <b-col>
        <Stubbable :stubbed="stubbed">
          <template v-slot:content>
            <h1>{{ name }}</h1>
            <span class="format"
              ><font-awesome-icon v-if="formatIcon" :icon="formatIcon" />{{ format }}</span
            >
            <FirstAustraliansIndicator
              v-if="person.firstAustraliansInd"></FirstAustraliansIndicator>
          </template>
          <template v-slot:stub>
            <div class="stub">
              <div class="d-flex flex-column sub-container">
                <div class="line"></div>
                <div class="line"></div>
              </div>
            </div>
          </template>
        </Stubbable>
      </b-col>
    </b-row>
    <b-row v-if="!notfound && !person.disambiguation" class="image-container">
      <b-col>
        <Stubbable :stubbed="stubbed">
          <template v-slot:content>
            <Thumbnail
              v-if="portrait"
              :url="portrait.url"
              :title="portrait.caption"
              type="portrait"
              :blur="blurPortrait"
              :key="'portrait' + id"
              :lazy="false"
              @load-failed="showThumbnail = false"
              @load-succeeded="showThumbnail = true" />
          </template>
          <template v-slot:stub>
            <div class="stub">
              <div class="d-flex flex-column sub-container">
                <div class="thumbnail"></div>
              </div>
            </div>
          </template>
        </Stubbable>
      </b-col>
    </b-row>
    <b-row v-if="!notfound && !person.disambiguation" class="section-container">
      <b-col>
        <Stubbable :stubbed="stubbed">
          <template v-slot:content>
            <PeopleActions
              :person-id="id"
              :person-name="name"
              :work-type="person.workType"></PeopleActions>
          </template>
          <template v-slot:stub>
            <div class="stub">
              <div class="d-flex flex-column sub-container">
                <div class="line stubbutton"></div>
              </div>
            </div>
          </template>
        </Stubbable>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <div v-if="!notfound">
          <div class="d-flex flex-column content-container">
            <Stubbable :stubbed="stubbed">
              <template v-slot:content>
                <template v-if="!person.disambiguation">
                  <div v-if="primaryBiography" class="summary-header">
                    <h2>About</h2>
                  </div>

                  <div v-if="hasPrimary" class="read-more">
                    <Collapse
                      class="moreDetailsCollapse"
                      :collapsed="!showDetails"
                      minHeight="14rem">
                      <div v-if="primaryBiography">
                        <div class="contribution">
                          Contributed by
                          <a
                            v-if="primaryBiography.id && primaryBiography.id.indexOf('http') === 0"
                            :href="primaryBiography.id"
                            target="_blank"
                            >{{ primaryBiography.org
                            }}<span class="ml-2"
                              ><font-awesome-icon
                                :icon="{
                                  prefix: 'fas',
                                  iconName: 'external-link-alt',
                                }" /></span></a
                          ><span v-else>{{ primaryBiography.org }}</span>
                        </div>
                        <div v-html="primaryBiography.bioghist"></div>
                      </div>
                      <div class="more-details">
                        <Field
                          class="summary-container"
                          v-for="field in otherFields"
                          :key="field.title"
                          :title="field.title"
                          :orientation="field.orientation"
                          :content="field.content"
                          :routeResolver="field.routeResolver"
                          :code="field.code"
                          :class="{ wide: field.code }"
                          grid-layout></Field>
                      </div>
                      <br />
                      <br />
                    </Collapse>
                    <div
                      :class="!showDetails ? 'collapseLink' : 'expandLink'"
                      class="pt-4 read-more-action">
                      <b-link>
                        <div @click="showDetails = !showDetails">
                          <span v-if="showDetails"
                            >Read less
                            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-up' }"
                          /></span>
                          <span v-if="!showDetails"
                            >Read more
                            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }"
                          /></span>
                        </div>
                      </b-link>
                    </div>
                  </div>
                  <div v-else class="read-more">
                    <div class="more-details">
                      <Field
                        class="summary-container"
                        v-for="field in otherFields"
                        :key="field.title"
                        :title="field.title"
                        :orientation="field.orientation"
                        :content="field.content"
                        :routeResolver="field.routeResolver"
                        :code="field.code"
                        :class="{ wide: field.code }"
                        grid-layout></Field>
                    </div>
                  </div>

                  <div v-if="otherBiographies && otherBiographies.length > 0">
                    <h4>Other biographies</h4>
                    <div class="link-container">
                      <b-button
                        variant="outline-primary"
                        v-for="(biography, index) in otherBiographies"
                        :href="biography.id"
                        class="col-5 work-link"
                        :key="biography.org + '-' + index"
                        :target="'_blank'">
                        <span class="link-text">{{ biography.org }}</span>
                        <font-awesome-icon
                          :icon="{
                            prefix: 'fas',
                            iconName: 'external-link-alt',
                          }"
                          :class="'link-icon'" />
                      </b-button>
                    </div>
                  </div>

                  <div class="more-details section-container">
                    <Field
                      v-for="field in primaryFields"
                      :key="field.title"
                      :title="field.title"
                      :orientation="field.orientation"
                      :content="field.content"
                      :routeResolver="field.routeResolver"
                      :code="field.code"></Field>
                  </div>

                  <Tags :work="person" />

                  <ContainingLists
                    :type="format"
                    :target-id="person.id"
                    :format="format"></ContainingLists>

                  <AnnotationList
                    :type="format"
                    :target-id="person.id"
                    :heading="format + ' notes'"></AnnotationList>

                  <ResourcesContainer
                    :person="person"
                    :partners="partners"
                    :key="person.id"></ResourcesContainer>
                </template>
                <template v-if="person.disambiguation">
                  <div class="disambiguation">
                    The record for {{ name }} (nla.party-{{ person.id }}) was ambiguous and now may
                    refer to:
                    <ul>
                      <li v-for="d in person.disambiguationNames" :key="d.id">
                        <router-link :to="link(d.id)" v-html="d.name"></router-link>
                        (nla.party-{{ d.id }})
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
              <template v-slot:stub>
                <div class="stub">
                  <div class="d-flex flex-column sub-container">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                  </div>
                </div>
              </template>
            </Stubbable>
          </div>
        </div>
      </b-col>

      <b-col md="3" class="aside-container">
        <template v-if="partners && !person.disambiguation">
          <h1 class="bevel">Partners</h1>
          <ul class="d-flex flex-column">
            <li v-for="partner in partners" :key="partner.name" class="mb-3">
              <a v-if="partner.name" :href="partner.url" target="_blank"
                ><span class="mr-2">{{ partner.name }}</span>
                <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'external-link-alt' }" />
              </a>
              <span v-else>{{ partner.name }}</span>
            </li>
          </ul>
        </template>

        <div class="cultural-sensitivity-feedback-link" v-if="!notfound">
          <CulturalSensitivityFeedback />
        </div>
      </b-col>
    </b-row>
    <div class="error" v-if="notfound && error && error.summary">
      <Alert :alert="error"></Alert>
    </div>
  </b-container>
</template>

<style lang="scss">
.basic-search .advanced-search {
  div {
    select,
    .down {
      color: colour(purple);
    }
  }
}
</style>

<style scoped lang="scss">
.people-work {
  margin-bottom: 3rem;

  h1,
  h2 {
    font-family: 'Source Serif Pro', serif;
    font-weight: 700;
  }

  @supports (font-variation-settings: normal) {
    h1,
    h2 {
      font-family: 'Source Serif Variable', serif;
    }
  }

  h1.bevel {
    font-family: inherit;
    font-size: 1.375rem;
    margin-bottom: 1em;
  }

  .format {
    font-weight: 600;
    font-size: 1.375rem;
    color: colour(grey-2);

    svg {
      margin-right: 0.5em;
    }
  }

  .contribution {
    margin-bottom: 1em;
  }

  .collapseLink {
    position: relative;
    bottom: 3rem;
    margin-bottom: -3rem;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    );
    width: 100%;
    height: 5em;
    pointer-events: none;

    button,
    a {
      padding-left: 0;
      position: absolute;
      bottom: 0;
      pointer-events: auto;
    }
  }

  .read-more {
    border-bottom: 1px solid colour(black);
    padding-bottom: 1rem;

    .read-more-action {
      span {
        font-size: 1.13rem;

        svg {
          margin-left: 0.3em;
        }
      }
    }
  }

  .aside-container {
    ul {
      padding-left: 0;

      li {
        list-style: none;
      }
    }

    .cultural-sensitivity-feedback-link {
      padding-top: 3rem;
    }
  }

  .link-container {
    display: flex;
    flex-wrap: wrap;

    .work-link {
      flex: 1;
      margin-right: 2em;
      margin-bottom: 1em;
      display: inline-block;
      border: 1px solid;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 8rem;
      min-height: 4rem;
      max-width: 20rem;
      min-width: 20rem;
      text-align: left;
      position: relative;
      font-size: 14px;

      &.high-priority {
        background-color: colour(light-blue);
      }

      .conditions-statement {
        display: block;
        font-size: smaller;
      }

      .link-icon {
        font-size: 12px;
        position: absolute;
        top: 2px;
        right: 2px;
      }
    }
  }

  .disambiguation {
    padding-top: 2rem;
  }
}

// Global containers
// Global containers
.content-container {
  margin-right: 8%;

  @media (max-width: 575px) {
    margin-right: 0px;
  }
}

.section-container,
.find-more-container,
.resources-container {
  margin-top: 4em;
}

.image-container {
  margin-top: 2em;
}

.summary-container {
  margin-top: 2.5em;
  max-width: 680px;
  margin-right: 30px;

  &.wide {
    max-width: 100%;
  }
}

.summary-header {
  margin-top: 3em;
  margin-bottom: 0.5em;
}

.aside-container {
  margin-top: 3em;
}

$container-absolute-width: 800px;
$container-margin: 5px;
$container-absolute-height: 250px;

$arrow-height: 20px;

$thumbnail-dimension: 167px;
$stubbutton-dimension: 50px;

.stub {
  width: $container-absolute-width;
  margin: 0;
  padding: 0;

  .sub-container {
    width: $container-absolute-width - ($container-margin * 2);
    margin: $container-margin;

    .stubbutton {
      width: $stubbutton-dimension * 2;
      height: $stubbutton-dimension;
    }

    .thumbnail {
      width: $thumbnail-dimension;
      height: $thumbnail-dimension;
    }
  }
}

.previous-bevel {
  position: relative;
  height: $primary-button-height;
  @include bevel-div();
}
</style>

<script>
import _ from 'lodash'
import scroll from 'vue-scrollto'

import colours from '@/mixins/colours'
import cultural from '@/mixins/cultural'
import utils from '@/mixins/utils'
import mappings from '@/mixins/mappings'
import service from '@/service/service'
import BasicSearch from '@/components/search/BasicSearch.vue'
import Tags from '@/components/work/Tags.vue'
import Alert from '@/components/Alert.vue'
import Field from '@/components/work/Field.vue'
import ResourcesContainer from '@/components/people/ResourcesContainer.vue'
import PeopleActions from '@/components/people/peopleActions/PeopleActions.vue'
import Collapse from '@/components/common/Collapse.vue'
import Stubbable from '@/components/Stubbable.vue'
import Thumbnail from '@/components/Thumbnail.vue'
import CulturalSensitivityWarning from '@/components/cultural/CulturalSensitivityWarning.vue'
import CulturalModal from '../components/cultural/CulturalModal'
import AnnotationList from '@/components/annotations/AnnotationList.vue'
import ContainingLists from '@/components/lists/ContainingLists.vue'
import CulturalSensitivityFeedback from '@/components/work/CulturalSensitivityFeedback.vue'
import FirstAustraliansIndicator from '@/components/FirstAustraliansIndicator.vue'

export default {
  name: 'PeopleView',
  mixins: [colours, utils, mappings, cultural],
  components: {
    BasicSearch,
    Tags,
    Field,
    PeopleActions,
    ResourcesContainer,
    FirstAustraliansIndicator,
    Collapse,
    Stubbable,
    Alert,
    CulturalSensitivityFeedback,
    CulturalSensitivityWarning,
    CulturalModal,
    Thumbnail,
    AnnotationList,
    ContainingLists,
  },
  metaInfo() {
    if (this.notfound) {
      return { title: 'Person/Organisation not found' }
    } else {
      return { title: this.name + ' - People and organisations' }
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    includeraw: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.updatePerson()
      }
    },
  },
  data() {
    return {
      person: {},
      notfound: false,
      biographies: [],
      portrait: {},
      collections: [],
      selectedCollection: null,
      showDetails: false,
      error: {},
      citation: {},
      showThumbnail: false,
    }
  },
  created() {
    let self = this

    service.landing.getCategories().then((collections) => (self.collections = collections))
    self.updatePerson()
  },
  methods: {
    updatePerson() {
      let self = this
      self.notfound = false
      self.error = {}
      self.portrait = {}
      self.scrollToTop()
      service.people
        .get(this.id, this.includeraw)
        .then((person) => {
          self.person = person
          self.$emit(
            'update-breadcrumbs',
            [{ value: person.primaryName || 'Person: ' + self.id }],
            true
          )

          if (self.person.deleted) {
            let message =
              'The record for ' +
              (person.primaryName ? person.primaryName : self.id) +
              ' has been deleted.'
            self.notfound = true
            self.error = {
              summary: message,
              impact: 'info',
              showContent: false,
            }
          } else {
            self.portrait = self.person.thumbnailUrl
              ? {
                  url: self.person.thumbnailUrl,
                  caption: 'Portrait of ' + self.person.primaryDisplayName,
                }
              : {}

            // determine the ORCID url
            let orcid = _.get(self.person, 'resources.AU-YORCID[0].url')
            if (orcid) self.person.orcid = [orcid]

            self.biographies = _.sortBy(self.person.biographies, [
              function (o) {
                return -o.bioghist.length
              },
            ])
          }
        })
        .catch(function (error) {
          self.notfound = true
          self.handleError(error, 'record', self.id, 'retrieve the requested record')

          self.$emit('update-breadcrumbs', [{ value: 'Person/Org not found' }], true)
        })
    },
    selectedCollectionUpdated(selectedCollection) {
      this.selectedCollection = selectedCollection
    },
    search(query) {
      var self = this

      if (!query || !query.keyword || _.isEmpty(_.trim(query.keyword))) {
        return
      }

      this.$router.push({
        name: self.selectedCollection ? 'Search' : 'SearchAll',
        params: self.selectedCollection
          ? {
              urlNamespace: self.selectedCollection.urlNamespace,
              urlKey: self.selectedCollection.urlKey,
            }
          : {},
        query: query,
      })
    },
    toUrl(values) {
      return _(values)
        .map((value) => {
          return { value: value, url: value }
        })
        .value()
    },
    link(identifier) {
      return {
        name: 'People',
        params: {
          id: identifier,
        },
      }
    },
    occupationLink(occupation) {
      return service.landing
        .getCategories()
        .then((c) => {
          return _.find(c, function (ci) {
            return ci.indexes.indexOf('occupation') != -1
          })
        })
        .then((category) => {
          return {
            name: 'Search',
            params: {
              urlNamespace: category.urlNamespace,
              urlKey: category.urlKey,
            },
            query: {
              keyword: 'occupation:"' + occupation.replace(/["']/g, '') + '"',
            },
          }
        })
    },
    scrollToTop() {
      this.$nextTick(() => {
        scroll.scrollTo('body', 500)
      })
    },
  },
  computed: {
    stubbed() {
      return _.isEmpty(this.person) && !this.notfound
    },
    blurPortrait() {
      return this.person.culturallySensitive && this.suppressContent(this.person.id)
    },
    name() {
      return this.person.primaryName
    },
    format() {
      return this.person.primaryFormat
    },
    formatIcon() {
      return this.mapFormatIcon(this.format)
    },
    primaryBiography() {
      let primary = _.head(this.biographies)
      if (primary) {
        return primary
      } else return null
    },
    otherBiographies() {
      return _(this.biographies)
        .tail()
        .filter((biography) => biography.id.indexOf('http') > -1)
        .sortBy(['org'])
        .value()
    },
    isThumbnail() {
      return this.portrait && !_.isUndefined(this.portrait.url) && this.showThumbnail
    },
    fields() {
      var self = this
      var v = this.person

      var filterFields = function (toFilter) {
        return _(toFilter)
          .filter(
            (field) =>
              (!field.predicate || field.predicate()) && field.content && !_.isEmpty(field.content)
          )
          .value()
      }

      var fields = [
        {
          title: 'Also known as',
          primary: true,
          orientation: 'horizontal',
          content: v.names,
        },
        { title: 'ORCID', content: this.toUrl(v.orcid) },
        { title: 'Wikipedia entry', content: this.toUrl(v.wikipediaURLs) },
        {
          title: 'Worldcat Identities',
          content: this.toUrl(v.worldcatIdentitiesURLs),
        },
        { title: 'VIAF', content: this.toUrl(v.viafURLs) },
        {
          title: 'Libraries Australia',
          content: this.toUrl(v.librariesAustraliaURLs),
        },
        {
          title: 'NLA Persistent Identifier',
          content: this.toUrl(['http://nla.gov.au/nla.party-' + v.id]),
        },
        {
          title: 'Raw Record',
          predicate: () => self.includeraw && v.rawRecord,
          content: v.rawRecord,
          code: 'xml',
        },
      ]

      return filterFields(fields)
    },
    primaryFields() {
      return _(this.fields)
        .filter((field) => field.primary)
        .value()
    },
    otherFields() {
      return _(this.fields)
        .filter((field) => !field.primary)
        .value()
    },
    hasPrimary() {
      return this.primaryFields.length > 0 || this.primaryBiography != null
    },
    hasOtherFields() {
      return this.otherFields.length > 0
    },
    partners() {
      let partners = this.person.contributors
      return _.isEmpty(partners) ? null : _.sortBy(partners, ['name'])
    },
  },
}
</script>
