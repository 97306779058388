<template>
  <section
    class="collection-result"
    :class="noResults && !searchDelayed ? '' : 'results-bottom-margin'"
    v-observe-visibility="{ callback: visibilityChanged }">
    <div class="d-flex result-header justify-content-between">
      <h2 class="bevel purple">{{ collection.name }}</h2>
      <div class="d-flex">
        <span class="total-results" v-if="!loading && !searchDelayed">{{
          totalResultsString
        }}</span>
        <span class="total-results" v-if="searchDelayed"
          >Click the search button below to load results</span
        >
        <Sorter
          v-if="!isSummary && !isWebsites && !noResults"
          :initialOrder="currentQuery.sortBy"
          @order-changed="updateSortOrder" />

        <div class="d-flex flex-column">
          <b-form-group
            label="Explain"
            label-class="sr-only"
            v-if="isDataManager && !loading && !isSummary && !noResults"
            class="explain-option">
            <b-form-checkbox size="sm" v-model="currentQuery.explain" @change="updateExplainOption"
              >Explain search</b-form-checkbox
            >
          </b-form-group>
          <b-form-group
            label="Select"
            label-class="sr-only"
            v-if="canSelect && authenticated && !loading && !isSummary && !noResults"
            class="explain-option">
            <b-form-checkbox size="sm" v-model="selectEnabled">Select works</b-form-checkbox>
          </b-form-group>
        </div>
      </div>
    </div>

    <SearchResultDownload v-if="canDownload && !isSummary"
                          :collection="collection"
                          :expected-record-count="resultsPage.totalRecords"/>

    <div v-show="selectEnabled && !isSummary && works && works.length > 0">
      <b-form-checkbox v-model="allResultsSelected" v-if="canSelect" switch class="select-all"
        >Select all results</b-form-checkbox
      >
      <BulkOperations
        :selectedResults="selectedResults"
        :allResults="works"
        @bulk-operation-succeeded="bulkOperationSucceeded"
        @bulk-operation-failed="bulkOperationFailed"
        @bulk-operations-active="bulkOperationsActive" />
    </div>

    <template v-if="searchDelayed">
      <div class="results-container" v-html="collection.catchline"></div>
      <div class="see-all-results">
        <div class="grey-accent">
          <b-button
            variant="see-all-results"
            :to="generateCollectionLink(collection)"
            @click="seeAllResults"
            :title="'Search ' + collection.name.toLowerCase() + ' for ' + currentQuery.keyword"
            ><span class="long-text">{{ searchDelayedText }}</span></b-button
          >
        </div>
      </div>
    </template>
    <template v-else>
      <div class="error" v-if="error && error.summary">
        <Alert :alert="error"></Alert>
      </div>

      <template v-if="resultsLoaded && searchError">
        <SearchError
          :search-error="searchError"
          v-if="searchError"
          :key="collection.id"></SearchError>
      </template>
      <template v-else-if="resultsLoaded && noResults && !searchError">
        <NoResultsPanel v-if="noResults && !isSummary"></NoResultsPanel>
      </template>
      <template v-else>
        <template v-if="resultComponent().isContainer">
          <component
            :is="resultComponent()"
            :search-error="searchError"
            :results="works"
            :isSummary="isSummary"
            :selectedResultIds="selectedResultIds"
            :canSelect="selectEnabled && canSelect"
            @selected="resultSelected" />
        </template>
        <template v-else>
          <div class="results-container" role="list">
            <component
              :is="resultComponent(result)"
              v-for="(result, index) in works"
              :key="result ? result.id : index"
              :result="result"
              :index="index"
              :isSummary="isSummary"
              :isResultSelected="isResultSelected(result)"
              :canSelect="selectEnabled && canSelect"
              @selected="resultSelected" />
          </div>
        </template>

        <component
          :is="paginationComponent"
          v-if="showPagination"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :pageSize="pageSize"
          :numRecords="numRecords"
          :hasMore="hasMore"
          :linkGenerator="paginationLinkGenerator" />
      </template>

      <div class="see-all-results" v-if="!noResults && isSummary">
        <div class="grey-accent">
          <b-button
            variant="see-all-results"
            :to="generateCollectionLink(collection)"
            @click="seeAllResults"
            >See all {{ collection.name.toLowerCase() }} results</b-button
          >
        </div>
      </div>
    </template>

    <b-modal
      id="clearSelectionModal"
      @ok="clearSelectionModalProceed"
      @close="clearSelectionModalCancel"
      @cancel="clearSelectionModalCancel">
      <template v-slot:modal-title>Move away from this page?</template>

      <h4>Are you certain?</h4>
      <p>
        You have selected some results. If you navigate away from this page your selection will be
        lost.
      </p>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="link" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="ok">Yes, navigate to other page</b-button>
      </template>
    </b-modal>
  </section>
</template>

<style scoped lang="scss">
$thumbnail-dimension: 145px;

.results-container {
  overflow: auto;
}

.pagination {
  margin-top: 16px;
}

.thumbnail {
  width: $thumbnail-dimension;
  height: $thumbnail-dimension;
  overflow: hidden;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
}

.explain-option {
  margin-left: 0.5rem;
  margin-bottom: 0rem;
}

.results-bottom-margin {
  margin-bottom: 10em;

  @media (max-width: 575px) {
    margin-bottom: 5em;
  }
}

h2.bevel {
  font-size: 1.5rem !important;
  font-family: 'Source Sans Pro', sans-serif;
  &:before {
    top: 0.14em !important;
  }
}

@supports (font-variation-settings: normal) {
  h2.bevel {
    font-family: 'Source Sans Variable', sans-serif;
  }
}

.result-header {
  margin-bottom: 1.5em;

  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
}

.total-results {
  margin-top: 0;
  font-style: italic;
  color: colour(grey-3);
  font-size: 1rem;
  margin-right: 1rem;
}

.see-all-results {
  margin-top: 3em;
  position: relative;
  float: right;

  .grey-accent {
    width: 25em;
  }

  button,
  .btn-see-all-results {
    color: colour(green);
    border: 2px solid colour(green);
    transition: 0.5s ease-in-out;
    font-weight: 600;

    &:hover,
    &:focus {
      background: colour(green);
      color: colour(white);
    }

    .long-text {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  @media (max-width: 575px) {
    float: none;
    .grey-accent {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }

  .grey-accent::before {
    @include bevel-bar(500%, $primary-button-height, -510%, 0em, colour(grey));
  }
}

.v-spinner {
  position: relative;
  top: -1em;
  width: 100%;
}

.select-all {
  margin-bottom: 1rem;
  margin-left: 1rem;
}
</style>

<script>
import _ from 'lodash'
import utils from '@/mixins/utils'
import currentRoute from '@/mixins/currentRoute'
import navigation from '@/mixins/navigation'
import auth from '@/mixins/auth'
import search from '@/mixins/search'
import service from '@/service/service'
import SearchResult from '@/components/search/SearchResult.vue'
import ImageResultContainer from '@/components/search/ImageResultContainer.vue'
import NewspaperResult from '@/components/search/NewspaperResult.vue'
import PeopleResult from '@/components/search/PeopleResult.vue'
import WebsiteResult from '@/components/search/WebsiteResult.vue'
import ListResult from '@/components/search/ListResult.vue'
import Paginator from '@/components/search/Paginator.vue'
import BasicPaginator from '@/components/search/BasicPaginator.vue'
import NoResultsPanel from '@/components/search/NoResultsPanel.vue'
import SearchError from '@/components/search/SearchError.vue'
import Sorter from '@/components/search/Sorter.vue'
import Alert from '@/components/Alert.vue'
import BulkOperations from '@/components/bulk/BulkOperations.vue'
import SearchResultDownload from '@/components/search/SearchResultDownload.vue'

const DEFAULT_BENTO_BOX_RESULTS_SIZE = 3
const DEFAULT_PAGE_SIZE = 20

const directToDeliveryMapping = {
  WEBSITE: (query) => {
    if (query.keyword) {
      if (query.keyword.startsWith('https:') || query.keyword.startsWith('http:')) {
        return service.search.lookupUrl(query.keyword).then((result) => {
          if (!_.isEmpty(result.works) && result.works[0]) {
            return result.works[0].deliveryUrl
          }
        })
      }
    }
  },
}

// map worktype to resulttype here
const resultMapping = {
  website: { component: WebsiteResult },
  work: { component: SearchResult },
  newspaper: { component: NewspaperResult },
  people: { component: PeopleResult },
  list: { component: ListResult },
}

// override if special formatting asked for (tile)
const resultMappingUiHint = {
  IMAGE_TILE: {
    component: ImageResultContainer,
    notSummary: false,
    bentoBoxResultsSize: 8,
  },
}

const blankResults = {
  works: [],
  numRecords: 0,
  totalRecords: 0,
  maxRecords: 0,
  startPos: 0,
  pageSize: 0,
}

export default {
  name: 'CollectionSearchResult',
  mixins: [utils, search, auth, currentRoute, navigation],
  props: {
    collection: {
      type: Object,
      required: true,
    },
    isSummary: {
      type: Boolean,
      required: false,
      default: true,
    },
    immediateSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    NoResultsPanel,
    SearchError,
    Sorter,
    Alert,
    BulkOperations,
    SearchResultDownload
  },
  data() {
    return {
      loading: true,
      resultsPage: blankResults,
      resultsLoaded: false,
      searchError: undefined,
      error: {},
      selectedResultIds: [],
      selectionChangeFromChildren: false,
      allResultsSelected: false,
      canSelect: false,
      selectEnabled: false,
      clearSelectionModalProceed: () => {},
      clearSelectionModalCancel: () => {},
      clearSelectionModalVisible: false,
    }
  },
  computed: {
    canDownload() {
      return this.maximumExportSize > 0
          && this.resultsLoaded
          && !this.noResults
          && !this.searchError;
    },
    searchDelayedText() {
      return `Search ${this.collection.name.toLowerCase()}${
        this.currentQuery.keyword ? ' for ' + this.currentQuery.keyword : ''
      }`
    },
    currentPage() {
      if (this.resultsPage && this.pageSize) {
        return Math.floor(this.resultsPage.startPos / this.pageSize) + 1
      }

      return 1
    },
    fuzzyResults() {
      return (
        this.isWebsites &&
        this.resultsPage &&
        _.isArray(this.resultsPage.resultGroups) &&
        !_.isEmpty(this.resultsPage.resultGroups)
      )
    },
    paginationComponent() {
      if (this.fuzzyResults) {
        return BasicPaginator
      }

      return Paginator
    },
    numRecords() {
      return (this.resultsPage && this.resultsPage.numRecords) || this.pageSize
    },
    hasMore() {
      return (this.resultsPage && this.resultsPage.hasMoreGroups) || false
    },
    pageSize() {
      return this.resultsPage.pageSize || this.resultsPage.numRecords || 0
    },
    noResults() {
      return this.resultsPage.numRecords === 0
    },
    showPagination() {
      return !this.isSummary && this.resultsPage.totalRecords > this.pageSize
    },
    totalResultsString() {
      if (this.resultsPage.totalRecords === 0) {
        return 'No results'
      }

      if (this.resultsPage.totalRecords === 1) {
        return 'One result'
      }

      var prefix = this.fuzzyResults ? 'At least ' : ''
      return prefix + this.resultsPage.totalRecords.toLocaleString() + ' total results'
    },
    totalRows() {
      return Math.min(this.resultsPage.totalRecords, this.resultsPage.maxRecords - this.pageSize)
    },
    works() {
      if (
        this.resultsPage &&
        _.isArray(this.resultsPage.works) &&
        !_.isEmpty(this.resultsPage.works)
      ) {
        return this.resultsPage.works
      }

      if (
        this.resultsPage &&
        _.isArray(this.resultsPage.resultGroups) &&
        !_.isEmpty(this.resultsPage.resultGroups)
      ) {
        var works = []

        this.treeSearch(
          this.resultsPage.resultGroups,
          (node) => (node.subGroups || []).concat(node.works || []),
          (node) => node.id,
          (work, parent, depth, ancestors) => {
            var ancestorFields = _(ancestors || []).reduce((fields, ancestor) => {
              if (ancestor.fieldName && ancestor.fieldValue) {
                fields[ancestor.fieldName] = ancestor.fieldValue
              }

              // eslint-disable-next-line no-prototype-builtins
              if (ancestor.hasOwnProperty('totalFound')) {
                fields.totalFound = ancestor.totalFound
              }

              return fields
            }, {})

            works.push(_.merge(ancestorFields, work))
          }
        )

        return works
      }

      return []
    },
    searchDelayed() {
      return !this.isSummary ? false : this.collection.searchDelay
    },
    selectedResults() {
      var self = this
      return _.map(this.selectedResultIds, (id) => {
        return _.find(self.works, (work) => work.id === id)
      })
    },
  },
  watch: {
    $route(to, from) {
      var self = this
      if (this.clearSelectionModalVisible) {
        return
      }

      if (!_.isEmpty(this.selectedResultIds)) {
        this.clearSelectionModalProceed = () => {
          self.clearSelectionModalVisible = false
          self.clearSelection()
          self.$router.push(to)
        }

        this.clearSelectionModalCancel = () => {
          self.clearSelectionModalVisible = false
        }

        this.$bvModal.show('clearSelectionModal')
        this.clearSelectionModalVisible = true

        self.$router.push(from)
      }
    },
    allResultsSelected: {
      immediate: true,
      handler(selected) {
        if (this.selectionChangeFromChildren) {
          this.selectionChangeFromChildren = false
          return
        }

        if (selected) {
          this.selectedResultIds = _.map(this.works, (work) => work.id)
        } else {
          this.selectedResultIds = []
        }
      },
    },
    selectEnabled() {
      if (!this.selectEnabled) {
        this.clearSelection()
      }
    },
    currentQuery(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.resultsLoaded = false
        this.doSearch()
      }
    },
    isSummary() {
      this.resultsLoaded = false
      this.doSearch()
    },
    resultsPage() {
      let results = this.isWebsites
        ? _.assign({}, _.omit(this.resultsPage, ['facets']), { facets: [] })
        : this.resultsPage
      this.$emit('search-result', results)
    },
  },
  mounted() {
    if (this.immediateSearch) {
      this.doSearch()
    }
  },
  methods: {
    clearSelection() {
      this.allResultsSelected = false
      this.selectedResultIds = []
    },
    recalculateAllResultsSelected() {
      var works = this.works
      this.allResultsSelected =
        !_.isEmpty(this.selectedResultIds) &&
        !_.isEmpty(works) &&
        this.selectedResultIds.length === works.length
    },
    isVisible() {
      return this.$el ? this.isElementInViewport(this.$el) : false
    },
    visibilityChanged() {
      if (this.isVisible() && !this.resultsLoaded) {
        this.doSearch()
      }
    },
    mapping(work) {
      return (
        (!this.isSummary && resultMappingUiHint[this.collection.uiSearchType]) ||
        resultMapping[work ? work.workType : null] || {
          component: SearchResult,
        }
      )
    },
    resultComponent(work) {
      var mapping = this.mapping(work)

      if (mapping && (!mapping.notSummary || !this.isSummary)) {
        return mapping.component
      }

      return SearchResult
    },
    thumbnailStyle(result) {
      return {
        'background-image': "url('" + result.url + "')",
      }
    },
    executeSearch(self, limits, pageSize) {
      // extension point - override
      return service.search.queryCollection(
        self.collection.id,
        self.queryToString(self.currentQuery),
        limits,
        self.currentQuery.startPos,
        pageSize,
        self.currentQuery.pageTotals,
        self.currentQuery.sortBy,
        self.currentQuery.explain
      )
    },
    doSearch() {
      let self = this
      this.searchError = undefined

      if (this.resultsLoaded) {
        return Promise.resolve()
      }

      let resultsSize =
        this.mapping && this.mapping.bentoBoxResultsSize
          ? this.mapping.bentoBoxResultsSize
          : DEFAULT_BENTO_BOX_RESULTS_SIZE
      let pageSize = !this.isSummary ? this.currentQuery.pageSize || DEFAULT_PAGE_SIZE : resultsSize
      let limits = this.getLimitsFromQuery(this.currentQuery)

      this.loading = true
      this.resultsPage = blankResults
      this.resultsPage.works = new Array(pageSize).fill(null)

      if ((this.isVisible() || this.immediateSearch || !this.isSummary) && !this.resultsLoaded) {
        if (!this.searchDelayed) {
          this.$emit('search-initiated', this.collection.name)

          if (
            !this.isSummary &&
            _.isFunction(directToDeliveryMapping[this.collection.searchHandler])
          ) {
            Promise.resolve(
              directToDeliveryMapping[this.collection.searchHandler](this.currentQuery)
            ).then((deliveryUrl) => {
              if (deliveryUrl) {
                window.location.replace(deliveryUrl)
              }
            })
          }

          return this.executeSearch(this, limits, pageSize)
            .then((response) => {
              self.$emit('facets-updated', self.isWebsites ? [] : response.facets)

              //if (response.totalRecords < self.currentQuery.startPos) {
              //    var lastPage = Math.ceil(response.totalRecords / response.pageSize);
              //    self.navigateToPage(lastPage, response.pageSize);
              //} else {
              self.resultsPage = response
              //}

              self.resultsLoaded = true
              self.loading = false

              if (localStorage.getItem('scrollRoute') === self.$route.fullPath) {
                window.scrollTo(0, localStorage.getItem('scrollPosition') || 0)
                localStorage.setItem('scrollPosition', 0)
                localStorage.setItem('scrollRoute', '')
              }
            })
            .catch((error) => {
              self.$emit('facets-updated', [])
              if (error && error.response) {
                if (error.response.status === 400) {
                  // at present, all 400 bad request search errors are considered 'global'
                  // (i.e. the same error would occur for all collections). This may change in future.
                  self.$emit('global-search-error', error.response.data)
                }
                self.searchError = error.response.data
              } else {
                self.searchError = 'An unexpected error occurred while searching'
              }
              self.resultsLoaded = true
              self.loading = false
            })
        }
      }

      return Promise.resolve()
    },
    seeAllResults() {
      service._gtm.interaction(this, {
        category: 'Search',
        action: 'See All: ' + this.collection.name,
      })
    },
    paginationLinkGenerator(newPage, pageSize) {
      if (
        this.resultsPage &&
        (this.resultsPage.previousPageTotals || this.resultsPage.nextPageTotals)
      ) {
        return this.generateSearchPaginationLink(
          newPage,
          pageSize || this.pageSize,
          newPage > this.currentPage
            ? this.resultsPage.nextPageTotals
            : this.resultsPage.previousPageTotals
        )
      } else {
        return this.generateSearchPaginationLink(newPage, pageSize || this.pageSize)
      }
    },
    updateSortOrder(order) {
      this.$emit('order-changed', order)
    },
    isResultSelected(result) {
      return Boolean(_.find(this.selectedResultIds, (id) => id === result.id))
    },
    resultSelected(result, selected) {
      if (selected) {
        this.selectedResultIds.push(result.id)
      } else {
        this.selectionChangeFromChildren = true
        this.selectedResultIds = _.reject(this.selectedResultIds, (id) => id === result.id)
      }

      this.recalculateAllResultsSelected()
    },
    bulkOperationSucceeded(message) {
      this.resultsPage = blankResults
      this.resultsLoaded = false
      this.searchError = undefined
      this.clearSelection()

      this.error = {
        summary: message,
        impact: 'info',
      }

      return this.doSearch()
    },
    bulkOperationFailed(message) {
      this.error = {
        summary: message,
        impact: 'error',
      }
    },
    bulkOperationsActive(active) {
      this.canSelect = !this.isSummary && active
    },
    updateExplainOption(explain) {
      this.$emit('explain-changed', explain)
    },
  },
}
</script>
