<template>
  <div>
    <APIControls :api-details="apiDetails" @update-details="u => $emit('update-details', u)" :alert-only="true"/>
    <div class="section" v-for="section in sectionsForDisplay" :key="section.heading">
      <h1 class="bevel">{{ section.heading }}</h1>
      <div class="field" v-for="field in section.fields" :key="field.key">
        <div class="label">{{ field.label }}</div>
        <template v-if="field.update">
          <div class="d-flex flex-row" :class="{expanded: field.key === 'purpose' && !purposeCollapsed}">
            <EditableText
              class="value"
              :class="field.key"
              :label="field.label"
              :error-text="field.errorText"
              :value="field.value"
              :textarea="field.textarea"
              :input-width="field.textarea ? '40rem' : '20rem'"
              :tooltip="field.tooltip"
              :required="field.required"
              :html="field.html"
              :validation-rules="field.rules"
              :isUpdating="isUpdating(field.key)"
              @editing="(editing) => setEditingField(field.key, editing)"
              @input="field.update"
              :disabled="!isMyProfile"></EditableText>
          </div>
          <div class="collapseLink"
               :class="purposeCollapsed ? '' : 'no-background'"
               v-if="field.key === 'purpose' && showExpandPurpose && !isEditing(field.key)">
            <b-button variant="link" @click="purposeCollapsed = !purposeCollapsed">
              {{ showMoreText }}
              <font-awesome-icon :icon="showMoreIcon" />
            </b-button>
          </div>
        </template>
        <template v-else-if="field.component">
          <component :is="field.component"
                     :api-details="apiDetails"
                     :field="field"
                     @update-details="d => $emit('update-details', d)"/>
        </template>
        <template v-else>
          <div class="value">{{ field.value }}</div>
        </template>
      </div>
    </div>
    <div class="section">
      <h1 class="bevel">Documentation</h1>
      <div class="field">
        <a href="/about/create-something/using-api">About the Trove API</a>
      </div>
    </div>
    <div class="section">
      <h1 class="bevel">Terms of use</h1>
      <div class="field">
        <p>
          Commercial users must
          <a :href="contactUsUrl" target="_blank">contact us to make an individual agreement</a>. There is a limit
          on the number of calls you can make to the API in a given period of time.
          <a :href="apiKeyRateIncreaseRequestUrl" target="_blank">Contact us to discuss an increase to your limit</a>.
        </p>

        <p>
          You must acknowledge Trove as the source of the data. Download a
          <a href="/about/who-we-are/our-logo" target="_blank">Trove logo</a> to use on your site.
        </p>
        <p>
          Read the full
          <a href="/about/create-something/using-api/trove-api-terms-use" target="_blank">Trove API Terms of Use</a>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section {
  margin-top: 1rem;

  .bevel {
    margin-top: 0.5rem;
  }

  .field {
    margin-top: 0.8rem;

    .label {
      font-weight: bold;
      margin-right: 1rem;
    }

    .purpose {
      max-width: 60rem;
      max-height: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .collapseLink {
      position: relative;
      bottom: 4rem;
      margin-bottom: -4rem;
      background: linear-gradient(
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 1),
              rgba(255, 255, 255, 1)
      );
      width: 100%;
      height: 6rem;
      pointer-events: none;

      &.no-background {
        background: none;
      }

      button,
      a {
        padding-left: 0;
        position: absolute;
        bottom: 0;
        pointer-events: auto;

        svg {
          margin-left: 0.3em;
        }
      }
    }

    .expanded {
      .purpose {
        max-height: 1000px;
        transition: max-height 0.5s ease;
      }
    }
  }
}

::v-deep form {
  height: auto;
}
</style>

<script>
import _ from 'lodash'
import EditableText from '@/components/EditableText.vue'
import service from '@/service/service'
import profile from '@/mixins/profile'
import APIControls from "@/components/profile/APIControls.vue";

export default {
  name: 'ApiManage',
  mixins: [profile],
  components: {
    APIControls,
    EditableText,
  },
  props: {
    apiDetails: {
      type: Object,
      required: true,
    },
    contactUsUrl: {
      type: String,
      required: false,
      default: '',
    },
    apiKeyRateIncreaseRequestUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      updatingField: null,
      editingField: null,
      showExpandPurpose: false,
      purposeCollapsed: true
    }
  },
  mounted() {
    const self = this;
    this.$nextTick(() => {
      self.showExpandPurpose = document.querySelector(".purpose").clientHeight >= 250;
    });
  },
  computed: {
    sectionsForDisplay() {
      let self = this

      let keyDetails = {
        heading: 'Your API details',
        fields: {
          key: {
            label: 'Your API Key',
            required: false,
            valMap(val, details) {
              let commercialText =
                  details['commercial'] === 'Y'
                      ? 'commercial use allowed'
                      : 'non-commercial use only'
              return `${val} (${commercialText})`
            },
          },
          quota: {
            label: 'Quota',
            valMap(val) {
              let mappings = [
                { short: 'd', long: 'day' },
                { short: 'm', long: 'minute' },
                { short: 'h', long: 'hour' },
                { short: 's', long: 'second' },
                { short: 'u', long: '100 milliseconds' },
                { short: 'p', long: '10 milliseconds' },
              ]

              let longMapping = _.find(mappings, (mapping) => (val || '').includes(mapping.short))
              let explaination = longMapping ? ` (API calls per ${longMapping.long})` : ''
              return `${val}${explaination}`
            },
          },
          purpose: {
            label: 'Purpose',
            required: true,
            rules: {required: true, email: false, max: 2000},
            html: true,
            textarea: true,
            errorText: "Please tell us what you are going to use the API for",
            update(value) {
              self.update({ purpose: value })
            }
          }
        }
      };

      if (self.apiDetails.keyStatus !== 'Z') {
        keyDetails.fields.expiryDate = {
          label: "Expires on",
          component: APIControls
        };
      }

      let sections = [
        keyDetails,
        {
          heading: 'Details of the API contact person in your organisation',
          fields: {
            organisation: {
              label: 'Organisation',
              tooltip: 'The name of your organisation',
              update(value) {
                self.update({ organisation: value })
              },
            },
            contact: {
              label: 'Name',
              required: true,
              errorText: "Please enter a name",
              tooltip:
                'If Trove staff need to contact your organisation about their usage of the Trove API, ' +
                'who should we talk to? We might want to let you know about changes to the API, or to ' +
                'contact you if your API account is being misused in some way.',
              update(value) {
                self.update({ name: value }, 'contact')
              },
            },
            email: {
              label: 'Email',
              required: true,
              rules: {required: true, email: true},
              errorText: "Please enter an email address",
              tooltip: 'Email address of the Trove API contact person in your organisation',
              update(value) {
                self.update({ email: value })
              },
            },
            phone: {
              label: 'Phone',
              required: true,
              errorText: "Please enter a phone number",
              tooltip: 'Contact phone number of the Trove API contact person in your organisation',
              update(value) {
                self.update({ phone: value })
              },
            },
          },
        },
      ]

      return _.map(sections, (section) => {
        return _.assign({}, section, {
          fields: self.mapForDisplay(section.fields),
        })
      })
    },
    showMoreText() {
      return this.purposeCollapsed ? 'Show more' : 'Show less'
    },
    showMoreIcon() {
      return this.purposeCollapsed
          ? { prefix: 'fas', iconName: 'caret-down' }
          : { prefix: 'fas', iconName: 'caret-up' }
    },
  },
  methods: {
    isUpdating(field) {
      return this.updatingField && this.updatingField === field
    },
    isEditing(field) {
      return this.editingField === field
    },
    setEditingField(field, isEditing) {
      if (isEditing) {
        this.editingField = field;
      } else {
        this.editingField = null;
      }
    },
    update(details, fieldKey) {
      let self = this
      self.updatingField = fieldKey || _(details).keys().first();

      service.profile
        .updateAPIDetails(details)
        .then((updatedDetails) => {
          self.$emit('update-details', updatedDetails);
          if (self.updatingField === "purpose") {
            this.$nextTick(() => {
              self.showExpandPurpose = document.querySelector(".purpose").clientHeight >= 250;
            });
          }
          self.updatingField = null;
        })
        .catch(() => {
          self.updatingField = null;
        });
    },
    mapForDisplay(mappings) {
      return _(mappings)
        .pickBy((mapping, key) => {
          return this.apiDetails[key]
        })
        .map((mapping, key) => {
          let val = this.apiDetails[key]
          return {
            key: key,
            label: mapping.label,
            value: mapping.valMap ? mapping.valMap(val, this.apiDetails) : val,
            tooltip: mapping.tooltip,
            update: mapping.update,
            textarea: mapping.textarea,
            required: mapping.required,
            errorText: mapping.errorText,
            component: mapping.component,
            rules: mapping.rules,
            html: mapping.html,
          }
        })
        .value()
    },
  },
}
</script>
