<template>
  <div class="edit-note">
    <div class="form">
      <ValidationObserver ref="observer" tag="div" key="editNote">
        <ValidationProvider
          rules="required"
          v-slot="{ errors, classes }"
          name="You must provide some text in the note">
          <b-form-group label="Your list item note" label-class="font-weight-bold">
            <b-form-textarea
              :class="classes"
              v-model="item.note"
              :required="true"
              :maxlength="maxLength"
              rows="3"
              max-rows="6"></b-form-textarea>
          </b-form-group>
          <Countdown :count="length" :max="maxLength" :warningCount="800" />
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>

        <div class="buttons">
          <b-button variant="link" @click="cancel" v-if="showCancel">Cancel</b-button>
          <b-button @click="save" :disabled="inProgress">Save</b-button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Countdown from '@/components/Countdown.vue'

export default {
  name: 'EditListItemNote',
  components: {
    ValidationProvider,
    ValidationObserver,
    Countdown,
  },
  props: {
    existing: {
      type: Object,
      required: true,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 4000,
    },
    inProgress: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      item: {
        note: undefined,
      },
      editingMode: false,
      saving: false,
    }
  },
  mounted() {
    this.reset()
  },
  computed: {
    length() {
      return this.maxLength - (this.item && this.item.note ? this.item.note.length : 0)
    },
  },
  methods: {
    save() {
      let self = this
      self.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          self.$emit('save', self.item)
        }
      })
    },
    cancel() {
      this.$emit('cancel')
    },
    reset() {
      _.assign(this.item, this.existing)
      this.item.note = _.replace(this.item.note, /<br\/>/g, '\n')
    },
  },
}
</script>
<style scoped lang="scss">
.edit-note {
  .form {
    .invalid {
      border: solid 1px colour(form-field-error);
    }

    .error {
      color: colour(form-field-error);
    }
  }

  .buttons {
    text-align: right;
  }

  .close button {
    color: colour(purple);
    padding-right: 0;
    padding-top: 0;
    height: 2.5em;
    min-height: 2.5em;
    min-width: 6em;

    &:hover,
    &:active {
      color: colour(magenta);
      text-decoration: underline;
    }

    svg {
      font-size: 22px !important;
      margin-left: 5px;
      position: relative;
      top: 2px;
    }
  }
}
</style>
