<template>
  <div class="profile-downloads">
    <FilterableList
        ref="downloadList"
        class="profile-download-list"
        :filterConfigs="filterConfigs"
        :filtered="filtered"
        :items="items"
        :loading="loading"
        itemName="download"
        itemNamePlural="downloads"
        :paginationInfo="paginationInfo"
        :show-search="false"
        :show-total="false"
        @get-items="getItems"
        @page-changed="pageChanged"
        @reset-filters="resetFilters">
      <template v-slot:header="data">
        <span class="count">{{ data.items.length | formatNumber }}</span>
        <span class="header-text">of {{maxAllowed}} downloads</span>
      </template>

      <template v-slot:no-items>
        You do not have any recent downloads.
      </template>

      <template v-slot:item="data">
        <div class="metadata">
          <span class="date">{{ getFormattedDate(data.item) }}</span>
        </div>

        <div class="summary">
          <h2 class="download-title">
            {{data.item.name}}
          </h2>
          <div class="download-category">{{Math.min(maximumExportSize, data.item.expectedRecordCount) | formatNumber}} records from {{data.item.categoryName}}</div>
          <div class="download-description">{{data.item.description}}</div>
          <div class="download-controls">
            <b-link :href="createDownloadLink(data.item)" target="_blank" v-if="readyForDownload(data.item)">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'file-download' }" />
              Download
            </b-link>
            <b-link variant="link" @click="() => retry(data.item.id)" v-if="hasFailed(data.item)">Retry</b-link>
            <b-link variant="link" @click="() => cancel(data.item.id)">{{canCancel(data.item) ? 'Cancel' : 'Delete'}}</b-link>
          </div>
        </div>
        <div class="status">
          {{data.item.statusLabel}}
        </div>
      </template>

      <template v-slot:stub>
        <div class="d-flex stub" style="padding-bottom: 2.5rem">
          <div class="left" style="width: 5rem">
            <div class="line" style="width: 90%"></div>
            <div class="line" style="width: 50%"></div>
          </div>
          <div class="body" style="width: 100%">
            <div class="line" style="width: 50%"></div>
            <div class="line" style="width: 60%"></div>
            <div class="line" style="width: 20%"></div>
          </div>
        </div>
      </template>
    </FilterableList>

    <div class="notice">
      <p>Note: downloads will be automatically deleted {{daysToLive}} day{{daysToLive !== 1 ? 's' : ''}} after they were created. Please ensure you have downloaded the file before then.</p>
      <p>For more information, please visit the <a :href="downloadHelpUrl" target="_blank">Trove Help</a> page.</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.profile-downloads {
  .profile-download-list {
    padding: 1rem 1rem 0;

    .metadata {
      width: 6rem;
      margin-right: 1rem;
      text-align: left;
    }

    .summary {
      flex: 1;
      margin-right: 2rem;

      .download-title {
        font-family: 'Source Sans Pro', sans-serif !important;
        font-size: 1.25rem;
        font-weight: 600;
      }

      .download-category {
        font-style: italic;
      }

      .download-controls {
        margin-top: 0.5rem;

        a {
          margin-right: 2rem;
        }
      }
    }

    .status {
      flex: 0;
      margin-right: 1rem;
    }
  }

  .notice {
    margin-left: 1rem;
    margin-top: 3rem;
  }
}
</style>

<script>
import _ from 'lodash'
import moment from 'moment'
import service from '@/service/service'
import profile from '@/mixins/profile'
import FilterableListPage from '@/components/profile/lists/FilterableListPage.vue'
import FilterableList from '@/components/profile/lists/FilterableList.vue'

export default {
  name: 'UserProfileDownloads',
  extends: FilterableListPage,
  mixins: [profile],
  components: {
    FilterableList,
  },
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    return { title: 'My profile' }
  },
  data() {
    return {
      maxAllowed: 0,
      daysToLive: 0,
      downloadHelpUrl: undefined
    }
  },
  created() {
    let self = this

    service.config.environment().then((environment) => {
      self.maxAllowed = environment.maxAllowedSearchDownloads;
      self.daysToLive = environment.searchDownloadsDaysToLive;
      self.downloadHelpUrl = environment.downloadHelpUrl || environment.searchHelpUrl;
    });
  },
  computed: {
    hasActiveAPIKey() {
      return this.profileInfo && this.profileInfo.hasAPIKey && this.profileInfo.apiDetails.keyStatus === 'C';
    },
    maximumExportSize() {
      return this.authenticated && this.myUserProfile ? this.myUserProfile.maximumExportSize : 0;
    },
  },
  methods: {
    getFormattedDate(download) {
      return _.toUpper(moment(download.created).format('DD MMM YYYY h:mm a'))
    },
    getFromService() {
      return service.profile.listSearchDownloads();
    },
    hasFailed(download) {
      return download.status === "FAILED";
    },
    readyForDownload(download) {
      return ["COMPLETED", "DOWNLOADED"].indexOf(download.status) > -1;
    },
    canCancel(download) {
      return ["FAILED", "IN_PROGRESS", "QUEUED"].indexOf(download.status) > -1;
    },
    cancel(id) {
      let self = this
      service.profile.deleteSearchDownload(id).then(self.refresh);
    },
    retry(id) {
      let self = this
      service.profile.retrySearchDownload(id).then(self.refresh);
    },
    refresh() {
      service.profile.activeSearchDownloadCount().then(c => self.count = c);
      this.$refs.downloadList.getItems();
    },
    createDownloadLink(download) {
      return service.config.baseAPIURL + '/profile/downloads/' + download.id;
    }
  },
  mounted() {
    if (!this.isMyProfile) {
      this.$router.push('/')
    }
  },
}
</script>
