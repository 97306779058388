<template>
  <b-container fluid class="support-horizontal" v-if="horizontal">
    <b-row>
      <b-col
        v-for="supportArticle in items"
        :key="supportArticle.id"
        @click="articleClick(supportArticle)"
        class="hover">
        <b-row class="h-100">
          <b-col class="d-flex info flex-column">
            <div>
              <h3 class="title">{{ supportArticle.title }}</h3>
            </div>
            <div class="flex-fill">
              <b-img
                :alt="supportArticle.artworkImageAlt"
                :src="supportArticle.artworkUrl"
                class="artwork" />
              <p class="heading">{{ supportArticle.catchline }}</p>
            </div>
            <div>
              <div class="small-bevel" :class="randomColourName()"></div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>

  <b-container fluid class="support-vertical" v-else>
    <b-row
      no-gutters
      class="mt-4 hover"
      v-for="supportArticle in items"
      :key="supportArticle.id"
      @click="articleClick(supportArticle)">
      <b-col md="auto">
        <b-img
          class="artwork"
          :alt="supportArticle.artworkImageAlt"
          :src="supportArticle.artworkUrl" />
      </b-col>

      <b-col class="info d-flex flex-column">
        <div class="align-top">
          <h3 class="title">{{ supportArticle.title }}</h3>
        </div>
        <div class="flex-fill align-center">
          <p class="heading">{{ supportArticle.catchline }}</p>
        </div>
      </b-col>
      <div class="bevel-wrapper">
        <div class="small-bevel mt-2" :class="randomColourName()"></div>
      </div>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
.hover {
  cursor: pointer;
  &:hover,
  &:focus {
    .title {
      text-decoration: underline;
    }
  }
}

.support-horizontal {
  padding-left: 0px;

  .info {
    .title {
      font-weight: bold;
      font-size: 1.25em;
    }
  }

  .artwork {
    height: 200px;
    width: 200px;
    object-fit: cover;
  }
}

.support-vertical {
  padding-left: 0px;

  .info {
    .title {
      font-weight: bold;
      font-size: 1.25em;
    }
  }

  .heading {
    text-transform: uppercase;
    color: colour(grey-3);
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    margin-bottom: 0;
  }

  @supports (font-variation-settings: normal) {
    .heading {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }

  .artwork {
    height: 100px;
    width: 100px;
    object-fit: cover;
    margin-right: 1em;
  }
  .bevel-wrapper {
    display: block;
    width: 100%;
  }
}
</style>

<script>
import colours from '@/mixins/colours'

export default {
  name: 'SupportArticleList',
  mixins: [colours],
  components: {},
  props: {
    followExtUrl: {
      type: Boolean,
      required: false,
      default: true,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array, // of SupportArticles
      required: true,
    },
  },
  methods: {
    articleClick(itemId) {
      if (this.followExtUrl) {
        if (itemId.externalUrl) {
          this.$router.push(itemId.externalUrl)
        }
      } else {
        this.$emit('article-follow', itemId.id)
      }
    },
  },
}
</script>
