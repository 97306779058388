<template>
  <div>
    <h1 v-if="selectedCollection">
      <span class="sr-only">Search results for: </span>{{ collectionHeader }}
    </h1>
    <h1 v-else class="sr-only">Search results for all collections</h1>

    <div v-if="!advanced" :style="basicSearchStyle">
      <div ref="basic" class="basic-search">
        <BasicSearch
          :selectedCollectionId="selectedCollectionId"
          :collections="collections"
          :displayCollections="false"
          :displayAdvancedSearch="isSearchAll"
          @collection-changed="selectedCollectionUpdated"
          @search="search">
        </BasicSearch>
      </div>
    </div>

    <div class="d-flex flex-row-reverse advanced-links">
      <template v-if="advanced">
        <b-link v-if="appliedSearch" class="search-type-toggle"
          ><div @click="expandAdvanced">Show advanced search</div></b-link
        >
        <b-link v-else-if="hasQuery" class="search-type-toggle"
          ><div @click="collapseAdvanced">Hide advanced search</div></b-link
        >
      </template>
      <b-link class="search-type-toggle" v-if="showAdvancedLink"
        ><div @click="searchTypeToggle">{{ searchTypeToggleText }}</div></b-link
      >
    </div>

    <div v-if="advanced" :style="advancedSearchStyle">
      <div ref="advanced">
        <b-collapse id="advanced-search-fields" v-model="showAdvanced">
          <component
            v-if="advancedSearchComponent && advanced"
            :is="advancedSearchComponent"
            :selectedCollectionId="selectedCollectionId"
            :collections="collections"
            @collection-changed="selectedCollectionUpdated"
            @search="search"
            @field-config-updated="fieldConfigUpdated">
          </component>
        </b-collapse>

        <div class="accent-line"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.advanced-links a:nth-child(1) {
  margin-left: 1rem;
}

.basic-search .advanced-search {
  div {
    select,
    .down {
      color: colour(purple);
    }
  }
}

a.search-type-toggle {
  padding-top: 0.5rem;
  font-weight: bold;
  font-size: 0.9rem;
  color: colour(purple) !important;
}

.accent-line {
  height: 4px;
  background: #000;
}

h1 {
  font-family: 'Source Serif Pro', serif;
  font-weight: 600;
  padding-bottom: 1.5rem;
}

@supports (font-variation-settings: normal) {
  h1 {
    font-family: 'Source Serif Variable', serif;
  }
}
</style>

<script>
import _ from 'lodash'
import currentRoute from '@/mixins/currentRoute'
import utils from '@/mixins/utils'
import search from '@/mixins/search'
import BasicSearch from '@/components/search/BasicSearch.vue'
import AdvancedSearch from '@/components/search/advanced/AdvancedSearch.vue'
import BookAdvancedSearch from '@/components/search/advanced/BookAdvancedSearch.vue'
import NewspaperAdvancedSearch from '@/components/search/advanced/NewspaperAdvancedSearch.vue'
import PeopleAndOrgAdvancedSearch from '@/components/search/advanced/PeopleAndOrgAdvancedSearch.vue'
import WebAdvancedSearch from '@/components/search/advanced/WebAdvancedSearch.vue'
import ResearchReportsAdvancedSearch from '@/components/search/advanced/ResearchReportsAdvancedSearch.vue'
import MapPictureAdvancedSearch from '@/components/search/advanced/MapPictureAdvancedSearch.vue'
// eslint-disable-next-line max-len
import CorrespondenceCollectionAdvancedSearch from '@/components/search/advanced/CorrespondenceCollectionAdvancedSearch.vue'
import MusicAudioVideoAdvancedSearch from '@/components/search/advanced/MusicAudioVideoAdvancedSearch.vue'
import MagazineAdvancedSearch from '@/components/search/advanced/MagazineAdvancedSearch.vue'

var TRANSITION_DURATION = 500

export default {
  name: 'SearchBar',
  mixins: [utils, search, currentRoute],
  components: {
    BasicSearch,
  },
  props: {
    collections: {
      type: Array,
      required: false,
    },
    selectedCollectionId: {
      type: String,
      required: false,
    },
    advanced: {
      type: Boolean,
      required: false,
      default: false,
    },
    canAdvanced: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      basicSearchHeight: 0,
      advancedSearchHeight: 0,
      sliding: false,
      showAdvanced: false,
      appliedSearch: false,
    }
  },
  mounted() {
    this.configureAdvancedOpen()
  },
  computed: {
    showAdvancedLink() {
      return this.canAdvanced && !this.excludedFromAdvancedSearch(this.selectedCollection)
    },
    advancedSearchComponent() {
      const advancedSearchComponentMapping = {
        EBTW: { component: AdvancedSearch },
        RESOURCE: { component: AdvancedSearch },
        NEWSPAPER: { component: NewspaperAdvancedSearch },
        GAZETTE: { component: NewspaperAdvancedSearch },
        NEWSPAPER_AND_GAZETTE: { component: NewspaperAdvancedSearch },
        WEBSITE: { component: WebAdvancedSearch },
        PEOPLE: { component: PeopleAndOrgAdvancedSearch },
        LIST: { component: AdvancedSearch },
        ARTICLE: { component: ResearchReportsAdvancedSearch },
        RESEARCH: { component: ResearchReportsAdvancedSearch },
        BOOK: { component: BookAdvancedSearch },
        BOOK_LIBRARY: { component: BookAdvancedSearch },
        PICTURE: { component: MapPictureAdvancedSearch },
        MUSIC: { component: MusicAudioVideoAdvancedSearch },
        COLLECTION: { component: CorrespondenceCollectionAdvancedSearch },
        MAP: { component: MapPictureAdvancedSearch },
        MAP_AND_PICTURE: { component: MapPictureAdvancedSearch },
        MAGAZINE: { component: MagazineAdvancedSearch },
      }

      if (
        this.selectedCollection &&
        advancedSearchComponentMapping[this.selectedCollection.searchHandler] &&
        advancedSearchComponentMapping[this.selectedCollection.searchHandler].component
      ) {
        return advancedSearchComponentMapping[this.selectedCollection.searchHandler].component
      } else {
        return AdvancedSearch
      }
    },
    searchTypeToggleText() {
      return this.advanced ? 'Simple search' : 'Advanced search'
    },
    selectedCollection() {
      return _(this.collections).find((collection) => collection.id === this.selectedCollectionId)
    },
    collectionHeader() {
      return this.selectedCollection ? this.selectedCollection.name : 'All'
    },
    basicSearchStyle() {
      return this.searchStyle(!this.advanced)
    },
    advancedSearchStyle() {
      return this.searchStyle(!this.appliedSearch ? this.advanced : true)
    },
  },
  methods: {
    fieldConfigUpdated(fieldConfig) {
      this.$emit('field-config-updated', fieldConfig)
    },
    selectedCollectionUpdated(selectedCollection) {
      this.$emit('collection-changed', selectedCollection)
    },
    search(query) {
      this.showAdvanced = false
      this.appliedSearch = this.advanced
      this.updateStartAdvancedOpen(false)
      this.$emit('search', query)
    },
    searchTypeToggle() {
      this.showAdvanced = !this.advanced
      this.appliedSearch = false
      this.updateStartAdvancedOpen(this.showAdvanced)

      this.$emit('search-type-changed', !this.advanced)
    },
    expandAdvanced() {
      this.showAdvanced = true
      this.appliedSearch = false
    },
    collapseAdvanced() {
      this.showAdvanced = false
      this.appliedSearch = true
    },
    configureAdvancedOpen() {
      this.showAdvanced = this.startAdvancedOpen || !this.hasQuery
      this.appliedSearch = !this.startAdvancedOpen && this.hasQuery
    },
    searchStyle(show) {
      const slideStyle = this.sliding
        ? {
            'transition-duration': TRANSITION_DURATION / 1000 + 's',
            'transition-property': 'max-height',
          }
        : {}

      return _.merge(
        {
          overflow: this.sliding || !show ? 'hidden' : 'visible',
        },
        slideStyle
      )
    },
  },
  watch: {
    startAdvancedOpen() {
      this.configureAdvancedOpen()
    },
    currentQuery() {
      if (!this.hasQuery) {
        this.updateStartAdvancedOpen(true)
      }
    },
    advanced: {
      immediate: true,
      handler() {
        var self = this
        self.sliding = true

        self.after(TRANSITION_DURATION).then(() => {
          self.sliding = false
        })
      },
    },
  },
}
</script>
