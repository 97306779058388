import utils from '@/mixins/utils'

export default {
  mixins: [utils],
  props: {
    showAvailability: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    format() {
      return this.listVisibility + ' List'
    },
    subtitle() {
      return this.numItems
    },
    listVisibility() {
      return this.result.collaborative ? 'Collaborative' : this.result.priv ? 'Private' : 'Public'
    },
    numItems() {
      return this.result.numItems === 0 || this.result.numItems > 1
        ? this.result.numItems + ' items'
        : this.result.numItems + ' item'
    },
    hasDescription() {
      return true
    },
    description() {
      // Strip HTML from the description so we don't have to work around the formatting
      // when shortening the description.
      let tpl = document.createElement('div')
      tpl.innerHTML = this.result.description ? this.result.description.trim() : ''
      return tpl.innerText
    },
    detailsLinkDescriptor() {
      return {
        name: 'List',
        params: {
          id: this.result.id,
        },
        query: this.query,
      }
    },
  },
}
