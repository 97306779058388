<template>
  <div></div>
</template>

<style lang="scss">
.user-notification {
  .date {
    display: block;
    margin-bottom: 0.5rem;
  }

  svg {
    margin-right: 0.5rem;
  }
}
</style>

<script>
import service from '@/service/service'
import moment from 'moment'

export default {
  name: 'AbstractNotificationDetail',
  props: {
    id: {
      type: Number,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      let date = moment(this.date).format('DD MMM YYYY hh:mma')
      if (date.indexOf('12:00am') > -1) {
        date = moment(this.date).format('DD MMM YYYY')
      }
      return date
    },
  },
  methods: {
    clearNotification() {
      let self = this
      service.profile.clearAlertNotificationForUser(self.id).catch((error) => {
        self.handleError(error, undefined, undefined, 'clear your notification')
      })
    },
  },
}
</script>
