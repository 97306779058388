<template>
  <div class="user-notifications">
    <h2 class="bevel" v-if="heading && (emptyMessage || notifications.length > 0 || hasOlder)">
      {{ heading }}
    </h2>
    <b-link class="clear-all" v-if="clearable && notifications.length > 0"
      ><div @click="clearAll">Clear all</div></b-link
    >

    <div
      v-if="(!notifications || notifications.length === 0) && emptyMessage"
      class="no-notifications">
      <p>{{ emptyMessage }}</p>
    </div>

    <ul class="notification-list" v-if="notifications && notifications.length > 0">
      <li
        v-for="notification in notifications"
        :key="notification.id"
        class="notification-item coloured-bottom-border compact">
        <component
          :is="getComponent(notification)"
          v-bind="{
            id: notification.id,
            date: notification.eventDate,
            details: notification.details,
            clearable: clearable,
          }" />
      </li>
    </ul>

    <p v-if="notifications.length === 0 && hasOlder">
      You have no notifications from the last {{ daysPerPage }} days.
    </p>

    <b-button v-if="hasOlder" @click="loadNextPage"> Load older </b-button>
  </div>
</template>

<style scoped lang="scss">
.user-notifications {
  padding-top: 2rem;

  .clear-all {
    float: right;
  }

  .no-notifications {
    padding-bottom: 1rem;
  }

  .notification-list {
    list-style: none;
    padding-left: 0;

    .notification-item {
      margin-bottom: 1rem;
    }
  }

  h2.bevel {
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    &:before {
      top: 0.11rem;
    }
  }
  @supports (font-variation-settings: normal) {
    h2.bevel {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }
}
</style>

<script>
import service from '@/service/service'
import _ from 'lodash'
import CollaborationRequest from '@/components/profile/notifications/CollaborationRequest'
import CollabListNoLongerCollaborative from '@/components/profile/notifications/CollabListNoLongerCollaborative'
import ListChanged from '@/components/profile/notifications/ListChanged'
import ListOwnerChanged from '@/components/profile/notifications/ListOwnerChanged'
import CollabListRoleChanged from '@/components/profile/notifications/CollabListRoleChanged'
import ListDeleted from '@/components/profile/notifications/ListDeleted'
import ListItemsChanged from '@/components/profile/notifications/ListItemsChanged'
import NotesChanged from '@/components/profile/notifications/NotesChanged'
import UserAchievement from './UserAchievement'

export default {
  name: 'UserNotifications',
  components: {
    CollaborationRequest,
    CollabListNoLongerCollaborative,
    ListChanged,
    ListOwnerChanged,
    CollabListRoleChanged,
    ListDeleted,
    ListItemsChanged,
    NotesChanged,
  },
  props: {
    heading: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
      validator(val) {
        return ['alert', 'feed'].indexOf(_.toLower(val)) > -1
      },
    },
    emptyMessage: {
      type: String,
      required: false,
    },
    clearable: {
      type: Boolean,
      required: false,
    },
    daysPerPage: {
      type: Number,
      required: false,
      default: 7,
    },
    paged: {
      type: Boolean,
      required: false,
      default: true,
    },
    collate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      notifications: [],
      notificationCountNotCollated: 0,
      total: 0,
      start: 0,
      end: 0,
      loading: false,
    }
  },
  mounted() {
    this.start = this.daysPerPage
    this.loadNotifications(this.start, this.end)
  },
  computed: {
    hasOlder() {
      return this.notificationCountNotCollated < this.total
    },
  },
  methods: {
    loadNotifications(from, to) {
      let self = this
      self.loading = true
      service.profile
        .getNotificationsForUser(self.type, from, to, self.collate)
        .then((data) => {
          self.total = data.total
          self.notificationCountNotCollated += data.notificationCountNotCollated
          self.start = data.startDay
          self.end = data.endDay
          _.forEach(data.notifications, (notification) => {
            self.notifications.push(notification)
          })
          self.loading = false
        })
        .catch((error) => {
          self.handleError(error, undefined, undefined, 'retrieve your ' + self.type + 's')
        })
    },
    loadNextPage() {
      this.end = this.start
      this.start = this.end + this.daysPerPage
      this.loadNotifications(this.start, this.end)
    },
    getComponent(notification) {
      let component

      switch (notification.category) {
        case 'COLLAB_LIST_JOIN_REQUEST':
          component = CollaborationRequest
          break
        case 'COLLAB_LIST_NOT_COLLABORATIVE':
          component = CollabListNoLongerCollaborative
          break
        case 'LIST_CHANGED':
          component = ListChanged
          break
        case 'LIST_OWNER_CHANGED':
          component = ListOwnerChanged
          break
        case 'COLLAB_LIST_ROLE_CHANGED':
          component = CollabListRoleChanged
          break
        case 'LIST_DELETED':
          component = ListDeleted
          break
        case 'LIST_ITEMS_CHANGED':
          component = ListItemsChanged
          break
        case 'NOTES_CHANGED':
          component = NotesChanged
          break
        case 'ACHIEVEMENT':
          component = UserAchievement
          break
      }

      return component
    },
    clearAll() {
      let self = this
      service.profile
        .clearAlertNotificationsForUser()
        .then(() => {
          self.total = 0
          self.notifications.splice(0, self.notifications.length)
        })
        .catch((error) => {
          self.handleError(error, undefined, undefined, 'clear your ' + self.type + 's')
        })
    },
  },
}
</script>
