<template>
  <div class="containing-lists">
    <h2 class="heading">
      {{ heading }}
      <span class="list-count" v-if="includeHeadingCount">({{ lists.length }})</span>
    </h2>
    <a href="javascript:void(0)" @click="toggleShowLists" class="collapse-link" v-if="collapsible"
      >{{ showLists ? 'Hide' : 'Show' }} {{ label }}</a
    >

    <div class="error" v-if="error && error.summary">
      <Alert :alert="error"></Alert>
    </div>
    <template v-if="lists.length === 0">
      <div class="no-lists">
        <span v-if="!saving">No {{ labelSingular }}s have been added for this record</span>
        <div v-if="saving" class="loading-spinner spinner-md"></div>
      </div>
    </template>

    <template v-else>
      <AchievementNotice :user-achievement="userAchievement"></AchievementNotice>
      <ul class="list-inline">
        <li v-for="list in lists" :key="list.id" class="list-inline-item">
          <b-button-group>
            <b-button
              variant="secondary"
              class="green"
              v-b-popover.hover.top="cleanUsername(list.userId)"
              :to="{ name: 'List', params: { id: list.id } }"
              :class="mine(list) ? '' : 'inverse'">
              <font-awesome-icon class="list-icon" :icon="{ prefix: 'fas', iconName: 'list' }" />
              {{ list.name }}
              <font-awesome-icon
                class="private-icon"
                v-if="list.priv"
                :icon="{ prefix: 'fa', iconName: 'lock' }" />
              <font-awesome-icon
                class="collaborative-icon"
                v-if="list.collaborative"
                :icon="{ prefix: 'fa', iconName: 'users' }" />
            </b-button>
            <b-button
              variant="secondary"
              class="green remove"
              v-b-popover.hover.top="'Remove from list'"
              :class="mine(list) ? '' : 'inverse'"
              :disabled="saving"
              @click="removeFromList(list.id)"
              v-if="list.editable">
              <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'times' }" />
            </b-button>
          </b-button-group>
        </li>
        <li v-if="saving" class="list-inline-item">
          <div class="loading-spinner spinner-md"></div>
        </li>
      </ul>
    </template>

    <template v-if="authenticated">
      <AddToList
        :target-id="targetId"
        :type="type"
        :format="format"
        :available-lists="available"
        :panel-open="addPanelOpen"
        @cancel="cancel"
        @saving="updateSaving"
        @lists-selected="listsSelected"
        @user-achievement="setUserAchievement"></AddToList>

      <b-button @click="toggleAddPanel" class="add-button" v-if="!addPanelOpen">
        <font-awesome-icon class="private-icon" :icon="{ prefix: 'fa', iconName: 'plus' }" />
        Add to list
      </b-button>
    </template>

    <template v-if="!authenticated">
      <b-button @click="login" variant="link" class="add-login-button">
        Login to add to list
      </b-button>
    </template>
  </div>
</template>

<style scoped lang="scss">
.containing-lists {
  padding-top: 2rem;

  .no-lists {
    padding-bottom: 1rem;
  }

  .list-collapse {
    clear: both;
  }

  .heading {
    display: inline-block;

    .list-count {
      opacity: 0.7;
      font-size: smaller;
      font-weight: 500;
    }
  }

  .list-inline-item {
    margin-bottom: 1rem;

    .btn {
      margin-right: 0;

      &.remove {
        border-left: none;

        &:not(.inverse) {
          border-left: 1px solid white !important;
        }
      }
    }
  }

  .collapse-link {
    margin-left: 2rem;
    display: inline-block;
  }

  .private-icon,
  .collaborative-icon,
  .wait-icon {
    margin-left: 0.5rem;
  }

  .list-icon {
    margin-right: 0.5rem;
  }

  .add-login-button {
    margin-top: -2rem;
    margin-left: -0.75rem;
    padding-top: 1rem;
  }
}
</style>

<script>
import service from '@/service/service'
import _ from 'lodash'
import Vue from 'vue'
import auth from '@/mixins/auth'
import Alert from '@/components/Alert.vue'
import AddToList from '@/components/lists/AddToList.vue'
import AchievementNotice from '@/components/common/AchievementNotice.vue'

export default {
  name: 'ContainingLists',
  components: {
    Alert,
    AddToList,
    AchievementNotice,
  },
  mixins: [auth],
  props: {
    heading: {
      type: String,
      required: false,
      default: 'Lists',
    },
    type: {
      type: String,
      required: true,
      validator(val) {
        return ['work', 'person', 'organisation'].indexOf(_.toLower(val)) > -1
      },
    },
    targetId: {
      type: String,
      required: true,
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false,
    },
    includeHeadingCount: {
      type: Boolean,
      required: false,
      default: false,
    },
    format: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      lists: [],
      available: [],
      loading: false,
      listsLoaded: false,
      error: undefined,
      showLists: !this.collapsible,
      addPanelOpen: false,
      saving: false,
      userAchievement: undefined,
    }
  },
  mounted() {
    this.loadLists()
  },
  watch: {
    targetId() {
      let self = this
      Vue.nextTick(() => {
        self.loadLists()
      })
    },
  },
  computed: {
    cleanType() {
      return _.toLower(this.type)
    },
    label() {
      return _.toLower(this.heading)
    },
    labelSingular() {
      return _.endsWith(this.label, 's')
        ? this.label.substring(0, this.label.length - 1)
        : this.label
    },
  },
  methods: {
    mine(list) {
      return list.userId === this.username || list.userId === this.fullUsername
    },
    loadLists() {
      let self = this
      self.loading = true
      self.listsLoaded = false
      self.lists.splice(0, self.lists.length)
      self.available.splice(0, self.available.length)
      let parseLists = (allLists) => {
        if (allLists.containing) {
          _.each(allLists.containing, (list) => {
            self.lists.push(list)
          })
        }
        if (allLists.available) {
          _.each(allLists.available, (list) => {
            if (_.findIndex(self.lists, (l) => l.id === list.id) === -1) {
              self.available.push(list)
            }
          })
        }
        self.loading = false
        self.listsLoaded = true
      }

      if (self.cleanType === 'work') {
        service.list
          .getListsContainingWork(self.targetId)
          .then((data) => {
            parseLists(data)
          })
          .catch(() => {
            self.loading = false
          })
      } else if (self.cleanType === 'person' || self.cleanType === 'organisation') {
        service.list
          .getListsContainingPersonOrOrg(self.targetId)
          .then((data) => {
            parseLists(data)
          })
          .catch(() => {
            self.loading = false
          })
      }
    },
    toggleShowLists() {
      this.showLists = !this.showLists
      if (this.showLists && !this.listsLoaded) {
        this.loadLists()
      }
    },
    removeFromList(listId) {
      let self = this
      self.userAchievement = undefined
      self.$bvModal
        .msgBoxConfirm('Are you sure you wish to remove the item from this list?', {
          cancelVariant: 'link',
        })
        .then((result) => {
          if (result) {
            self.saving = true
            service.list
              .removeItemFromListByTargetId(listId, self.type, self.targetId)
              .then(() => {
                let list = _.findIndex(self.lists, (l) => {
                  return l.id === listId
                })
                self.available.push(self.lists[list])
                self.lists.splice(list, 1)
                self.saving = false
              })
              .catch((error) => {
                self.saving = false
                self.handleError(error, 'list', undefined, 'remove this item from the list')
              })
          }
        })
    },
    toggleAddPanel() {
      this.addPanelOpen = !this.addPanelOpen
    },
    cancel() {
      this.addPanelOpen = false
    },
    listsSelected(newLists, leavePanelOpen) {
      this.addPanelOpen = leavePanelOpen
      if (newLists) {
        let self = this
        _.forEach(newLists, (list) => {
          self.lists.push(list)
          self.available.splice(
            _.findIndex(self.available, (l) => {
              return l.id === list.id
            }),
            1
          )
        })
      }
    },
    updateSaving(saving) {
      this.saving = saving
    },
    setUserAchievement(ua) {
      this.userAchievement = ua
    },
  },
}
</script>
