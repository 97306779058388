<template>
  <b-container class="profile">
    <Stubbable :stubbed="(!profileInfo || !profileInfo.user) && (!error || !error.summary)">
      <template slot="content">
        <div class="error" v-if="error && error.summary">
          <Alert :alert="error"></Alert>
        </div>

        <b-row
          no-gutters
          class="section-container profile-header"
          v-if="isMyProfile || canViewPrivateProfiles || profileInfo.publiclyVisible">
          <b-col sm="12">
            <div class="user-profile-image">
              <UserThumbnail
                :user-summary="profileInfo.user"
                :link-username="false"
                :heading="true"
                ref="profileImage"
                class="thumbnail"></UserThumbnail>

              <VisibilityInd
                :visible="profileInfo.publiclyVisible"
                :faint="!editing"
                v-if="isMyProfile" />
            </div>
          </b-col>
        </b-row>

        <b-row
          no-gutters
          class="profile-content"
          v-if="isMyProfile || canViewPrivateProfiles || profileInfo.publiclyVisible">
          <b-card no-body>
            <b-card-header header-tag="nav">
              <b-nav card-header tabs>
                <b-nav-item
                  v-for="activity in activities"
                  :active="profileActivity === activity.name.toLowerCase()"
                  :to="activity.nav"
                  :key="activity.name">
                  <div>{{ activity.display }}</div>
                </b-nav-item>
              </b-nav>
            </b-card-header>
            <b-card-body>
              <router-view
                @update-profile="updateProfile"
                @editing="changeEditingStatus"
                @error="handleErrors"
                :profile-info="profileInfo"></router-view>
            </b-card-body>
          </b-card>
        </b-row>
      </template>
      <template slot="stub">
        <div class="stub">
          <div class="profile-stub">
            <div class="button pill image"></div>
            <div class="button pill header"></div>
            <div class="button pill tabs"></div>
            <div class="button pill"></div>
            <div class="button pill"></div>
          </div>
        </div>
      </template>
    </Stubbable>
  </b-container>
</template>

<style lang="scss">
.click-to-save-avatar {
  font-weight: bold;
}

.dropzone {
  padding: 0;
  margin: 0 !important;
  background-color: colour(blue);
  border-radius: 3px;
  border: none;

  &:hover {
    background-color: colour(blue);
  }

  .dz-message {
    color: white;
    text-align: left;
    padding-left: 2rem;
    padding-right: 1rem;
  }

  .dz-preview {
    margin: 0;

    .dz-details {
      background: 0;
    }

    .dz-error-message {
      background: colour(orange);
      top: 15px;
    }

    .dz-remove {
      display: inline-block;
      width: 100%;
      margin: 0;
    }

    .dz-progress {
      opacity: 0.7;
    }
  }
}
</style>

<style scoped lang="scss">
$thumbnail-dimension: 80px;
$thumbnail-max-aspect-ratio: 1.5;

.profile {
  margin-bottom: 3rem;

  h1,
  h2 {
    font-size: 50px;
    font-weight: 700;
  }

  .profile-header {
    margin-top: 2em;
    margin-bottom: 1em;

    .user-profile-image {
      .overlay {
        position: absolute;
        border-radius: 40px;
        display: inline-block;
        width: 80px;
        height: 80px;
        font-size: 36px;
        padding-top: 14px;
        text-align: center;

        .controls {
          opacity: 1;

          a,
          a:hover,
          a:visited {
            color: white;
          }
        }

        &.darker {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }

      .visibility-indicator {
        position: absolute;
        text-align: center;
        font-size: 20px;
        width: 77px;
        left: 1px;
        bottom: 2px;
        padding-top: 4px;
        height: 40px;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        color: white;
        background-color: rgba(100, 100, 100, 0.5);

        .icon {
          opacity: 1;
        }

        .thumbnail-vis-ind {
          margin-left: 0;
          font-size: 24px;
          color: white;
        }
      }
    }

    .thumbnail {
      display: inline-block;
      vertical-align: middle;
    }

    .change-link {
      margin-left: -0.75rem;
      margin-top: 0.5rem;
    }

    .update-avatar-form {
      max-height: 0;
      overflow: hidden;

      &.open {
        max-height: 400px;
        margin-top: 1rem;
        transition: max-height ease-in-out 300ms;
      }

      .upload-widget {
        display: inline-block;

        .vue-dropzone {
          max-width: 220px;
          max-height: 250px;
          margin-bottom: 1rem;
          margin-top: 1rem;
          margin-left: 0.8rem;
          display: inline-block;
        }

        .error {
          color: colour(form-field-error);
          margin-left: 1rem;
        }
      }

      .upload-info {
        display: inline-block;
        vertical-align: top;
        color: colour(grey-3);
        font-size: 0.875rem;
        margin-left: 1rem;
        margin-top: 1rem;

        p {
          margin-bottom: 0;
        }

        ul {
          padding-left: 1rem;
        }
      }

      .buttons {
        margin-top: 1rem;
      }
    }
  }

  .profile-content {
    .card {
      width: 100%;
      border: none;
      background: transparent;
      display: block;

      .card-header {
        border: none;
        background: transparent;
        padding: 0;
        margin-bottom: 2.5rem;

        .nav-tabs .nav-link {
          border: none;
          background: transparent;
          font-weight: 700;
          color: colour(black);
          margin-right: 2rem;

          &:hover,
          &:active,
          &.active {
            color: colour(black);
            border-bottom: 3px solid colour(blue);
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
          }
        }
      }

      .card-body {
        padding: 0;
      }
    }
  }

  .profile-stub {
    .image {
      margin-top: 2.5rem;
      width: 100px;
      height: 100px;
      display: inline-block;
    }

    .header {
      display: inline-block;
      width: 300px;
      margin-left: 2rem;
      margin-bottom: 35px;
      height: 3rem;
    }

    .tabs {
      margin-top: 2rem;
      width: 700px;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import Vue from 'vue'
import Stubbable from '@/components/Stubbable.vue'
import auth from '@/mixins/auth'
import colours from '@/mixins/colours'
import utils from '@/mixins/utils'
import mappings from '@/mixins/mappings'
import profile from '@/mixins/profile'
import pluralize from 'pluralize/pluralize'
import service from '@/service/service'
import Alert from '@/components/Alert.vue'
import VisibilityInd from '@/components/profile/VisibilityInd.vue'
import UserThumbnail from '@/components/profile/UserThumbnail'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'ProfileView',
  mixins: [colours, utils, mappings, auth, profile],
  components: {
    UserThumbnail,
    Alert,
    VisibilityInd,
    vueDropzone: vue2Dropzone,
    Stubbable,
  },
  data() {
    return {
      profileInfo: {},
      userLists: {},
      showChangeAvatarForm: false,
      uploadInProgress: false,
      fileReadyForUpload: false,
      uploadWidgetError: undefined,
      editing: false,
    }
  },
  metaInfo() {
    if (!_.isEmpty(this.usernameParam)) {
      return { title: this.displayUserId }
    } else {
      return { title: 'My profile' }
    }
  },
  beforeRouteEnter(to, from, next) {
    let username = to.query.username
    if (!_.isEmpty(username)) {
      next({
        replace: true,
        name: 'PublicProfile',
        params: {
          username: username,
        },
      })
    } else {
      next((vm) => vm.loadProfileInfo())
    }
  },
  created() {
    let self = this
    self.loadProfileInfo()
  },
  computed: {
    activities() {
      let allActivities = [
        {
          name: 'about',
          display: 'Activity',
          nav: this.navToAbout,
          show:
            this.isMyProfile || this.canViewPrivateProfiles || this.profileInfo.biographyVisible,
        },
        {
          name: 'corrections',
          display: 'Text corrections',
          nav: this.navToCorrections,
          show: this.isMyProfile || this.canViewPrivateProfiles || this.profileInfo.activityVisible,
        },
        {
          name: 'lists',
          display: this.listLabel,
          nav: this.navToLists,
          show: this.isMyProfile || this.canViewPrivateProfiles || this.profileInfo.activityVisible,
        },
        {
          name: 'tags',
          display: 'Tags',
          nav: this.navToTags,
          show: this.isMyProfile || this.canViewPrivateProfiles || this.profileInfo.activityVisible,
        },
        {
          name: 'notes',
          display: 'Notes',
          nav: this.navToNotes,
          show: this.isMyProfile || this.canViewPrivateProfiles || this.profileInfo.activityVisible,
        },
        {
          name: 'downloads',
          display: 'Downloads',
          nav: this.navToDownloads,
          show: this.maximumExportSize > 0
        },
        {
          name: 'partner',
          display: 'Partner',
          nav: this.navToPartner,
          show: this.profileInfo.dashboardAccess,
        },
        {
          name: 'settings',
          display: 'Settings',
          nav: this.navToSettings,
          show: this.isMyProfile || this.canViewPrivateProfiles,
        },
        {
          name: 'dev',
          display: 'For Developers',
          nav: this.navToDevelopers,
          show: this.isMyProfile || this.canViewPrivateProfiles,
        },
        {
          name: 'moderateContent',
          display: 'Moderate Content',
          nav: {
            name: 'ModerateContent',
          },
          show: this.isMyProfile && this.profileInfo.contentManager,
        },
      ]

      return _.filter(allActivities, (activity) => activity.show)
    },
    hasActiveAPIKey() {
      return this.profileInfo && this.profileInfo.hasAPIKey && this.profileInfo.apiDetails.keyStatus === 'C';
    },
    isMyProfile() {
      return this.authenticated && _.isEmpty(this.usernameParam)
    },
    displayUserId() {
      return this.profileInfo.user ? this.profileInfo.user.displayName : ''
    },
    listLabel() {
      return pluralize('List', this.profileInfo.listCount)
    },
    navToAbout() {
      if (!_.isEmpty(this.usernameParam)) {
        return {
          name: 'PublicProfileAbout',
          params: {
            username: this.usernameParam,
          },
        }
      } else {
        return {
          name: 'ProfileAbout',
        }
      }
    },
    navToSettings() {
      if (!_.isEmpty(this.usernameParam)) {
        return {
          name: 'PublicProfileSettings',
          params: {
            username: this.usernameParam,
          },
        }
      } else {
        return {
          name: 'ProfileSettings',
        }
      }
    },
    navToDevelopers() {
      if (!_.isEmpty(this.usernameParam)) {
        return {
          name: 'PublicProfileDev',
          params: {
            username: this.usernameParam,
          },
        }
      } else {
        return {
          name: 'ProfileDev',
        }
      }
    },
    navToDownloads() {
      return {
        name: 'ProfileDownloads',
      }
    },
    navToPartner() {
      return {
        name: 'ProfilePartner',
      }
    },
    navToCorrections() {
      if (!_.isEmpty(this.usernameParam)) {
        return {
          name: 'PublicProfileCorrections',
          params: {
            username: this.usernameParam,
          },
        }
      } else {
        return {
          name: 'ProfileCorrections',
        }
      }
    },
    navToTags() {
      if (!_.isEmpty(this.usernameParam)) {
        return {
          name: 'PublicProfileTags',
          params: {
            username: this.usernameParam,
          },
        }
      } else {
        return {
          name: 'ProfileTags',
        }
      }
    },
    navToNotes() {
      if (!_.isEmpty(this.usernameParam)) {
        return {
          name: 'PublicProfileNotes',
          params: {
            username: this.usernameParam,
          },
        }
      } else {
        return {
          name: 'ProfileNotes',
        }
      }
    },
    navToLists() {
      if (!_.isEmpty(this.usernameParam)) {
        return {
          name: 'PublicProfileLists',
          params: {
            username: this.usernameParam,
          },
        }
      } else {
        return {
          name: 'ProfileLists',
        }
      }
    },
    profileActivity() {
      return this.$route
        ? _.toLower(_.replace(this.$route.name, /(Profile)|(PublicProfile)/g, ''))
        : undefined
    },
    dropzoneOptions() {
      return {
        url: '/api/profile/uploadAvatar',
        thumbnailWidth: 200,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        maxFilesize: 0.5,
        withCredentials: true,
        createImageThumbnails: true,
        maxFiles: 1,
        addRemoveLinks: true,
        autoProcessQueue: false,
        dictDefaultMessage: 'Drag and drop your new image here, or click to browse to the file',
        dictRemoveFile: 'Clear file',
        dictFileTooBig: 'Your file exceeds the maximum allowable size {{maxFilesize}}MB',
        headers: { apikey: service.auth.apikey() },
      }
    },
  },
  methods: {
    getAvatarProgressElement() {
      return document.querySelector('.dz-progress')
    },
    hideAvatarProgressBar() {
      let progressElement = this.getAvatarProgressElement()

      if (progressElement) {
        progressElement.classList.add('invisible')
      }
    },
    showAvatarProgressBar() {
      let progressElement = this.getAvatarProgressElement()

      if (progressElement) {
        progressElement.classList.remove('invisible')
      }
    },
    loadProfileInfo() {
      let self = this
      self.profileInfo = {}
      let userId = !_.isEmpty(self.usernameParam)
        ? self.usernameParam
        : self.authenticated
        ? self.fullUsername
        : ''
      let getterPromise
      if (this.isMyProfile) {
        getterPromise = service.profile.getMyProfile(userId)
      } else {
        getterPromise = service.profile.get(userId)
      }
      getterPromise
        .then((info) => {
          _.forOwn(info, function (val, key) {
            self.$set(self.profileInfo, key, val)
          })
          self.updateBreadcrumbs()

          if (
            !self.profileActivity ||
            (!self.isMyProfile &&
              (self.profileActivity === 'settings' ||
                (!self.profileInfo.activityVisible &&
                  ['corrections', 'tags', 'notes', 'lists'].indexOf(self.profileActivity) > -1)))
          ) {
            self.$router.replace(this.navToAbout)
          }
        })
        .catch((error) => {
          self.saving = false
          self.updateBreadcrumbs([{ value: 'User not found' }])
          if (error.response && error.response.status === 404) {
            self.error = {
              summary:
                'A user with the requested user id was not found or has chosen to keep their profile hidden',
              impact: 'info',
            }
          } else {
            self.handleError(error, undefined, undefined, 'retrieve the profile')
          }
        })
    },
    updateBreadcrumbs(crumbs) {
      this.$emit(
        'update-breadcrumbs',
        _.isEmpty(crumbs)
          ? [{ value: this.isMyProfile ? 'My profile' : this.displayUserId }]
          : crumbs,
        true
      )
    },
    updateProfile(newProfileInfo) {
      let self = this
      _.forOwn(newProfileInfo, function (val, key) {
        self.$set(self.profileInfo, key, val)
      })
      self.$refs.profileImage.clearThumbnail()
      Vue.nextTick(() => {
        self.$refs.profileImage.createImageUrl()
      })
    },
    toggleChangeAvatarForm() {
      this.showChangeAvatarForm = !this.showChangeAvatarForm
      if (this.showChangeAvatarForm) {
        this.$refs.avatarUpload.enable()
        document.querySelector('.dz-message').innerHTML = this.dropzoneOptions.dictDefaultMessage
      } else {
        this.$refs.avatarUpload.removeAllFiles(true)
        this.$refs.avatarUpload.disable()
      }
    },
    uploadReady() {
      if (this.$refs.avatarUpload.dropzone.files.length > 1) {
        this.$refs.avatarUpload.removeAllFiles(true)
        this.uploadWidgetError = 'You can only select 1 file'
      } else {
        this.fileReadyForUpload = true
        this.$refs.avatarUpload.dropzone.options.clickable = false
        this.uploadWidgetError = undefined
        this.hideAvatarProgressBar()
      }
    },
    uploadCleared() {
      this.uploadWidgetError = undefined
      this.fileReadyForUpload = false
    },
    uploadAvatar() {
      this.showAvatarProgressBar()
      this.uploadInProgress = true
      this.$refs.avatarUpload.processQueue()
    },
    uploadComplete(file, profileInfo) {
      this.hideAvatarProgressBar()
      this.uploadInProgress = false
      this.toggleChangeAvatarForm()
      this.profileInfo.profileImageAvailable = false
      let self = this
      this.$nextTick(() => {
        self.updateProfile(profileInfo)
      })
    },
    uploadFailed() {
      this.hideAvatarProgressBar()
      this.uploadInProgress = false
      this.fileReadyForUpload = false
    },
    deleteAvatar() {
      let self = this
      service.profile
        .deleteAvatar()
        .then((profileInfo) => {
          self.updateProfile(profileInfo)
          this.toggleChangeAvatarForm()
        })
        .catch((error) => {
          self.handleErrors({
            error: error,
            contextMessage: 'delete your profile image',
          })
        })
    },
    handleErrors(data) {
      this.handleError(data.error, undefined, undefined, data.contextMessage)
    },
    changeEditingStatus(isEditing) {
      this.editing = isEditing
      if (this.showChangeAvatarForm) {
        this.toggleChangeAvatarForm()
      }
    },
  },
}
</script>
