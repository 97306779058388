<template>
  <div class="collaborators" v-if="collaborators.length > 0">
    <h2 v-if="showHeading">Current list collaborators</h2>
    <ul class="list-unstyled">
      <li>
        <ListOwner
          :list-id="listId"
          :can-change="canRemoveAdministrators"
          :list-administrators="listAdministrators"
          :list-owner="listOwner"></ListOwner>
      </li>
      <li
        v-for="(collaborator, index) in sortedCollaborators"
        class="collaborator"
        :key="collaborator.user.fullUserId + index">
        <div class="details">
          <UserThumbnail
            :user-summary="collaborator.user"
            size="small"
            class="user"></UserThumbnail>
          <span class="role">
            <b-button variant="link" @click="swapRole(collaborator)" v-if="canManageRoles"
              >Change to
              {{
                collaborator.role === 'ADMINISTRATOR' ? 'Collaborator' : 'Administrator'
              }}</b-button
            >
            <span v-else class="role-label">{{ collaborator.role | capitalize }}</span>
          </span>
          <span class="controls">
            <b-button
              variant="link"
              @click="selectCollaborator(collaborator, true)"
              title="Remove collaborator"
              :disabled="openPanel !== undefined"
              v-if="canRemove(collaborator)">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'trash-alt' }" />
            </b-button>
          </span>
        </div>
        <div class="selection-panel" :class="{ open: openPanel === collaborator.user.fullUserId }">
          <span v-if="isMe(selectedCollaborator)"
            >This will remove your access to this list. Your contributions will not be
            removed.</span
          >
          <span v-else
            >This will remove {{ selectedCollaborator.user.displayName }}'s access to this
            list.</span
          >
          <label for="deleteContributions" class="sr-only" v-if="!isMe(selectedCollaborator)"
            >Delete contributions</label
          >
          <b-form-checkbox
            id="deleteContributions"
            v-model="selectedCollaborator.removeContributions"
            :required="true"
            v-if="!isMe(selectedCollaborator)">
            Remove all items added by this collaborator?
          </b-form-checkbox>

          <template v-if="!isMe(selectedCollaborator)">
            <label for="reason" class="label">Remove collaborator reason (optional):</label>
            <b-form-select id="removeCollaboratorReason" v-model="removeCollaboratorReason">
              <option :value="null">Reason for removing collaborator...</option>
              <option
                v-for="(reason, index) in removeCollaboratorReasons"
                :key="index"
                :value="reason">
                {{ reason }}
              </option>
            </b-form-select>
          </template>

          <div class="buttons">
            <b-button @click="removeCollaborator" :disabled="inProgress" size="sm"
              >Remove {{ selectedCollaborator.user.displayName }}</b-button
            >
            <b-button variant="link" @click="cancel">Cancel</b-button>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div v-else>There are no collaborators on this list</div>
</template>

<style scoped lang="scss">
.collaborators {
  h2 {
    font-size: 1.375rem;
  }

  .collaborator {
    .details {
      .user {
        width: 200px;
        overflow: hidden;
      }

      .role {
        width: 200px;
        margin-left: 1rem;
        display: inline-block;

        .role-label {
          font-weight: normal;
          margin-left: 1rem;
        }
      }

      .controls {
        display: inline-block;
        margin-left: 1rem;
        margin-top: -0.5rem;
        color: colour(magenta) !important;

        .btn-link {
          color: colour(magenta) !important;
        }
      }
    }

    .selection-panel {
      margin-top: 1rem;
      max-height: 0;
      height: 0;
      overflow: hidden;
      font-weight: normal;

      &.open {
        height: auto;
        max-height: 200px;
        transition: max-height 0.5s ease;
      }

      .buttons {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .custom-control {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import auth from '@/mixins/auth'
import lists from '@/mixins/lists'
import service from '@/service/service'
import UserThumbnail from '@/components/profile/UserThumbnail'
import ListOwner from '@/components/lists/management/ListOwner'

export default {
  name: 'ListCollaborators',
  mixins: [auth, lists],
  components: {
    ListOwner,
    UserThumbnail,
  },
  props: {
    collaborators: {
      type: Array,
      required: true,
    },
    listId: {
      type: [String, Number],
      required: true,
    },
    canRemoveAdministrators: {
      type: Boolean,
      required: true,
    },
    canRemoveCollaborators: {
      type: Boolean,
      required: true,
    },
    canManageRoles: {
      type: Boolean,
      required: true,
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: true,
    },
    listOwner: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      requestingAccess: undefined,
      inProgress: false,
      openPanel: undefined,
      selectedCollaborator: {
        role: undefined,
        user: {
          displayName: undefined,
          fullUserId: undefined,
        },
        toRemove: false,
        removeContributions: false,
      },
      removeCollaboratorReasons: [
        'Breaching group rules',
        'Posting irrelevant content',
        'Not contributing to group',
      ],
      removeCollaboratorReason: null,
    }
  },
  computed: {
    sortedCollaborators() {
      return _.sortBy(this.collaborators, ['role', 'user.displayName'])
    },
    listAdministrators() {
      return _.filter(this.collaborators, (c) => c.role === 'ADMINISTRATOR')
    },
  },
  methods: {
    isMe(collab) {
      return collab.user.fullUserId === this.fullUsername
    },
    selectCollaborator(collab, toRemove) {
      this.selectedCollaborator.role = collab.role
      this.selectedCollaborator.user.fullUserId = collab.user.fullUserId
      this.selectedCollaborator.user.displayName = collab.user.displayName
      this.selectedCollaborator.toRemove = toRemove
      this.openPanel = collab.user.fullUserId
    },
    swapRole(collab) {
      collab.role = collab.role === 'ADMINISTRATOR' ? 'COLLABORATOR' : 'ADMINISTRATOR'
      let self = this
      self.inProgress = true
      service.list
        .updateCollaboratorRole(self.listId, collab.user.fullUserId, collab.role)
        .then((updated) => {
          self.$emit('collaborator-updated', updated)
          self.cancel()
          self.inProgress = false
        })
        .catch((error) => {
          self.inProgress = false
          self.handleError(error, undefined, undefined, "update the collaborator's role")
        })
    },
    cancel() {
      this.selectedCollaborator.role = undefined
      this.selectedCollaborator.user.fullUserId = undefined
      this.selectedCollaborator.user.displayName = undefined
      this.selectedCollaborator.toRemove = false
      this.selectedCollaborator.removeContributions = false
      this.openPanel = undefined
      this.removeCollaboratorReason = null
    },
    canRemove(collaborator) {
      return (
        (collaborator.role === 'ADMINISTRATOR'
          ? this.canRemoveAdministrators
          : this.canRemoveCollaborators) || this.isMe(collaborator)
      )
    },
    removeCollaborator() {
      let self = this
      self.inProgress = true
      service.list
        .removeCollaborator(
          self.listId,
          self.selectedCollaborator.user.fullUserId,
          self.selectedCollaborator.removeContributions,
          self.removeCollaboratorReason
        )
        .then(() => {
          if (self.fullUsername === self.selectedCollaborator.user.fullUserId) {
            self.$router.push({ name: 'List' })
          } else {
            self.$emit('collaborator-removed')
            self.cancel()
            self.inProgress = false
          }
        })
        .catch((error) => {
          self.inProgress = false
          self.handleError(error, undefined, undefined, "update the collaborator's role")
        })
    },
  },
}
</script>
