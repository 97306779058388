<template>
  <div>
    <div class="d-flex align-items-start type-buttons">
      <div class="flex-grow-1">
        <PropertyFilter
          v-for="filterSpecification in propertyFilterSpecifications"
          :key="filterSpecification.name"
          :filterSpecification="filterSpecification"
          @filter-updated="filterUpdated(filterSpecification.name, ...arguments)"></PropertyFilter>
      </div>
      <div class="d-flex">
        <div
          class="button-container"
          v-for="componentFilterSpecification in activeComponentFilterSpecifications"
          :key="componentFilterSpecification.name">
          <b-button
            variant="primary"
            class="rounded"
            @click="toggleFilterComponent(componentFilterSpecification)"
            >{{ componentFilterSpecification.name
            }}<span class="ml-2"
              ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }" /></span
          ></b-button>

          <div class="arrow-container">
            <div
              class="arrow"
              v-if="isCurrentComponentFilterSpecification(componentFilterSpecification)"></div>
          </div>
        </div>
      </div>
    </div>

    <span
      v-for="componentFilterSpecification in componentFilterSpecifications"
      :key="componentFilterSpecification.name">
      <keep-alive>
        <component
          :key="componentFilterSpecification.name"
          v-show="isCurrentComponentFilterSpecification(componentFilterSpecification)"
          :is="componentFilterSpecification.component"
          v-bind="componentFilterSpecification.props"
          :filterSpecification="componentFilterSpecification"
          @filter-updated="filterUpdated(componentFilterSpecification.name, ...arguments)"
          @close="currentFilterComponentSpecificationName = null" />
      </keep-alive>
    </span>

    <b-badge pill variant="primary" v-for="display in filterDisplays" :key="display.text">
      {{ display.text }}
      <b-link>
        <div @click="display.clear()">
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </div>
      </b-link>
    </b-badge>

    <p class="numFiltered" v-if="showNumber">
      {{ numFilteredItems }} {{ pluralize('item', numFilteredItems) }}
      {{ numFilteredItems === 1 ? 'matches' : 'match' }} the selected filter criteria
    </p>
  </div>
</template>

<style lang="scss">
.draggable {
  .handle {
    cursor: move;
  }
}
</style>

<style scoped lang="scss">
$arrow-height: 20px;
$button-spacing: 1rem;

.numFiltered {
  margin-top: 0.5rem;
}

.badge {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  text-transform: capitalize;

  a {
    color: white;
    margin-left: 0.2rem;
  }
}

.type-buttons {
  margin-top: 1em;
  .button-container {
    &:first-child {
      margin-left: 0.5rem;
    }
    button {
      margin-right: 1rem;
      background: transparent !important;
      border-color: transparent !important;
      color: colour(purple);
      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child {
      button {
        margin-right: 0rem;
      }
      .arrow-container {
        margin-right: 0rem;
      }
    }

    .arrow-container {
      margin-right: 1rem;

      .arrow {
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-bottom: $arrow-height solid colour(grey);
        border-right: 16px solid transparent;
        margin-top: 0;
        position: relative;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (max-width: 575px) {
    .button-container {
      width: 90vw;
      margin-top: 5rem;
      margin-left: -100% !important;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import filtering from '@/mixins/filtering'
import utils from '@/mixins/utils'
import PropertyFilter from '@/components/work/PropertyFilter.vue'

export default {
  name: 'FilterContainer',
  mixins: [filtering, utils],
  components: {
    PropertyFilter,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    propertyFilterSpecifications: {
      type: Array,
      required: false,
      default: () => [],
    },
    componentFilterSpecifications: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      currentFilterComponentSpecificationName: null,
      filters: {},
    }
  },
  created() {
    this.refresh()
  },
  watch: {
    componentFilterSpecifications: {
      immediate: true,
      handler() {
        if (this.currentFilterComponentSpecification) {
          this.currentFilterComponentSpecificationProps =
            this.currentFilterComponentSpecification.props
        }
      },
    },
    items() {
      this.refresh()
    },
  },
  computed: {
    showNumber() {
      return this.numFilteredItems && this.numFilteredItems !== (this.items || []).length
    },
    filterDisplays() {
      return _(this.filters)
        .values()
        .reduce((displays, filter) => {
          return _.concat(filter.displays || [], displays)
        }, [])
    },
    activeComponentFilterSpecifications() {
      return _.filter(this.componentFilterSpecifications || [], (spec) => {
        return !_.isFunction(spec.predicate) || spec.predicate()
      })
    },
    numFilteredItems() {
      return (this.filterItems() || []).length
    },
  },
  methods: {
    refresh() {
      var self = this
      var specs = _.concat(
        self.componentFilterSpecifications || [],
        self.propertyFilterSpecifications || []
      )

      _(specs)
        .filter((spec) => _.isFunction(spec.initialFilter))
        .forEach((spec) =>
          self.filterUpdated(spec.name, spec.initialFilter, spec.initialFilterDisplay)
        )
    },
    isCurrentComponentFilterSpecification(spec) {
      return (
        spec &&
        spec.name &&
        spec.name === this.currentFilterComponentSpecificationName &&
        (!_.isFunction(spec.predicate) || spec.predicate())
      )
    },
    filterUpdated(filterName, filter, displays) {
      this.$set(this.filters, filterName, {
        name: filterName,
        displays: displays,
        filterFunction: filter,
      })
      this.$emit('filter-updated', this.filterItems())
    },
    toggleFilterComponent(filterComponentSpecification) {
      if (
        !this.currentFilterComponentSpecificationName ||
        this.currentFilterComponentSpecificationName !== filterComponentSpecification.name
      ) {
        this.currentFilterComponentSpecificationName = filterComponentSpecification.name
      } else {
        this.currentFilterComponentSpecificationName = null
      }
    },
    filterItems() {
      var self = this

      let filterFunctions = _(this.filters)
        .values()
        .map((filter) => filter.filterFunction)
        .value()

      var filteredItems = _(this.items)
        .filter((item) => {
          return self.and(filterFunctions, item)
        })
        .value()

      return filteredItems
    },
  },
}
</script>
