<template>
  <b-pagination-nav
    size="md"
    :value="page"
    :link-gen="linkGen"
    :limit="pageLimit"
    :number-of-pages="numberOfPages"
    :no-page-detect="true"
    @input="pageChanged"></b-pagination-nav>
</template>

<style scoped lang="scss">
.pagination {
  margin-top: 1rem;
}
</style>

<script>
import _ from 'lodash'
import navigation from '@/mixins/navigation'

export default {
  name: 'Paginator',
  mixins: [navigation],
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    linkGenerator: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      page: 1,
      pageLimit: 10,
    }
  },
  computed: {
    numberOfPages() {
      return _.ceil(this.totalRows / this.pageSize)
    },
  },
  watch: {
    currentPage: {
      immediate: true,
      handler() {
        this.page = this.currentPage
      },
    },
  },
  methods: {
    linkGen(pageNum) {
      if (_.isFunction(this.linkGenerator)) {
        return this.linkGenerator(pageNum, this.pageSize)
      }

      return this.generateSearchPaginationLink(pageNum, this.pageSize)
    },
    pageChanged(newPage) {
      var self = this

      if (newPage !== self.currentPage) {
        self.page = null

        self.$nextTick(() => {
          self.page = self.currentPage
        })
      }
    },
  },
}
</script>
