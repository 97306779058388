<template>
  <div class="pending-collaboration-requests" v-if="pendingRequests.length > 0">
    <h2 v-if="showHeading">
      You have {{ pendingRequests.length }} pending collaboration request(s)
    </h2>

    <ul class="list-unstyled">
      <li v-for="request in pendingRequests" :key="request.requestId" class="collaborator">
        <UserThumbnail :user-summary="request.user" class="user" size="small"></UserThumbnail>
        <div class="controls" v-if="!request.status">
          <b-button
            variant="link"
            @click="acceptCollaborator(request.requestId)"
            :disabled="inProgress">
            Approve
          </b-button>

          <b-button
            variant="link"
            @click="declineCollaborator(request.requestId, false)"
            :disabled="inProgress">
            Deny
          </b-button>

          <b-button
            variant="link"
            @click="declineCollaborator(request.requestId, true)"
            :disabled="inProgress">
            Block
          </b-button>
        </div>
        <span class="status" v-if="request.status">{{ request.status }}</span>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.pending-collaboration-requests {
  h2 {
    font-size: 1.375rem;
  }

  .collaborator {
    margin-bottom: 1rem;

    .date {
      margin-left: 1rem;
    }

    .user {
      display: inline-block;
      width: 200px;
      overflow: hidden;
    }

    .controls {
      display: inline-block;
      margin-left: 1rem;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import auth from '@/mixins/auth'
import lists from '@/mixins/lists'
import service from '@/service/service'
import UserThumbnail from '@/components/profile/UserThumbnail'
import moment from 'moment'

export default {
  name: 'PendingCollaborationRequests',
  mixins: [auth, lists],
  components: {
    UserThumbnail,
  },
  props: {
    listId: {
      type: [String, Number],
      required: true,
    },
    pendingRequests: {
      type: Array,
      required: true,
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      inProgress: undefined,
    }
  },
  computed: {},
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM YYYY h:mm a')
    },
    acceptCollaborator(requestId) {
      let self = this
      self.inProgress = true
      service.list
        .acceptCollaborationRequest(requestId)
        .then(() => {
          self.inProgress = false
          self.$emit('request-actioned')
        })
        .catch((error) => {
          self.handleError(error, 'list', undefined, 'accept a collaboration request')
        })
    },
    declineCollaborator(requestId, block) {
      let self = this
      self.inProgress = true
      service.list
        .declineCollaborationRequest(requestId, block)
        .then(() => {
          self.inProgress = false
          self.$set(
            _.filter(self.pendingRequests, (r) => r.requestId === requestId)[0],
            'status',
            block ? 'Declined & blocked' : 'Declined'
          )
          self.$emit('request-actioned')
        })
        .catch((error) => {
          self.handleError(error, 'list', undefined, 'decline a collaboration request')
        })
    },
  },
}
</script>
