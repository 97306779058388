<template>
  <div class="work-action-related-material" v-if="sortedUrls && sortedUrls.length > 0">
    <h2 class="heading bevel blue">Related resources</h2>
    <p>These items provide further information about this resource.</p>

    <LibraryList :libraries="sortedUrlsForList"></LibraryList>

    <div class="show-all-container">
      <b-button
        variant="outline-primary"
        class="show-all-toggle"
        v-if="urls.length > limitTo"
        @click="toggleShowAll">
        {{ showAll ? 'Show less' : 'Show all' }}
      </b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.work-action-related-material {
  padding-top: 30px;

  .heading {
    font-size: 20px;
  }

  h2.bevel {
    font-family: inherit;
    font-size: 1.375rem;
    margin-bottom: 1em;
    &:before {
      top: 0.17em;
    }
  }

  .show-all-container {
    width: 100%;
    margin-top: 1em;

    .show-all-toggle {
      min-width: 10rem;
    }

    .btn {
      border: 2px solid colour(green);
      color: colour(white);
      background: colour(green);
      transition: 0.5s ease-in-out;
      font-weight: 600;

      &:hover,
      &:focus {
        background: colour(white);
        color: colour(green);
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import LibraryList from '@/components/LibraryList.vue'
import WorkActionsCommon from '@/components/work/workActions/WorkActionsCommon'

export default {
  name: 'WorkActionsRelatedMaterial',
  components: {
    LibraryList,
  },
  mixins: [WorkActionsCommon],
  props: {
    relatedMaterialUrls: {
      type: Array,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      required: false,
    },
    limitTo: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  data() {
    return {
      urls: [],
      showAll: false,
    }
  },
  mounted() {
    this.updateUrls()
  },
  watch: {
    relatedMaterialUrls() {
      this.updateUrls()
    },
  },
  computed: {
    sortedUrlsForList() {
      return _.map(this.sortedUrls, this.mapAvailableUrlForList)
    },
    sortedUrls() {
      let max = this.showAll ? this.urls.length : this.limitTo
      return _.take(
        _.orderBy(
          this.urls,
          [
            (u) => u.nla, // nla stuff then non-nla
            (u) => _.toLower(u.text),
          ], // then alphabetic
          ['asc', 'asc']
        ),
        max
      )
    },
  },
  methods: {
    hideThumbnail(url) {
      url.thumbnail = undefined
    },
    updateUrls() {
      let self = this

      self.urls.splice(0, self.urls.length)

      _.forEach(this.relatedMaterialUrls, (u, i) => {
        let label = self.determineLabel(u)
        let nlamatch = u.url ? u.url.match(/https?:\/\/(www\.)?nla\.gov\.au.*/g) || [] : []
        let nla = nlamatch.length > 0

        if (_.toLower(label) !== 'thumbnail') {
          self.urls.push({
            url: u.url,
            text: label,
            key: i,
            thumbnail: self.determineThumbnail(u),
            nla: nla,
            format: u.format,
            language: u.language,
            year: u.year,
          })
        }
      })
    },
    determineLabel(url) {
      return url.linkText || url.url
    },
    determineThumbnail(url) {
      let u = _.toLower(url.url)
      let label = _.toLower(url.linkText || '')

      let showThumbnail =
        u.indexOf('mapindex') > -1 ||
        u.indexOf('flightdiagram') > -1 ||
        label.indexOf('map index') > -1 ||
        label.indexOf('flight diagram') > -1

      return showThumbnail ? self.thumbnailUrl || url.url : ''
    },
    toggleShowAll() {
      this.showAll = !this.showAll
      this.$emit('recalc-height')
    },
  },
}
</script>
