<template>
  <div>
    <div class="collection-keyhole full-width" :style="cTop">
      <div class="container banner-wrapper">
        <div class="outer-container container d-flex flex-row">
          <svg class="keyholeContent" aria-hidden="true">
            <defs>
              <clipPath id="key-clip-path26282">
                <!-- eslint-disable max-len -->
                <path
                  id="key-clip-path26282key"
                  d="M0.904516856,2.84217094e-14 L0.904516856,400 L1304.31112,400 L1266.01534,227.994002 C1344.28601,195.204305 1400,117.53527 1400,28.0800145 C1400,18.5799818 1399.38313,9.20028462 1398.188,2.84217094e-14 C1397.78722,2.84217094e-14 932.02606,2.84217094e-14 0.904516856,2.84217094e-14 Z"
                  transform="translate(700.452258, 200.000000) scale(-1, 1) translate(-700.452258, -200.000000) "></path>
                <!-- eslint-enable max-len -->
              </clipPath>
            </defs>
            <image
              clip-path="url(#key-clip-path26282)"
              v-if="keyholeImage"
              :xlink:href="keyholeImage"
              :alt="keyholeImageAlt"
              width="100%"
              height="100%"
              preserveAspectRatio="xMinYMin meet"></image>
          </svg>

          <b-container class="content-container">
            <b-row>
              <b-col class="col-lg-7 col-md-7 col-xs-12 col-sm-12 title-col">
                <div class="title-section">
                  <div class="title-container">
                    <h1 class="title">{{ title }}</h1>
                    <div class="subtitle">{{ subtitle }}</div>
                    <b-img
                      class="logo"
                      v-if="logos.length === 1"
                      :src="logos[0].logo"
                      :alt="logos[0].alt"></b-img>
                  </div>
                </div>
                <div class="caption-section">
                  <div class="caption-container d-flex">
                    <div class="ctext">
                      {{ keyholeImageCaption }}
                    </div>
                    <div class="caption-row full-width" :style="cBottom"></div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
    <div class="partner-logos-multi" v-if="logos && logos.length > 1">
      <b-img
        class="logo"
        :src="logo.logo"
        :alt="logo.alt"
        v-for="logo in logos"
        :key="logo.logo"></b-img>
    </div>
  </div>
</template>

<style scoped lang="scss">
.collection-keyhole {
  background-color: transparent;
  overflow: hidden;
  margin-top: 1rem;

  .banner-wrapper {
    padding: 0;

    @media (min-width: 1415px) {
      padding: 0 15px;
    }
  }
}

.full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

.outer-container {
  height: auto;
  overflow: visible;
  flex-wrap: wrap;
  padding: 0;

  .keyholeContent {
    width: 100%;
    height: 300px;
    z-index: 1;

    clipPath {
      display: none;
    }
  }

  .caption-row {
    min-height: 70px;
    position: absolute;
    z-index: -1;
    height: 100%;
  }

  .content-container {
    .title-col {
      z-index: 1;
      flex-direction: column;
      display: flex;
    }
  }

  .title-section {
    padding-bottom: 1rem;
    flex-grow: 1;

    .title-container {
      margin-left: auto;
      margin-right: auto;

      .title {
        text-align: left;
        font-family: 'Source Serif Pro', serif;
        font-size: 2.5rem;
        font-weight: 600;
        color: colour(purple);
        margin-bottom: 0.2em;
        line-height: 1.3em;
      }

      @supports (font-variation-settings: normal) {
        .title {
          font-family: 'Source Serif Variable', serif;
        }
      }

      .subtitle {
        margin-bottom: 0.5rem;
        text-align: left;
        font-size: 1.125rem;
        color: colour(black);
        margin-bottom: 0.2em;
        text-transform: uppercase;
      }

      img.title {
        max-height: 1.1em;
        max-width: 6em;
        margin-top: 0.8em;
        margin-bottom: 0.5em;
        color: colour(black);
      }
    }
  }

  .caption-section {
    height: auto;
    display: flex;
    position: relative;
    min-height: 70px;

    svg {
      margin-right: 0.5em;
      font-size: 1.2rem;
      position: relative;
      top: 2px;
    }

    .caption-container {
      flex-wrap: wrap;
      position: relative;
      align-items: center;

      .caption-row.full-width {
        left: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 150vw;
        right: 50%;
        width: 150vw;
      }
    }

    .ctext {
      color: colour(white);
      font-size: 1rem;
      text-align: left;
      overflow-wrap: break-word;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    img.nla,
    img.caption {
      height: 2rem;
    }
  }
}

.partner-logos,
.partner-logos-multi {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.logo {
  max-width: 120px;
  padding-right: 2rem;
}

.partner-logos-multi {
  padding-left: 15px;

  @media (min-width: 768px) {
    padding-left: 30px;
  }
}

@media (min-width: 768px) {
  .full-width {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    right: 50%;
    width: 100vw;
  }

  .collection-keyhole {
    padding: 0;

    .outer-container {
      height: 400px;
      padding: 0 15px;
      flex-wrap: wrap;
      position: relative;

      &::after {
        display: block;
        content: ' ';
        position: absolute;
        width: 5000px;
        height: 100%;
        background: white;
        left: 100%;
        top: 0;
        z-index: 5;
      }

      .title-section {
        padding-top: 40px;
      }

      .keyholeContent {
        width: 1400px;
        height: 400px;
        position: absolute;
        left: 58%;
        overflow: hidden;
        max-width: 100%;
        display: block;
        z-index: 2;

        clipPath {
          display: block;
        }

        @media (min-width: 992px) {
          left: 58%;
        }
      }

      //full width hack
      @media (min-width: 992px) {
        .full-width {
          left: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          max-width: 100vw;
          right: 50%;
          width: 100vw;
        }

        .collection-keyhole {
          padding: 0 45px;
        }
        .outer-container {
          .title-section {
            padding-top: 3rem;
            padding-bottom: 1rem;

            .title-container {
              .title {
                font-size: 2.5rem;
              }

              .subtitle {
                font-size: 1.125rem;
              }
            }
          }
        }
      }

      .content-container {
        .title-col {
          height: 400px;
          padding-left: 0;
        }
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import utils from '@/mixins/utils'
import colours from '@/mixins/colours'

let MAX_PARTNER_LOGOS = 5

export default {
  name: 'CollectionKeyhole',
  components: {},
  mixins: [utils, colours],
  props: {
    title: {
      type: String,
      required: false,
      default: 'Collection',
    },
    subtitle: {
      type: String,
      required: false,
      default: 'About',
    },
    logoImages: {
      type: Array,
      required: false,
    },
    keyholeImage: {
      type: String,
      required: false,
    },
    keyholeImageAlt: {
      type: String,
      required: false,
    },
    keyholeImageCaption: {
      type: String,
      required: false,
    },
    bannerScheme: {
      type: String,
      required: false,
    },
  },
  computed: {
    cTop() {
      let c = this.bannerScheme ? this.bannerScheme.split(/-/) : null
      let v = ((c && c[0]) || 'yellow').replace('lightblue', 'light-blue')
      return { 'background-color': this.colour(v) }
    },
    cBottom() {
      let c = this.bannerScheme ? this.bannerScheme.split(/-/) : null
      let v = ((c && c[1]) || 'purple').replace('lightblue', 'light-blue')
      return { 'background-color': this.colour(v) }
    },
    logos() {
      return this.logoImages && this.logoImages.length > 0
        ? _.take(this.logoImages, MAX_PARTNER_LOGOS)
        : []
    },
  },
}
</script>
