import _ from 'lodash'
import currentRoute from '@/mixins/currentRoute'
import search from '@/mixins/search'

const KEYWORD_PARAMS = ['keyword', 'keyword.mod']
const GLOBAL_SEARCH_LIMITS = ['l-publictag', 'l-usertag', 'subject', 'occupation']
const ROUTE_KEYS = ['name', 'params', 'query']

let modQuery = (link, modFunc, toMod) => {
  return _.assign({}, link, {
    query: modFunc(link.query || {}, _.isArray(toMod) ? toMod : [toMod]),
  })
}

let omitFromQuery = (link, toOmit) => {
  return modQuery(link, _.omit, toOmit)
}

let pickFromQuery = (link, toPick) => {
  return modQuery(link, _.pick, toPick)
}

export default {
  mixins: [currentRoute, search],
  data() {
    return {
      navigationQueryWatchers: [],
    }
  },
  watch: {
    $route(to, from) {
      let self = this
      let toQuery = to.query || {}
      let fromQuery = from.query || {}

      _.forEach(self.navigationQueryWatchers, (watcher) => {
        let allParams = _.union(_.keys(toQuery), _.keys(fromQuery))

        let changedParams = _.filter(watcher.params || allParams, (param) => {
          return (toQuery[param] || fromQuery[param]) && toQuery[param] !== fromQuery[param]
        })

        if (!_.isEmpty(changedParams)) {
          watcher.callback(changedParams)
        }
      })
    },
  },
  methods: {
    addQueryWatcher(params, callback) {
      this.navigationQueryWatchers.push({
        params: params ? (!_.isArray(params) ? [params] : params) : null,
        callback: callback,
      })
    },
    addSearchPaginationWatcher(callback) {
      this.addQueryWatcher(this.searchPaginationParams, callback)
    },
    routeTo(link) {
      this.$router.push(link)
    },
    routeToOmitFromQuery(toOmit) {
      this.routeTo(this.generateCurrentPageLinkOmitFromQuery(toOmit))
    },
    generateCurrentPageLink() {
      return _.assign(
        { name: '', params: {}, query: {} },
        _.cloneDeep(_.pick(this.currentRoute, ROUTE_KEYS))
      )
    },
    generateCurrentPageLinkWithOnlyKeyword() {
      return pickFromQuery(this.generateCurrentPageLink(), KEYWORD_PARAMS)
    },
    generateCurrentPageLinkWithoutParams() {
      return pickFromQuery(this.generateCurrentPageLink(), [])
    },
    generateCurrentPageLinkOmitFromQuery(toOmit) {
      return omitFromQuery(this.generateCurrentPageLink(), toOmit)
    },
    generateCurrentPageLinkWithoutPagination() {
      return omitFromQuery(this.generateCurrentPageLink(), this.searchPaginationParams)
    },
    generateSearchPaginationLink(newPage, pageSize, pageTotals) {
      let link = this.generateCurrentPageLink()

      link.query.startPos = (newPage - 1) * pageSize

      if (link.query.startPos === 0) {
        delete link.query.startPos
      }

      if (pageTotals) {
        link.query.pageTotals = pageTotals
      } else {
        delete link.query.pageTotals
      }

      return link
    },
    generateFacetLink(facetCode, term) {
      return this.generateLimitLink(this.facetPrefix + facetCode, term)
    },
    generateLimitLink(limit, value) {
      let link = this.generateCurrentPageLinkWithoutPagination()
      let queryLimitValues = link.query[limit]

      if (queryLimitValues) {
        if (!_.isArray(queryLimitValues)) {
          queryLimitValues = [queryLimitValues]
        }

        if (_.includes(queryLimitValues, value)) {
          // If our value exists in the array, remove it
          queryLimitValues = _.reject(queryLimitValues, (v) => v === value)
        } else {
          // If our value doesn't exist in the array, add it
          queryLimitValues.push(value)
        }

        if (queryLimitValues.length === 0) {
          link.query = _.omit(link.query, [limit])
        } else if (queryLimitValues.length === 1) {
          // If the array only has one entry after this, convert it to a string
          link.query[limit] = queryLimitValues[0]
        } else {
          link.query[limit] = queryLimitValues
        }
      } else {
        link.query[limit] = value
      }

      // remove search modifiers as well for all collections except websites and newspapers
      if (!(this.isWebsites || this.isNewspaperAndGazette)) {
        const limitModifier = limit + this.getModifierSuffix();
        if (link.query[limitModifier]) {
          link.query = _.omit(link.query, limitModifier)
        }
      }

      return link
    },
    generateCollectionLink(collection, advanced) {
      if (!collection) {
        return {
          name: this.getSearchRouteName(null, advanced),
          query: this.currentQuery,
        }
      }

      return {
        name: this.getSearchRouteName(collection, advanced),
        params: {
          urlNamespace: collection.urlNamespace,
          urlKey: collection.urlKey,
        },
        query: this.getKeywordOnlyQuery(true),
      }
    },
    getKeywordOnlyQuery(includeGlobalLimits) {
      let pick = ['keyword']

      if (includeGlobalLimits) {
        pick = _.concat(pick, GLOBAL_SEARCH_LIMITS)
      }

      return _.pick(this.currentQuery, pick)
    },
  },
}
