<template>
  <div class="annotation">
    <AchievementNotice :user-achievement="annotation.userAchievement"></AchievementNotice>
    <div class="header">
      <span class="creator">
        <UserThumbnail
          :user-summary="annotation.user"
          size="small"
          :link-username="!annotation.user.anonymous"></UserThumbnail>
        <span title="Private comment" v-if="annotation.priv">
          <font-awesome-icon
            class="private-icon"
            :icon="{ prefix: 'fa', iconName: 'lock' }"></font-awesome-icon>
        </span>
      </span>
      <span class="date"
        ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'calendar' }" />
        {{ formattedDate }}</span
      >

      <span class="delete" v-if="annotation.deletable">
        <b-button
          variant="link"
          @click="deleteAnnotation"
          title="Delete note"
          :disabled="disableControls"
          v-if="!readOnly">
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'minus-circle' }" />
        </b-button>
      </span>

      <span class="edit" v-if="annotation.editable">
        <b-button
          variant="link"
          @click="toggleEditPanel"
          title="Edit note"
          :disabled="disableControls"
          v-if="!readOnly">
          <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'edit' }" />
        </b-button>
      </span>
    </div>
    <div class="content">
      <div v-if="!editPanelOpen">
        <span v-if="!allowHtml">{{ annotation.content }}</span>
        <span v-else v-html="annotation.content"></span>
      </div>

      <div v-if="editPanelOpen && annotation.editable">
        <div class="error" v-if="error && error.summary">
          <Alert :alert="error"></Alert>
        </div>
        <EditAnnotation
          :existing="annotation"
          @save="save"
          v-if="!readOnly"
          :in-progress="saving"
          @cancel="toggleEditPanel"></EditAnnotation>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.annotation {
  padding-top: 1rem;

  .header {
    font-size: 0.9rem;

    .creator {
      display: inline-block;
      margin-right: 1rem;
      min-width: 10rem;
      font-weight: bold;

      .private-icon {
        font-weight: normal;
        margin-left: 0.5rem;
      }
    }

    .date {
      display: inline-block;
      font-weight: bold;
    }

    .delete {
      padding-left: 3rem;
    }
  }

  .content {
    width: 100%;
    padding-top: 0.5rem;
    margin-bottom: 1rem;
  }
}
</style>

<script>
import moment from 'moment'
import service from '@/service/service'
import UserThumbnail from '@/components/profile/UserThumbnail'
import _ from 'lodash'
import auth from '@/mixins/auth'
import EditAnnotation from '@/components/annotations/EditAnnotation.vue'
import Alert from '@/components/Alert.vue'
import AchievementNotice from '@/components/common/AchievementNotice.vue'

export default {
  name: 'Annotation',
  mixins: [auth],
  components: {
    Alert,
    EditAnnotation,
    UserThumbnail,
    AchievementNotice,
  },
  props: {
    annotation: {
      type: Object,
      required: true,
    },
    allowHtml: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: true,
      validator(val) {
        return ['work', 'version', 'person', 'organisation', 'list'].indexOf(_.toLower(val)) > -1
      },
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      editPanelOpen: false,
      error: undefined,
      saving: false,
      disableControls: false,
    }
  },
  computed: {
    creator() {
      return this.anonymous ? 'Anonymous' : this.annotation.userId
    },
    anonymous() {
      return (
        _.startsWith(this.annotation.userId, '*anon*') || this.annotation.userId === 'Anonymous'
      )
    },
    formattedDate() {
      let date = this.annotation.lastModified
      if (!date) {
        date = this.annotation.lastModifiedStr
        let match = date.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}).*/)
        if (match.length > 1) {
          date = match[1] + '-' + match[2] + '-' + match[3] + ' ' + match[4] + ':' + match[5]
        }
      } else {
        date = _.toUpper(moment(date).format('DD MMM YYYY'))
      }

      return date
    },
  },
  methods: {
    deleteAnnotation() {
      let self = this
      self.disableControls = true
      self.$bvModal
        .msgBoxConfirm('Are you sure you wish to remove this note?', {
          cancelVariant: 'link',
        })
        .then((result) => {
          if (result) {
            self.$emit('delete', self.annotation.id)
          } else {
            self.disableControls = false
          }
        })
    },
    toggleEditPanel() {
      this.editPanelOpen = !this.editPanelOpen
      this.disableControls = this.editPanelOpen
    },
    save(anno) {
      let self = this
      self.saving = true
      service.annotations
        .updateAnnotation(self.type, anno)
        .then((result) => {
          _.assign(self.annotation, result)
          self.saving = false

          self.$emit('updated', result)
          self.toggleEditPanel()
        })
        .catch((error) => {
          self.saving = false
          self.handleError(error, 'note', undefined, 'saving your note')
        })
    },
  },
}
</script>
