<template>
  <div>
    <template v-if="isEditing">
      <ValidationObserver ref="observer" tag="div" v-slot="{ invalid, handleSubmit }">
        <b-form @submit.prevent="handleSubmit(update)" @reset="reset" class="d-flex flex-row">
          <ValidationProvider :rules="validationRules" v-slot="{ errors }" :name="errorText || label || 'value'" slim>
            <div>
              <div class="input-row">
                <template v-if="textarea">
                  <b-form-textarea
                      v-model="editValue"
                      rows="4"
                      v-if="tooltip"
                      v-b-tooltip.hover
                      :title="tooltip"
                      :style="inputStyle"/>
                  <b-form-textarea
                      v-model="editValue"
                      rows="4"
                      v-else
                      v-b-tooltip.hover
                      :style="inputStyle"/>
                </template>
                <template v-else>
                  <b-form-input
                    v-if="tooltip"
                    v-model="editValue"
                    :required="required"
                    v-b-tooltip.hover
                    :title="tooltip"
                    :style="inputStyle"></b-form-input>
                  <b-form-input v-else v-model="editValue" :style="inputStyle"></b-form-input>
                </template>
                <div class="buttons">
                  <b-button type="submit" variant="success" :disabled="invalid">
                    <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'check' }"/>
                  </b-button>
                  <b-button type="reset" variant="link">cancel</b-button>
                </div>
              </div>
              <div>
                <div class="hint" v-if="validationRules && validationRules.max">
                  Please enter no more than {{validationRules.max}} characters
                </div>
                <p class="error">{{ errors[0] }}</p>
              </div>
            </div>
          </ValidationProvider>
        </b-form>
      </ValidationObserver>
    </template>
    <template v-else-if="isUpdating">
      <div class="spin d-flex">
        <div>{{ value }}</div>
        <div class="loading-spinner spinner-md"></div>
      </div>
    </template>
    <template v-else>
      <span class="edit" @click="edit" v-if="isMyProfile">
        <template v-if="html">
          <span v-html="value"></span>
        </template>
        <template v-else>{{ value }}</template>
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit' }"/></span>
      <span v-else>
        <template v-if="html">
          <span v-html="value"></span>
        </template>
        <template v-else>{{ value }}</template>
      </span>
    </template>
  </div>
</template>

<style scoped lang="scss">
form {
  height: 2.5rem;

  .input-row {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    .buttons {
      white-space: nowrap;
    }

  }

  .hint {
    font-size: small;
  }

  input, textarea {
    margin-right: 0.7rem;
  }

  button {
    margin-right: 0;
  }

  .error {
    color: colour(form-field-error);
    margin-top: 1rem;
    font-size: medium;
  }
}

.edit {
  cursor: pointer;
}

.edit svg,
.spin .loading-spinner {
  margin-left: 0.5rem;
}
</style>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, max } from 'vee-validate/dist/rules'
import profile from '@/mixins/profile'

extend('required', {
  ...required,
  message: '{_field_}',
})

extend('max', {
  ...max,
  message: 'The provided value is too long',
})

extend('email', email)
export default {
  name: 'EditableText',
  mixins: [profile],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    textarea: {
      type: Boolean,
      required: false,
    },
    errorText: {
      type: String,
      required: false,
    },
    tooltip: {
      type: String,
      required: false,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    validationRules: {
      type: [Object, String],
      required: false,
    },
    inputWidth: {
      type: String,
      required: false,
      default: '20rem',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    html: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      editValue: this.value,
    }
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  mounted() {
    this.setValue();
  },
  computed: {
    inputStyle() {
      return {
        width: this.inputWidth,
      }
    },
  },
  methods: {
    edit() {
      this.isEditing = true;
      this.$emit("editing", true);
    },
    update() {
      this.isEditing = false
      this.$emit("editing", false);
      this.$emit('input', this.editValue)
    },
    reset() {
      this.isEditing = false;
      this.$emit("editing", false);
      this.setValue();
    },
    setValue() {
      this.editValue = this.textarea ? this.value.replaceAll("<br>", "") : this.value;
    }
  },
}
</script>
