import _ from 'lodash'
import utils from '@/mixins/utils'

export default {
  mixins: [utils],
  props: {
    showAvailability: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    title() {
      return this.result.primaryName + (this.result.disambiguation ? ' (disambiguation entry)' : '')
    },
    subtitle() {
      return this.result.occupations
        ? _(this.result.occupations)
            .filter((occupation) => occupation && isNaN(occupation))
            .value()
            .join(', ')
        : null
    },
    format() {
      var mapping = {
        person: 'Person',
        corporatebody: 'Organisation',
        family: 'Family',
      }

      return mapping[this.result.entityType] || this.result.entityType || ''
    },
    defaultFormatIcon() {
      return { prefix: 'fas', iconName: 'user' }
    },
    description() {
      return this.result.snippets
        ? _(this.result.snippets)
            .sortBy((snippet) => snippet.length)
            .reverse()
            .value()[0]
        : ''
    },
    detailsLinkDescriptor() {
      return {
        name: 'People',
        params: {
          id: this.result.id,
        },
        query: this.query,
      }
    },
  },
}
