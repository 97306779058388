<template>
  <div class="cultural-sensitivity-warning" v-if="showWarning">
    <div class="alert">
      <div class="icon">
        <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'exclamation-triangle' }" size="2x" />
      </div>
      <div class="text-and-actions">
        <slot name="text-and-actions" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.cultural-sensitivity-warning {
  position: sticky;
  bottom: 0;
  margin-top: 2rem;

  .alert {
    background-color: colour(yellow);
    line-height: 2.5rem;
    display: flex;

    .show-content {
      margin-right: 0.5rem;
    }

    .icon {
      margin-top: 0.25rem;
    }

    .text-and-actions {
      display: inline-block;
      flex: 1;
      margin-left: 1rem;

      .text {
        line-height: 1.75rem;
      }

      .actions {
        text-align: right;
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
}
</style>
<script>
import cultural from '@/mixins/cultural'

export default {
  name: 'AbstractCulturalSensitivityWarning',
  mixins: [cultural],
  props: {
    identifier: {
      type: String,
      required: true,
    },
    secondaryIdentifier: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      applyToSession: false,
      hideWarning: false,
    }
  },
  computed: {
    showWarning() {
      return (
        !this.hideWarning &&
        this.showCulturalWarnings &&
        this.suppressContent(this.identifier) &&
        (!this.secondaryIdentifier || this.suppressContent(this.secondaryIdentifier))
      )
    },
  },
  methods: {
    showAllContentForSession() {
      this.dismissPrimaryWarning({ show: false, vm: this })
      this.acceptWarningForWork(this.identifier)
      if (this.secondaryIdentifier) {
        this.acceptWarningForWork(this.secondaryIdentifier)
      }
    },
    showContent() {
      this.dismissPrimaryWarning({ show: true, vm: this })
      this.acceptWarningForWork(this.identifier)
      if (this.secondaryIdentifier) {
        this.acceptWarningForWork(this.secondaryIdentifier)
      }
    },
    hideContent() {
      this.hideWarning = true
      this.dismissPrimaryWarning({ show: true, vm: this })
    },
  },
}
</script>
