<template>
  <div>
    <b-row class="content">
      <b-col md="7" lg="8" class="section">
        <div class="descriptionContainer">
          <div>
            <div
              :key="configuration.collection.id"
              v-html="configuration.body"
              class="collection-description"></div>
          </div>
          <div v-if="configuration.callToActionLink" class="section cta d-flex align-content-left">
            <div class="cta-bevel grey flex-fill"></div>
            <div class="cta-link">
              <b-link :href="configuration.callToActionLink"
                >{{ configuration.callToAction }}
                <font-awesome-icon icon="external-link-alt" />
              </b-link>
            </div>
          </div>
          <div v-if="configuration.relatedNews" class="section content">
            <b-col>
              <h1 class="bevel light-blue">News</h1>
              <news-items horizontal :items="configuration.relatedNews" />
            </b-col>
          </div>
        </div>
      </b-col>

      <b-col md="5" lg="4" class="section">
        <collection-contributors />
        <div class="related" v-if="configuration.relatedCollectionPages">
          <h2 class="bevel black">Might also be interested in...</h2>
          <related-collections
            :items="configuration.relatedCollectionPages"
            :followExtUrl="false"
            @article-follow="navToCollection" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped lang="scss">
.section {
  margin-top: 4rem;
}

.descriptionContainer {
  margin-bottom: 3em;
  @media (min-width: 787.98px) {
    max-width: 800px;
    margin-right: 30px;
  }
}

h1.bevel {
  margin-bottom: 2em;
  font-size: 1.5rem !important;

  &:before {
    top: 0.13em !important;
  }
}

h2 {
  font-family: 'Source Serif Pro', serif;
  font-weight: 600;
  font-size: 2.5em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

@supports (font-variation-settings: normal) {
  h2 {
    font-family: 'Source Serif Variable', serif;
  }
}

h2.bevel {
  margin-bottom: 2em;
  font-size: 1.375rem !important;
  font-family: inherit;
  font-weight: bold;
  padding: 0;

  &:before {
    top: 0.2em !important;
  }
}

.collection-description ::v-deep blockquote {
  background: colour(grey);
  border-left: 10px solid colour(grey-5);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: '\201C''\201D''\2018''\2019';
}

.collection-description ::v-deep blockquote:before {
  color: colour(grey-5);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.collection-description ::v-deep blockquote p {
  display: inline;
}

.collection-description ::v-deep h1 {
  color: colour(dark-green);
  background: colour(grey-5);
}

.collection-description ::v-deep p img {
  max-width: 100%;
}

.collection-description ::v-deep ul {
  margin-left: 20px;
  padding-left: 10px;
}

.cta {
  .cta-bevel {
    position: relative;
    height: $primary-button-height;
    @include bevel-div();
  }

  .cta-link {
    margin-left: 3rem;
    margin-top: auto;
    margin-bottom: auto;

    a {
      font-size: 1.2rem;
      font-weight: bold;
      color: colour(magenta);

      svg {
        margin-left: 10px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-active /* .fade-leave-to above version 2.1.8 */ {
  opacity: 0;
}

.contributors {
  ul {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
  }
}
</style>

<script>
import _ from 'lodash'
import RelatedCollections from '@/components/collection/SupportArticles.vue'
import NewsItems from '@/components/collection/SupportArticles.vue'
import CollectionContributors from '@/components/collection/ContributorsList'

export default {
  name: 'AboutCollection',
  components: {
    CollectionContributors,
    RelatedCollections,
    NewsItems,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },
  methods: {
    navToCollection(collectionId) {
      var c = _.find(this.configuration.collection.relatedCollections, ['id', collectionId])
      this.$router.push({
        name: 'Collection',
        params: _.pick(c, ['urlNamespace', 'urlKey']),
      })
    },
  },
}
</script>
