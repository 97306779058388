<template>
  <div class="list-items-container">
    <div class="error" v-if="error && error.summary">
      <Alert :alert="error"></Alert>
    </div>

    <h2 v-if="!headerless">Items in this list</h2>

    <span>{{ listSize }} Items in this list</span>

    <span class="export-list">
      <AddUrlToList
        v-if="canAddUrlItem"
        class="action"
        :list="list"
        @url-item-added="addUrlToList" />
      <ExportList class="action" :list="list" />
    </span>

    <template v-if="allItems !== undefined">
      <FilterContainer
        :items="allItems"
        :propertyFilterSpecifications="propertyFilterSpecifications"
        :componentFilterSpecifications="componentFilterSpecifications"
        @filter-updated="filterUpdated" />

      <div class="sort-container" v-if="!canModify && sortOptions && sortOptions.length > 0">
        <label for="sortOptions" class="sort-label">Sort by:</label>
        <b-form-select id="sortOptions" v-model="sortBy" class="sort-options" @change="sortOrderChanged">
          <option v-for="sort in sortOptions" :key="sort" :value="sort">
            {{ sort | capitalize }}
          </option>
        </b-form-select>
        <b-form-checkbox v-model="sortDesc" :value="true" class="sort-direction" @change="sortOrderChanged">
          Descending
        </b-form-checkbox>
      </div>
    </template>

    <div class="items">
      <template v-if="!loading">
        <template v-if="canModify">
          <draggable
            v-model="displayItems"
            class="draggable"
            handle=".handle"
            v-bind="dragOptions"
            @change="itemMoved"
            @start="drag = true"
            @end="drag = false">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <div v-for="item in displayItems" :key="item.id" :item="item">
                <ListItem
                  :item="item"
                  :listSize="listSize"
                  :list-author="listAuthor"
                  :canModify="canModify"
                  @item-removed="itemRemoved"
                  @item-moved="listItemMoved"
                  :can-reorder="canModify && (listOwner || listAdministrator) && listSize > 1"
                  :list-owner="listOwner"
                  :list-administrator="listAdministrator"
                  :list-collaborator="listCollaborator" />
              </div>
            </transition-group>
          </draggable>
        </template>
        <template v-else>
          <div v-for="item in displayItems" :key="item.id" :item="item">
            <ListItem
              :item="item"
              :listSize="listSize"
              :list-author="listAuthor"
              :can-modify="canModify"
              :list-owner="listOwner"
              :list-administrator="listAdministrator"
              :list-collaborator="listCollaborator" />
          </div>
        </template>
      </template>
      <template v-else>
        <template v-if="numStubs">
          <div class="d-flex stub" v-for="index in numStubs" :key="index">
            <div class="thumbnail"></div>
            <div class="body">
              <div class="line" style="width: 30%"></div>
              <div class="line" style="width: 60%"></div>
              <div class="block"></div>
              <div class="line" style="width: 20%"></div>
            </div>
          </div>
        </template>
      </template>

      <div v-if="noFilteredItems && !noItems && !loading">
        <Alert
          :alert="{
            summary: 'No items match the selected filter criteria',
            impact: 'info',
          }"></Alert>
      </div>

      <div v-if="noFilteredItems && noItems && !loading">
        <Alert :alert="{ summary: 'This list is empty', impact: 'info' }"></Alert>
      </div>
    </div>
    <div v-if="showPagination">
      <Paginator
        :current-page="currentPage"
        :total-rows="pageRows"
        :page-size="pageSize"
        :linkGenerator="paginationLinkGenerator" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
import filtering from '@/mixins/filtering'
import auth from '@/mixins/auth'
import lists from '@/mixins/lists'
import simplepagination from '@/mixins/simplepagination'
import service from '@/service/service'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import Alert from '@/components/Alert.vue'
import LanguageFilter from '@/components/work/LanguageFilter.vue'
import DateFilter from '@/components/work/DateFilter.vue'
import ListItem from '@/components/lists/ListItem'
import Paginator from '@/components/search/Paginator.vue'
import FilterContainer from '@/components/work/FilterContainer.vue'
import ExportList from '@/components/lists/ExportList.vue'
import AddUrlToList from '@/components/lists/AddUrlToList.vue'

const DEFAULT_PAGE_SIZE = 25
const moment = extendMoment(Moment)

export default {
  name: 'ListItems',
  mixins: [filtering, auth, lists, simplepagination],
  components: {
    draggable,
    Alert,
    ListItem,
    Paginator,
    FilterContainer,
    ExportList,
    AddUrlToList,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
    listSize: {
      type: Number,
      required: true,
    },
    listAuthor: {
      type: String,
      required: true,
    },
    listCollaborator: {
      type: Boolean,
      required: false,
    },
    listAdministrator: {
      type: Boolean,
      required: false,
    },
    listOwner: {
      type: Boolean,
      required: false,
    },
    headerless: {
      type: Boolean,
      required: false,
      default: false,
    },
    canModify: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      pageSize: DEFAULT_PAGE_SIZE,
      pageRows: this.listSize,
      typeFilter: [],
      langFilter: [],
      dateFilter: [],
      itemsDateRange: {
        start: '',
        end: '',
      },
      sortOptions: [],
      sortBy: "DEFAULT",
      sortDesc: false,
      displayItems: [],
      oldDisplayItems: [],
      drag: false,
      allItems: [],
      filteredItems: [],
      loading: true,
      numStubs: DEFAULT_PAGE_SIZE,
      source: service.source(),
    }
  },
  created() {
    this.updateFilters()
  },
  mounted() {
    service.config
      .environment()
      .then((response) => {
        this.pageSize = response.defaultPageSize
      })
      .catch(() => {
        this.pageSize = DEFAULT_PAGE_SIZE
      })
  },
  computed: {
    canAddUrlItem() {
      return (
        this.authenticated &&
        (this.list.currentUserAContributor || this.isListOwner(this.list.author))
      )
    },
    listId() {
      return this.list.id
    },
    showPagination() {
      return !this.noFilteredItems && this.allItems && this.allItems.length > this.pageSize
    },
    noItems() {
      return _.isEmpty(this.allItems)
    },
    noFilteredItems() {
      return _.isEmpty(this.displayItems)
    },
    propertyFilterSpecifications() {
      return [
        {
          name: 'Type',
          options: this.allItemsByType,
          placeholder: 'Filter by type',
          label: 'display',
          valueField: 'type',
          filter: (item, selectedOptions) => {
            if (_.isEmpty(selectedOptions)) {
              return true
            }

            return (
              _.filter(selectedOptions || [], (selectedOption) => {
                return _.includes(selectedOption.ids, item.id)
              }).length > 0
            )
          },
        },
      ]
    },
    componentFilterSpecifications() {
      var self = this

      return [
        {
          name: 'Languages',
          component: LanguageFilter,
          props: {
            languages: self.languages,
          },
          predicate: () => !_.isEmpty(self.languages) && _.keys(self.languages).length > 1,
          filter: (item, selectedLanguages) => {
            if (_.isEmpty(selectedLanguages)) {
              return true
            }

            var selectedLanguagesWithIds = _(selectedLanguages)
              .map((selectedLanguage) =>
                _.find(
                  self.langFilter,
                  (languageWithIds) => languageWithIds.value === selectedLanguage
                )
              )
              .value()
            var matchingLanguages = _(selectedLanguagesWithIds)
              .filter((selectedLanguageWithIds) =>
                _.find(selectedLanguageWithIds.ids, (id) => id === item.id)
              )
              .value()

            return !_.isEmpty(matchingLanguages)
          },
        },
        {
          name: 'Date range',
          component: DateFilter,
          props: {
            defaultSelected: self.itemsDateRange,
          },
          filter: (item, startYear, endYear) => {
            if (!startYear && !endYear) {
              return true
            }

            const selectedMoment = moment.range(
              startYear ? moment(startYear, 'YYYY') : null,
              endYear ? moment(endYear, 'YYYY') : null
            )

            var selectedDateRangesWithIds = _(self.dateFilter)
              .filter((dateRangeWithIds) => {
                let range = dateRangeWithIds.value.split('-')
                const filterStart = range[0] ? moment(range[0], 'YYYY') : null
                const filterEnd = range[1] ? moment(range[1], 'YYYY') : null

                return (
                  filterStart &&
                  filterStart.within(selectedMoment) &&
                  filterEnd &&
                  filterEnd.within(selectedMoment)
                )
              })
              .value()

            var matchingDateRanges = _(selectedDateRangesWithIds)
              .filter((selectedDateRangeWithIds) =>
                _.find(selectedDateRangeWithIds.ids, (id) => id === item.id)
              )
              .value()

            return !_.isEmpty(matchingDateRanges)
          },
        },
      ]
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    filteredLanguages() {
      return this.getLanguagesFromItems(this.filteredItems)
    },
    languages: function () {
      return this.getLanguagesFromItems(this.allItems)
    },
    allItemsByType() {
      return _(this.typeFilter)
        .sortBy((item) => item.value)
        .map((item) => {
          let idArray = item.ids
          return {
            type: item.value,
            ids: idArray,
            count: idArray.length,
            display: item.value + ' (' + idArray.length + ')',
          }
        })
        .value()
    },
  },
  methods: {
    getLanguagesFromItems(items) {
      const langs = _(this.langFilter || [])
        .filter((filter) => {
          const matchingItems = _.filter(items, (item) => {
            return _.find(filter.ids, (id) => id === item.id)
          })
          return !_.isEmpty(matchingItems)
        })
        .value()

      const lang = _(langs).reduce((res, f) => {
        res[f.value] = f.ids.length
        return res
      }, {})

      return lang
    },
    sortOrderChanged() {
      this.updateDisplayItems();
    },
    updateFilters() {
      let self = this
      return self.fetchFilters().then(() => {
        self.filteredItems = self.allItems
      })
    },
    fetchFilters() {
      let self = this

      const typeFilter = service.list.getTypeFilter(self.listId).then((filters) => {
        self.typeFilter = filters.type
      })

      const langFilter = service.list.getLangFilter(self.listId).then((filters) => {
        self.langFilter = filters.lang
      })

      const dateFilter = service.list.getDateFilter(self.listId).then((filters) => {
        self.dateFilter = filters.date
        self.setDateRangeFilter()
      })

      const allItems = service.list.getAllListItems(self.listId).then((filters) => {
        self.allItems = filters.all;
        self.sortOptions = filters.sortOptions;
      })

      return Promise.all([allItems, typeFilter, langFilter, dateFilter]).then(() => {
        if (_.isEmpty(self.allItems)) {
          self.allItems = self.getAllItemsInFilters()
        }
      })
    },
    getAllItemsInFilters() {
      let self = this;
      var allFilters = _.concat(this.typeFilter || [], this.langFilter || [], this.dateFilter || [])

      var allIds = _.reduce(
        allFilters,
        (allIds, filter) => {
          return allIds.concat(filter.ids || [])
        },
        []
      )

      var items = []

      for (var i = 0; i < allIds.length; i++) {
        items.push(self.allItems.find(item => item.id === allIds[i]));
      }

      items = _(items)
        .uniqBy((item) => item.id)
        .sortBy((item) => {
          let seq = parseInt(item.seq);
          if (self.sortBy === 'DATE') {
            seq = parseInt(item.dateSeq);
          } else if (self.sortBy === 'TITLE') {
            seq = parseInt(item.titleSeq);
          }
          return seq;
        })
        .value()

      return items
    },
    updateListItems() {
      let self = this

      let start = (self.currentPage - 1) * self.pageSize

      let end = start + self.pageSize
      let sortedItems = _.sortBy(this.filteredItems, (item) => {
        let itemDef = self.allItems.find(i => i.id === item.id);
        let seq = parseInt(itemDef.seq);
        if (self.sortBy === 'DATE') {
          seq = parseInt(itemDef.dateSeq);
        } else if (self.sortBy === 'TITLE') {
          seq = parseInt(itemDef.titleSeq);
        }
        return self.sortDesc ? -seq : seq;
      })

      let ids = _.map(sortedItems, (item) => item.id)

      let paginatedIds = _.slice(ids, start, end)
      self.pageRows = ids.length

      if (!_.isEmpty(paginatedIds)) {
        self.loading = true

        // refresh source before sending cancel token with request
        self.source = service.source()

        self.displayItems = Array(paginatedIds.length)

        for (let i = 0; i < paginatedIds.length; i++) {
          self.displayItems.splice(i, 1, { id: paginatedIds[i] })
          service.list
            .getListItemById(self.listId, paginatedIds[i], self.source.token)
            .then((item) => {
              self.loading = false
              if (item) {
                self.displayItems.splice(self.displayItems.findIndex(i => _.toNumber(i.id) === _.toNumber(item.id)), 1, item)
              } else {
                self.displayItems.splice(i, 1)
              }
            })
            .catch((error) => {
              self.loading = false
              self.handleError(error, 'list', self.listId, 'retrieve list item ' + paginatedIds[i])
            })
        }
      } else {
        self.displayItems = []
      }
    },
    setDateRangeFilter() {
      let self = this

      if (!_.isEmpty(self.dateFilter)) {
        let startDate = _.head(self.dateFilter).value.split('-')[0]
        let endDate = _.last(self.dateFilter).value.split('-')[1]

        self.itemsDateRange.start = startDate === 'null' ? '' : startDate
        self.itemsDateRange.end = endDate === 'null' ? '' : endDate
      }
    },
    itemRemoved(listItem) {
      this.listItemRemoved(listItem)
    },
    updateDisplayItems() {
      this.updateListItems()
    },
    itemMoved(event) {
      if (event && event.moved) {
        var elementToDisplace = this.oldDisplayItems[event.moved.newIndex]
        var displacingElement = this.oldDisplayItems[event.moved.oldIndex]
        var newListSeq = elementToDisplace.listSeq

        this.listItemMoved(displacingElement, newListSeq)
      }
    },
    listItemMoved(item, newSeq) {
      let self = this
      service.list
        .moveListItem(item.listId, item.id, newSeq)
        .then(() => {
          return self.updateFilters()
        })
        .then(() => {
          self.updateListItems()
        })
        .catch((error) => {
          self.saving = false
          self.handleError(error, 'list', undefined, 'move this item')
        })
    },
    addUrlToList(item) {
      var self = this

      var ltarget = { id: item.link, type: 'uri', description: item.title }
      var lreason = item.note
      var itemTitle = item.title

      service.list
        .addUrlItemToList(this.listId, ltarget, lreason)
        .then((data) => {
          self.$emit('item-added')

          item.link = null
          item.title = null
          item.note = null

          // item won't yet be searchable in solr, so can't be retrieved.  Append it in our model instead.

          var newListId = data.id
          var newSeq = data.listSeq

          let addItemToModel = function (mFilter, mValue, listId, seq) {
            if (mFilter) {
              var tf = _.find(mFilter, {value: mValue})
              if (_.isUndefined(tf)) {
                tf = {value: mValue, ids: [], sequences: []}
                mFilter.push(tf)
              }
              tf.ids.push('' + listId)
              if (tf.sequences) {
                tf.sequences.push('' + seq)
              }
            }
          }

          addItemToModel(self.typeFilter, 'website', data.id, data.listSeq)
          addItemToModel(self.langFilter, 'english', data.id, data.listSeq)

          self.allItems.push({ id: '' + newListId, seq: '' + newSeq })
          self.updateDisplayItems()

          self.error = {
            summary: "Added '" + itemTitle + "' to the end of this list.",
            impact: 'info',
          }
        })
        .catch((error) => {
          self.saving = false
          self.handleError(error, 'list', undefined, 'adding the URL to your list')
        })
    },
    listItemRemoved(item) {
      let self = this
      self.$bvModal
        .msgBoxConfirm('Are you sure you wish to remove the item from this list?', {
          cancelVariant: 'link',
        })
        .then((result) => {
          if (result) {
            service.list
              .removeItemFromList(item.listId, item.id)
              .then(() => {
                self.updateFilters().then(() => {
                  self.updateListItems()
                  self.$emit('list-item-removed')
                })
              })
              .catch((error) => {
                self.saving = false
                self.handleError(error, 'list', undefined, 'remove this item from your list')
              })
          }
        })
    },
    filterUpdated(filteredItems) {
      this.filteredItems = filteredItems
    },
  },
  watch: {
    currentPageRaw() {
      this.updateListItems()
    },
    displayItems: {
      immediate: true,
      handler(newVal, oldVal) {
        this.oldDisplayItems = oldVal
      },
    },
    filteredItems(after, before) {
      if (!_.isEqual(after, before)) {
        this.source.cancel('filters updated')
        this.updateDisplayItems()
      }
    },
  },
}
</script>

<style lang="scss">
.draggable {
  .handle {
    cursor: move;
  }
}
</style>

<style scoped lang="scss">
$arrow-height: 20px;
$button-spacing: 1rem;

$thumbnail-dimension: 80px;
$thumbnail-max-aspect-ratio: 1.5;

.stub {
  .thumbnail {
    width: $thumbnail-dimension;
    height: $thumbnail-dimension * $thumbnail-max-aspect-ratio;
    margin-right: 1em;
  }

  .body {
    width: 100%;
  }
}

.list-items-container {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid black;

  .items {
    margin-top: 2rem;
  }

  .export-list {
    float: right;
    .action {
      margin-left: 1rem;
    }
  }

  .type-buttons {
    margin-top: 1em;

    button {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      background: colour(white);
      color: colour(purple);
      transition: 0.5s ease-in-out;
      font-weight: 600;
      outline: none;

      &:hover,
      &:active {
        background: colour(purple);
        color: colour(white);
      }

      &:focus {
        box-shadow: none;
      }

      &.inverse {
        background: colour(purple);
        color: colour(white);
      }

      .count {
        font-weight: 500;
        opacity: 0.7;
        margin-left: 0.3em;
      }
    }

    .button-container {
      button {
        margin-right: 1rem;
        background: transparent !important;
        border-color: transparent !important;
        color: colour(purple);

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        button {
          margin-right: 0rem;
        }

        .arrow-container {
          margin-right: 0rem;
        }
      }

      .arrow-container {
        margin-right: 1rem;

        .arrow {
          width: 0;
          height: 0;
          border-left: 16px solid transparent;
          border-bottom: $arrow-height solid colour(grey);
          border-right: 16px solid transparent;
          margin-top: 0;
          position: relative;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .sort-container {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    justify-content: flex-end;

    .sort-label {
      white-space: nowrap;
      display: inline-block;
      margin-right: 0.7rem;
      margin-bottom: 0;
    }

    .sort-options {
      margin-right: 0.7rem;
      width: auto;
    }
  }
}
</style>
