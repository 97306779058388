<template>
  <span class="hint">
    <template v-if="warning">{{ warningText }}: </template>
    <span :class="warning ? 'warning' : ''">{{ count }}</span> of {{ max }}
  </span>
</template>

<style scoped lang="scss">
.hint {
  font-size: 0.8rem;
  color: colour(grey-1);

  .warning {
    color: #ff733e;
    font-weight: bold;
  }
}
</style>

<script>
export default {
  name: 'Countdown',
  props: {
    count: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    warningText: {
      type: String,
      required: false,
      default: 'Characters left',
    },
    warningCount: {
      type: Number,
      required: false,
    },
  },
  computed: {
    warning() {
      return this.warningCount && this.count <= this.warningCount
    },
  },
}
</script>
