<template>
  <b-container v-if="crumbs.length">
    <b-row no-gutters>
      <b-col class="breadcrumbs-container d-flex flex-row">
        <div v-for="crumb in breadCrumbs" :key="crumb.value" class="crumb mt-4">
          <template v-if="crumb.link">
            <router-link :to="crumb.link">{{
              crumb | stripHtml | truncate(breadCrumbs.length)
            }}</router-link>
          </template>
          <template v-else>
            {{ crumb | stripHtml | truncate(breadCrumbs.length) }}
          </template>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
.breadcrumbs-container {
  font-size: 0.875rem;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  div {
    padding-right: 1.4em;
  }

  div:last-child {
    padding-right: inherit;
  }

  div::after {
    content: '/';
    position: relative;
    right: -0.7em;
  }

  div:last-child::after {
    display: none;
  }
}
</style>

<script>
const MAX_LEN = 50
import service from '@/service/service'
import _ from 'lodash'
import history from '@/mixins/history'

export default {
  name: 'Breadcrumbs',
  mixins: [history],
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
    showHistory: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      historic: [],
    }
  },
  watch: {
    crumbs: {
      immediate: true,
      handler() {
        this.updateHistoric()
      },
    },
    showHistory: {
      immediate: true,
      handler() {
        this.updateHistoric()
      },
    },
  },
  methods: {
    updateHistoric() {
      const self = this
      const route = this.latestBreadCrumbRoute

      if (route) {
        if (route.params.urlNamespace && route.params.urlKey) {
          service.landing.getCategories().then((cat) => {
            let category = _.filter(cat, (c) => c.urlKey === route.params.urlKey)
            let name =
              category && category.length === 1 && category[0].name ? category[0].name + ' - ' : ''
            self.historic = [
              {
                link: route.fullPath,
                value: name + route.meta.breadCrumbName || route.name,
                doNotTruncate: true,
              },
            ]
          })
        } else {
          self.historic = [
            {
              link: route.fullPath,
              value: route.meta.breadCrumbName || route.name,
            },
          ]
        }
      } else {
        self.historic = []
      }
    },
  },
  computed: {
    breadCrumbs() {
      return [{ link: '/', value: 'Home' }]
        .concat(this.showHistory ? this.historic : [])
        .concat(this.crumbs)
    },
  },
  filters: {
    truncate: function (crumb, breadCrumbsLength) {
      let str = crumb.value
      if (
        !str ||
        str.length <= MAX_LEN ||
        crumb.doNotTruncate ||
        (breadCrumbsLength <= 3 && str.length <= 150)
      ) {
        return str + (crumb.untruncatedSuffix ? ' ' + crumb.untruncatedSuffix : '')
      }

      // split on space
      var i = str.lastIndexOf(' ', MAX_LEN)
      if (i === -1) {
        i = MAX_LEN
      }
      return (
        str.slice(0, i) + ' ...' + (crumb.untruncatedSuffix ? ' ' + crumb.untruncatedSuffix : '')
      )
    },
    stripHtml: function (crumb) {
      const tmp = document.createElement('div')
      tmp.innerHTML = crumb.value
      return {
        ...crumb,
        value: tmp.textContent || tmp.innerText,
      }
    },
  },
}
</script>
