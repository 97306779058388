<template>
  <b-modal :id="modalId" class="modal" @ok="ok" @close="cancel" @cancel="cancel">
    <template v-slot:modal-title>Merge these results together into a single work?</template>

    <h4>Are you certain?</h4>
    <p>
      Are you sure you want to merge the following
      {{ sortedResults.length }} works into a single work?
    </p>

    <div class="results">
      <div v-for="result in sortedResults" class="result" :key="result.id">
        <div v-if="result.title" class="d-flex">
          <div class="fieldName">Title:</div>
          <div class="flex-grow-1">
            <b>{{ result.title }}</b>
          </div>
        </div>
        <div v-if="result.primaryName">
          <div class="fieldName">Name:</div>
          <div class="flex-grow-1">
            <b>{{ result.primaryName }}</b>
          </div>
        </div>
        <div v-if="result.author" class="d-flex">
          <div class="fieldName">Author:</div>
          <div class="flex-grow-1">
            <b>{{ result.author }}</b>
          </div>
        </div>
        <div v-if="result.yearRange" class="d-flex">
          <div class="fieldName">Year:</div>
          <div class="flex-grow-1">
            <b>{{ result.yearRange }}</b>
          </div>
        </div>
        <div class="target-radio">
          <b-form-radio v-model="targetVersion" name="target-version" :value="result.id"
            >Merge into this work</b-form-radio
          >
        </div>
      </div>
    </div>

    <p>
      You should only proceed if you are <b>COMPLETELY CERTAIN</b> that these two results are really
      the same <b><i>work</i></b
      >, and have been mis-catalogued or erroneously separated or not grouped.
    </p>
    <p>
      For help and more information,
      <a
        href="http://help.nla.gov.au/trove/using-trove/creating-contributing/splitting-merging"
        target="_new"
        >view our guidelines <i>(opens new window)</i></a
      >
      on merging results together to become the same work.
    </p>

    <p>
      Write a note here if you think it will help others understand why you've decided to merge
      these works:
    </p>
    <p>
      <b-form-textarea v-model="note" rows="4" :maxlength="200"></b-form-textarea>
    </p>

    <p>
      Note: Merged records will never be automatically separated, but may be separated manually.
      <i>What has been joined by Man, let no Algorithm put asunder.</i>
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="link" @click="cancel">Cancel</b-button>
      <b-button variant="primary" @click="ok">I am certain! Merge these results!</b-button>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import service from '@/service/service'
import auth from '@/mixins/auth'
import BulkOperation from '@/components/bulk/BulkOperation.vue'

var EXCLUDED_WORK_TYPES = ['newspaper', 'people', 'website', 'list']

export default {
  name: 'BulkMerge',
  extends: BulkOperation,
  mixins: [auth],
  data() {
    return {
      note: '',
      targetVersion: null,
      sortedResults: [],
    }
  },
  watch: {
    selectedResults: {
      immediate: true,
      handler() {
        this.sortedResults = _(this.selectedResults || [])
          .sortBy((result) => result.numItems)
          .reverse()
          .value()

        if (!_.isEmpty(this.sortedResults)) {
          this.targetVersion = this.sortedResults[0].id
        }
      },
    },
  },
  computed: {
    operationId: () => 'merge',
    buttonText: () => 'Merge selected works',
    buttonDisabled() {
      return this.selectedResults.length < 2
    },
    canPerformOperation() {
      if (!_.isArray(this.allResults) || _.includes(this.allResults, null)) {
        return false
      }

      if (
        _(this.allResults)
          .map((result) => result.workType)
          .filter((type) => _.includes(EXCLUDED_WORK_TYPES, type))
          .value().length > 0
      ) {
        return false
      }

      return this.authenticated && this.isDataManager
    },
  },
  methods: {
    ok() {
      var self = this

      var toMerge = this.sortedResults

      var target = _.filter(this.sortedResults, (result) => result.id === this.targetVersion)
      var rest = _.reject(this.sortedResults, (result) => result.id === this.targetVersion)

      if (_.isArray(target) && target.length === 1) {
        toMerge = _.concat(target, rest)
      }

      service.work
        .mergeWorks(
          _.map(toMerge, (result) => result.id),
          this.note
        )
        .then(() => {
          self.cancel()
          self.$emit(
            'bulk-operation-succeeded',
            // eslint-disable-next-line max-len
            'The selected works have been merged.  It could take up to several minutes before this is reflected in the search results.'
          )
        })
        .catch((error) => {
          self.$emit('bulk-operation-failed', error.response.data.message)
        })
    },
    cancel() {
      this.note = ''
    },
  },
}
</script>
