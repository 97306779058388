// for vue-observe-visibility
require('intersection-observer')
require('es6-promise').polyfill()
require('es6-math')

import 'url-search-params-polyfill'
import _ from 'lodash'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import service from './service/service'

import * as Keycloak from 'keycloak-js'
import VueLogger from 'vuejs-logger'
import VueGtm from '@gtm-support/vue2-gtm'

import Bootstrap from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueObserveVisibility from 'vue-observe-visibility'

import VueClipboard from 'vue-clipboard2'
import LineClamp from 'vue-line-clamp'
import Fragment from 'vue-fragment'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { faMap } from '@fortawesome/free-solid-svg-icons'
import { faAtlas } from '@fortawesome/free-solid-svg-icons'
import { faGlobeAsia } from '@fortawesome/free-solid-svg-icons'
import { faMusic } from '@fortawesome/free-solid-svg-icons'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { faImage } from '@fortawesome/free-regular-svg-icons'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faArchive } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faFrown } from '@fortawesome/free-regular-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

library.add(
  faUser,
  faBook,
  faCheckSquare,
  faEyeSlash,
  faSmile,
  faSpinner,
  faCalendar,
  faNewspaper,
  faMap,
  faAtlas,
  faGlobeAsia,
  faMusic,
  faVolumeUp,
  faVideo,
  faImage,
  faLocationArrow,
  faTimesCircle,
  faTimes,
  faEye,
  faFrown,
  faLocationArrow,
  faQuestionCircle,
  faLock,
  faArrowRight,
  faTag,
  faPlus,
  faInfo,
  faEdit,
  faArchive,
  faUsers,
  faBuilding,
  faCopyright,
  faCaretUp,
  faCaretDown,
  faExternalLinkAlt,
  faInfoCircle,
  faExternalLinkAlt,
  faSearch,
  faAngleDown,
  faArrowsAlt,
  faTrash,
  faCheck
)

Vue.config.productionTip = false

Vue.use(Bootstrap)
Vue.use(VueObserveVisibility)
Vue.use(VueClipboard)

Vue.use(LineClamp)
Vue.use(Fragment.Plugin)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.mixin({
  data: function () {
    return {
      globalTroveConfig: {
        // Note: currently maps to the landingPageType.
        homePageType: 'HOME',
      },
    }
  },
})

import numbro from 'numbro'
Vue.filter('formatNumber', (value) => {
  return numbro(value).format({ thousandSeparated: true })
})

Vue.filter('capitalize', (value) => {
  return _.capitalize(value)
})

import isURL from 'validator/lib/isURL'
import { extend } from 'vee-validate'
import { required, max } from 'vee-validate/dist/rules'

extend('requiredPlain', {
  ...required,
  message: 'Please enter a value for this field.',
})

extend('maxPlain', {
  ...max,
  message: 'Please enter a shorter value for this field.',
})

extend('urlProtocol', {
  message: 'URL starting with \'https://webarchive.nla.gov.au/\' must be from the Trove Websites category',
  validate: (value) => {
    const fullURLPattern = /^https:\/\/webarchive.nla.gov.au\/(\?*)/i
    const m1 = value.match(fullURLPattern)
    const valid = m1 && isURL(value, { protocols: ['https'], require_protocol: true })

    return new Promise((resolve) => {
      resolve({ valid: valid, data: {} })
    })},
})

extend('url', {
  message: 'Please enter a valid website address.',
  validate: (value) => isURL(value),
})

extend('orcid', {
  message: 'Please enter a valid 16-digit ORCID iD.',
  validate: (value) => {
    let valid = false
    let m = value.match(/^(\d{4}-){3}\d{3}[0-9Xx]$/)
    if (m && m.length > 1) {
      let total = 0
      let digits = _.replace(value, /-/g, '').substr(0, 15)
      for (var i = 0; i < digits.length; i++) {
        total = (total + _.toNumber(digits[i])) * 2
      }
      let remainder = total % 11
      let result = (12 - remainder) % 11
      let checkDigit = result === 10 ? 'X' : '' + result
      valid = _.endsWith(value, checkDigit)
    }
    return valid
  },
})

extend('facebook', {
  message: 'The value provided is not a valid Facebook handle.',
  validate: (value) => {
    let fullURLPattern = /^((https?:\/\/)|(www.))(facebook.com)?.*?$/i
    let m1 = value.match(fullURLPattern)
    let valid = !m1 || m1.length === 0

    return new Promise((resolve) => {
      resolve({ valid: valid, data: {} })
    })
  },
})

extend('twitter', {
  message: 'The value provided is not a valid Twitter handle.',
  validate: (value) => {
    let fullURLPattern = /^((https?:\/\/)|(www.))(twitter.com)?.*?$/i
    let m1 = value.match(fullURLPattern)
    let valid = !m1 || m1.length === 0

    return new Promise((resolve) => {
      resolve({ valid: valid, data: {} })
    })
  },
})

extend('email', {
  message: 'The Email provided is not valid.',
  validate: (value) => {
    let emailPattern =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+([-]*[a-zA-Z0-9]+)*([.]?[a-zA-Z0-9]+([-]*[a-zA-Z0-9]+)*)*$/
    let valid = value.match(emailPattern)

    return new Promise((resolve) => {
      resolve({ valid: valid, data: {} })
    })
  },
})

import errorHandling from '@/mixins/errorHandling'
Vue.mixin(errorHandling)

const logOptions = {
  isEnabled: process.env.NODE_ENV !== 'production',
  logLevel: 'debug',
  stringifyArguments: true,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
}
Vue.use(VueLogger, logOptions)

service.auth.installApikeyInterceptor()

import { sync } from 'vuex-router-sync'
sync(store, router, { moduleName: 'route' })

service.config
  .environment()
  .then((config) => {
    Vue.use(VueGtm, {
      id: config.gtmId,
      enabled: !config.gtmDisabled && config.gtmId,
      vueRouter: router,
    })

    service.auth.installApikeyRefresher(config.apikeyRefreshInterval)

    let keycloak = new Keycloak(config.kcInit)

    keycloak.onAuthSuccess = () => {
      store.dispatch('trove/recordAsHuman')
    }

    // Sets Keycloak as Vue instance property similar to the vue-keycloak
    // plugin to reduce refactoring authentication related code.
    Vue.prototype.$keycloak = keycloak
    Vue.prototype.$kcRedirectUrl = config.kcInit.redirectUri
    Vue.prototype.$kcAuthUrl = config.kcInit.kcAuthUrl

    keycloak
      .init({ onLoad: 'check-sso' })
      .success((auth) => {
        if (!auth) {
          Vue.$log.info('Not authenticated')
        } else {
          Vue.$log.info('Authenticated')
        }

        // sets the Bearer token on every axios call
        service.auth.installTokenInterceptor(Vue)

        new Vue({
          router,
          store,
          metaInfo() {
            return { title: 'Trove' }
          },
          created() {
            // eslint-disable-next-line
                clearTimeout(troveInitTimeout);  // troveInitTimeout is a global var defined in index.html
          },
          render: (h) => h(App),
        }).$mount('#app')

        const tokenExpireWithinSeconds = 10 * 60 // 10 minutes
        const updateTokenInMilliSeconds = 55 * 60 * 1000 // 55 minutes as access token expire in one hour
        // refresh the token if expiring within 10 minutes
        setInterval(() => {
          keycloak
            .updateToken(tokenExpireWithinSeconds)
            .success((refreshed) => {
              if (refreshed) {
                Vue.$log.debug('Token refreshed: ' + refreshed)
              } else {
                Vue.$log.warn(
                  'Token not refreshed. Valid for ' +
                    Math.round(
                      keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
                    ) +
                    ' seconds'
                )
              }
            })
            .error(() => {
              Vue.$log.error('Failed to refresh token')
            })
        }, updateTokenInMilliSeconds)
      })
      .error((error) => {
        Vue.$log.error('Failed to initialise Keycloak: ' + JSON.stringify(error))
      })
  })
  .catch(function () {
    // eslint-disable-next-line
    troveInitError("Refresh to try again. (Couldn't retrieve configuration.)");
  })
