<template>
  <Stubbable :stubbed="stubbed" class="random">
    <template v-slot:content>
      <div class="result" :class="isResultSelected ? 'selected' : ''">
        <MultiLink :link="link">
          <div class="sub-container">
            <Thumbnail
              :url="result.thumbnailUrl"
              :title="'Thumbnail for ' + title"
              type="image"
              :blur="blurThumbnail"></Thumbnail>

            <template v-if="format"
              ><span class="format"
                ><font-awesome-icon v-if="formatIcon" :icon="formatIcon" />{{ format }}</span
              ></template
            >
            <template v-if="subtitle && format"> - </template
            ><template v-if="subtitle"
              ><span class="subtitle">{{ subtitle }}</span></template
            >

            <div class="caption">{{ title }}</div>

            <div class="main-indicators">
              <div class="contributor" v-if="showContributor">
                <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'location-arrow' }" />{{
                  contributorText
                }}
              </div>
              <OnlineAvailability
                v-if="result && showAvailability"
                :availability="result.onlineText"></OnlineAvailability>
              <FirstAustraliansIndicator
                v-if="result.firstAustraliansInd"></FirstAustraliansIndicator>
            </div>
          </div>
        </MultiLink>

        <div class="select" v-if="canSelect">
          <b-form-checkbox v-model="selectedInternal" switch>Selected</b-form-checkbox>
        </div>
      </div>
    </template>
    <template v-slot:stub>
      <div class="stub">
        <div class="d-flex flex-column sub-container">
          <div class="thumbnail"></div>
          <div class="line" style="width: 100%"></div>
          <div class="line" style="width: 40%"></div>
        </div>
      </div>
    </template>
  </Stubbable>
</template>

<style scoped lang="scss">
$container-absolute-width: 200px;
$container-margin: 5px;
$container-absolute-height: 250px;

$arrow-height: 20px;

$thumbnail-dimension: 200px;

$font-size: 0.9rem;

.stub {
  width: $container-absolute-width;
  @media (max-width: 575px) {
    width: 50%;
  }
  margin: 0;
  padding: 0;

  .sub-container {
    width: $container-absolute-width - ($container-margin * 2);
    margin: $container-margin;
    @media (max-width: 575px) {
      width: auto;
    }

    .thumbnail {
      width: 100%;
      height: 100%;
    }

    .thumbnail {
      height: $thumbnail-dimension;

      margin-left: auto;
      margin-right: auto;
      margin-top: $container-margin;
      margin-bottom: 0.5rem;
    }
  }
}

.result {
  width: $container-absolute-width;
  margin: 0;
  padding: 0;

  @media (max-width: 575px) {
    width: auto;
  }

  &.selected {
    background-color: colour(grey);
  }

  .bottom {
    margin-right: 2em;
    position: absolute;
    bottom: 0;

    .contributor {
      display: inline-block;

      svg {
        margin-right: 0.5em;
      }
    }
  }

  .explain-wrapper {
    background-color: colour(grey);
    margin-right: 5px;
    margin-left: 5px;
    padding: 0.7em;
  }

  .sub-container {
    width: $container-absolute-width - ($container-margin * 2);
    /* height: $container-absolute-height - ($container-margin * 2); */
    margin: $container-margin;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 575px) {
      //30px for bootstrap container padding
      width: calc(100vw / 2 - 30px);
    }
    .thumbnail {
      height: $thumbnail-dimension;
      border-radius: 5px;

      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      margin-left: auto;
      margin-right: auto;
      margin-top: $container-margin;
      margin-bottom: $container-margin;
    }

    .thumbnail-substitute {
      width: 100%;
      height: 100%;

      div {
        width: 100%;
        height: 10em;
        background-color: colour(grey-5);
        font-size: $font-size !important;
        padding: $container-margin;

        .source {
          svg {
            margin-right: 0.5em;
          }
        }
      }
    }

    .online,
    .contributor,
    .language,
    .correction,
    .merge-split,
    .first-australians-indicator,
    .online-availability-badge {
      font-size: $font-size !important;
    }

    .caption {
      width: 100%;
      font-size: $font-size !important;
      padding-top: 0.2em;
      padding-bottom: 0.2em;
    }

    .main-indicators {
      margin-top: 0.5rem;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import utils from '@/mixins/utils.js'
import colours from '@/mixins/colours.js'
import SearchResult from '@/components/search/SearchResult.vue'
import Stubbable from '@/components/Stubbable.vue'
import MultiLink from '@/components/MultiLink.vue'

export default {
  name: 'ImageResult',
  mixins: [utils, colours],
  extends: SearchResult,
  components: {
    Stubbable,
    MultiLink,
  },
  computed: {
    stubbed() {
      return _.isEmpty(this.result)
    },
    defaultFormatIcon() {
      return { prefix: 'far', iconName: 'image' }
    },
    link() {
      return this.deliverySystemLink || this.detailsLinkDescriptor
    },
  },
}
</script>
