<template>
  <FilterableList
    class="annotations"
    :filterConfigs="filterConfigs"
    :filtered="filtered"
    :items="items"
    :loading="loading"
    itemName="note"
    itemNamePlural="notes"
    :paginationInfo="paginationInfo"
    @get-items="getItems"
    @page-changed="pageChanged"
    @reset-filters="resetFilters">
    <template v-slot:header>
      <span class="count">{{ profileInfo.annotationCount | formatNumber }}</span>
      <span class="header-text">note{{ profileInfo.annotationCount > 1 ? 's' : '' }} added</span>
    </template>

    <template v-slot:no-items>
      There are no notes to show here. Find out
      <a :href="creatingNotesHelpUrl">how to add a Note in Trove</a>.
    </template>

    <template v-slot:item="data">
      <div class="metadata">
        <span class="date">{{ getFormattedDate(data.item) }}</span>
        <span class="time">{{ getFormattedTime(data.item) }}</span>
        <span class="visibility"
          ><font-awesome-icon
            :icon="{
              prefix: 'fas',
              iconName: data.item.priv ? 'lock' : 'lock-open',
            }"
            class="icon"
        /></span>
      </div>

      <div class="summary">
        <h2 class="annotated-item-title">
          <a v-if="data.item.targetType === 'a'" :href="getLinkForNote(data.item)">{{
            data.item.itemTitle || 'Title'
          }}</a>
          <router-link v-else :to="getLinkForNote(data.item)">{{
            data.item.itemTitle || 'Title'
          }}</router-link>
        </h2>
        <span class="content" v-html="truncate(data.item.content)"></span>
      </div>
    </template>

    <template v-slot:stub>
      <div class="d-flex stub" style="padding-bottom: 2.5rem">
        <div class="left" style="width: 5rem">
          <div class="line" style="width: 90%"></div>
          <div class="line" style="width: 50%"></div>
        </div>
        <div class="body" style="width: 100%">
          <div class="line" style="width: 50%"></div>
          <div class="line" style="width: 60%"></div>
          <div class="line" style="width: 20%"></div>
        </div>
      </div>
    </template>
  </FilterableList>
</template>

<style scoped lang="scss">
.annotations {
  padding: 1rem;
}

.count {
  font-size: 2rem;
  font-weight: 500;
  color: colour(green);
  padding-right: 0.5rem;
}

.header-text {
  font-size: 2rem;
  padding-right: 0.5rem;
}

.metadata {
  width: 6rem;
  margin-right: 1rem;
  text-align: left;

  .date {
    text-transform: uppercase;
    display: inline-block;
  }

  .date,
  .time,
  .visibility {
    text-transform: uppercase;
    display: block;
  }
}

.summary {
  flex: 1;
  display: inline-block;
  padding-right: 0;
  padding-left: 0;

  .annotated-item-title {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 1.25rem;
    font-weight: normal;
  }
}
</style>

<script>
import _ from 'lodash'
import moment from 'moment'

import service from '@/service/service'
import profile from '@/mixins/profile'
import auth from '@/mixins/auth'
import FilterableListPage from '@/components/profile/lists/FilterableListPage.vue'
import FilterableList from '@/components/profile/lists/FilterableList.vue'

const FILTER_DEFAULT = {
  annotatedMaterialType: null,
  timePeriod: null,
  visibility: 'BOTH',
  content: undefined,
  number: 1,
}

export default {
  name: 'UserAnnotations',
  extends: FilterableListPage,
  mixins: [profile, auth],
  components: {
    FilterableList,
  },
  props: {
    troveNewspapersUrlPrepend: {
      type: String,
      required: false,
      default: '/newspaper/article/',
    },
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    if (!_.isEmpty(this.usernameParam)) {
      return { title: this.cleanUsername() }
    } else {
      return { title: 'My profile' }
    }
  },
  data() {
    return {
      creatingNotesHelpUrl: null,
    }
  },
  mounted() {
    let self = this
    self.updateBreadcrumbs()
    service.config.environment().then((config) => {
      self.creatingNotesHelpUrl = config.creatingNotesHelpUrl
    })
  },
  computed: {
    filterConfigs() {
      return [
        {
          name: 'containing',
          display: 'Containing',
          type: 'text',
          value: () => this.filter.content,
          input: (v) => (this.filter.content = v),
        },
        {
          name: 'created',
          display: 'Created',
          type: 'select',
          value: () => this.filter.timePeriod,
          input: (v) => (this.filter.timePeriod = v),
          options: this.timeIntervalOptions,
        },
        {
          name: 'addedTo',
          display: 'Added to',
          type: 'select',
          value: () => this.filter.annotatedMaterialType,
          input: (v) => (this.filter.annotatedMaterialType = v),
          options: this.annotatedMaterialTypeOptions,
        },
        {
          name: 'visibility',
          display: 'Visibility',
          type: 'select',
          predicate: () => this.isMyProfile,
          value: () => this.filter.visibility,
          input: (v) => (this.filter.visibility = v),
          options: this.visibilityOptions,
        },
      ]
    },
    filterDefault() {
      return FILTER_DEFAULT
    },
  },
  methods: {
    getFormattedDate(annotation) {
      let date = annotation.lastModified
      if (!date) {
        date = annotation.lastModifiedStr
        let match = date.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}).*/)
        if (match.length > 1) {
          date = match[3] + ' ' + match[2] + ' ' + match[1]
        }
      } else {
        date = _.toUpper(moment(date).format('DD MMM YYYY'))
      }

      return date
    },
    getFormattedTime(annotation) {
      let date = annotation.lastModified
      if (!date) {
        date = annotation.lastModifiedStr
        let match = date.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}).*/)
        if (match.length > 1) {
          date = match[4] + ':' + match[5]
        }
      } else {
        date = _.toUpper(moment(date).format('HH:mm'))
      }

      return date
    },
    getLinkForNote(annotation) {
      let target = annotation.targetWithoutPrefix

      switch (annotation.targetType) {
        case 'a':
          return this.troveNewspapersUrlPrepend + target
        case 'p':
          return {
            name: 'People',
            params: {
              id: target,
            },
          }
        case 'l':
          return {
            name: 'List',
            params: {
              id: target,
            },
          }
        case 'r':
          return {
            name: 'VersionRedirect',
            params: {
              versionId: target,
            },
          }
        default:
          return {
            name: 'Work',
            params: {
              id: target,
            },
          }
      }
    },
    truncate(text) {
      return text && !_.isEmpty(text) && text.length > 550 ? text.substr(0, 550) + '...' : text
    },
    updateBreadcrumbs() {
      let crumbs = []
      if (!_.isEmpty(this.usernameParam)) {
        crumbs.push({
          link: {
            name: 'PublicProfileNotes',
            params: { username: this.usernameParam },
          },
          value: 'User profile',
        })
      } else {
        crumbs.push({ link: { name: 'ProfileNotes' }, value: 'My profile' })
      }

      this.$emit('update-breadcrumbs', crumbs, true)
    },
    getFromService(user, filter) {
      return service.annotations.getAnnotationsForUser(
        user,
        _.assign({}, filter, {
          includePrivate: filter.visibility !== 'PUBLIC',
          includePublic: filter.visibility !== 'PRIVATE',
        })
      )
    },
  },
  watch: {
    profileInfo: {
      deep: true,
      handler() {
        this.getItems()
      },
    },
  },
}
</script>
