<script>
import _ from 'lodash'
import moment from 'moment'
import service from '@/service/service'
import AdvancedSearch from '@/components/search/advanced/AdvancedSearch.vue'
import OptionalSelect from '@/components/search/advanced/OptionalSelect.vue'
import Multitagger from '@/components/search/advanced/Multitagger.vue'

export default {
  name: 'WebAdvancedSearch',
  extends: AdvancedSearch,
  data() {
    return {
      minDate: null,
      maxDate: null,
    }
  },
  computed: {
    fieldConfig() {
      return [
        this.generateTextField('All of these words', 'keyword', true),
        this.generateTextField('The phrase', 'keyword.phrase', true),
        this.generateTextField('Without these words', 'keyword.not', true),
        this.generateField('Include related terms', {
          name: 'l-relatedTerms',
          pillHints: {
            displayOverride: 'Include related terms',
          },
          type: 'checkbox',
          display: 'Include',
          help: 'For example, a search for run, will also include results for runs and running.',
          checked: true,
          unchecked: false,
        }),
      ]
    },
    limitFieldConfig() {
      var self = this

      return [
        this.generateDateRangeField('Snapshot date', 'date', self.minDate, self.maxDate),
        {
          display: 'Limit by file type',
          labelWidth: 2,
          subFields: [
            {
              name: 'l-advContentType',
              type: 'multiselect',
              options: [
                {
                  text: 'PDF',
                  value: 'pdf',
                },
                {
                  text: 'Presentation',
                  value: 'presentation',
                },
                {
                  text: 'Spreadsheet',
                  value: 'spreadsheet',
                },
                {
                  text: 'Word processing',
                  value: 'document',
                },
              ],
              valueMapper: this.multiselectMapper,
              valueReverseMapper: this.multiselectReverseMapper,
              width: '100%',
            },
          ],
        },
        {
          display: 'Australian Government Web Archive',
          labelWidth: 2,
          subFields: [
            {
              type: 'custom',
              name: 'auGov',
              pillHints: {
                hide: () => !_.isEmpty(self.fieldValueFromQuery(self.currentQuery, 'auGovState')),
                valueMap: (value) => {
                  let map = {
                    true: 'All states',
                  }
                  return map[value] || value
                },
              },
              facet: 'auGov',
              component: OptionalSelect,
              props: {
                originalChecked: (function () {
                  return self.fieldValueFromQuery(self.currentQuery, 'auGov') === 'true'
                })(),
                originalValue: (function () {
                  return self.fieldValueFromQuery(self.currentQuery, 'auGovState')
                })(),
                checkboxText: 'Limit to the gov.au web domain',
              },
              events: {
                checkedUpdated: function (checked) {
                  self.fieldUpdated('auGov', checked ? 'true' : null)
                },
                valueUpdated: function (value) {
                  self.fieldUpdated('auGovState', value)
                },
                'clear-handler': function (handler) {
                  self.addClearHandler(handler)
                },
              },
            },
          ],
        },
        {
          display: 'Limit by domain',
          labelWidth: 2,
          subFields: [
            {
              name: 'l-advSite',
              type: 'custom',
              component: Multitagger,
              props: {
                id: 'domainsMultiselect',
                placeholder: 'Enter a domain',
                tagPlaceholder: 'Press enter to add this domain',
                noOptions: 'Type to add a domain',
                originalValues: (function () {
                  var originalValues = self.fieldValueFromQuery(self.currentQuery, 'l-advSite')

                  if (originalValues && !_.isArray(originalValues)) {
                    return [originalValues]
                  }

                  return originalValues
                })(),
              },
              events: {
                update: function (domains) {
                  self.fieldUpdated('l-advSite', domains)
                },
                'clear-handler': function (handler) {
                  self.addClearHandler(handler)
                },
              },
              width: '100%',
            },
          ],
        },
      ]
    },
  },
  watch: {
    selectedCollectionId: {
      immediate: true,
      handler() {
        var self = this

        service.search.getSearchMetadata(self.selectedCollectionId).then((metadata) => {
          var minDate = moment(metadata.minDate, 'YYYY-MM-DD')
          self.minDate = minDate.isValid() ? minDate.toDate() : null

          var maxDate = moment(metadata.maxDate, 'YYYY-MM-DD')
          self.maxDate = maxDate.isValid() ? maxDate.toDate() : null
        })
      },
    },
  },
}
</script>
