<template>
  <div>
    <div class="header-line">
      <b-container>
        <b-row class="header-spacing">
          <h1 v-if="renderH1" class="trove-logo">
            <img
              src="https://assets.nla.gov.au/logos/trove/trove-colour.svg"
              width="154"
              alt="Home - Trove logo" />
          </h1>
          <div v-else class="trove-logo" align="center">
            <router-link to="/">
              <img
                src="https://assets.nla.gov.au/logos/trove/trove-colour.svg"
                width="154"
                alt="Home - Trove logo" />
            </router-link>
          </div>
          <b-col class="nav-wrapper">
            <b-navbar toggleable="md">
              <b-navbar-toggle target="collapse-nav" class="toggle"
                >MENU <i class="far fa-bars"></i
              ></b-navbar-toggle>
              <b-collapse is-nav id="collapse-nav">
                <Nav
                  :items="headerNav"
                  textNoWrap
                  eventName="nav-anc-changed"
                  @nav-anc-changed="navChanged"
                  variant="nav-small">
                  <li v-if="authenticated" class="dropdown nav-nested">
                    <b-dropdown
                      variant="link"
                      toggle-class=""
                      toggle-tag="a"
                      right
                      menu-class="logged-in-menu">
                      <template v-slot:button-content>
                        <span
                          v-if="hasNotifications"
                          class="notification-dot"
                          :title="notificationTooltip"></span
                        >{{ simpleUsername }}
                      </template>
                      <b-dropdown-item :to="{ name: 'Profile' }">My profile</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item :to="{ name: 'ProfileCorrections' }"
                        >Text corrections</b-dropdown-item
                      >
                      <b-dropdown-item :to="{ name: 'ProfileLists' }">Lists</b-dropdown-item>
                      <b-dropdown-item :to="{ name: 'ProfileTags' }">Tags</b-dropdown-item>
                      <b-dropdown-item :to="{ name: 'ProfileNotes' }">Notes</b-dropdown-item>
                      <b-dropdown-item :to="{ name: 'ProfileDownloads' }" v-if="maximumExportSize > 0">Downloads</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="logout">Logout</b-dropdown-item>
                    </b-dropdown>
                  </li>
                </Nav>
              </b-collapse>
            </b-navbar>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="header-bottom">
      <b-container>
        <b-row class="text-center explore-links">
          <b-col></b-col>
          <b-col cols="auto" class="ie11autofix">
            <Nav
              :items="landingPages"
              :selectedId="selectedLandingId"
              textNoWrap
              eventName="nav-ecp-changed"
              variant="nav-large"
              @nav-ecp-changed="handleLanding">
            </Nav>
          </b-col>
          <b-col></b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
/*  Un-scoped style block necessary to override the Vue Bootstrap dropdown menu styles */
.nav-nested {
  top: 2px;

  .btn-group {
    vertical-align: inherit !important;
    display: inline !important;
  }

  button,
  a:not(.dropdown-item) {
    border-bottom: 2px solid transparent;
    border-top: none;
    -webkit-transition: border-bottom 0.55s;
    transition: border-bottom 0.55s;
    display: inline-block;
    height: 100%;
    position: relative;
    text-transform: uppercase !important;
    border-radius: 0;
    font-weight: 600;
    font-size: 0.875rem;
    color: colour(grey-4);
    margin-left: 8px;
    margin-right: 8px;
    vertical-align: inherit;
    padding-left: 0;
    padding-right: 0;
    line-height: 1.25;
    padding-bottom: 17.5px;
    margin-top: 3px;
    padding-top: 0;

    @media (max-width: 767px) {
      font-size: 1.125rem;
    }

    &:hover,
    &:focus,
    &:focus-within {
      color: colour(magenta) !important;
      border-bottom: 2px solid colour(magenta) !important;
      text-decoration: none;
      transition: 0.15s;
    }
  }

  .logged-in-menu {
    background: white;
    font-size: 0.875rem;
    border-radius: 0;
    border: 1px solid colour(purple);

    @media (max-width: 575px) {
      left: 0px;
      top: 25px;
    }

    li {
      .dropdown-divider {
        border-top: 1px solid #e5e5e5;
      }
      a {
        font-size: 0.875rem;
        padding: 8px 8px !important;
        color: colour(purple);
        font-weight: 400;

        &:hover {
          background-color: colour(blue);
          color: white;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.ie11autofix {
  -ms-flex: 1 0 auto;
}

.header-spacing {
  margin-top: 27px;
}

.header-line {
  min-height: 42px;
}

.explore-links {
  font-weight: 700;
}

.header-bottom {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ddd;
}

.trove-logo {
  position: absolute;
  left: calc(50% - 109px);
  top: 26px;
  background: #fff;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 10;

  @media (max-width: 991px) {
    left: 0px;
    padding-left: 24px;
  }
}
h1.trove-logo {
  font-size: 2.5rem;
  margin-top: -1px;
}

.toggle,
.navbar-light .navbar-toggler {
  margin-right: 0;
  font-weight: 600;
  background-color: transparent;
  color: colour(purple);
  border: none;
  font-size: 1.3rem !important;
  padding: 0;

  @media (min-width: 768px) {
    display: none;
  }
}
</style>

<script>
import Nav from './common/Nav.vue'
import auth from '@/mixins/auth'
import _ from 'lodash'

export default {
  name: 'TroveHeader',
  components: {
    Nav,
  },
  mixins: [auth],
  props: {
    landingPages: {
      type: Array,
      required: true,
    },
    selectedLandingId: {
      type: String,
      required: false,
    },
    navigation: {
      type: Array,
      required: true,
    },
    renderH1: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    headerNav() {
      let self = this
      let nav = self.navigation

      _.find(nav, function (l) {
        if (self.isLoginNav(l) || self.isRegisterNav(l)) {
          l.url = '/'
        }
      })

      if (self.authenticated) {
        nav = _.filter(nav, (n) => !self.isLoginNav(n) && !self.isRegisterNav(n))
      }

      return nav
    },
    hasNotifications() {
      return this.myUserProfile && this.myUserProfile.notificationAlerts > 0
    },
    notificationTooltip() {
      let count = this.myUserProfile.notificationAlerts
      return 'You have ' + count + ' unread notification' + (count > 1 ? 's' : '')
    },
  },
  methods: {
    handleLanding(landingPage) {
      this.$emit('landing-selected', landingPage)
    },
    navChanged(navItem) {
      if (this.isLoginNav(navItem)) {
        this.login()
      } else if (this.isRegisterNav(navItem)) {
        this.register()
      }
    },
    isLoginNav(navItem) {
      return navItem && (_.toLower(navItem.name) === 'login' || _.toLower(navItem.id) === 'login')
    },
    isRegisterNav(navItem) {
      return (
        navItem && (_.toLower(navItem.name) === 'sign up' || _.toLower(navItem.id) === 'sign up')
      )
    },
  },
}
</script>
