<template>
  <b-container class="root-container">
    <template>
      <b-row>
        <b-col sm="12" md="9">
          <b-card no-body v-if="hasLists">
            <b-tabs content-class="mt-3" pills>
              <b-tab active v-if="hasPersonalLists">
                <template slot="title"
                  ><span class="notification-dot" v-if="notifications.personal"></span>Personal
                  lists</template
                >
                <ProfileListTab
                  :list-user="profileInfo.user.fullUserId"
                  key="personal"
                  list-type="personal"
                  :profile-info="profileInfo"
                  :show-manage-link="true"
                  @has-notification="updatePersonalListNotification"
                  @no-lists="hasPersonalLists = false"></ProfileListTab>
              </b-tab>

              <b-tab v-if="hasCollaborativeLists">
                <template slot="title"
                  ><span class="notification-dot" v-if="notifications.collaborative"></span
                  >Collaborative lists</template
                >
                <ProfileListTab
                  :list-user="profileInfo.user.fullUserId"
                  key="collaborative"
                  list-type="collaborative"
                  :profile-info="profileInfo"
                  :show-manage-link="true"
                  @has-notification="updateCollaborativeListNotification"
                  @no-lists="hasCollaborativeLists = false"></ProfileListTab>
              </b-tab>

              <b-tab v-if="hasFollowedLists">
                <template slot="title"
                  ><span class="notification-dot" v-if="notifications.followed"></span
                  >{{ isMyProfile ? "Lists I'm following" : 'Followed lists' }}</template
                >
                <ProfileListTab
                  :list-user="profileInfo.user.fullUserId"
                  key="followed"
                  list-type="followed"
                  :profile-info="profileInfo"
                  :show-unfollow-link="true"
                  @has-notification="updateFollowedListNotification"
                  @no-lists="hasFollowedLists = false"></ProfileListTab>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-if="!hasLists">
      There are no Lists to show here. Find out
      <a href="/help/become-voluntrove/lists">how to create a List in Trove</a>.
    </template>
  </b-container>
</template>

<style scoped lang="scss">
.root-container {
  padding-top: 1em;
  padding-bottom: 1em;

  ::v-deep .card {
    margin-top: 1rem;
    width: 100%;
    border: none;
    background: transparent;
    display: block;

    .nav-item {
      background: transparent;
      border: none;

      .active {
        color: colour(white);
        background-color: colour(purple);
      }
    }

    .nav-link {
      background-color: colour(white);
      color: colour(purple);
      border: 2px solid colour(purple);
      padding: 5px 10px;
      margin-right: 1rem;
      border-radius: 25px;
      font-size: 1rem;
      font-weight: 600;
      transition: 0.5s ease-in-out;

      .active {
        color: colour(white);
        background-color: colour(purple);
      }

      &:hover,
      &:focus {
        background: colour(purple);
        color: colour(white);
      }
    }
  }

  .custom-checkbox {
    display: inline-block;
    padding-right: 2rem;
  }

  .followed-lists {
    margin-top: 4rem;

    .unfollow-link {
      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .coloured-bottom-border {
    max-width: 1000px;
  }
}
</style>

<script>
import _ from 'lodash'
import auth from '@/mixins/auth'
import ProfileListTab from '@/components/profile/lists/ProfileListTab'
import profile from '@/mixins/profile'

export default {
  name: 'UserProfileLists',
  mixins: [auth, profile],
  components: {
    ProfileListTab,
  },
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: {},
      hasPersonalLists: true,
      hasCollaborativeLists: true,
      hasFollowedLists: true,
      notifications: {
        personal: false,
        collaborative: false,
        followed: false,
      },
    }
  },
  metaInfo() {
    if (!_.isEmpty(this.usernameParam)) {
      return { title: this.cleanUsername() }
    } else {
      return { title: 'My profile' }
    }
  },
  mounted() {
    this.updateBreadcrumbs()
  },
  computed: {
    hasLists() {
      return this.hasPersonalLists || this.hasCollaborativeLists || this.hasFollowedLists
    },
    listUser() {
      if (!_.isEmpty(this.usernameParam)) {
        return this.usernameParam
      } else {
        return this.fullUsername
      }
    },
    stubbed() {
      return _.isEmpty(this.lists) && _.isEmpty(this.error)
    },
  },
  methods: {
    updateBreadcrumbs() {
      let crumbs = []

      if (!_.isEmpty(this.usernameParam)) {
        crumbs.push({
          link: '/userProfile/' + this.usernameParam + '/lists',
          value: 'My Profile',
        })
      } else {
        crumbs.push({ link: '/userProfile/lists', value: 'My Profile' })
      }

      this.$emit('update-breadcrumbs', crumbs, true)
    },
    viewListRoute(list) {
      return {
        name: 'List',
        params: { id: list.id },
      }
    },
    manageListRoute(list) {
      return {
        name: 'ManageList',
        params: { id: list.id },
      }
    },
    updateCollaborativeListNotification(hasNotification) {
      this.notifications.collaborative = this.isMyProfile && hasNotification
    },
    updatePersonalListNotification(hasNotification) {
      this.notifications.personal = this.isMyProfile && hasNotification
    },
    updateFollowedListNotification(hasNotification) {
      this.notifications.followed = this.isMyProfile && hasNotification
    },
  },
}
</script>
