<template>
  <Stubbable :stubbed="loading">
    <template v-slot:content>
      <div class="work-action-borrow">
        <MutexList :items="statesForList" @item-selected="changeState"></MutexList>

        <div v-if="hasOptions">
          <LibraryList
            :libraries="sortedOpenHoldingsForList"
            @library-clicked="track"></LibraryList>

          <h4 v-if="sortedNotOpenHoldings.length > 0"> Not open to the public </h4>
          <LibraryList
            :libraries="sortedNotOpenHoldingsForList"
            @library-clicked="track"></LibraryList>

          <div class="show-all-container">
            <b-button
              variant="outline-primary"
              class="show-all-toggle"
              v-if="states[selectedState].length > limitTo"
              @click="toggleShowAll">
              {{ showAll ? 'Show less' : 'Show all' }}
            </b-button>
          </div>
          <div class="previous d-flex align-content-center mt-3">
            <div class="bevel flex-grow-1"></div>
          </div>
        </div>
      </div>
      <slot name="after"></slot>
    </template>
    <template v-slot:stub>
      <div class="stub">
        <div class="work-action-borrow">
          <div
            v-for="state in allStateNames"
            class="button pill stubbed-state-button"
            :key="state"></div>
          <div>
            <div class="button pill stubbed-borrow-link"></div>
            <div class="button pill stubbed-borrow-link"></div>
            <div class="button pill stubbed-borrow-link"></div>
          </div>
        </div>
      </div>
    </template>
  </Stubbable>
</template>

<style scoped lang="scss">
.work-action-borrow {
  .stubbed-state-button {
    min-width: 5rem;
    margin-right: 10px;
    border-radius: 50rem !important;
    display: inline-block;
  }

  .stubbed-borrow-link {
    max-height: 8rem;
    min-height: 4rem;
    max-width: 20rem;
    min-width: 20rem;
    margin-right: 2em;
    display: inline-block;
  }

  .state-list {
    padding-bottom: 20px;

    .state-button {
      margin-right: 10px;
      margin-bottom: 1em;
      border: 2px solid colour(purple);
      color: colour(purple);
      transition: 0.5s ease-in-out;
      &:hover {
        background: colour(purple);
        color: colour(white);
      }
      &:not(.disabled).active {
        background: colour(purple);
        color: colour(white);
        box-shadow: none;
      }

      .state-count {
        margin-left: 10px;
        opacity: 0.8;
      }

      .state-title {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  .library-list {
    display: flex;
    flex-wrap: wrap;

    .borrow-link {
      flex: 1;
      margin-right: 2em;
      margin-bottom: 1.5em;
      display: inline-block;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 4rem;
      max-width: 22%;
      min-width: 22%;
      text-align: left;
      border-radius: 0;
      position: relative;
      transition: 0.5s ease-in-out;
      color: colour(black);
      border: none;
      border-bottom: 2px solid colour(light-blue);
      @media (max-width: 767.98px) {
        min-width: 43%;
        max-width: 100%;
      }
      @media (max-width: 575.98px) {
        min-width: 100%;
        margin-right: 0;
      }

      .format,
      .language {
        font-size: smaller;
        color: colour(grey-3);
      }

      .link-icon {
        font-size: 0.8rem;
        opacity: 0.7;
        position: relative;
        top: -2px;
      }
      &:hover,
      &:focus {
        color: colour(blue);
        background: colour(grey);
        border-bottom: 2px solid colour(blue);
      }
    }
  }

  .show-all-container {
    width: 100%;
    margin-top: 1em;

    .show-all-toggle {
      min-width: 10rem;
    }

    .btn {
      border: 2px solid colour(green);
      color: colour(white);
      background: colour(green);
      transition: 0.5s ease-in-out;
      font-weight: 600;
      &:hover,
      &:focus {
        background: colour(white);
        color: colour(green);
      }
    }
  }
  .previous .bevel {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    height: 0;
    border-left: 0 solid #f4f4f4;
    border-top: 48px solid #f4f4f4;
    border-right: 0.80001em solid transparent;
  }
}
</style>

<script>
import _ from 'lodash'
import service from '@/service/service'
import Stubbable from '@/components/Stubbable.vue'
import MutexList from '@/components/MutexList.vue'
import LibraryList from '@/components/LibraryList.vue'

export default {
  name: 'WorkActionsBorrow',
  components: {
    Stubbable,
    MutexList,
    LibraryList,
  },
  props: {
    borrowOptionCount: {
      type: Number,
      required: true,
    },
    workId: {
      type: String,
      required: true,
    },
    versionId: {
      type: String,
      required: false,
      default: undefined,
    },
    limitTo: {
      type: Number,
      required: false,
      default: 12,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      states: {
        all: [],
        act: [],
        nsw: [],
        nt: [],
        qld: [],
        sa: [],
        tas: [],
        vic: [],
        wa: [],
        unknown: [],
      },
      longStateNames: {
        act: 'Australia Capital Territory',
        nsw: 'New South Wales',
        vic: 'Victoria',
        qld: 'Queensland',
        tas: 'Tasmania',
        sa: 'South Australia',
        nt: 'Northern Territory',
        wa: 'Western Australia',
        all: 'All',
        unknown: 'Unknown',
      },
      selectedState: 'all',
      showAll: false,
      loading: false,
    }
  },
  mounted() {
    this.loadBorrowOptions()
  },
  computed: {
    statesForList() {
      var self = this

      return _(this.states)
        .map((contents, shortName) => {
          return {
            name: shortName,
            longName: self.longStateNames[shortName],
            count: contents.length,
          }
        })
        .filter((state) => state.shortName !== 'all' && state.count > 0)
        .value()
    },
    allStateNames() {
      return _.keys(this.longStateNames)
    },
    sortedOpenHoldingsForList() {
      return this.mapHoldingsForList(this.sortedOpenHoldings)
    },
    sortedNotOpenHoldingsForList() {
      return this.mapHoldingsForList(this.sortedNotOpenHoldings)
    },
    sortedOpenHoldings() {
      let max = this.showAll ? this.states[this.selectedState].length : this.limitTo
      return _.take(
        _.filter(
          _.sortBy(this.states[this.selectedState], 'library.name'),
          (h) => h.library.openToPublic
        ),
        max
      )
    },
    sortedNotOpenHoldings() {
      let max = this.showAll
        ? this.states[this.selectedState].length
        : this.sortedOpenHoldings.length > this.limitTo
        ? 0
        : this.limitTo - this.sortedOpenHoldings.length
      return _.take(
        _.filter(
          _.sortBy(this.states[this.selectedState], 'library.name'),
          (h) => !h.library.openToPublic
        ),
        max
      )
    },
    hasOptions() {
      return this.borrowOptionCount > 0
    },
  },
  methods: {
    mapHoldingsForList(holdings) {
      return _.map(holdings, (holding) => {
        return _.assign({}, _.pick(holding, ['url', 'key']), {
          name: holding.library.fullName,
          nuc: holding.library.nuc,
          fields: [
            {
              text: holding.formatString,
            },
            {
              text: holding.languageString,
              hide:
                !holding.url || !(holding.languageString && holding.languageString !== 'English'),
            },
          ],
        })
      })
    },
    loadBorrowOptions() {
      let self = this
      if (!self.states.all || self.states.all.length === 0) {
        self.loading = true
        service.work.getBorrowOptions(self.workId, self.versionId).then((holdings) => {
          _.forEach(holdings, (holding, i) => {
            let state = holding.library ? _.toLower(holding.library.stateAbbrev) : 'unknown'
            self.states.all.push(holding)
            holding.key = i
            holding.formatString = holding.formats ? _.join(holding.formats, '; ') : ''
            holding.languageString = holding.languages ? _.join(holding.languages, '; ') : ''
            if (_.has(self.states, state)) {
              self.states[state].push(holding)
            } else {
              self.states.unknown.push(holding)
            }
          })

          self.loading = false
          self.$emit('recalc-height')
        })
      }
    },
    changeState(state) {
      if (state && state.name) {
        this.selectedState = state.name.toLowerCase()
      }

      this.showAll = false
    },
    toggleShowAll() {
      let self = this
      self.showAll = !self.showAll
      self.$emit('recalc-height')
    },
    track(library) {
      service._gtm.conversion(this, {
        category: 'Borrow',
        action: 'Exit',
        label: library.url,
        context: library.nuc || library.name,
      })
    },
  },
}
</script>
