<template>
  <span v-if="list">
    <b-link
      ><div @click="showAddUrlModal">
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'browser' }" /> Add an archived website
      </div></b-link
    >

    <b-modal id="addUrlModal" @ok="ok">
      <template v-slot:modal-title>Add new item</template>

      <p>Add a <b>Trove archived website</b> to this list.</p>

      <ValidationObserver ref="observer">
        <b-form>
          <ValidationProvider
            rules="requiredPlain|urlProtocol|maxPlain:2048"
            v-slot="{ errors, classes }">
            <b-form-group label="Link to item">
              <b-form-input v-model="item.link" placeholder="https://webarchive.nla.gov.au/" :class="classes" />
              <p class="error">{{ errors[0] }}</p>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="requiredPlain|maxPlain:100" v-slot="{ errors, classes }">
            <b-form-group label="Title of item">
              <b-form-input v-model="item.title" :class="classes" />
              <p class="error">{{ errors[0] }}</p>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="maxPlain:50000" v-slot="{ errors, classes }">
            <b-form-group label="Note (optional)">
              <b-form-textarea
                v-model="item.note"
                placeholder="A short note describing why you are adding this item"
                :class="classes"
                :required="false" />
              <p class="error">{{ errors[0] }}</p>
            </b-form-group>
          </ValidationProvider>

          <p>The item will be added to the <b>end</b> of your list.</p>
        </b-form>
      </ValidationObserver>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="link" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="ok" target="_blank">Add</b-button>
      </template>
    </b-modal>
  </span>
</template>

<style scoped lang="scss">
#addUrlModal {
  .invalid {
    border: solid 1px colour(form-field-error);
  }
  .error {
    color: colour(form-field-error);
  }
}
</style>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'AddUrlToList',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {
        link: null,
        title: null,
        note: null,
      },
    }
  },
  methods: {
    ok(evt) {
      let self = this
      evt.preventDefault()
      self.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          self.$nextTick(() => self.$bvModal.hide('addUrlModal'))
          self.$emit('url-item-added', self.item)
        }
      })
    },
    showAddUrlModal() {
      this.$bvModal.show('addUrlModal')
    },
  },
}
</script>
