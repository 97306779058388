<template>
  <div class="wsod">
    <div class="msg my-auto">
      <font-awesome-icon :icon="{ prefix: 'far', iconName: 'frown' }" size="2x" />
      <p>404 Trove Not Found</p>
      <p>[ <a href="/">Retry</a> ]</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wsod {
  display: flex;
  flex-direction: column;
  height: 100%;
  .msg {
    text-align: center;
    p {
      padding: 1em;
    }
  }
  font-weight: 300;
}
</style>

<script>
export default {
  name: 'SystemWsod',
}
</script>
