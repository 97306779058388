// TODO: Unify this somehow with the standard palette as defined in global-vars.scss

var colours = {
  green: '#008944' /* primary interaction */,
  purple: '#2d004b',
  grey: '#f4f4f4',
  yellow: '#ffbd4c',
  violet: '#6338c2',
  magenta: '#c0286f',
  orange: '#ff733e',
  'light-blue': '#61b7f9',
  blue: '#016ed3',
  black: '#000000',
  white: '#ffffff',

  'purple-tint80': '#57336f',
  'purple-tint60': '#816693',
  'violet-tint80': '#8360ce',
  'violet-tint60': '#a288da',
  'blue-tint80': '#348bdc',
  'blue-tint60': '#66a8e4',
  'light-blue-tint80': '#81c5fa',
  'light-blue-tint60': '#a0d4fb',
  'green-tint80': '#33a169',
  'green-tint60': '#66b88f',
  'yellow-tint80': '#ffca70',
  'yellow-tint60': '#ffd794',
  'orange-tint80': '#ff8f65',
  'orange-tint60': '#ffab8b',
  'magenta-tint80': '#cd538c',
  'magenta-tint60': '#d97ea9',

  'grey-1': '#2D2D2D' /* footer dark */,
  'grey-2': '#4A4A4A' /* categories */,
  'grey-3': '#535353' /* text */,
  'grey-4': '#6D6D6D' /* footer light */,
  'grey-5': '#D1D1D1' /* line */,
  'grey-6': '#A3A3A3' /* footer line */,

  'special-aust1': '#7f5e25',
  'special-aust2': '#b2502b',
  'special-aust3': '#4c2212',
}

import _ from 'lodash'

export default {
  computed: {
    colourNames() {
      return _.keys(colours)
    },
    colourValues() {
      return _.values(colours)
    },
    backgroundColourNames() {
      return _.filter(
        this.colourNames,
        (n) => n !== 'black' && n !== 'white' && n.indexOf('grey') === -1
      )
    },
  },
  methods: {
    colour(name) {
      return colours[name]
    },
    randomColour() {
      return colours[this.randomColourName]
    },
    randomColourValue() {
      return this.colourValues[Math.floor(Math.random() * this.colourValues.length)]
    },
    randomColourName() {
      return this.colourNames[Math.floor(Math.random() * this.colourNames.length)]
    },
  },
}
