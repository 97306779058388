<template>
  <section class="day-container">
    <svg class="keyholeContent" aria-hidden="true">
      <defs>
        <clipPath id="key-clip-path26282">
          <!-- eslint-disable max-len -->
          <path
            d="M1400,0 L1400,352 L85.2301872,352 L118.914631,200.633646 C50.0394765,171.781083 1,103.433357 1,24.7098917 C1,16.4474589 1.53015251,8.19353341 2.59525481,0 L1400,0 Z"></path>
          <!-- eslint-enable max-len -->
        </clipPath>
      </defs>
      <image
        clip-path="url(#key-clip-path26282)"
        :xlink:href="item.artworkUrl"
        :alt="item.artworkImageAlt"
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMid slice"></image>
    </svg>

    <b-container class="p-0 content-container">
      <b-row class="content-row">
        <b-col class="col-xl-5 col-lg-5 col-md-5 col-5 col-sm-5 content-col">
          <h2 class="bevel violet">{{ title }}</h2>

          <div class="subContainer">
            <h3 class="trivia-title">{{ item.title }}</h3>
            <div>
              <p v-html="item.summary"></p>
              <a class="link p-0" variant="link" :href="item.externalUrl">
                <span class="sr-only">{{ item.title }} - </span>Read more
                <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'arrow-right' }" />
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<style scoped lang="scss">
.day-container {
  position: relative;
  height: 100%;
  height: 352px;
  padding-top: 4em;
  padding-bottom: 4em;
  padding-left: 15px;
  background-color: colour(grey);

  h2.bevel {
    margin-bottom: 2em;
    font-size: 1.5rem;
    font-family: 'Source Sans Pro', sans-serif;
    &:before {
      top: 0.14em !important;
    }
  }
  @supports (font-variation-settings: normal) {
    h2.bevel {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }

  .subContainer {
    .trivia-title {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.2;
    }

    .link {
      font-size: 18px;
      &:hover,
      &:focus {
        color: colour(blue);
        text-decoration: underline;
      }
      svg {
        margin-left: 0.3em;
      }
    }
  }

  .keyholeContent {
    position: absolute;
    top: 0;
    left: 40%;
    height: 100%;
    width: 100%;
  }
}
</style>

<script>
import colours from '@/mixins/colours'

export default {
  name: 'Trivia',
  mixins: [colours],
  props: {
    title: {
      type: String,
      required: false,
      default: 'On this day',
    },
    item: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {}
  },
}
</script>
