<template>
  <div class="container">
    <div class="d-flex flex-row" :style="shelfStyle">
      <div class="item" v-for="(item, i) in slicedItems" :key="i" :style="itemStyle(item, i)">
        <div class="thumb-container" :style="panelStyle(item, i)">
          <Thumbnail
            :url="item.img"
            :title="'Cover for ' + item.title"
            :key="'perspective' + item.id"
            type="wrapped"
            :blur="item.blur"
            :lazy="false"></Thumbnail>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  overflow: hidden;
  margin-right: auto;
  margin-left: 0;
  padding: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);

  .item {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    border-radius: 3px;
    overflow: hidden;

    .thumb-container {
      position: relative;
      margin-top: auto;
      margin-bottom: auto;
      overflow: hidden;
    }
  }
}
</style>

<script>
import Vue from 'vue'
import colours from '@/mixins/colours'
import _ from 'lodash'
import Thumbnail from '@/components/Thumbnail.vue'

export default {
  name: 'PerspectiveShelf',
  mixins: [colours],
  components: {
    Thumbnail,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 200,
    },
    stepHeight: {
      type: Number,
      required: false,
      default: 10,
    },
    aspectRatio: {
      type: Number,
      required: false,
      default: 1.5,
    },
    overlap: {
      type: Number,
      required: false,
      default: 0.75,
    },
    max: {
      type: Number,
      required: false,
      default: 10,
    },
    fadeMin: {
      type: Number,
      required: false,
      default: 5,
    },
    fadeOffset: {
      type: Number,
      required: false,
      default: 100,
    },
    clearBlursOnClick: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowUnblurAll: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    this.updateItems()
  },
  watch: {
    items: {
      deep: true,
      handler() {
        let self = this
        Vue.nextTick(() => {
          self.updateItems()
        })
      },
    },
  },
  data() {
    return {
      slicedItems: [],
      anyBlurred: false,
    }
  },
  computed: {
    shelfStyle() {
      return {
        width: this.calculateTotalWidth(Math.max(0, this.slicedItems.length - 1)) + 'px',
      }
    },
  },
  methods: {
    updateItems() {
      let self = this
      self.slicedItems.splice(0, self.slicedItems.length)
      _.forEach(_.sortBy(self.items, (i) => _.isUndefined(i.img)).slice(0, this.max), (item) => {
        self.slicedItems.push(item)
        self.anyBlurred = self.anyBlurred || item.blur
      })
    },
    calculateTotalWidth(i) {
      if (i === 0) {
        return this.height / this.aspectRatio
      }

      let height = this.height - i * this.stepHeight
      let width = height / this.aspectRatio

      return width + this.calculateTotalWidth(i - 1)
    },
    calculateOffset(i) {
      if (i === 0) {
        return 0
      }

      let height = this.height - i * this.stepHeight
      let width = height / this.aspectRatio

      return width * this.overlap + this.calculateOffset(i - 1)
    },
    itemStyle(item, i) {
      return {
        'z-index': i * -1 - 100,
        'pointer-events': 'none',
        right: this.calculateOffset(i) + 'px',
      }
    },
    panelStyle(item, i) {
      let height = this.itemHeight(item, i)
      let width = height / this.aspectRatio
      return {
        height: height + 'px',
        'max-height': height + 'px',
        'min-height': height + 'px',
        width: width + 'px',
        'max-width': width + 'px',
        'min-width': width + 'px',
      }
    },
    itemHeight(item, i) {
      return this.height - i * this.stepHeight
    },
  },
}
</script>
