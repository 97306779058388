<template>
  <div>
    <div class="collection-result mt-4">
      <ImageResultContainer
        v-if="results.works"
        :results="results.works"
        :isSummary="false"></ImageResultContainer>
      <h4 v-else>No records were found</h4>
    </div>

    <div class="d-flex justify-content-center">
      <b-button variant="primary" class="inverse" v-if="moreLink" :to="moreLink">Show all</b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import utils from '@/mixins/utils'
import service from '@/service/service'
import ImageResultContainer from '@/components/search/ImageResultContainer.vue'

export default {
  name: 'PeopleSearch',
  components: {
    ImageResultContainer,
  },
  mixins: [utils],
  props: {
    name: {
      index: String,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      results: {
        works: [],
        numRecords: 0,
        totalRecords: 0,
        maxRecords: 0,
        startPos: 0,
        pageSize: 0,
      },
      resultsLoaded: false,
      moreLink: null,
    }
  },
  mounted: function () {
    var self = this

    if (this.resultsLoaded) {
      return
    }

    let pageSize = 10
    self.results.works = new Array(pageSize).fill(null)
    service.search.queryCollection('-1', this.query, {}, 0, pageSize, 0).then((response) => {
      self.results = response

      self.resultsLoaded = true
      self.loading = false
      if (self.results.works.length < 1) {
        self.results.works = null
      }

      if (self.results.hasMoreResults) {
        let q = {}
        q.keyword = this.query
        self.moreLink = {
          name: 'SearchAll',
          params: {},
          query: q,
        }
      }
    })
  },
  watch: {},
  computed: {
    query() {
      return this.index + ':"' + this.name + '"'
    },
  },
  methods: {},
}
</script>
