<template>
  <div class="list-owner">
    <UserThumbnail :user-summary="listOwner" size="small" class="owner-thumbnail"></UserThumbnail>
    <b-button
      variant="link"
      @click="toggleTransferOwnerPanel"
      v-if="canChange"
      class="change-owner-link">
      Change owner
      <font-awesome-icon
        :icon="{
          prefix: 'fas',
          iconName: transferOwnerPanelOpen ? 'caret-up' : 'caret-down',
        }" />
    </b-button>
    <span v-else class="owner-label">List owner</span>

    <div class="transfer-owner-panel" :class="{ open: transferOwnerPanelOpen }">
      <div
        v-if="!administratorOptions || administratorOptions.length === 0"
        class="no-admin-message">
        You can only transfer ownership of this list to an Administrator. There are no
        administrators assigned to this list.
      </div>
      <template v-if="administratorOptions && administratorOptions.length > 0">
        <label for="newOwner" class="label">Transfer ownership to: </label>
        <b-form-select
          id="newOwner"
          v-model="newOwnerUserId"
          :required="true"
          inline
          :options="administratorOptions">
          <option :value="null" slot="first"> Select a list administrator... </option>
        </b-form-select>
        <b-button
          class="change-owner-btn"
          @click="transferOwnership"
          :disabled="saving || !newOwnerUserId"
          size="sm"
          >Transfer ownership</b-button
        >
        <b-button variant="link" @click="toggleTransferOwnerPanel">Cancel</b-button>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-owner {
  .owner-thumbnail {
    width: 200px;
    overflow: hidden;
  }

  .change-owner-link {
    padding-top: 0;
    margin-left: 1rem;
  }

  .owner-label {
    font-weight: normal;
    width: 200px;
    margin-left: 2rem;
    display: inline-block;
  }

  .transfer-owner-panel {
    font-weight: normal;
    margin-top: 1rem;
    max-height: 0;
    overflow: hidden;

    &.open {
      margin-bottom: 1rem;
      max-height: 400px;
      transition: max-height ease-in-out 300ms;

      label {
        max-width: inherit;
        width: auto;
        margin-right: 0.5rem;
      }

      select {
        width: inherit;
        display: inline-block;
      }

      .change-owner-btn {
        margin-left: 1rem;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import UserThumbnail from '@/components/profile/UserThumbnail'
import service from '@/service/service'

export default {
  name: 'ListOwner',
  components: {
    UserThumbnail,
  },
  props: {
    listId: {
      type: [String, Number],
      required: true,
    },
    listOwner: {
      type: Object,
      required: true,
    },
    canChange: {
      type: Boolean,
      required: false,
    },
    listAdministrators: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      transferOwnerPanelOpen: false,
      newOwnerUserId: null,
      saving: false,
    }
  },
  computed: {
    administratorOptions() {
      let self = this
      return _.map(self.listAdministrators, (c) => {
        return { text: c.user.displayName, value: c.user.fullUserId }
      })
    },
  },
  methods: {
    toggleTransferOwnerPanel() {
      this.transferOwnerPanelOpen = !this.transferOwnerPanelOpen
      this.newOwnerUserId = null
    },
    transferOwnership() {
      let self = this
      self.error = undefined

      let newOwner = self.listAdministrators.filter(
        (c) => c.user.fullUserId === self.newOwnerUserId
      )[0].user.displayName

      self.saving = true
      self.$bvModal
        .msgBoxConfirm(
          'You are about to make ' +
            newOwner +
            ' the owner of this list. You will lose all owner privileges and become a list Collaborator. ' +
            'Are you sure you wish to proceed?',
          { cancelVariant: 'link' }
        )
        .then((result) => {
          if (result) {
            service.list
              .transferListOwnership(self.listId, self.newOwnerUserId)
              .then(() => {
                self.saving = false
                self.$router.push({ name: 'ProfileLists' })
              })
              .catch((error) => {
                self.saving = false
                self.handleError(error, 'list', self.listId, 'transfer ownership of your list')
              })
          } else {
            self.saving = false
          }
        })
    },
  },
}
</script>
