import _ from 'lodash'

export default {
  methods: {
    stringWithLengthValidator(value, length) {
      let lengthArray = _.isArray(length) ? length : [length]
      return (
        value && !_.isEmpty(value) && _.isString(value) && _.includes(lengthArray, value.length)
      )
    },
    positionMultipliedValueChecksumValidator(value, length, modulus) {
      let X_VALUE = 10

      if (!this.stringWithLengthValidator(value, length)) {
        return false
      }

      let digits = _.map(value.split(''), (digit) => (digit === 'X' ? X_VALUE : parseInt(digit)))

      if (!_.every(digits, (digit) => _.isNumber(digit))) {
        return false
      }

      if (_.includes(_.slice(digits, 0, length - 1), X_VALUE)) {
        return false
      }

      let sum = _.reduce(
        digits,
        (sum, digit, i) => {
          return sum + digit * (length - i)
        },
        0
      )

      return sum % (modulus || 11) === 0
    },
    tenDigitISBNValidator(value) {
      return this.positionMultipliedValueChecksumValidator(value, 10)
    },
    thirteenDigitISBNValidator(value) {
      return this.EANThirteenValidator(value)
    },
    EANThirteenValidator(value) {
      if (!this.stringWithLengthValidator(value, 13)) {
        return false
      }

      let digits = _.map(value.split(''), (digit) => parseInt(digit))

      if (!_.every(digits, (digit) => _.isNumber(digit))) {
        return false
      }

      let digitsToSum = _.slice(digits, 0, 12)
      let checkDigit = digits[12]

      let sum = _.reduce(
        digitsToSum,
        (sum, digit, i) => {
          let multiplier = i % 2 === 0 ? 1 : 3
          return sum + digit * multiplier
        },
        0
      )

      if (sum % 10 === 0 && checkDigit === 0) {
        return true
      }

      return 10 - (sum % 10) === checkDigit
    },
    ISBNValidator(value) {
      if (!this.stringWithLengthValidator(value, [10, 13])) {
        return false
      }

      return value.length === 10
        ? this.tenDigitISBNValidator(value)
        : this.thirteenDigitISBNValidator(value)
    },
    ISSNValidator(value) {
      return this.positionMultipliedValueChecksumValidator((value || '').replace(/-/g, ''), 8)
    },
    ISMNValidator(value) {
      return (value || '').startsWith('9790') && this.EANThirteenValidator(value)
    },
    fuzzyDateValidator(value) {
      return (
        _.isString(value) &&
        (value.match(/^\d{4}$/) ||
          value.match(/^(\d{4})-(\d+)$/) ||
          value.match(/^(\d{4})-(\d+)-(\d+)$/))
      )
    },
  },
}
