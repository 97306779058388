<template>
  <div class="add-annotation" :class="{ open: addPanelOpen }">
    <b-button @click="toggleAddPanel" class="add-button" v-if="!addPanelOpen"
      >Add {{ label }}</b-button
    >
    <CaptchaModal
      :show-captcha="!captchaPassed"
      v-if="!authenticated && addPanelOpen"
      @captcha-verified="captchaVerified"
      entity="note"
      action="Add"
      @dismiss-modal="dismissCaptchaModal"></CaptchaModal>

    <div v-if="captchaPassed && addPanelOpen">
      <div class="error" v-if="error && error.summary">
        <Alert :alert="error"></Alert>
      </div>

      <EditAnnotation
        :existing="annotation"
        :show-cancel="true"
        :in-progress="saving"
        @save="save"
        @cancel="toggleAddPanel"></EditAnnotation>
    </div>
  </div>
</template>

<style scoped lang="scss">
.add-annotation {
  padding: 1rem;

  .add-button {
    margin-left: -1rem;
  }

  &.open {
    background-color: colour(grey);
  }

  .form {
    width: 100%;
    padding-top: 0.5rem;
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0.5rem;
    }

    .invalid {
      border: solid 1px colour(form-field-error);
    }

    .error {
      color: colour(form-field-error);
    }
  }

  .button {
    text-align: right;
  }

  .close button {
    color: colour(purple);
    padding-right: 0;
    padding-top: 0;
    height: 2.5em;
    min-height: 2.5em;
    min-width: 6em;

    &:hover,
    &:active {
      color: #c0286f;
      text-decoration: underline;
    }

    svg {
      font-size: 22px !important;
      margin-left: 5px;
      position: relative;
      top: 2px;
    }
  }
}
</style>

<script>
import service from '@/service/service'
import _ from 'lodash'
import auth from '@/mixins/auth'
import Alert from '@/components/Alert.vue'
import CaptchaModal from '@/components/common/CaptchaModal.vue'
import EditAnnotation from '@/components/annotations/EditAnnotation.vue'

export default {
  name: 'AddAnnotation',
  mixins: [auth],
  components: {
    Alert,
    EditAnnotation,
    CaptchaModal,
  },
  props: {
    targetId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator(val) {
        return ['work', 'version', 'person', 'organisation', 'list'].indexOf(_.toLower(val)) > -1
      },
    },
    label: {
      type: String,
      required: false,
      default: 'Note',
    },
  },
  data() {
    return {
      annotation: {
        userId: undefined,
        content: undefined,
        priv: false,
        target: undefined,
      },
      error: undefined,
      addPanelOpen: false,
      showCaptcha: false,
      captchaPassed: false,
      saving: false,
    }
  },
  mounted() {
    this.captchaPassed = this.authenticated
  },
  computed: {},
  methods: {
    save(anno) {
      let self = this
      self.saving = true
      _.assign(this.annotation, anno)
      self.annotation.userId = self.username
      self.annotation.target = self.targetId
      service.annotations
        .addAnnotation(self.type, self.annotation)
        .then((response) => {
          self.$emit('annotation-added', response)
          self.saving = false
          self.toggleAddPanel()
        })
        .catch((error) => {
          self.saving = false
          self.handleError(error, 'note', undefined, 'saving your note')
        })
    },
    clear() {
      this.annotation.userId = undefined
      this.annotation.content = undefined
      this.annotation.priv = false
      this.annotation.target = undefined
    },
    toggleAddPanel() {
      const self = this
      service.config.environment().then((response) => {
        if (response.googleCaptchaUrl) {
          // Do this check here to prevent the flash out -- if the recaptcha test has already been passed,
          // the user will see the recaptcha modal for a split second before it disappears.
          service.auth.checkCaptchaStatusForSession(response.googleCaptchaUrl).then((data) => {
            if (data === 'isHuman') {
              self.captchaPassed = true
            } else if (data === 'captchaReady') {
              self.captchaPassed = false
            }
            if (!self.addPanelOpen) {
              self.addPanelOpen = true
            } else {
              self.clear()
              self.addPanelOpen = false
            }
          })
        }
      })
    },
    captchaVerified() {
      let self = this
      self.captchaPassed = true
    },
    dismissCaptchaModal() {
      let self = this
      self.showCaptcha = false
      self.captchaPassed = false
      self.addPanelOpen = false
    },
  },
}
</script>
