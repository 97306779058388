<template>
  <div class="work-action-buy row">
    <b-col sm="12" md="4" lg="3" v-if="hasSuppliersPanel">
      <div class="suppliers-panel">
        <h2 class="heading">Need a copy?</h2>
        <b-row>
          <b-col :cols="hasBookshopsPanel ? 12 : 9">
            <div class="text-container">
              <font-awesome-icon size="3x" icon="info-circle" class="text-icon"></font-awesome-icon>
              <p class="text">
                You may be able to obtain an electronic copy of part or all of this item from the
                following institution(s). The amount you can obtain will depend on copyright and
                other rights.
              </p>
            </div>
          </b-col>
        </b-row>
        <div class="button-wrapper">
          <b-button
            variant="outline-primary"
            :href="copiesDirectUrl"
            v-if="copiesDirectParams"
            class="supplier-button"
            :class="{ short: hasBookshopsPanel }"
            target="_blank"
            ><span class="mr-2">National Library of Australia - Copies Direct</span
            ><font-awesome-icon
              :icon="{ prefix: 'fas', iconName: 'external-link-alt' }"
              :class="'link-icon'" />
          </b-button>

          <b-button
            v-for="supplier in sortedOtherSuppliers"
            variant="outline-primary"
            :href="supplier.orderingUrl"
            class="supplier-button"
            :key="supplier.nuc"
            target="_blank">
            <span class="link-text"
              ><span class="mr-2">{{ supplier.name }}</span
              ><font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'external-link-alt' }"
                :class="'link-icon'"
            /></span>
          </b-button>
        </div>
      </div>
    </b-col>

    <b-col sm="12" md="8" lg="9" v-if="hasBookshopsPanel">
      <div class="bookshops-panel">
        <Alert :alert="error" v-if="error"></Alert>
        <div class="for-sale" v-if="bookshopIds && bookshopIds.length > 0">
          <div class="bookshops-loading" v-if="bookshopsLoading > 0">
            <div class="loading-spinner spinner-lg"></div>
            Searching - please wait...
          </div>
          <div
            class="no-bookshops"
            v-if="
              bookshopIds &&
              bookshopIds.length > 0 &&
              bookshops.length === 0 &&
              bookshopsLoading === 0
            ">
            We were unable to find this edition in any bookshop we are able to search.
          </div>
          <div class="bookshops">
            <b-button
              v-for="bookshop in sortedBookshops"
              variant="outline-primary"
              :href="bookshop.searchUrl"
              class="bookshop-link"
              :class="{
                nla: bookshop.nla,
                australian: bookshop.location === 'Australian',
                international: bookshop.location !== 'Australian',
              }"
              :key="bookshop.id"
              target="_blank">
              <span class="link-text">
                <span class="mr-2">{{ bookshop.name }}</span
                ><font-awesome-icon
                  :icon="{ prefix: 'fas', iconName: 'external-link-alt' }"
                  :class="'link-icon'" />
              </span>
              <font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'globe-asia' }"
                :class="'location-icon'"
                v-if="bookshop.location !== 'Australian'" />
              <b-img
                :src="require('@/assets/Australia.svg')"
                alt="Australian bookshop"
                :class="'location-icon'"
                v-if="bookshop.location === 'Australian' && !bookshop.nla"></b-img>
              <b-img
                :src="require('@/assets/nla_logo_buy.svg')"
                alt="National Library of Australian bookshop"
                :class="'location-icon'"
                v-if="bookshop.nla"></b-img>
            </b-button>
          </div>
          <div class="other-bookshops" v-if="otherBookshopsUrl">
            You also may like to try some of
            <a :href="otherBookshopsUrl" target="_blank">these bookshops</a>, which may or may not
            sell this item.
          </div>
        </div>
      </div>
    </b-col>
    <b-col>
      <div class="previous d-flex align-content-center mt-3">
        <div class="bevel flex-grow-1"></div>
      </div>
    </b-col>
  </div>
</template>

<style scoped lang="scss">
.work-action-buy {
  .suppliers-panel {
    padding: 1em;
    background: colour(yellow);

    .heading {
      font-weight: 800;
      font-size: 1.3rem;
      padding-bottom: 20px;
      color: colour(purple);
    }

    .text-container {
      display: flex;
      margin-bottom: 10px;

      .text-icon {
        display: inline-block;
        flex: 1;
        max-width: 1em;
        color: colour(purple);
        opacity: 0.8;
      }

      .text {
        display: inline-block;
        margin-left: 20px;
        flex: 1;
        color: colour(purple);
        max-width: 700px;
      }
    }

    .button-wrapper {
      text-align: left;
    }

    .supplier-button {
      margin-left: auto;
      position: relative;
      max-height: auto;
      text-align: left;
      transition: 0.5s ease-in-out;
      color: colour(purple);
      border: 2px solid colour(purple);
      font-weight: 600;
      background: colour(yellow);

      &.short {
        min-width: 100%;
        padding-right: 20px;
      }

      &:hover,
      &:focus {
        background-color: colour(purple);
        color: colour(white);
      }

      .link-icon {
        font-size: 0.8rem;
        opacity: 0.7;
        position: relative;
        top: -2px;
      }
    }
  }

  .bookshops-panel {
    .other-bookshops {
      padding-top: 20px;
    }

    .bookshops-loading {
      padding-bottom: 1.5rem;
      color: #008944;
      font-size: 1.5rem;
      font-weight: 600;

      svg {
        margin-right: 0.8em;
      }
    }

    .bookshop-link {
      flex: 1;
      margin-right: 2em;
      margin-bottom: 1.5em;
      display: inline-block;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 8rem;
      min-height: 4rem;
      max-width: 30%;
      min-width: 30%;
      text-align: left;
      border-radius: 0;
      position: relative;
      transition: 0.5s ease-in-out;
      color: colour(black);
      border: none;
      border-bottom: 2px solid colour(light-blue);
      @media (max-width: 767.98px) {
        min-width: 43%;
        max-width: 100%;
      }
      @media (max-width: 575.98px) {
        min-width: 100%;
        margin-right: 0;
      }

      &.high-priority {
        background-color: colour(blue);
      }

      &.australian {
        .location-icon {
          margin-top: 6px;
          max-width: 15%;
          margin-right: 5px;
        }

        &.nla {
          .location-icon {
            margin-top: 8px;
            max-width: 15%;
            margin-right: 0;
          }
        }
      }

      &.international {
        .location-icon {
          margin-right: 6px;
        }
      }
      &:hover,
      &:focus {
        color: colour(blue);
        background: colour(grey);
        border-bottom: 2px solid colour(blue);
      }

      .link-text {
        display: inline-block;
        max-width: 77%;
      }

      .conditions-statement {
        display: block;
        font-size: smaller;
      }

      .location-icon {
        font-size: 30px;
        float: right;
        margin-right: 20px;
        margin-top: 5px;
        max-width: 25%;
        opacity: 0.25;
      }

      .link-icon {
        font-size: 0.8rem;
        opacity: 0.7;
        position: relative;
        top: -2px;
      }
    }
  }
}
.previous {
  width: 100%;
}
.previous .bevel {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  height: 0;
  border-left: 0 solid #f4f4f4;
  border-top: 48px solid #f4f4f4;
  border-right: 0.80001em solid transparent;
}
</style>

<script>
import Vue from 'vue'
import service from '@/service/service'
import _ from 'lodash'
import Alert from '@/components/Alert.vue'

export default {
  name: 'WorkActionsBuy',
  components: {
    Alert,
  },
  props: {
    copiesDirectParams: {
      type: String,
      required: false,
    },
    workId: {
      type: String,
      required: true,
    },
    versionId: {
      type: String,
      required: false,
    },
    bookshopIds: {
      type: Array,
      required: false,
    },
    otherSuppliers: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      copiesDirectBaseUrl: '',
      bookshops: [],
      bookshopsLoading: false,
      otherBookshopsUrl: '',
    }
  },
  mounted() {
    let self = this

    if (self.copiesDirectParams) {
      service.config.environment().then((response) => {
        self.copiesDirectBaseUrl = response.copiesDirectUrl
      })
    }

    service.config.environment().then((response) => {
      self.otherBookshopsUrl = response.bookshopsUrl
    })
    this.updateBookshops()
  },
  watch: {
    bookshopIds() {
      this.updateBookshops()
    },
    versionId() {
      this.updateBookshops()
    },
    workId() {
      this.updateBookshops()
    },
  },
  computed: {
    hasSuppliersPanel() {
      return (
        (!_.isUndefined(this.copiesDirectParams) && this.copiesDirectParams !== '') ||
        (this.otherSuppliers && this.otherSuppliers.length > 0)
      )
    },
    hasBookshopsPanel() {
      return this.bookshopIds && this.bookshopIds.length > 0
    },
    copiesDirectUrl() {
      return this.copiesDirectParams ? this.copiesDirectBaseUrl + this.copiesDirectParams : ''
    },
    sortedBookshops() {
      return _.orderBy(this.bookshops, ['order', 'name'])
    },
    sortedOtherSuppliers() {
      let self = this
      return self.otherSuppliers
        ? _.orderBy(
            _.filter(
              self.otherSuppliers,
              (s) => !_.isUndefined(s.orderingUrl) && s.orderingUrl.length > 0
            ),
            'name'
          )
        : []
    },
  },
  methods: {
    updateBookshops() {
      let self = this
      if (self.bookshopIds) {
        self.bookshops.splice(0, self.bookshops.length)
        Vue.nextTick(() => {
          self.$emit('recalc-height')
          self.bookshopsLoading = 0
          _.forEach(self.bookshopIds, (bookshopId) => {
            self.bookshopsLoading++
            service.work
              .getBookshops(self.workId, self.versionId || self.workId, bookshopId)
              .then((response) => {
                if (response && response.length > 0) {
                  _.forEach(response, (bookshop) => {
                    if (!_.find(self.bookshops, (bs) => bs.id === bookshop.id)) {
                      bookshop.order = bookshop.nla ? 1 : bookshop.location === 'Australian' ? 2 : 3
                      self.bookshops.push(bookshop)
                    }
                  })
                }

                self.bookshopsLoading--
                self.$emit('recalc-height')
              })
              .catch((error) => {
                self.bookshopsLoading = 0
                self.handleError(
                  error,
                  'bookshops',
                  undefined,
                  'search for this item in available bookshops'
                )
              })
          })
        })
      }
    },
  },
}
</script>
