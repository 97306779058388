import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('trove', ['acceptWarningForWork', 'dismissPrimaryWarning']),
  },
  computed: {
    ...mapGetters('trove', ['primaryWarningDisplayed', 'showCulturalWarnings', 'suppressContent']),
  },
}
