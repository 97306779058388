<template>
  <datepicker
    :id="id"
    :ref="ref"
    v-model="internalValue"
    format="yyyy-MM-dd"
    :typeable="true"
    :open-date="openDate"
    placeholder="YYYY-MM-DD"
    @selected="dateSelected"></datepicker>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'SmartRangeDatepicker',
  components: {
    Datepicker,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    ref: {
      type: String,
      required: false,
    },
    openDate: {
      type: Date,
      required: false,
    },
    value: {},
    defaultToEndOfRange: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      internalValue: _.cloneDeep(this.value),
      inputElement: null,
    }
  },
  mounted() {
    var self = this
    self.inputElement = document.getElementById(self.id)

    if (self.inputElement) {
      self.inputElement.addEventListener('keyup', () => {
        self.update()
      })
    }
  },
  methods: {
    dateSelected(date) {
      this.update(date)
    },
    update(date) {
      if (!date && !this.inputElement.value) {
        this.$emit('input', '')
        return
      }

      let parsedDate = moment(date)
      let rawValue =
        date && parsedDate.isValid()
          ? parsedDate.format('YYYY-MM-DD')
          : this.inputElement.value || ''

      if (_.isEmpty(rawValue)) {
        this.$emit('input', 'invalid')
        return
      }

      if (_.isString(rawValue)) {
        let timePeriod = ''
        let val = 'invalid'
        let result = []

        /* eslint-disable no-cond-assign */
        if ((result = rawValue.match(/^(\d+)$/))) {
          // only year
          timePeriod = 'year'
          val = moment([parseInt(result[1]), 1, 1])
        } else if ((result = rawValue.match(/^(\d+)-(\d+)$/))) {
          // year and month
          timePeriod = 'month'
          val = moment([parseInt(result[1]), parseInt(result[2]) - 1, 1])
        } else if ((result = rawValue.match(/^(\d+)-(\d+)-(\d+)$/))) {
          // year, month, and day
          timePeriod = 'day'
          val = moment([parseInt(result[1]), parseInt(result[2]) - 1, parseInt(result[3])])
        }
        /* eslint-enable no-cond-assign */

        if (timePeriod && val) {
          val = this.defaultToEndOfRange ? val.endOf(timePeriod) : val.startOf(timePeriod)
          val = val.isValid() ? val.format('YYYY-MM-DD') : 'invalid'
        }

        this.$emit('input', val)
      }
    },
    clear(date) {
      this.inputElement.value = date
      this.dateSelected(date)
    },
  },
}
</script>
