import _ from 'lodash'
import store from '@/store/store'

export default {
  computed: {
    currentRoute() {
      return store.state.route
    },
    currentMeta() {
      if (!store.state.route) return null
      return store.state.route.meta
    },
    currentQuery() {
      if (!store.state.route) return null
      return store.state.route.query
    },
    isSearchAll() {
      if (!store.state.route) return false
      return store.state.route.name === 'SearchAll'
    },
    hasQuery() {
      if (!store.state.route) return false
      return !_.isEmpty(store.state.route.query)
    },
    urlKey() {
      if (!store.state.route) return null
      return store.state.route.params ? store.state.route.params.urlKey : null
    },
    urlNamespace() {
      if (!store.state.route) return null
      return store.state.route.params ? store.state.route.params.urlNamespace : null
    },
  },
}
