<template>
  <div class="bulk-operations">
    <div class="d-flex" v-if="bulkOperationsActive">
      <template v-for="op in bulkOperations">
        <b-button
          variant="primary"
          v-if="op.predicate()"
          :key="op.id"
          :disabled="op.buttonDisabled()"
          @click="op.click()"
          >{{ op.buttonText }}</b-button
        >
      </template>
      <div class="bevel grey flex-grow-1"></div>
    </div>

    <AchievementNotice
      :user-achievement="userAchievement"
      v-if="userAchievement"></AchievementNotice>

    <BulkMerge
      :selectedResults="selectedResults"
      :allResults="allResults"
      @new-operation="addOperation"
      @bulk-operation-succeeded="bulkOperationSucceeded"
      @bulk-operation-failed="bulkOperationFailed" />
    <BulkAddToList
      :selectedResults="selectedResults"
      :allResults="allResults"
      @new-operation="addOperation"
      @bulk-operation-succeeded="bulkOperationSucceeded"
      @bulk-operation-failed="bulkOperationFailed"
      @user-achievement="setUserAchievement" />
    <BulkTag
      :selectedResults="selectedResults"
      :allResults="allResults"
      @new-operation="addOperation"
      @bulk-operation-succeeded="bulkOperationSucceeded"
      @bulk-operation-failed="bulkOperationFailed"
      @user-achievement="setUserAchievement" />
    <BulkRemoveTag
      :selectedResults="selectedResults"
      :allResults="allResults"
      @new-operation="addOperation"
      @bulk-operation-succeeded="bulkOperationSucceeded"
      @bulk-operation-failed="bulkOperationFailed" />
  </div>
</template>

<style scoped lang="scss">
.bulk-operations {
  margin-bottom: 2rem;
  margin-left: 1rem;

  .select-all {
    margin-bottom: 1rem;
  }

  .bevel {
    height: 48px;
    @include bevel-div();
  }
}
</style>

<script>
import _ from 'lodash'
import BulkMerge from '@/components/bulk/BulkMerge.vue'
import BulkAddToList from '@/components/bulk/BulkAddToList.vue'
import BulkTag from '@/components/bulk/BulkTag.vue'
import BulkRemoveTag from '@/components/bulk/BulkRemoveTag.vue'
import AchievementNotice from '@/components/common/AchievementNotice.vue'

export default {
  name: 'BulkOperations',
  props: {
    selectedResults: {
      type: Array,
      required: true,
    },
    allResults: {
      type: Array,
      required: true,
    },
  },
  components: {
    BulkMerge,
    BulkAddToList,
    BulkTag,
    BulkRemoveTag,
    AchievementNotice,
  },
  data() {
    return {
      bulkOperations: [],
      userAchievement: undefined,
    }
  },
  computed: {
    bulkOperationsActive() {
      return _.filter(this.bulkOperations, (op) => op.predicate()).length > 0
    },
  },
  watch: {
    bulkOperationsActive: {
      immediate: true,
      handler(val) {
        this.$emit('bulk-operations-active', val)
      },
    },
  },
  methods: {
    addOperation(operation) {
      this.bulkOperations.push(operation)
    },
    bulkOperationSucceeded(message) {
      this.$emit('bulk-operation-succeeded', message)
    },
    bulkOperationFailed(message) {
      this.$emit('bulk-operation-failed', message)
    },
    setUserAchievement(ua) {
      this.userAchievement = ua
    },
  },
}
</script>
