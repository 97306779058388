<script>
import PeopleResultCommon from '@/components/search/PeopleResultCommon'
import ImageResult from '@/components/search/ImageResult.vue'

export default {
  name: 'PeopleImageResult',
  extends: ImageResult,
  mixins: [PeopleResultCommon],
}
</script>
