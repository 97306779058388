<template>
  <div class="work-actions">
    <b-row>
      <b-col cols="12">
        <div class="d-flex">
          <b-button
            v-if="canGet"
            variant="primary"
            :pressed="showOptions === 'get' && optionsOpened"
            @click="toggle('get')"
            class="work-actions-get"
            ref="get">
            {{ getLabel }}<font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }" />
          </b-button>
          <b-button
            v-if="canBorrow"
            variant="primary"
            :pressed="showOptions === 'borrow' && optionsOpened"
            @click="toggle('borrow')"
            class="work-actions-borrow"
            ref="borrow">
            {{ borrowLabel }}<span class="borrow-count">&#40;{{ borrowOptionCount || 0 }}&#41;</span
            ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }" />
          </b-button>
          <b-button
            v-if="canBuy"
            variant="primary"
            :pressed="showOptions === 'buy' && optionsOpened"
            @click="toggle('buy')"
            class="work-actions-buy"
            ref="buy">
            Buy<font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }" />
          </b-button>
          <b-button
            v-if="canCite"
            variant="primary"
            :pressed="showOptions === 'cite' && optionsOpened"
            @click="toggle('cite')"
            class="work-actions-cite"
            ref="cite">
            Cite this<font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }" />
          </b-button>
          <div class="bevel"></div>
        </div>
      </b-col>
    </b-row>
    <Collapse
      :collapsed="!optionsOpened"
      :class="'options-panel row'"
      :full-width="true"
      ref="collapsePanel">
      <b-col cols="12" v-if="showOptions === 'get'">
        <WorkActionsGet
          :available-urls="availableUrls"
          :subscribing-libraries="subscribingLibraries"
          :google-books-preview="googleBooksPreview"
          @recalc-height="updateHeight">
          <WorkActionsRelatedMaterial
            slot="after"
            v-if="relatedMaterialUrls && relatedMaterialUrls.length > 0"
            :thumbnail-url="thumbnailUrl"
            :related-material-urls="relatedMaterialUrls"
            @recalc-height="updateHeight">
          </WorkActionsRelatedMaterial>
        </WorkActionsGet>
      </b-col>
      <b-col cols="12" v-if="showOptions === 'borrow'">
        <WorkActionsBorrow
          :borrow-option-count="borrowOptionCount"
          :is-open="showOptions === 'borrow'"
          :work-id="workId"
          :version-id="versionId"
          @recalc-height="updateHeight">
          <WorkActionsRelatedMaterial
            slot="after"
            v-if="relatedMaterialUrls && relatedMaterialUrls.length > 0"
            :thumbnail-url="thumbnailUrl"
            :related-material-urls="relatedMaterialUrls"
            @recalc-height="updateHeight">
          </WorkActionsRelatedMaterial>
        </WorkActionsBorrow>
      </b-col>
      <b-col cols="12" v-if="showOptions === 'buy'">
        <WorkActionsBuy
          :copies-direct-params="copiesDirectParams"
          :work-id="workId"
          :version-id="versionId"
          :bookshop-ids="bookshopIds"
          :other-suppliers="otherSuppliers"
          @recalc-height="updateHeight"></WorkActionsBuy>
      </b-col>
      <b-col cols="12" v-if="showOptions === 'cite'">
        <CiteAction
          :id="workId"
          :work-type="workType"
          :title="workTitle"
          :citation-id="citationVersionId"
          @recalc-height="updateHeight"></CiteAction>
      </b-col>
    </Collapse>
  </div>
</template>

<style scoped lang="scss">
.work-actions {
  .options-panel {
    width: 100%;
    margin-top: 2em;
  }
  .btn-primary {
    flex-grow: 1;
    transition: 0.5s ease-in-out;
    .active {
      background: colour(white) !important;
      color: colour(purple);
    }
    &:not(.disabled).active:focus {
      box-shadow: none;
      background: colour(white);
      color: colour(purple);
    }
    &:not(.disabled).active:hover {
      box-shadow: none;
    }
    &:not(.disabled).active {
      background: colour(white) !important;
      color: colour(purple) !important;
    }
    &:hover {
      background: colour(white);
      color: colour(purple);
    }
    &:focus {
      box-shadow: none;
    }
    .borrow-count {
      font-weight: 500;
      opacity: 0.7;
      margin-left: 0.3em;
    }
  }
  .fa-caret-down {
    margin-left: 0.5rem;
  }
}
.bevel {
  flex-grow: 2;
  width: 100%;
  height: 0;
  border-left: 0 solid #f4f4f4;
  border-top: 48px solid #f4f4f4;
  border-right: 0.80001em solid transparent;
}

@media (max-width: 575px) {
  .work-actions {
    .d-flex {
      flex-wrap: wrap;
    }

    .btn-primary {
      width: 100%;
      margin-top: 0.5em;
      margin-right: 0;
    }

    .bevel {
      display: none;
    }
  }
}
</style>

<script>
import Vue from 'vue'
import _ from 'lodash'
import workPeopleActions from '@/mixins/work-people-actions'
import Collapse from '@/components/common/Collapse.vue'
import WorkActionsGet from '@/components/work/workActions/WorkActionsGet.vue'
import WorkActionsBuy from '@/components/work/workActions/WorkActionsBuy.vue'
import WorkActionsBorrow from '@/components/work/workActions/WorkActionsBorrow.vue'
import WorkActionsRelatedMaterial from '@/components/work/workActions/WorkActionsRelatedMaterial.vue'
import CiteAction from '@/components/citations/CiteAction.vue'

export default {
  name: 'WorkActions',
  components: {
    Collapse,
    WorkActionsGet,
    WorkActionsBuy,
    WorkActionsBorrow,
    WorkActionsRelatedMaterial,
    CiteAction,
  },
  mixins: [workPeopleActions],
  props: {
    allowGet: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowBorrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowBuy: {
      type: Boolean,
      required: false,
      default: false,
    },
    format: {
      type: String,
      required: false,
    },
    thumbnailUrl: {
      type: String,
      required: false,
    },
    borrowOptionCount: {
      type: Number,
      required: true,
    },
    availableUrls: {
      type: Array,
      required: false,
    },
    subscribingLibraries: {
      type: Array,
      required: false,
    },
    relatedMaterialUrls: {
      type: Array,
      required: false,
    },
    googleBooksPreview: {
      type: String,
      required: false,
    },
    copiesDirectParams: {
      type: String,
      required: false,
    },
    otherSuppliers: {
      type: Array,
      required: false,
    },
    workId: {
      type: String,
      required: true,
    },
    workTitle: {
      type: String,
      required: true,
    },
    versionId: {
      type: String,
      required: false,
    },
    bookshopIds: {
      type: Array,
      required: false,
    },
    workType: {
      type: String,
      required: true,
      default: '',
    },
    workCategories: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
    citationVersionId: {
      type: String,
      required: false,
    },
    digitalOnly: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showOptions: undefined,
      optionsOpened: false,
    }
  },
  watch: {
    versionId() {
      let self = this
      self.close()
      Vue.nextTick(() => {
        self.announceAvailableActions()
      })
    },
    allowBorrow() {
      let self = this
      Vue.nextTick(() => {
        self.announceAvailableActions()
      })
    },
    borrowOptionCount() {
      let self = this
      Vue.nextTick(() => {
        self.announceAvailableActions()
      })
    },
  },
  mounted() {
    this.announceAvailableActions()
  },
  computed: {
    isAvailableOnline() {
      return (
        !_.isEmpty(this.availableUrls) ||
        this.googleBooksPreview ||
        !_.isEmpty(this.subscribingLibraries)
      )
    },
    isGooglePreviewOnly() {
      return (
        this.googleBooksPreview &&
        _.isEmpty(this.availableUrls) &&
        _.isEmpty(this.subscribingLibraries)
      )
    },
    canGet() {
      return this.isAvailableOnline && this.allowGet
    },
    canBuy() {
      return (
        this.allowBuy &&
        ((!_.isUndefined(this.copiesDirectParams) && this.copiesDirectParams.length > 0) ||
          (this.bookshopIds && this.bookshopIds.length > 0) ||
          (this.otherSuppliers && this.otherSuppliers.length > 0))
      )
    },
    canCite() {
      return !_.isEmpty(this.citationVersionId)
    },
    canBorrow() {
      return this.allowBorrow && this.borrowOptionCount > 0 && !this.digitalOnly
    },
    getLabel() {
      let label = 'Get'

      if (this.isGooglePreviewOnly) {
        label = 'Preview'
      } else if (this.format) {
        let formats = _.toLower(this.format)
        if (
          (formats.indexOf('book') > -1 && formats !== 'audio book') ||
          formats.indexOf('thesis') > -1 ||
          formats.indexOf('magazine') > -1 ||
          formats.indexOf('article') > -1 ||
          formats.indexOf('periodical') > -1
        ) {
          label = 'Read'
        } else if (formats.indexOf('sound') > -1 || formats.indexOf('audio') > -1) {
          label = 'Listen'
        } else if (
          formats.indexOf('map') > -1 ||
          formats.indexOf('picture') > -1 ||
          formats.indexOf('photograph') > -1 ||
          formats.indexOf('art work') > -1 ||
          formats.indexOf('poster') > -1 ||
          formats.indexOf('artifact') > -1 ||
          formats.indexOf('artefact') > -1 ||
          formats.indexOf('video') > -1
        ) {
          label = 'View'
        }
      }

      return label
    },
    borrowLabel() {
      if (this.workCategories || this.format) {
        let categories = _.map(this.workCategories || [], _.toLower)
        let formats = _.toLower(this.format || '')

        if (
          !_.includes(categories, 'maps') &&
          (_.includes(categories, 'images and artefacts') || _.includes(formats, 'unpublished'))
        ) {
          return 'Locate'
        }
      }

      return 'Borrow'
    },
  },
}
</script>
