<template>
  <div class="sorter d-flex">
    <span>Sort by:</span>
    <b-form-select v-model="selectedType">
      <option v-for="type in types" :key="type.name" :value="type">
        {{ type.name }}
      </option>
    </b-form-select>
  </div>
</template>

<style scoped lang="scss">
.sorter {
  span {
    white-space: pre;
    margin-right: 1rem;
  }
}
</style>

<script>
import _ from 'lodash'

var TYPES = [
  { name: 'Relevance', value: null },
  { name: 'Year (latest first)', value: 'dateDesc' },
  { name: 'Year (earliest first)', value: 'dateAsc' },
]

export default {
  name: 'Sorter',
  props: {
    initialOrder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedType: _.find(TYPES, (type) => type.value === this.initialOrder) || TYPES[0],
      types: TYPES,
    }
  },
  watch: {
    selectedType() {
      this.$emit('order-changed', this.selectedType.value)
    },
  },
}
</script>
