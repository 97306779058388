<template>
  <div class="filterable-list" ref="content">
    <b-row class="filters" v-if="showFilters">
      <b-col sm="12" class="header">
        <slot name="header" :items="items"></slot>
      </b-col>

      <b-col sm="12" md="4" lg="3" v-for="config in enabledFilterConfigs" :key="config.name">
        <label :for="config.name">{{ config.display }}:</label>

        <b-form-input
          v-if="config.type === 'text'"
          :id="config.name"
          :value="config.value()"
          @input="config.input"
          v-on="config.inputEvents"></b-form-input>

        <b-form-select
          v-if="config.type === 'select'"
          :id="config.name"
          :value="config.value()"
          class="mb-3"
          @input="config.input">
          <option v-for="option in config.options" :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </b-form-select>

        <b-badge v-if="config.type === 'pill'" pill variant="primary" :key="config.key()">
          {{ config.value() }}
          <b-link
            ><div @click="config.close">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" /></div
          ></b-link>
        </b-badge>
      </b-col>

      <b-col sm="12" v-if="showSearch">
        <b-button @click.prevent="getItems">Search</b-button>
        <b-button v-if="filtered" variant="link" @click="resetFilters">Reset filters</b-button>
      </b-col>
    </b-row>

    <div class="no-items" v-if="!hasItems && !loading">
      <span v-if="filtered">No {{ itemNamePlural }} match the selected filter(s)</span>
      <span v-else-if="isMyProfile"><slot name="no-items"></slot></span>
      <span v-else>This user does not have any {{ listType }} {{ itemNamePlural }}</span>
    </div>

    <div class="total" v-if="hasItems && paginationInfo.totalElements && showTotal">
      {{ paginationInfo.totalElements }}
      {{ paginationInfo.totalElements > 1 ? itemNamePlural : itemName }}
    </div>

    <template v-if="!loading">
      <div class="item-list">
        <template v-if="!hasListSlot">
          <b-container>
            <b-row v-for="item in items" class="coloured-bottom-border item" :key="item.id">
              <slot name="item" :item="item"></slot>
            </b-row>
          </b-container>
        </template>
        <template v-else>
          <slot name="list" :items="items"></slot>
        </template>

        <div class="paging" v-if="paged">
          <div class="paginator">
            <Paginator
              :current-page="currentPage"
              :total-rows="pageRows"
              :page-size="pageSize"
              :linkGenerator="paginationLinkGenerator"
              class="page-controls grey" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="numStubs">
        <div class="stubs" :class="stubsClass">
          <template v-for="index in numStubs">
            <div :key="index">
              <slot name="stub"></slot>
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<style scoped lang="scss">
.filterable-list {
  .item-list {
    .item {
      max-width: 1000px;
      display: flex;
    }
  }

  .stubs {
    padding-top: 4rem;
  }

  .header {
    padding-bottom: 1rem;
  }

  .paging {
    .paginator {
      display: inline-block;

      .page-controls {
        border-bottom: 2px colour(grey-5) !important;
      }
    }
  }

  .filters {
    .reset {
      padding: 0;
    }

    .filter-button {
      margin-top: -5px;
    }

    .date-field {
      display: inline-block;
      width: auto;
      margin-right: 0.5rem;
    }

    .custom-checkbox {
      display: inline-block;
      padding-right: 2rem;
    }

    .and {
      padding-right: 0.5rem;
    }

    .btn[aria-pressed='true'] {
      background-color: colour(purple) !important;
      color: colour(white) !important;
    }

    .btn[aria-pressed='false'] {
      background-color: colour(white) !important;
      color: colour(purple) !important;
    }

    .badge {
      display: inline-block;
      margin-bottom: 1rem;

      a {
        color: #fff;
        margin-left: 0.2rem;
      }
    }
  }

  .total {
    font-style: italic;
    text-align: right;
    padding-bottom: 1rem;
  }
}
</style>

<script>
import _ from 'lodash'
import scroll from 'vue-scrollto'
import simplepagination from '@/mixins/simplepagination'
import utils from '@/mixins/utils'
import auth from '@/mixins/auth'
import profile from '@/mixins/profile'
import Paginator from '@/components/search/Paginator.vue'

const NUM_STUBS = 10

export default {
  name: 'FilterableList',
  mixins: [auth, profile, utils, simplepagination],
  components: {
    Paginator,
  },
  props: {
    filterConfigs: {
      type: Array,
      required: true,
    },
    filtered: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemName: {
      type: String,
      required: false,
      default: 'item',
    },
    itemNamePlural: {
      type: String,
      required: false,
      default: 'items',
    },
    listType: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: true,
    },
    paginationInfo: {
      type: Object,
      required: true,
    },
    stubsClass: {
      type: String,
      required: false,
    },
    numStubsOverride: {
      type: Number,
      required: false,
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    currentPageRaw() {
      this.$emit('page-changed', this.currentPage - 1)
    },
  },
  computed: {
    hasListSlot() {
      return !_.isEmpty(this.$slots['list'])
    },
    enabledFilterConfigs() {
      return _.filter(this.filterConfigs, (config) => {
        return !_.isFunction(config.predicate) || config.predicate()
      })
    },
    showFilters() {
      return true
      //return this.hasItems || this.filtered
    },
    hasItems() {
      return !_.isEmpty(this.items)
    },
    paged() {
      return this.pageRows > this.pageSize
    },
    pageRows() {
      return this.paginationInfo.totalElements || 0
    },
    pageSize() {
      return this.paginationInfo.size
    },
    numStubs() {
      return this.numStubsOverride || this.pageSize || NUM_STUBS
    },
  },
  methods: {
    paginationChanged() {
      this.scrollToTop()
    },
    scrollToTop(ref) {
      this.$nextTick(() => {
        ref === 'content'
          ? scroll.scrollTo(this.$refs['content'], 500, { easing: 'ease-out' })
          : scroll.scrollTo('body', 500, { easing: 'ease-out' })
      })
    },
    resetFilters() {
      this.$emit('reset-filters')
    },
    getItems() {
      this.$emit('get-items')
    },
  },
}
</script>
