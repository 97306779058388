<script>
import ListResultCommon from '@/components/search/ListResultCommon'
import ImageResult from '@/components/search/ImageResult.vue'

export default {
  name: 'ListImageResult',
  extends: ImageResult,
  mixins: [ListResultCommon],
}
</script>
