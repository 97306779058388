<template>
  <b-container class="root-container" id="manageListContainer">
    <Stubbable :stubbed="stubbed">
      <template slot="content">
        <div class="error" v-if="error && error.summary">
          <Alert :alert="error"></Alert>
        </div>

        <template v-if="!error || !error.summary || error.showContent">
          <b-row ref="content">
            <b-col md="12">
              <ListSummary
                :list="list"
                class="header-section"
                :hide-owner-on-my-lists="false"
                :show-collaborator-count="true"
                :title-and-thumbnail-only="editingMode"
                v-if="!editingMode"
                @thumbnail-loaded="thumbnailLoaded">
              </ListSummary>

              <EditListDetails
                :existing="list"
                :in-progress="saving"
                v-if="editingMode"
                @save="save"
                @cancel="cancel" />

              <div
                class="image-selector"
                v-if="list.numItems > 0 && !editingMode"
                :style="{
                  height: thumbnailHeight + 'px',
                  padding: thumbnailHeight / 2 - 16 + 'px 2px',
                }">
                <a
                  href="javascript:void(0)"
                  @click="showThumbnailSelector = true"
                  class="launcher-link">
                  <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'pencil' }" size="2x" />
                </a>
              </div>

              <ListThumbnailSelector
                :list-id="list.id"
                v-if="!editingMode"
                :show="showThumbnailSelector"
                :total-item-count="list.numItems"
                @close="updateThumbnail" />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="9">
              <div class="body">
                <div class="controls" v-if="!editingMode">
                  <div class="clearfix">
                    <b-button
                      variant="success"
                      v-if="listOwner || isListAdministrator"
                      @click="toggleEdit"
                      class="edit-button">
                      Edit list details
                    </b-button>

                    <b-button
                      variant="link"
                      v-if="listOwner"
                      @click="deleteList"
                      class="delete-button">
                      <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'trash-alt' }" />
                      Delete this list
                    </b-button>
                  </div>
                </div>

                <div class="collaborator-container" v-if="list.collaborative && !editingMode">
                  <h2 class="collaborator-heading">Manage collaborators</h2>

                  <b-card no-body>
                    <b-tabs content-class="mt-3" no-nav-style>
                      <b-tab
                        active
                        v-if="
                          list.pendingCollaborationRequests &&
                          list.pendingCollaborationRequests.length > 0
                        ">
                        <template slot="title">
                          <span class="notification-dot"></span>Awaiting confirmation
                        </template>
                        <PendingCollaborationRequests
                          :list-id="list.id"
                          :show-heading="false"
                          :pending-requests="list.pendingCollaborationRequests || []"
                          @request-actioned="updateList"></PendingCollaborationRequests>
                      </b-tab>
                      <b-tab
                        :active="
                          !list.pendingCollaborationRequests ||
                          list.pendingCollaborationRequests.length === 0
                        "
                        :title-item-class="{
                          hide: !(list.collaborators && list.collaborators.length > 0),
                        }"
                        v-if="
                          (list.collaborators && list.collaborators.length > 0) ||
                          !(
                            list.pendingCollaborationRequests &&
                            list.pendingCollaborationRequests.length > 0
                          )
                        ">
                        <template
                          slot="title"
                          v-if="list.collaborators && list.collaborators.length > 0">
                          Collaborators
                        </template>
                        <ListCollaborators
                          :list-id="list.id"
                          :show-heading="false"
                          :list-owner="list.owner"
                          :collaborators="list.collaborators || []"
                          :can-remove-administrators="listOwner"
                          :can-remove-collaborators="isListAdministrator || listOwner"
                          :can-manage-roles="listOwner"
                          @collaborator-updated="collaboratorUpdated"
                          @collaborator-removed="updateList"></ListCollaborators>
                      </b-tab>
                      <b-tab
                        v-if="
                          list.blockedCollaborationRequests &&
                          list.blockedCollaborationRequests.length > 0
                        ">
                        <template slot="title"> Blocked </template>
                        <BlockedCollaborationRequests
                          :list-id="list.id"
                          :show-heading="false"
                          :blocked-requests="list.blockedCollaborationRequests || []"
                          @request-unblocked="updateList"></BlockedCollaborationRequests>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>

              <ListItems
                v-if="showListItems"
                :list="list"
                :list-size="listSize"
                :list-author="list.author"
                :list-collaborator="isListCollaborator"
                :list-administrator="isListAdministrator"
                :list-owner="listOwner"
                headerless
                :can-modify="!readOnly"
                @list-item-removed="updateList"
                @page-changed="scrollToTop('content')" />
            </b-col>
            <b-col md="3">
              <Tags :work="list" />

              <AnnotationList
                type="list"
                :target-id="list.id"
                heading="List notes"
                :read-only="readOnly"></AnnotationList>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="9">
              <div class="d-flex flex-column content-container"></div>
            </b-col>
          </b-row>
        </template>
      </template>
      <template v-slot:stub>
        <div class="stub">
          <div class="list-stub">
            <div class="button pill title"></div>
            <div class="button pill summary-title"></div>
            <div class="button pill summary"></div>
          </div>
        </div>
      </template>
    </Stubbable>
  </b-container>
</template>

<script>
import _ from 'lodash'
import scroll from 'vue-scrollto'
import service from '@/service/service'
import auth from '@/mixins/auth'
import lists from '@/mixins/lists'
import Stubbable from '@/components/Stubbable.vue'
import Tags from '@/components/work/Tags.vue'
import ListItems from '@/components/lists/ListItems.vue'
import ListSummary from '@/components/lists/ListSummary.vue'
import AnnotationList from '@/components/annotations/AnnotationList.vue'
import Alert from '@/components/Alert.vue'
import ListCollaborators from '@/components/lists/management/ListCollaborators'
import EditListDetails from '@/components/lists/management/EditListDetails'
import PendingCollaborationRequests from '@/components/lists/management/PendingCollaborationRequests'
import BlockedCollaborationRequests from '@/components/lists/management/BlockedCollaborationRequests'
import ListThumbnailSelector from '@/components/lists/management/ListThumbnailSelector'

export default {
  name: 'ManageListView',
  mixins: [auth, lists],
  components: {
    PendingCollaborationRequests,
    BlockedCollaborationRequests,
    ListCollaborators,
    ListThumbnailSelector,
    ListSummary,
    Stubbable,
    Tags,
    Alert,
    EditListDetails,
    ListItems,
    AnnotationList,
  },
  metaInfo() {
    if (!_.isEmpty(this.error) && _.isEmpty(this.list)) {
      return { title: 'List not found' }
    } else {
      return { title: 'Manage ' + this.list.title }
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from
    })
  },
  data() {
    return {
      list: {},
      collections: [],
      saving: false,
      inProgress: false,
      previousRoute: undefined,
      selectedCollection: null,
      editingMode: false,
      followRequestInProgress: false,
      error: {},
      showThumbnailSelector: false,
      thumbnailHeight: 160,
    }
  },
  created() {
    let self = this
    self.updateList()
  },
  computed: {
    stubbed() {
      return _.isEmpty(this.list) && _.isEmpty(this.error)
    },
    listSize() {
      return this.list.numItems
    },
    showListItems() {
      return this.list.numItems > 0
    },
    listOwner() {
      return (
        this.list.author === this.simpleUsername ||
        this.list.author === this.username ||
        this.list.author === this.fullUsername
      )
    },
    isListCollaborator() {
      let self = this
      return (
        self.list.collaborators &&
        _.findIndex(self.list.collaborators, (c) => c.user.fullUserId === self.fullUsername) > -1
      )
    },
    isListAdministrator() {
      let self = this
      return (
        self.list.collaborators &&
        _.findIndex(
          self.list.collaborators,
          (c) => c.user.fullUserId === self.fullUsername && c.role === 'ADMINISTRATOR'
        ) > -1
      )
    },
    readOnly() {
      let myProfile = this.isMyProfile
      return !myProfile && !this.listOwner && !this.isListCollaborator
    },
  },
  methods: {
    scrollToTop(ref) {
      this.$nextTick(() => {
        ref === 'content'
          ? scroll.scrollTo(this.$refs['content'], 500, { easing: 'ease-out' })
          : scroll.scrollTo('body', 500, { easing: 'ease-out' })
      })
    },
    updateList() {
      let self = this
      self.error = {}

      service.list
        .getForListManagement(this.id)
        .then((list) => {
          self.list = list
          let crumbs = []
          if (self.previousRoute) {
            if (self.previousRoute.name === 'List') {
              crumbs.push({ link: self.previousRoute, value: self.list.title })
            } else if (self.previousRoute.name === 'ProfileLists') {
              crumbs.push({ link: self.previousRoute, value: 'My profile' })
            } else {
              crumbs.push({
                link: { name: 'List', params: { id: this.list.id } },
                value: self.list.title,
              })
            }
          }
          crumbs.push({ value: 'Manage ' + list.title })
          self.$emit('update-breadcrumbs', crumbs, true)
        })
        .catch((error) => {
          self.$emit('update-breadcrumbs', [{ value: 'List not found' }], true)
          self.handleError(error, 'list', self.id, 'load the list')
        })
    },
    toggleEdit() {
      this.editingMode = !this.editingMode
    },
    cancel() {
      this.toggleEdit()
    },
    thumbnailLoaded(dimensions) {
      this.thumbnailHeight = dimensions.height
    },
    updateThumbnail(newUrl) {
      if (newUrl) {
        this.list.thumbnailUrl = newUrl
      }
      this.showThumbnailSelector = false
    },
    save(list) {
      let self = this
      self.saving = true

      let privateList = list.priv ? 'y' : 'n'
      let request = {
        listId: list.id,
        name: list.title,
        description: list.description,
        priv: privateList,
        collaborative: list.collaborative,
        facebookGroupId: list.collaborative ? list.facebookGroupId : '',
      }

      service.list
        .updateList(list.id, request)
        .then((result) => {
          _.assign(self.list, result)
          self.saving = false

          self.toggleEdit()
        })
        .catch((error) => {
          self.saving = false
          self.handleError(error, 'list', list.id, 'update your list')
        })
    },
    deleteList() {
      let self = this
      self.error = undefined

      let message
      if (self.list.collaborative && self.list.collaboratorCount) {
        if (self.list.collaboratorCount === 1) {
          message = 'There is one other contributor'
        } else {
          message = 'There are ' + self.list.collaboratorCount + ' contributors'
        }
        message +=
          // eslint-disable-next-line max-len
          ' to this list. Are you sure you want to delete this list? You may want to consider transferring ownership to one of the contributors instead.'
      } else {
        message = "Are you sure you want to delete the list '" + self.list.title + "'?"
      }

      self.$bvModal.msgBoxConfirm(message, { cancelVariant: 'link' }).then((result) => {
        if (result) {
          service.list
            .deleteList(this.list.id)
            .then((deleted) => {
              self.inProgress = false
              if (deleted) {
                self.$emit('update-activity-counts')
                self.$router.push({ name: 'ProfileLists' })
              } else {
                self.error = {
                  summary: 'An unexpected error occurred while removing your list',
                  impact: 'error',
                }
              }
            })
            .catch((error) => {
              self.saving = false
              self.handleError(error, 'list', self.list.id, 'remove your list')
            })
        }
      })
    },
    collaboratorUpdated(updated) {
      _.assign(
        _.filter(this.list.collaborators, (c) => c.user.fullUserId === updated.user.fullUserId)[0],
        updated
      )
    },
  },
}
</script>

<style scoped lang="scss">
@import '../scss/list-view.scss';
.tabs,
.nav-tabs .nav-item,
.tab,
.nav-link {
  border: none !important;
  background: transparent;
  font-weight: 700;
  color: colour(black);
  margin-right: 2rem;

  &:hover,
  &:active,
  &.active {
    color: colour(black);
    border-bottom: 3px solid colour(blue);
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
}
.root-container {
  margin-top: 4rem;
}

.header-section {
  width: 100%;

  .follow-controls {
    svg {
      margin-right: 0.5rem;
    }
  }

  ::v-deep .thumbnail-column {
    margin-right: 1em;
  }
}

.body {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  margin-left: 110px;
}

.controls {
  .edit-button {
    float: left;
  }

  .delete-button {
    float: right;
    color: colour(magenta);

    svg {
      margin-right: 0.5rem;
    }
  }
}

.collaborator-container {
  clear: both;
  margin-top: 2rem;

  .collaborator-heading {
    font-size: 2rem;
  }

  h3 {
    font-weight: 600;
    font-size: 1rem;
    color: colour(grey-2);
    font-family: 'Source Sans Pro', sans-serif;
  }

  @supports (font-variation-settings: normal) {
    .tagged {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }

  ::v-deep .card {
    margin-top: 1rem;
    width: 100%;
    border: none;
    background: transparent;
    display: block;

    .nav-item {
      background: transparent;
      border: none;

      .active {
        color: colour(white);
        background-color: colour(purple);
      }
    }

    .nav-link {
      background-color: colour(white);
      color: colour(purple);
      border: 2px solid colour(purple);
      padding: 5px 10px;
      margin-right: 1rem;
      border-radius: 25px;
      font-size: 1rem;
      font-weight: 600;
      transition: 0.5s ease-in-out;

      .active {
        color: colour(white);
        background-color: colour(purple);
      }

      &:hover,
      &:focus {
        background: colour(purple);
        color: colour(white);
      }
    }
  }
}

.image-selector {
  position: absolute;
  top: 0;
  height: 160px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 45px 2px;
  text-align: center;

  a,
  a:hover,
  a:visited {
    color: white;
  }
}
</style>
