import service from '@/service/service'

export default {
  dismissPrimaryWarning: (context, opts) => {
    context.commit('DISMISS_PRIMARY_WARNING', opts.show)

    service._gtm.interaction(opts.vm, {
      category: 'cultural-warning',
      action: opts.show ? 'show warnings' : 'hide warnings',
    })
  },
  acceptWarningForWork: (context, identifier) => {
    context.commit('ACCEPT_WARNING', identifier)
  },
  updateProfileActivity: (context, activity) => {
    context.commit('UPDATE_PROFILE_ACTIVITY', activity)
  },
  // intended for non-recoverable failure conditions (e.g. core config)
  recordApiCriticalFail: (context) => {
    context.commit('API_CRITICAL_FAIL')
  },
  updateUserProfile: (context, profile) => {
    context.commit('UPDATE_USER_PROFILE', profile)
  },
  addFacetsDisplayConfig: (context, displayConfig) => {
    context.commit('ADD_FACETS_DISPLAY_CONFIG', displayConfig)
  },
  addFieldsDisplayConfig: (context, displayConfig) => {
    context.commit('ADD_FIELDS_DISPLAY_CONFIG', displayConfig)
  },
  recordAsHuman: (context) => {
    context.commit('HUMAN')
  },
}
