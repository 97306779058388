/* eslint-disable no-undef */

export default {
  methods: {
    updateRoute(routeName, params, query, replace) {
      const updatedRoute = {
        name: routeName,
        params: params,
        query: query,
      }

      if (!_.isEqual(_.pick(this.$route, ['name', 'params', 'query']), updatedRoute)) {
        if (replace) {
          this.$router.replace(updatedRoute)
        } else {
          this.$router.push(updatedRoute)
        }
      }
    },
  },
}

/* eslint-enable no-undef */
