<template>
  <div>
    <div v-if="addPanelOpen">
      <div class="error" v-if="error && error.summary">
        <Alert :alert="error"></Alert>
      </div>
      <EditListItemNote
        :existing="item"
        @save="save"
        :in-progress="saving"
        @cancel="toggleAddPanel"></EditListItemNote>
    </div>
    <b-button @click="toggleAddPanel" class="add-button" v-if="!addPanelOpen">
      <font-awesome-icon class="private-icon" :icon="{ prefix: 'fa', iconName: 'plus' }" />
      Add list item note
    </b-button>
  </div>
</template>

<script>
import EditListItemNote from '@/components/lists/EditListItemNote.vue'

export default {
  name: 'AddListItemNote',
  components: {
    EditListItemNote,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addPanelOpen: false,
      saving: false,
    }
  },
  methods: {
    toggleAddPanel() {
      this.addPanelOpen = !this.addPanelOpen
    },
    save(item) {
      this.$emit('item-updated', item)
    },
  },
}
</script>
