import _ from 'lodash'
import Vue from 'vue'
import Router from 'vue-router'

import LandingView from './views/LandingView.vue'
import CollectionView from './views/CollectionView.vue'
import SearchView from './views/SearchView.vue'
import WorkView from './views/WorkView.vue'
import PeopleView from './views/PeopleView.vue'
import ProfileView from './views/ProfileView.vue'
import VersionView from './views/VersionView.vue'
import ListView from './views/ListView.vue'
import ManageListView from './views/ManageListView.vue'
import HallOfFameView from './views/HallOfFameView.vue'

import UserTags from './components/profile/UserTags'
import UserAnnotations from './components/profile/UserAnnotations'
import UserTextCorrections from './components/profile/UserTextCorrections'
import UserProfileAbout from './components/profile/UserProfileAbout'
import UserProfileSettings from './components/profile/UserProfileSettings'
import UserProfileLists from './components/profile/UserProfileLists'
import UserAccountDeleted from './components/profile/UserAccountDeleted'
import UserProfilePartner from './components/profile/UserProfilePartner'
import UserProfileDev from './components/profile/UserProfileDev'
import UserProfileDownloads from './components/profile/UserProfileDownloads'
import PartnerDashboard from './views/PartnerDashboard'
import ModerateContent from './components/profile/ModerateContent'

Vue.use(Router)

function processSpecialParams(route, specialParamsList, specialParamsMapping) {
  let queryParams = _.omit(route.query, specialParamsList || [])
  let specialParams = _.pick(route.query, specialParamsList || [])

  if (specialParamsMapping) {
    _.forEach(specialParamsMapping, (mapper, specialParam) => {
      if (specialParams[specialParam]) {
        specialParams[specialParam] = mapper(specialParams[specialParam])
      }
    })
  }
  return Object.assign({}, route.params, { query: queryParams }, specialParams)
}

function filterOutQueryParams(route, notAllowedParams) {
  const queryParams = {};

  // Include only the parameters that are not in the notAllowedParams array
  Object.keys(route.query).forEach(param => {
    if (!notAllowedParams.includes(param)) {
      queryParams[param] = route.query[param];
    }
  });

  // Check if we need to redirect (if the original query params and filtered params are different)
  if (Object.keys(queryParams).length !== Object.keys(route.query).length) {
    return { path: route.path, query: queryParams };
  }
  return true;
}

function filterQueryParams(route, allowedParams) {
  const queryParams = {};
  // Include only the allowed parameters in the queryParams object
  allowedParams.forEach(param => {
    if (route.query[param] !== undefined) {
      queryParams[param] = route.query[param];
    }
  });

  // Check if we need to redirect (if the original query params and filtered params are different)
  if (Object.keys(queryParams).length !== Object.keys(route.query).length) {
    return { path: route.path, query: queryParams };
  }
  return true;
}


// Breadcrumbs: Set meta.breadCrumbHistory to remember a visit to this page in the breadcrumb history, and set
// meta.breadCrumbName to override the breadcrumb display value (otherwise route.name is used).

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: LandingView,
      props: { landingId: 'home' },
    },
    {
      path: '/landing/:landingId',
      name: 'Landing',
      component: LandingView,
      props: (route) => Object.assign({}, route.params),
    },
    {
      name: 'HallOfFame',
      path: '/landing/community/hallOfFame',
      component: HallOfFameView,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
    },
    {
      path: '/collection/:urlNamespace/:urlKey',
      name: 'Collection',
      component: CollectionView,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
    },
    {
      path: '/collection/:urlNamespace/:urlKey/:view',
      name: 'CollectionSearch',
      component: CollectionView,
      props: (route) =>
        Object.assign({}, route.params, {
          query: route.query,
          advanced: route.advanced,
        }),
      meta: {
        breadCrumbHistorical: true,
        breadCrumbName: 'Search results',
        isCollectionSearch: true,
      },
    },
    {
      path: '/search/:urlNamespace/:urlKey',
      name: 'Search',
      component: SearchView,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
      meta: { breadCrumbHistorical: true, breadCrumbName: 'Search results' },
    },
    {
      path: '/search',
      name: 'SearchAll',
      component: SearchView,
      props: (route) => Object.assign({}, { query: route.query }),
      meta: { breadCrumbHistorical: true, breadCrumbName: 'Search results' },
      beforeEnter: (to, from, next) => {
        const result = filterOutQueryParams(to, ['includeraw']);
        result === true ? next() : next(result);
      }
    },
    {
      path: '/search/advanced/:urlNamespace/:urlKey',
      name: 'AdvancedSearch',
      component: SearchView,
      props: (route) => Object.assign({}, route.params, { query: route.query, advanced: true }),
      meta: { breadCrumbHistorical: true, breadCrumbName: 'Search results' },
      beforeEnter: (to, from, next) => {
        const { urlNamespace, urlKey } = to.params;
        if (urlNamespace === 'category' && urlKey === 'lists') {
          next({ path: `/search/${urlNamespace}/${urlKey}`, query: to.query });
        } else {
          next();
        }
      },
    },
    {
      path: '/work/:id',
      name: 'Work',
      component: WorkView,
      props: (route) =>
        processSpecialParams(route, ['includeraw'], {
          includeraw: (val) => val === 'y',
        }),
      beforeEnter: (to, from, next) => {
        const result = filterQueryParams(to, ['keyword', 'includeraw']);
        result === true ? next() : next(result);
      }
    },
    {
      path: '/work/:id/version/:versionId',
      name: 'Version',
      component: WorkView,
      props: (route) =>
        processSpecialParams(route, ['includeraw'], {
          includeraw: (val) => val === 'y',
        }),
      beforeEnter: (to, from, next) => {
        const result = filterQueryParams(to, ['keyword', 'includeraw']);
        result === true ? next() : next(result);
      }
    },
    {
      path: '/version/:versionId',
      name: 'VersionRedirect',
      component: VersionView,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
    },
    {
      path: '/nbdid/:nbdId',
      name: 'NbdRedirect',
      component: VersionView,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
    },
    {
      path: '/people/:id',
      name: 'People',
      component: PeopleView,
      props: (route) =>
        processSpecialParams(route, ['includeraw'], {
          includeraw: (val) => val === 'y',
        }),
    },
    {
      path: '/list',
      name: 'ListRedirect',
      component: ListView,
      props: (route) => ({ id: route.query.id }),
    },
    {
      path: '/list/manage/:id',
      name: 'ManageList',
      component: ManageListView,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/list/:id',
      name: 'List',
      component: ListView,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
    },
    {
      path: '/dashboard',
      name: 'PartnerDashboard',
      component: PartnerDashboard,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/userProfile',
      name: 'Profile',
      component: ProfileView,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
      children: [
        {
          name: 'ProfileAbout',
          path: 'about',
          component: UserProfileAbout,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'ProfileSettings',
          path: 'settings',
          component: UserProfileSettings,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'ProfilePartner',
          path: 'partner',
          component: UserProfilePartner,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'ProfileCorrections',
          path: 'corrections',
          component: UserTextCorrections,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'ProfileTags',
          path: 'tags',
          component: UserTags,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'ProfileNotes',
          path: 'notes',
          component: UserAnnotations,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'ProfileLists',
          path: 'lists',
          component: UserProfileLists,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'ProfileDev',
          path: 'developers',
          component: UserProfileDev,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'ProfileDownloads',
          path: 'downloads',
          component: UserProfileDownloads,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'ModerateContent',
          path: 'moderate/content',
          component: ModerateContent,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/userProfile/user/:username',
      name: 'PublicProfile',
      component: ProfileView,
      props: (route) => Object.assign({}, route.params, { query: route.query }),
      children: [
        {
          name: 'PublicProfileAbout',
          path: 'about',
          component: UserProfileAbout,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'PublicProfileLists',
          path: 'lists',
          component: UserProfileLists,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'PublicProfileTags',
          path: 'tags',
          component: UserTags,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'PublicProfileNotes',
          path: 'notes',
          component: UserAnnotations,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'PublicProfileCorrections',
          path: 'corrections',
          component: UserTextCorrections,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'PublicProfileSettings',
          path: 'settings',
          component: UserProfileSettings,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
        {
          name: 'PublicProfileDev',
          path: 'developers',
          component: UserProfileDev,
          props: (route) => Object.assign({}, route.params, { query: route.query }),
        },
      ],
    },
    {
      name: 'UserAccountDeleted',
      path: '/user/account-deleted',
      component: UserAccountDeleted,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (router.app.$keycloak.authenticated) {
      next()
    } else {
      const loginUrl = router.app.$keycloak.createLoginUrl()
      window.location.replace(loginUrl)
    }
  } else {
    next()
  }
})

export default router
