<script>
import NewspaperResultCommon from '@/components/search/NewspaperResultCommon'
import ImageResult from '@/components/search/ImageResult.vue'

export default {
  name: 'NewspaperImageResult',
  extends: ImageResult,
  mixins: [NewspaperResultCommon],
}
</script>
