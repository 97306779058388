<template>
  <div class="user-notification">
    <div class="date">{{ formattedDate }}</div>
    <div class="details">
      <font-awesome-icon class="tag-icon" :icon="{ prefix: 'fas', iconName: 'list' }" />
      The list "<router-link :to="listRoute" @click.native="clearNotification">{{
        details.listTitle
      }}</router-link
      >" has been changed:
      <template v-if="newItemCount === 1">
        <p>The item "{{ newItems[0][1] }}" has been added to the list.</p>
      </template>
      <template v-if="newItemCount > 1">
        <p>The following items have been added to the list:</p>
        <ul>
          <li v-for="(item, index) in newItems" :key="item[1] + index">
            {{ item[1] }}
          </li>
        </ul>
      </template>
      <template v-if="removedItemCount === 1">
        <p> The item "{{ removedItems[0][1] }}" has been removed from the list. </p>
      </template>
      <template v-if="removedItemCount > 1">
        <p>The following items have been removed from the list:</p>
        <ul>
          <li v-for="(item, index) in removedItems" :key="item[1] + index">
            {{ item[1] }}
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-notification {
  ul {
    padding-left: 3rem;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}
</style>

<script>
import _ from 'lodash'
import AbstractNotificationDetail from './AbstractNotificationDetail'

export default {
  name: 'CollabListItemsChanged',
  extends: AbstractNotificationDetail,
  computed: {
    listRoute() {
      return {
        name: 'List',
        params: { id: this.details.listId },
      }
    },
    newItems() {
      return _.toPairs(this.details.newItems)
    },
    newItemCount() {
      return this.newItems.length
    },
    removedItems() {
      return _.toPairs(this.details.removedItems)
    },
    removedItemCount() {
      return this.removedItems.length
    },
  },
}
</script>
