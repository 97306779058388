<template>
  <div>
    <b-form @submit.prevent="search">
      <div class="search-box d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="search">
            <label class="sr-only" for="searchInput">Search</label>
            <b-form-input
              id="searchInput"
              :placeholder="isCollection"
              type="text"
              width="100%"
              v-model="searchText"
              @focus.native="searchBoxFocused"
              @input="searchBoxUpdated"></b-form-input>
          </span>
        </div>
        <div class="collections-select" v-if="hasCollections && displayCollections">
          <label class="sr-only" for="searchCollectionInput">Search collection</label>
          <b-form-select id="searchCollectionInput" v-model="selectedCollection">
            <template slot="first">
              <option :value="null" selected>All categories</option>
            </template>
            <option v-for="collection in collections" :key="collection.id" :value="collection">
              {{ collection.name }}
            </option>
          </b-form-select>
        </div>
        <div class="search-icon">
          <b-button type="submit" variant="link" :disabled="!canSearch">
            <span class="sr-only">Search</span>
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'search' }" />
          </b-button>
        </div>
      </div>
      <div class="accent-line"></div>
    </b-form>

    <div
      class="advanced-search d-flex justify-content-end align-items-center"
      v-if="displayAdvancedSearch">
      <div>
        <b-form-select v-model="advancedSearchSelected">
          <option :value="null">Advanced Search</option>
          <option
            v-for="collection in collectionsForAdvancedSearch"
            :key="collection.id"
            :value="collection">
            {{ collection.name }}
          </option>
        </b-form-select>
        <font-awesome-icon class="down" :icon="{ prefix: 'fas', iconName: 'caret-down' }" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
h1 {
  font-family: 'Source Serif Pro', serif;
  font-weight: 700;
  padding-bottom: 1rem;
}

@supports (font-variation-settings: normal) {
  h1 {
    font-family: 'Source Serif Variable', serif;
  }
}

.search-box {
  padding: 0.5em;
  background-color: white;
  height: 58px;
  overflow: hidden;

  #searchCollectionInput {
    color: colour(purple);
    font-size: 1.125rem;
    cursor: pointer;
  }

  .collections-select {
    @media (max-width: 575px) {
      display: none;
    }
  }

  input {
    width: 100%;
    border: 0;
    color: colour(black);
    font-family: 'Source Serif Pro', serif;
    font-size: 1.5rem;
    margin-left: 0.75rem;
    border-left: 1px solid colour(purple);
    border-radius: 0;
    &::placeholder {
      color: colour(grey-3);
      font-style: italic;
    }

    @media (max-width: 575px) {
      font-size: 1rem;
    }
  }

  @supports (font-variation-settings: normal) {
    input {
      font-family: 'Source Serif Variable', serif;
    }
  }

  select {
    border: 0;
    width: 10rem;
  }

  input:focus,
  select:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.search-section .search-box input {
  border-left: none;
}

.small-trove-logo {
  img {
    height: 50px;
  }
}
.accent-line {
  height: 4px;
  background: #000;
}

// Drops the accent from the homepage
.search-section {
  .accent-line {
    height: 0;
    background: transparent !important;
  }
}

.advanced-search {
  padding-right: 0.5rem;

  div {
    position: relative;
    height: 2rem;

    select {
      position: absolute;
      right: 0;
      padding-right: 1.5rem;
      z-index: 10;
      width: 15rem;
      background: 0;
      border: 0;
      color: colour(white);
      text-align-last: right;
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
      cursor: pointer;

      option {
        &:first-child {
          display: none;
        }

        color: colour(purple) !important;
        font-weight: normal;
        text-align-last: right;
        border: 1px solid black;
      }
    }

    .down {
      position: absolute;
      right: 0;
      top: 20%;
      font-size: 1.5rem;
      margin-left: 0.5rem;
      color: colour(white);
    }
  }
}
</style>

<script>
import _ from 'lodash'
import currentRoute from '@/mixins/currentRoute'
import history from '@/mixins/history.js'
import search from '@/mixins/search.js'
import service from '@/service/service'

export default {
  name: 'BasicSearch',
  mixins: [currentRoute, search, history],
  props: {
    collections: {
      type: Array,
      required: false,
    },
    selectedCollectionId: {
      type: String,
      required: false,
    },
    defaultText: {
      type: String,
      required: false,
    },
    displayCollections: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayAdvancedSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      searchText: null,
      searchBoxCleared: false,
      selectedCollection: null,
      emptySearchAttempted: false,
    }
  },
  created() {
    this.searchText = this.validQueryParamSearch ? this.currentQuery.keyword : this.defaultText
  },
  computed: {
    advancedSearchSelected: {
      get() {
        return null
      },
      set(collection) {
        service._gtm.interaction(this, {
          category: 'Search',
          action: 'Homepage Advanced selection: ' + collection.name,
        })

        this.updateStartAdvancedOpen(true)
        this.performSearchSelected(collection, this.searchText)
      },
    },
    canSearch() {
      return (
        this.canPerformSearch(this.searchText, this.currentQuery) ||
        (!_.isEmpty(this.searchText) && this.displayCollections)
      )
    },
    initialText() {
      return this.validQueryParamSearch ? this.currentQuery.keyword : this.defaultText
    },
    clearSearchBox() {
      return !this.validQueryParamSearch
    },
    validQueryParamSearch() {
      return this.currentQuery && !_(this.currentQuery.keyword).isEmpty()
    },
    hasCollections() {
      return this.collections && !_(this.collections).isEmpty()
    },
    isCollection() {
      if (this.$route && this.$route.meta && this.$route.meta.isCollectionSearch) {
        return 'Search this collection'
      } else {
        return 'Search Trove...'
      }
    },
  },
  methods: {
    search() {
      let searchText = ''

      if (this.searchBoxCleared || !this.clearSearchBox) {
        searchText = this.searchText
      }

      searchText = _.trim(searchText)

      if (searchText) {
        this.emptySearchAttempted = false
        this.$emit(
          'search',
          _.assign({}, this.removePaginationFromQuery(this.currentQuery), {
            keyword: searchText,
          })
        )
      } else {
        this.emptySearchAttempted = true
      }
    },
    searchBoxFocused() {
      if (!this.searchBoxCleared && this.clearSearchBox) {
        this.searchText = ''
        this.searchBoxCleared = true
      }
    },
    searchBoxUpdated() {
      if (this.emptySearchAttempted) {
        this.emptySearchAttempted = _.trim(this.searchText) === ''
      }
    },
    performSearchSelected(collection, searchText) {
      const routeDestination = {
        name: 'AdvancedSearch',
        params: {
          urlNamespace: collection.urlNamespace,
          urlKey: collection.urlKey,
        },
      }

      if (!_.isEmpty(searchText)) {
        routeDestination.query = { keyword: searchText }
      }

      this.$router.push(routeDestination)
    },
  },
  watch: {
    collections(updatedCollections) {
      const collectionBySearchRoute = this.findCollectionBySearchRoute(updatedCollections)
      if (collectionBySearchRoute) {
        this.selectedCollection = collectionBySearchRoute
      }
    },
    initialText: {
      immediate: true,
      handler() {
        this.searchText = this.initialText
      },
    },
    selectedCollectionId: {
      immediate: true,
      handler() {
        let self = this

        if (self.hasCollections) {
          self.selectedCollection =
            _(self.collections).find((collection) => {
              return collection.id === self.selectedCollectionId
            }) || null
        }
      },
    },
    selectedCollection() {
      this.$emit('collection-changed', this.selectedCollection)
    },
  },
}
</script>
