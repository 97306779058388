<template>
  <div class="collection-view">
    <b-container class="collection-content">
      <CollectionKeyhole
        :title="configuration.collection.name"
        :subtitle="configuration.collection.catchline"
        :logoImages="configuration.logosAndAltText"
        :keyholeImage="configuration.image"
        :keyholeImageAlt="configuration.imageAlt"
        :keyholeImageCaption="configuration.imageCaption"
        :bannerScheme="configuration.bannerColours" />

      <div class="section d-flex flex-row get-links">
        <b-button variant="primary" :pressed="selected === 'about'" @click="showView('about')"
          >About</b-button
        >
        <b-button
          v-if="configuration.collection.explorable"
          variant="primary"
          :pressed="selected === 'explore'"
          @click="showView('explore')"
          >Explore</b-button
        >
        <b-button
          v-if="configuration.collection.searchable"
          variant="primary"
          :pressed="selected === 'search'"
          @click="showView('search')"
          >Search</b-button
        >
        <div><div></div></div>
      </div>

      <div>
        <b-row class="section content">
          <b-col>
            <h2 class="bevel magenta">{{ componentConfig.heading }}</h2>

            <component
              :is="componentConfig.component"
              :configuration="configuration"
              :collectionsOverride="[configuration.collection]"
              :showCollections="false"
              :searchHandler="configuration.collection.searchHandler"
              :canAdvanced="false"
              @search-params-updated="searchParamsUpdated" />
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<style scoped lang="scss">
h2.bevel {
  margin-bottom: 2em;
  font-size: 1.5rem !important;
  font-family: 'Source Sans Pro', sans-serif;
  &:before {
    top: 0.14em !important;
  }
}

@supports (font-variation-settings: normal) {
  h2.bevel {
    font-family: 'Source Sans Variable', sans-serif;
  }
}

.content {
  .descriptionContainer {
    margin-bottom: 3em;
  }
}

.section {
  margin-top: 4rem;
}

.get-links {
  div {
    position: relative;
    width: 100%;

    div::after {
      @include bevel-bar(100%, $primary-button-height, 0em, 0em, colour(grey));

      @media (max-width: 575px) {
        content: none;
      }
    }
  }

  //for mobile
  @media (max-width: 575px) {
    flex-wrap: wrap;

    button {
      width: 100%;
      margin-top: 10px;
    }

    div:last-of-type {
      margin-bottom: 1rem;
    }
  }
}

.collection-content {
  margin-bottom: 2em;
}

.btn-primary {
  flex-grow: 1;
  transition: 0.5s ease-in-out;
  .active {
    background: colour(white) !important;
    color: colour(purple);
  }
  &:not(.disabled).active:focus {
    box-shadow: none;
    background: colour(white) !important;
    color: colour(purple);
  }
  &:not(.disabled).active:hover {
    box-shadow: none;
  }
  &:not(.disabled).active {
    background: colour(white) !important;
    color: colour(purple) !important;
  }
  &:hover,
  &:focus {
    background: colour(white);
    color: colour(purple);
  }
  .borrow-count {
    font-weight: 500;
    opacity: 0.7;
    margin-left: 0.3em;
  }
}
</style>

<script>
import routeHandling from '@/mixins/routeHandling'
import currentRoute from '@/mixins/currentRoute'
import service from '@/service/service'
import CollectionKeyhole from '@/components/collection/CollectionKeyhole.vue'
import About from '@/components/collection/About.vue'
import Search from '@/components/search/Search.vue'
import SearchExplore from '@/components/search/SearchExplore.vue'

export default {
  name: 'CollectionView',
  components: {
    Search,
    SearchExplore,
    CollectionKeyhole,
    About,
  },
  mixins: [routeHandling, currentRoute],
  metaInfo() {
    return { title: this.configuration.collection.name }
  },
  props: {
    view: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selected: '',
      configuration: {
        collection: {
          name: '',
          relatedCollections: [],
        },
      },
    }
  },
  watch: {
    urlNamespace() {
      this.updateCollection()
    },
    urlKey() {
      this.updateCollection()
    },
    currentQuery() {
      this.updateCollection()
    },
  },
  created() {
    this.updateCollection()
    this.selected = this.view || 'about'
  },
  computed: {
    componentConfig() {
      switch (this.view) {
        case 'explore':
          return {
            heading: 'Explore',
            component: SearchExplore,
          }
        case 'search':
          return {
            heading: 'Search',
            component: Search,
          }
        default:
          return {
            heading: 'About',
            component: About,
          }
      }
    },
  },
  methods: {
    showView(view) {
      this.selected = view

      this.$router.push({
        name: 'CollectionSearch',
        params: {
          urlNamespace: this.configuration.collection.urlNamespace,
          urlKey: this.configuration.collection.urlKey,
          view: this.selected,
        },
        query: null,
      })
    },
    updateCollection() {
      const self = this
      service.collection.getPage(this.urlNamespace, this.urlKey).then((configuration) => {
        self.configuration = configuration
        self.$emit('update-breadcrumbs', [{ value: configuration.collection.name }])
      })
    },
    searchParamsUpdated(urlNamespace, urlKey, query) {
      const params = {
        urlNamespace: this.configuration.collection.urlNamespace,
        urlKey: this.configuration.collection.urlKey,
        view: this.selected,
      }

      this.updateRoute('CollectionSearch', params, query)
    },
  },
}
</script>
