<template>
  <div class="componentContainer">
    <div ref="collapseContainer" :style="containerStyle" class="content">
      <slot></slot>
      <div
        class="visibilityDetector"
        v-observe-visibility="{ callback: detectorVisibilityChanged }"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.visibilityDetector {
  width: 1px;
  height: 1px;
}

.no-background {
  background: 0;
}
</style>

<script>
import Vue from 'vue'

export default {
  name: 'Collapse',
  props: {
    minHeight: {
      type: String,
      required: false,
      default: '0px',
    },
    collapsed: {
      type: Boolean,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      containerStyle: {},
    }
  },
  mounted() {
    let self = this
    self.updateContainerStyle()

    if (window.MutationObserver) {
      let observer = new MutationObserver(() => {
        self.updateContainerStyle()
      })

      observer.observe(self.$el.querySelector('.content'), {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
      })
    }

    if (window.addEventListener) {
      window.addEventListener('resize', function () {
        self.updateContainerStyle()
      })
    }
  },
  watch: {
    collapsed() {
      Vue.nextTick(() => {
        this.updateContainerStyle()
      })
    },
  },
  methods: {
    detectorVisibilityChanged(isVisible) {
      if (this.collapsed) {
        this.$emit('fully-visible', isVisible)
      }
    },
    updateContainerStyle() {
      var maxHeight = this.$refs.collapseContainer
        ? this.$refs.collapseContainer.scrollHeight + 'px'
        : 'auto'

      this.containerStyle = {
        display: 'block',
        'max-height': this.collapsed ? this.minHeight : maxHeight,
        overflow: 'hidden',
        transition: 'max-height 0.5s ease',
      }

      if (this.fullWidth) {
        this.containerStyle.width = '100%'
      }
    },
  },
}
</script>
