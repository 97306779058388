<template>
  <div
    class="collaboration-offer"
    v-if="
      list.collaborative && authenticated && !isListOwner(list.author) && !list.currentUserIsBlocked
    ">
    <div class="offer" v-if="!(list.currentUserAContributor || list.currentUserJoinRequestPending)">
      <b-button
        variant="success"
        @click="requestCollaborationAccess"
        :disabled="
          requestingAccess === 'inprogress' || requestingAccess === 'done' || !myProfileIsPublic
        "
        size="sm">
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'user-plus' }" class="author-icon" />
        Request to join
      </b-button>
      <span v-if="!myProfileIsPublic"
        >You can only collaborate on lists if your profile is publicly visible</span
      >

      <div
        class="collaborator-request"
        v-if="requestingAccess === 'inprogress' || requestingAccess === 'done'">
        <div v-if="requestingAccess === 'inprogress'" class="loading-spinner spinner-md"></div>
        <span class="access-requested" v-if="requestingAccess === 'done'"
          >Thank you! Your request to join this list is with the list owner for review.</span
        >
      </div>
    </div>

    <div class="collaborator-request" v-if="list.currentUserJoinRequestPending">
      <b-button disabled variant="success" size="sm">
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'user-plus' }" class="author-icon" />
        Request to join
      </b-button>
      <div class="access-requested">
        <span
          >You have already requested to join this list and your request is with the list owner for
          review.</span
        >
      </div>
    </div>

    <div class="already-collaborator" v-if="list.currentUserAContributor">
      You are a collaborator on this list.
    </div>
  </div>
</template>

<style scoped lang="scss">
.collaboration-offer {
  margin-top: 1rem;
}
</style>

<script>
import auth from '@/mixins/auth'
import lists from '@/mixins/lists'
import service from '@/service/service'

export default {
  name: 'CollaborationOffer',
  mixins: [auth, lists],
  components: {},
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      requestingAccess: undefined,
    }
  },
  computed: {},
  methods: {
    requestCollaborationAccess() {
      let self = this
      self.requestingAccess = 'inprogress'
      service.list
        .requestCollaborationAccess(self.fullUsername, self.list.id)
        .then(() => {
          self.requestingAccess = 'done'
        })
        .catch((error) => {
          self.handleError(error, 'list', self.list.id, 'offer to collaborate on this list')
        })
    },
  },
}
</script>
