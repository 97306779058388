<template>
  <div class="explain" v-if="explanation && isDataManager">
    <b-link
      ><div @click="toggleShowExplanation">
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'search-plus' }" class="left-icon" />{{
          showExplanation ? 'Hide' : 'Show'
        }}
        explanation<font-awesome-icon
          :icon="{
            prefix: 'fa',
            iconName: showExplanation ? 'caret-up' : 'caret-down',
          }"
          class="right-icon" /></div
    ></b-link>
    <div class="explanation" :class="{ open: showExplanation }">
      <pre v-html="explanation"></pre>
    </div>
  </div>
</template>

<style scoped lang="scss">
.explain {
  padding-top: 1rem;

  .left-icon {
    margin-right: 0.5rem;
  }

  .right-icon {
    margin-left: 0.5rem;
  }

  .explanation {
    margin-top: 1rem;
    max-height: 0;
    height: 0;
    background-color: colour(grey);
    overflow: hidden;
    &.open {
      height: auto;
      padding: 0.5rem;
      max-height: 800px;
      border-radius: 3px;
      transition: max-height 0.5s ease;
      margin-left: 0.1rem;
      margin-top: 0.5rem;
      overflow: scroll;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import auth from '@/mixins/auth'

export default {
  name: 'SearchResultExplain',
  mixins: [auth],
  props: {
    rawExplainText: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showExplanation: false,
    }
  },
  computed: {
    explanation() {
      let explain = undefined
      if (this.rawExplainText) {
        explain = _.replace(this.rawExplainText, /\n/g, '<br/>')
      }
      return explain
    },
  },
  methods: {
    toggleShowExplanation() {
      this.showExplanation = !this.showExplanation
    },
  },
}
</script>
