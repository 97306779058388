import _ from 'lodash'

var applyStringMapping = (mapping, input) => {
  if (_.isArray(input)) {
    return _.map(input, (child) => applyStringMapping(mapping, child))
  }

  if (!input) {
    return ''
  }

  if (_.isEmpty(mapping)) {
    return input
  }

  var replaced = input

  _(mapping).each((m) => {
    replaced = replaced.replace(m.search, m.replace)
  })

  return replaced
}

export default {
  methods: {
    mapCreativeCommonsImagePath(source) {
      return applyStringMapping(
        [
          {
            search: /\/images\/icon_creative_commons\.gif/g,
            replace: require('@/assets/cc.svg'),
          },
        ],
        source
      )
    },
    mapFormats(formats) {
      if (!_.isArray(formats)) {
        return []
      }

      return _(formats)
        .map((format) => this.mapFormat(format))
        .value()
    },
    mapWorkTypeName(format) {
      if (_(_.lowerCase(format || '')).includes('book')) {
        return 'book'
      }

      return 'work'
    },
    mapFormat(format) {
      return applyStringMapping(
        [
          { search: /object/g, replace: 'artefact' },
          { search: /Object/g, replace: 'Artefact' },
          { search: /;/g, replace: ',' },
        ],
        format
      )
    },
    mapFormatIcon(format, defaultIcon) {
      var iconMapping = [
        {
          text: 'image',
          icon: { prefix: 'far', iconName: 'image' },
        },
        {
          text: 'atlas',
          icon: { prefix: 'fas', iconName: 'atlas' },
        },
        {
          text: 'globe',
          icon: { prefix: 'fas', iconName: 'globe-asia' },
        },
        {
          text: 'map',
          icon: { prefix: 'fas', iconName: 'map' },
        },
        {
          text: 'music',
          icon: { prefix: 'fas', iconName: 'music' },
        },
        {
          text: 'video',
          icon: { prefix: 'fas', iconName: 'video' },
        },
        {
          text: 'audio',
          icon: { prefix: 'fas', iconName: 'volume-up' },
        },
        {
          text: 'book',
          icon: { prefix: 'fas', iconName: 'book' },
        },
        {
          text: 'article',
          icon: { prefix: 'fas', iconName: 'newspaper' },
        },
        {
          text: 'person',
          icon: { prefix: 'fas', iconName: 'user' },
        },
        {
          text: 'family',
          icon: { prefix: 'fas', iconName: 'users' },
        },
        {
          text: 'organisation',
          icon: { prefix: 'far', iconName: 'building' },
        },
        {
          text: 'html snapshot',
          icon: { prefix: 'far', iconName: 'browser' },
        },
        {
          text: 'list',
          icon: { prefix: 'fas', iconName: 'list' },
        },
        {
          text: 'web page',
          icon: { prefix: 'far', iconName: 'browser' },
        },
        {
          text: 'journal',
          icon: { prefix: 'fas', iconName: 'newspaper' },
        },
      ]

      var iconInfo = _.filter(iconMapping, (mapping) => {
        return _(_.lowerCase(format || '')).includes(mapping.text)
      })

      if (iconInfo && iconInfo.length > 0) {
        return iconInfo[0].icon
      }

      return defaultIcon || { prefix: 'fas', iconName: 'archive' }
    },
  },
}
