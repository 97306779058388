<template>
  <div v-if="systemTag" class="top">
    <p class="m-0">{{ systemTag }}</p>
  </div>
</template>

<style scoped lang="scss">
.top {
  float: left;
  width: 100%;
  background: black;
  color: white;
  text-align: center;
  padding-left: 0.3em;
  letter-spacing: 0.3em;
  font-weight: 100;
  border-bottom: 2px solid colour(grey-3);

  p::before {
    content: '[';
    color: colour(orange);
  }

  p::after {
    content: ']';
    color: colour(orange);
  }
}
</style>

<script>
import service from '@/service/service'

export default {
  name: 'SystemTag',
  data: function () {
    return {
      systemTag: '',
    }
  },
  mounted() {
    let self = this
    service.config.environment().then((r) => {
      self.systemTag = r.systemTag
    })
  },
}
</script>
