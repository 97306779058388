import _ from 'lodash'
import moment from 'moment'

export default {
  methods: {
    and(filters, object) {
      return _(filters)
        .map((filter) => {
          return filter(object)
        })
        .every((result) => result)
    },
    or(filters, object) {
      return _(filters)
        .map((filter) => filter(object))
        .find((result) => result)
        ? true
        : false
    },
    languageFiltering(selectedLanguages, item) {
      var filters = _(selectedLanguages)
        .map((language) => {
          return (item) => {
            if (language === 'undefined') {
              return item.language === undefined
            }
            return item.language === language
          }
        })
        .value()

      return _(filters).isEmpty() ? true : this.or(filters, item)
    },
    dateFiltering(start, end, item) {
      let startYear = start ? _.toNumber(start) : null
      let endYear = end ? _.toNumber(end) : null

      var parseItemYear = (itemYear) => {
        var parsed = moment(itemYear)

        if (parsed.isValid()) {
          return _.toNumber(parsed.format('YYYY'))
        }

        return null
      }

      if (_.isArray(item.year)) {
        return _.find(item.year, (itemYear) => {
          var year = parseItemYear(itemYear)
          return (
            (startYear == null ? true : year >= startYear) &&
            (endYear == null ? true : year <= endYear)
          )
        })
      } else {
        var year = parseItemYear(item.year)
        return (
          (startYear == null ? true : year >= startYear) &&
          (endYear == null ? true : year <= endYear)
        )
      }
    },
  },
}
