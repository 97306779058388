<template>
  <div>
    <AbstractCulturalSensitivityWarning v-bind="$props" v-if="isThumbnail">
      <template
        slot="text-and-actions"
        v-if="!showImagesOnThisPage && !showImagesEverywhereInTrove">
        <div class="text">
          For cultural sensitivity reasons, thumbnails on this page may be blurred. To show
          thumbnails, please select from the following options:
        </div>
        <div class="actions">
          <b-btn key="1" size="sm" class="show-content" @click="showImagesOnThisPage = true"
            >Show images on this page</b-btn
          >
          <b-btn key="2" size="sm" class="show-content" @click="showImagesEverywhereInTrove = true"
            >Show images everywhere in Trove</b-btn
          >
        </div>
      </template>

      <template slot="text-and-actions" v-if="showImagesOnThisPage">
        <div class="text">
          You will be able to view images on this page, and will continue to receive further
          cultural advice notices on Trove.
        </div>
        <div class="actions">
          <b-btn key="3" size="sm" class="show-content" @click="showContent"
            >Show images on this page</b-btn
          >
          <b-btn key="4" size="sm" class="show-content" @click="showImagesOnThisPage = false"
            >Cancel</b-btn
          >
        </div>
      </template>

      <template slot="text-and-actions" v-if="showImagesEverywhereInTrove">
        <div class="text">
          You will be able to view images on this page, and everywhere else on Trove. By selecting
          this option, you will not receive any further cultural advice notices.
        </div>
        <div class="actions">
          <b-btn key="5" size="sm" class="show-content" @click="showAllContentForSession"
            >Show images everywhere in Trove</b-btn
          >
          <b-btn key="6" size="sm" class="show-content" @click="showImagesEverywhereInTrove = false"
            >Cancel</b-btn
          >
        </div>
      </template>
    </AbstractCulturalSensitivityWarning>

    <AbstractCulturalSensitivityWarning v-bind="$props" v-else-if="showCulturalAdvice">
      <template slot="text-and-actions" v-if="!showStopCulturalAdvice">
        <div class="text">
          Please be advised that this page contains content that is culturally sensitive.
        </div>
        <div class="actions">
          <b-btn key="7" size="sm" class="show-content" @click="showStopCulturalAdvice = true"
            >Stop showing cultural advice</b-btn
          >
          <b-btn key="8" size="sm" class="show-content" @click="showCulturalAdvice = false"
            >Continue to show cultural advice</b-btn
          >
        </div>
      </template>
      <template slot="text-and-actions" v-else>
        <div class="text">
          By selecting 'Stop showing cultural advice' you will not receive any further cultural
          advice notices. Please note, if you opt out at this time, you will need to close your
          current browser session to receive cultural advice notices.
        </div>
        <div class="actions">
          <b-btn key="9" size="sm" class="show-content" @click="showAllContentForSession"
            >Stop showing cultural advice</b-btn
          >
          <b-btn key="10" size="sm" class="show-content" @click="showStopCulturalAdvice = false"
            >Cancel</b-btn
          >
        </div>
      </template>
    </AbstractCulturalSensitivityWarning>
  </div>
</template>

<script>
import AbstractCulturalSensitivityWarning from './AbstractCulturalSensitivityWarning'

export default {
  name: 'CulturalSensitivityWarning',
  extends: AbstractCulturalSensitivityWarning,
  props: {
    isThumbnail: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AbstractCulturalSensitivityWarning,
  },
  data() {
    return {
      showImagesOnThisPage: false,
      showImagesEverywhereInTrove: false,
      showStopCulturalAdvice: false,
      showCulturalAdvice: true,
    }
  },
}
</script>
