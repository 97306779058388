<template>
  <b-modal :id="modalId" class="modal" @ok="ok" @close="cancel" @cancel="cancel">
    <template v-slot:modal-title>Tag these results?</template>

    <h4>Are you certain?</h4>
    <p>
      Are you sure you want to tag the following
      {{ selectedResults.length }} work(s)?
    </p>

    <div class="results">
      <div v-for="result in selectedResults" class="result" :key="result.id">
        <div v-if="result.title" class="d-flex">
          <div class="fieldName">Title:</div>
          <div class="flex-grow-1">
            <b>{{ result.title }}</b>
          </div>
        </div>
        <div v-if="result.primaryName">
          <div class="fieldName">Name:</div>
          <div class="flex-grow-1">
            <b>{{ result.primaryName }}</b>
          </div>
        </div>
        <div v-if="result.author" class="d-flex">
          <div class="fieldName">Author:</div>
          <div class="flex-grow-1">
            <b>{{ result.author }}</b>
          </div>
        </div>
      </div>
    </div>

    <AddTag
      :showButtons="false"
      @validator="receiveValidator"
      @tags-updated="tagsUpdated"
      @user-achievement="userAchievement" />

    <div class="d-flex justify-content-center" v-if="saving">
      <div class="loading-spinner spinner-md"></div>
    </div>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="link" @click="cancel" :disabled="saving">Cancel</b-button>
      <b-button variant="primary" @click="ok" :disabled="saving">Tag these results</b-button>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import service from '@/service/service'
import AddTag from '@/components/work/AddTag.vue'
import BulkOperation from '@/components/bulk/BulkOperation.vue'

var EXCLUDED_WORK_TYPES = ['website']

export default {
  name: 'BulkTag',
  extends: BulkOperation,
  components: {
    AddTag,
  },
  data() {
    return {
      tags: [],
    }
  },
  computed: {
    operationId: () => 'tag',
    buttonText: () => 'Tag',
    // TODO: Pull the below function up into BulkOperation, and remove from the other bulk operations too.
    canPerformOperation() {
      if (!_.isArray(this.allResults) || _.includes(this.allResults, null)) {
        return false
      }

      if (
        _(this.allResults)
          .map((result) => result.workType)
          .filter((type) => _.includes(EXCLUDED_WORK_TYPES, type))
          .value().length > 0
      ) {
        return false
      }

      return this.isDataManager
    },
  },
  methods: {
    ok(event) {
      var self = this
      event.preventDefault()

      this.checkValidation().then((isValidated) => {
        if (isValidated) {
          self.saving = true
          self.error = undefined

          var targets = _.map(self.selectedResults, (result) => {
            return {
              id: result.id,
              type: self.typeMap(result),
            }
          })

          service.tags
            .addTags(targets, self.tags)
            .then((data) => {
              self.closeModal()
              self.saving = false

              let tags = _(data.items || [])
                .map((tag) => '"' + tag.content + '"')
                .uniq()
                .join(', ')
              if (_.isEmpty(tags)) {
                self.$emit(
                  'bulk-operation-succeeded',
                  'No tags have been applied.  Have these works been tagged with this already?  ' +
                    tags
                )
              } else {
                self.$emit(
                  'bulk-operation-succeeded',
                  'The selected works have been tagged with: ' + tags
                )
              }

              if (data.userAchievement && data.userAchievement.achievements.length > 0) {
                self.$emit('user-achievement', data.userAchievement)
              }
            })
            .catch((error) => {
              self.closeModal()
              self.saving = false
              self.$emit('bulk-operation-failed', error.response.data.message)
            })
        }
      })
    },
    tagsUpdated(tags) {
      this.tags = tags
    },
    userAchievement(ua) {
      this.$emit('user-achievement', ua)
    },
  },
}
</script>
