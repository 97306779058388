<template>
  <div class="options">
    <p>
      Due to the limitations of the CSV format, only list item details will be included in the
      download. For the full list details please download in JSON or XML format.
    </p>

    <b-button v-b-toggle.csv-export-options-collapse variant="link">{{ buttonText }}</b-button>
    <b-collapse id="csv-export-options-collapse" v-model="advancedVisible">
      <b-card title="Options" class="mb-2">
        <b-form-group label="Delimiter"
          ><b-form-select v-model="options.delimiter" :options="delimiters"></b-form-select
        ></b-form-group>
        <b-form-checkbox v-model="options.quoted">Surround fields with quotes</b-form-checkbox>
      </b-card>
    </b-collapse>
  </div>
</template>

<style scoped lang="scss">
.options {
  margin-top: 1.5rem;

  button {
    padding-left: 0;
  }

  .card {
    .card-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1rem;
    }

    @supports (font-variation-settings: normal) {
      .card-title {
        font-family: 'Source Sans Variable', sans-serif;
      }
    }
  }
}
</style>

<script>
var DELIMITERS = [
  {
    value: 'comma',
    text: 'comma',
  },
  {
    value: 'semicolon',
    text: 'semicolon',
  },
  {
    value: 'tab',
    text: 'tab',
  },
]

export default {
  name: 'CSVOptions',
  data() {
    return {
      options: {
        quoted: false,
        delimiter: DELIMITERS[0].value,
      },
      delimiters: DELIMITERS,
      advancedVisible: false,
    }
  },
  computed: {
    buttonText() {
      return this.advancedVisible ? 'Hide advanced export options' : 'Show advanced export options'
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit('options-updated', val)
      },
    },
  },
}
</script>
