<template>
  <div>
    <div class="collection-result mt-4 d-flex flex-wrap">
      <ImageResultContainer
        v-if="visibleResources && visibleResources.length > 0"
        :results="visibleResources"
        :isSummary="false"></ImageResultContainer>
      <h4 v-else>No records were found</h4>
    </div>

    <div v-if="moreLinkVisible" class="d-flex justify-content-center">
      <b-button v-if="moreLink" @click="toggleResources" variant="primary" class="inverse"
        >Show all</b-button
      >
      <b-button v-if="!moreLink" @click="toggleResources" variant="primary" class="inverse"
        >Show less</b-button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.people-selected {
  margin-top: 1em;
}
</style>

<script>
import _ from 'lodash'
import utils from '@/mixins/utils'
import ImageResultContainer from '@/components/search/ImageResultContainer.vue'

export default {
  name: 'PeopleSelected',
  components: {
    ImageResultContainer,
  },
  mixins: [utils],
  props: {
    resources: {
      type: Object,
      required: true,
    },
    partners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      moreLink: true,
      pageSize: 10,
    }
  },
  computed: {
    filteredResources() {
      return _(this.resources)
        .flatMap((o) => o)
        .filter((o) => !_.isEmpty(o.url))
        .filter((o) => this.partners.length === 0 || _.includes(this.partners, o.partner))
        .map((resource, idCount) => {
          let r = {}
          r.id = resource.title + idCount
          if (!_.isEmpty(resource.title)) r.title = resource.title
          if (!_.isEmpty(resource.creator)) r.author = resource.creator
          if (!_.isEmpty(resource.format)) r.format = resource.format
          else r.format = 'archive'
          if (!_.isEmpty(resource.years)) r.yearRange = resource.years
          if (!_.isEmpty(resource.text)) r.descriptiveText = resource.text
          if (!_.isEmpty(resource.series)) r.descriptiveSeries = resource.series
          if (!_.isEmpty(resource.language)) r.language = [resource.language]
          if (!_.isEmpty(resource.location)) r.descriptiveLocation = resource.location
          if (!_.isEmpty(resource.thumb)) r.thumbnailUrl = resource.thumb
          if (!_.isEmpty(resource.url)) r.deliverySystemLink = resource.url
          else r.nomore = true
          r.numHoldings = 1
          r.thumbnailIsCulturallySensitive = resource.thumbnailIsCulturallySensitive
          r.parentId = resource.parentId
          r.singleHoldingName = resource.partner.name
          return r
        })
        .sortBy(['title'])
        .value()
    },
    visibleResources() {
      return this.moreLink ? this.filteredResources.slice(0, this.pageSize) : this.filteredResources
    },
    moreLinkVisible() {
      return this.filteredResources.length > this.pageSize
    },
  },
  methods: {
    toggleResources() {
      this.moreLink = !this.moreLink
    },
  },
}
</script>
