<template>
  <div class="options">
    <p>
      This will produce a file containing a citation for each item in this list.
    </p>
    <p>
      Please select the type of citation you wish to download.
    </p>

    <b-form-select v-model="options.format" :options="allFormats"/>

    <p class="check">
      Check citations for accuracy before including them in your work.
    </p>
  </div>
</template>

<style scoped lang="scss">
.options {
  margin-top: 1.5rem;

  .check {
    color: colour(grey-3);
    font-style: italic;
    font-size: .9rem;
    margin-top: 1rem;
  }

  .website-format {
    margin-top: 1rem;
  }
}
</style>

<script>

import _ from "lodash";

export default {
  name: 'ExportCitationsOptions',
  props: {
    itemTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      options: {
        format: "APA"
      }
    }
  },
  computed: {
    allFormats() {
      return [
        {
          value: "APA",
          text: "APA"
        },
        {
          value: "BIBTEX",
          text: "BibTex"
        },
        {
          value: "ENDNOTE",
          text: "End Note"
        },
        {
          value: "HARVARD",
          text: "Harvard"
        },
        {
          value: "MLA",
          text: "MLA"
        },
        {
          value: "RIS",
          text: "RIS"
        },
        {
          value: "WIKIPEDIA",
          text: "Wikipedia"
        },
      ]
    },
    selectedFormat() {
      return _.find(this.allFormats, f => f.value === this.options.format);
    }
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit('options-updated', val)
      },
    },
  },
}
</script>
