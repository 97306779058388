<template>
  <b-form @submit.prevent="updateFilter" @reset.prevent="clearFilter">
    <div class="version-filter d-flex flex-column align-items-end">
      <div class="close">
        <b-button variant="link" @click="close"
          >Close <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'times' }"
        /></b-button>
      </div>

      <div class="d-flex">
        <b-form-group class="date-container" label="Date from" label-cols-sm="6">
          <b-form-input type="text" :value="startYear" @input="updateStartYear" />
        </b-form-group>
        <b-form-group class="date-container" label="Date to" label-cols-sm="6">
          <b-form-input type="text" :value="endYear" @input="updateEndYear" />
        </b-form-group>
      </div>

      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <div>
          <b-button variant="primary" class="inverse" type="reset">Clear</b-button>
          <b-button variant="primary" type="submit">Apply</b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<style scoped lang="scss">
.version-filter {
  .date-container {
    margin-left: 1rem;
    width: 15em;

    @media (max-width: 575px) {
      width: 100%;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import Filter from '@/components/work/Filter.vue'

export default {
  name: 'DateFilter',
  extends: Filter,
  props: {
    defaultSelected: {
      type: Object,
      required: false,
      default: null,
    },
    filterSpecification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      startYear: this.defaultSelected ? this.defaultSelected.start : null,
      endYear: this.defaultSelected ? this.defaultSelected.end : null,
      currentStartYear: this.defaultSelected ? this.defaultSelected.start : null,
      currentEndYear: this.defaultSelected ? this.defaultSelected.end : null,
    }
  },
  created() {
    if (this.defaultSelected && !this.defaultSelected.full && (this.startYear || this.endYear)) {
      this.updateFilter()
    }
  },
  watch: {
    defaultSelected: {
      deep: true,
      handler() {
        this.startYear = this.defaultSelected ? this.defaultSelected.start : null
        this.endYear = this.defaultSelected ? this.defaultSelected.end : null
      },
    },
  },
  methods: {
    updateStartYear(value) {
      this.currentStartYear = value
    },
    updateEndYear(value) {
      this.currentEndYear = value
    },
    updateFilter() {
      var self = this
      self.startYear = self.currentStartYear
      self.endYear = self.currentEndYear

      var filter = (item) => {
        return self.filterSpecification.filter(item, self.startYear, self.endYear)
      }

      var displays = []

      if (self.startYear || self.endYear) {
        displays = [
          {
            text:
              'Date range: ' + _.filter([self.startYear, self.endYear], (year) => year).join(' - '),
            clear: () => {
              self.startYear = null
              self.endYear = null
              self.currentStartYear = null
              self.currentEndYear = null
              self.updateFilter()
            },
          },
        ]
      }

      this.$emit('filter-updated', filter, displays)
    },
    clearFilter() {
      this.startYear = null
      this.endYear = null
      this.currentStartYear = null
      this.currentEndYear = null
      this.updateFilter()
    },
  },
}
</script>
