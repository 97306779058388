<template>
  <div class="blocked-collaboration-requests" v-if="blockedRequests.length > 0">
    <div class="blocked-requests">
      <h2 v-if="showHeading">
        You have {{ blockedRequests.length }} blocked collaboration request(s)
      </h2>

      <ul class="list-unstyled">
        <li v-for="request in blockedRequests" :key="request.user.fullUserId" class="collaborator">
          <UserThumbnail :user-summary="request.user" class="user" size="small"></UserThumbnail>
          <div class="controls">
            <b-button
              variant="link"
              @click="unblock(request.user.fullUserId)"
              :disabled="inProgress">
              Unblock
            </b-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.blocked-collaboration-requests {
  .blocked-requests {
    h2 {
      font-size: 1.375rem;
    }

    .collaborator {
      margin-bottom: 1rem;

      .user {
        width: 200px;
      }

      .controls {
        display: inline-block;
        margin-left: 1rem;
      }
    }
  }
}
</style>

<script>
import service from '@/service/service'
import UserThumbnail from '@/components/profile/UserThumbnail'
import moment from 'moment'

export default {
  name: 'BlockedCollaborationRequests',
  components: {
    UserThumbnail,
  },
  props: {
    listId: {
      type: [String, Number],
      required: true,
    },
    blockedRequests: {
      type: Array,
      required: true,
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      inProgress: undefined,
      panelOpen: false,
    }
  },
  computed: {},
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM YYYY h:mm a')
    },
    unblock(userId) {
      let self = this
      self.inProgress = true
      service.list
        .unblockUserFromList(this.listId, userId)
        .then(() => {
          self.inProgress = false
          self.$emit('request-unblocked')
        })
        .catch((error) => {
          self.handleError(error, 'list', undefined, 'unblock a collaboration request')
        })
    },
    togglePanel() {
      this.panelOpen = !this.panelOpen
    },
  },
}
</script>
