<template>
  <div class="app-content">
    <template v-if="criticalApiFail">
      <div class="content">
        <SystemWsod />
      </div>
    </template>
    <template v-else>
      <div class="content">
        <SystemTag />

        <TroveHeader
          @landing-selected="routeLanding"
          :landing-pages="landingPages"
          :selectedLandingId="selectedLandingPage"
          :navigation="globalConfig.navigation"
          :renderH1="isHomePage" />

        <Alerts />

        <Breadcrumbs :crumbs="breadcrumbs" :showHistory="showHistory" />
        <router-view @update-breadcrumbs="updateBreadcrumbs" />
      </div>
      <div class="footer">
        <TroveFooter
          v-if="!loading"
          :collapsable="false"
          :acknowledgement-of-country="globalConfig.acknowledgementOfCountry"
          :partner="globalConfig.partnerWidget"
          :social="globalConfig.social"
          :bottom-links="globalConfig.bottomLinks"
          :common-links="globalConfig.footerLinks"
          :newsletter-widget="globalConfig.newsletterWidget" />
      </div>
    </template>
  </div>
</template>

<style lang="scss">
@import '../node_modules/source-sans-pro/source-sans-pro.css';
@import '../node_modules/source-sans-pro/source-sans-variable.css';
@import '../node_modules/source-serif-pro/source-serif-pro.css';
@import '../node_modules/source-serif-pro/source-serif-variable.css';
@import './scss/bootstrap-overrides.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './scss/global-styles.scss';
</style>

<script>
import _ from 'lodash'
import store from '@/store/store'
import service from '@/service/service'
import SystemTag from '@/components/SystemTag.vue'
import SystemWsod from '@/components/SystemWsod.vue'
import TroveHeader from '@/components/TroveHeader.vue'
import TroveFooter from '@/components/TroveFooter.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Alerts from '@/components/Alerts.vue'
import history from '@/mixins/history'

export default {
  name: 'TroveViewApp',
  mixins: [history],
  components: {
    TroveHeader,
    TroveFooter,
    Breadcrumbs,
    Alerts,
    SystemTag,
    SystemWsod,
  },
  metaInfo() {
    return {
      title: 'Trove',
      titleTemplate: (t) => (t && t !== 'Trove' ? t + ' - Trove' : 'Trove'),
    }
  },
  data() {
    return {
      breadcrumbs: [],
      showHistory: false,
      landingPages: [],
      globalConfig: {
        navigation: [],
        categories: [],
        navLandingPages: [],
        acknowledgementOfCountry: {},
        newsletterWidget: {},
        social: {},
        culturalAdviceWidget: {},
        partnerWidget: {},
        bottomLinks: [],
        footerLinks: [],
      },
      loading: true,
      isHomePage: false,
      criticalApiFail: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        const history = this.routeHistory

        if (history.length > 1 && _.isEqual(route, history[1])) {
          // we've gone backwards
          this.removeRouteHistoryHead()
        } else {
          this.addToRouteHistory(route)
        }

        this.isHomePage = route.name === 'Home'
      },
    },
  },
  created() {
    service.errorHandling.installErrorInterceptor()

    let self = this
    service.landing.getPages().then((pagesConfig) => {
      self.landingPages = pagesConfig

      _.forEach(self.landingPages, (landingPage) => {
        landingPage.routerLink = {
          name: 'Landing',
          params: {
            landingId: landingPage.urlKey,
          },
        }
      })
    })

    service.config
      .global()
      .then(function (gc) {
        _.forOwn(gc, function (val, key) {
          self.$set(self.globalConfig, key, val)
          _.forOwn(val, function (val2, key2) {
            self.$set(self.globalConfig[key], key2, val2)
          })
        })

        _.forEach(self.globalConfig.navigation, function (nav) {
          nav.id = nav.title
          nav.name = nav.title
        })

        self.loading = false
      })
      .catch(() => (self.loading = false))
  },
  methods: {
    updateBreadcrumbs(crumbs, showHistory) {
      this.breadcrumbs = crumbs
      this.showHistory = showHistory
    },
    routeLanding(landingPage) {
      this.$router.push({
        name: 'Landing',
        params: {
          landingId: landingPage.urlKey,
        },
      })
    },
  },

  computed: {
    selectedLandingPage() {
      var lid = this.$route.params.landingId
      if (lid) {
        var lp = _.find(this.landingPages, { urlKey: lid })
        return lp ? lp.id : null
      }
      return null
    },
  },
  mounted() {
    // eslint-disable-next-line
         store.subscribeAction((action, state) => {
      if ('recordApiCriticalFail' === action.type) {
        this.criticalApiFail = true // one way street
      }
    })
  },
}
</script>
