<template>
  <div>
    <Search :advanced="advanced" @search-params-updated="searchParamsUpdated" />
    <CulturalModal />
  </div>
</template>

<style scoped lang="scss"></style>

<script>
import currentRoute from '@/mixins/currentRoute'
import search from '@/mixins/search'
import routeHandling from '@/mixins/routeHandling'
import Search from '@/components/search/Search.vue'
import CulturalModal from '../components/cultural/CulturalModal'

export default {
  name: 'SearchView',
  components: {
    Search,
    CulturalModal,
  },
  mixins: [search, routeHandling, currentRoute],
  metaInfo() {
    return { title: 'Search' }
  },
  props: {
    advanced: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.$emit('update-breadcrumbs', [{ value: 'Search results' }])
  },
  methods: {
    searchParamsUpdated(urlNamespace, urlKey, query, advanced, replace) {
      const params = {
        urlNamespace: urlNamespace,
        urlKey: urlKey,
      }

      this.updateRoute(
        this.getSearchRouteName({ urlNamespace: urlNamespace, urlKey: urlKey }, advanced),
        params,
        query,
        replace
      )
    },
  },
}
</script>
