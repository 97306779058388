<template>
  <div class="dashboard-container">
    <div class="container">
      <div class="form-row">
        <div v-if="dataLoaded" class="col-auto">
          <label for="library">Your contributions</label>
          <b-form-select id="library" class="form-control" v-model="selectedLibrary">
            <option v-for="(value, name) in embedInfo.libraries" :key="name" :value="name">
              {{ value }}
            </option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div id="report-container" class="dashboard"></div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as pbi from 'powerbi-client'
import service from '../service/service'
import auth from '@/mixins/auth'
import profile from '@/mixins/profile'

export default {
  name: 'PartnerDashboard',
  mixins: [auth, profile],
  data() {
    return {
      embedInfo: {},
      dataLoaded: false,
      selectedLibrary: null,
    }
  },
  mounted() {
    this.updateBreadcrumbs()
  },
  watch: {
    selectedLibrary: {
      immediate: true,
      handler() {
        this.loadEmbedInfo()
      },
    },
  },
  methods: {
    updateBreadcrumbs() {
      this.$emit(
        'update-breadcrumbs',
        [{ link: '/userProfile/', value: 'My profile' }, { value: 'dashboard' }],
        true
      )
    },
    loadEmbedInfo() {
      let self = this
      service.dashboard
        .getReportEmbedDetails(self.selectedLibrary)
        .then((info) => {
          _.assign(self.embedInfo, info)
          if (!self.selectedLibrary) {
            self.selectedLibrary = Object.keys(self.embedInfo.libraries)[0]
          }
          self.dataLoaded = true
          self.embedReport()
        })
        .catch((error) => {
          //If no dashboard library can be found, navigate to Trove home
          this.$router.push('/')
          self.handleError(error, 'dashboard', undefined, 'embed partner dashboard')
        })
    },
    embedReport() {
      let self = this
      let config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: self.embedInfo.reportEmbedToken,
        embedUrl:
          'https://app.powerbi.com/reportEmbed?groupId=' +
          self.embedInfo.groupId +
          '&reportId=' +
          self.embedInfo.reportId,
        id: self.embedInfo.reportId,
        permissions: pbi.models.Permissions.Read,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true,
        },
      }

      let reportContainer = document.getElementById('report-container')
      let pbiService = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      )
      pbiService.reset(reportContainer) // remove already embedded component if any
      pbiService.embed(reportContainer, config)
    },
  },
}
</script>

<style scoped lang="scss">
.dashboard-container {
  margin-top: 1em;

  .dashboard {
    margin-top: 1em;
    height: 1000px;
    width: 100%;
    display: flex;
  }
}
</style>
