import _ from 'lodash'
import { extend } from 'vee-validate'
import { between } from 'vee-validate/dist/rules'
import validation from '@/mixins/validation'
import LibraryLookup from '@/components/search/advanced/LibraryLookup.vue'
import NewspaperLookup from '@/components/search/advanced/NewspaperLookup.vue'
import { mapActions, mapMutations } from 'vuex'

extend('yearBetween', {
  ...between,
  message: () => 'Not a valid year',
})

export default {
  mixins: [validation],
  created() {
    extend('isbn', {
      validate: this.ISBNValidator,
      message: () => 'Not a valid ISBN',
    })

    extend('issn', {
      validate: this.ISSNValidator,
      message: () => 'Not a valid ISSN',
    })

    extend('ismn', {
      validate: this.ISMNValidator,
      message: () => 'Not a valid ISMN',
    })

    extend('fuzzyDate', {
      validate: this.fuzzyDateValidator,
      message: () => 'Date must be YYYY, or YYYY-MM, or YYYY-MM-DD',
    })
  },
  methods: {
    ...mapActions('trove', ['addFacetsDisplayConfig', 'addFieldsDisplayConfig']),
    ...mapMutations('trove', {
      clearFieldsDisplayConfig: 'CLEAR_FIELDS_DISPLAY_CONFIG',
    }),
    standardField(fieldKey, labelWidth) {
      var fields = {
        australian_content: this.generateCheckboxField('Australian content', 'l-australian'),
        year: this.generateRangeField('Year range', 'date', 'YYYY', {
          yearBetween: { min: -9999, max: 9999 },
        }),
        isbn: this.generateTextField('ISBN', 'isbn', false, null, {
          isbn: true,
        }),
        issn: this.generateTextField('ISSN', 'issn', false, null, {
          issn: true,
        }),
        ismn: this.generateTextField('ISMN', 'ismn', false, null, {
          ismn: true,
        }),
        public_tag: this.generateTextField('Public tag', 'publictag'),
        library: () =>
          this.generateField('Organisations', this.generateLibrarySubfield(this.allLibraries)),
        format: () => this.generateFieldFromFacet('advformat'),
        access: () => this.generateFieldFromFacet('advavailability'),
        language: () => this.generateFieldFromFacet('advlanguage'),
        selected_subject: () => this.generateFieldFromFacet('advdiscipline'),
        category: () => this.generateFieldFromFacet('advcategory'),
        austlang: () => this.generateFieldFromFacet('advaustlanguage'),
        place: () => this.generateFieldFromFacet('advgeocoverage'),
        scale: () => this.generateFieldFromFacet('advzoom'),
        rights: () => this.generateFieldFromFacet('advrights'),
        illustration_type: () => this.generateFieldFromFacet('advIllustrationType'),
        word_count: () =>
          this.generateField(
            'Word count',
            this.generateSelectSubfieldFromFacet('advWordCount', 'Any')
          ),
        art_type: () => this.generateFieldFromFacet('advArtType'),
        title: () => this.generateFieldFromFacet('advtitle'),
      }

      var field = _.isFunction(fields[fieldKey]) ? fields[fieldKey]() : fields[fieldKey]

      if (field && labelWidth) {
        return _.merge(field, { labelWidth: labelWidth })
      }

      return field || {}
    },
    generateLibrarySubfield(allLibraries) {
      var self = this

      return {
        name: 'nuc', // nuc string may be quoted as it often contains a colon (e.g.) ANL:DL
        type: 'custom',
        component: LibraryLookup,
        props: {
          originalValues: (function () {
            let originalValues = self.fieldValueFromQuery(self.currentQuery, 'nuc')

            if (originalValues && !_.isArray(originalValues)) {
              originalValues = [originalValues]
            }

            // remove quotes
            return _.map(originalValues, (v) => v.replace(/^"(.*)"$/, '$1'))
          })(),
          allLibraries: allLibraries,
        },
        events: {
          update: function (selectedLibraries) {
            self.fieldUpdated(
              'nuc',
              _(selectedLibraries)
                .map((library) => library.nuc)
                .value()
            )
          },
          'clear-handler': function (handler) {
            self.addClearHandler(handler)
          },
        },
        width: '100%',
      }
    },
    generateTitleSubfield(allTitles, initialQuery) {
      var self = this

      return {
        name: 'l-advtitle',
        facet: ['advtitle', 'advstate'],
        additionalFields: ['l-advstate'],
        type: 'custom',
        component: NewspaperLookup,
        pillHints: {
          valueMap(val) {
            let mappedTitle = _.find(allTitles, (title) => title.id === val)
            return mappedTitle ? mappedTitle.name : val
          },
        },
        props: {
          originalValues: (function () {
            var titleValues = self.fieldValueFromQuery(initialQuery, 'l-advtitle')
            var stateValues = self.fieldValueFromQuery(initialQuery, 'l-advstate')

            var originalValues = _.concat(titleValues || [], stateValues || [])

            if (originalValues && !_.isArray(originalValues)) {
              return [originalValues]
            }

            return originalValues
          })(),
          allTitles: allTitles,
        },
        events: {
          update: function (selectedTitles) {
            var stateFields = _.filter(selectedTitles, (title) => title.isState)
            var titleFields = _.filter(selectedTitles, (title) => !title.isState)

            self.fieldUpdated(
              'l-advstate',
              _(stateFields)
                .map((state) => state.name)
                .value()
            )
            self.fieldUpdated(
              'l-advtitle',
              _(titleFields)
                .map((title) => title.id)
                .value()
            )
          },
          'clear-handler': function (handler) {
            self.addClearHandler(handler)
          },
        },
        width: '100%',
      }
    },
    generateField(display, subFields) {
      return {
        display: display,
        subFields: _.isArray(subFields) ? subFields : [subFields],
      }
    },
    generateIllustratedField(display) {
      return this.generateField(display, this.generateIllustratedSubfield())
    },
    generateIllustratedSubfield() {
      return {
        name: 'l-illustrated',
        type: 'select',
        options: [
          {
            text: 'Any',
            value: null,
          },
          {
            text: 'Illustrated articles only',
            value: 'true',
          },
          {
            text: 'Articles without illustrations only',
            value: 'false',
          },
        ],
      }
    },
    generateSelectSubfieldFromFacet(facetCode, nullValue) {
      var options = this.getTermsFromFacet(facetCode)

      if (nullValue) {
        options = [{ text: nullValue, value: null }].concat(options)
      }

      return {
        name: `l-${facetCode}`,
        facet: facetCode,
        type: 'select',
        options: options,
      }
    },
    generateMultiselectSubfieldFromFacet(facetCode, facet) {
      let pillHints =
        facet && facet.mapping
          ? {
              valueMap: (value) => {
                return facet.mapping[value] || value
              },
            }
          : {}

      return {
        name: `l-${facetCode}`,
        pillHints: pillHints,
        facet: facetCode,
        type: 'multiselect',
        options: this.getTermsFromFacet(facetCode),
        valueMapper: this.multiselectMapper,
        valueReverseMapper: this.multiselectReverseMapper,
        width: '100%',
      }
    },
    generateFieldFromFacet(facetCode) {
      let facet = this.getFacetFromCode(facetCode)
      return this.generateField(
        facet ? facet.displayName : '',
        this.generateMultiselectSubfieldFromFacet(facetCode, facet)
      )
    },
    generateTextField(display, field, grow, placeholder, validationRules) {
      return this.generateField(display, {
        name: field,
        type: 'text',
        grow: grow,
        placeholder: placeholder,
        validationRules: validationRules,
      })
    },
    generateModifiableTextField(display, field) {
      var modifiers = [
        {
          text: 'All of these words',
          value: null,
        },
        {
          text: 'Any of these words',
          value: 'any',
        },
        {
          text: 'The phrase',
          value: 'phrase',
        },
        {
          text: 'Without these words',
          value: 'not',
        },
      ]

      return this.generateField(display, [
        {
          name: `${field}.mod`,
          type: 'select',
          options: modifiers,
          default: null,
        },
        {
          name: field,
          type: 'text',
          grow: true,
        },
      ])
    },
    generateCheckboxField(display, field, checked, unchecked) {
      return this.generateField(display, {
        name: field,
        pillHints: {
          valueMap: (value) => {
            return value === 'y' ? 'yes' : 'no'
          },
        },
        type: 'checkbox',
        checked: checked || 'y',
        unchecked: unchecked || null,
      })
    },
    generateDateRangeField(display, field, minDate, maxDate) {
      return this.generateField(display, [
        {
          display: 'From',
          name: `${field}.from`,
          type: 'date',
          openDate: minDate,
          validationRules: { fuzzyDate: true },
        },
        {
          display: 'To',
          name: `${field}.to`,
          type: 'date',
          openDate: maxDate,
          defaultToEndOfRange: true,
          validationRules: { fuzzyDate: true },
        },
      ])
    },
    generateRangeField(display, field, placeholder, validationRules) {
      return this.generateField(display, [
        {
          display: 'From',
          name: `${field}.from`,
          type: 'text',
          width: '10rem',
          placeholder: placeholder,
          validationRules: validationRules,
        },
        {
          display: 'To',
          name: `${field}.to`,
          type: 'text',
          width: '10rem',
          placeholder: placeholder,
          validationRules: validationRules,
        },
      ])
    },
  },
}
