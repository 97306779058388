<script>
import _ from 'lodash'
import moment from 'moment'
import auth from '@/mixins/auth'
import service from '@/service/service'
import AdvancedSearch from '@/components/search/advanced/AdvancedSearch.vue'

export default {
  name: 'NewspaperAdvancedSearch',
  extends: AdvancedSearch,
  mixins: [auth],
  data() {
    return {
      allTitles: [],
      minDate: null,
      maxDate: null,
      keywordSubfields: ['keyword.any', 'keyword.phrase', 'keyword.not'],
    }
  },
  computed: {
    fieldConfig() {
      return [
        this.generateTextField('All of these words', 'keyword', true),
        this.generateTextField('Any of these words', 'keyword.any', true),
        this.generateTextField('The phrase', 'keyword.phrase', true),
        this.generateTextField('Without these words', 'keyword.not', true),
      ]
    },
    limitFieldConfig() {
      let limitFields = [
        this.standardField('art_type'),
        this.generateField(
          'Titles and places',
          this.generateTitleSubfield(this.allTitles, this.currentQuery)
        ),
        this.generateDateRangeField('Date range', 'date', this.minDate, this.maxDate),
        this.standardField('category'),
        this.standardField('illustration_type'),
        this.generateField('Word count', this.generateSelectSubfieldFromFacet('advWord', 'Any')),
      ]

      if (this.isQAer) {
        limitFields.push(this.generateRangeField('Batch(es)', 'batchId'))
      }

      return limitFields
    },
  },
  watch: {
    selectedCollectionId: {
      immediate: true,
      handler() {
        var self = this

        service.search.getSearchMetadata(self.selectedCollectionId).then((metadata) => {
          self.allTitles = metadata.titles
        })
      },
    },
    allTitles: {
      immediate: true,
      handler() {
        var self = this
        var minYear = null
        var maxYear = null

        _.each(self.allTitles || [], (title) => {
          if (title.startYear && (!minYear || title.startYear < minYear)) {
            minYear = title.startYear
            var minDate = moment(minYear + '-01-01', 'YYYY-MM-DD')
            self.minDate = minDate.isValid() ? minDate.toDate() : null
          }

          if (title.endYear && (!maxYear || title.endYear > maxYear)) {
            maxYear = title.endYear
            var maxDate = moment(maxYear + '-12-31', 'YYYY-MM-DD')
            self.maxDate = maxDate.isValid() ? maxDate.toDate() : null
          }
        })
      },
    },
  },
}
</script>
