<template>
  <b-form @submit.prevent="updateFilter" @reset.prevent="clearFilter">
    <div class="version-filter d-flex flex-column align-items-end">
      <div class="close">
        <b-button variant="link" @click="close"
          >Close <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'times' }"
        /></b-button>
      </div>

      <b-form-checkbox-group v-model="selectedLanguages" class="checkboxes">
        <b-form-checkbox
          v-for="language in sortedLanguages"
          class="checkbox language"
          :key="language"
          :value="language"
          >{{ language }} ({{ languages[language] }})</b-form-checkbox
        >
      </b-form-checkbox-group>

      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <div>
          <b-button variant="primary" class="inverse" type="reset">Clear</b-button>
          <b-button variant="primary" type="submit">Apply</b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<style scoped lang="scss">
.version-filter {
  .checkboxes {
    margin-bottom: 2rem;

    .checkbox {
      margin-right: 0;
      margin-left: 1rem;

      &.language {
        text-transform: capitalize;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import Filter from '@/components/work/Filter.vue'

export default {
  name: 'LanguageFilter',
  extends: Filter,
  props: {
    languages: {
      type: Object, // language and number of versions, e.g. {english: 1, french: 2}
      required: true,
    },
    defaultSelected: {
      type: Array,
      required: false,
      default: null,
    },
    filterSpecification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedLanguages: this.defaultSelected,
    }
  },
  created() {
    if (this.defaultSelected) {
      this.updateFilter()
    }
  },
  computed: {
    sortedLanguages() {
      let english = this.languages['english']

      let sorted = _(this.languages)
        .keys()
        .concat(this.selectedLanguages || [])
        .uniq()
        .filter((l) => l !== 'english')
        .sortBy((l) => l)
        .value()

      if (english) {
        sorted.push('english')
      }

      return sorted
    },
  },
  methods: {
    updateFilter() {
      var self = this

      var displays = _.map(self.selectedLanguages || [], (language) => {
        return {
          text: 'Language: ' + language,
          clear: () => {
            self.selectedLanguages = _.filter(self.selectedLanguages, (l) => l !== language)
            self.updateFilter()
          },
        }
      })

      var filter = (item) => {
        return self.filterSpecification.filter(item, self.selectedLanguages)
      }

      this.$emit('filter-updated', filter, displays)
    },
    clearFilter() {
      this.selectedLanguages = []
      this.updateFilter()
    },
  },
}
</script>
