<template>
  <router-link v-if="link || route" :to="link || route"><slot></slot></router-link>
  <span v-else><slot></slot></span>
</template>

<script>
export default {
  name: 'PossibleLink',
  props: {
    linkResolver: {
      type: Function,
      required: false,
    },
    route: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      link: null,
      resolved: false,
    }
  },
  mounted: function () {
    var self = this
    if (self.linkResolver && !self.resolved && this.label) {
      self.resolved = true
      self.linkResolver(this.label).then((route) => {
        self.link = route
      })
    }
  },
}
</script>
