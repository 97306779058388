<!-- eslint-disable -->
<template>
    <div class="library-list">
        <template v-for="library in libraries">
            <div class="library-info" :class="{'high-priority': library.highPriority}" :key="library.key">
                <div class="link-text" v-if="!library.url">{{library.name}}</div>

                <b-link v-if="libraryLink(library)" :href="libraryLink(library)" :target="library.target">
                    <div @click="libraryClicked(library)"><span class="mr-2">{{library.name}}</span><font-awesome-icon v-if="!library.hideExternalIcon" :icon="{ prefix: 'fas', iconName: 'external-link-alt' }" :class="'link-icon'"/></div>
                </b-link>

                <template v-if="library.fields">
                    <template v-for="field in library.fields">
                        <template v-if="!field.hide && field.text">
                            <div class="field" v-if="!field.href">{{field.text}}</div>
                            <b-link class="field" v-if="field.href" :href="field.href">{{field.text}}</b-link>
                        </template>
                    </template>
                </template>
            </div>
        </template>
    </div>
</template>
<!-- eslint-enable -->

<style scoped lang="scss">
.library-list {
  display: flex;
  flex-wrap: wrap;

  .library-info {
    flex: 1;
    margin-right: 2em;
    margin-bottom: 1.5em;
    display: inline-block;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 4rem;
    max-width: 22%;
    min-width: 22%;
    text-align: left;
    border-radius: 0;
    position: relative;
    border: none;
    border-bottom: 2px solid colour(light-blue);
    @media (max-width: 767.98px) {
      min-width: 43%;
      max-width: 100%;
    }
    @media (max-width: 575.98px) {
      min-width: 100%;
      margin-right: 0;
    }

    .field {
      font-size: smaller;
      color: colour(grey-3);
      text-transform: capitalize;
    }

    .link-icon {
      font-size: 0.8rem;
      opacity: 0.7;
      position: relative;
      top: -2px;
    }

    &.high-priority {
      background-color: colour(grey);

      &:hover,
      &:focus {
        color: colour(blue) !important;
        border-color: colour(blue);
        background-color: colour(grey);
      }
    }

    transition: 0.5s ease-in-out;

    &:hover,
    &:focus {
      background: colour(grey);
      border-bottom: 2px solid colour(blue);

      a {
        text-decoration: none;
        color: colour(blue);
      }
    }

    a {
      text-decoration: none;
      color: colour(black);
      transition: 0.5s ease-in-out;
    }
  }
}
</style>

<script>
import currentRoute from '@/mixins/currentRoute'

export default {
  name: 'LibraryList',
  props: {
    libraries: {
      type: Array,
      required: true,
    },
  },
  mixins: [currentRoute],
  data() {
    return {}
  },
  methods: {
    libraryLink(library) {
      if (library.url) {
        let dest = library.url
        const query = this.currentQuery
        if (dest.startsWith('https://nla.gov.au/nla.obj-') &&
            (query && (!query['keyword.mod'] || query['keyword.mod'] !== 'not') && query['keyword'])) {
          let term = query['keyword'] ? query['keyword'].trim() : ''
          if (term !== '') {
            dest += '?searchTerm=' + encodeURIComponent(term)
          }
        }
        return dest
      }
      return null
    },
    libraryClicked(library) {
      this.$emit('library-clicked', library)
    }
  },
}
</script>
