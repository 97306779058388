<template>
  <FilterableList
    class="text-corrections"
    :filterConfigs="filterConfigs"
    :filtered="filtered"
    :items="items"
    :loading="loading"
    itemName="correction"
    itemNamePlural="corrections"
    :paginationInfo="paginationInfo"
    @get-items="getItems"
    @page-changed="pageChanged"
    @reset-filters="resetFilters">
    <template v-slot:header>
      <span class="count">{{ profileInfo.linesCorrected | formatNumber }}</span>
      <span class="header-text"
        >line{{ profileInfo.linesCorrected != 1 ? 's' : '' }} corrected</span
      >
      <span class="leader-board" v-if="leaderBoardRank"
        >({{ leaderBoardRank | formatNumber }}
        <span v-if="totalLeaderBoardPositions > 0">
          of {{ totalLeaderBoardPositions | formatNumber }} Voluntrove text correctors)</span
        ></span
      >
    </template>

    <template v-slot:no-items>
      No text corrections have been contributed within the last 90 days
    </template>

    <template v-slot:item="data">
      <div class="metadata">
        <span class="date">{{ formatDate(data.item.created) }}</span>
        <span v-if="data.item.lineCount > 0" class="line-count"
          >{{ data.item.lineCount }} line{{ data.item.lineCount > 1 ? 's' : '' }}</span
        >
      </div>

      <div class="summary">
        <h2 class="corrected-item-title">
          <a :href="getLinkForCorrection(data.item)">{{ data.item.articleTitle }}</a>
        </h2>
        <span class="category" v-if="data.item.category"
          ><font-awesome-icon :icon="formatIcon(data.item.category)" />{{
            data.item.category
          }}</span
        >
        <span class="parent-title" v-if="data.item.parentTitle">
          - {{ data.item.parentTitle }}</span
        >
        <span class="creator" v-if="data.item.parentCreator"> - {{ data.item.parentCreator }}</span>
        <span class="issue" v-if="data.item.parentIssue"> - {{ data.item.parentIssue }}</span>
        <span class="date" v-if="data.item.parentIssueDate"
          ><font-awesome-icon :icon="{ prefix: 'far', iconName: 'calendar' }" />{{
            formatDate(parseDate(data.item.parentIssueDate), hasLongDateFormat(data.item))
          }}</span
        >
        <span class="date" v-if="data.item.date"
          ><font-awesome-icon :icon="{ prefix: 'far', iconName: 'calendar' }" />{{
            formatDate(parseDate(data.item.date), hasLongDateFormat(data.item))
          }}</span
        >
        <span class="page" v-if="data.item.page"> - {{ formatPageForCorrection(data.item) }}</span>

        <div class="link" v-if="isMyProfile || canViewPrivateProfiles">
          <a href="javascript:void(0)" @click="toggleDiff(data.item.id)" class="show-diff"
            ><font-awesome-icon :icon="{ prefix: 'fal', iconName: 'exchange' }" />{{
              isOpen(data.item.id) ? 'Collapse' : 'Compare'
            }}</a
          >
        </div>

        <b-row class="diff" :class="{ open: isOpen(data.item.id) }">
          <b-col sm="12" md="12" lg="6" class="deletions">
            <h3>Deletions</h3>
            <div v-html="data.item.deletionsHtml"></div>
          </b-col>
          <b-col sm="12" md="12" lg="6" class="additions">
            <h3>Additions</h3>
            <div v-html="data.item.additionsHtml"></div>
          </b-col>
        </b-row>
      </div>
    </template>

    <template v-slot:stub>
      <div class="d-flex stub" style="padding-bottom: 2.5rem">
        <div class="left" style="width: 5rem">
          <div class="line" style="width: 90%"></div>
          <div class="line" style="width: 50%"></div>
        </div>
        <div class="body" style="width: 100%">
          <div class="line" style="width: 50%"></div>
          <div class="line" style="width: 60%"></div>
          <div class="line" style="width: 20%"></div>
        </div>
      </div>
    </template>
  </FilterableList>
</template>

<style lang="scss">
.text-corrections {
  padding: 1rem;
}

.count {
  font-size: 2rem;
  font-weight: 500;
  color: colour(green);
  padding-right: 0.5rem;
}

.header-text {
  font-size: 2rem;
  padding-right: 0.5rem;
}

.leader-board {
  color: colour(grey-2);
  font-size: 1.5rem;
}

.additions .inserted {
  background-color: colour(green-tint60);
  text-decoration: underline;
  text-decoration-style: dashed;
}

.deletions .inserted {
  display: none;
}

.additions .deleted {
  display: none;
}

.deletions .deleted {
  background-color: colour(orange);
  text-decoration: line-through;
}
</style>

<style scoped lang="scss">
.wait-icon {
  margin-left: 0.5rem;
}

.metadata {
  width: 6rem;
  margin-right: 1rem;
  text-align: left;

  .line-count {
    display: block;
    color: colour(grey-3);
    font-size: 0.875rem;
  }

  .date {
    text-transform: uppercase;
    display: block;
  }
}

.summary {
  flex: 1;
  display: inline-block;
  padding-right: 0;
  padding-left: 0;

  .corrected-item-title {
    font-family: 'Source Serif Pro', serif;
    font-size: 1.375rem;
    font-weight: 600;
  }
  @supports (font-variation-settings: normal) {
    .corrected-item-title {
      font-family: 'Source Serif Variable', serif;
    }
  }
  .category {
    font-weight: bold;
    display: inline-block;

    svg {
      margin-right: 0.5rem;
    }
  }

  .date {
    color: colour(violet);
    display: inline-block;
    padding-left: 0.5rem;
    font-weight: 600;

    svg {
      margin-right: 0.5rem;
    }
  }

  .link {
    font-size: 0.8rem;
    padding-top: 0.3rem;
  }

  .show-diff {
    svg {
      margin-right: 0.5rem;
    }
  }
}

.diff {
  height: 0;
  background-color: colour(white);
  overflow: hidden;

  .after ::v-deep .inserted {
    background-color: #66b88f;
    text-decoration: underline;
  }

  &.open {
    height: auto;
    padding: 0.5rem;
    transition: max-height 0.5s ease;
    margin-left: 0.1rem;
    margin-top: 0.5rem;
  }

  h3 {
    font-size: 1rem;
  }
}
</style>

<script>
import _ from 'lodash'
import moment from 'moment'
import Diff from 'text-diff'

import service from '@/service/service'
import profile from '@/mixins/profile'
import auth from '@/mixins/auth'
import mappings from '@/mixins/mappings'
import navigation from '@/mixins/navigation'
import FilterableListPage from '@/components/profile/lists/FilterableListPage.vue'
import FilterableList from '@/components/profile/lists/FilterableList.vue'

const FILTER_DEFAULT = {
  timePeriod: null,
  articleId: null,
  number: 1,
}

export default {
  name: 'UserTextCorrections',
  extends: FilterableListPage,
  mixins: [profile, auth, mappings, navigation],
  components: {
    FilterableList,
  },
  props: {
    troveNewspapersUrlPrepend: {
      type: String,
      required: false,
      default: '/newspaper/article/',
    },
    profileInfo: {
      type: Object,
      required: true,
    },
    articleId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      leaderBoardRank: null,
      totalLeaderBoardPositions: -1,
      totalLinesCorrected: 0,
      open: [],
      dlcViewerUrl: null,
    }
  },
  metaInfo() {
    if (!_.isEmpty(this.usernameParam)) {
      return { title: this.cleanUsername() }
    } else {
      return { title: 'My profile' }
    }
  },
  mounted() {
    this.fetchArticleIdFromQuery()
    this.updateBreadcrumbs()
  },
  watch: {
    $route: {
      immediate: false,
      handler() {
        this.fetchArticleIdFromQuery()
      },
    },
  },
  computed: {
    filterConfigs() {
      return [
        {
          name: 'addedWithin',
          display: 'Added within',
          type: 'select',
          value: () => this.filter.timePeriod,
          input: (v) => (this.filter.timePeriod = v),
          options: this.timeIntervalOptions,
        },
        {
          name: 'article',
          type: 'pill',
          predicate: () => this.filter.articleId && !_.isEmpty(this.items),
          key: () => this.filter.articleId,
          value: () => this.items[0].articleTitle,
          close: this.clearArticleFilter,
        },
      ]
    },
    filterDefault() {
      return FILTER_DEFAULT
    },
  },
  methods: {
    fetchArticleIdFromQuery() {
      if (this.$route.query && this.$route.query.articleId) {
        this.filter.articleId = this.$route.query.articleId
      }
    },
    getArticleTitle(correction) {
      return _.isEmpty(correction.articleTitle) ? correction.parentTitle : correction.articleTitle
    },
    formatDate(date, long) {
      return moment(date).format(long ? 'dddd D MMMM YYYY' : 'DD MMM YYYY')
    },
    parseDate(date) {
      let m = date && date.match(/\d{4}-\d{2}-\d{2}/) ? date : undefined
      return m && m.length > 1 ? moment(date, 'YYYY-MM-DD') : undefined
    },
    getLinkForCorrection(correction) {
      let self = this

      if (correction.articleType === 'dlc') {
        return self.formatDlcLink(correction)
      }
      return self.troveNewspapersUrlPrepend + correction.articleId
    },
    formatDlcLink(correction) {
      let self = this

      const partPid = correction.articlePartPid
      const pid = correction.articlePid

      service.config.environment().then((response) => {
        self.dlcViewerUrl = response.dlcViewerUrl
      })

      return self.dlcViewerUrl + '/' + pid + '/view?partId=' + partPid
    },
    formatIcon(category) {
      return this.mapFormatIcon(_.toLower(category))
    },
    toggleDiff(id) {
      let self = this
      if (self.isOpen(id)) {
        self.open.splice(self.open.indexOf(id), 1)
      } else {
        self.open.push(id)
        let tc = self.items[_.findIndex(self.items, (c) => c.id === id)]
        tc.deletionsHtml = self.diff(tc.oldLines, tc.newLines, true, false)
        tc.additionsHtml = self.diff(tc.oldLines, tc.newLines, false, true)
      }
    },
    diff(before, after) {
      let diff = new Diff()
      let textDiff = diff.main(before, after)
      let html = diff.prettyHtml(textDiff)
      html = _.replace(html, /&lt;BR&gt;/g, '<br/>')
      html = _.replace(html, /<ins>/g, "<span class='inserted'>")
      html = _.replace(html, /<del>/g, "<span class='deleted'>")
      html = _.replace(html, /<\/ins>/g, '</span>')
      html = _.replace(html, /<\/del>/g, '</span>')

      return html
    },
    isOpen(id) {
      return this.open.indexOf(id) > -1
    },
    clearArticleFilter() {
      this.routeToOmitFromQuery('articleId')
      this.filter.articleId = null
    },
    updateBreadcrumbs() {
      let crumbs = []
      if (!_.isEmpty(this.usernameParam)) {
        crumbs.push({
          link: '/userProfile/' + this.usernameParam + '/notes',
          value: 'User profile',
        })
      } else {
        crumbs.push({ link: '/userProfile/corrections', value: 'My profile' })
      }

      this.$emit('update-breadcrumbs', crumbs, true)
    },
    getFromService(user, filter) {
      let self = this

      return service.profile.getTextCorrectionsForUser(user, filter).then((data) => {
        self.leaderBoardRank = data.leaderBoardSummary.position
        self.totalLeaderBoardPositions = data.leaderBoardSummary.totalPositions
        self.totalLinesCorrected = data.leaderBoardSummary.totalContributions

        let page = data.correctionsPage

        let items = _.map(page.content, item => {
          return _.assign({}, item, {
            lineCount: item.diffCount,
          })
        })

        return _.assign({}, page, { content: items })
      })
    },
    hasLongDateFormat(correction) {
      return correction.articleType !== 'dlc'
    },
    formatPageForCorrection(correction) {
      return `${correction.articleType === 'dlc' ? 'Image' : 'Page'} ${correction.page}`
    },
  },
}
</script>
