<template>
  <div class="field">
    <b-row>
      <b-col v-if="gridLayout" cols="12" sm="3">
        <span class="contentDetailLabel">{{ title }}</span>
      </b-col>
      <template v-else
        ><h2>{{ title }}</h2></template
      >
      <b-col cols="12" sm="9">
        <span
          :class="layout"
          v-for="(partial, index) in displayContent"
          :key="partial.value + random()">
          <span v-if="partial.inlineTitle">{{ partial.inlineTitle }}</span>

          <template v-if="partial.url">
            <b-link :href="partial.url" v-html="partial.value"></b-link>
          </template>
          <template v-else-if="partial.html">
            <span v-html="partial.html"></span>
          </template>
          <template v-else-if="code">
            <pre class="code"><code :class="code">{{partial.value}}</code></pre>
          </template>
          <PossibleLink
            v-else
            :linkResolver="routeResolver || partial.routeResolver"
            :route="partial.route"
            :label="partial.value"
            :key="partial.value + random()">
            <span v-html="partial.value"></span>
          </PossibleLink>

          <span v-if="!layout && index + 1 < displayContent.length">; </span>
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped lang="scss">
.field {
  margin-top: 2rem;

  h2 {
    padding-left: 15px;
  }
  .contentDetailLabel {
    color: #2d004b;
    font-size: 18px;
    font-weight: 600;
  }
  .partial-content {
    display: block;
  }

  .code {
    background: colour(grey);
    max-height: 50rem;
    overflow: scroll;
  }
}

//Make summary expand full width so they are stacked on top of each other /overwrite bootstrap
.summary-container {
  .col-sm-3,
  .col-sm-9 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

//specific rules for peoples page
.people-work {
  .more-details {
    .summary-container {
      &:first-child {
        margin-top: 2.5rem;
      }

      margin-top: 1.5rem;

      .col-sm-3 {
        max-width: none;
        flex: 0 0 35%;
      }
      .col-sm-9 {
        max-width: none;
        flex: 1 0 65%;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import PossibleLink from '@/components/common/PossibleLink.vue'

const HREF_PATTERN =
  /(https?:\/\/[-\w]+(\.\w[-\w]*)+(:\d+)?(\/[^.!,?;"<>()\s\x7F-\xFF]*([.!,?;]+[^.!,?;"<>()\s\x7F-\xFF]+)*)?)/
const HTML_PATTERN = /<.*?>.*?<\//

export default {
  name: 'Field',
  components: {
    PossibleLink,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      required: true,
    },
    orientation: {
      type: String,
      default: 'vertical',
    },
    routeResolver: {
      type: Function,
      required: false,
    },
    tryHref: {
      type: Boolean,
      required: false,
    },
    showHtml: {
      type: Boolean,
      required: false,
    },
    code: {
      type: String,
      required: false,
      default: undefined,
    },
    gridLayout: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    displayContent() {
      let self = this
      let mapContent = function (contentList) {
        return _(contentList)
          .filter(
            (c) =>
              (_.isString(c) && !_.isEmpty(c)) ||
              (_.isObject(c) && (!_.isEmpty(c.content) || !_.isEmpty(c.value)))
          )
          .map((partial) => {
            let content = partial
            if (_.isString(partial)) {
              content = {
                value: partial,
              }
            } else if (_.isObject(partial)) {
              content = {
                value: partial.content || partial.value,
                routeResolver: partial.routeResolver,
                route: partial.route,
                inlineTitle: partial.inlineTitle,
                url: partial.url,
              }
            }

            if (self.showHtml) {
              let match = content.value.match(HTML_PATTERN)
              if (match && match.length > 0) {
                content.html = content.value
              } else if (self.tryHref) {
                content.value = _.replace(
                  content.value,
                  HREF_PATTERN,
                  '<a href="$1" target=\'_blank\'>$1</a>'
                )
              }
            } else if (self.tryHref) {
              content.value = _.replace(
                content.value,
                HREF_PATTERN,
                '<a href="$1" target=\'_blank\'>$1</a>'
              )
            }

            return content
          })
          .value()
      }

      if (!_.isArray(self.content)) {
        return mapContent([self.content])
      }

      return mapContent(self.content)
    },
    layout() {
      return this.orientation === 'vertical' ? 'partial-content' : ''
    },
  },
  methods: {
    random() {
      return _.random(0, 99999) + ''
    },
  },
}
</script>
