<template>
  <div>
    <div class="error" v-if="error && error.summary">
      <Alert :alert="error"></Alert>
    </div>

    <div class="form" v-if="ready && captchaPassed">
      <ValidationObserver ref="observer" tag="div" key="addTag">
        <ValidationProvider rules="required" v-slot="{ errors, classes }" name="tag visibility">
          <b-form-group>
            <b-form-radio-group
                v-model="newTag.priv"
                :required="true"
                :class="classes"
                name="tagVisibility">
              <b-form-radio :value="false">Public</b-form-radio>
              <b-form-radio :value="true" :disabled="!authenticated"
              >Private
                <a href="javascript:void(0)" @click="login" v-if="!authenticated"
                >(login required)</a
                ></b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, classes }" name="tag name">
          <b-form-group label="Tag" label-class="font-weight-bold">
            <vue-bootstrap-autocomplete v-model="autocomplete"
                                        @hit="selectTag"
                                        @input="getAutocompleteMatches"
                                        :serializer="t => t.content + (t.priv ? ' (private)' : '')"
                                        v-if="authenticated"
                                        :minMatchingChars="1"
                                        :data="autocompleteMatches">
            </vue-bootstrap-autocomplete>
            <b-form-input v-else v-model="newTag.content" :class="classes" :required="true"></b-form-input>
          </b-form-group>
          <div class="hint">
            To add multiple tags, separate different tags with a comma. To include a comma in your
            tag, surround the tag with double quotes.
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>

        <div class="buttons" v-if="showButtons">
          <b-button variant="link" @click="cancel">Cancel</b-button>
          <b-button @click="addTag" :disabled="saving">Save</b-button>
        </div>
      </ValidationObserver>
    </div>

    <CaptchaModal
        :show-captcha="true"
        v-if="ready && !captchaPassed"
        @captcha-verified="captchaVerified"
        entity="tag"
        action="Add"
        @dismiss-modal="dismissCaptchaModal"></CaptchaModal>
  </div>
</template>

<style scoped lang="scss">
.form {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  background-color: colour(grey);

  .form-group {
    margin-bottom: 0.5rem;
  }

  .invalid {
    border: solid 1px colour(form-field-error);
  }

  .error {
    color: colour(form-field-error);
    margin-top: 1rem;
  }

  .buttons {
    text-align: right;
  }

  .list-field-label {
    font-weight: bold !important;
  }

  .hint {
    font-size: 0.8rem;
    color: colour(grey-1);
  }

  .custom-control-inline {
    margin-right: 2rem !important;
  }
}
</style>

<script>
import _ from 'lodash'
import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import { required } from 'vee-validate/dist/rules.umd'
import service from '@/service/service'
import auth from '@/mixins/auth'
import utils from '@/mixins/utils'
import CaptchaModal from '@/components/common/CaptchaModal.vue'
import Alert from '@/components/Alert.vue'
import tags from '@/mixins/tags'
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete';

setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_}',
})

export default {
  name: 'AddTag',
  mixins: [auth, tags, utils],
  components: {
    ValidationProvider,
    ValidationObserver,
    CaptchaModal,
    Alert,
    VueBootstrapAutocomplete
  },
  props: {
    targetWork: {
      type: Object,
      required: false,
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      newTag: {
        userId: undefined,
        priv: false,
        content: undefined
      },
      ready: false,
      captchaPassed: false,
      showCaptcha: false,
      saving: false,
      error: undefined,
      myTags: [],
      autocomplete: "",
      autocompleteMatches: []
    }
  },
  mounted() {
    this.captchaPassed = this.authenticated
    this.$emit('validator', this.validate)
    this.ready = true
    if (this.authenticated) {
      let self = this
      service.tags.getTagsForUser(this.fullUsername).then(tags => {
        self.myTags = tags;
      });
    }
  },
  watch: {
    newTag: {
      deep: true,
      handler() {
        if (this.newTag) {
          this.$emit('tags-updated', this.splitTags(this.newTag.content, this.newTag.priv))
        }
      },
    }
  },
  computed: {
  },
  methods: {
    selectTag(tag) {
      this.newTag.content = tag.content;
      this.newTag.priv = tag.priv;
      this.addTag();
      this.autocomplete = "";
      this.autocompleteMatches = [];
    },
    getAutocompleteMatches() {
      let matches = [];
      if (this.authenticated && this.myTags && this.myTags.length) {
        matches = _.sortBy(this.myTags.filter(t => t.content.toLowerCase().startsWith(this.autocomplete.toLowerCase())), "content");
        if (matches.length > 5) {
          matches = matches.slice(0, 5);
        }
      }
      this.autocompleteMatches = matches;
    },
    captchaVerified(openAddPanel) {
      let self = this
      self.captchaPassed = true
      Vue.nextTick(() => {
        self.addPanelOpen = openAddPanel
      })
    },
    dismissCaptchaModal() {
      let self = this
      self.captchaPassed = false
      this.cancel()
    },
    validate() {
      return this.$refs.observer.validate()
    },
    cancel() {
      this.$emit('cancel')
    },
    addTag() {
      let self = this

      if (!self.targetWork) {
        return
      }

      this.validate().then((isValid) => {
        if (isValid) {
          self.saving = true
          self.error = undefined

          var tags = self.splitTags(self.newTag.content || self.autocomplete, self.newTag.priv)

          let targets = [
            {
              id: self.targetWork.id,
              type: self.targetWork.workType,
            },
          ]

          self.$emit('user-achievement', undefined)
          service.tags
              .addTags(targets, tags)
              .then((response) => {
                self.saving = false

                if (response.userAchievement && response.userAchievement.achievements.length > 0) {
                  self.$emit('user-achievement', response.userAchievement)
                }

                self.$emit('tags-added', self.mapTags(response.items, self.targetWork.entityType))
              })
              .catch((error) => {
                self.saving = false
                self.handleError(error, 'tag', undefined, 'save your tag')
              })
        }
      })
    },
  },
}
</script>
