<script>
import PeopleResultCommon from '@/components/search/PeopleResultCommon'
import SearchResult from '@/components/search/SearchResult.vue'

export default {
  name: 'PeopleResult',
  extends: SearchResult,
  mixins: [PeopleResultCommon],
}
</script>
