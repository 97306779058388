<script>
import service from '@/service/service'

export default {
  name: 'LibraryMetadataMixin',
  data() {
    return {
      allLibraries: [],
    }
  },
  watch: {
    selectedCollectionId: {
      immediate: true,
      handler() {
        var self = this

        service.search.getSearchMetadata(self.selectedCollectionId).then((metadata) => {
          self.allLibraries = metadata.libraries || []
        })
      },
    },
  },
}
</script>
