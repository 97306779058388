<template>
  <div>
    <div class="checkbox">
      <b-form-checkbox v-model="checked" :value="true" :unchecked-value="false">{{
        checkboxText
      }}</b-form-checkbox>
    </div>
  </div>
</template>

<style scoped lang="scss">
.checkbox,
.select {
  font-size: small;
}

.checkbox {
  margin-bottom: 1rem;
}
</style>

<script>
export default {
  name: 'OptionalSelect',
  props: {
    originalChecked: {
      type: Boolean,
      required: false,
      default: false,
    },
    originalValue: {
      type: String,
      required: false,
      default: null,
    },
    checkboxText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checked: null,
      value: null,
    }
  },
  created() {
    this.$emit('clear-handler', this.clear)
  },
  methods: {
    clear() {
      this.checked = false
      this.value = null
    },
  },
  watch: {
    originalChecked: {
      immediate: true,
      handler() {
        this.checked = this.originalChecked
      },
    },
    originalValue: {
      immediate: true,
      handler() {
        this.value = this.originalValue
      },
    },
    checked() {
      if (!this.checked) {
        this.value = null
      }

      this.$emit('checkedUpdated', this.checked)
    },
    value() {
      this.$emit('valueUpdated', this.value)
    },
  },
}
</script>
