<template>
  <div class="thumbnail-container" :class="{ wrapped: type === 'wrapped' }">
    <div class="thumbnail" :class="[{ blurred: isBlurred, lazy: lazy }]" @click="toggleBlur">
      <template v-if="thumbnailUrl && showImage">
        <template v-if="isIEOrOldEdge && isBlurred">
          <svg class="blur-ie" :class="[type, size]">
            <defs>
              <filter id="blur">
                <feGaussianBlur stdDeviation="5" />
              </filter>
            </defs>
            <image :href="thumbnailUrl" width="100%" height="100%" filter="url(#blur)"></image>
          </svg>
        </template>
        <template v-else>
          <v-lazy-image
            v-if="lazy"
            ref="lazyImage"
            :class="[type, size]"
            :src="thumbnailUrl"
            :alt="title"
            :key="'Lazy' + key"
            @error="clearImage"
            @intersect="checkImage">
          </v-lazy-image>

          <img
            v-else
            :class="[type, size]"
            :src="thumbnailUrl"
            :alt="title"
            :key="'notLazy' + key"
            @error="clearImage" />
        </template>
      </template>
      <template v-else>
        <div
          v-if="showPlaceholder"
          class="fakeimage placeholder"
          :style="placeholderStyle"
          :class="[type, size]"></div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.thumbnail-container {
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;

  &.wrapped {
    height: 100%;
    width: 100%;

    .thumbnail {
      height: 100%;
      width: 100%;

      img,
      .fakeimage {
        height: 100%;
        width: 100%;
      }
    }
  }

  .thumbnail {
    overflow: hidden;
    border-radius: 5px;
    background-size: cover;
    background-position: center;

    img,
    .fakeimage,
    .blur-ie {
      border-radius: 5px;

      &.cover {
        height: auto;

        &.large {
          width: $thumb-cover-large-width;

          &.placeholder {
            height: $thumb-cover-large-placeholder-height;
          }
        }

        &.standard {
          width: $thumb-cover-standard-width;

          &.placeholder {
            height: $thumb-cover-standard-placeholder-height;
          }
        }

        &.small {
          width: $thumb-cover-small-width;

          &.placeholder {
            height: $thumb-cover-small-placeholder-height;
          }
        }
      }

      &.image {
        height: auto;

        &.large {
          width: $thumb-image-large-width;

          &.placeholder {
            height: $thumb-image-large-placeholder-height;
          }
        }

        &.standard {
          width: $thumb-image-standard-width;

          @media (max-width: 575px) {
            //30px for bootstrap container padding
            width: calc(100vw / 2 - 30px);
          }

          &.placeholder {
            height: $thumb-image-standard-placeholder-height;
          }
        }

        &.small {
          width: $thumb-image-small-width;

          &.placeholder {
            height: $thumb-image-small-placeholder-height;
          }
        }
      }

      &.portrait {
        height: auto;

        &.large {
          width: $thumb-portrait-large-width;

          &.placeholder {
            height: $thumb-portrait-large-placeholder-height;
          }
        }

        &.standard {
          width: $thumb-portrait-standard-width;

          &.placeholder {
            height: $thumb-portrait-standard-placeholder-height;
          }
        }

        &.small {
          width: $thumb-portrait-small-width;

          &.placeholder {
            height: $thumb-portrait-small-placeholder-height;
          }
        }
      }

      &.circle {
        height: auto;
        border: 1px solid colour(grey-6);

        &.large {
          width: $thumb-circle-large-width;
          height: $thumb-circle-large-width;
          border-radius: calc($thumb-circle-large-width / 2);

          &.placeholder {
            height: $thumb-circle-large-placeholder-height;
          }
        }

        &.standard {
          width: $thumb-circle-standard-width;
          height: $thumb-circle-standard-width;
          border-radius: calc($thumb-circle-standard-width / 2);

          &.placeholder {
            height: $thumb-circle-standard-placeholder-height;
          }
        }

        &.small {
          width: $thumb-circle-small-width;
          height: $thumb-circle-small-width;
          border-radius: calc($thumb-circle-small-width / 2);

          &.placeholder {
            height: $thumb-circle-small-placeholder-height;
          }
        }
      }
    }

    &.blurred {
      $blurAmount: 5px;
      filter: blur($blurAmount);
      -webkit-filter: blur($blurAmount);
      -moz-filter: blur($blurAmount);
      -o-filter: blur($blurAmount);
      -ms-filter: blur($blurAmount);
      transform: scale(1.1);
    }

    .blur-ie {
      display: block;
    }
  }
}
</style>

<script>
import colours from '@/mixins/colours'
import _ from 'lodash'
import VLazyImage from 'v-lazy-image'

export default {
  name: 'Thumbnail',
  mixins: [colours],
  components: {
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      required: false,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: false,
      default: 'cover',
      validator(val) {
        return ['cover', 'portrait', 'image', 'wrapped', 'circle'].indexOf(val) > -1
      },
    },
    size: {
      type: String,
      required: false,
      default: 'standard',
      validator(val) {
        return ['standard', 'large', 'small'].indexOf(val) > -1
      },
    },
    title: {
      type: String,
      required: false,
      default: 'Thumbnail image',
    },
    blur: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowUnblur: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPlaceholder: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      blurred: this.blur,
      showImage: true,
      hideBackground: false,
      key: _.random(0, 100000),
      thumbnailUrl: this.url,
      retried: false,
    }
  },
  watch: {
    url: function (newVal) {
      if (!this.lazy) {
        this.checkImageDimensions(newVal)
      }
    },
  },
  mounted() {
    if (!this.lazy) {
      this.checkImageDimensions(this.url)
    }
  },
  computed: {
    isIEOrOldEdge() {
      let match = navigator.userAgent.match(/\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/)
      return (
        match &&
        match.length >= 2 &&
        (match[2] === '11' || (match[1].indexOf('Edge') > -1 && _.toNumber(match[2]) < 18))
      )
    },
    isBlurred() {
      return this.blur && this.thumbnailUrl && this.showImage
    },
    canToggleBlur() {
      return this.blur && this.allowUnblur
    },
    placeholderColour() {
      let num = 0
      let str = this.url || this.title || ''
      for (var i = 0; i < str.length; i++) {
        num += str.charCodeAt(i)
      }

      let colour
      if (num === 0) {
        colour = this.randomColourValue()
      } else {
        colour = this.colour(this.backgroundColourNames[num % this.backgroundColourNames.length])
      }

      return colour
    },
    placeholderStyle() {
      return {
        'background-color': this.placeholderColour,
      }
    },
  },
  methods: {
    checkImageDimensions(url) {
      let img = new Image()
      img.src = url
      img.onload = () => {
        if (img.naturalWidth > 1 && img.naturalHeight > 1) {
          this.thumbnailUrl = img.src
          this.showImage = true

          this.$nextTick(() => {
            let realImage = document.querySelector('.thumbnail img')
            this.$emit('load-succeeded', {
              width: realImage.width,
              height: realImage.height,
            })
          })
        } else {
          this.clearImage()
          this.$emit('load-failed')
        }
      }
      img.onerror = () => {
        let proxyUrl = img.src.match(/proxy\?url=http:(.+)&md5=/i)
        if (proxyUrl && proxyUrl.length === 2 && !this.retried) {
          this.retried = true
          this.thumbnailUrl = 'https:' + decodeURI(proxyUrl[1])
          this.showImage = false
          this.checkImageDimensions(this.thumbnailUrl)
        } else {
          this.clearImage()
          this.$emit('load-failed')
        }
      }
    },
    clearImage() {
      this.showImage = false
      this.thumbnailUrl = undefined
    },
    toggleBlur() {
      if (this.canToggleBlur) {
        this.blurred = !this.blurred
      }
    },
    checkImage() {
      let self = this
      self.checkImageDimensions(self.thumbnailUrl)
    },
  },
}
</script>
