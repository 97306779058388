<template>
  <div class="landing-view">
    <b-container>
      <SearchKeyhole
        v-if="isHomePage"
        :collections="collections"
        :headline="config.bannerTopText"
        :footerText="config.bannerBottomText"
        :footerTextCallToActionText="config.bannerBottomCallToAction"
        :footerTextCallToActionLink="config.bannerBottomLink"
        :footerLogoLeft="config.bannerBottomImage"
        :footerLogoLeftAlt="config.bannerBottomImageAlt"
        :footerLogoRight="config.bannerBottomImage1"
        :footerLogoRightAlt="config.bannerBottomImage1Alt"
        :keyholeImage="config.image"
        :keyholeImageAlt="config.imageAlt"
        @collection-changed="selectedCollectionUpdated"
        @search="search" />

      <div v-else class="basic-search">
        <BasicSearch
          :collections="collections"
          :display-collections="true"
          displayAdvancedSearch
          @collection-changed="selectedCollectionUpdated"
          @search="search" />
      </div>
    </b-container>

    <template v-if="isHomePage">
      <b-container>
        <FeaturedArticles
          v-if="isFeaturedArticlesAvailable"
          :items="config.secondaryFeatures"
          :mainArticle="mainArticle"
          :homePage="isHomePage"
          :title="config.name"
          class="section" />

        <AdditionalComponents
          v-if="isAdditionalComponentsAvailable"
          i
          :title="config.additionalComponentsTitle"
          :items="config.additionalComponents"
          :homePage="isHomePage"
          class="section" />

        <b-row class="community-day">
          <b-col xs="12" lg="6">
            <BodyText
              v-if="hasCallToAction"
              :title="config.bodyTextBlockTitle"
              :item="config.callToAction[0]" />
          </b-col>
          <b-col xs="12" lg="6">
            <Trivia
              v-if="hasTroveTrivia"
              :title="config.troveTrivia[0].troveTriviaTitle"
              :item="config.troveTrivia[0]" />
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-else>
      <b-container class="article-horizontal">
        <AdditionalComponents
          v-if="isAdditionalComponentsAvailable"
          :title="config.additionalComponentsTitle"
          :items="config.additionalComponents"
          :homePage="isHomePage" />
        <div class="bevel flex-grow-1"></div>
      </b-container>
      <b-container class="landing">
        <FeaturedArticles
          v-if="isFeaturedArticlesAvailable"
          :items="config.secondaryFeatures"
          :mainArticle="mainArticle"
          :homePage="isHomePage"
          :title="config.name"
          class="section" />
        <div class="previous d-flex align-content-center"></div>
      </b-container>
    </template>

    <CulturalModal />
  </div>
</template>

<style scoped lang="scss">
.landing-view {
  overflow: hidden;

  .section {
    margin-top: 4em;
  }

  .bevel {
    height: 20px;
    border-left: 0 solid #f4f4f4;
    border-top: 48px solid #f4f4f4;
    border-right: 0.80001em solid transparent;
    margin-top: 2em;
  }

  .landing {
    margin-bottom: 4em;
  }

  .community-day {
    .col-lg-6 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .article-horizontal {
    margin-top: 4em;
    h1.bevel {
      margin-top: 4em;
    }
  }

  .basic-search {
    .advanced-search {
      div {
        select,
        .down {
          color: colour(purple);
        }
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import service from '@/service/service'
import SearchKeyhole from '@/components/home/SearchKeyhole.vue'
import FeaturedArticles from '@/components/home/FeaturedArticles.vue'
import AdditionalComponents from '@/components/landing/AdditionalComponents.vue'
import BodyText from '@/components/home/BodyText.vue'
import Trivia from '@/components/home/Trivia.vue'
import CulturalModal from '@/components/cultural/CulturalModal.vue'
import BasicSearch from '@/components/search/BasicSearch.vue'

export default {
  name: 'LandingView',
  components: {
    SearchKeyhole,
    BasicSearch,
    FeaturedArticles,
    AdditionalComponents,
    BodyText,
    Trivia,
    CulturalModal,
  },
  metaInfo() {
    return { title: this.config.name }
  },
  props: {
    landingId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      collections: [],
      config: {},
      selectedCollection: null,
    }
  },
  computed: {
    isHomePage() {
      return this.landingId.toLowerCase() === this.globalTroveConfig.homePageType.toLowerCase()
    },
    isFeaturedArticlesAvailable() {
      return this.config.featuredArticle && !_.isEmpty(this.config.secondaryFeatures)
    },
    isAdditionalComponentsAvailable() {
      return !_.isEmpty(this.config.additionalComponents)
    },
    hasCallToAction() {
      return !_.isEmpty(this.config.callToAction)
    },
    hasTroveTrivia() {
      return !_.isEmpty(this.config.troveTrivia)
    },
    mainArticle() {
      return _.isArray(this.config.featuredArticle)
        ? this.config.featuredArticle[0]
        : this.config.featuredArticle
    },
  },
  watch: {
    landingId: {
      immediate: true,
      handler() {
        this.initLandingPage()
      },
    },
  },
  methods: {
    initLandingPage() {
      let self = this
      if (this.landingId) {
        service.landing.getCategories().then((categories) => {
          self.collections = categories
        })

        service.landing.getPage(this.landingId).then((landingPageConfig) => {
          if (landingPageConfig) {
            self.config = landingPageConfig
            self.$emit('update-breadcrumbs', this.isHomePage ? [] : [{ value: self.config.name }])
          }
        })
      }
    },
    selectedCollectionUpdated(selectedCollection) {
      this.selectedCollection = selectedCollection
    },
    search(query) {
      var self = this
      if (!query || !query.keyword || _.isEmpty(_.trim(query.keyword))) {
        return
      }

      this.$router.push({
        name: self.selectedCollection ? 'Search' : 'SearchAll',
        params: self.selectedCollection
          ? {
              urlNamespace: self.selectedCollection.urlNamespace,
              urlKey: self.selectedCollection.urlKey,
            }
          : {},
        query: query,
      })
    },
  },
}
</script>
