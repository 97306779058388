export default {
  methods: {
    mapAvailableUrlForList(url) {
      return {
        name: url.text,
        key: url.key,
        url: url.url,
        highPriority: url.highPriority,
        target: url.nla ? '_self' : '_blank',
        hideExternalIcon: url.nla,
        nuc: url.nuc,
        fields: [
          {
            text: url.format,
          },
          {
            text: url.language,
          },
          {
            text: url.year,
          },
          {
            text: 'Access conditions apply',
            hide: !url.hasConditions,
          },
        ],
      }
    },
  },
}
