export default {
  computed: {
    usernameParam() {
      return this.$route.params.username
    },
    isMyProfile() {
      return (
        !this.usernameParam ||
        this.usernameParam === this.simpleUsername ||
        this.usernameParam === this.fullUsername
      )
    },
    canViewPrivateProfiles() {
      return this.profileInfo && this.profileInfo.profileViewerAccess
    },
  },
}
