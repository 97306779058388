<template>
  <div class="mutex-list" v-if="hasOptions">
    <template v-if="items.length === 1">
      <h4 class="list-title">{{ items[0].longName }}</h4>
    </template>
    <template v-if="items.length > 1">
      <b-button
        v-for="item in items"
        pill
        variant="outline-primary"
        :pressed="selectedItem === item"
        :key="item.name"
        @click="selectedItem = item"
        class="item-button">
        <span class="list-title">{{ item.name }}</span
        ><span class="item-count" v-if="item.count !== null">{{ item.count }}</span>
      </b-button>
    </template>
  </div>
</template>

<style scoped lang="scss">
.mutex-list {
  padding-bottom: 20px;

  .item-button {
    margin-right: 10px;
    margin-bottom: 1em;
    border: 2px solid colour(purple);
    color: colour(purple);
    transition: 0.5s ease-in-out;
    &:hover {
      background: colour(purple);
      color: colour(white);
    }
    &:not(.disabled).active {
      background: colour(purple);
      color: colour(white);
      box-shadow: none;
    }

    .item-count {
      margin-left: 10px;
      opacity: 0.8;
    }

    .list-title {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'MutexList',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: null,
    }
  },
  created() {
    this.selectedItem = this.items[0]
  },
  watch: {
    selectedItem: {
      immediate: false,
      handler() {
        this.$emit('item-selected', this.selectedItem)
      },
    },
  },
  computed: {
    hasOptions() {
      return !_.isEmpty(this.items)
    },
  },
}
</script>
