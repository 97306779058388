<template>
  <div class="controls">
    <div class="expiry-label" v-if="!alertOnly">{{expiry}}</div>
    <Alert :alert="error" v-if="error && error.summary" class="normal-alert"></Alert>
    <div class="control-row" v-if="alertOnly" :class="{'alert': expired || expiringSoon || deactivated}">
      <div class="text" v-if="(expired || expiringSoon || deactivated)">
        <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-triangle' }" class="icon"/>
        <span v-if="expired">Your API Key has expired.</span>
        <span v-if="expiringSoon">Your API Key will expire soon.</span>
        <span v-if="deactivated">Your API Key has been deactivated.</span>
      </div>
    </div>

    <div class="control-row" v-if="!alertOnly">
      <div class="buttons">
        <b-link class="control renew-key-link" v-if="expiringSoon">
          <div @click="showWarning('renew')">Renew my API Key</div>
        </b-link>

        <b-link class="control regenerate-key-link" v-if="expired">
          <div @click="showWarning('regen')">Renew my API Key</div>
        </b-link>

        <b-link class="control delete-key-link" v-if="!deactivated">
          <div @click="showWarning('delete')">Delete my API Key</div>
        </b-link>
      </div>
    </div>

    <div class="warning-container">
      <div class="warning delete-warning" :class="{ open: deleteWarningOpen }">
        <h2 class="caution">
          <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-triangle' }"/>
          Caution
        </h2>
        <p>You are about to permanently delete your Trove API Key! You will not be able to use the Trove API with this
          key again.</p>
        <p>To use the Trove API, you will need to request a new API Key.</p>
        <div class="buttons">
          <b-button variant="link" @click="cancel">Cancel</b-button>
          <b-button @click="deleteKey" class="continue-link delete-key-link">I understand, delete my API
            Key
          </b-button>
        </div>
      </div>

      <div class="warning regen-warning" :class="{ open: regenWarningOpen }">
        <h2>
          Renew your expired API key
        </h2>
        <p>This will reactive your expired API key.</p>
        <p>If you would like to create a new API key, simply delete your expired key and apply for a new one.</p>
        <div class="buttons">
          <b-button variant="link" @click="cancel">Cancel</b-button>
          <b-button  @click="renewKey" class="continue-link">Continue</b-button>
        </div>
      </div>

      <div class="warning renew-warning" :class="{ open: renewWarningOpen }">
        <h2>
          Renew your API key
        </h2>
        <p>This will renew your current API key for another 12 months.</p>
        <div class="buttons">
          <b-button variant="link" @click="cancel">Cancel</b-button>
          <b-button  @click="renewKey" class="continue-link">Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.controls {
  max-width: 820px;

  .normal-alert {
    text-align: left;
  }

  .control-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &.alert {
      margin-top: 0;
      border: 1px solid colour(yellow);
      background:  colour(yellow);

      .icon {
        color: colour(black);
      }
    }

    .text {
      margin-right: 1rem;

      .icon {
        margin-right: 1rem;
      }
    }

    .buttons {
      flex: 1;
      margin-top: 0.5rem;

      .control {
        display: inline-block;
        margin-right: 1rem;
        white-space: nowrap;
      }
    }
  }

  .delete-key-link {
    color: colour(magenta);
  }

  .warning-container {
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    .warning {
      border-radius: 4px;
      text-align: left;
      max-height: 0;
      overflow: hidden;
      background-color: colour(yellow-tint60);
      max-width: 820px;

      &.open {
        padding: 1rem;
        max-height: 500px;
        transition: max-height ease-in-out 300ms;

        h2 {
          font-size: 1.1rem;
          margin-bottom: 1rem;
          font-family: 'Source Sans Pro', sans-serif;

          &.caution {
            font-size: 1.2rem;
          }

          svg {
            margin-right: 0.5rem;
          }
        }

        @supports (font-variation-settings: normal) {
          h2 {
            font-family: 'Source Sans Variable', sans-serif;
          }
        }

        .buttons {
          text-align: right;
          margin-top: 0.75rem;

          .delete-key-link {
            color: colour(white);

            &:hover {
              color: colour(purple);
            }
          }
        }

        .btn-link {
          font-weight: 400;
        }

        .continue-link {
        }
      }
    }
  }
}
</style>

<script>
import moment from 'moment';
import service from '@/service/service';
import scroll from 'vue-scrollto';
import Alert from '@/components/Alert.vue'

export default {
  name: 'APIControls',
  components: {Alert},
  props: {
    apiDetails: {
      type: Object,
      required: true
    },
    alertOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deleteWarningOpen: false,
      regenWarningOpen: false,
      renewWarningOpen: false
    }
  },
  computed: {
    expiry() {
      return this.apiDetails.keyStatus === 'E' ? "Your API Key has expired" : this.apiDetails.expiryDate;
    },
    expired() {
      return this.apiDetails.keyStatus === 'E';
    },
    deactivated() {
      return this.apiDetails.keyStatus === 'Z';
    },
    expiringSoon() {
      return !this.expired && !this.deactivated && moment(this.apiDetails.expiryDate, "DD MMM YYYY").subtract(1, 'months').isBefore(moment());
    }
  },
  methods: {
    showWarning(warning) {
      this.deleteWarningOpen = warning === 'delete';
      this.regenWarningOpen = warning === 'regen';
      this.renewWarningOpen = warning === 'renew';
    },
    cancel() {
      this.deleteWarningOpen = false;
      this.regenWarningOpen = false;
      this.renewWarningOpen = false;
      this.error = undefined;
    },
    renewKey() {
      let self = this;
      service.profile.renewOrRegenAPIKey().then((d) => {
        self.$emit('update-details', d);
        self.cancel();
      }).catch(e => {
        self.handleError(e, undefined, undefined, 'renew your API Key')
      });
    },
    deleteKey() {
      let self = this;
      service.profile.deleteAPIKey().then((d) => {
        self.$emit('update-details', d);
        self.cancel();
        self.$nextTick(() => scroll.scrollTo('body', 500, {easing: 'ease-out'}));
      }).catch(e => {
        self.handleError(e, undefined, undefined, 'delete your API Key')
      });
    }
  }
}
</script>