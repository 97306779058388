<template>
  <div class="user-thumbnail" :class="{ 'with-image': showImage }">
    <template v-if="linkUsername && !userSummary.anonymous">
      <router-link v-if="linkUsername && userId" :to="routeLink">
        <h1 v-if="showUsername && userId && heading">
          {{ cleanUsername(userId) }}
        </h1>
        <span v-if="showUsername && userId && !heading">{{ cleanUsername(userId) }}</span>
      </router-link>
    </template>
    <template v-else>
      <h1 v-if="showUsername && userId && heading">
        {{ cleanUsername(userId) }}
      </h1>
      <span v-if="showUsername && userId && !heading">{{ cleanUsername(userId) }}</span>
    </template>
  </div>
</template>
<style scoped lang="scss">
.user-thumbnail {
  display: inline-block;

  &.with-image {
    vertical-align: middle;
  }

  .thumbnail {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  h1,
  h2 {
    font-size: 50px;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    margin-left: 1rem;
  }

  .thumbnail-placeholder {
    height: auto;
    text-align: center;
    color: colour(grey-4);
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;

    svg {
      vertical-align: top;
      margin-top: -1px;
      margin-left: -1px;
      color: colour(grey-5);
    }

    &.large {
      width: $thumb-circle-large-width;
      height: $thumb-circle-large-width;
      border-radius: calc($thumb-circle-large-width / 2);

      &.thumbnail-placeholder {
        height: $thumb-circle-large-placeholder-height;
        font-size: $thumb-circle-large-placeholder-height;

        svg {
          vertical-align: top;
          margin-top: -1px;
          margin-left: -1px;
          color: colour(grey-5);
        }
      }
    }

    &.standard {
      width: $thumb-circle-standard-width;
      height: $thumb-circle-standard-width;
      border-radius: calc($thumb-circle-standard-width / 2);

      &.thumbnail-placeholder {
        height: $thumb-circle-standard-placeholder-height;
        font-size: $thumb-circle-standard-placeholder-height;

        svg {
          vertical-align: top;
          margin-top: -1px;
          margin-left: -1px;
          color: colour(grey-5);
        }
      }
    }

    &.small {
      width: $thumb-circle-small-width;
      height: $thumb-circle-small-width;
      border-radius: calc($thumb-circle-small-width / 2);

      &.thumbnail-placeholder {
        height: $thumb-circle-small-placeholder-height;
        font-size: $thumb-circle-small-placeholder-height;

        svg {
          vertical-align: top;
          margin-top: -1px;
          margin-left: -1px;
          color: colour(grey-5);
        }
      }
    }
  }
}
</style>

<script>
import Thumbnail from '@/components/Thumbnail'
import auth from '@/mixins/auth'

export default {
  name: 'UserThumbnail',
  mixins: [auth],
  components: {
    Thumbnail,
  },
  props: {
    userSummary: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'standard',
      validator(val) {
        return ['standard', 'large', 'small'].indexOf(val) > -1
      },
    },
    showImage: {
      type: Boolean,
      required: false,
      default: true,
    },
    placeholderIcon: {
      type: Object,
      required: false,
      default: () => {
        return { prefix: 'fas', iconName: 'user-circle' }
      },
    },
    showUsername: {
      type: Boolean,
      required: false,
      default: true,
    },
    linkUsername: {
      type: Boolean,
      required: false,
      default: true,
    },
    heading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      imageUrl: this.url,
    }
  },
  mounted() {
    this.createImageUrl()
  },
  watch: {
    user: {
      deep: true,
      handler: () => {
        let self = this
        self.$nextTick(() => {
          self.createImageUrl()
        })
      },
    },
  },
  computed: {
    routeLink() {
      return {
        name: 'PublicProfile',
        params: { username: this.userId },
      }
    },
    userId() {
      return this.userSummary.fullUserId
    },
  },
  methods: {
    clearThumbnail() {
      this.imageUrl = undefined
    },
    createImageUrl() {
      let cacheBuster = Math.ceil(Math.random() * 10000000)
      this.imageUrl =
        this.userId && this.userSummary.profileImageAvailable
          ? '/api/profile/' + encodeURIComponent(this.userId) + '/avatar?a=' + cacheBuster
          : undefined
    },
  },
}
</script>
