import { render, staticRenderFns } from "./Term.vue?vue&type=template&id=38852824&scoped=true&"
import script from "./Term.vue?vue&type=script&lang=js&"
export * from "./Term.vue?vue&type=script&lang=js&"
import style0 from "./Term.vue?vue&type=style&index=0&id=38852824&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38852824",
  null
  
)

export default component.exports