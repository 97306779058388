<template>
  <div class="user-notification">
    <div class="date">{{ formattedDate }}</div>
    <div class="details">
      <UserThumbnail :user-summary="details.oldOwner" size="small"></UserThumbnail>
      has made you the owner of the list "<router-link
        :to="listRoute"
        @click.native="clearNotification"
        >{{ details.listTitle }}</router-link
      >".
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-notification {
  ul {
    padding-left: 3rem;
  }
}
</style>

<script>
import AbstractNotificationDetail from './AbstractNotificationDetail'
import UserThumbnail from '@/components/profile/UserThumbnail'

export default {
  name: 'ListOwnerChanged',
  extends: AbstractNotificationDetail,
  components: {
    UserThumbnail,
  },
  computed: {
    listRoute() {
      return {
        name: 'ManageList',
        params: { id: this.details.listId },
      }
    },
  },
}
</script>
