<template>
  <div class="version-container">
    <router-link :to="versionLinkDescriptor">
      <Thumbnail
        :url="version.thumbnailUrl"
        :title="'Cover for ' + version.title"
        :blur="blur"
        :key="'thumb' + version.id">
      </Thumbnail>
    </router-link>
    <div class="title">
      <router-link :to="versionLinkDescriptor">{{ version.title }}</router-link>
    </div>
    <div class="bottom d-flex align-items-center">
      <span class="year flex-grow-1">{{ yearRange }}</span>
      <OnlineAvailability
        v-if="version"
        :availability="
          version.onlineText ||
          (version.availability && version.availability.length > 0 ? version.availability[0] : '')
        "></OnlineAvailability>
    </div>
  </div>
</template>

<style scoped lang="scss">
$thumbnail-dimension: 80px;
$thumbnail-max-aspect-ratio: 1.5;

.version-container {
  width: 33%;
  padding-right: 2rem;
  padding-bottom: 2rem;
  font-weight: 600;
  font-size: 0.9rem;

  @media (max-width: 575px) {
    padding-right: 1rem;
  }

  .title {
    font-family: 'Source Serif Pro', serif;
    padding-top: 0.5rem;
    padding-bottom: 0.8rem;
  }

  @supports (font-variation-settings: normal) {
    .title {
      font-family: 'Source Serif Variable', serif;
    }
  }

  .year {
    padding-right: 20px;
  }

  .bottom {
    width: 100%;
    min-height: 2.2rem;
    background-color: colour(grey);
    font-size: small;
    font-weight: 600;
    padding: 0.5rem;

    @media (max-width: 575px) {
      flex-wrap: wrap;
    }
    .online {
      color: colour(purple);
      margin-right: 0;

      svg {
        margin-right: 0.5em;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'

import colours from '@/mixins/colours'
import currentRoute from '@/mixins/currentRoute'
import cultural from '@/mixins/cultural'
import OnlineAvailability from '@/components/search/OnlineAvailability.vue'
import Thumbnail from '@/components/Thumbnail.vue'

export default {
  name: 'Version',
  mixins: [colours, cultural, currentRoute],
  props: {
    version: {
      type: Object,
      required: true,
    },
    parentWorkId: {
      type: String,
      required: true,
    },
    includeraw: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    OnlineAvailability,
    Thumbnail,
  },
  computed: {
    blur() {
      return this.version.culturallySensitive && this.suppressContent(this.parentWorkId)
    },
    versionLinkDescriptor() {
      return {
        name: 'Version',
        params: {
          id: this.parentWorkId,
          versionId: this.version.id,
        },
        query: this.includeraw
          ? Object.assign({}, this.currentQuery, { includeraw: 'y' })
          : this.currentQuery,
      }
    },
    yearRange() {
      let range = ''

      if (this.version.yearRange && this.version.yearRange.length > 0) {
        range = _.join(
          _.sortBy(this.version.yearRange, (y) => -y),
          ', '
        )
      } else if (this.version.year && this.version.year.length > 0) {
        range = _(this.version.year)
          .sortBy((year) => year)
          .values()
          .join(' - ')
      }

      return range
    },
  },
}
</script>
