<template>
  <div>
    <div class="top">
      <b-container>
        <b-row class="pt-4 pb-4">
          <b-col sm="4" lg="5" class="acknowledgement">
            <template v-if="acknowledgementOfCountry">
              <h1>{{ acknowledgementOfCountry.title }}</h1>
              <p>{{ acknowledgementOfCountry.body }}</p>
              <p>
                <a :href="acknowledgementOfCountry.actionLink">{{
                  acknowledgementOfCountry.action
                }}</a>
              </p>
            </template>
          </b-col>
          <b-col cols="6" sm="4" class="newsletter separator-left">
            <template v-if="newsletterWidget">
              <h1>{{ newsletterWidget.title }}</h1>
              <p v-if="newsletterWidget.body">{{ newsletterWidget.body }}</p>
              <p>
                <a :href="newsletterWidget.actionLink">{{ newsletterWidget.action }}</a>
              </p>
            </template>

            <div class="social" v-if="social">
              <div class="d-flex flex-row social-links" role="list">
                <div role="listitem">
                  <a :href="social.facebook" class="social-link mr-3"
                    ><span class="sr-only">Facebook</span>
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }" />
                  </a>
                </div>
                <div role="listitem">
                  <a :href="social.twitter" class="social-link mr-3"
                    ><span class="sr-only">Twitter</span>
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" />
                  </a>
                </div>
                <div role="listitem">
                  <a :href="social.youtube" class="social-link"
                    ><span class="sr-only">YouTube</span>
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }" />
                  </a>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="6" sm="4" lg="3" class="links separator-left pl-4 pr-4">
            <ul>
              <li v-for="link in commonLinks" :key="link.title">
                <a :href="link.url">{{ link.title }}</a>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="middle">
      <b-container>
        <b-row
          align-v="center"
          no-gutters
          class="pt-4 pb-4 library-logo partners"
          align-h="between"
          v-if="partner">
          <b-col md="3" xl="2" class="nla-logo">
            <b-img :src="partner.image" :alt="partner.imageAlt" />
          </b-col>
          <b-col class="partner-content">
            <p>
              {{ partner.body }} <br /><a :href="partner.actionLink" v-if="partner.actionLink">{{
                partner.action
              }}</a>
            </p>
          </b-col>
          <b-col md="3" xl="2" class="trove-partners-logo text-right">
            <b-img :src="partner.image1" :alt="partner.imageAlt1" />
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="bottom">
      <b-container>
        <b-row no-gutters>
          <b-col>
            <div class="d-flex flex-row bottom-links">
              <ul>
                <li v-for="link in bottomLinks" :key="link.url">
                  <a :href="link.url">{{ link.title }}</a>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tab div:last-child {
  background-color: colour(grey-4);
  color: colour(white);
  padding-top: 1em;
  padding-bottom: 1em;
}

h1 {
  font-family: 'Source Serif Pro', serif;
  font-size: 1.3em;
  font-weight: 700;
}

@supports (font-variation-settings: normal) {
  h1 {
    font-family: 'Source Serif Variable', serif;
  }
}

.top {
  background-color: colour(grey-4);
  color: colour(white);
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  p {
    font-size: 1rem;
  }
  .links {
    margin-top: 1.25rem;

    ul {
      padding-left: 0;
    }
    li {
      list-style-type: none;
      font-size: 1em;
      padding-top: 0.5em;
      @media (max-width: 575px) {
        font-size: 0.8em;
      }
    }
  }
  .social {
    margin-top: 2em;
    h1 {
      margin-right: 0.8em;
      margin-top: 0.6em;
    }
    .social-links {
      font-size: 2em;
    }
    a.social-link {
      border-bottom: none;
      color: #f8f8f8;
      transition: none;

      &:after {
        content: none;
      }
    }
    a.social-link:hover,
    a.social-link:focus {
      color: colour(white);
    }
  }
  @media (min-width: 575.99px) {
    .acknowledgement {
      padding-right: 25px;
    }
    .newsletter {
      padding-left: 25px;
      padding-right: 25px;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
    .links {
      padding-left: 25px;
    }
  }
  @media (max-width: 575.98px) {
    .acknowledgement {
      margin-bottom: 1.5em;
    }
    .newsletter {
      padding-right: 25px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
    .links {
      padding-left: 25px;
    }
    .social {
      margin-top: 1.5em;
    }
  }
}

.middle {
  background-color: colour(grey-1);
  color: colour(white);
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .partners {
    flex-wrap: wrap;
  }
  p {
    margin-bottom: 0;
    font-size: 1rem;
    text-align: center;
  }
  .nla-logo {
    min-width: 150px;
    img {
      height: 40px;
    }
  }
  .trove-partners-logo {
    min-width: 150px;
    img {
      height: 40px;
    }
  }
  @media (max-width: 767.98px) {
    height: 13rem;
    padding: 1rem 0;
    p {
      margin-bottom: 1.5em;
    }
    .partner-content {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      order: 1;
    }
    .nla-logo {
      order: 2;
    }
    .nla-logo,
    .trove-partners-logo {
      width: 50%;
    }
    .trove-partners-logo {
      order: 3;
    }
  }
}

.bottom {
  background-color: colour(black);
  color: colour(white);
  margin-right: 0;
  margin-left: 0;
  font-size: 0.875rem;
  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    li {
      display: inline-block;
      margin-right: 1.3em;
      line-height: 64px;
      @media (max-width: 575.98px) {
        line-height: 45px;
      }
    }
  }
  a {
    font-weight: 400;
    color: colour(white);
  }
  a:hover,
  a:focus {
    text-decoration: underline;
  }
}

.acknowledgement,
.newsletter,
.links,
.middle {
  a {
    position: relative;
    color: colour(white);
    font-weight: 600;
    &:after {
      position: absolute;
      content: '';
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 100%;
      background: rgba(255, 255, 255, 0.2);
      height: 2px;
      bottom: -3px;
    }
  }
  a:hover,
  a:focus {
    text-decoration: none;
    color: colour(white);
    transition: 0.5s ease-in-out;
    &:after {
      background: colour(white);
    }
  }
}

.email {
  border-bottom: 4px solid colour(white);
  padding-bottom: 0.8em;

  input {
    color: colour(white);
    background: 0;
    border: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0;
  }
  input::placeholder {
    font-family: 'Source Serif Pro', serif;
    color: rgba(255, 255, 255, 0.8);
    font-style: italic;
  }
  @supports (font-variation-settings: normal) {
    input::placeholder {
      font-family: 'Source Serif Variable', serif;
    }
  }

  input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  button {
    background-color: colour(white);
    border: 2px solid colour(white) !important;
    color: colour(grey-4);
    font-weight: 700;
    padding-left: 1.2em;
    padding-right: 1.2em;
    white-space: nowrap;
    font-size: 0.8em;
    transition: 0.5s ease-in-out;
  }
  button:hover,
  button:focus {
    background-color: colour(blue) !important;
    color: colour(white) !important;
  }
}
</style>

<script>
import Vue from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

library.add(faFacebookF, faTwitter, faYoutube, faAngleDown, faAngleUp)

Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  name: 'TroveFooter',
  props: {
    collapsable: {
      type: Boolean,
      required: false,
      default: true,
    },
    commonLinks: {
      type: Array,
      required: false,
    },
    bottomLinks: {
      type: Array,
      required: false,
    },
    social: {
      type: Object,
      required: false,
    },
    partner: {
      type: Object,
      required: false,
    },
    newsletterWidget: {
      type: Object,
      required: false,
    },
    acknowledgementOfCountry: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      height: 0,
    }
  },
  mounted() {
    this.height = this.$el.scrollHeight
  },
  computed: {
    networks() {
      return {
        youtube: {
          sharer: this.social.youtube,
          type: 'direct',
        },
      }
    },
  },
  methods: {
    joinNewsletter() {},
  },
}
</script>
