<style scoped lang="scss">
.modal {
  .results {
    margin-bottom: 1rem;
    max-height: 20rem;
    overflow: scroll;

    .result {
      background-color: colour(grey);
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      width: 100%;

      .fieldName {
        width: 80px;
      }
    }
  }

  .target-radio {
    margin-top: 0.5rem;
  }

  .wait-icon {
    margin: 1rem;
  }

  .form {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
    background-color: colour(grey);

    .form-group {
      margin-bottom: 0.5rem;
    }

    .invalid {
      border: solid 1px colour(form-field-error);
    }

    .error {
      color: colour(form-field-error);
      margin-top: 1rem;
    }

    .buttons {
      text-align: right;
    }

    .list-field-label {
      font-weight: bold !important;
    }

    .hint {
      font-size: 0.8rem;
      color: colour(grey-1);
    }

    .custom-control-inline {
      margin-right: 2rem !important;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import auth from '@/mixins/auth'

export default {
  name: 'BulkOperation',
  mixins: [auth],
  components: {},
  props: {
    selectedResults: {
      type: Array,
      required: false,
      default: () => [],
    },
    allResults: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      validator: () => true,
    }
  },
  created() {
    this.$emit('new-operation', {
      id: this.operationId,
      buttonText: this.buttonText,
      buttonDisabled: () => this.buttonDisabled,
      predicate: () => this.canPerformOperation,
      click: () => this.showModal(),
    })
  },
  computed: {
    operationId: () => '',
    buttonText: () => '',
    buttonDisabled() {
      return this.selectedResults.length < 1
    },
    canPerformOperation() {
      if (!_.isArray(this.allResults) || _.includes(this.allResults, null)) {
        return false
      }

      return this.authenticated
    },
    modalId() {
      return this.operationId + 'Modal'
    },
  },
  methods: {
    typeMap(work) {
      return {
        work: 'work',
        people: work.primaryFormat,
        newspaper: 'newspaper',
        list: 'list',
      }[work.workType]
    },
    showModal() {
      this.$bvModal.show(this.modalId)
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide(this.modalId)
      })
    },
    checkValidation() {
      return Promise.resolve(this.validator()).then((isValidated) => {
        return isValidated
      })
    },
    receiveValidator(validator) {
      if (_.isFunction(validator)) {
        this.validator = validator
      }
    },
    ok() {},
    cancel() {},
  },
}
</script>
