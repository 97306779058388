<template>
  <div class="account-deleted-container">
    Your Trove account has been deleted click
    <router-link to="/">here</router-link> to navigate to the home page
  </div>
</template>

<script>
import auth from '../../mixins/auth'

export default {
  name: 'UserProfileSettings',
  mixins: [auth],
  created() {
    let self = this
    if (self.authenticated) {
      // refresh the page to reflect the updated authentication state
      self.$router.go(0)
    }
  },
}
</script>

<style scoped lang="scss">
.account-deleted-container {
  text-align: center;
  margin-top: 20px;
}
</style>
