<template>
  <div class="annotation-list">
    <h2 class="heading">
      {{ heading }}
      <span class="annotation-count">({{ annotations.length }})</span>
    </h2>
    <a href="javascript:void(0)" @click="toggleShowNotes" class="collapse-link" v-if="collapsible"
      >{{ showNotes ? 'Hide' : 'Show' }} {{ label }}</a
    >

    <Collapse class="list-collapse" :collapsed="!showNotes">
      <template v-if="annotations.length === 0">
        No {{ labelSingular }}s have been added for this record
      </template>

      <template v-else>
        <p>
          {{ annotations.length }} {{ labelSingular }}{{ annotations.length > 1 ? 's' : '' }} for
          this record
        </p>
        <Annotation
          v-for="annotation in annotations"
          :annotation="annotation"
          :type="type"
          :read-only="readOnly"
          @delete="deleteAnnotation"
          @updated="annotationUpdated"
          class="listed-annotation"
          :key="annotation.id"></Annotation>
      </template>

      <AddAnnotation
        :target-id="targetId"
        :type="type"
        :label="labelSingular"
        v-if="!readOnly"
        @annotation-added="annotationAdded"></AddAnnotation>
    </Collapse>
  </div>
</template>

<style scoped lang="scss">
.annotation-list {
  padding-top: 2rem;

  .list-collapse {
    clear: both;
  }

  .heading {
    display: inline-block;

    .annotation-count {
      opacity: 0.7;
      font-size: smaller;
      font-weight: 500;
    }
  }

  .collapse-link {
    margin-left: 2rem;
    display: inline-block;

    @media (max-width: 425px) {
      margin-left: 0px;
    }
  }

  .listed-annotation {
    margin-bottom: 1.2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid colour(grey-5);
  }
}
</style>

<script>
import service from '@/service/service'
import _ from 'lodash'
import Vue from 'vue'
import Annotation from '@/components/annotations/Annotation.vue'
import AddAnnotation from '@/components/annotations/AddAnnotation.vue'
import Collapse from '@/components/common/Collapse.vue'
import auth from '@/mixins/auth'

export default {
  name: 'AnnotationList',
  mixins: [auth],
  components: {
    Collapse,
    Annotation,
    AddAnnotation,
  },
  props: {
    heading: {
      type: String,
      required: false,
      default: 'Notes',
    },
    type: {
      type: String,
      required: true,
      validator(val) {
        return ['work', 'version', 'person', 'organisation', 'list'].indexOf(_.toLower(val)) > -1
      },
    },
    targetId: {
      type: String,
      required: true,
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      annotations: [],
      loading: false,
      notesLoaded: false,
      showNotes: !this.collapsible,
    }
  },
  mounted() {
    this.loadAnnotations()
  },
  watch: {
    targetId() {
      let self = this
      Vue.nextTick(() => {
        self.loadAnnotations()
      })
    },
  },
  computed: {
    cleanType() {
      return _.toLower(this.type)
    },
    label() {
      return _.toLower(this.heading)
    },
    labelSingular() {
      return _.endsWith(this.label, 's')
        ? this.label.substring(0, this.label.length - 1)
        : this.label
    },
  },
  methods: {
    loadAnnotations() {
      let self = this
      self.loading = true
      self.notesLoaded = false
      self.annotations.splice(0, self.annotations.length)
      let parseAnnotations = (a) => {
        _.each(a, (ann) => {
          self.annotations.push(ann)
        })
        self.loading = false
        self.notesLoaded = true
      }

      if (self.cleanType === 'work') {
        service.work
          .getAnnotations(self.targetId)
          .then((data) => {
            parseAnnotations(data)
          })
          .catch(() => {
            self.loading = false
          })
      } else if (self.cleanType === 'version') {
        service.work
          .getAnnotations(undefined, self.targetId)
          .then((data) => {
            parseAnnotations(data)
          })
          .catch(() => {
            self.loading = false
          })
      } else if (self.cleanType === 'person' || self.cleanType === 'organisation') {
        service.people
          .getAnnotations(self.targetId)
          .then((data) => {
            parseAnnotations(data)
          })
          .catch(() => {
            self.loading = false
          })
      } else if (self.cleanType === 'list') {
        service.list
          .getAnnotations(self.targetId)
          .then((data) => {
            parseAnnotations(data)
          })
          .catch(() => {
            self.loading = false
          })
      }
    },
    toggleShowNotes() {
      this.showNotes = !this.showNotes
      if (this.showNotes && !this.notesLoaded) {
        this.loadAnnotations()
      }
    },
    annotationAdded(anno) {
      this.annotations.push(anno)
    },
    annotationUpdated(anno) {
      let self = this
      let updated = _.find(self.annotations, (a) => {
        return a.id === anno.id
      })
      updated.content = anno.content
      updated.lastModified = anno.lastModified
      updated.lastModifiedStr = anno.lastModifiedStr
    },
    deleteAnnotation(id) {
      let self = this

      service.annotations.deleteAnnotation(id).then(() => {
        let index = _.findIndex(self.annotations, (a) => {
          return a.id === id
        })
        self.annotations.splice(index, 1)
      })
    },
  },
}
</script>
