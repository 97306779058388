<template>
  <FilterableList
    :filterConfigs="filterConfigs"
    :items="items"
    :paginationInfo="paginationInfo"
    @get-items="getItems">
    <template v-slot:item> Empty item </template>
    <template v-slot:stub> Empty stub </template>
  </FilterableList>
</template>

<style scoped lang="scss"></style>

<script>
import _ from 'lodash'

import auth from '@/mixins/auth'
import profile from '@/mixins/profile'
import FilterableList from '@/components/profile/lists/FilterableList.vue'

export default {
  name: 'FilterableListPage',
  mixins: [auth, profile],
  components: {
    FilterableList,
  },
  data() {
    return {
      items: [],
      loading: false,
      error: null,
      filter: {},
      paginationInfo: {
        size: this.defaultPageSize,
        page: 0,
        offset: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
      },
    }
  },
  created() {
    this.filter = _.cloneDeep(this.filterDefault)
    this.getItems()
  },
  computed: {
    defaultPageSize() {
      return 10
    },
    filterConfigs() {
      return []
    },
    filterDefault() {
      return {}
    },
    filtered() {
      return !_.isEqual(this.filterDefault, this.filter)
    },
    queryUser() {
      if (!_.isEmpty(this.usernameParam)) {
        return this.usernameParam
      } else {
        return this.fullUsername
      }
    },
    timeIntervalOptions() {
      return [
        {
          value: null,
          text: 'Any time',
        },
        {
          value: 'LAST_HOUR',
          text: 'Last hour',
        },
        {
          value: 'LAST_DAY',
          text: 'Last day',
        },
        {
          value: 'LAST_WEEK',
          text: 'Last week',
        },
        {
          value: 'LAST_MONTH',
          text: 'Last month',
        },
      ]
    },
    annotatedMaterialTypeOptions() {
      return [
        {
          value: null,
          text: 'Everything',
        },
        {
          value: 'NEWSPAPERS',
          text: 'Newspapers and Gazettes',
        },
        {
          value: 'PEOPLE_ORG',
          text: 'People and Organisations',
        },
        {
          value: 'LIST',
          text: 'Lists',
        },
        {
          value: 'EVERYTHING_ELSE',
          text: 'Everything else (books, music, pictures...)',
        },
      ]
    },
    visibilityOptions() {
      return [
        {
          value: 'BOTH',
          text: 'All',
        },
        {
          value: 'PUBLIC',
          text: 'Public',
        },
        {
          value: 'PRIVATE',
          text: 'Private',
        },
      ]
    },
  },
  methods: {
    resetFilters() {
      this.filter = _.cloneDeep(this.filterDefault)
      this.getItems()
    },
    pageChanged(newPage) {
      this.paginationInfo.page = newPage
      this.getItems()
    },
    getItems() {
      let self = this

      if (!self.isMyProfile && !self.canViewPrivateProfiles && !self.profileInfo.activityVisible) {
        return
      }

      self.error = null
      self.loading = true

      self.items = []

      self
        .getFromService(self.queryUser, _.assign({}, self.filter, self.paginationInfo))
        .then((page) => {
          self.items = page.content || []

          self.paginationInfo = {
            offset: page.pageable.offset || 0,
            size: page.size || self.defaultPageSize,
            totalPages: page.totalPages || 0,
            page: page.number || 0,
            totalElements: page.totalElements || 0,
            numberOfElements: page.numberOfElements || 0,
          }

          self.loading = false
        })
        .catch((error) => {
          self.loading = false
          // TODO: Correct name for page here
          self.handleError(error, 'corrections', undefined, 'retrieve the recent text corrections')
        })
    },
    getFromService() {
      return Promise.resolve([], {})
    },
  },
}
</script>
