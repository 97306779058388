<template>
  <div class="pagination d-flex align-items-center">
    <b-button v-if="hasPrevious" class="inverse" size="sm" variant="secondary" :to="previousLink"
      >Previous</b-button
    >
    <span class="range">{{ currentRange }}</span>
    <b-button v-if="hasNext" class="inverse" size="sm" variant="secondary" :to="nextLink"
      >Next</b-button
    >
  </div>
</template>

<style scoped lang="scss">
.pagination {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .range {
    margin-right: 1rem;
    font-weight: bold;
  }
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'BasicPaginator',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    numRecords: {
      type: Number,
      required: true,
    },
    hasMore: {
      type: Boolean,
      required: true,
    },
    linkGenerator: {
      type: Function,
      required: false,
    },
  },
  computed: {
    hasPrevious() {
      return this.currentPage > 1
    },
    hasNext() {
      return this.hasMore
    },
    currentRange() {
      var start = (this.currentPage - 1) * this.pageSize + 1
      var end = (this.currentPage - 1) * this.pageSize + this.numRecords

      return `${start} - ${end}`
    },
    previousLink() {
      return this.generateLink(this.currentPage - 1)
    },
    nextLink() {
      return this.generateLink(this.currentPage + 1)
    },
  },
  methods: {
    generateLink(pageNum) {
      if (_.isFunction(this.linkGenerator)) {
        return this.linkGenerator(pageNum, this.pageSize)
      }

      return this.generateSearchPaginationLink(pageNum, this.pageSize)
    },
  },
}
</script>
