<template>
  <div>
    <div class="work-action-get">
      <div class="free-access" v-if="sortedFreeAccessUrls.length > 0">
        <h2 class="heading bevel blue">Free access</h2>

        <LibraryList
          :libraries="sortedFreeAccessUrlsForList"
          @library-clicked="track"></LibraryList>

        <div class="show-all-container">
          <b-button
            variant="outline-primary"
            class="show-all-toggle"
            v-if="freeAccessUrls.length > freeAccessLimitTo"
            @click="toggleFreeAccessShowAll">
            {{ showAllFreeAccess ? 'Show less' : 'Show all' }}
          </b-button>
        </div>
      </div>

      <div class="membership-access" v-if="hasSubscribingLibraries">
        <h2 class="heading bevel blue">Via a library membership</h2>

        <MutexList :items="states" @item-selected="stateSelected"></MutexList>
        <LibraryList
          :libraries="selectedSubscribingLibrariesForList"
          @library-clicked="track"></LibraryList>

        <div class="show-all-container">
          <b-button
            variant="outline-primary"
            class="show-all-toggle"
            v-if="(groupedSubscribingLibraries[selectedState] || []).length > subscriptionLimitTo"
            @click="toggleSubscriptionShowAll">
            {{ showAllSubscription ? 'Show less' : 'Show all' }}
          </b-button>
        </div>
      </div>

      <div class="conditional-access" v-if="sortedConditionalAccessUrls.length > 0">
        <h2 class="heading bevel blue">Access conditions apply</h2>

        <LibraryList
          :libraries="sortedConditionalAccessUrlsForList"
          @library-clicked="track"></LibraryList>

        <div class="show-all-container">
          <b-button
            variant="outline-primary"
            class="show-all-toggle"
            v-if="conditionalAccessUrls.length > conditionalAccessLimitTo"
            @click="toggleConditionalAccessShowAll">
            {{ showAllConditionalAccess ? 'Show less' : 'Show all' }}
          </b-button>
        </div>
      </div>
      <div class="previous d-flex align-content-center mt-3">
        <div class="bevel flex-grow-1"></div>
      </div>
    </div>

    <slot name="after"></slot>
  </div>
</template>

<style scoped lang="scss">
.work-action-get {
  .heading {
    font-size: 20px;
  }

  .free-access,
  .membership-access {
    padding-bottom: 20px;
  }

  .show-all-container {
    width: 100%;
    margin-top: 1em;

    .show-all-toggle {
      min-width: 10rem;
    }

    .btn {
      border: 2px solid colour(green);
      color: colour(white);
      background: colour(green);
      transition: 0.5s ease-in-out;
      font-weight: 600;

      &:hover,
      &:focus {
        background: colour(white);
        color: colour(green);
      }
    }
  }
  h2.bevel {
    font-family: inherit;
    font-size: 1.375rem;
    margin-bottom: 1em;
    &:before {
      top: 0.17em;
    }
  }
  .previous {
    width: 100%;
  }
  .previous .bevel {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    height: 0;
    border-left: 0 solid #f4f4f4;
    border-top: 48px solid #f4f4f4;
    border-right: 0.80001em solid transparent;
  }
}
</style>

<script>
import _ from 'lodash'
import service from '@/service/service'
import MutexList from '@/components/MutexList.vue'
import LibraryList from '@/components/LibraryList.vue'
import WorkActionsCommon from '@/components/work/workActions/WorkActionsCommon'

export default {
  name: 'WorkActionsGet',
  components: {
    MutexList,
    LibraryList,
  },
  mixins: [WorkActionsCommon],
  props: {
    availableUrls: {
      type: Array,
      required: false,
    },
    subscribingLibraries: {
      type: Array,
      required: false,
    },
    googleBooksPreview: {
      type: String,
      required: false,
    },
    limitTo: {
      type: Number,
      required: false,
      default: 12,
    },
  },
  data() {
    return {
      freeAccessUrls: [],
      conditionalAccessUrls: [],
      showAllFreeAccess: false,
      showAllConditionalAccess: false,
      showAllSubscription: false,
      selectedState: null,
      groupedSubscribingLibraries: {},
    }
  },
  mounted() {
    this.updateUrls()
  },
  watch: {
    googleBooksPreview() {
      this.updateUrls()
    },
    availableUrls() {
      this.updateUrls()
    },
    subscribingLibraries: {
      immediate: true,
      handler() {
        this.groupedSubscribingLibraries = _(this.subscribingLibraries)
          .groupBy((lib) => {
            return lib.stateAbbrev && _.isString(lib.stateAbbrev)
              ? lib.stateAbbrev.toLowerCase()
              : 'unknown'
          })
          .assign({ all: this.subscribingLibraries })
          .value()
      },
    },
  },
  computed: {
    selectedSubscribingLibrariesForList() {
      var subLibs = _(this.groupedSubscribingLibraries[this.selectedState] || [])
        .map((library) => {
          var vendor = !_.isEmpty(library.vendors) ? library.vendors[0] : {}

          return {
            name: library.fullName,
            key: library.nuc,
            url: service.vendor.generateVendorUrl(library, vendor),
            fields: [
              {
                text: vendor.name,
              },
              {
                text: library.format,
              },
              {
                text: library.language,
              },
              {
                text: library.year,
              },
              {
                text: 'onsite only',
                hide: vendor.accessRestrictions !== '1',
              },
              {
                text: 'click here to log on',
                href: vendor.accessRestrictions,
                hide: vendor.accessRestrictions === '1' || vendor.accessRestrictions === '0',
              },
            ],
          }
        })
        .uniqBy((lib) => lib.url)
        .value()

      return _.take(subLibs, this.showAllSubscription ? subLibs.length : this.subscriptionLimitTo)
    },
    states() {
      return _(this.groupedSubscribingLibraries)
        .map((libraries, stateAbbrev) => {
          var sortMapping = {
            unknown: 'zzz',
            all: 'aaa',
          }

          return {
            name: stateAbbrev,
            count: libraries.length,
            sort: sortMapping[stateAbbrev] || stateAbbrev, // TODO: better way of getting this sorted last
          }
        })
        .sortBy((state) => state.sort)
        .value()
    },
    hasSubscribingLibraries() {
      return !_.isEmpty(this.subscribingLibraries)
    },
    subscriptionLimitTo() {
      return this.limitTo
    },
    freeAccessLimitTo() {
      return this.limitTo
    },
    conditionalAccessLimitTo() {
      return this.freeAccessUrls.length > 0 ? this.limitTo / 3 : this.limitTo
    },
    sortedFreeAccessUrlsForList() {
      return _.map(this.sortedFreeAccessUrls, this.mapAvailableUrlForList)
    },
    sortedFreeAccessUrls() {
      let max = this.showAllFreeAccess ? this.freeAccessUrls.length : this.freeAccessLimitTo
      return _.take(
        _.orderBy(
          _.filter(this.freeAccessUrls, (u) => !u.hasConditions),
          [
            (u) => u.nla, // nla stuff then non-nla
            (u) => _.toLower(u.text),
          ], // then alphabetic
          ['desc', 'asc']
        ),
        max
      )
    },
    sortedConditionalAccessUrlsForList() {
      return _.map(this.sortedConditionalAccessUrls, this.mapAvailableUrlForList)
    },
    sortedConditionalAccessUrls() {
      let max = this.showAllConditionalAccess
        ? this.conditionalAccessUrls.length
        : this.conditionalAccessLimitTo
      return _.take(
        _.orderBy(
          _.filter(this.conditionalAccessUrls, (u) => u.hasConditions),
          [
            (u) => u.nla, // nla stuff then non-nla
            (u) => _.toLower(u.text),
          ], // then alphabetic
          ['desc', 'asc']
        ),
        max
      )
    },
  },
  methods: {
    stateSelected(state) {
      this.selectedState = state.name
    },
    updateUrls() {
      let self = this

      self.freeAccessUrls.splice(0, self.freeAccessUrls.length)
      self.conditionalAccessUrls.splice(0, self.conditionalAccessUrls.length)

      _.forEach(this.availableUrls, (u, i) => {
        let label = self.determineLabel(u)
        let nlamatch = u.url ? u.url.match(/https?:\/\/(www\.)?nla\.gov\.au.*/g) || [] : []

        var mapUrl = (hasConditions) => {
          return {
            url: u.url,
            text: label,
            tooltip: _.startsWith(label, 'View at') ? label : label + ' at ' + u.url,
            key: i,
            nla: nlamatch.length > 0,
            hasConditions: hasConditions,
            highPriority: nlamatch.length > 0,
            format: u.format,
            language: u.language,
            year: u.year,
            nuc: hasConditions ? u.restrictionNucSymbol : u.nucSymbol,
          }
        }

        if (u.type === 'r') {
          self.conditionalAccessUrls.push(mapUrl(true))
        } else {
          self.freeAccessUrls.push(mapUrl(false))
        }
      })

      if (this.googleBooksPreview) {
        self.conditionalAccessUrls.push({
          url: this.googleBooksPreview,
          text: 'Preview on Google Books',
          key: 'google',
          hasConditions: true,
          highPriority: true,
          nla: false,
        })
      }
    },
    determineLabel(url) {
      if (url.key === 'google') {
        return url.text
      }
      let label = url.linkText

      if (label && url.nuc && url.nuc.trim().length > 0) {
        label += ' at ' + url.nuc
      }

      if (url.openLibrary) {
        label = 'Open Library/Internet Archive'
      } else if (url.hathi) {
        label = 'Hathi Trust'
      } else if (
        url.type === 'r' &&
        url.restrictionNUC &&
        url.restrictionNUC.indexOf(':NED') === -1 &&
        url.restrictionNUC.indexOf('ANL') === -1
      ) {
        label =
          'Access through ' +
          (url.restrictionNUC.indexOf('Unknown library code') > -1 ? url.nuc : url.restrictionNUC)
      } else if (
        url.type === 'r' &&
        url.restrictionNUC &&
        (url.restrictionNUC.indexOf(':NED') > -1 || url.restrictionNUC.indexOf('ANL') === -1)
      ) {
        label = url.linkText
      } else if (!label && url.nladigitalObject && (!url.nuc || url.nuc === 'ANL')) {
        label = 'National Library of Australia'
      } else if (!label) {
        label = 'View at ' + (url.nuc || url.url)
      }

      return label
    },
    toggleFreeAccessShowAll() {
      this.showAllFreeAccess = !this.showAllFreeAccess
      this.$emit('recalc-height')
    },
    toggleSubscriptionShowAll() {
      this.showAllSubscription = !this.showAllSubscription
      this.$emit('recalc-height')
    },
    toggleConditionalAccessShowAll() {
      this.showAllConditionalAccess = !this.showAllConditionalAccess
      this.$emit('recalc-height')
    },
    track(library) {
      service._gtm.conversion(this, {
        category: 'Get',
        action: 'Exit',
        label: new URL(library.url).host,
        context: !_.isUndefined(library.nuc) && !_.isEmpty(library.nuc) ? library.nuc : 'Unknown',
      })
    },
  },
}
</script>
