<template>
  <div class="search-result-download" v-if="canDownloadCollection">
    <div class="download-form-toggle">
      <b-link @click="toggleDownloadForm">
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'file-download' }" />
        Download results
      </b-link>
    </div>
    <Alert :alert="success" v-if="success" :shown="10"></Alert>

    <b-modal id="exportModal">
      <template v-slot:modal-title>Download search results</template>

      <div class="search-result-download-modal">
        <div v-if="activeDownloads < maxAllowed">
          <Alert :alert="error" v-if="error" :shown="10"></Alert>
          <p>Your download will be queued and you will be notified when it is ready. You can view your queued downloads
            under your User Profile.</p>
          <p v-if="activeDownloads >= 0 && activeDownloads < maxAllowed">
            You have {{ activeDownloads }} out of {{maxAllowed}} active downloads.
          </p>
          <b-form-group label="Name *">
              <b-form-input v-model="downloadForm.name"
                            v-b-tooltip.hover
                            max="255"
                            title="Provide a short name for the download"/>
            <p class="search-result-download-error" v-if="formErrors.name">{{ formErrors.name }}</p>
          </b-form-group>

          <b-form-group label="Description">
            <b-form-textarea
                v-model="downloadForm.description"
                rows="3"
                v-b-tooltip.hover
                title='Enter a brief description of download'/>
            <p class="search-result-download-error" v-if="formErrors.description">{{ formErrors.description }}</p>
          </b-form-group>
          <div class="caveats" :class="{'expanded': caveatsOpen}">
            <p>Please note the following:</p>
            <ul>
              <li>You may only have a maximum of {{maxAllowed}} downloads at a time, regardless of status.</li>
              <li>The total number of records downloaded may vary from your search results as the underlying data may change before the download has finished.</li>
              <li>Search result downloads are limited to {{maximumExportSize | formatNumber}} records.</li>
            </ul>
          </div>
        </div>
        <div v-else>You already have {{ activeDownloads }} active downloads. Please wait until these have completed,
          or delete them from your User Profile before requesting another download.
        </div>
      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="link" @click="cancel">Cancel</b-button>
        <b-button variant="primary"
                  v-if="activeDownloads < maxAllowed"
                  @click="queueDownload(ok)">Download</b-button>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss">
.search-result-download-modal {
  .search-result-download-error {
    color: colour(form-field-error);
  }

  .caveats {
    font-size: 0.9rem;
  }
}
</style>

<style scoped lang="scss">
.search-result-download {
  .download-form-toggle {
    text-align: right;
    margin-right: 1.5rem;
  }
}
</style>

<script>
import currentRoute from '@/mixins/currentRoute'
import utils from '@/mixins/utils'
import search from '@/mixins/search'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from 'vee-validate/dist/rules'
import service from "@/service/service";
import Alert from "@/components/Alert.vue";
import auth from "@/mixins/auth";
import _ from "lodash";

extend('required', {
  ...required,
  message: '{_field_}',
})

export default {
  name: 'SearchResultDownload',
  mixins: [utils, search, currentRoute, auth],
  components: {
    ValidationProvider,
    ValidationObserver,
    Alert
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
    expectedRecordCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeDownloads: 0,
      maxAllowed: 0,
      downloadForm: {
        name: "",
        description: ""
      },
      formErrors: {
        name: "",
        description: ""
      },
      caveatsOpen: false,
      daysToLive: 0,
      success: undefined,
      error: undefined,
      bulkDownloadableCollections: []
    }
  },
  created() {
    let self = this;
    service.config.environment().then((response) => {
      self.maxAllowed = response.maxAllowedSearchDownloads;
      self.bulkDownloadableCollections = response.bulkDownloadableCollections;
      self.daysToLive = response.searchDownloadsDaysToLive;
    });
  },
  computed: {
    canDownloadCollection() {
      return this.bulkDownloadableCollections.indexOf(this.collection.urlKey) > -1;
    }
  },
  methods: {
    toggleDownloadForm() {
      let self = this;
      self.downloadForm.description = "";
      self.downloadForm.name = "";
      self.$bvModal.show('exportModal');
      service.profile.activeSearchDownloadCount().then(r => {
        self.activeDownloads = r;
        self.$nextTick(() => {
          self.clearAlert();
        });
      });
    },
    queueDownload(modalOk) {
      let valid = true;
      if (!this.downloadForm.name) {
        this.formErrors.name = "You must provide a name for this download.";
        valid = false;
      } else if (this.downloadForm.name.length > 255) {
        this.formErrors.name = "Your name is too long ("
            + this.downloadForm.name.length
            + " characters). The name must not be longer than 255 characters.";
        valid = false;
      }

      if (this.downloadForm.description && this.downloadForm.description.length > 1000) {
        this.formErrors.description = "Your description is too long ("
            + this.downloadForm.description.length
            + " characters). The description must not be longer than 1000 characters.";
        valid = false;
      }

      if (valid) {
        let self = this
        const currentQuery = this.currentQuery;
        const paramsAsString = this.constructSearchParams(currentQuery);

        service.profile.queueSearchDownload(this.downloadForm.name,
            this.downloadForm.description,
            window.location.search,
            paramsAsString,
            this.collection.urlKey,
            this.expectedRecordCount).then(r => {
          self.setAlert("Your download has been queued.", "info");
          modalOk();
        }).catch(e => self.setAlert("An error occurred while queuing your download.", "error"));
      }
    },
    constructSearchParams(currentQuery) {
      const queryString = this.queryToString(currentQuery);
      const limits = this.getLimitsFromQuery(currentQuery);
      const sortBy = currentQuery.sortBy;

      const params = {
        terms: queryString,
      };

      if (!_.isEmpty(limits)) {
        params.limits = limits;
      }

      if (sortBy) {
        params.sortBy = sortBy;
      }
      return this.convertSmartCharacters(JSON.stringify(params));
    },
    setAlert(message, severity) {
      this.clearAlert();

      const alert = {
        summary: message,
        impact: severity
      }

      if (severity === "error") {
        this.error = alert;
      } else {
        this.success = alert;
      }
    },
    clearAlert() {
      this.success = undefined;
      this.error = undefined;
    }
  }
}
</script>
