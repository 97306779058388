<template>
  <div class="find-more-container">
    <h2 v-if="!headerless">Find out more on this topic...</h2>

    <div>
      <div class="error" v-if="error && error.summary">
        <Alert :alert="error"></Alert>
      </div>

      <template v-if="!hasTagsOrSubjects">
        <div class="no-tags">
          <span v-if="!saving && verbose">No tags have been added to this record</span>
          <div v-if="saving" class="loading-spinner spinner-lg"></div>
        </div>
      </template>

      <template v-else>
        <AchievementNotice :user-achievement="userAchievement"></AchievementNotice>
        <ul class="list-inline">
          <li v-for="tag in tagsAndSubjects" :key="tag.id" class="list-inline-item">
            <b-button-group>
              <b-button
                variant="secondary"
                class="green"
                v-b-popover.hover.top="tag.user ? tag.user.displayName : ''"
                :href="tag.href"
                :to="tag.to"
                :class="tag.subject ? 'inverse' : ''">
                <font-awesome-icon class="tag-icon" :icon="getTagIcon(tag)" v-if="!tag.subject" />
                {{ tag.name }}
                <font-awesome-icon
                  class="private-icon"
                  v-if="tag.priv"
                  :icon="{ prefix: 'fa', iconName: 'lock' }" />
              </b-button>
              <b-button
                variant="secondary"
                class="green remove"
                v-b-popover.hover.top="'Remove this tag'"
                @click="deleteTag(tag)"
                :disabled="saving"
                v-if="!tag.subject && tag.editable && !readOnly">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'times' }" />
              </b-button>
            </b-button-group>
          </li>
          <li class="list-inline-item">
            <b-button
              variant="secondary"
              class="show-more-link"
              @click="toggleShowAll"
              :disabled="saving"
              v-if="limitTags">
              <span v-if="!isLimited">Show less</span>
              <span v-if="isLimited">Show all</span>
            </b-button>
            <div v-if="saving" class="loading-spinner spinner-lg"></div>
          </li>
        </ul>
        <div class="tagged" v-if="hasTags">
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'tag' }" />Tagged by Voluntroves
        </div>
      </template>
    </div>

    <AddTag
      v-if="addPanelOpen && !readOnly"
      :targetWork="work"
      @tags-added="tagsAdded"
      @cancel="addPanelOpen = false"
      @user-achievement="setUserAchievement" />

    <b-button
      variant="secondary"
      class="add-tag-btn"
      :disabled="saving"
      v-if="!addPanelOpen && !readOnly"
      @click="addPanelOpen = true">
      <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'plus' }" />
      Add a tag
    </b-button>
  </div>
</template>

<style scoped lang="scss">
.find-more-container {
  margin-top: 2rem;

  h2 {
    margin-bottom: 0.8rem;
  }

  .tagged {
    font-size: medium;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    color: colour(green);
  }

  @supports (font-variation-settings: normal) {
    .tagged {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }

  .tag-icon {
    margin-right: 0.5rem;
  }

  .private-icon,
  .wait-icon {
    margin-left: 0.5rem;
  }

  .list-inline {
    margin-bottom: 0;
  }
  .list-inline-item {
    margin-bottom: 1rem;

    .btn {
      margin-right: 0;

      &.remove {
        border-left: none;

        &:not(.inverse) {
          border-left: 1px solid white !important;
        }
      }
    }
  }

  .no-tags {
    padding-bottom: 1rem;
  }

  .add-tag {
    background-color: colour(grey);
  }

  .add-tag-btn {
    margin-top: 1rem;
  }
}
</style>

<script>
import _ from 'lodash'
import auth from '@/mixins/auth'
import colours from '@/mixins/colours'
import service from '@/service/service'
import Alert from '@/components/Alert.vue'
import AddTag from '@/components/work/AddTag.vue'
import AchievementNotice from '@/components/common/AchievementNotice.vue'
import history from '@/mixins/history'
import tags from '@/mixins/tags'

const MAX_TAGS_TO_SHOW = 20

export default {
  name: 'Tags',
  mixins: [colours, auth, history, tags],
  components: {
    Alert,
    AddTag,
    AchievementNotice,
  },
  props: {
    work: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerless: {
      type: Boolean,
      required: false,
      default: false,
    },
    verbose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      allTagsAndSubjects: [],
      privateTag: false,
      error: undefined,
      addPanelOpen: false,
      saving: false,
      tagContent: null,
      uniqueId: 1,
      limit: MAX_TAGS_TO_SHOW,
      userAchievement: undefined,
    }
  },
  mounted() {
    this.extractTagsAndSubjects()
  },
  watch: {
    work() {
      this.extractTagsAndSubjects()
    },
  },
  computed: {
    tagsAndSubjects() {
      return _.take(
        _.sortBy(this.allTagsAndSubjects, (t) => {
          return _.toLower(t.name)
        }),
        this.limit
      )
    },
    isLimited() {
      return this.limit === MAX_TAGS_TO_SHOW
    },
    limitTags() {
      return this.allTagsAndSubjects.length > MAX_TAGS_TO_SHOW
    },
    hasTagsOrSubjects() {
      return !_.isEmpty(this.tagsAndSubjects)
    },
    hasTags() {
      return _.filter(this.allTagsAndSubjects || [], (t) => !t.subject).length > 0
    },
  },
  methods: {
    getTagIcon(tag) {
      let icon = {
        prefix: '',
        iconName: '',
      }

      if (tag.mine) {
        icon.prefix = 'far'
        icon.iconName = 'user-tag'
      } else {
        icon.prefix = 'fas'
        icon.iconName = 'tag'
      }

      return icon
    },
    extractTagsAndSubjects() {
      let self = this
      self.allTagsAndSubjects.splice(0, self.allTagsAndSubjects.length)

      let subjects =
        self.work.supportsAnnotatedSubjects && self.work.annotatedSubjectsKeys
          ? self.work.annotatedSubjectsKeys
          : self.work.subjects || []
      var searchRoute = this.latestSearchRoute

      let mappedSubjects = _(subjects)
        .map((subject) => {
          var mapped = {
            id: subject,
            subject: true,
            name: subject,
            user: undefined,
            editable: false,
            mine: false,
            to: {
              name: 'SearchAll',
              query: { subject: subject.replace(/["']/g, '') },
            },
          }

          if (searchRoute) {
            self.augmentTagLink(mapped, searchRoute)
          }

          return mapped
        })
        .value()

      let mappedOccupations = !self.work.occupations
        ? []
        : _(self.work.occupations)
            .map((occupation) => {
              var mapped = {
                id: occupation + self.uniqueId++,
                subject: true,
                name: occupation,
                user: undefined,
                editable: false,
                mine: false,
                to: {
                  name: 'Search',
                  params: {
                    urlNamespace: 'category',
                    urlKey: 'people',
                  },
                  query: { occupation: occupation.replace(/["']/g, '') },
                },
              }

              if (searchRoute && searchRoute.name === 'CollectionSearch') {
                self.augmentTagLink(mapped, searchRoute)
              }

              return mapped
            })
            .value()

      _(mappedSubjects)
        .concat(mappedOccupations)
        .concat(self.mapTags(self.work.tags, self.work.entityType))
        .forEach((t) => self.allTagsAndSubjects.push(t))
    },
    toggleShowAll() {
      this.limit = this.limit === 99999 ? MAX_TAGS_TO_SHOW : 99999
    },
    deleteTag(tag) {
      let self = this
      self.error = undefined
      if (self.saving) {
        return
      }
      self.userAchievement = undefined
      self.$bvModal
        .msgBoxConfirm("Are you sure you wish to remove the tag '" + tag.name + "'?", {
          cancelVariant: 'link',
        })
        .then((result) => {
          if (result) {
            self.saving = true
            service.tags
              .deleteTag(tag.id)
              .then((deleted) => {
                self.saving = false
                if (deleted) {
                  let index = _.findIndex(self.allTagsAndSubjects, (t) => {
                    return t.id === tag.id
                  })
                  self.allTagsAndSubjects.splice(index, 1)
                } else {
                  self.error = {
                    summary: 'An unexpected error occurred while removing your tag',
                    impact: 'error',
                  }
                }
              })
              .catch((error) => {
                self.saving = false
                self.handleError(error, 'tag', undefined, 'remove your tag')
              })
          } else {
            self.saving = false
          }
        })
    },
    clear() {
      this.newTag.content = undefined
      this.newTag.priv = false
    },
    tagsAdded(addedTags) {
      this.addPanelOpen = false
      this.allTagsAndSubjects = _.concat(this.allTagsAndSubjects, addedTags)
    },
    setUserAchievement(ua) {
      this.userAchievement = ua
    },
  },
}
</script>
