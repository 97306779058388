import auth from '@/mixins/auth'
import _ from 'lodash'

export default {
  mixins: [auth],
  methods: {
    author(listAuthor) {
      return _.startsWith(listAuthor, 'public:')
        ? _.toLower(listAuthor.split('public:')[1])
        : _.toLower(listAuthor)
    },
    isListOwner(listAuthor) {
      return (
        this.username === _.toLower(listAuthor) ||
        this.username === this.author(listAuthor) ||
        this.fullUsername === this.author(listAuthor)
      )
    },
    userProfile(listAuthor) {
      if (this.authenticated && this.isListOwner(listAuthor)) {
        return {
          name: 'Profile',
        }
      } else {
        return {
          name: 'PublicProfile',
          params: {
            username: listAuthor.indexOf('user:') > -1 ? listAuthor : 'user:' + listAuthor,
          },
        }
      }
    },
    formattedDate(date) {
      return _.toUpper(date)
    },
    getListStatusMessage(modifiedLists, errors, lists, targets) {
      let substitutions = [
        {
          name: 'list',
          source: _.unionBy(lists, modifiedLists, 'id'),
          sourceProp: 'id',
          sourcePropMod: (p) => String(p),
          replaceProp: 'name',
        },
        {
          name: 'item',
          source: targets,
          sourceProp: 'id',
          sourcePropMod: (p) => String(p),
          replaceProp: 'title',
        },
      ]

      let capitalFirstLetter = (s) => {
        if (!_.isString(s) || _.isEmpty(s)) {
          return ''
        }

        return s.charAt(0).toUpperCase() + s.slice(1)
      }

      let mapError = (error) => {
        let regex = /\{(.+?):(.+?)\}/g

        let defaultSub = capitalFirstLetter(_.replace(error || '', /\{(.+?):(.+?)\}/g, '$1'))

        if (_.every(substitutions, (sub) => !_.isEmpty(sub.source))) {
          try {
            return capitalFirstLetter(
              (error || '').replace(regex, (match, subName, subId) => {
                if (match && subName && subId) {
                  let sub = _.find(substitutions, (sub) => sub.name === subName)

                  if (sub) {
                    let sourceItem = _.find(
                      sub.source || [],
                      (item) =>
                        (_.isFunction(sub.sourcePropMod)
                          ? sub.sourcePropMod(item[sub.sourceProp])
                          : item[sub.sourceProp]) === subId
                    )
                    return sourceItem ? sourceItem[sub.replaceProp] : sub.name
                  }
                }

                return match
              })
            )
          } catch (e) {
            return defaultSub
          }
        } else {
          return defaultSub
        }
      }

      let modifiedListMessages = _(modifiedLists || [])
        .map((list) => {
          let numAdded = (list.createdItemIds || []).length
          let plural = numAdded !== 1
          return `${numAdded === 0 ? 'No' : String(numAdded)} work${plural ? 's' : ''} ${
            plural ? 'have' : 'has'
          } been added to ${list.name}`
        })
        .value()

      let errorMessages = _(errors || [])
        .uniq()
        .map((error) => {
          return mapError(error)
        })
        .value()

      let combined = _.concat(modifiedListMessages, errorMessages)

      let join = '<br/>'
      let messagePrefix = ''
      let messageSuffix = ''
      let prefix = ''
      let suffix = ''

      if (combined.length > 1) {
        join = ''
        messagePrefix = '<li>'
        messageSuffix = '</li>'
        prefix = '<ul>'
        suffix = '</ul>'
      }

      return (
        prefix +
        _(combined)
          .map((message) => {
            return `${messagePrefix}${message}${messageSuffix}`
          })
          .join(join) +
        suffix
      )
    },
  },
}
