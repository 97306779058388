<script>
import utils from '@/mixins/utils'
import navigation from '@/mixins/navigation'
import SearchResult from '@/components/search/SearchResult.vue'

export default {
  name: 'WebsiteResult',
  extends: SearchResult,
  mixins: [utils, navigation],
  props: {
    showAvailability: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      maxTitleLength: 100,
    }
  },
  computed: {
    format() {
      return 'HTML snapshot'
    },
    deliverySystemLink() {
      return this.result.deliveryUrl
    },
    showDate() {
      return true
    },
    refineSearch() {
      return this.result.totalFound && this.result.site
    },
    refineSearchText() {
      return `${this.totalFoundString} from <strong>${this.result.site}</strong>`
    },
    refineSearchLink() {
      return this.generateFacetLink('site', this.result.site)
    },
  },
}
</script>
