<script>
import AdvancedSearch from '@/components/search/advanced/AdvancedSearch.vue'
import LibraryMetadataMixin from '@/components/search/advanced/LibraryMetadataMixin.vue'

export default {
  name: 'PeopleAndOrgAdvancedSearch',
  extends: AdvancedSearch,
  mixins: [LibraryMetadataMixin],
  computed: {
    fieldConfig() {
      return [
        this.generateModifiableTextField('Keyword', 'keyword'),
        this.generateModifiableTextField('Name', 'name'),
        this.generateModifiableTextField('Organisation', 'organisationname'),
      ]
    },
    limitFieldConfig() {
      return [
        this.standardField('public_tag'),
        this.generateFieldFromFacet('advType'),
        this.generateFieldFromFacet('advOccupation'),
        this.generateRangeField('Exists from', 'existencedate', 'YYYY'),
        this.generateTextField('Date of birth/establishment', 'l-birth', false, 'YYYY'),
        this.generateTextField('Date of death/dissolution', 'l-death', false, 'YYYY'),
        this.standardField('library'),
      ]
    },
  },
}
</script>
