<template>
  <b-modal :id="modalId" class="modal" @ok="ok" @close="cancel" @cancel="cancel">
    <template v-slot:modal-title>Remove tags from these results?</template>

    <h4>Are you certain?</h4>
    <p>
      Are you sure you want to remove tags from the following
      {{ selectedResults.length }}
      {{ pluralize('work', selectedResults.length) }}?
    </p>

    <div class="results">
      <div v-for="result in selectedResults" class="result" :key="result.id">
        <div v-if="result.title" class="d-flex">
          <div class="fieldName">Title:</div>
          <div class="flex-grow-1">
            <b>{{ result.title }}</b>
          </div>
        </div>
        <div v-if="result.primaryName">
          <div class="fieldName">Name:</div>
          <div class="flex-grow-1">
            <b>{{ result.primaryName }}</b>
          </div>
        </div>
        <div v-if="result.author" class="d-flex">
          <div class="fieldName">Author:</div>
          <div class="flex-grow-1">
            <b>{{ result.author }}</b>
          </div>
        </div>
      </div>
    </div>

    <div class="form">
      <ValidationObserver ref="observer" tag="div" key="removeTag">
        <b-form-group>
          <b-form-radio-group v-model="priv">
            <b-form-radio :value="false">Public</b-form-radio>
            <b-form-radio :value="true">Private</b-form-radio>
            <b-form-radio :value="null">Both</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <ValidationProvider rules="required" v-slot="{ errors, classes }" name="tag name">
          <b-form-group label="Tag" label-class="font-weight-bold">
            <b-form-input v-model="tagsInput" :class="classes" :required="true"></b-form-input>
          </b-form-group>
          <div class="hint">
            To remove multiple tags, separate different tags with a comma. To include a comma in
            your tag, surround the tag with double quotes.
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>
      </ValidationObserver>
    </div>

    <div class="d-flex justify-content-center" v-if="saving">
      <div class="loading-spinner spinner-md"></div>
    </div>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="link" @click="cancel" :disabled="saving">Cancel</b-button>
      <b-button variant="primary" @click="ok" :disabled="saving"
        >Remove tags from these results</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import pluralize from 'pluralize/pluralize'
import service from '@/service/service'
import utils from '@/mixins/utils'
import tags from '@/mixins/tags'
import BulkOperation from '@/components/bulk/BulkOperation.vue'

var EXCLUDED_WORK_TYPES = ['website']

export default {
  name: 'BulkRemoveTag',
  extends: BulkOperation,
  mixins: [utils, tags],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      tagsInput: '',
      priv: false,
    }
  },
  created() {
    this.resetForm()
  },
  mounted() {
    this.receiveValidator(this.validate)
  },
  computed: {
    operationId: () => 'removeTag',
    buttonText: () => 'Remove Tag',
    canPerformOperation() {
      if (!_.isArray(this.allResults) || _.includes(this.allResults, null)) {
        return false
      }

      if (
        _(this.allResults)
          .map((result) => result.workType)
          .filter((type) => _.includes(EXCLUDED_WORK_TYPES, type))
          .value().length > 0
      ) {
        return false
      }

      return this.isDataManager
    },
  },
  methods: {
    validate() {
      return this.$refs.observer.validate()
    },
    resetForm() {
      this.tagsInput = ''
      this.priv = false
    },
    ok(event) {
      var self = this
      event.preventDefault()

      this.checkValidation().then((isValidated) => {
        if (isValidated) {
          self.saving = true
          self.error = undefined

          var tags = this.splitTags(self.tagsInput, self.priv)

          var targets = _.map(self.selectedResults, (result) => {
            return {
              id: result.id,
              type: self.typeMap(result),
            }
          })

          service.tags
            .deleteTags(targets, tags)
            .then((data) => {
              self.closeModal()
              self.resetForm()
              self.saving = false

              let tags = _(data.items || [])
                .map((tag) => '"' + tag.content + '"')
                .uniq()
                .join(', ')
              let works = _(data.items || [])
                .map((tag) => (tag.workId || '').slice(1, (tag.workId || '').length))
                .uniq()
                .value()

              if (_.isEmpty(tags)) {
                self.$emit(
                  'bulk-operation-succeeded',
                  'No tags were removed.  None of the tags specified were present on the selected works.  ' +
                    tags
                )
              } else {
                self.$emit(
                  'bulk-operation-succeeded',
                  'The following tags were removed from the ' +
                    works.length +
                    ' affected selected ' +
                    pluralize('work', works.length) +
                    ': ' +
                    tags
                )
              }
            })
            .catch((error) => {
              self.closeModal()
              self.resetForm()
              self.$emit('bulk-operation-failed', error.response.data.message)
            })
        }
      })
    },
    cancel() {
      this.resetForm()
    },
  },
}
</script>
