<template>
  <Stubbable :stubbed="stubbed">
    <template v-slot:content>
      <div class="facet">
        <h3>{{ facet.displayName }}</h3>
        <template v-if="manyTerms">
          <Collapse :collapsed="termsCollapsed" minHeight="10rem">
            <TermList :facet="facet" :terms="facet.terms" />
          </Collapse>

          <div class="collapseLink" :class="termsCollapsed ? '' : 'no-background'">
            <b-button variant="link" @click="termsCollapsed = !termsCollapsed">
              {{ showMoreText }}
              <font-awesome-icon :icon="showMoreIcon" />
            </b-button>
          </div>
        </template>
        <template v-else>
          <TermList :facet="facet" :terms="facet.terms" />
        </template>
      </div>
    </template>
    <template v-slot:stub>
      <div class="d-flex flex-column stub">
        <div class="line" style="width: 30%"></div>
        <div class="line indented" style="width: 80%"></div>
        <div class="line indented" style="width: 80%"></div>
        <div class="line indented" style="width: 80%"></div>
        <div class="line indented" style="width: 80%"></div>
        <div class="line indented" style="width: 80%"></div>
      </div>
    </template>
  </Stubbable>
</template>

<style scoped lang="scss">
.stub {
  margin-top: 2.5rem;

  .line {
    &.indented {
      margin-left: 1rem;
    }
  }
}

.facet {
  h3 {
    font-size: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    margin-top: 2.5rem;
  }

  @supports (font-variation-settings: normal) {
    h3 {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }

  .collapseLink {
    position: relative;
    bottom: 3rem;
    margin-bottom: -3rem;
    background: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    );
    width: 100%;
    height: 5em;
    pointer-events: none;

    &.no-background {
      background: none;
    }

    button,
    a {
      padding-left: 0;
      position: absolute;
      bottom: 0;
      pointer-events: auto;
      svg {
        margin-left: 0.3em;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import Collapse from '@/components/common/Collapse.vue'
import TermList from '@/components/search/facets/TermList.vue'
import Stubbable from '@/components/Stubbable.vue'

var MANY_TERMS_CUTOFF = 6

export default {
  name: 'Facet',
  components: {
    Collapse,
    TermList,
    Stubbable,
  },
  props: {
    facet: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      termsCollapsed: true,
    }
  },
  computed: {
    stubbed() {
      return !this.facet
    },
    allTerms() {
      var flattenTerms = function (terms) {
        return !terms
          ? []
          : _.reduce(
              terms,
              (allTerms, term) => allTerms.concat([term], flattenTerms(term.subTerms)),
              []
            )
      }

      return flattenTerms(this.facet.terms)
    },
    manyTerms() {
      return this.allTerms.length > MANY_TERMS_CUTOFF
    },
    showMoreText() {
      return this.termsCollapsed ? 'Show more' : 'Show less'
    },
    showMoreIcon() {
      return this.termsCollapsed
        ? { prefix: 'fas', iconName: 'caret-down' }
        : { prefix: 'fas', iconName: 'caret-up' }
    },
  },
}
</script>
