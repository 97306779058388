export default {
  props: {
    troveNewspapersUrlPrepend: {
      type: String,
      required: false,
      default: '/newspaper/article/',
    },
    showAvailability: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    subtitle() {
      return this.result.newspaper
    },
    showDate() {
      return true
    },
    showPage() {
      return true
    },
    description() {
      return this.result.abstrct || this.result.extract
    },
    deliverySystemLink() {
      let dest = this.troveNewspapersUrlPrepend + this.result.id
      const query = this.currentQuery
      if (query && (query['keyword'] || query['keyword.any'] || query['keyword.phrase'])) {
        let term = query['keyword'] ? query['keyword'].trim() : ''
        term += query['keyword.any'] ? ' ' + query['keyword.any'].trim() : ''
        term += query['keyword.phrase'] ? ' ' + query['keyword.phrase'].trim() : ''
        term = term.trim()
        if (term !== '') {
          dest += '?searchTerm=' + encodeURIComponent(term)
        }
      }
      return dest
    },
    format() {
      return (
        (this.result.articleType || this.result.formatDisplay) +
        (this.result.illustrated ? ' Illustrated' : '')
      )
    },
    defaultFormatIcon() {
      return { prefix: 'fas', iconName: 'newspaper' }
    },
  },
}
