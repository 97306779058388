<template>
  <div class="user-notification">
    <div class="date">{{ formattedDate }}</div>
    <div class="details">
      <template v-if="!details.removedBySelf">
        An administrator of the list
        <div v-if="details.removedByAdmin && details.reason">
          "<router-link :to="listRoute">{{ details.listTitle }}</router-link
          >" has removed your access due to "{{ details.reason }}".
        </div>
        <div v-else-if="details.removedByAdmin">
          "<router-link :to="listRoute">{{ details.listTitle }}</router-link
          >" has removed your access.
        </div>
        <div v-if="!details.removedByAdmin">
          "<router-link :to="listRoute">{{ details.listTitle }}</router-link
          >" has changed your access level from
          <span class="role">{{ details.oldRole | capitalize }}</span> to
          <span class="role">{{ details.newRole | capitalize }}</span
          >.
        </div>
      </template>

      <template v-if="details.removedBySelf">
        {{ cleanUsername(details.userId) }} has stopped collaborating on the list "<router-link
          :to="listRoute"
          >{{ details.listTitle }}</router-link
        >".
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-notification {
  .role {
  }
}
</style>

<script>
import AbstractNotificationDetail from './AbstractNotificationDetail'
import auth from '@/mixins/auth'

export default {
  name: 'CollabListRoleChanged',
  extends: AbstractNotificationDetail,
  mixins: [auth],
  components: {},
  computed: {
    listRoute() {
      return {
        name: 'ManageList',
        params: { id: this.details.listId },
      }
    },
  },
}
</script>
