import _ from 'lodash'

export default {
  suppressContent: (state) => (identifier) => {
    return state.showCulturalWarnings && !state.unsuppressedIdentifiers[identifier]
  },
  currentProfileActivity: (state) => {
    return state.profileActivity
  },
  myUserProfile: (state) => {
    return state.myUserProfile
  },
  routeHistory: (state) => {
    return state.routeHistory
  },
  latestRoute: (state) => {
    return state.routeHistory[0]
  },
  latestSearchRoute: (state) => {
    return _.find(
      state.routeHistory,
      (route) => route.params && route.params.urlNamespace && route.params.urlKey
    )
  },
  findCollectionBySearchRoute: (state, getters, rootState) => (collections) => {
    const latestRoute = getters['latestRoute']
    if (!latestRoute || _.isEmpty(latestRoute.query) || rootState.route.name === 'SearchAll') {
      return null
    }

    const route = getters['latestBreadCrumbRoute']

    if (route && !_.isEmpty(collections)) {
      if (route.params.urlNamespace && route.params.urlKey) {
        return _.find(collections, (collection) => {
          if (route.params) {
            return (
              route.params.urlKey === collection.urlKey &&
              route.params.urlNamespace === collection.urlNamespace
            )
          }

          return false
        })
      }
    }

    return null
  },
  isHuman: (state) => {
    return state.isHuman
  },
  latestBreadCrumbRoute: (state) => {
    return _.find(state.routeHistory, (route) => route.meta && route.meta.breadCrumbHistorical)
  },
  primaryWarningDisplayed: (state) => {
    return state.primaryWarningDisplayed
  },
  showCulturalWarnings: (state) => {
    return state.showCulturalWarnings
  },
  startAdvancedOpen: (state) => {
    return state.startAdvancedOpen
  },
}
