<template>
  <div class="copyright-container" v-if="!isHideCopyrightStatus">
    <div class="copyright-status">
      <b-link>
        <div @click="showDetails = !showDetails">
          <span><font-awesome-icon :icon="copyrightIcon" />{{ copyrightStatus }}</span>
        </div>
      </b-link>
    </div>

    <b-collapse id="copyrightStatusDetailsCollapse" v-model="showDetails">
      <b-card class="copy-details">
        <template v-if="status">
          <div class="close">
            <b-button @click="showDetails = false"
              >Close <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'times' }"
            /></b-button>
          </div>
          <h3>{{ status.copyrightStatus }}</h3>

          <p class="reason" v-if="status.copyrightStatusReason">
            Reason for copyright status: {{ status.copyrightStatusReason }}
          </p>

          <p v-if="status.contextMsg === '1.1'">
            You may copy under some circumstances, for example you may copy a portion for research
            or study.
            <span v-if="canBuy"
              ><b-link><div @click="buy">Order a copy</div></b-link> to the extent allowed under
              fair dealing.</span
            >
            <span v-if="canBorrow"
              ><b-link v-if="canBorrow"
                ><div @click="borrow">Contact the holding library</div></b-link
              ></span
            >
            <span v-if="!canBorrow"
              >Contact the
              <a href="https://www.copyright.org.au/" v-if="!canBorrow"
                >Australian Copyright Council</a
              ></span
            >
            for further information about copying.
          </p>

          <p v-if="status.contextMsg === '1.2'"></p>

          <p v-if="status.contextMsg === '1.3'">
            You may have full rights to copy, or may be able to copy only under some circumstances,
            for example a portion for research or study.
            <span v-if="canBorrow"
              ><b-link v-if="canBorrow"
                ><div @click="borrow">Contact the holding library</div></b-link
              ></span
            >
            <span v-if="!canBorrow"
              >Contact the
              <a href="https://www.copyright.org.au/" v-if="!canBorrow"
                >Australian Copyright Council</a
              ></span
            >
            for further information about copying.
          </p>

          <p v-if="status.contextMsg === '2.1'">
            <span v-if="canBuy"
              >You may copy or <b-link><div @click="buy">order</div></b-link> a copy.</span
            >
            <span v-if="!canBuy">You may make a copy.</span>
            <span v-if="canBorrow"
              ><b-link v-if="canBorrow"
                ><div @click="borrow">Contact the holding library</div></b-link
              ></span
            >
            <span v-if="!canBorrow"
              >Contact the
              <a href="https://www.copyright.org.au/" v-if="!canBorrow"
                >Australian Copyright Council</a
              ></span
            >
            for further information about copying.
          </p>

          <p v-if="status.contextMsg === '2.2'">
            Copyright varies with publication date of each issue. You may have full rights to copy,
            or may be able to copy only under some circumstances, for example a portion for research
            or study.
            <span v-if="canBuy"
              ><b-link><div @click="buy">Order a copy</div></b-link> to the extent allowed under
              fair dealing.</span
            >
            <span v-if="canBorrow"
              ><b-link v-if="canBorrow"
                ><div @click="borrow">Contact the holding library</div></b-link
              ></span
            >
            <span v-if="!canBorrow"
              >Contact the
              <a href="https://www.copyright.org.au/" v-if="!canBorrow"
                >Australian Copyright Council</a
              ></span
            >
            for further information about copying.
          </p>

          <p v-if="status.contextMsg === '3'"></p>

          <p v-if="status.contextMsg === '4'">
            This collection may have multiple rights owners.
            <span v-if="canBorrow"
              >If you wish to copy or order copies,
              <b-link v-if="canBorrow"
                ><div @click="borrow"> contact the holding libraries </div></b-link
              ></span
            >.
          </p>

          <p v-if="status.contextMsg === '5'">
            You may
            <span v-if="canBuy"
              ><b-link><div @click="buy">order a copy</div></b-link> or</span
            >
            use the online copy for research or study; for other uses
            <span v-if="canBorrow"
              ><b-link v-if="canBorrow"
                ><div @click="borrow">contact the holding library</div></b-link
              ></span
            >
            <span v-if="!canBorrow"
              >contact the
              <a href="https://www.copyright.org.au/" v-if="!canBorrow"
                >Australian Copyright Council</a
              ></span
            >.
          </p>

          <p v-if="status.contextMsg === '6'"></p>

          <p v-if="status.contextMsg === '7'">
            <span v-if="canBorrow"
              ><b-link v-if="canBorrow"
                ><div @click="borrow">Contact the holding library</div></b-link
              ></span
            >
            <span v-if="!canBorrow"
              >Contact the
              <a href="https://www.copyright.org.au/" v-if="!canBorrow"
                >Australian Copyright Council</a
              ></span
            >
            for information about copying.
          </p>

          <p v-if="status.contextMsg === '8'">
            <span v-if="canBuy"
              >You may <b-link><div @click="buy">order a copy</div></b-link> for research or study;
              for other uses</span
            >
            <span v-if="canBorrow"
              ><b-link v-if="canBorrow"
                ><div @click="borrow">
                  {{ canBuy ? 'c' : 'C' }}ontact the holding library
                </div></b-link
              ></span
            >
            <span v-if="!canBorrow"
              >{{ canBuy ? 'c' : 'C' }}ontact the
              <a href="https://www.copyright.org.au/" v-if="!canBorrow"
                >Australian Copyright Council</a
              ></span
            >
            for information about copying.
          </p>

          <p> Copyright status was determined using the following information: </p>

          <div class="fields">
            <div v-for="field in determiningFields" :key="field.name">
              <span class="name">{{ field.name }}:</span>
              <span class="value"> {{ field.value }}</span>
            </div>
          </div>

          <p>
            Copyright status may not be correct if data in the record is incomplete or inaccurate.
            For more information please see:
            <a href="http://wiki.nla.gov.au//x/LAJbAQ">how copyright status is determined</a>.
          </p>
        </template>
        <template v-if="error">
          <p>
            Copyright status could not be determined due to data in the record being incomplete or
            inaccurate. For more information please see:
            <a href="http://wiki.nla.gov.au//x/A7U">how copyright status is determined</a>.
          </p>
        </template>
      </b-card>
    </b-collapse>
  </div>
</template>

<style scoped lang="scss">
.copyright-container {
  margin-top: 0.5em;
  margin-right: 0.5rem;

  .copyright-status {
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: right;

    svg {
      margin-right: 0.5em;
    }
  }
  .copy-details {
    background: colour(grey);
    border-radius: 0;
    border: none;
    h3 {
      font-size: 1.8125rem;
    }
    .reason {
      color: colour(green);
      font-size: 1.2rem;
      font-weight: 600;
    }
    .btn {
      background: none;
      border: none;
      color: colour(black);
      margin-bottom: 0.5em;
      svg {
        font-size: 22px !important;
        margin-left: 5px;
        position: relative;
        top: 2px;
      }
      &:hover,
      &:focus {
        text-decoration: underline;
        color: colour(magenta);
      }
    }
  }

  .fields {
    margin-bottom: 1rem;

    .name {
      font-weight: bold;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import service from '@/service/service'

export default {
  name: 'CopyrightStatus',
  props: {
    bibliographicRecordId: {
      type: String,
      required: true,
    },
    canBorrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    canBuy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      status: null,
      error: null,
      showDetails: false,
    }
  },
  methods: {
    borrow() {
      this.$emit('go-to-borrow')
    },
    buy() {
      this.$emit('go-to-buy')
    },
  },
  computed: {
    copyrightStatus() {
      return this.status ? this.status.copyrightStatus : 'Unknown'
    },
    isHideCopyrightStatus() {
      return this.status && (this.status.contextMsg == '2.1' || this.status.contextMsg == '2.2')
    },
    copyrightIcon() {
      return { prefix: 'far', iconName: 'copyright' }
    },
    determiningFields() {
      var fields = [
        { name: 'Creator Status', value: this.status.creatorStatus },
        {
          name: 'Creator Date of Death',
          value: this.status.creatorDateOfDeath,
        },
        { name: 'Material type', value: this.status.materialType },
        { name: 'Published Status', value: this.status.publishedStatus },
        { name: 'Publication Date', value: this.status.publicationDate },
        {
          name: 'Government copyright ownership',
          value: this.status.govtCopyrightOwnership,
        },
      ]

      return _(fields)
        .filter((field) => field.value)
        .value()
    },
  },
  watch: {
    bibliographicRecordId: {
      immediate: true,
      handler() {
        var self = this
        self.status = null

        if (self.bibliographicRecordId) {
          service.copyright
            .checkStatus(self.bibliographicRecordId)
            .then((response) => {
              self.status = response
            })
            .catch((error) => {
              self.error = error
            })
        }
      },
    },
  },
}
</script>
