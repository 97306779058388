<template>
  <span class="search-help-link">
    <a :href="helpUrl" v-if="helpUrl" :class="format"><slot>help page on searching</slot></a>
  </span>
</template>

<style scoped lang="scss">
.search-help-link {
  .within-alert {
    color: black !important;
    font-weight: 600;
    a {
      color: black !important;
    }
  }
}
</style>

<script>
import service from '@/service/service'

export default {
  name: 'SearchHelpLink',
  props: {
    format: {
      type: String,
      required: false,
    },
  },
  created() {
    let self = this
    service.config.environment().then((response) => {
      self.helpUrl = response.searchHelpUrl
    })
  },
  data() {
    return {
      helpUrl: undefined,
    }
  },
}
</script>
