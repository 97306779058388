<template>
  <div v-if="summary" class="list-description">
    <h2 v-if="showHeading">Description</h2>
    <span v-html="sanitizeHtml(summary)"></span>

    <div class="collapseLink" v-if="showMore">
      <b-link>
        <span @click="showDetails = !showDetails" v-if="showDetails"
          >Read less <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-up' }"
        /></span>
        <span @click="showDetails = !showDetails" v-if="!showDetails"
          >Read more <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }"
        /></span>
      </b-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-description {
  h2 {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.1875rem;
    color: colour(purple);
    font-family: 'Source Sans Pro', sans-serif;
  }

  @supports (font-variation-settings: normal) {
    h2 {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import utils from '@/mixins/utils'

export default {
  name: 'ListDescription',
  mixins: [utils],
  props: {
    list: {
      type: Object,
      required: true,
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: true,
    },
    truncate: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 100,
    },
  },
  data() {
    return {
      showDetails: false,
      showMore: false,
    }
  },
  computed: {
    summary() {
      let text = this.list.description
      let max = text
        ? text.indexOf('<br') > 0
          ? Math.min(text.indexOf('<br'), this.maxLength)
          : this.maxLength
        : 0
      return this.truncate
        ? text && !_.isEmpty(text) && text.length > max
          ? text.substr(0, max) + '...'
          : text
        : text
    },
  },
  methods: {
    toggleShowMore(toggle) {
      this.showMore = toggle
    },
  },
}
</script>
