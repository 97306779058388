<template>
  <Stubbable :stubbed="isCitationsLoading">
    <template v-slot:content>
      <div class="work-action-cite">
        <b-row>
          <b-col class="cite-pills">
            <b-button
              pill
              variant="outline-primary"
              @click="toggle('apa')"
              :pressed="showOptions === 'apa'"
              id="cite-apa"
              class="mb-2 mr-2">
              APA
            </b-button>
            <b-button
              pill
              variant="outline-primary"
              @click="toggle('mla')"
              :pressed="showOptions === 'mla'"
              id="cite-mla"
              class="mb-2 mr-2">
              MLA
            </b-button>
            <b-button
              pill
              variant="outline-primary"
              @click="toggle('harvard')"
              :pressed="showOptions === 'harvard'"
              id="cite-harvard"
              class="mb-2 mr-2">
              Harvard
            </b-button>
            <b-button
              pill
              variant="outline-primary"
              @click="toggle('wiki')"
              :pressed="showOptions === 'wiki'"
              id="cite-wiki"
              class="mb-2 mr-2">
              Wikipedia
            </b-button>
            <b-button
              pill
              variant="outline-primary"
              @click="toggle('endnote')"
              :pressed="showOptions === 'endnote'"
              id="cite-endnote"
              class="mb-2 mr-2">
              EndNote XML
            </b-button>
            <b-button
              pill
              variant="outline-primary"
              @click="toggle('bibtex')"
              :pressed="showOptions === 'bibtex'"
              id="cite-bibtex"
              class="mb-2 mr-2">
              BibTeX
            </b-button>
          </b-col>
        </b-row>
        <template v-if="isOptionSelected">
          <b-row>
            <b-col>
              <div class="citation">
                <div>
                  <template v-if="canDownload">
                    <pre ref="citationText" id="cite-text">{{ citationText }}</pre>
                  </template>
                  <template v-else>
                    <span ref="citationText" v-html="citationText"></span>
                  </template>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row cols="12">
            <b-col class="cite-options">
              <p class="check">
                Check citations for accuracy before including them in your work.
              </p>
              <b-button
                variant="outline-primary"
                id="cite-copy"
                title="Copy to clipboard"
                class="mr-2"
                @click="copy()">
                Copy to clipboard
              </b-button>
              <b-button
                v-if="canDownload"
                variant="outline-primary"
                id="cite-download"
                title="Download"
                @click="download()">
                Download
              </b-button>
            </b-col>
          </b-row>
        </template>
        <div class="previous d-flex align-content-center mt-3">
          <div class="bevel flex-grow-1"></div>
        </div>
      </div>
    </template>
    <template v-slot:stub>
      <div class="stub">
        <div class="d-flex body">
          <div class="button mr-2 pill" style="width: 3.5rem"></div>
          <div class="button mr-2 pill" style="width: 3.5rem"></div>
          <div class="button mr-2 pill" style="width: 5.3rem"></div>
          <div class="button mr-2 pill" style="width: 6.1rem"></div>
          <div class="button mr-2 pill" style="width: 7.9rem"></div>
          <div class="button mr-2 pill" style="width: 4.7rem"></div>
        </div>
      </div>
    </template>
  </Stubbable>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import saveAs from 'file-saver'
import service from '@/service/service'
import Stubbable from '@/components/Stubbable.vue'

export default {
  name: 'CiteAction',
  components: {
    Stubbable,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    citationId: {
      type: String,
      required: false,
    },
    workType: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      showOptions: undefined,
      citation: {},
      citationText: undefined,
    }
  },
  mounted() {
    var self = this

    self.loadCitations().then(() => {
      self.toggle('apa')
    })
  },
  computed: {
    isCitationsLoading() {
      return _.isEmpty(this.citation)
    },
    canDownload() {
      return this.showOptions == 'endnote' || this.showOptions == 'bibtex'
    },
    isOptionSelected() {
      return this.showOptions != undefined
    },
  },
  methods: {
    toggle(option) {
      let self = this
      let previousOption = self.showOptions
      self.close()
      Vue.nextTick(() => {
        if (option !== previousOption) {
          self.showOptions = option

          this.selectCitation()
        }

        self.$emit('recalc-height')
      })
    },
    selectCitation() {
      let option = this.showOptions
      switch (option) {
        case 'apa':
          this.citationText = this.citation.apa
          break
        case 'mla':
          this.citationText = this.citation.mla
          break
        case 'harvard':
          this.citationText = this.citation.harvard
          break
        case 'wiki':
          this.citationText = this.citation.wikipedia
          break
        case 'endnote':
          this.citationText = this.citation.endNote
          break
        case 'bibtex':
          this.citationText = this.citation.bibtex
          break
        default:
          break
      }

      service._gtm.interaction(this, {
        category: 'buy-borrow-cite',
        action: 'cite-' + option,
        label: this.$route.fullPath,
      })
    },
    close() {
      this.showOptions = undefined
    },
    copy() {
      this.$copyText(this.$refs.citationText.textContent)
    },
    download() {
      let option = this.showOptions
      let downloadable
      let filename = this.title.replace(/\.\s*$/, '')
      switch (option) {
        case 'endnote':
          downloadable = new Blob([this.citation.endNote], {
            type: 'application/xml;charset=utf-8',
          })
          saveAs(downloadable, filename + '.xml')
          break
        case 'bibtex':
          downloadable = new Blob([this.citation.bibtex], {
            type: 'application/x-bibtex;charset=utf-8',
          })
          saveAs(downloadable, filename + '.bib')
          break
        default:
          break
      }
    },
    loadCitations() {
      let citationService = this.workType == 'people' ? service.people : service.work

      if (this.workType == 'people') {
        return citationService.getCitation(this.id).then((response) => {
          this.citation = response
        })
      } else {
        return citationService.getCitation(this.id, this.citationVersionId).then((response) => {
          this.citation = response
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.check {
  color: colour(grey-3);
  font-style: italic;
  font-size: 0.9rem;
}

pre {
  overflow: visible;
  margin-bottom: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  padding: 1.5em;
  max-width: 700px;
}

@supports (font-variation-settings: normal) {
  pre {
    font-family: 'Source Sans Variable', sans-serif;
  }
}

.work-action-cite {
  .cite-pills {
    button.btn {
      border-radius: 10rem;
      color: colour(purple);
      border: 2px solid colour(purple);
      font-weight: 600;
      transition: 0.5s ease-in-out;
      &:hover,
      &:focus {
        background: colour(purple);
        color: colour(white);
      }
      &:not(.disabled).active {
        background: colour(purple);
        color: colour(white);
        box-shadow: none;
      }
    }
  }
}

.citation {
  overflow: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  border: 1px solid colour(grey-3);
  padding: 1.5em;
  max-width: 700px;
}
.previous {
  width: 100%;
}
.previous .bevel {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  height: 0;
  border-left: 0 solid #f4f4f4;
  border-top: 48px solid #f4f4f4;
  border-right: 0.80001em solid transparent;
}
.cite-options {
  button {
    margin-top: 1em;
    margin-bottom: 1em;
    background: colour(green);
    color: colour(white);
    transition: 0.5s ease-in-out;
    border: 2px solid colour(green);
    font-weight: 600;
    &:hover,
    &:focus {
      background: colour(white);
      color: colour(green);
    }
  }
}
</style>
