<style lang="scss">
.version-filter {
  background-color: colour(grey);
  padding: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 575px) {
    div:nth-of-type(2) {
      flex-wrap: wrap;
    }
  }
  .actions {
    text-align: right;
  }

  .close {
    margin-bottom: 1rem;
    text-shadow: none;
    display: block;
    text-align: right;
    button {
      color: colour(purple);
      padding-right: 0;
      padding-top: 0;
      height: 2.5em;
      min-width: 6em;
      &:hover,
      &:focus,
      &:active {
        color: colour(magenta);
        text-decoration: underline;
      }
      svg {
        font-size: 22px !important;
        margin-left: 5px;
        position: relative;
        top: 2px;
      }
    }
  }
}
</style>

<script>
import filtering from '@/mixins/filtering'

export default {
  name: 'Filter',
  mixins: [filtering],
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
