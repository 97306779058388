<template>
  <div v-if="visible" class="alert mt-1 d-flex" :class="alert.impact">
    <div class="icon">
      <font-awesome-icon :icon="icon" size="2x" />
    </div>
    <div class="content">
      <span v-html="sanitizeHtml(alert.summary)"></span>
      <a :href="alert.link" v-if="alert.link">{{ alert.action }}</a>
      <router-link v-if="alert.routerLink" :to="alert.routerLink">{{ alert.action }}</router-link>
    </div>
    <div v-if="!alert.fromCMS" class="close-button">
      <b-button variant="link" @click="close"
        >Close<font-awesome-icon :icon="{ prefix: 'fal', iconName: 'times' }"
      /></b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.alert {
  border-radius: 3px;
  align-items: center;

  &.Information,
  &.information,
  &.info,
  &.warning {
    background-color: colour(yellow);
    color: black;

    a {
      color: black;
      font-weight: 600;
      margin-left: 0.35rem;
    }
  }

  &.Outage,
  &.outage,
  &.error {
    background-color: colour(orange);
    color: black;

    a {
      color: black;
      font-weight: 600;
      margin-left: 0.35rem;
    }
  }

  .icon {
    svg {
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  }

  .content {
    flex: 1;
  }

  .close-button {
    text-shadow: none;
    text-align: right;
    button {
      color: colour(purple);
      padding-right: 0;
      padding-top: 0;
      min-width: 6em;
      &:hover,
      &:focus,
      &:active {
        color: colour(magenta);
        text-decoration: underline;
      }
      svg {
        font-size: 22px !important;
        margin-left: 5px;
        position: relative;
        top: 2px;
      }
    }
  }
}
</style>
<script>
import _ from 'lodash'
import utils from '@/mixins/utils.js'

export default {
  name: 'Alert',
  props: {
    alert: {
      type: Object,
      required: true,
    },
    autohide: {
      type: Boolean,
      default: true,
    },
    shown: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      visible: true,
    }
  },
  mixins: [utils],
  computed: {
    icon() {
      var icon
      if (_.toLower(this.alert.impact).indexOf('info') > -1) {
        icon = { prefix: 'fal', iconName: 'info-circle' }
      } else if (
        _.toLower(this.alert.impact) === 'outage' ||
        _.toLower(this.alert.impact) === 'error'
      ) {
        icon = { prefix: 'fal', iconName: 'exclamation-triangle' }
      } else if (_.toLower(this.alert.impact) === 'warning') {
        icon = { prefix: 'fal', iconName: 'exclamation-circle' }
      }
      return icon
    },
  },
  methods: {
    hide() {
      this.visible = true
      if (this.autohide && _.toLower(this.alert.impact).indexOf('info') > -1) {
        var self = this
        setTimeout(function () {
          self.visible = false
        }, this.shown * 1000)
      }
    },
    close() {
      this.visible = false
    },
  },
  mounted() {
    if (!_.isEmpty(this.alert)) {
      this.hide()
    }
  },
  watch: {
    alert: function (newVal) {
      if (!_.isEmpty(newVal)) {
        this.hide()
      }
    },
  },
}
</script>
