<template>
  <div v-if="visible && achievements && achievements.length > 0" class="achievements">
    <div class="close-button">
      <b-button variant="link" @click="close"
        >Close<font-awesome-icon :icon="{ prefix: 'fal', iconName: 'times' }"
      /></b-button>
    </div>
    <div class="achievement mt-1" v-for="achievement in achievements" :key="achievement">
      <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'trophy' }" size="2x" />
      {{ achievement }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.achievements {
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  background-color: colour(yellow-tint80);

  div {
    position: initial;
  }

  .close-button {
    margin-bottom: 1rem;
    text-shadow: none;
    text-align: right;
    float: right;
    button {
      color: colour(purple);
      padding-right: 0;
      padding-top: 0;
      height: 2.5em;
      min-width: 6em;
      &:hover,
      &:focus,
      &:active {
        color: colour(magenta);
        text-decoration: underline;
      }
      svg {
        font-size: 22px !important;
        margin-left: 5px;
        position: relative;
        top: 2px;
      }
    }
  }
}

.achievement {
  padding: 0.5rem;
  width: 95%;

  color: black;

  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'AchievementNotice',
  props: {
    userAchievement: {
      type: Object,
      required: false,
    },
    autohide: {
      type: Boolean,
      default: true,
    },
    shown: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      visible: true,
    }
  },
  computed: {
    achievements() {
      let messages = []
      if (
        this.userAchievement &&
        !_.isEmpty(this.userAchievement) &&
        this.userAchievement.achievements.length > 0
      ) {
        messages = this.userAchievement.achievements
      }
      return messages
    },
  },
  watch: {
    userAchievement: function (newVal) {
      this.visible = true
      if (!_.isEmpty(newVal)) {
        this.hide()
      }
    },
  },
  methods: {
    hide() {
      this.visible = true
      if (this.autohide) {
        var self = this
        setTimeout(function () {
          self.visible = false
        }, this.shown * 1000)
      }
    },
    close() {
      this.visible = false
    },
  },
  mounted() {
    if (!_.isEmpty(this.userAchievement)) {
      this.hide()
    }
  },
}
</script>
