<template>
  <b-container>
    <b-row no-gutters>
      <b-col>
        <Alert
          class="mt-4"
          :autohide="autohide"
          v-for="alert in alerts"
          :alert="alert"
          :key="alert.summary"></Alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import service from '@/service/service'
import Alert from '@/components/Alert.vue'

export default {
  name: 'Alerts',
  components: {
    Alert,
  },
  props: {
    autohide: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      alerts: [],
    }
  },
  watch: {
    $route() {
      this.refresh()
    },
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      let self = this
      service.config.alerts().then((a) => {
        self.alerts = _.filter(a, self.dateFilter)
      })
    },
    dateFilter(alert) {
      if (alert.displayFrom) {
        if (alert.displayTo) {
          return moment.utc().isBetween(alert.displayFrom, alert.displayTo)
        } else {
          return moment.utc().isAfter(alert.displayFrom)
        }
      } else if (alert.displayTo) {
        return moment.utc().isAfter(alert.displayTo)
      }
      return true
    },
  },
}
</script>
