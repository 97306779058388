<template>
  <div class="list-thumbnail-selector">
    <b-modal
      id="listThumbnailSelector"
      size="lg"
      centered
      @show="loadAvailableThumbnails"
      :visible="showModal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true">
      <b-container fluid>
        <div class="error" v-if="error && error.summary">
          <Alert :alert="error"></Alert>
        </div>

        <div class="available-thumbnails">
          <p v-if="showNoThumbnailsMessage"> There are no available thumbnails on this list </p>
          <div
            class="thumbnail-wrapper"
            v-for="(thumb, index) in goodThumbnails"
            :key="thumb.itemId + '-' + index">
            <a
              href="javascript:void(0)"
              @click="selectThumbnail(thumb)"
              class="select-link"
              v-if="!thumb.failed"
              :class="{
                selected: selectedThumbnail && thumb.itemId === selectedThumbnail.itemId,
              }">
              <Thumbnail
                :key="thumb.itemId + '-' + index"
                :url="thumb.url"
                class="thumbnail"
                size="small"
                :lazy="false"
                @load-failed="removeFailedThumbnail(thumb)"
                @load-succeeded="thumbnailLoaded(thumb)"
                :show-placeholder="false"></Thumbnail>
            </a>
          </div>
          <div v-if="loading" class="loading-spinner spinner-md"></div>
        </div>
      </b-container>
      <div slot="modal-footer">
        <b-button
          variant="link"
          @click="loadNextPage"
          v-if="fetched < totalItemCount - 1 && !loading && pendingThumbnails === 0"
          class="load-more"
          >Load more</b-button
        >
        <b-button variant="link" @click="cancel">Cancel</b-button>
        <b-button @click="changeThumbnail" :disabled="!selectedThumbnail.itemId || inProgress"
          >Update thumbnail</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.list-thumbnail-selector {
}

.available-thumbnails {
  display: flex;
  flex-wrap: wrap;
  max-height: 330px;
  overflow: scroll;

  .thumbnail-wrapper {
    margin: 0.5rem;

    .select-link {
      display: inline-block;
      border-radius: 5px;
      padding: 2px;
      border: 6px solid colour(white);

      &:hover {
        border: 6px solid colour(green);
      }

      &.selected {
        border: 6px solid colour(orange);

        &:hover {
          border: 6px solid colour(orange);
        }
      }
    }
  }
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.load-more {
  position: absolute;
  left: 1rem;
}
</style>

<script>
import _ from 'lodash'
import Vue from 'vue'
import auth from '@/mixins/auth'
import service from '@/service/service'
import Thumbnail from '@/components/Thumbnail'
import Alert from '@/components/Alert'

export default {
  name: 'ListThumbnailSelector',
  mixins: [auth],
  components: {
    Thumbnail,
    Alert,
  },
  props: {
    listId: {
      type: [String, Number],
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    totalItemCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      availableThumbnails: [],
      selectedThumbnail: { itemId: undefined, thumbnail: undefined },
      modalOpen: false,
      pageSize: 50,
      page: 1,
      start: 0,
      fetched: 0,
      nextStart: undefined,
      loading: false,
      showNoThumbnailsMessage: false,
      inProgress: false,
    }
  },
  computed: {
    showModal() {
      return this.show || this.modalOpen
    },
    goodThumbnails() {
      return _.filter(this.availableThumbnails, (t) => !t.failed)
    },
    pendingThumbnails() {
      return _.filter(this.availableThumbnails, (t) => !t.loaded).length
    },
  },
  watch: {
    pendingThumbnails(newVal) {
      if (newVal === 0 && this.goodThumbnails.length <= this.page * this.pageSize) {
        let self = this
        self.showNoThumbnailsMessage = self.goodThumbnails.length === 0 && !self.loading

        Vue.nextTick(() => {
          self.loadAvailableThumbnails()
        })
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
    changeThumbnail() {
      let self = this
      self.inProgress = true
      service.list
        .updateListThumbnail(self.listId, self.selectedThumbnail.url)
        .then(() => {
          self.inProgress = false
          self.$emit('close', self.selectedThumbnail.url)
        })
        .catch((error) => {
          self.inProgress = false
          self.handleError(
            error,
            undefined,
            undefined,
            'update the representative image for this list'
          )
        })
    },
    loadNextPage() {
      this.page = this.page + 1
      this.loadAvailableThumbnails()
    },
    loadAvailableThumbnails() {
      let self = this
      self.loading = true
      self.selectedThumbnail = { itemId: undefined, thumbnail: undefined }

      service.list
        .getAvailableThumbnails(self.listId, self.fetched, self.pageSize)
        .then((thumbnails) => {
          self.fetched = self.fetched + self.pageSize
          _.each(thumbnails, (url, itemId) => {
            if (url) {
              let t = {
                itemId: itemId,
                url: url,
                loaded: false,
                failed: false,
              }

              self.availableThumbnails.push(t)
            }
          })
          self.loading = false
        })
        .catch((error) => {
          self.loading = false
          self.handleError(error, undefined, undefined, 'load available thumbnails for this list')
        })
    },
    selectThumbnail(thumb) {
      _.assign(this.selectedThumbnail, thumb)
    },
    removeFailedThumbnail(thumbnail) {
      thumbnail.failed = true
      thumbnail.loaded = true
    },
    thumbnailLoaded(thumbnail) {
      thumbnail.loaded = true
    },
  },
}
</script>
