<template>
  <div :style="style" class="d-flex flex-column">
    <ValidationProvider :rules="subField.validationRules" v-slot="{ errors }" slim>
      <label :for="fieldName" v-if="subField.display && subField.type !== 'checkbox'" class="label"
        >{{ subField.display }}:
      </label>
      <label :for="fieldName" v-if="!subField.display" class="sr-only">{{ subField.name }} </label>

      <b-form-input
        :id="fieldName"
        v-if="subField.type === 'text'"
        v-model="value"
        :placeholder="subField.placeholder"></b-form-input>

      <SmartRangeDatepicker
        :id="fieldName"
        ref="datePickerRef"
        v-if="subField.type === 'date'"
        v-model="value"
        :open-date="subField.openDate"
        :defaultToEndOfRange="subField.defaultToEndOfRange"></SmartRangeDatepicker>

      <b-form-select :id="fieldName" v-if="subField.type === 'select'" v-model="value">
        <option
          v-for="option in subField.options"
          :key="option.text"
          :value="option.value"
          v-html="option.text"></option>
      </b-form-select>

      <b-form-checkbox
        :id="fieldName"
        v-if="subField.type === 'checkbox'"
        v-model="value"
        :value="subField.checked"
        :unchecked-value="subField.unchecked"
        >{{ subField.display }}</b-form-checkbox
      >

      <multiselect
        :id="fieldName"
        v-if="subField.type === 'multiselect'"
        v-model="value"
        :options="subField.options"
        :multiple="true"
        placeholder="Any"
        label="text"
        track-by="value">
        <template slot="option" slot-scope="props">
          <div :style="optionStyle(props.option)">
            <span v-html="props.option.text"></span>
          </div>
        </template>
        <template slot="clear">
          <div
            class="multiselect__clear"
            v-if="value && value.length > 0"
            @mousedown.prevent.stop="clear">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
          </div>
        </template>
      </multiselect>

      <component
        v-if="subField.type === 'custom'"
        :is="subField.component"
        v-bind="subField.props"
        v-on="subField.events" />

      <b-form-text v-if="subField.help">{{ subField.help }}</b-form-text>

      <p class="error">{{ errors[0] }}</p>
    </ValidationProvider>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.label {
  margin-right: 1rem;
}

.help {
  font-size: small;
}

.error {
  color: colour(form-field-error);
  margin-top: 1rem;
}
</style>

<style lang="scss">
.multiselect {
  .multiselect__clear {
    position: absolute;
    right: 2.5rem;
    top: 0.5rem;
    display: block;
    cursor: pointer;
    z-index: 2;
  }
}
</style>

<script>
import _ from 'lodash'
import currentRoute from '@/mixins/currentRoute'
import search from '@/mixins/search'
import Multiselect from 'vue-multiselect'
import SmartRangeDatepicker from '@/components/search/advanced/SmartRangeDatepicker'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'SubField',
  mixins: [search, currentRoute],
  components: {
    Multiselect,
    SmartRangeDatepicker,
    ValidationProvider,
  },
  props: {
    subField: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: null,
      originalValue: null,
    }
  },
  created() {
    if (_.isFunction(this.subField.clear)) {
      this.$emit('clear-handler', this.subField.clear)
    } else {
      this.$emit('clear-handler', this.clear)
    }
  },
  computed: {
    fieldName() {
      return this.subField.name
    },
    style() {
      var style = {}

      if (this.subField.width) {
        style.width = this.subField.width
      }

      return style
    },
  },
  methods: {
    optionStyle(option) {
      return {
        'margin-left': (option.depth || 0) + 'rem',
      }
    },
    clear() {
      let defaultValue = this.subField.default || null
      if (this.subField.type === 'date') {
        this.$refs.datePickerRef.clear(defaultValue)
      } else {
        this.value = defaultValue
      }
    },
    updateMetadata() {
      if (!this.subField.name) {
        return
      }

      this.originalValue = this.fieldValueFromQuery(this.currentQuery, this.subField.name)

      if (_.isFunction(this.subField.valueMapper)) {
        this.originalValue = this.subField.valueMapper(this.subField, this.originalValue)
      }

      this.value = this.originalValue || this.subField.default || null
    },
  },
  watch: {
    currentQuery: {
      immediate: true,
      handler() {
        this.updateMetadata()
      },
    },
    subField: {
      deep: true,
      immediate: true,
      handler() {
        this.updateMetadata()
      },
    },
    value: {
      immediate: true,
      handler() {
        if (!this.subField.name) {
          return
        }

        let mappedValue = this.value

        if (_.isFunction(this.subField.valueReverseMapper)) {
          mappedValue = this.subField.valueReverseMapper(this.subField, this.value)
        }

        if (this.subField.onValueChange && _.isFunction(this.subField.onValueChange)) {
          this.subField.onValueChange(mappedValue, this.value)
        }

        if (this.subField.type === 'date' && !mappedValue) {
          this.clear();
        }

        this.$emit('updated', this.subField.name, mappedValue)
      },
    },
  },
}
</script>
