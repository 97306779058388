<template>
  <div>
    <h3>Apply to access the Trove API</h3>

    <div class="glance">
      <p>At a glance</p>
      <ul>
        <li>Reuse Trove content within your own site or application</li>
        <li>
          Contact the NLA to make an individual API agreement if you wish to use the API for
          commercial purposes
        </li>
        <li> You will have a limited number of API calls available in a given period of time </li>
      </ul>
    </div>

    <!-- eslint-disable vue/no-unused-vars -->
    <ValidationObserver ref="observer" tag="div" key="apiApply" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(apply)" @reset.prevent="clear">
        <b-form-group label="Agreement">
          <b-form-checkbox v-model="form.accept" value="y" :disabled="!isMyProfile">
            I have read and agree to the
            <a href="/about/create-something/using-api/trove-api-terms-use">API terms of use</a>
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          :disabled="!termsAccepted"
          :class="!termsAccepted ? 'text-muted' : ''"
          label="Please select the type of API agreement which best matches your usage">
          <b-form-radio-group v-model="form.agreement">
            <b-form-radio value="noncommercial">Non-commercial</b-form-radio>
            <!-- eslint-disable max-len -->
            <b-form-radio
              value="commercial"
              v-b-tooltip.hover
              title="An example of commercial use is including Trove content in an application that is behind a pay wall."
              >Commercial</b-form-radio
            >
            <!-- eslint-enable max-len -->
          </b-form-radio-group>
        </b-form-group>

        <div v-if="commercialSelected">
          <b-form-group label="Commercial API use">
            <p>
              You have indicated that you will use the Trove API for commercial purposes. Please
              <a :href="contactUsUrl">contact us to negotiate an individual agreement</a>
            </p>
          </b-form-group>
        </div>

        <div v-if="noncommercialSelected">
          <b-form-group label="Organisation">
            <b-form-input
              v-model="form.organisation"
              v-b-tooltip.hover
              title="The name of your organisation"></b-form-input>
          </b-form-group>

          <b-form-group label="What are you going to use the API for? *">
            <ValidationProvider
              :rules="{required: true, max: 2000}"
              v-slot="{ errors }"
              name="Please tell us what you are going to use the API for">
              <!-- eslint-disable max-len -->
              <b-form-textarea
                v-model="form.usage"
                rows="4"
                v-b-tooltip.hover
                title='Briefly describe what you will be using the Trove API for. For example: "including theses in the results returned by mysampleapp.com"'></b-form-textarea>
              <!-- eslint-enable max-len -->
              <div class="hint">
                Please enter no more than 2000 characters
              </div>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="Name *">
            <ValidationProvider rules="required" v-slot="{ errors }" name="Please enter a name">
              <!-- eslint-disable max-len -->
              <b-form-input
                v-model="form.name"
                v-b-tooltip.hover
                title="If Trove staff need to contact your organisation about their usage of the Trove API, who should we talk to? We might want to let you know about changes to the API, or to contact you if your API account is being misused in some way."></b-form-input>
              <!-- eslint-enable max-len -->
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="Email *">
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors }"
              name="Please enter an email address">
              <b-form-input
                v-model="form.email"
                v-b-tooltip.hover
                title="Email address of the Trove API contact person in your organisation"></b-form-input>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="Phone *">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Please enter a phone number">
              <b-form-input
                v-model="form.phone"
                v-b-tooltip.hover
                title="Contact phone number of the Trove API contact person in your organisation"></b-form-input>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </b-form-group>

          <b-button type="submit">Apply for API key</b-button>
        </div>
      </b-form>
    </ValidationObserver>
    <!-- eslint-enable vue/no-unused-vars -->
  </div>
</template>

<style scoped lang="scss">
.error {
  color: colour(form-field-error);
}

.hint {
  font-size: small;
}

.glance {
  margin-top: 1rem;
}
</style>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import service from '@/service/service'
import profile from '@/mixins/profile'
import scroll from 'vue-scrollto'

extend('required', {
  ...required,
  message: '{_field_}',
})

extend('email', email)

const FORM_DEFAULT = {
  accept: 'n',
  agreement: null,
  usage: null,
  organisation: null,
  name: null,
  email: null,
  phone: null,
}

export default {
  name: 'APIApply',
  mixins: [profile],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    contactUsUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      form: FORM_DEFAULT,
    }
  },
  computed: {
    termsAccepted() {
      return this.form.accept === 'y'
    },
    commercialSelected() {
      return this.form.agreement === 'commercial'
    },
    noncommercialSelected() {
      return this.form.agreement === 'noncommercial'
    },
  },
  methods: {
    apply() {
      let self = this

      service.profile.applyForAPIKey(this.form).then((apiDetails) => {
        self.$emit('update-details', apiDetails)
        self.$nextTick(() => scroll.scrollTo('body', 500, { easing: 'ease-out' }));
      })
    },
    clear() {
      this.form = FORM_DEFAULT
    },
  },
}
</script>
