<template>
  <div class="edit-annotation">
    <div class="form">
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid, classes }"
        tag="div"
        key="addAnnotation">
        <ValidationProvider rules="required" v-slot="{ errors }" name="visibility">
          <b-form-group>
            <b-form-radio-group
              v-model="annotation.priv"
              :required="true"
              :class="classes"
              name="noteVisibility">
              <b-form-radio :value="false">Public</b-form-radio>
              <b-form-radio :value="true" :disabled="!authenticated"
                >Private
                <a href="javascript:void(0)" @click="login" v-if="!authenticated"
                  >(login required)</a
                ></b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, classes }" name="note">
          <b-form-group label="Your notes">
            <b-form-textarea
              id="commentContent"
              :class="classes"
              v-model="annotation.content"
              :required="true"
              :maxlength="maxLength"
              rows="3"
              max-rows="6"></b-form-textarea>
          </b-form-group>
          <Countdown :count="lengthLeft" :max="maxLength" :warningCount="800" />
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>

        <div class="buttons">
          <b-button variant="link" @click="cancel" v-if="showCancel">Cancel</b-button>
          <b-button @click="save" :disabled="inProgress">Save</b-button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<style scoped lang="scss">
.edit-annotation {
  padding: 1rem;
  background-color: colour(grey);

  .form {
    width: 100%;
    padding-top: 0.5rem;
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0.5rem;
    }

    .invalid {
      border: solid 1px colour(form-field-error);
    }

    .error {
      color: colour(form-field-error);
    }

    .hint {
      font-size: 0.8rem;
      color: colour(grey-1);
    }
  }

  .buttons {
    text-align: right;
  }

  .close button {
    color: colour(purple);
    padding-right: 0;
    padding-top: 0;
    height: 2.5em;
    min-height: 2.5em;
    min-width: 6em;

    &:hover,
    &:active {
      color: colour(magenta);
      text-decoration: underline;
    }

    svg {
      font-size: 22px !important;
      margin-left: 5px;
      position: relative;
      top: 2px;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import { required } from 'vee-validate/dist/rules.umd'
import auth from '@/mixins/auth'
import Countdown from '@/components/Countdown.vue'

setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'The "{_field_}" field is required',
})

export default {
  name: 'EditAnnotation',
  mixins: [auth],
  components: {
    ValidationProvider,
    ValidationObserver,
    Countdown,
  },
  props: {
    existing: {
      type: Object,
      required: true,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 4000,
    },
    inProgress: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      annotation: {
        userId: undefined,
        content: undefined,
        priv: false,
        target: undefined,
      },
      error: undefined,
    }
  },
  mounted() {
    this.reset()
  },
  computed: {
    lengthLeft() {
      return (
        this.maxLength -
        (this.annotation && this.annotation.content ? this.annotation.content.length : 0)
      )
    },
  },
  methods: {
    save() {
      let self = this
      self.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          self.$emit('save', self.annotation)
        }
      })
    },
    cancel() {
      this.$emit('cancel')
    },
    reset() {
      _.assign(this.annotation, this.existing)
      this.annotation.content = _.replace(this.annotation.content, /<br\/>/g, '\n')
    },
  },
}
</script>
