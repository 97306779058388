<template>
  <Stubbable :stubbed="stubbed" class="coloured-bottom-border" role="listitem">
    <template v-slot:content>
      <div class="result d-flex" :class="isResultSelected ? 'selected' : ''">
        <div class="thumbnail-column">
          <b-link
            v-if="deliverySystemLink"
            :href="deliverySystemLink"
            aria-hidden="true"
            @click="externalLinkClicked">
            <Thumbnail
              :url="result.thumbnailUrl"
              class="thumbnail"
              :title="'Thumbnail for ' + title"
              size="small"
              :show-placeholder="false"
              :blur="blurThumbnail"></Thumbnail>
          </b-link>
          <router-link :to="detailsLinkDescriptor" v-if="!deliverySystemLink" aria-hidden="true">
            <Thumbnail
              :url="result.thumbnailUrl"
              class="thumbnail"
              :title="'Thumbnail for ' + title"
              size="small"
              :show-placeholder="false"
              :blur="blurThumbnail"></Thumbnail>
          </router-link>
        </div>
        <div class="body">
          <div class="title">
            <h3>
              <b-link
                v-if="deliverySystemLink"
                :href="deliverySystemLink"
                v-html="title"
                @click="externalLinkClicked"></b-link>
              <router-link v-else :to="detailsLinkDescriptor" v-html="title"></router-link>
            </h3>
            <span v-if="truncatedTitleLength > 0" class="truncated-title-message"
              >... (not showing {{ truncatedTitleLength }} character{{
                truncatedTitleLength > 1 ? 's' : ''
              }})</span
            >
          </div>

          <div class="metadata">
            <span class="format"
              ><font-awesome-icon v-if="formatIcon" :icon="formatIcon" />{{ format }}</span
            >
            <template v-if="result.firstArticlePublicationName">
              -
              <span class="publication">{{ result.firstArticlePublicationName }}</span></template
            >
            <template v-if="subtitle">
              - <span class="subtitle">{{ subtitle }}</span></template
            >
            <span class="author" v-if="author && showAuthor">
              <template v-if="result.owner"
                ><UserThumbnail
                  :user-summary="result.owner"
                  size="small"
                  :link-username="!result.owner.anonymous"></UserThumbnail
              ></template>
              <template v-else
                ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'user' }" />{{
                  author
                }}</template
              >
            </span>
            <span class="date" v-if="result.date && showDate"
              ><font-awesome-icon :icon="{ prefix: 'far', iconName: 'calendar' }" />{{
                result.date
              }}</span
            >
            <template v-if="result.page && showPage">
              - <span>Page {{ result.page }}</span></template
            >
          </div>

          <div
            class="description"
            v-line-clamp="2"
            v-if="hasDescription"
            v-html="description"></div>
          <div class="snippets" v-if="hasSnippets" v-html="snippets"></div>

          <div class="indicators">
            <div class="url" v-if="result.url">{{ result.url }}</div>
            <OnlineAvailability
              v-if="result && showAvailability"
              :availability="result.onlineText"></OnlineAvailability>
            <FirstAustraliansIndicator
              v-if="result.firstAustraliansInd"></FirstAustraliansIndicator>
            <span class="contributor" v-if="showContributor"
              ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'location-arrow' }" />{{
                contributorText
              }}</span
            >
            <span class="language" v-if="showLanguage"
              ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'globe-asia' }" />{{
                languageText
              }}</span
            >
            <span class="correction" v-if="showCorrection"
              ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit' }" />{{
                correctionText
              }}</span
            >
            <span class="correction" v-if="showCorrectableText"
              ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit' }" />Item can be text
              corrected</span
            >
          </div>

          <div v-if="myTags && myTags.length > 0" class="mytags">
            <ul class="list-inline">
              <li v-for="tag in myTags" :key="tag.name" class="list-inline-item">
                <b-badge>
                  <span class="tag">
                    <font-awesome-icon class="tag-icon" size="sm" :icon="getTagIcon(tag)" />
                    {{ tag.name }}
                    <font-awesome-icon
                      v-if="tag.priv"
                      class="private-icon"
                      size="sm"
                      :icon="{ prefix: 'fa', iconName: 'lock' }" />
                  </span>
                </b-badge>
              </li>
            </ul>
          </div>

          <SearchResultExplain :raw-explain-text="result.searchExplainString"></SearchResultExplain>

          <div class="refineSearch" v-if="refineSearch">
            <b-button class="inverse" :to="refineSearchLink" v-html="refineSearchText"></b-button>
          </div>

          <div class="select" v-if="canSelect">
            <b-form-checkbox v-model="selectedInternal" switch>Selected</b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:stub>
      <div class="d-flex stub">
        <div class="thumbnail"></div>
        <div class="body">
          <div class="line" style="width: 30%"></div>
          <div class="line" style="width: 60%"></div>
          <div class="block"></div>
          <div class="line" style="width: 20%"></div>
        </div>
      </div>
    </template>
  </Stubbable>
</template>

<style lang="scss">
.result {
  .body {
    width: 100%;
  }

  .format {
    font-weight: 700;
    color: colour(black);

    svg {
      margin-right: 0.5em;
      font-size: 1.1rem;
    }
  }

  //After - # items, for mobile responsiveness
  span:nth-of-type(2) {
    @media (max-width: 575px) {
      margin-right: 1.5rem;
    }
  }

  .author,
  .date {
    color: colour(violet);
    padding-left: 1.5em;
    font-weight: 600;

    svg {
      margin-right: 0.5em;
    }

    @media (max-width: 767px) {
      display: inline-block;
      padding-left: 0;
      white-space: pre-line;
    }
  }

  .subtitle {
    @media (max-width: 767px) {
      margin-right: 24px;
    }
  }

  .online,
  .contributor,
  .language,
  .correction,
  .merge-split {
    color: colour(violet);
    display: inline-block;
    font-size: 0.9rem;
    margin-right: 2em;

    svg {
      margin-right: 0.5em;
    }
  }

  .mytags {
    .tag {
      font-size: 0.9rem;
    }
    .badge {
      font-weight: 400;
      background-color: colour(green);
    }
  }

  .merge-split {
    color: color(orange);
  }

  .refineSearch {
    float: right;
    margin-top: 1rem;
  }

  .snippets {
    em {
      font-weight: bold;
      font-style: normal;
    }
  }

  .select {
    margin-top: 0.8rem;
  }
}

.url {
  color: colour(grey-3);
  margin-top: 0.5rem;
  overflow: hidden;
  word-break: break-word;
}

.result,
.result-header {
  max-width: 927px;
  @media (min-width: 767.98px) {
    margin-right: 30px;
  }
}
</style>

<style scoped lang="scss">
$thumbnail-dimension: 80px;
$thumbnail-max-aspect-ratio: 1.5;

.stub {
  .thumbnail {
    width: $thumbnail-dimension;
    height: $thumbnail-dimension * $thumbnail-max-aspect-ratio;
    margin-right: 1em;
    color: colour(grey-6);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .body {
    width: 100%;

    .block {
      color: colour(grey-2);

      .text {
        padding: 0.5rem;
      }
      .reveal {
        margin-left: 0.5rem;
      }
    }
  }
}

.coloured-bottom-border {
  max-width: 927px;
}

.result {
  &.selected {
    background-color: colour(grey);
  }

  .thumbnail {
    margin-right: 1em;

    max-width: 70px;
    min-width: 70px;
    overflow: hidden;
  }

  .title {
    margin-bottom: 0.5rem;

    h3 {
      font-family: 'Source Serif Pro', serif;
      font-size: 1.375rem;
      font-weight: 600;
      color: colour(black);
      display: inline;

      a:visited {
        color: colour(purple-tint80);
      }
    }

    @supports (font-variation-settings: normal) {
      h3 {
        font-family: 'Source Serif Variable', serif;
      }
    }

    .truncated-title-message {
      font-size: 1.1rem;
      color: colour(grey-4);
      font-weight: 600;
      margin-left: 0.25rem;
    }
  }

  .description,
  .snippets {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: colour(grey-3);
  }
}
</style>

<script>
import _ from 'lodash'
import currentRoute from '@/mixins/currentRoute'
import colours from '@/mixins/colours'
import cultural from '@/mixins/cultural'
import utils from '@/mixins/utils'
import mappings from '@/mixins/mappings'
import Thumbnail from '@/components/Thumbnail.vue'
import SearchResultExplain from '@/components/search/SearchResultExplain.vue'

import Stubbable from '@/components/Stubbable.vue'
import OnlineAvailability from '@/components/search/OnlineAvailability.vue'
import FirstAustraliansIndicator from '@/components/FirstAustraliansIndicator.vue'
import UserThumbnail from '@/components/profile/UserThumbnail'

let PRIORITY_LANGUAGE = 'english'
let MAX_LANGUAGES_TO_DISPLAY = 3
let MAX_AUST_LANGUAGES_TO_DISPLAY = 10

export default {
  name: 'SearchResult',
  mixins: [colours, utils, mappings, cultural, currentRoute],
  components: {
    Stubbable,
    OnlineAvailability,
    Thumbnail,
    FirstAustraliansIndicator,
    SearchResultExplain,
    UserThumbnail,
  },
  props: {
    result: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    isSummary: {
      type: Boolean,
      required: false,
      default: true,
    },
    showAvailability: {
      type: Boolean,
      required: false,
      default: true,
    },
    isResultSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    canSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      creatorDisplayLimit: 3,
      maxTitleLength: undefined,
      selectedInternal: false,
    }
  },
  watch: {
    isResultSelected: {
      immediate: true,
      handler(selected) {
        this.selectedInternal = selected
      },
    },
    selectedInternal: {
      immediate: true,
      handler(selected) {
        if (selected !== this.isResultSelected) {
          this.$emit('selected', this.result, selected)
        }
      },
    },
  },
  methods: {
    externalLinkClicked() {
      localStorage.setItem('scrollPosition', window.scrollY)
      localStorage.setItem('scrollRoute', this.$route.fullPath)
    },
    getTagIcon(tag) {
      return tag.priv ? { prefix: 'far', iconName: 'user-tag' } : { prefix: 'fas', iconName: 'tag' }
    },
  },
  computed: {
    stubbed() {
      return _.isEmpty(this.result)
    },
    blurThumbnail() {
      return (
        this.result.thumbnailIsCulturallySensitive &&
        this.suppressContent(this.result.id) &&
        (!this.result.parentId || this.suppressContent(this.result.parentId))
      )
    },
    myTags() {
      var t1 = _.map(this.result.myPrivateTags, (v) => ({
        name: v,
        priv: true,
      }))
      var t2 = _.map(this.result.myPublicTags, (v) => ({
        name: v,
        priv: false,
      }))
      return _.sortBy(_.concat(t1, t2), [(v) => !v.priv, 'name']) // private first
    },
    title() {
      let title = this.result.title
      if (!_.isUndefined(this.maxTitleLength) && title.length > this.maxTitleLength) {
        title = title.substring(0, this.maxTitleLength - 1)
      }
      return title
    },
    truncatedTitleLength() {
      let title = this.result.title
      return !_.isUndefined(this.maxTitleLength) && title.length > this.maxTitleLength
        ? title.length - this.maxTitleLength
        : undefined
    },
    showAuthor() {
      return true
    },
    author() {
      let self = this
      let author = ''

      if (self.result.author) {
        if (self.result.author.indexOf(';') > -1) {
          let authors = _.split(self.result.author, ';')
          if (authors.length > this.creatorDisplayLimit) {
            author =
              _.join(_.take(authors, this.creatorDisplayLimit), '; ') +
              ' +' +
              (authors.length - this.creatorDisplayLimit) +
              ' more'
          } else {
            author = _.join(_.take(authors, this.creatorDisplayLimit), '; ')
          }
        } else {
          author = self.result.author
        }
      }

      return author
    },
    showDate() {
      return false
    },
    showPage() {
      return false
    },
    subtitle() {
      let parts = []

      if (this.result.numItems > 1) {
        parts.push(this.result.numItems + ' editions')
      }

      if (this.result.yearRange) {
        parts.push(this.result.yearRange)
      }

      return parts.join(': ')
    },
    hasDescription() {
      return false
    },
    description() {
      return this.result.description
    },
    hasSnippets() {
      return this.result.snippets && this.result.snippets.length > 0
    },
    snippets() {
      return _.unescape(
        '... ' +
          this.result.snippets.join(' ... ') +
          ' ...' +
          (this.result.wordCount ? ' ' + this.result.wordCount + ' words' : '')
      )
    },
    descriptiveText() {
      return this.result.descriptiveText
    },
    descriptiveSeries() {
      return this.result.descriptiveSeries
    },
    descriptiveLocation() {
      return this.result.descriptiveLocation
    },
    deliverySystemLink() {
      // override for link / immediate navigation to delivery system.
      return this.result.deliverySystemLink ? this.result.deliverySystemLink : null
    },
    detailsLinkDescriptor() {
      return {
        name: 'Work',
        params: {
          id: this.result.id,
        },
        query: this.currentQuery,
      }
    },
    format() {
      return this.mapFormat(this.result.formatDisplay)
    },
    formatIcon() {
      return this.mapFormatIcon(this.format, this.defaultFormatIcon)
    },
    defaultFormatIcon() {
      return { prefix: 'fas', iconName: 'archive' }
    },
    showContributor() {
      return this.result.numHoldings === 1 && this.result.singleHoldingName
    },
    contributorText() {
      if (this.result.numHoldings === 1) {
        return 'At ' + this.result.singleHoldingName
      }

      return ''
    },
    languageText() {
      let text = undefined
      let languages = this.result.languages

      if (languages && languages.length > 0) {
        if (this.result.austLanguages) {
          let withCodes = _.keys(this.result.austLanguages)
          languages = _.filter(
            languages,
            (lang) =>
              withCodes.indexOf(lang) === -1 &&
              lang !== 'undetermined' &&
              lang !== 'australian languages'
          )

          let count = 0
          if (languages.length > MAX_LANGUAGES_TO_DISPLAY) {
            count = languages.length - MAX_LANGUAGES_TO_DISPLAY
            languages = _.take(languages, MAX_LANGUAGES_TO_DISPLAY)
          }

          _.forEach(this.result.austLanguages, (withoutCode) => {
            if (languages.length < MAX_AUST_LANGUAGES_TO_DISPLAY + MAX_LANGUAGES_TO_DISPLAY) {
              languages.push(withoutCode)
            } else {
              count++
            }
          })

          text = _.map(_.uniq(languages), _.upperFirst).join(', ')
          if (count > 0) {
            text += ' +' + count + ' more'
          }
        }
      }
      return text
    },
    showLanguage() {
      return this.languageText && _.toLower(this.languageText) !== PRIORITY_LANGUAGE
    },
    showCorrection() {
      return this.result.numberOfCorrectors > 0
    },
    correctionText() {
      let text = 'Text corrected by '
      if (this.result.correctedByMe) {
        if (this.result.numberOfCorrectors === 1) {
          text = text + 'you'
        } else {
          text =
            text +
            'you and ' +
            (this.result.numberOfCorrectors - 1) +
            ' other Voluntrove' +
            (this.result.numberOfCorrectors > 2 ? 's' : '')
        }
      } else {
        text =
          text +
          this.result.numberOfCorrectors +
          ' Voluntrove' +
          (this.result.numberOfCorrectors > 1 ? 's' : '')
      }
      return text
    },
    showCorrectableText() {
      return this.result.correctableText
    },
    totalFoundString() {
      return (
        this.result.totalFound.toLocaleString() +
        ' result' +
        (this.result.totalFound !== 1 ? 's' : '')
      )
    },
    refineSearch() {
      return false
    },
  },
}
</script>
