import { render, staticRenderFns } from "./FirstAustraliansIndicator.vue?vue&type=template&id=945f6e98&scoped=true&"
import script from "./FirstAustraliansIndicator.vue?vue&type=script&lang=js&"
export * from "./FirstAustraliansIndicator.vue?vue&type=script&lang=js&"
import style0 from "./FirstAustraliansIndicator.vue?vue&type=style&index=0&id=945f6e98&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "945f6e98",
  null
  
)

export default component.exports