export default {
  data() {
    return {
      error: undefined,
    }
  },
  methods: {
    notFoundMessage(entityType, id) {
      if (!entityType) {
        entityType = 'record'
      }
      let label = 'A'
      if (entityType.match(/[aeiou].*/).length > 1) {
        label = label + 'n'
      }
      label = label + ' ' + entityType
      if (id) {
        label = label + ' with id ' + id
      } else {
        label = label + ' with the requested id'
      }
      label = label + ' could not be found'
      return label
    },
    /**
     * This function will attempt to provide common error handling for ajax calls.
     *
     * Assumes that the component will render an Alert component when there is an error.
     *
     * Sets a data property called object 'error' which be passed as-is to an Alert.
     *
     * @param error The Axios error response object
     * @param entityType The type of entity being worked with. Will default to 'record'
     *  if not provided. Used to build messages like 'The [entityType] could not be found'.
     * @param id The id of the entity being worked with. Will default 'the requested id'
     *  if not provided. Used to build messages like 'The [entityType] with id [id] could not be found'
     * @param actionText The text used to describe the action taken, in a sentence starting
     *  with "An unexpected error has occurred while attempting to [action]. Defaults to
     *  'perform the requested action' if not provided
     */
    handleError(error, entityType, id, actionText) {
      let self = this

      if (error.response) {
        if (error.response.status === 404) {
          self.error = {
            summary: self.notFoundMessage(entityType, id),
            impact: 'info',
          }
        } else if (error.response.status === 410) {
          self.error = {
            summary:
              'The ' +
              (entityType || 'record') +
              (id ? ' with id ' + id : '') +
              ' has been deleted.',
            impact: 'info',
          }
        } else if (error.response.status === 401 || error.response.status === 403) {
          self.error = {
            summary: 'You do not have permission to perform the requested action',
            impact: 'error',
          }
        } else {
          self.error = {
            summary:
              'An unexpected error has occurred while attempting to ' +
              (actionText || 'perform the requested action'),
            impact: 'error',
          }
        }
      } else {
        self.error = {
          summary:
            'An unexpected error has occurred while attempting to ' +
            (actionText || 'perform the requested action'),
          impact: 'error',
        }
      }
    },
  },
}
