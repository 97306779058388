<template>
  <span class="visibility-ind" :class="{ faint: faint }">
    <PossibleLink :route="faint ? { name: 'ProfileSettings' } : undefined">
      <font-awesome-icon :icon="{ prefix: 'fas', iconName: visible ? 'eye' : 'eye-slash' }" />
    </PossibleLink>
  </span>
</template>

<style scoped lang="scss">
.visibility-ind {
  font-size: 16px;
  margin-left: 1rem;
  color: colour(orange) !important;

  a {
    color: colour(orange) !important;
  }

  &.faint {
    color: colour(grey-5) !important;

    a {
      color: colour(grey-5) !important;
    }
  }
}
</style>

<script>
import PossibleLink from '@/components/common/PossibleLink.vue'

export default {
  name: 'VisibilityInd',
  components: {
    PossibleLink,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    faint: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
