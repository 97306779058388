<template>
  <b-container class="root-container">
    <template>
      <b-row>
        <b-col sm="12" md="9">
          <b-card no-body>
            <b-tabs content-class="mt-3" pills lazy>
              <b-tab title="Notes" active>
                <ModerateContentTab
                  key="notes"
                  itemName="note"
                  itemNamePlural="notes"
                  :profile-info="profileInfo" />
              </b-tab>
              <b-tab title="Tags">
                <ModerateContentTab
                  key="tags"
                  itemName="tag"
                  itemNamePlural="tags"
                  :profile-info="profileInfo" />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<style scoped lang="scss">
.root-container {
  padding-top: 1em;
  padding-bottom: 1em;

  ::v-deep .card {
    margin-top: 1rem;
    width: 100%;
    border: none;
    background: transparent;
    display: block;

    .nav-item {
      background: transparent;
      border: none;

      .active {
        color: colour(white);
        background-color: colour(purple);
      }
    }

    .nav-link {
      background-color: colour(white);
      color: colour(purple);
      border: 2px solid colour(purple);
      padding: 5px 10px;
      margin-right: 1rem;
      border-radius: 25px;
      font-size: 1rem;
      font-weight: 600;
      transition: 0.5s ease-in-out;

      .active {
        color: colour(white);
        background-color: colour(purple);
      }

      &:hover,
      &:focus {
        background: colour(purple);
        color: colour(white);
      }
    }
  }

  .count {
    font-size: 2rem;
    font-weight: 500;
    color: colour(green);
    padding-right: 0.5rem;
  }

  .header-text {
    font-size: 2rem;
    padding-right: 0.5rem;
  }

  .metadata {
    width: 6rem;
    margin-right: 1rem;
    text-align: left;

    .date {
      text-transform: uppercase;
      display: inline-block;
    }

    .date,
    .time,
    .visibility {
      text-transform: uppercase;
      display: block;
    }
  }

  .summary {
    flex: 1;
    display: inline-block;
    padding-right: 0;
    padding-left: 0;

    .annotated-item-title {
      font-family: 'Source Sans Pro', sans-serif !important;
      font-size: 1.25rem;
      font-weight: normal;
    }
  }

  .creator {
    float: right;
  }
}
</style>

<script>
import ModerateContentTab from './ModerateContentTab'
import profile from '@/mixins/profile'

export default {
  name: 'ModerateContent',
  mixins: [profile],
  components: { ModerateContentTab },
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const hasPermission = this.isMyProfile && this.profileInfo.contentManager
    if (!hasPermission) {
      this.$router.push('/')
    }
  },
}
</script>
