<template>
  <multiselect
    class="property-filter"
    v-model="values"
    :multiple="true"
    :options="filterSpecification.options || []"
    :placeholder="filterSpecification.placeholder"
    :label="filterSpecification.label || 'display'"
    :track-by="filterSpecification.valueField || 'value'">
  </multiselect>
</template>

<style lang="scss">
.property-filter {
  .multiselect__placeholder {
    color: colour(purple);
    font-size: 1rem;
    font-weight: 600;
  }
}

@media (max-width: 575px) {
  .property-filter {
    width: 90vw;
    margin-top: 1rem;
  }
}

.multiselect__tag {
  background: colour(green);
}

.multiselect__option--highlight {
  background: colour(green);

  &:after {
    background: colour(green);
  }
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--group-selected.multiselect__option--highlight {
  background: colour(blue);
  &:after {
    background: colour(blue);
  }
}

.multiselect__tag-icon {
  background: colour(green);

  &:hover,
  &:active {
    background: colour(green-tint80);
  }

  &:after {
    color: white;
  }
}
</style>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'PropertyFilter',
  components: {
    Multiselect,
  },
  props: {
    filterSpecification: {
      type: Object,
      required: true,
    },
    defaultSelected: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      values: this.filterSpecification.defaultSelected || [],
    }
  },
  methods: {
    updateFilter() {
      var self = this

      var filter = (item) => {
        return self.filterSpecification.filter(item, self.values)
      }

      this.$emit('filter-updated', filter, [])
    },
  },
  watch: {
    values: {
      immediate: true,
      handler() {
        this.updateFilter()
      },
    },
  },
}
</script>
