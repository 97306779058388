<template>
  <li>
    <b-form-checkbox v-model="selected" :class="selected ? 'selected' : ''">
      <router-link :to="link">{{ term.displayTerm }} ({{ termCountFormatted }})</router-link>
    </b-form-checkbox>
    <template v-if="hasSubTerms">
      <TermList :facet="facet" :terms="term.subTerms" />
    </template>
  </li>
</template>

<style scoped lang="scss">
.selected {
  font-weight: 600;
}
</style>

<script>
import numbro from 'numbro'
import navigation from '@/mixins/navigation'

export default {
  name: 'Term',
  mixins: [navigation],
  components: {
    TermList: () => import('@/components/search/facets/TermList.vue'), // TODO: Why not a regular import?
  },
  props: {
    facet: {
      type: Object,
      required: true,
    },
    term: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: this.term.selected,
    }
  },
  computed: {
    link() {
      return this.generateFacetLink(this.facet.code, this.term.term)
    },
    hasSubTerms() {
      return this.term.subTerms && this.term.subTerms.length > 0
    },
    termCountFormatted() {
      if (!this.term) {
        return ''
      }

      return numbro(this.term.count || 0).format({ average: true })
    },
  },
  watch: {
    selected() {
      this.$router.push(this.link)
    },
  },
}
</script>
