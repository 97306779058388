<template>
  <b-navbar toggleable="lg">
    <b-navbar-toggle target="collapse-categories" class="toggle"
      >Categories <i class="fas fa-caret-down"></i
    ></b-navbar-toggle>
    <b-collapse is-nav id="collapse-categories">
      <Nav
        :items="items"
        :selectedId="sId"
        eventName="collection-changed"
        @collection-changed="selectedCollectionUpdated" />
    </b-collapse>
  </b-navbar>
</template>

<style scoped lang="scss">
.toggle,
.navbar-light .navbar-toggler {
  margin-right: 0;
  font-weight: 600;
  background-color: transparent;
  color: colour(purple);
  border: none;
  font-size: 1rem !important;
  padding: 0;

  &[aria-expanded='true'] svg {
    rotate: 180deg;
  }
  &[aria-expanded='false'] svg {
    rotate: 0deg;
  }

  @media (min-width: 992px) {
    display: none;
  }
}
</style>

<script>
import _ from 'lodash'
import navigation from '@/mixins/navigation'
import Nav from '@/components/common/Nav.vue'
import service from '@/service/service'

export default {
  name: 'CollectionsBar',
  components: {
    Nav,
  },
  mixins: [navigation],
  props: {
    collections: {
      type: Array,
      required: true,
    },
    selectedCollectionId: {
      type: String,
      required: true,
    },
    advanced: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    sId: function () {
      return this.selectedCollectionId || 'all'
    },
  },
  watch: {
    collections() {
      this.mapItems()
    },
    urlNamespace() {
      this.mapItems()
    },
    urlKey() {
      this.mapItems()
    },
    currentQuery() {
      this.mapItems()
    },
    advanced() {
      this.mapItems()
    },
  },
  methods: {
    mapItems() {
      let self = this

      let allItem = {
        name: 'All',
        id: 'all',
        routerLink: self.generateCollectionLink(null, self.advanced),
      }

      this.items = [allItem].concat(
        _.map(this.collections, (collection) => {
          return _.assign({}, collection, {
            routerLink: self.generateCollectionLink(collection, self.advanced),
          })
        })
      )
    },
    selectedCollectionUpdated(collection) {
      service._gtm.interaction(this, {
        category: 'Search',
        action: 'Collections Bar: ' + collection.name,
      })
    },
  },
}
</script>
