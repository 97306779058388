<template>
  <div class="user-notification">
    <div class="date">{{ formattedDate }}</div>
    <font-awesome-icon class="tag-icon" :icon="{ prefix: 'fas', iconName: 'trophy' }" />
    <span v-if="details.achievements.length === 1">{{ details.achievements[0] }}</span>
    <div v-if="details.achievements.length > 1" class="list">
      You achieved the following
      <ul>
        <li v-for="(achievement, index) in details.achievements" :key="achievement + index">
          {{ achievement }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-notification {
  .list {
    display: inline;
  }
}
</style>

<script>
import AbstractNotificationDetail from './AbstractNotificationDetail'

export default {
  name: 'UserAchievement',
  extends: AbstractNotificationDetail,
}
</script>
