<template>
  <div class="trove-nav">
    <nav :class="variant">
      <div class="navrow" :no-gutters="isSmallVariant()">
        <ul>
          <li v-for="item in items" :key="item.id">
            <router-link
              v-if="item.routerLink"
              @click="changeItem(item)"
              :to="item.routerLink"
              :class="[variant, isSelected(item.id) ? 'selected' : '']">
              {{ item.name }}
            </router-link>
            <b-link
              v-if="!item.routerLink"
              :href="item.url && item.url !== '/' ? item.url : 'javascript:void(0)'"
              :class="[variant, isSelected(item.id) ? 'selected' : '']">
              <div @click="changeItem(item)">{{ item.name }}</div>
            </b-link>
          </li>
          <slot></slot>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    items: {
      type: Array,
      required: true,
    },
    eventName: {
      // eventName to fire
      type: String,
      required: true,
    },
    selectedId: {
      type: String,
      required: false,
    },
    variant: {
      // small, large
      type: String,
      required: false,
    },
    textNoWrap: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    isSelected(itemId) {
      return itemId && itemId === this.selectedId
    },
    changeItem(item) {
      this.$emit(this.eventName, item)
    },
    isLargeVariant() {
      return this.variant == 'nav-large'
    },
    isSmallVariant() {
      return this.variant == 'nav-small'
    },
  },
}
</script>

<style scoped lang="scss">
.trove-nav nav {
  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    li {
      display: inline-block;
    }
  }
}

.trove-nav nav .navrow {
  border-bottom: 1px solid #888; /* #2D004B */
  line-height: 1.25;
}

.trove-nav nav.nav-small .navrow {
  border-bottom: none;
  text-align: right;
}

.trove-nav nav.nav-large .navrow {
  border-bottom: none;
}

.trove-nav nav a {
  padding-bottom: 1.5em;
  color: #000;
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.55s;
  display: inline-block;
  padding-left: 1em;
  padding-right: 1em;
  height: 100%;
  position: relative;
  top: 2px;

  @media (max-width: 991px) {
    padding-bottom: 1em;
    font-size: 1rem;
  }
}

.trove-nav nav.nav-small a {
  border-bottom: 2px solid transparent;
  font-weight: 600;
  font-size: 0.875rem;
  color: colour(grey-4);
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 3px;
  padding: 0 0 17.5px 0;

  &:hover,
  &:focus {
    color: colour(magenta);
    border-bottom: 2px solid colour(magenta);
  }

  @media (max-width: 767px) {
    font-size: 1.125rem;
  }
}

.trove-nav nav.nav-large a {
  color: colour(black);
  padding: 0.9rem 0;
  padding-bottom: 16px;
  margin-left: 27.5px;
  margin-right: 27.5px;
  border-bottom: 7px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: 812px) {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (max-width: 575px) {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 0;
  }
}

.trove-nav nav a:focus,
.trove-nav nav a:hover {
  color: #000;
  text-decoration: none;
  border-bottom: 3px solid colour(green);
  transition: 0.15s;
}

.trove-nav nav.nav-large a:focus,
.trove-nav nav.nav-large a:hover {
  border-bottom: 5px solid colour(green);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.trove-nav nav a.selected {
  border-bottom: 3px solid colour(purple);
}

.trove-nav nav.nav-small a.selected {
  border-bottom: 1px solid colour(purple);
}

.trove-nav nav.nav-large a.selected {
  color: #2d004b;
  border-bottom: 5px solid colour(purple);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.trove-nav nav {
  font-size: 0.9rem;
}

.trove-nav nav.nav-small {
  text-transform: uppercase;
  font-size: 0.7rem;

  @media screen and (max-width: 767px) {
    ul {
      text-align: left;

      li:first-child {
        padding-top: 1em;
        border-top: 1px solid #ddd;
        margin-top: 1.3rem;
      }

      li {
        display: block;
        clear: right;
      }

      li:last-child {
        padding-bottom: 1em;
      }
    }
  }
}

.trove-nav nav a.nav-large {
  font-weight: 700;
  font-size: 1.3125rem;
}

.collections-container .trove-nav ul {
  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14%, 1fr));
    grid-row-gap: 1rem;
    justify-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-auto-rows: 1fr;
    grid-column-gap: 1rem;
    margin-bottom: 10px;

    li {
      width: 100%;
      height: 100%;

      a {
        width: inherit;
        height: inherit;
        padding: 0.5rem 0;
      }
    }
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-column-gap: 0.75rem;
  }
}
</style>
