<template>
  <span class="online-availability-badge" v-if="show">
    <span v-if="accessConditions" class="access-conditions">
      <font-awesome-icon :icon="icon"></font-awesome-icon>Access conditions apply
    </span>
    <span v-if="notAvailable" class="not-available">
      <font-awesome-icon :icon="icon"></font-awesome-icon>Not available online
    </span>
  </span>
</template>

<style scoped lang="scss">
.online-availability-badge {
  color: colour(violet);
  display: inline-block;
  font-size: 0.9rem;
  margin-right: 1rem;

  .access-conditions {
  }

  .not-available {
  }

  svg {
    margin-right: 10px;
  }
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'OnlineAvailability',
  props: {
    availability: {
      type: String,
      required: false,
    },
  },
  computed: {
    notAvailable: function () {
      return (
        _.toLower(this.availability) == 'n' ||
        _.toLower(this.availability) == 'not available online'
      )
    },
    accessConditions: function () {
      return (
        _.toLower(this.availability) &&
        (_.toLower(this.availability) == 'y/r' ||
          _.toLower(this.availability).indexOf('conditions') > -1)
      )
    },
    show: function () {
      return this.notAvailable || this.accessConditions
    },
    icon: function () {
      return this.notAvailable ? 'info-circle' : this.accessConditions ? 'lock' : ''
    },
  },
}
</script>
