<template>
  <b-container>
    <b-row>
      <b-col>
        <b-tabs card>
          <b-tab v-for="f in dFacets" :key="f.code">
            <template v-slot:title>
              {{ getDisplayName(f.code) }}
            </template>
            <b-row>
              <b-col cols="md-3" class="order-md-2">
                <h1 class="bevel">{{ getLongName(f.code) }}</h1>
                <p v-for="desc in getDescription(f.code)" :key="desc">
                  {{ desc }}
                </p>
              </b-col>
              <b-col cols="md-9" class="order-md-1">
                <component
                  class="vis"
                  :is="f.component"
                  v-bind="f.props"
                  :facet="facetValue[f.code]"
                  :facetCode="f.code"
                  :loading="loading"
                  @search-term-changed="searchTermChanged"
                  :searchHandler="searchHandler" />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

    <b-row ref="terms">
      <b-col class="terms-container">
        <template v-for="facet in facets">
          <b-badge v-for="term in selectedFacetTerms(facet)" :key="term.term" pill variant="primary"
            >{{ facet.displayName }}: {{ term.displayTerm }}
            <router-link :to="generateFacetLink(facet.code, term.term)"
              ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }"
            /></router-link>
          </b-badge>
        </template>
        <template v-if="dateRangeTerms">
          <b-badge pill variant="primary"
            >Years: {{ dateRangeTerms }}
            <b-link
              ><div @click="removeDateRangeTerms">
                <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" /></div
            ></b-link>
          </b-badge>
        </template>

        <router-link v-if="hasSelectedFacetTerms" :to="generateCurrentPageLinkWithOnlyKeyword()"
          >Clear all</router-link
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col class="results-container">
        <template>
          <CollectionExploreResult
            v-for="collection in collectionsOverride"
            :key="collection.id"
            @facets-updated="updateFacets"
            @search-initiated="searchInitiated"
            @search-result="updateLatestSearchResult"
            @global-search-error="globalSearchErrorOccurred"
            @order-changed="orderChanged"
            :collection="collection"
            :isSummary="false" />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
// ie11 target, override ie11 default 150px.
_:-ms-lang(x),
.vis {
  height: 300px;
}

.vis {
  max-width: 927px;
}

.visinfo {
  color: colour(blue);
}

.results-container {
  margin-top: 4em;
}

.collections-container {
  margin-top: 1.5em;
  text-align: center;
  .result-header {
    h1.bevel {
      margin-bottom: 2em;
      font-size: 1.5rem !important;
      &:before {
        top: 0.14em !important;
      }
    }
  }
}

.terms-container {
  .badge {
    margin-right: 0.5rem;
    margin-top: 0.5rem;

    a {
      color: white;
      margin-left: 0.2rem;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import service from '@/service/service'
import currentRoute from '@/mixins/currentRoute'
import navigation from '@/mixins/navigation'
import search from '@/mixins/search'
import utils from '@/mixins/utils'
import SearchBar from '@/components/search/SearchBar.vue'
import CollectionsBar from '@/components/search/CollectionsBar.vue'
import CollectionExploreResult from '@/components/search/CollectionExploreResult.vue'
import Facet from '@/components/search/facets/Facet.vue'
import SearchError from '@/components/search/SearchError.vue'

import WordVis from '@/components/search/vis/WordVis.vue'
import BarTextVis from '@/components/search/vis/BarTextVis.vue'
import NewspapersDateVis from '@/components/search/vis/NewspapersDateVis.vue'
import AustlangMapVis from '@/components/search/vis/AustlangMapVis.vue'
import StateMapVis from '@/components/search/vis/StateMapVis.vue'

var austlangProps = {
  colours: ['special-aust1', 'special-aust2', 'special-aust3'],
}

var purpleProps = {
  colours: ['violet', 'violet-tint80', 'violet-tint60'],
}

var illustrationProps = {
  colours: ['magenta', 'yellow', 'orange', 'green', 'violet'],
}

var threeAngleProps = {
  angles: [0, -45, 45],
}

var searchHandlerMapping = {
  ARTICLE: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  BOOK_LIBRARY: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  BOOK: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  COLLECTION: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis, props: austlangProps },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  NEWSPAPER_AND_GAZETTE: [
    { code: 'filteredstate', component: StateMapVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'category', component: WordVis },
    { code: 'state', component: BarTextVis },
    { code: 'illustrationType', component: WordVis, props: illustrationProps },
    { code: 'pubtag', component: WordVis },
  ],
  GAZETTE: [
    { code: 'filteredstate', component: StateMapVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'category', component: WordVis },
    { code: 'state', component: BarTextVis },
    { code: 'illustrationType', component: WordVis },
    { code: 'pubtag', component: WordVis },
  ],
  NEWSPAPER: [
    { code: 'filteredstate', component: StateMapVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'category', component: WordVis },
    { code: 'state', component: BarTextVis },
    { code: 'illustrationType', component: WordVis, props: illustrationProps },
    { code: 'pubtag', component: WordVis },
  ],
  MAP: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'zoom', component: WordVis, props: purpleProps },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  MAGAZINE: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  MAP_AND_PICTURE: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'zoom', component: WordVis, props: purpleProps },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  MUSIC: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  PICTURE: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  RESEARCH: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  PEOPLE: [
    {
      code: 'austlanguageregion',
      component: AustlangMapVis,
      props: { singular: 'person', plural: 'people' },
    },
    {
      code: 'austlanguage',
      component: WordVis,
      props: { singular: 'person', plural: 'people' },
    },
    {
      code: 'occupation',
      component: WordVis,
      props: { singular: 'person', plural: 'people' },
    },
    {
      code: 'pubtag',
      component: WordVis,
      props: { singular: 'person', plural: 'people' },
    },
  ],
  RESOURCE: [
    { code: 'austlanguageregion', component: AustlangMapVis },
    { code: 'austlanguage', component: WordVis },
    { code: 'year', component: NewspapersDateVis },
    { code: 'subject', component: WordVis },
    { code: 'language', component: WordVis, props: threeAngleProps },
    { code: 'format', component: BarTextVis },
    { code: 'pubtag', component: WordVis },
    { code: 'geocoverage', component: BarTextVis },
  ],
  ALL: [
    { code: 'subject', component: WordVis },
    { code: 'pubtag', component: WordVis },
  ],
}

export default {
  name: 'SearchExplore',
  mixins: [search, utils, currentRoute, navigation],
  components: {
    SearchBar,
    CollectionsBar,
    CollectionExploreResult,
    Facet,
    SearchError,
    WordVis,
    BarTextVis,
    AustlangMapVis,
  },
  props: {
    searchHandler: {
      type: String,
      required: false,
    },
    collectionsOverride: {
      type: Array,
      required: false,
    },
  },
  filters: {
    get: function (obj, path) {
      return _.get(obj, path, '')
    },
  },
  data() {
    return {
      loading: false,
      results: [],
      previousSearch: Boolean(false),
      facets: null,
      latestSearchResult: null,
      scrolling: false,
      facetInformation: null,
      globalSearchError: undefined,
    }
  },
  created() {
    var self = this

    self.$emit('update-breadcrumbs', [
      { link: '/', value: 'Home' },
      { link: '/', value: 'Story' },
      { value: 'Search Results' },
    ])

    service.collection.getExploreFacetInfo().then((config) => {
      this.facetInformation = config
    })
  },
  computed: {
    dateRangeTerms() {
      if (this.currentQuery && this.currentQuery['date.from'] && this.currentQuery['date.to']) {
        return (
          this.currentQuery['date.from'].substr(0, 4) +
          ' through ' +
          this.currentQuery['date.to'].substr(0, 4)
        )
      }
      return null
    },
    hasSelectedFacetTerms() {
      return this.selectedFacetTerms().length > 0
    },
    isCollectionSpecified() {
      return this.urlNamespace && this.urlKey
    },
    selectedCollection() {
      var self = this
      return _(self.collections).find(
        (collection) =>
          collection.id &&
          collection.urlNamespace === self.urlNamespace &&
          collection.urlKey === self.urlKey
      )
    },
    selectedCollectionId() {
      return this.selectedCollection ? this.selectedCollection.id : ''
    },
    showFacets() {
      return this.collectionsOverride.length === 1 && !_.isEmpty(this.facets)
    },
    hasQuery() {
      return !_(this.currentQuery).isEmpty()
    },
    facetValue: function () {
      var fv = {}
      _.forEach(_.compact(this.facets), (f) => {
        fv[f.code] = f
      })
      return fv
    },
    dFacets: function () {
      var s = searchHandlerMapping[this.searchHandler]
      var include = this.collectionsOverride ? this.collectionsOverride[0].exploreByFacets : []
      return _.filter(s, (o) => _.includes(include, o.code))
    },
  },
  methods: {
    removeDateRangeTerms() {
      var newQuery = _.cloneDeep(this.currentQuery)

      delete newQuery['date.from']
      delete newQuery['date.to']
      delete newQuery.startPos
      delete newQuery.pageSize
      delete newQuery.pageTotals

      this.updateSearchParams(this.urlNamespace, this.urlKey, newQuery)
    },
    getDisplayName(code) {
      return _.get(_.find(this.facetInformation, { facet: code }), 'shortname', code)
    },
    getDescription(code) {
      return _.get(_.find(this.facetInformation, { facet: code }), 'description', ['-'])
    },
    getLongName(code) {
      return _.get(_.find(this.facetInformation, { facet: code }), 'longname', '-')
    },
    updateLatestSearchResult(result) {
      this.latestSearchResult = result
    },
    selectedFacetTerms(facet) {
      var self = this

      if (!facet) {
        return _(self.facets)
          .filter((facet) => facet)
          .reduce(
            (selectedTerms, facet) => selectedTerms.concat(self.selectedFacetTerms(facet)),
            []
          )
      }

      var recurseTerms = function (terms) {
        return !terms
          ? []
          : _.reduce(
              terms,
              (allTerms, term) => allTerms.concat([term], recurseTerms(term.subTerms)),
              []
            )
      }

      var selectedFacetTerms = _(recurseTerms(facet.terms))
        .filter((term) => term.selected)
        .map((term) => _.merge(term, { facetCode: facet.code }))
        .value()

      return selectedFacetTerms
    },
    orderChanged(order) {
      var newQuery = Object.assign({}, this.currentQuery, { sortBy: order })

      if (!order) {
        delete newQuery.sortBy
      }

      this.updateSearchParams(this.urlNamespace, this.urlKey, newQuery)
    },
    updateFacets(facets) {
      this.loading = false
      this.facets = facets
    },
    searchInitiated() {
      this.loading = true
    },
    searchTermChanged(term) {
      var newQuery = _.cloneDeep(this.currentQuery)
      for (const i in term) {
        if (term[i] == null) {
          delete newQuery[i]
        } else {
          newQuery[i] = term[i]
        }
      }
      this.updateSearchParams(this.urlNamespace, this.urlKey, newQuery)
    },
    updateSearchParams(urlNamespace, urlKey, q) {
      if (_.isUndefined(urlNamespace) || _.isUndefined(urlKey) || _.isUndefined(q)) {
        return
      }
      var trimmedQuery = _(q)
        .omitBy((value) => _.isArray(value) && _.isEmpty(value))
        .value()
      this.$emit('search-params-updated', urlNamespace, urlKey, trimmedQuery, false)
    },
    globalSearchErrorOccurred(globalError) {
      this.globalSearchError = globalError
    },
  },
}
</script>
