import { render, staticRenderFns } from "./EditListItemNote.vue?vue&type=template&id=87b5eade&scoped=true&"
import script from "./EditListItemNote.vue?vue&type=script&lang=js&"
export * from "./EditListItemNote.vue?vue&type=script&lang=js&"
import style0 from "./EditListItemNote.vue?vue&type=style&index=0&id=87b5eade&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87b5eade",
  null
  
)

export default component.exports