import Vue from 'vue'

export default {
  methods: {
    updateHeight() {
      let self = this
      Vue.nextTick(() => {
        self.$refs.collapsePanel.updateContainerStyle()
      })
    },
    toggle(option) {
      if (!this.showOptions || !this.optionsOpened || this.showOptions === option) {
        this.optionsOpened = !this.optionsOpened
      }

      this.showOptions = option
    },
    open(option, focus) {
      this.showOptions = option
      this.optionsOpened = true
      if (focus) {
        this.$refs[option].focus()
      }
    },
    close() {
      this.optionsOpened = false
    },
    announceAvailableActions() {
      this.$emit('available-actions-updated', {
        canGet: this.canGet,
        canBorrow: this.canBorrow,
        canBuy: this.canBuy,
        canCite: this.canCite,
      })
    },
  },
}
