<template>
  <multiselect
    ref="select"
    v-model="values"
    :id="id"
    label="text"
    track-by="text"
    :placeholder="placeholder"
    :tag-placeholder="tagPlaceholder"
    open-direction="bottom"
    :options="[]"
    :multiple="true"
    :searchable="true"
    :taggable="true"
    :max-height="500"
    :hide-selected="true"
    :allowEmpty="true"
    @tag="tag"
    @close="close">
    <template slot="clear">
      <div class="multiselect__clear" v-if="values.length" @mousedown.prevent.stop="clear">
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </div>
    </template>
    <template slot="noOptions">
      {{ noOptions }}
    </template>
  </multiselect>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect {
  .multiselect__clear {
    position: absolute;
    right: 2.5rem;
    top: 0.5rem;
    display: block;
    cursor: pointer;
    z-index: 2;
  }
}
</style>

<script>
import _ from 'lodash'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Multitagger',
  components: {
    Multiselect,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    tagPlaceholder: {
      type: String,
      required: true,
    },
    noOptions: {
      type: String,
      required: true,
    },
    originalValues: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      values: [],
    }
  },
  created() {
    this.$emit('clear-handler', this.clear)
  },
  methods: {
    tag(item) {
      this.values.push({ text: item })
    },
    clear() {
      this.values = []
    },
    close() {
      // This is a bit of a hack, but seems to work.  We're really reaching in deep.
      // If this ceases working in the future that's fine.  We don't strictly need it.

      if (
        this.$refs.select &&
        this.$refs.select.$refs.search &&
        this.$refs.select.$refs.search._value
      ) {
        var pendingValue = this.$refs.select.$refs.search._value

        if (_.isEmpty(_.find(this.values, (value) => value.text === pendingValue))) {
          this.values.push({ text: pendingValue })
        }
      }
    },
  },
  watch: {
    originalValues: {
      immediate: true,
      handler() {
        this.values = _(this.originalValues || [])
          .map((originalValue) => {
            return { text: originalValue }
          })
          .value()
      },
    },
    values: {
      immediate: true,
      handler() {
        this.$emit(
          'update',
          _(this.values)
            .map((value) => value.text)
            .value()
        )
      },
    },
  },
}
</script>
