<template>
  <section class="section body-text-container">
    <h2 class="bevel purple">{{ title }}</h2>
    <div class="content">
      <h4 v-if="item && item.title">{{ item.title }}</h4>
      <p v-html="item.summary" />
      <div class="pt-3 d-flex flex-row body-text-links">
        <b-button v-if="!isLink1Register" variant="primary" :href="item.actionLink">{{
          item.action
        }}</b-button>
        <b-button
          v-if="isLink1Register"
          variant="primary"
          href="javascript:void(0)"
          @click="register"
          >{{ item.action }}</b-button
        >
        <b-button
          v-if="!isLink2Register"
          variant="primary"
          class="inverse"
          :href="item.action1Link"
          >{{ item.action1 }}</b-button
        >
        <b-button
          v-if="isLink2Register"
          variant="primary"
          href="javascript:void(0)"
          @click="register"
          >{{ item.action1 }}</b-button
        >
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.section {
  margin-bottom: 4em;
}

.body-text-container {
  background-color: white;
  margin-right: 3em;
  padding-left: 15px;

  @media (max-width: 575px) {
    display: none;
  }

  h2.bevel {
    margin-bottom: 2em;
    font-size: 1.5rem;
    font-family: 'Source Sans Pro', sans-serif;
    &:before {
      top: 0.14em !important;
    }
  }
  @supports (font-variation-settings: normal) {
    h2.bevel {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }

  .content {
    padding-left: 52px;

    @media (max-width: 575px) {
      padding-left: 15px;
    }
  }

  .body-text-links {
    .btn {
      line-height: 1.9em !important;
    }
  }
}
</style>

<script>
import auth from '@/mixins/auth'
import Vue from 'vue'
import _ from 'lodash'

export default {
  name: 'BodyText',
  mixins: [auth],
  props: {
    title: {
      type: String,
      required: false,
      default: 'Join the Trove community',
    },
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.checkForSignUpLink()
  },
  watch: {
    item: {
      deep: true,
      handler: () => {
        let self = this
        Vue.nexTick(() => {
          self.checkForSignUpLink()
        })
      },
    },
  },
  data() {
    return {
      isLink1Register: false,
      isLink2Register: false,
    }
  },
  methods: {
    checkForSignUpLink() {
      let action = _.toLower(this.item.action)
      let action1 = _.toLower(this.item.action1)

      if (
        action.indexOf('sign up') > -1 ||
        action.indexOf('sign-up') > -1 ||
        action.indexOf('regist') > -1
      ) {
        this.isLink1Register = true
      }
      if (
        action1.indexOf('sign up') > -1 ||
        action1.indexOf('sign-up') > -1 ||
        action1.indexOf('regist') > -1
      ) {
        this.isLink2Register = true
      }
    },
  },
}
</script>
