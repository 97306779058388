<template>
  <FilterableList
    class="tags"
    :filterConfigs="filterConfigs"
    :filtered="filtered"
    :items="items"
    :loading="loading"
    itemName="tag"
    itemNamePlural="tags"
    :paginationInfo="paginationInfo"
    stubsClass="d-flex flex-wrap"
    :numStubsOverride="30"
    @get-items="getItems"
    @page-changed="pageChanged"
    @reset-filters="resetFilters">
    <template v-slot:header>
      <span class="count">{{ profileInfo.tagCount | formatNumber }}</span>
      <span class="header-text">tag{{ profileInfo.tagCount > 1 ? 's' : '' }} used</span>
    </template>

    <template v-slot:no-items> You have not added any tags </template>

    <template v-slot:list>
      <ul class="list-inline">
        <li v-for="tag in sortedTags" :key="tag.id" class="list-inline-item">
          <b-button-group>
            <b-button
              variant="secondary"
              class="green"
              v-b-popover.hover.top="
                tag.taggedItemCount + ' item' + (tag.taggedItemCount > 1 ? 's' : '')
              "
              :to="tag.to">
              {{ tag.name }}
              <font-awesome-icon
                class="private-icon"
                v-if="tag.priv"
                :icon="{ prefix: 'fa', iconName: 'lock' }" />
            </b-button>
          </b-button-group>
        </li>
      </ul>
    </template>

    <template v-slot:stub>
      <div class="d-flex stub">
        <div class="body" style="width: 5rem">
          <div class="line" style="width: 100%; height: 2rem"></div>
        </div>
      </div>
    </template>
  </FilterableList>
</template>

<style scoped lang="scss">
.stub {
  margin-right: 1rem;
  padding: 0;
}

.tags {
  padding: 1rem;
}

.tag-icon {
  margin-right: 0.5rem;
}

.private-icon,
.wait-icon {
  margin-left: 0.5rem;
}

.list-inline-item {
  margin-bottom: 1rem;
}
</style>

<script>
import _ from 'lodash'
import service from '@/service/service'
import profile from '@/mixins/profile'
import utils from '@/mixins/utils'
import FilterableListPage from '@/components/profile/lists/FilterableListPage.vue'
import FilterableList from '@/components/profile/lists/FilterableList.vue'

const FILTER_DEFAULT = {
  annotatedMaterialType: null,
  content: '',
  timePeriod: null,
  visibility: 'BOTH',
  sortBy: null,
}

export default {
  name: 'UserTags',
  extends: FilterableListPage,
  mixins: [profile, utils],
  components: {
    FilterableList,
  },
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    if (!_.isEmpty(this.usernameParam)) {
      return { title: this.cleanUsername() }
    } else {
      return { title: 'My profile' }
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.updateBreadcrumbs()
  },
  computed: {
    filterConfigs() {
      return [
        {
          name: 'containing',
          display: 'Containing',
          type: 'text',
          value: () => this.filter.content,
          input: (v) => (this.filter.content = v),
        },
        {
          name: 'created',
          display: 'Created',
          type: 'select',
          value: () => this.filter.timePeriod,
          input: (v) => (this.filter.timePeriod = v),
          options: this.timeIntervalOptions,
        },
        {
          name: 'addedTo',
          display: 'Added to',
          type: 'select',
          value: () => this.filter.annotatedMaterialType,
          input: (v) => (this.filter.annotatedMaterialType = v),
          options: this.annotatedMaterialTypeOptions,
        },
        {
          name: 'sort',
          display: 'Sort',
          type: 'select',
          value: () => this.filter.sortBy,
          input: (v) => (this.filter.sortBy = v),
          options: [
            {
              value: null,
              text: 'Alphabetically',
            },
            {
              value: 'DESC',
              text: 'Tag (most used)',
            },
            {
              value: 'ASC',
              text: 'Tag (least used)',
            },
          ],
        },
        {
          name: 'visibility',
          display: 'Visibility',
          type: 'select',
          predicate: () => this.isMyProfile,
          value: () => this.filter.visibility,
          input: (v) => (this.filter.visibility = v),
          options: this.visibilityOptions,
        },
      ]
    },
    filterDefault() {
      return FILTER_DEFAULT
    },
    sortedTags() {
      let itemCountFn = (t) =>
        this.filter.sortBy === 'DESC' ? -t.taggedItemCount : t.taggedItemCount
      let sortByFields = _.compact([
        this.filter.sortBy ? itemCountFn : null,
        (t) => t.name.toLowerCase(),
      ])
      return _.sortBy(this.items, sortByFields)
    },
  },
  methods: {
    updateBreadcrumbs() {
      let crumbs = []
      if (!_.isEmpty(this.usernameParam)) {
        crumbs.push({
          link: '/userProfile/' + this.usernameParam + '/tags',
          value: 'User profile',
        })
      } else {
        crumbs.push({ link: '/userProfile/tags', value: 'My profile' })
      }

      this.$emit('update-breadcrumbs', crumbs, true)
    },
    getFromService(user, filter) {
      return service.tags
        .getTagsForUser(
          user,
          _.assign({}, filter, {
            includePrivate: filter.visibility !== 'PUBLIC',
            includePublic: filter.visibility !== 'PRIVATE',
          })
        )
        .then((tags) => {
          return {
            content: _(tags)
              .map((tag) => {
                let tagParam = 'l-' + (tag.mine ? 'usertag' : 'publictag')
                let tagQuery = {}
                tagQuery[tagParam] = tag.content.replace(/["']/g, '')

                return {
                  id: tag.id,
                  name: tag.content,
                  editable: tag.editable,
                  priv: tag.priv,
                  taggedItemCount: tag.taggedItemCount,
                  to: {
                    name: 'SearchAll',
                    query: tagQuery,
                  },
                  href:
                    '/search?l-' +
                    (tag.mine ? 'usertag' : 'publictag') +
                    '="' +
                    tag.content.replace(/["']/g, '') +
                    '"',
                }
              })
              .sortBy((tag) => tag.name.toLowerCase())
              .value(),
          }
        })
    },
  },
  watch: {
    profileInfo: {
      deep: true,
      handler() {
        this.getItems()
      },
    },
  },
}
</script>
