<template>
  <div class="user-notification">
    <div class="date">{{ formattedDate }}</div>
    <div class="details">
      <font-awesome-icon class="tag-icon" :icon="{ prefix: 'fas', iconName: 'list' }" />
      The list "{{ details.listTitle }}" has been deleted by the list owner. All of your
      contributions have been removed from this list.
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-notification {
  ul {
    padding-left: 3rem;
  }
}
</style>

<script>
import AbstractNotificationDetail from './AbstractNotificationDetail'

export default {
  name: 'CollabListDeleted',
  extends: AbstractNotificationDetail,
  computed: {},
}
</script>
