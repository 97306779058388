<template>
  <multiselect
    v-model="values"
    id="libraryLookup"
    label="display"
    track-by="name"
    placeholder="Type to search for holding organisation"
    open-direction="bottom"
    :options="foundLibraries"
    :multiple="true"
    :searchable="true"
    :loading="isLoading"
    :internal-search="false"
    :clear-on-select="false"
    :close-on-select="true"
    :options-limit="300"
    :limit="30"
    :limit-text="limitText"
    :max-height="600"
    :show-no-results="false"
    :hide-selected="true"
    @search-change="search"
    @input="input"
    @remove="remove">
    <template slot="option" slot-scope="props">
      <div :style="optionStyle(props.option)">
        <span v-html="highlight(props.option.display)"></span>
        <span v-if="props.option.nuc" class="nuc">[{{ props.option.nuc }}]</span>
      </div>
    </template>
    <template slot="clear">
      <div class="multiselect__clear" v-if="values.length" @mousedown.prevent.stop="clear">
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </div>
    </template>
  </multiselect>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect {
  .multiselect__clear {
    position: absolute;
    right: 2.5rem;
    top: 0.5rem;
    display: block;
    cursor: pointer;
    z-index: 2;
  }

  .highlighted {
    color: white;
    background-color: colour(grey-6);
  }

  .nuc {
    margin-left: 0.4rem;
    font-size: small;
    color: #e0e0e0;
  }

  /*
     .multiselect__option--highlight {
         .nuc {
         }
     }
     */
}
</style>

<script>
import _ from 'lodash'
import utils from '@/mixins/utils'
import Multiselect from 'vue-multiselect'

export default {
  name: 'LibraryLookup',
  mixins: [utils],
  components: {
    Multiselect,
  },
  props: {
    originalValues: {
      type: Array,
      required: false,
    },
    allLibraries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      values: [],
      foundLibraries: [],
      isLoading: false,
      currentQuery: null,
      deletedValue: null,
    }
  },
  created() {
    this.$emit('clear-handler', this.clear)
  },
  methods: {
    highlight(text) {
      if (!this.currentQuery || !text) {
        return text
      }

      return text.replace(
        new RegExp(this.currentQuery, 'gi'),
        "<span class='highlighted'>$&</span>"
      )
    },
    optionStyle(option) {
      return {
        'margin-left': (option.depth || 0) + 'rem',
      }
    },
    limitText(count) {
      return `and ${count} other libraries`
    },
    search(query) {
      var self = this
      self.currentQuery = query

      var isSelected = function (library) {
        return _.find(self.values, (value) => value.nuc === library.nuc)
      }

      var matches = function (library, query) {
        var toSearch = (
          (library.fullName || '') +
          (library.shortName || '') +
          (library.nuc || '')
        ).toLowerCase()
        return _.includes(toSearch, (query || '').toLowerCase())
      }

      var getAncestors = function (library) {
        if (library.parent) {
          return [library.parent].concat(getAncestors(library.parent))
        }

        return []
      }

      var allLibraries = _.cloneDeep(self.allLibraries || [])

      self.treeEach(
        allLibraries,
        (library) => library.childLibraries,
        (library) => {
          if (matches(library, query) && library.nuc && !isSelected(library)) {
            library.visible = true

            _(getAncestors(library)).each((ancestor) => {
              ancestor.visible = true
            })
          }
        }
      )

      self.foundLibraries = []
      self.treeSearch(
        allLibraries,
        (library) => library.childLibraries,
        (library) => {
          if (library.visible) {
            self.foundLibraries.push(library)
          }
        }
      )
    },
    remove(value) {
      this.deletedValue = value
    },
    input(value) {
      var self = this

      if (_.isEmpty(value)) {
        self.deletedValue = null
        self.$emit('update', self.values)
        return
      }

      var addDescendants = function (library) {
        var toAdd = []

        self.treeSearch(
          library.childLibraries,
          (library) => library.childLibraries,
          (library) => {
            toAdd.push(library)
          }
        )

        self.values = self.values.concat(toAdd)
      }

      if (_.isEmpty(self.deletedValue)) {
        addDescendants(_.last(value))
      } else {
        self.deletedValue = null
      }
      _.remove(self.values, (value) => !value.nuc)

      var uniqueValues = _.uniqBy(self.values, (value) => value.nuc)
      if (!_.isEqual(self.values, uniqueValues)) {
        self.values = uniqueValues
      }

      self.$emit('update', self.values)
    },
    clear() {
      this.values = []
      this.$emit('update', this.values)
    },
  },
  watch: {
    originalValues: {
      immediate: true,
      handler() {
        var self = this

        if (self.originalValues && self.originalValues.length > 0) {
          self.values = _(self.originalValues)
            .map((originalValue) => {
              var matches = []
              self.treeSearch(
                self.allLibraries || [],
                (library) => library.childLibraries,
                (library) => library.nuc === originalValue,
                (library) => {
                  matches.push(library)
                }
              )

              return matches[0] || null
            })
            .filter((library) => library)
            .value()
        } else {
          self.values = []
        }
      },
    },
    allLibraries: {
      immediate: true,
      handler() {
        this.treeEach(
          this.allLibraries || [],
          (library) => library.childLibraries,
          (library, parent, depth) => {
            library.display = library.name
            library.parent = parent
            library.depth = depth
          }
        )

        this.search(this.currentQuery)
      },
    },
  },
}
</script>
