<template>
  <Collapse class="list-collapse" :collapsed="!panelOpen">
    <div class="add-to-list">
      <div class="error" v-if="error && error.summary">
        <Alert :alert="error"></Alert>
      </div>

      <div class="form">
        <ValidationObserver ref="observer" v-slot="{ invalid, classes }" tag="div" key="addList">
          <div class="available-lists">
            <ValidationProvider
              :rules="availableListValidationRules"
              v-slot="{ errors, failedRules, classes }"
              name="You must select at least one available list to add, or create a new list">
              <b-form-group
                label="Select from your available lists"
                label-class="font-weight-bold"
                size="lg">
                <b-form-checkbox-group
                  :class="classes"
                  v-model="selectedLists"
                  :options="availableListOptions"></b-form-checkbox-group>
                <span v-if="!availableLists || availableLists.length === 0"
                  >You have no other available lists</span
                >
              </b-form-group>
              <p class="error" v-if="failedRules.required">
                Select at least one available list to add to
              </p>
            </ValidationProvider>
          </div>

          <p class="or">- and/or -</p>
          <ValidationProvider
            :rules="!isSelectingLists ? 'required' : ''"
            v-slot="{ errors, failedRules, classes }"
            name="A list name is required when creating a new list">
            <b-form-group label="Make a new list" label-class="font-weight-bold">
              <b-form-input
                v-model="newList.name"
                :class="classes"
                :required="!isSelectingLists"></b-form-input>
            </b-form-group>
            <p class="error" v-if="failedRules.required"> A name for the new list is required </p>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors, classes }"
            name="Description for this list (optional)"
            v-if="newList.name">
            <b-form-group label="Describe this list (optional)" label-class="font-weight-bold">
              <b-form-textarea
                :class="classes"
                v-model="newList.description"
                :required="false"
                :maxlength="2000"
                rows="3"
                max-rows="6"></b-form-textarea>
            </b-form-group>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>

          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="visibility"
            v-if="newList.name"
            class="visibility">
            <b-form-group>
              <b-form-radio-group
                v-model="newList.priv"
                :required="true"
                :class="classes"
                @change="visibilityChanged"
                name="visibility">
                <b-form-radio :value="false">Public</b-form-radio>
                <b-form-radio :value="true">Private</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>

          <div class="collaboration">
            <b-form-checkbox
              v-model="newList.collaborative"
              v-if="newList.name"
              :disabled="newList.priv || !myUserProfile.publiclyVisible"
              switch
              >Collaborative</b-form-checkbox
            >
            <span v-if="newList.priv" class="statement"
              >Only public lists can be made collaborative</span
            >
            <span v-if="newList.name && !myUserProfile.publiclyVisible" class="statement"
              >You can only create collaborative lists if your
              <a href="/userProfile/settings">profile is public</a></span
            >
          </div>

          <div class="divider"></div>

          <ValidationProvider
            v-slot="{ errors, classes }"
            name="Reason for adding this item? (optional)"
            v-if="availableLists.length > 0 || newList.name">
            <b-form-group
              label="Reason for adding this item? (optional)"
              label-class="font-weight-bold">
              <b-form-textarea
                :class="classes"
                v-model="reasonForAdding"
                :required="false"
                :maxlength="50000"
                rows="3"
                max-rows="6"></b-form-textarea>
            </b-form-group>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>

          <div class="buttons" v-if="showButtons">
            <b-button variant="link" @click="cancel" :disabled="saving">Cancel</b-button>
            <b-button @click="save" :disabled="saving">Save</b-button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </Collapse>
</template>

<style scoped lang="scss">
.add-to-list {
  padding: 1rem;
  background-color: colour(grey);

  .add-button {
    margin-left: -1rem;
  }

  &.open {
  }

  .form {
    width: 100%;
    padding-top: 0.5rem;
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0.5rem;
    }

    .invalid {
      border: solid 1px colour(form-field-error);
    }

    .error {
      color: colour(form-field-error);
    }

    .buttons {
      text-align: right;
    }

    .list-field-label {
      font-weight: bold !important;
    }

    .or {
      font-size: 1.1rem;
      font-style: italic;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .divider {
      width: 95%;
      border-bottom: 1px solid colour(grey-6);
      margin-bottom: 2rem;
      padding-top: 1rem;
      margin-right: auto;
      margin-left: auto;
    }

    .visibility,
    .collaboration {
      display: inline-block;
      margin-right: 1rem;

      .custom-control {
        display: inline-block;
      }

      .statement {
        margin-left: 1rem;
      }
    }

    .custom-control-inline {
      margin-right: 2rem !important;
    }
  }
}
</style>

<script>
import service from '@/service/service'
import _ from 'lodash'
import auth from '@/mixins/auth'
import utils from '@/mixins/utils'
import lists from '@/mixins/lists'
import Alert from '@/components/Alert.vue'
import Collapse from '@/components/common/Collapse.vue'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import { required } from 'vee-validate/dist/rules.umd'
import { mapGetters } from 'vuex'

setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_}',
})

export default {
  name: 'AddToList',
  mixins: [auth, utils, lists],
  components: {
    Collapse,
    ValidationProvider,
    ValidationObserver,
    Alert,
  },
  props: {
    targetId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator(val) {
        return ['work', 'version', 'person', 'organisation', 'list'].indexOf(_.toLower(val)) > -1
      },
    },
    format: {
      type: String,
      required: false,
    },
    availableLists: {
      type: Array,
      required: false,
      default: () => [],
    },
    panelOpen: {
      type: Boolean,
      required: true,
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedLists: [],
      newList: {
        userId: undefined,
        name: undefined,
        description: undefined,
        priv: false,
        target: undefined,
        collaborative: false,
      },
      reasonForAdding: undefined,
      error: undefined,
      saving: false,
    }
  },
  mounted() {
    this.$emit('validator', this.validate)
  },
  watch: {
    panelOpen(newVal) {
      if (!newVal) {
        this.clear()
      } else {
        if (_.isUndefined(this.myUserProfile.publiclyVisible)) {
          this.loadUserProfile()
        }
      }
    },
    'newList.name'(newVal) {
      if (!newVal) {
        this.clearNewList()
      }
    },
    saving(newVal) {
      this.$emit('saving', newVal)
    },
    newList: {
      deep: true,
      handler() {
        this.$emit('new-list-updated', this.newList)
      },
    },
    selectedLists() {
      this.$emit(
        'selected-lists-updated',
        _(this.selectedLists)
          .map((selectedListId) =>
            _.find(this.availableLists, (list) => list.id === selectedListId)
          )
          .filter((list) => list)
          .value()
      )
    },
    reasonForAdding() {
      this.$emit('reason-updated', this.reasonForAdding)
    },
  },
  computed: {
    ...mapGetters('trove', ['myUserProfile']),
    availableListOptions() {
      const options = _.map(this.availableLists, (list) => {
        return {
          text:
            list.name + (list.collaborative ? ' (collaborative)' : list.priv ? ' (private)' : ''),
          value: list.id,
        }
      })

      return _.sortBy(options, (option) => _.toLower(option.text))
    },
    availableListValidationRules() {
      return !this.isCreatingList ? 'required' : ''
    },
    isCreatingList() {
      return !_.isEmpty(this.newList.name)
    },
    isSelectingLists() {
      return this.selectedLists.length > 0
    },
  },
  methods: {
    loadUserProfile() {
      let self = this
      self.loading = true
      service.profile.get(self.fullUsername).then(() => {
        self.loading = false
      })
    },
    save() {
      let self = this
      this.validate().then((isValid) => {
        if (isValid) {
          self.saving = true
          let existingLists = _.filter(self.availableLists, (list) => {
            return self.selectedLists.indexOf(list.id) > -1
          })

          self.newList.userId = self.username
          self.newList.target = self.targetId

          let lists = _.filter(_.concat(self.newList, existingLists), (list) => _.trim(list.name))

          self.$emit('user-achievement', undefined)
          service.list
            .addItemsToLists(
              [{ id: self.targetId, type: self.type, format: self.format }],
              lists,
              self.reasonForAdding
            )
            .then((data) => {
              self.error = {}
              self.saving = false

              let message = self.getListStatusMessage(
                data.items,
                data.errors,
                self.availableLists,
                []
              )

              self.clear()

              if (!_.isEmpty(message)) {
                self.error = {
                  summary: message,
                  impact: 'info',
                }

                self.saving = false
              }

              self.$emit('lists-selected', data.items, !_.isEmpty(data.errors))

              if (
                _.isEmpty(self.error) &&
                data.userAchievement &&
                data.userAchievement.achievements.length > 0
              ) {
                self.$emit('user-achievement', data.userAchievement)
              }
            })
            .catch((error) => {
              self.saving = false
              self.handleError(error, 'list', undefined, 'saving your lists')
            })
        }
      })
    },
    validate() {
      return this.$refs.observer.validate()
    },
    cancel() {
      this.clear()
      this.$emit('cancel')
    },
    clear() {
      this.error = {}
      this.clearNewList()
      this.$refs.observer.reset()
      this.reasonForAdding = undefined
      this.selectedLists.splice(0, this.selectedLists.length)
    },
    clearNewList() {
      this.newList.userId = undefined
      this.newList.description = undefined
      this.newList.name = undefined
      this.newList.priv = false
      this.newList.collaborative = false
      this.newList.target = undefined
    },
    visibilityChanged() {
      let self = this
      this.$nextTick(() => {
        if (self.newList.priv) {
          self.newList.collaborative = false
        }
      })
    },
  },
}
</script>
