<template>
  <div class="profile-about">
    <b-row>
      <b-col md="8" sm="12" class="profile-details">
        <div class="display" v-if="!showEditForm" :class="{ closed: showEditForm }">
          <div class="feed-container">
            <UserNotifications
              type="feed"
              heading="Activity feed"
              :collate="true"
              v-if="
                (isMyProfile || canViewPrivateProfiles) && profileInfo.feedEnabled
              "></UserNotifications>
          </div>
        </div>
      </b-col>
      <b-col md="4" sm="12">
        <UserNotifications
          type="alert"
          heading="Notifications"
          v-if="isMyProfile"
          empty-message="There are no items requiring your attention"
          :clearable="true"
          :paged="false"
          :collate="false"
          class="notification-alerts"></UserNotifications>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped lang="scss">
.profile-about {
  padding: 1rem 1rem 0;
  min-height: 500px;

  h1.bevel {
    &:before {
      top: 0.11rem;
    }
  }

  .display {
    margin-top: 1rem;
    height: auto;
    overflow: hidden;

    &.closed {
      max-height: 0;
      height: 0;
      transition: max-height 0.5s ease;
    }

    .edit-button {
      margin-top: 2rem;
    }

    .biography,
    .orcid,
    .website {
      margin-top: 1rem;
    }

    .social {
      margin-top: 2rem;
      margin-bottom: 1rem;

      .twitter,
      .facebook {
        margin-right: 2rem;
      }

      .vis-ind {
        margin-left: 0;
      }
    }

    .biography {
      margin-bottom: 1rem;
    }

    .section-label {
      font-weight: 600;
      margin-right: 0.5rem;
    }
  }

  .edit {
    margin-top: 1rem;
    max-height: 0;
    height: 0;
    overflow: hidden;

    .label {
      font-weight: 700;
      width: 125px;
    }

    .input-prefix {
      display: inline-block;
      width: 200px;
    }

    .form-control-long {
      width: 600px !important;
      max-width: 600px !important;
    }

    &.open {
      height: auto;
      max-height: 1000px;
      transition: max-height 0.5s ease;

      #biography {
        max-height: 500px;
      }
    }

    .form-group {
      margin-bottom: 0.5rem;
    }

    input.form-control {
      max-width: 400px;
      display: inline;
    }

    .invalid {
      border: solid 1px colour(form-field-error);
    }

    .error {
      color: colour(form-field-error);
    }

    .hint {
      font-size: 0.8rem;
      color: colour(grey-1);
    }

    .buttons {
      margin-top: 2rem;

      p {
        margin-bottom: 1.5rem;
      }

      .visibility-ind {
        margin-left: 0;
        margin-right: 0.5rem;
      }

      .visibility-ind:nth-child(2) {
        margin-left: 1rem;
      }
    }
  }

  .feed-container {
    max-width: 90%;
  }

  .notification-alerts {
    margin-bottom: 2rem;
  }
}
</style>

<script>
import _ from 'lodash'
import auth from '@/mixins/auth'
import utils from '@/mixins/utils'
import profile from '@/mixins/profile'
import service from '@/service/service'
import VisibilityInd from '@/components/profile/VisibilityInd.vue'
import UserNotifications from '@/components/profile/notifications/UserNotifications.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Countdown from '@/components/Countdown.vue'

export default {
  name: 'UserProfileAbout',
  mixins: [auth, profile, utils],
  components: {
    VisibilityInd,
    ValidationProvider,
    ValidationObserver,
    UserNotifications,
    Countdown,
  },
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditForm: false,
      editDetails: {
        biography: undefined,
        website: undefined,
        twitter: undefined,
        facebook: undefined,
        orcid: undefined,
      },
      inProgress: false,
      maxLength: {
        biography: 5000,
        website: 1000,
        twitter: 255,
        facebook: 255,
        orcid: 19,
      },
    }
  },
  metaInfo() {
    if (!_.isEmpty(this.usernameParam)) {
      return { title: this.cleanUsername() }
    } else {
      return { title: 'My profile' }
    }
  },
  mounted() {
    this.updateBreadcrumbs()
  },
  computed: {
    lengthLeft() {
      return (
        this.maxLength.biography -
        (this.editDetails.biography ? this.editDetails.biography.length : 0)
      )
    },
    twitterPrefix() {
      return _.startsWith(this.profileInfo.twitter, '@') ? '' : '@'
    },
    biographyPopulated() {
      return (
        this.isTruthy(this.profileInfo.biography) ||
        this.isTruthy(this.profileInfo.website) ||
        this.isTruthy(this.profileInfo.twitter) ||
        this.isTruthy(this.profileInfo.facebook) ||
        this.isTruthy(this.profileInfo.orcid)
      )
    },
  },
  watch: {
    showEditForm(newVal) {
      this.$emit('editing', newVal)
    },
  },
  methods: {
    updateBreadcrumbs() {
      let crumbs = []
      if (!_.isEmpty(this.usernameParam)) {
        crumbs.push({
          link: '/userProfile/' + this.usernameParam + '/about',
          value: 'User profile',
        })
      } else {
        crumbs.push({ link: '/userProfile/about', value: 'My profile' })
      }

      this.$emit('update-breadcrumbs', crumbs, true)
    },
    edit() {
      this.editDetails.biography = _.replace(this.profileInfo.biography, /<br\/>/g, '\n')
      this.editDetails.website = this.profileInfo.website
      this.editDetails.twitter = this.profileInfo.twitter
      this.editDetails.facebook = this.profileInfo.facebook
      this.editDetails.orcid = this.profileInfo.orcid
      this.showEditForm = true
    },
    cancelEdit() {
      this.clearEditDetails()
      this.showEditForm = false
    },
    clearEditDetails() {
      this.editDetails.biography = undefined
      this.editDetails.website = undefined
      this.editDetails.twitter = undefined
      this.editDetails.facebook = undefined
      this.editDetails.orcid = undefined
    },
    saveProfile() {
      let self = this

      self.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          self.profileInfo.biography = self.editDetails.biography
          self.profileInfo.website = self.editDetails.website
          self.profileInfo.twitter = self.editDetails.twitter
          self.profileInfo.facebook = self.editDetails.facebook
          self.profileInfo.orcid = self.editDetails.orcid

          self.inProgress = true
          service.profile
            .updateProfile(self.profileInfo)
            .then((response) => {
              self.$emit('update-profile', response)
              this.clearEditDetails()
              this.showEditForm = false
              self.inProgress = false
            })
            .catch((error) => {
              self.inProgress = false
              self.$emit('error', {
                error: error,
                contextMessage: 'update your profile',
              })
            })
        }
      })
    },
  },
}
</script>
