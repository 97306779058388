<template>
  <div class="captcha-modal">
    <b-modal
      id="captcha-modalModal"
      size="lg"
      @show="reset"
      @shown="renderCaptcha"
      centered
      :visible="showCaptcha && !alreadyHuman"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true">
      <div slot="modal-header" class="px-4 w-100">
        <span class="close">
          <b-button variant="link" @click="dismiss"
            >Close
            <span><font-awesome-icon :icon="{ prefix: 'fal', iconName: 'times' }" /></span>
          </b-button>
        </span>
      </div>
      <b-container fluid>
        <div v-if="!anonymousMode" class="login">
          <b-row>
            <b-col lg="8" md="7" sm="6">
              <h2 class="section-heading">Already got a Trove account</h2>
            </b-col>
            <b-col lg="4" md="5" sm="6">
              <b-btn class="login-btn right" @click="login"> Login </b-btn>
            </b-col>
          </b-row>
        </div>
        <div v-if="!anonymousMode" class="register">
          <b-row>
            <b-col lg="8" md="7" sm="6">
              <h2 class="section-heading">Sign up for a free Trove account</h2>
              <p>It's easy and takes two shakes of a lamb's tail!</p>
              <p>With your Trove account you can:</p>
              <ul>
                <li>edit and delete tags and comments,</li>
                <li>create lists,</li>
                <li> create private tags and comments, readable only by you, and </li>
                <li>keep track of your newspaper corrections</li>
              </ul>
            </b-col>
            <b-col lg="4" md="5" sm="6">
              <b-btn class="register-btn right" @click="register"> Sign up </b-btn>
            </b-col>
          </b-row>
        </div>
        <div class="anonymous">
          <b-btn variant="link" @click="showAnonymousOption" v-if="!anonymousPanelOpen"
            >{{ anonymousPrompt || action + ' an anonymous ' + entity }}?</b-btn
          >
          <div v-show="anonymousPanelOpen">
            <h2 class="section-heading">Confirm you're a human</h2>
            <b-row>
              <b-col cols="12" v-if="error">
                <Alert v-if="error" :alert="{ impact: 'error', summary: error }"></Alert>
              </b-col>
              <b-col lg="8" md="7" sm="6">
                <p>
                  Since you've made it this far, we want to assume you're a real, live human. But we
                  need to be super sure you aren't a robot.
                </p>
              </b-col>
              <b-col lg="4" md="5" sm="6">
                <div class="captchaImageWrapper">
                  <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="siteKey"></div>
                </div>
                <b-btn class="submit-btn" @click="submitCaptcha" :disabled="!captchaAuthResponse">
                  Submit
                </b-btn>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
      <div slot="modal-footer"></div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.modal-header {
  border-bottom: 1px solid colour(purple) !important;

  .heading {
    display: inline-block;
    float: left;
    font-size: 1.75rem;
    margin: 0;
    font-family: 'Source Serif Pro', serif;
  }

  @supports (font-variation-settings: normal) {
    .heading {
      font-family: 'Source Serif Variable', serif;
    }
  }

  .close {
    display: inline-block;
    float: right !important;
    white-space: nowrap;
    padding: 0;
    padding-top: 2px;

    .btn {
      font-size: 18px;
      font-weight: normal;
      color: #2d004b;
      opacity: 1;
      margin-top: 0;

      span {
        font-size: 22px;
        padding-left: 5px;
        top: 2px;
        position: relative;
      }

      &:hover,
      &:focus {
        color: #c0286f;
        outline: none;
      }
    }
  }
}

.section-heading {
  font-size: 1.5rem;
}

.right {
  .login-btn,
  .register-btn {
    float: right;
  }
}

.login-btn,
.submit-btn,
.register-btn {
  min-width: 158px;
}

.anonymous,
.register,
.login {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #2d004b;
}
</style>

<script>
import service from '@/service/service'
import auth from '@/mixins/auth'
import Alert from '@/components/Alert.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CaptchaModal',
  mixins: [auth],
  components: {
    Alert,
  },
  props: {
    action: {
      type: String,
      required: false,
      default: 'Add',
    },
    entity: {
      type: String,
      required: false,
      default: 'Note',
    },
    anonymousPrompt: {
      type: String,
      required: false,
    },
    showCaptcha: {
      type: Boolean,
      required: true,
    },
    anonymousMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captchaWidget: undefined,
      siteKey: undefined,
      captchaAuthResponse: undefined,
      alreadyHuman: false,
      error: undefined,
      anonymousPanelOpen: false,
    }
  },
  mounted() {
    this.anonymousPanelOpen = this.anonymousMode

    var self = this
    service.config.environment().then((response) => {
      if (response.googleCaptchaUrl && response.googleCaptchaKey) {
        self.siteKey = response.googleCaptchaKey
        self.captchaUrl = response.googleCaptchaUrl

        service.auth.checkCaptchaStatusForSession(self.captchaUrl).then((data) => {
          if (data === 'isHuman') {
            self.verified(false)
          } else {
            self.alreadyHuman = false
          }
        })
      }
    })
  },
  computed: {},
  methods: {
    ...mapGetters('trove', ['isHuman']),
    ...mapActions('trove', ['recordAsHuman']),
    reset() {
      this.anonymousPanelOpen = this.anonymousMode
    },
    dismiss() {
      this.$emit('dismiss-modal')
    },
    verified(proceedWithNextAction) {
      this.alreadyHuman = true
      this.$emit('captcha-verified', proceedWithNextAction)
    },
    renderCaptcha() {
      let self = this
      if (window.grecaptcha) {
        self.captchaWidget = window.grecaptcha.render('g-recaptcha', {
          sitekey: self.siteKey,
          size: 'compact',
          callback: (response) => {
            self.captchaAuthResponse = response
          },
        })
      }
    },
    submitCaptcha() {
      let self = this
      self.error = undefined
      service.auth.submitCaptcha(self.captchaUrl, self.captchaAuthResponse).then((response) => {
        if (response.data === 'ok') {
          self.verified(true)
          self.recordAsHuman()
        } else {
          self.error = 'You must prove you are human and pass the reCAPTCHA challenge first.'
        }
      })
    },
    showAnonymousOption() {
      this.anonymousPanelOpen = true
    },
  },
}
</script>
