var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"root-container",attrs:{"id":"manageListContainer"}},[_c('Stubbable',{attrs:{"stubbed":_vm.stubbed},scopedSlots:_vm._u([{key:"stub",fn:function(){return [_c('div',{staticClass:"stub"},[_c('div',{staticClass:"list-stub"},[_c('div',{staticClass:"button pill title"}),_c('div',{staticClass:"button pill summary-title"}),_c('div',{staticClass:"button pill summary"})])])]},proxy:true}])},[_c('template',{slot:"content"},[(_vm.error && _vm.error.summary)?_c('div',{staticClass:"error"},[_c('Alert',{attrs:{"alert":_vm.error}})],1):_vm._e(),(!_vm.error || !_vm.error.summary || _vm.error.showContent)?[_c('b-row',{ref:"content"},[_c('b-col',{attrs:{"md":"12"}},[(!_vm.editingMode)?_c('ListSummary',{staticClass:"header-section",attrs:{"list":_vm.list,"hide-owner-on-my-lists":false,"show-collaborator-count":true,"title-and-thumbnail-only":_vm.editingMode},on:{"thumbnail-loaded":_vm.thumbnailLoaded}}):_vm._e(),(_vm.editingMode)?_c('EditListDetails',{attrs:{"existing":_vm.list,"in-progress":_vm.saving},on:{"save":_vm.save,"cancel":_vm.cancel}}):_vm._e(),(_vm.list.numItems > 0 && !_vm.editingMode)?_c('div',{staticClass:"image-selector",style:({
                height: _vm.thumbnailHeight + 'px',
                padding: _vm.thumbnailHeight / 2 - 16 + 'px 2px',
              })},[_c('a',{staticClass:"launcher-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showThumbnailSelector = true}}},[_c('font-awesome-icon',{attrs:{"icon":{ prefix: 'fas', iconName: 'pencil' },"size":"2x"}})],1)]):_vm._e(),(!_vm.editingMode)?_c('ListThumbnailSelector',{attrs:{"list-id":_vm.list.id,"show":_vm.showThumbnailSelector,"total-item-count":_vm.list.numItems},on:{"close":_vm.updateThumbnail}}):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('div',{staticClass:"body"},[(!_vm.editingMode)?_c('div',{staticClass:"controls"},[_c('div',{staticClass:"clearfix"},[(_vm.listOwner || _vm.isListAdministrator)?_c('b-button',{staticClass:"edit-button",attrs:{"variant":"success"},on:{"click":_vm.toggleEdit}},[_vm._v(" Edit list details ")]):_vm._e(),(_vm.listOwner)?_c('b-button',{staticClass:"delete-button",attrs:{"variant":"link"},on:{"click":_vm.deleteList}},[_c('font-awesome-icon',{attrs:{"icon":{ prefix: 'fas', iconName: 'trash-alt' }}}),_vm._v(" Delete this list ")],1):_vm._e()],1)]):_vm._e(),(_vm.list.collaborative && !_vm.editingMode)?_c('div',{staticClass:"collaborator-container"},[_c('h2',{staticClass:"collaborator-heading"},[_vm._v("Manage collaborators")]),_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"content-class":"mt-3","no-nav-style":""}},[(
                        _vm.list.pendingCollaborationRequests &&
                        _vm.list.pendingCollaborationRequests.length > 0
                      )?_c('b-tab',{attrs:{"active":""}},[_c('template',{slot:"title"},[_c('span',{staticClass:"notification-dot"}),_vm._v("Awaiting confirmation ")]),_c('PendingCollaborationRequests',{attrs:{"list-id":_vm.list.id,"show-heading":false,"pending-requests":_vm.list.pendingCollaborationRequests || []},on:{"request-actioned":_vm.updateList}})],2):_vm._e(),(
                        (_vm.list.collaborators && _vm.list.collaborators.length > 0) ||
                        !(
                          _vm.list.pendingCollaborationRequests &&
                          _vm.list.pendingCollaborationRequests.length > 0
                        )
                      )?_c('b-tab',{attrs:{"active":!_vm.list.pendingCollaborationRequests ||
                        _vm.list.pendingCollaborationRequests.length === 0,"title-item-class":{
                        hide: !(_vm.list.collaborators && _vm.list.collaborators.length > 0),
                      }}},[(_vm.list.collaborators && _vm.list.collaborators.length > 0)?_c('template',{slot:"title"},[_vm._v(" Collaborators ")]):_vm._e(),_c('ListCollaborators',{attrs:{"list-id":_vm.list.id,"show-heading":false,"list-owner":_vm.list.owner,"collaborators":_vm.list.collaborators || [],"can-remove-administrators":_vm.listOwner,"can-remove-collaborators":_vm.isListAdministrator || _vm.listOwner,"can-manage-roles":_vm.listOwner},on:{"collaborator-updated":_vm.collaboratorUpdated,"collaborator-removed":_vm.updateList}})],2):_vm._e(),(
                        _vm.list.blockedCollaborationRequests &&
                        _vm.list.blockedCollaborationRequests.length > 0
                      )?_c('b-tab',[_c('template',{slot:"title"},[_vm._v(" Blocked ")]),_c('BlockedCollaborationRequests',{attrs:{"list-id":_vm.list.id,"show-heading":false,"blocked-requests":_vm.list.blockedCollaborationRequests || []},on:{"request-unblocked":_vm.updateList}})],2):_vm._e()],1)],1)],1):_vm._e()]),(_vm.showListItems)?_c('ListItems',{attrs:{"list":_vm.list,"list-size":_vm.listSize,"list-author":_vm.list.author,"list-collaborator":_vm.isListCollaborator,"list-administrator":_vm.isListAdministrator,"list-owner":_vm.listOwner,"headerless":"","can-modify":!_vm.readOnly},on:{"list-item-removed":_vm.updateList,"page-changed":function($event){return _vm.scrollToTop('content')}}}):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[_c('Tags',{attrs:{"work":_vm.list}}),_c('AnnotationList',{attrs:{"type":"list","target-id":_vm.list.id,"heading":"List notes","read-only":_vm.readOnly}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('div',{staticClass:"d-flex flex-column content-container"})])],1)]:_vm._e()],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }