<template>
  <div class="profile-partner">
    <p>
      View your Partner dashboard for insights into your collections. Explore formats, unique
      holdings and rights and re-use statements by clicking on the tabs at the bottom of the page.
    </p>
    <router-link :to="dashboardRoute">View Dashboard</router-link>
  </div>
</template>

<style scoped lang="scss">
.profile-partner {
  padding: 1rem 1rem 0;
}
</style>

<script>
export default {
  name: 'UserProfilePartner',
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dashboardRoute() {
      return {
        name: 'PartnerDashboard',
      }
    },
  },
  mounted() {
    const hasPermission = this.profileInfo.dashboardAccess
    if (!hasPermission) {
      this.$router.push('/')
    }
  },
}
</script>
