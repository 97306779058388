import _ from 'lodash'
import Vue from 'vue'

export default {
  DISMISS_PRIMARY_WARNING: (state, showAdvice) => {
    state.primaryWarningDisplayed = true
    state.showCulturalWarnings = showAdvice
  },
  ACCEPT_WARNING: (state, identifier) => {
    Vue.set(state.unsuppressedIdentifiers, identifier, true)
  },
  UPDATE_PROFILE_ACTIVITY: (state, activity) => {
    state.profileActivity = activity
  },
  API_CRITICAL_FAIL: (state) => {
    state.apiLastCriticalFail = new Date()
  },
  HUMAN: (state) => {
    state.isHuman = true
  },
  UPDATE_USER_PROFILE: (state, profile) => {
    _.forOwn(profile, function (val, key) {
      Vue.set(state.myUserProfile, key, val)
    })
  },
  ADD_TO_ROUTE_HISTORY: (state, route) => {
    state.routeHistory.unshift(route)
  },
  REMOVE_ROUTE_HISTORY_HEAD: (state) => {
    state.routeHistory.shift()
  },
  UPDATE_START_ADVANCED_OPEN: (state, startAdvancedOpen) => {
    state.startAdvancedOpen = startAdvancedOpen
  },
}
