<template>
  <b-form @submit.prevent="updateFilter" @reset.prevent="clearFilter">
    <div class="version-filter d-flex flex-column align-items-end">
      <div class="close">
        <b-button variant="link" @click="close"
          >Close <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }"
        /></b-button>
      </div>

      <b-form-checkbox-group
        v-model="selectedPartners"
        class="d-flex flex-wrap flex-row-reverse checkboxes">
        <b-form-checkbox
          v-for="partner in partners"
          class="checkbox"
          :key="partner.name"
          :value="partner"
          >{{ partner.name }}</b-form-checkbox
        >
      </b-form-checkbox-group>

      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <div>
          <b-button variant="primary" class="inverse" type="reset">Clear</b-button>
          <b-button variant="primary" type="submit">Apply</b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<style scoped lang="scss">
.version-filter {
  .checkboxes {
    margin-bottom: 2rem;

    .checkbox {
      margin-right: 0;
      margin-left: 1rem;
    }
  }
}
</style>

<script>
import Filter from '@/components/work/Filter.vue'

export default {
  name: 'PartnerFilter',
  extends: Filter,
  props: {
    partners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPartners: [],
    }
  },
  methods: {
    updateFilter() {
      this.$emit('filter-updated', this.selectedPartners)
    },
    clearFilter() {
      this.selectedPartners = []
      this.updateFilter()
    },
  },
}
</script>
