<template>
  <FilterableList
    :filterConfigs="filterConfigs"
    :filtered="filtered"
    :items="items"
    :loading="loading"
    :listType="listType"
    itemName="list"
    itemNamePlural="lists"
    :paginationInfo="paginationInfo"
    @get-items="getItems"
    @page-changed="pageChanged"
    @reset-filters="resetFilters">
    <template v-slot:header> </template>

    <template v-slot:no-items>
      There are no Lists to show here. Find out
      <a href="/help/become-voluntrove/lists">how to create a List in Trove</a>.
    </template>

    <template v-slot:item="data">
      <ListSummary
        :list="data.item"
        :compact="true"
        :hide-owner-on-my-lists="true"
        :header-route="viewListRoute(data.item)"
        :show-summary="true"
        style="width: 100%">
        <router-link
          :to="manageListRoute(data.item)"
          slot="float-right"
          v-if="isMyProfile && showManageLink">
          <span
            class="notification-dot"
            v-if="(data.item.pendingCollaborationRequests || []).length > 0"></span
          ><font-awesome-icon :icon="{ prefix: 'far', iconName: 'cog' }" />
          Manage this list
        </router-link>
        <b-link slot="float-right" class="unfollow-link" v-if="isMyProfile && showUnfollowLink"
          ><div @click.prevent="stopFollowing(data.item)">Unfollow</div></b-link
        >
      </ListSummary>
    </template>

    <template v-slot:stub>
      <div class="d-flex stub" style="padding-top: 1rem">
        <div class="thumbnail" style="width: 80px; height: 96px; margin-right: 1rem"></div>
        <div class="body" style="width: 100%">
          <div class="line" style="width: 30%; height: 2rem"></div>
          <div class="line" style="width: 60%"></div>
        </div>
        <div class="right" style="width: 8rem">
          <div class="line" style="width: 100%"></div>
        </div>
      </div>
    </template>
  </FilterableList>
</template>

<style scoped lang="scss"></style>

<script>
import _ from 'lodash'
import service from '@/service/service'
import auth from '@/mixins/auth'
import profile from '@/mixins/profile'
import utils from '@/mixins/utils'
import ListSummary from '@/components/lists/ListSummary.vue'
import FilterableListPage from '@/components/profile/lists/FilterableListPage.vue'
import FilterableList from '@/components/profile/lists/FilterableList.vue'

export default {
  name: 'ProfileListTab',
  extends: FilterableListPage,
  mixins: [auth, profile, utils],
  components: {
    ListSummary,
    FilterableList,
  },
  props: {
    listUser: {
      type: String,
      required: true,
    },
    listType: {
      type: String,
      required: true,
      validator(val) {
        return ['personal', 'collaborative', 'followed'].indexOf(val) > -1
      },
    },
    showUnfollowLink: {
      type: Boolean,
      required: false,
    },
    showManageLink: {
      type: Boolean,
      required: false,
    },
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    defaultPageSize() {
      return 20
    },
    filterConfigs() {
      return [
        {
          name: 'containing',
          display: 'Containing',
          type: 'text',
          value: () => this.filter.containingText,
          input: (v) => (this.filter.containingText = v),
        },
        {
          name: 'created',
          display: 'Created',
          type: 'select',
          predicate: () => this.listType === 'personal',
          value: () => this.filter.createdWithin,
          input: (v) => (this.filter.createdWithin = v),
          options: this.timeIntervalOptions,
        },
        {
          name: 'addedTo',
          display: 'Added to',
          type: 'select',
          predicate: () => this.listType !== 'personal',
          value: () => this.filter.addedToWithin,
          input: (v) => (this.filter.addedToWithin = v),
          options: this.timeIntervalOptions,
        },
        {
          name: 'role',
          display: 'Role',
          type: 'select',
          predicate: () => this.listType === 'collaborative',
          value: () => this.filter.role,
          input: (v) => (this.filter.role = v),
          options: [
            {
              value: null,
              text: 'Any role',
            },
            {
              value: 'OWNER',
              text: 'List owner',
            },
            {
              value: 'ADMINISTRATOR',
              text: 'Administrator',
            },
            {
              value: 'COLLABORATOR',
              text: 'Collaborator',
            },
          ],
        },
        {
          name: 'visibility',
          display: 'Visibility',
          type: 'select',
          predicate: () => this.listType === 'personal' && this.isMyProfile,
          value: () => this.filter.visibilityType,
          input: (v) => (this.filter.visibilityType = v),
          options: [
            {
              value: 'PERSONAL',
              text: 'All lists',
            },
            {
              value: 'PUBLIC',
              text: 'Public',
            },
            {
              value: 'PRIVATE',
              text: 'Private',
            },
          ],
        },
      ]
    },
    filterDefault() {
      return {
        containingText: null,
        createdWithin: null,
        addedToWithin: null,
        visibilityType: _.toUpper(this.listType),
        role: null,
        number: 1,
      }
    },
    visibilityOptions() {
      return this.listType === 'personal' && this.isMyProfile
        ? [
            { text: 'All lists', value: 'PERSONAL' },
            { text: 'Public', value: 'PUBLIC' },
            { text: 'Private', value: 'PRIVATE' },
          ]
        : undefined
    },
    queryUser() {
      return this.listUser
    },
  },
  methods: {
    viewListRoute(list) {
      return {
        name: 'List',
        params: { id: list.id },
      }
    },
    manageListRoute(list) {
      return {
        name: 'ManageList',
        params: { id: list.id },
      }
    },
    stopFollowing(list) {
      let self = this
      service.list
        .stopFollowingList(list.id)
        .then(() => {
          self.items.splice(
            _.findIndex(self.items, (f) => f.id === list.id),
            1
          )
        })
        .catch((error) => {
          self.handleError(error, 'list', list.id, 'stop following this list')
        })
    },
    getFromService(user, filter) {
      let self = this

      return service.list.getListsForUser(user, filter).then((page) => {
        let hasNotification = false

        _.forEach(page.content, (list) => {
          if (self.isMyProfile && (list.pendingCollaborationRequests || []).length > 0) {
            hasNotification = true
          }
        })

        self.$emit('has-notification', hasNotification)

        if (page.totalElements === 0) {
          self.$emit('no-lists')
        }

        return page
      })
    },
  },
  watch: {
    profileInfo: {
      deep: true,
      handler() {
        this.getItems()
      },
    },
  },
}
</script>
