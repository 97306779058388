<template>
  <div class="lookup-container">
    <multiselect
      v-model="values"
      id="newspaperLookup"
      label="name"
      track-by="id"
      placeholder="Type to search"
      open-direction="bottom"
      :options="foundTitles"
      :multiple="true"
      :searchable="true"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="false"
      :preserve-search="false"
      :options-limit="1000"
      :limit="10"
      :limit-text="limitText"
      :max-height="400"
      :show-no-results="false"
      :hide-selected="true"
      @search-change="search"
      @input="input">
      <template slot="option" slot-scope="props">
        <div :style="optionStyle(props.option)">
          <span v-html="highlight(props.option.name)"></span>
        </div>
      </template>
      <template slot="clear">
        <span class="multiselect__clear" v-if="values.length" @mousedown.prevent.stop="clear">
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </span>
      </template>
    </multiselect>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.dates-container {
  .form-group {
    .form-row {
      min-width: 230px;
    }
  }
}

.multiselect {
  .multiselect__clear {
    position: absolute;
    right: 2.5rem;
    top: 0.5rem;
    display: block;
    cursor: pointer;
    z-index: 2;
  }

  .highlighted {
    color: white;
    background-color: colour(grey-6);
  }
}
</style>

<style scoped lang="scss">
.lookup-container {
  width: 100%;

  .dates-title {
    font-size: small;
    margin-bottom: 0.5rem;
  }

  .dates-container {
    .form-group {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .vue-slider {
    margin-bottom: 1rem;
  }
}
</style>

<script>
import _ from 'lodash'
import utils from '@/mixins/utils'
import Multiselect from 'vue-multiselect'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'NewspaperLookup',
  mixins: [utils],
  components: {
    Multiselect,
  },
  props: {
    originalValues: {
      type: Array,
      required: false,
    },
    allTitles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      values: [],
      foundTitles: [],
      currentQuery: null,
    }
  },
  created() {
    this.$emit('clear-handler', this.clear)
  },
  methods: {
    highlight(text) {
      if (!this.currentQuery || !text) {
        return text
      }

      return text.replace(
        new RegExp(this.currentQuery, 'gi'),
        "<span class='highlighted'>$&</span>"
      )
    },
    optionStyle(option) {
      return {
        'margin-left': (option.depth || 0) + 'rem',
      }
    },
    limitText(count) {
      return `and ${count} other titles`
    },
    search(query) {
      var self = this
      self.currentQuery = query

      var isSelected = function (title) {
        return _.find(
          self.values,
          (value) => value.id === title.id || (value.isState && value.id === title.state)
        )
      }

      var matches = function (title, query) {
        var toSearch = ((title.name || '') + (title.state || '')).toLowerCase()
        return _.includes(toSearch, (query || '').toLowerCase())
      }

      var matched = _(self.allTitles)
        .filter((title) => matches(title, query))
        .reject((title) => isSelected(title))
        .value()

      var grouped = _(matched)
        .groupBy((title) => title.state)
        .toPairs()
        .map((pair) => {
          return {
            name: pair[0],
            children: pair[1] || [],
          }
        })
        .value()

      self.foundTitles = []
      self.treeEach(
        grouped,
        (title) => title.children,
        (title, parent, depth) => {
          self.foundTitles.push(
            _.merge(title, {
              depth: depth,
              isState: !title.id,
              id: title.id ? title.id : title.name,
            })
          )
        }
      )
    },
    input(value) {
      var self = this

      if (_.isEmpty(value)) {
        self.$emit('update', self.values)
        return
      }

      var uniqueValues = _.uniqBy(self.values, (value) => value.id)
      if (!_.isEqual(self.values, uniqueValues)) {
        self.values = uniqueValues
      }

      self.$emit('update', self.values)
    },
    clear() {
      this.values = []
      this.$emit('update', this.values)
    },
    isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n)
    },
    updateValuesFromOriginals() {
      var self = this

      if (
        self.originalValues &&
        self.originalValues.length > 0 &&
        self.allTitles &&
        self.allTitles.length > 0
      ) {
        var titles = _(self.originalValues)
          .map((originalValue) => {
            return _(self.allTitles).find((title) => title.id === originalValue)
          })
          .filter((value) => value)
          .value()

        var states = _(self.originalValues)
          .filter((value) => !self.isNumber(value))
          .map((state) => {
            return {
              id: state,
              name: state,
              isState: true,
            }
          })
          .value()

        self.values = _.concat(titles, states)
      }
    },
  },
  watch: {
    values() {
      this.search(this.currentQuery)
    },
    originalValues: {
      immediate: true,
      handler() {
        this.updateValuesFromOriginals()
      },
    },
    allTitles: {
      immediate: true,
      handler() {
        this.updateValuesFromOriginals()
        this.search(this.currentQuery)
      },
    },
  },
}
</script>
