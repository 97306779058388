<template>
  <svg :width="thumbnailWidth" :height="thumbnailHeight" aria-hidden="true">
    <title v-if="title">{{ title }}</title>
    <desc v-if="description">{{ description }}</desc>
    <defs>
      <clipPath :id="id">
        <!-- eslint-disable max-len -->
        <path
          :id="id + 'key'"
          :transform="keyholeTransform"
          d="M4.27333838,136.675132 L20.7894,62.6124 C9.1824,57.7494 0.9204,46.2304 0.9204,32.9634 C0.9204,12.9194 19.4344,-3.2136 39.9754,1.2834 C52.6584,4.0594 62.5124,14.4424 64.7974,27.2234 C67.5824,42.8024 58.7334,57.0164 45.4354,62.5874 L61.968278,136.675132 C61.968278,136.675132 4.27333838,136.675132 4.27333838,136.675132 Z"></path>
        <!-- eslint-enable max-len -->
      </clipPath>
    </defs>
    <image
      :clip-path="'url(#' + id + ')'"
      :xlink:href="this.imageUrl"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid slice" />
  </svg>
</template>

<style scoped lang="scss"></style>

<script>
import _ from 'lodash'

var keyholeWidth = 66.4
var keyholeHeight = 137

var thumbnailAspectRatio = keyholeWidth / keyholeHeight

export default {
  name: 'KeyholeThumbnail',
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    width: {
      type: Number,
      required: false,
      default: 110,
    },
  },
  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
    }
  },
  computed: {
    id() {
      return 'key-clip-path' + _.random(0, 100000)
    },
    keyholeTransform() {
      return 'scale(' + this.thumbnailWidth / keyholeWidth + ')'
    },
    thumbnailWidth() {
      return this.width
    },
    thumbnailHeight() {
      return this.width / thumbnailAspectRatio
    },
  },
}
</script>
