<template>
  <ul>
    <Term v-for="term in terms" :key="term.term" :facet="facet" :term="term" />
  </ul>
</template>

<style scoped lang="scss">
ul {
  list-style-type: none;
  padding-left: 1rem;
}
</style>

<script>
import Term from '@/components/search/facets/Term.vue'

export default {
  name: 'TermList',
  components: {
    Term,
  },
  props: {
    facet: {
      type: Object,
      required: true,
    },
    terms: {
      type: Array,
      required: true,
    },
  },
}
</script>
