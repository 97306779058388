<template>
  <div :id="'vnp' + this.facetCode">
    <div v-if="loading" class="loading">
      <span class="loading-spinner spinner-lg"></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fffa;
  position: absolute;

  width: 100%;
  height: 100%;

  .spinner-grow {
    width: 2em;
    height: 2em;
  }
}
</style>

<script>
import _ from 'lodash'
import colours from '@/mixins/colours'
import currentRoute from '@/mixins/currentRoute'

import * as d3 from 'd3'

var MARGIN = { top: 20, right: 20, bottom: 50, left: 60 }
var WIDTH = 1200 - MARGIN.left - MARGIN.right
var HEIGHT = 400 - MARGIN.top - MARGIN.bottom

export default {
  name: 'NewspapersDateVis',
  mixins: [colours, currentRoute],
  props: {
    facet: {
      type: Object,
      required: false,
    },
    facetCode: {
      type: String,
      required: true,
    },
    singular: {
      type: String,
      required: false,
      default: 'item',
    },
    plural: {
      type: String,
      required: false,
      default: 'items',
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    colours: {
      type: Array,
      required: false,
      default: () => ['blue'],
    },
  },
  data() {
    return {
      v: [],
    }
  },
  mounted() {
    var self = this

    var vis = d3.select('#vnp' + this.facetCode)

    var tooltip = vis
      .append('div')
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('opacity', 0)
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '5px')

    var svg = vis
      .append('svg')
      .attr('viewBox', [
        0,
        0,
        WIDTH + MARGIN.left + MARGIN.right,
        HEIGHT + MARGIN.top + MARGIN.bottom,
      ])
      .append('g')
      .attr('transform', 'translate(' + MARGIN.left + ',' + MARGIN.top + ')')

    svg.append('g').attr('class', 'gridx')

    svg.append('path').attr('class', 'areax').attr('fill', this.colour('grey'))

    svg
      .append('path')
      .attr('class', 'linex')
      .attr('fill', 'none')
      .attr('stroke', this.colour('magenta'))
      .attr('stroke-width', '2px')

    svg
      .append('g')
      .attr('class', 'axisBottom')
      .attr('transform', 'translate(0, ' + HEIGHT + ')')

    svg.append('g').attr('class', 'axisLeft').attr('transform', 'translate(0, 0)')

    this.$_mychart = vis
    this.$_mysvg = svg
    this.$_mytooltip = tooltip

    let updateBrush = function (event) {
      var extent = event.selection // [x0, x1]

      if (!extent) {
        self.searchTermChanged({ 'date.from': null, 'date.to': null })
        return
      }

      var first = extent[0]
      var last = extent[1]

      var x = self.$_x
      var x0 = Math.floor(x.invert(first))
      var x1 = Math.floor(x.invert(last))

      self.searchTermChanged({
        'date.from': x0 + '-01-01',
        'date.to': x1 + '-12-31',
      })
    }

    var mybrush = d3
      .brushX()
      .extent([
        [0, 0],
        [WIDTH, HEIGHT],
      ])
      .on('end', updateBrush)

    this.$_mybrush_fn = mybrush
    this.$_mybrush = svg.append('g').call(mybrush)

    this.updateValues(null)
  },
  watch: {
    facet(newValue) {
      this.updateValues(newValue)
      this.updateBrushExtents()
    },
    currentQuery() {
      this.updateBrushExtents()
    },
  },
  computed: {
    visColours() {
      return _.map(this.colours, (n) => this.colour(n))
    },
  },
  methods: {
    searchTermChanged(val) {
      this.$emit('search-term-changed', val)
    },
    updateBrushExtents() {
      var q = this.currentQuery
      var moveTo = null

      if (q && q['date.from'] && q['date.to']) {
        var x0 = q['date.from'].substr(0, 4)
        var x1 = q['date.to'].substr(0, 4)

        moveTo = [this.$_x(x0), this.$_x(x1)]
      }

      this.$_mybrush.transition().call(this.$_mybrush_fn.move, moveTo)
    },

    updateValues(facet) {
      var self = this
      if (!facet) return

      var startYear = 1780
      var endYear = new Date().getFullYear()

      var vals = facet.terms
        .map((x) => {
          return { count: x.count, text: x.displayTerm, year: +x.term }
        })
        .filter((x) => x.year >= startYear && x.year <= endYear)

      var all = []
      for (var i = startYear; i < endYear; i++) {
        all.push({
          count: 0,
          text: i,
          year: i,
        })
      }

      var result = _.unionBy(vals, all, 'year')
      this.v = _.sortBy(result, 'year')

      var svg = self.$_mysvg

      // set the ranges
      var x = d3.scaleLinear().range([0, WIDTH])
      var y = d3.scaleSqrt().range([HEIGHT, 0])

      x.domain(
        d3.extent(this.v, function (d) {
          return d.year
        })
      )
      y.domain([
        0,
        Math.max(
          20,
          d3.max(this.v, function (d) {
            return d.count
          })
        ),
      ])

      this.$_x = x // keep x domain only for brush update.

      // define the area
      var area = d3
        .area()
        .x(function (d) {
          return x(d.year)
        })
        .y0(HEIGHT)
        .y1(function (d) {
          return y(d.count)
        })
        .curve(d3.curveBasis)

      // define the line
      var valueline = d3
        .line()
        .x(function (d) {
          return x(d.year)
        })
        .y(function (d) {
          return y(d.count)
        })
        .curve(d3.curveBasis)
      //                .curve(d3.curveStepBefore);

      var rsvg = this.$_mychart.select('svg')

      // axis and gridlines
      rsvg.select('g.axisBottom').call(d3.axisBottom(x).ticks(10).tickFormat(d3.format('')))

      rsvg.select('g.axisLeft').call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')))

      var gridlines = d3.axisLeft().tickFormat('').ticks(5).tickSize(-WIDTH).scale(y)

      svg.select('g.gridx').call(gridlines)

      svg.selectAll('.gridx .tick line').attr('stroke', this.colour('grey-6'))

      svg.select('.gridx .domain').remove()

      // area and line
      svg.select('path.areax').data([this.v]).transition().duration(1000).attr('d', area)

      svg.select('path.linex').data([this.v]).transition().duration(1000).attr('d', valueline)
    },
  },
}
</script>
