<template>
  <div>
    <slot name="content" v-if="!stubbed"></slot>
    <slot name="stub" v-if="stubbed"></slot>
  </div>
</template>

<style scoped lang="scss"></style>

<script>
export default {
  name: 'Stubbable',
  props: {
    stubbed: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
