<template>
  <FilterableList
    :filterConfigs="filterConfigs"
    :filtered="filtered"
    :items="items"
    :loading="loading"
    :itemName="itemName"
    :itemNamePlural="itemNamePlural"
    :paginationInfo="paginationInfo"
    @get-items="getItems"
    @page-changed="pageChanged"
    @reset-filters="resetFilters">
    <template v-slot:no-items> There are no {{ itemNamePlural }} to show here. </template>

    <template v-slot:item="data">
      <b-col col lg="2" class="metadata">
        <span class="date">{{ getFormattedDate(data.item) }}</span>
        <span class="time">{{ getFormattedTime(data.item) }}</span>
        <span class="visibility"
          ><font-awesome-icon
            :icon="{
              prefix: 'fas',
              iconName: data.item.priv ? 'lock' : 'lock-open',
            }"
            class="icon"
        /></span>
      </b-col>

      <b-col cols="12" sm="auto" class="summary">
        <h2 class="annotated-item-title">
          <b-link
            v-if="deliverySystemLink(data.item)"
            :href="getLinkForNote(data.item)"
            v-html="data.item.itemTitle"></b-link>
          <router-link v-else :to="getLinkForNote(data.item)">{{
            data.item.itemTitle || 'Title'
          }}</router-link>
        </h2>
        <span class="content" v-html="truncate(data.item.content)"></span>
      </b-col>

      <b-col col lg="2" class="creator">
        <UserThumbnail
          :user-summary="data.item.user"
          size="small"
          :lazy="true"
          :link-username="false"></UserThumbnail>
      </b-col>
    </template>
  </FilterableList>
</template>

<script>
import _ from 'lodash'
import service from '@/service/service'
import UserAnnotations from './UserAnnotations'
import UserThumbnail from './UserThumbnail'

const FILTER_DEFAULT = {
  annotatedMaterialType: null,
  timePeriod: null,
  visibility: 'BOTH',
  content: undefined,
  number: 1,
  userSortOrder: null,
}

export default {
  name: 'ModerateContentTab',
  extends: UserAnnotations,
  components: { UserThumbnail },
  props: {
    itemName: {
      type: String,
      required: true,
    },
    itemNamePlural: {
      type: String,
      required: true,
    },
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filterConfigs() {
      return [
        {
          name: 'containing',
          display: 'Containing',
          type: 'text',
          value: () => this.filter.content,
          input: (v) => (this.filter.content = v),
        },
        {
          name: 'created',
          display: 'Created',
          type: 'select',
          value: () => this.filter.timePeriod,
          input: (v) => (this.filter.timePeriod = v),
          options: this.timeIntervalOptions,
        },
        {
          name: 'addedTo',
          display: 'Added to',
          type: 'select',
          value: () => this.filter.annotatedMaterialType,
          input: (v) => (this.filter.annotatedMaterialType = v),
          options: this.annotatedMaterialTypeOptions,
        },
        {
          name: 'visibility',
          display: 'Visibility',
          type: 'select',
          predicate: () => this.isMyProfile,
          value: () => this.filter.visibility,
          input: (v) => (this.filter.visibility = v),
          options: this.visibilityOptions,
        },
        {
          name: 'sort',
          display: 'Sort',
          type: 'select',
          value: () => this.filter.userSortOrder,
          input: (v) => (this.filter.userSortOrder = v),
          options: [
            {
              value: null,
              text: '',
            },
            {
              value: 'ASC',
              text: 'User Ascending',
            },
            {
              value: 'DESC',
              text: 'User Descending',
            },
          ],
        },
      ]
    },
    filterDefault() {
      return FILTER_DEFAULT
    },
  },
  methods: {
    getFromService(userId, filter) {
      let visibility = {
        includePrivate: filter.visibility !== 'PUBLIC',
        includePublic: filter.visibility !== 'PRIVATE',
      }

      if (this.itemName === 'note') {
        return service.annotations.getAllAnnotations(_.assign({}, filter, visibility))
      } else if (this.itemName === 'tag') {
        return service.tags.getAllTags(_.assign({}, filter, visibility))
      }
    },
    deliverySystemLink(annotation) {
      return annotation.targetType === 'a'
    },
  },
}
</script>
