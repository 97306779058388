import _ from 'lodash'
import history from '@/mixins/history'

const TAG_DELIMITER = ','
const TAG_QUOTE = '"'

export default {
  mixins: [history],
  computed: {
    tagDelimiter() {
      return TAG_DELIMITER
    },
    tagQuote() {
      return TAG_QUOTE
    },
  },
  methods: {
    splitTags(content, priv) {
      return _(this.quotedSplit(content, this.tagDelimiter, this.tagQuote))
        .filter((tagContent) => !_.isEmpty(tagContent))
        .map((tagContent) => {
          return {
            content: tagContent,
            priv: priv,
          }
        })
        .value()
    },
    mapTags(tags, workType) {
      return _.map(tags, (tag) => this.mapTag(tag, workType, this.latestSearchRoute))
    },
    mapTag(tag, workType, searchRoute) {
      var query = {}
      query['l-' + (tag.mine ? 'user' : 'public') + 'tag'] =
        '"' + (tag.content || '').replace(/["']/g, '') + '"'

      var mapped = {
        id: tag.id,
        name: tag.content,
        user: tag.user,
        editable: tag.editable,
        mine: tag.mine,
        priv: tag.priv,
        to: {
          name: 'SearchAll',
          query: query,
        },
      }

      if (searchRoute) {
        this.augmentTagLink(mapped, searchRoute)
      }

      if (workType && workType === 'person') {
        mapped.to.params = {
          urlNamespace: 'category',
          urlKey: 'people',
        }
      }

      return mapped
    },
    augmentTagLink(tag, searchRoute) {
      var searchRouteParamsAugmentation = {
        CollectionSearch: (params) => {
          params.view = 'search'
        },
      }

      if (!tag.to) {
        tag.to = {}
      }

      tag.to.name = searchRoute.name
      tag.to.params = {
        urlNamespace: searchRoute.params.urlNamespace,
        urlKey: searchRoute.params.urlKey,
      }

      if (searchRouteParamsAugmentation[searchRoute.name]) {
        searchRouteParamsAugmentation[searchRoute.name](tag.to.params)
      }
    },
  },
}
