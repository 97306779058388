<template>
  <div>
    <div v-if="loading">
      <div class="loading-spinner spinner-md"></div>
    </div>
    <div v-else-if="hasContributors">
      <div class="contributors">
        <h2 class="bevel blue">Acknowledgements</h2>
        <ul>
          <li v-for="c in contributors" :key="c.contributorName">
            {{ c.contributorPrefix }}
            <span v-if="c.contributorLink">
              <b-link :href="c.contributorLink">
                {{ c.contributorName }}
              </b-link>
            </span>
            <span v-else>
              {{ c.contributorName }}
            </span>
            <span v-if="c.contributorAdditionalText" class="additional-text">
              {{ c.contributorAdditionalText }}
            </span>
          </li>
        </ul>
        <div class="small-bevel blue"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.contributors {
  margin-bottom: 4rem;

  h2 {
    font-family: 'Source Serif Pro', serif;
    font-weight: 600;
    font-size: 2.5em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }

  @supports (font-variation-settings: normal) {
    h2 {
      font-family: 'Source Serif Variable', serif;
    }
  }

  h2.bevel {
    font-size: 1.375rem !important;
    font-family: inherit;
    font-weight: bold;
    padding: 0;

    &:before {
      top: 0.2em !important;
    }
  }

  ul {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
  }

  li {
    margin-bottom: 0.5rem;
  }

  .additional-text {
    font-size: 0.875rem;
    color: colour(grey-3);
    display: block;
  }
}
</style>

<script>
import service from '@/service/service'
import currentRoute from '@/mixins/currentRoute'
import _ from 'lodash'

export default {
  name: 'CollectionContributors',
  mixins: [currentRoute],
  data() {
    return {
      loading: true,
      contributors: [],
    }
  },
  computed: {
    hasContributors() {
      return !_.isEmpty(this.contributors)
    },
  },
  created() {
    const self = this
    service.collection
      .getContributors(self.urlNamespace, self.urlKey)
      .then((contributors) => {
        self.contributors = contributors
        self.loading = false
      })
      .catch((error) => {
        self.loading = false
        self.handleError(error, 'contributors', undefined, 'retrieving contributors')
      })
  },
}
</script>
