<template>
  <b-container class="root-container" id="workContainer">
    <b-row class="section-container">
      <b-col>
        <BasicSearch
          :collections="collections"
          :display-collections="true"
          @collection-changed="selectedCollectionUpdated"
          @search="search" />
      </b-col>
    </b-row>

    <Stubbable :stubbed="stubbed">
      <template slot="content">
        <div class="error" v-if="error && error.summary">
          <Alert :alert="error"></Alert>
        </div>

        <template v-if="!error || !error.summary || error.showContent">
          <b-row ref="content">
            <b-col sm="12">
              <div class="d-flex section-container">
                <ListSummary :list="list" :class="'header-section'" :show-collaborator-count="true">
                  <div slot="float-right">
                    <div>
                      <router-link :to="manageListRoute" v-if="canManage" class="manage-list"
                        ><font-awesome-icon :icon="{ prefix: 'far', iconName: 'cog' }" />Manage this
                        list</router-link
                      >
                    </div>
                    <div v-if="canFollow" class="follow-controls">
                      <b-button
                        v-if="list.currentUserAFollower && !followRequestInProgress"
                        variant="success"
                        size="sm"
                        @click.prevent="stopFollowing">
                        Unfollow
                        <span class="follower-count" v-if="list.followerCount > 0">{{
                          list.followerCount
                        }}</span>
                      </b-button>
                      <b-button
                        v-if="!list.currentUserAFollower && !followRequestInProgress"
                        variant="success"
                        size="sm"
                        @click.prevent="follow">
                        Follow
                        <span class="follower-count" v-if="list.followerCount > 0">{{
                          list.followerCount
                        }}</span>
                      </b-button>
                      <div v-if="followRequestInProgress" class="loading-spinner spinner-lg"></div>
                    </div>
                    <div v-if="canManage && list.followerCount > 0" class="follow-controls">
                      <span class="btn btn-success btn-sm"
                        >Followers
                        <span class="follower-count">{{ list.followerCount }}</span></span
                      >
                    </div>
                  </div>

                  <div slot="extra-content">
                    <CollaborationOffer :list="list"></CollaborationOffer>
                  </div>
                </ListSummary>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="9">
              <div class="d-flex flex-column content-container">
                <ListItems
                  v-if="showListItems"
                  :list="list"
                  :list-size="listSize"
                  :list-author="list.author"
                  @item-added="updateList()"
                  @page-changed="scrollToTop('content')" />
              </div>
            </b-col>
            <b-col sm="12" md="3">
              <Tags :work="list" />

              <AnnotationList
                type="list"
                :target-id="list.id"
                heading="List notes"></AnnotationList>
            </b-col>
          </b-row>
        </template>
      </template>
      <template slot="stub">
        <div class="stub">
          <div class="list-stub">
            <div class="button pill title"></div>
            <div class="button pill summary-title"></div>
            <div class="button pill summary"></div>
          </div>
        </div>
      </template>
    </Stubbable>
  </b-container>
</template>

<script>
import _ from 'lodash'
import scroll from 'vue-scrollto'
import service from '@/service/service'
import auth from '@/mixins/auth'
import lists from '@/mixins/lists'
import Stubbable from '@/components/Stubbable.vue'
import BasicSearch from '@/components/search/BasicSearch'
import Tags from '@/components/work/Tags.vue'
import ListItems from '@/components/lists/ListItems.vue'
import ListSummary from '@/components/lists/ListSummary.vue'
import CollaborationOffer from '@/components/lists/CollaborationOffer.vue'
import AnnotationList from '@/components/annotations/AnnotationList.vue'
import Alert from '@/components/Alert.vue'

export default {
  name: 'ListView',
  mixins: [auth, lists],
  components: {
    ListSummary,
    CollaborationOffer,
    BasicSearch,
    Stubbable,
    Tags,
    Alert,
    ListItems,
    AnnotationList,
  },
  metaInfo() {
    if (!_.isEmpty(this.error) && _.isEmpty(this.list)) {
      return { title: 'List not found' }
    } else {
      return { title: this.list.title }
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      list: {},
      collections: [],
      selectedCollection: null,
      followRequestInProgress: false,
      error: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    let listId = to.query.id
    if (!_.isEmpty(listId)) {
      next({
        replace: true,
        // TODO: Not this magic string.  What if the path is changed in the router?  It'll break.
        path: '/list/' + listId,
      })
    }

    next()
  },
  created() {
    let self = this

    service.landing.getCategories().then((collections) => (self.collections = collections))
    self.updateList()
  },
  computed: {
    stubbed() {
      return _.isEmpty(this.list) && _.isEmpty(this.error)
    },
    listSize() {
      return this.list.numItems
    },
    showListItems() {
      return this.list.numItems > 0
    },
    canFollow() {
      return (
        this.authenticated &&
        !this.list.currentUserAContributor &&
        !this.isListOwner(this.list.author)
      )
    },
    canManage() {
      return (
        this.authenticated &&
        (this.list.currentUserAContributor || this.isListOwner(this.list.author))
      )
    },
    manageListRoute() {
      return {
        name: 'ManageList',
        params: { id: this.list.id },
      }
    },
  },
  methods: {
    updateList() {
      let self = this
      self.error = {}
      self.scrollToTop()

      service.list
        .get(this.id)
        .then((list) => {
          self.list = list
          self.$emit('update-breadcrumbs', [{ value: list.title }], true)
        })
        .catch((error) => {
          self.$emit('update-breadcrumbs', [{ value: 'List not found' }], true)
          self.handleError(error, 'list', self.id, 'load the list')
        })
    },
    selectedCollectionUpdated(selectedCollection) {
      this.selectedCollection = selectedCollection
    },
    search(query) {
      var self = this

      if (!query || !query.keyword || _.isEmpty(_.trim(query.keyword))) {
        return
      }

      this.$router.push({
        name: self.selectedCollection ? 'Search' : 'SearchAll',
        params: self.selectedCollection
          ? {
              urlNamespace: self.selectedCollection.urlNamespace,
              urlKey: self.selectedCollection.urlKey,
            }
          : {},
        query: query,
      })
    },
    follow() {
      let self = this
      self.followRequestInProgress = true
      service.list
        .followList(self.list.id)
        .then(() => {
          self.list.currentUserAFollower = true
          self.list.followerCount++
          self.followRequestInProgress = false
        })
        .catch((error) => {
          self.handleError(error, 'list', self.list.id, 'follow this list')
          self.followRequestInProgress = false
        })
    },
    stopFollowing() {
      let self = this
      self.followRequestInProgress = true
      service.list
        .stopFollowingList(self.list.id)
        .then(() => {
          self.list.currentUserAFollower = false
          self.list.followerCount--
          self.followRequestInProgress = false
        })
        .catch((error) => {
          self.handleError(error, 'list', self.list.id, 'stop following this list')
          self.followRequestInProgress = false
        })
    },
    scrollToTop(ref) {
      this.$nextTick(() => {
        ref === 'content'
          ? scroll.scrollTo(this.$refs['content'], 500, { easing: 'ease-out' })
          : scroll.scrollTo('body', 500, { easing: 'ease-out' })
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../scss/list-view.scss';

.header-section {
  width: 100%;

  .follow-controls {
    .follower-count {
      display: inline-block;
      border-radius: 10px;
      background-color: white;
      margin-left: 0.5rem;
      color: colour(black);
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-weight: 500;
    }
  }
}
</style>
