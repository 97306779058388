import axios from 'axios'
import _ from 'lodash'
import store from '@/store/store'
import memoize from 'memoizee'
import qs from 'qs'
import md5 from 'md5'
import Cookies from 'js-cookie'

// this must match the key used in the API
const TROVE_DISCOVERY_SYSTEM_NAME = 'discovery'

const THIRTY_MINUTES = 30 * 60 * 1000
const TEN_MINUTES = 10 * 60 * 1000
const FIVE_MINUTES = 5 * 60 * 1000

const BASE_API_URL = '/api'
const CRITICAL_API_URLS = ['/api/configuration/environment', '/api/configuration/application']

const http = axios.create({
  baseURL: BASE_API_URL,
})

let apiKey = ''

let getCollectionSet = function (set) {
  return http.get('/collection/?set=' + set).then((response) => {
    return response.data
  })
}

let getLandingByKey = function (urlKey) {
  if (urlKey === 'home') {
    return http.get('/landing/home').then((response) => {
      return response.data
    })
  }

  return http.get('/landing/urlKey/' + urlKey).then((response) => {
    return response.data
  })
}

let getGlobalConfig = memoize(
  function () {
    return http.get('/configuration/application').then((response) => {
      return response.data
    })
  },
  { maxAge: FIVE_MINUTES }
)

let getEnvConfig = memoize(
  function () {
    return http.get('/configuration/environment').then((response) => {
      return response.data
    })
  },
  { maxAge: THIRTY_MINUTES }
)

let getUserProfile = memoize(
  function (userId) {
    return http.get('/profile/' + userId).then((response) => {
      return response.data
    })
  },
  { maxAge: FIVE_MINUTES }
)

let getMyUserProfile = memoize(
  function (userId) {
    return http.get('/profile/' + userId).then((response) => {
      store.dispatch('trove/updateUserProfile', response.data, { root: true })

      return response.data
    })
  },
  { maxAge: FIVE_MINUTES }
)

let getBookshopsForWork = memoize(
  function (workId, versionId, bookshopId) {
    return http
      .get('/work/' + workId + '/version/' + versionId + '/bookshops/' + bookshopId)
      .then((response) => {
        return response.data
      })
  },
  { maxAge: TEN_MINUTES }
)

let getAlerts = memoize(
  function () {
    return http
      .get(
        '/configuration/alerts?deliverySystem=' + encodeURIComponent(TROVE_DISCOVERY_SYSTEM_NAME)
      )
      .then((response) => {
        return response.data
      })
  },
  { maxAge: FIVE_MINUTES }
)

let getSupportingArticle = function (articleId) {
  return http.get('/supporting/' + articleId + '/configuration').then((response) => {
    return response.data
  })
}

let getSearchMetadata = memoize(function (collectionId) {
  return http.get('search/' + collectionId + '/metadata').then((response) => {
    return response.data
  })
}, THIRTY_MINUTES)

let getPeopleRouteForName = memoize(
  function (name) {
    return http
      .get('/people/' + encodeURIComponent(name))
      .then((response) => {
        return {
          name: 'People',
          params: {
            id: response.data.id,
          },
        }
      })
      .catch(function () {
        // do nothing
      })
  },
  { maxAge: TEN_MINUTES }
)

let getWorkCitation = memoize(
  function (workId, versionId) {
    return http.get('/citation/work/' + workId + '?version=' + versionId).then((response) => {
      return response.data
    })
  },
  { maxAge: TEN_MINUTES }
)

let getWorkAnnotations = memoize(
  function (workId, versionId) {
    return http
      .get(
        '/annotation/' +
          (workId ? 'work/' + workId : '') +
          (versionId ? 'version/' + versionId : '')
      )
      .then((response) => {
        return response.data
      })
  },
  { maxAge: TEN_MINUTES }
)

let getPersonAnnotations = memoize(
  function (personOrOrgId) {
    return http.get('/annotation/person/' + personOrOrgId).then((response) => {
      return response.data
    })
  },
  { maxAge: TEN_MINUTES }
)

let getListAnnotations = memoize(
  function (listId) {
    return http.get('/annotation/list/' + listId).then((response) => {
      return response.data
    })
  },
  { maxAge: TEN_MINUTES }
)

let getListsContainingWork = memoize(
  function (workId) {
    return http.get('/list/containing/work/' + workId).then((response) => {
      return response.data
    })
  },
  { maxAge: TEN_MINUTES }
)

let getListsContainingPersonOrOrg = memoize(
  function (personOrOrgId) {
    return http.get('/list/containing/personOrOrg/' + personOrOrgId).then((response) => {
      return response.data
    })
  },
  { maxAge: TEN_MINUTES }
)

let getPersonCitation = memoize(
  function (personOrgId) {
    return http.get('/citation/person/' + personOrgId).then((response) => {
      return response.data
    })
  },
  { maxAge: TEN_MINUTES }
)

let checkCaptchaStatusForSession = function (captchaUrl) {
  return axios.get(captchaUrl, { withCredentials: true }).then((response) => {
    return response.data
  })
}

let submitCaptcha = function (captchaUrl, authResponse) {
  return axios
    .get(captchaUrl + '?recaptchaResponse=' + authResponse, {
      withCredentials: true,
    })
    .then((response) => {
      return response
    })
}

export default {
  source: () => {
    return axios.CancelToken.source()
  },
  vendor: {
    generateVendorUrl(library, vendor) {
      return BASE_API_URL + '/vendor/' + library.nuc + '/' + vendor.itemId + '/' + vendor.dbCode
    },
  },
  _gtm: {
    // Record a UI interaction event for google.  Interaction object should contain category and action, and
    // optionally a label and value - value being numeric (integer)
    interaction(vue, interaction) {
      var opts = _.pick(interaction, [
        'category',
        'action',
        'label',
        'value',
        'format',
        'year',
        'title',
      ])
      vue.$gtm.trackEvent(
        _.assign(
          {
            event: 'vue-interaction',
            noninteraction: true,
          },
          opts
        )
      )
    },
    // Same again, but this is a valuable interaction.  Context is a custom general purpose field.
    conversion(vue, interaction) {
      var opts = _.pick(interaction, ['category', 'action', 'label', 'value', 'context'])
      vue.$gtm.trackEvent(
        _.assign(
          {
            event: 'vue-conversion',
            noninteraction: false,
          },
          opts
        )
      )
    },
  },
  copyright: {
    checkStatus(bibliographicRecordId) {
      return http
        .get('/copyrightstatus/' + bibliographicRecordId)
        .then((response) => {
          return response.data
        })
        .catch(function () {
          // do nothing
        })
    },
  },
  config: {
    environment: getEnvConfig,
    alerts: getAlerts,
    global: getGlobalConfig,
    getCulturalAdvice() {
      return getGlobalConfig().then((conf) => {
        return conf.culturalAdviceWidget
      })
    },
    baseAPIURL: BASE_API_URL,
  },
  profile: {
    get: getUserProfile,
    getMyProfile: getMyUserProfile,
    info() {
      return http.get('/profile/info').then((response) => {
        return response.data
      })
    },
    logout() {
      return http.get('/profile/logout')
    },
    kcInit() {
      return http.get('/configuration/kcInit').then((response) => {
        return response.data
      })
    },
    getTextCorrectionsForUser(userId, filter) {
      return http
        .get('/profile/user/' + encodeURIComponent(userId) + '/recentCorrections', {
          params: filter,
        })
        .then((response) => {
          return response.data
        })
    },
    getNotificationsForUser(type, start, end, collate) {
      return http
        .get(
          '/profile/user/notifications?type=' +
            type +
            (start ? '&startDay=' + start + '&endDay=' + end : '') +
            '&collate=' +
            collate
        )
        .then((response) => {
          return response.data
        })
    },
    clearAlertNotificationsForUser() {
      return http.post('/profile/user/notifications/clearAllAlertsForUser').then((response) => {
        return response.data
      })
    },
    clearAlertNotificationForUser(notificationId) {
      return http
        .post('/profile/user/notifications/clearAlert/' + notificationId)
        .then((response) => {
          return response.data
        })
    },
    updateEmailAddress(email) {
      return http
        .post('/profile/user/email', email, {
          headers: {
            'Content-Type': 'text/plain',
          },
        })
        .then((response) => {
          return response.data
        })
    },
    updateProfile(profile) {
      return http.post('/profile/user', profile).then((response) => {
        store.dispatch('trove/updateUserProfile', response.data, {
          root: true,
        })
        return response.data
      })
    },
    deleteAvatar() {
      return http.delete('/profile/deleteAvatar').then((response) => {
        return response.data
      })
    },
    deleteProfile(userId) {
      return http.delete('/profile/' + userId).then((response) => {
        return response.data
      })
    },
    applyForAPIKey(details) {
      return http.post('/profile/api', details).then((response) => {
        return response.data
      })
    },
    updateAPIDetails(details) {
      return http.put('/profile/api', details).then((response) => {
        return response.data
      })
    },
    renewOrRegenAPIKey() {
      return http.post('/profile/api/extend').then((response) => {
        return response.data
      })
    },
    deleteAPIKey() {
      return http.delete('/profile/api').then((response) => {
        return response.data
      })
    },
    listSearchDownloads() {
      return http.get('/profile/downloads').then((response) => {
        return response.data
      })
    },
    activeSearchDownloadCount() {
      return http.get('/profile/downloads/active').then((response) => {
        return response.data
      })
    },
    queueSearchDownload(name, description, query, queryData, category, expectedRecordCount) {
      return http.post('/profile/downloads', {name, description, query, queryData, category, expectedRecordCount}).then((response) => {
        return response.data
      })
    },
    deleteSearchDownload(id) {
      return http.delete('/profile/downloads/' + id).then((response) => {
        return response.data
      })
    },
    retrySearchDownload(id) {
      return http.post('/profile/downloads/' + id + '/retry').then((response) => {
        return response.data
      })
    }
  },
  landing: {
    getPages() {
      return getGlobalConfig().then((landingConfig) => {
        return landingConfig.navLandingPages.map(function (data) {
          return {
            id: data.id,
            name: data.name,
            urlKey: data.urlKey,
            pageType: data.landingPageType,
          }
        })
      })
    },
    getPage(urlKey) {
      return getLandingByKey(urlKey)
    },
    getArticle(articleId) {
      return getSupportingArticle(articleId)
    },
    getCategories() {
      return getGlobalConfig().then((config) => {
        return config.categories
      })
    },
  },
  collection: {
    getCollectionSet(set) {
      return getCollectionSet(set)
    },
    getCoreCollections() {
      return getCollectionSet('core')
    },
    getResearchCollections() {
      return getCollectionSet('research')
    },
    getFeatureCollections() {
      return getCollectionSet('feature')
    },
    getConfiguration(namespace, key) {
      return http
        .get('/collection/' + namespace + '/' + key + '/configuration')
        .then((response) => {
          return response.data
        })
    },
    getPage(namespace, key) {
      return http.get('/collection/' + namespace + '/' + key + '/page').then((response) => {
        return response.data
      })
    },
    getContributors(namespace, key) {
      return http.get('/collection/' + namespace + '/' + key + '/contributors').then((response) => {
        return response.data
      })
    },
    getExploreFacetInfo: memoize(
      () => {
        return http.get('/collection/exploreinfo').then((response) => {
          return response.data
        })
      },
      { maxAge: TEN_MINUTES }
    ),
  },
  work: {
    get(workId, includeraw) {
      return http
        .get('/work/' + workId + (includeraw ? '?includeraw=true' : ''))
        .then((response) => {
          return response.data
        })
    },
    getVersionDetails(workId, versionId, includeraw) {
      return http
        .get('/work/' + workId + '/version/' + versionId + (includeraw ? '?includeraw=true' : ''))
        .then((response) => {
          return response.data
        })
    },
    getBorrowOptions: memoize(
      (workId, versionId) => {
        let url = '/work/' + workId
        if (versionId) {
          url += '/version/' + versionId
        }
        url += '/borrowOptions'

        return http.get(url).then((response) => {
          return response.data
        })
      },
      { maxAge: TEN_MINUTES }
    ),
    getBookshops: getBookshopsForWork,
    getAuthorRoute(name) {
      return getPeopleRouteForName(name)
    },
    workIdFromVersionId(versionId) {
      return http.get('/work/version/' + versionId).then((response) => {
        return response.data
      })
    },
    workIdFromNbdId(nbdId) {
      return http.get('/work/nbdid/' + nbdId).then((response) => {
        return response.data
      })
    },
    getCitation: getWorkCitation,
    getAnnotations: getWorkAnnotations,
    mergeWorks(workIds, note) {
      return http.post('/work/merge', { note: note, workIds: workIds }).then((response) => {
        return response.data
      })
    },
    splitVersionFromWork(workId, versionId, note) {
      return http
        .post('/work/split/' + workId, { note: note, versionId: versionId })
        .then((response) => {
          return response.data
        })
    },
  },
  people: {
    get(peopleId, includeraw) {
      return http
        .get('/people/' + peopleId + (includeraw ? '?includeraw=true' : ''))
        .then((response) => {
          return response.data
        })
    },
    getCitation: getPersonCitation,
    getAnnotations: getPersonAnnotations,
  },
  explore: {
    // TODO: Can we roll this in to search queryCollection?
    queryCollection(collectionId, queryString, limits, startPos, pageSize, pageTotals, sortBy) {
      // convert quotes and dashes from cp1252 to standard quotes and dashes
      let convertSmartCharacters = (s) => {
        s = s.replace(/\u2018|\u2019|\u201A|\uFFFD/g, "'")
        s = s.replace(/\u201c|\u201d|\u201e/g, '"')
        s = s.replace(/\u2013/g, '-')
        s = s.replace(/\u2014/g, '-')
        return s
      }

      let params = {
        terms: convertSmartCharacters(queryString),
      }

      if (!_.isEmpty(limits)) {
        params.limits = convertSmartCharacters(JSON.stringify(limits))
      }

      if (startPos) {
        params.startPos = startPos
      }

      if (pageSize) {
        params.pageSize = pageSize
      }

      if (pageTotals) {
        params.pageTotals = pageTotals
      }

      if (sortBy) {
        params.sortBy = sortBy
      }

      return http
        .get('/explore/' + collectionId, {
          params: params,
          paramsSerializer: (params) => {
            return params ? qs.stringify(params) : params
          },
        })
        .then((response) => {
          return response.data
        })
    },
  },
  search: {
    queryCollection(
      collectionId,
      queryString,
      limits,
      startPos,
      pageSize,
      pageTotals,
      sortBy,
      explain
    ) {
      // convert quotes and dashes from cp1252 to standard quotes and dashes
      let convertSmartCharacters = (s) => {
        s = s.replace(/\u2018|\u2019|\u201A|\uFFFD/g, "'")
        s = s.replace(/\u201c|\u201d|\u201e/g, '"')
        s = s.replace(/\u2013/g, '-')
        s = s.replace(/\u2014/g, '-')
        return s
      }

      let params = {
        terms: convertSmartCharacters(queryString),
      }

      if (!_.isEmpty(limits)) {
        // { format: [ "Microform" ] }
        params.limits = convertSmartCharacters(JSON.stringify(limits))
      }

      if (startPos) {
        params.startPos = startPos
      }

      if (pageSize) {
        params.pageSize = pageSize
      }

      if (pageTotals) {
        params.pageTotals = pageTotals
      }

      if (sortBy) {
        params.sortBy = sortBy
      }

      if (explain) {
        params.explain = true
      }

      return http
        .get('/search/' + collectionId, {
          params: params,
          paramsSerializer: (params) => {
            return params ? qs.stringify(params) : params
          },
        })
        .then((response) => {
          return response.data
        })
    },
    getFacetsForCollection(collectionId, facets) {
      if (_.isArray(facets) && !_.isEmpty(facets)) {
        return http
          .get('/search/' + collectionId + '/facets', {
            params: _.reduce(
              facets,
              (params, facet) => {
                params.append('facet', facet)
                return params
              },
              new URLSearchParams()
            ),
          })
          .then((response) => {
            return response.data
          })
      } else {
        return http.get('/search/' + collectionId, { terms: '' }).then((response) => {
          return response.data.facets
        })
      }
    },
    getSearchMetadata: getSearchMetadata,
    lookupUrl(url) {
      if (url) {
        // TROVE-401 - having http:// at the start of a website gives an error when hitting trove-vue-api
        // Length check to cover boundary case where someone inexplicably searches for "http://"
        const httpstart = 'http://'

        if (url.startsWith(httpstart) && url.length > httpstart.length) {
          url = url.substring(httpstart.length)
        }

        const httpsstart = 'https://'

        if (url.startsWith(httpsstart) && url.length > httpsstart.length) {
          url = url.substring(httpsstart.length)
        }

        return http.get('/search/url-lookup?url=' + url).then((response) => {
          return response.data
        })
      }
    },
  },
  auth: {
    apikey() {
      return apiKey
    },
    installTokenInterceptor(Vue) {
      http.interceptors.request.use(
        (config) => {
          if (Vue.prototype.$keycloak.authenticated && _.startsWith(config.url, '/')) {
            config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
          }
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    checkCaptchaStatusForSession: checkCaptchaStatusForSession,
    submitCaptcha: submitCaptcha,
    installApikeyRefresher(interval) {
      if (interval > 1800) {
        // 30m, sanity
        setInterval(() => {
          axios.get('/') // cookie x-ctx will be attached
        }, 1000 * interval)
      }
    },
    installApikeyInterceptor() {
      let xctx = ''

      http.interceptors.request.use(
        (config) => {
          let c = Cookies.get('x-ctx')
          if (c !== xctx) {
            xctx = c
            apiKey = md5('Wonder' + xctx).replace(/^0+/, '') // must match server calculation
          }

          config.headers.apikey = apiKey
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
  },
  errorHandling: {
    installErrorInterceptor() {
      http.interceptors.response.use(
        (response) => {
          return response
        },
        (error) => {
          // critical failures (screen of death)- any status or error condition for matching urls
          if (error.config && error.config.url) {
            var r = CRITICAL_API_URLS.filter((o) => error.config.url.startsWith(o))
            if (r.length > 0) {
              store.dispatch('trove/recordApiCriticalFail', { root: true })
            }
          }

          return Promise.reject(error)
        }
      )
    },
  },
  google: {
    lookupBooks(bookIds, limit) {
      if (!limit) {
        limit = 10
      }
      if (bookIds) {
        let url =
          'https://www.googleapis.com/books/v1/volumes?projection=full&limit=' + limit + '&q='
        url += encodeURIComponent(bookIds)

        return http.get(url).then((response) => {
          return response.data
        })
      }
    },
  },
  dashboard: {
    getReportEmbedDetails(nuc) {
      let url = '/dashboard/embedInfo'
      if (nuc) url += '?nuc=' + nuc
      return http.get(url).then((response) => {
        return response.data
      })
    },
  },
  list: {
    get(listId) {
      return http.get('/list/' + listId).then((response) => {
        return response.data
      })
    },
    getForListManagement(listId) {
      return http.get('/list/' + listId + '/manage').then((response) => {
        return response.data
      })
    },
    getTypeFilter(listId) {
      return this.getListFilters(listId, 'type')
    },
    getLangFilter(listId) {
      return this.getListFilters(listId, 'lang')
    },
    getDateFilter(listId) {
      return this.getListFilters(listId, 'date')
    },
    getAllListItems(listId) {
      return this.getListFilters(listId, 'all')
    },
    getListFilters(listId, filterType) {
      return http.get('/list/' + listId + '/filters?type=' + filterType).then((response) => {
        return response.data
      })
    },
    getListItemById(listId, itemId, cancelToken) {
      const request = {
        cancelToken: cancelToken,
      }

      return http.get('/list/' + listId + '/items/' + itemId, request).then((response) => {
        return response.data
      })
    },
    getAnnotations: getListAnnotations,
    getListsContainingWork: getListsContainingWork,
    getListsContainingPersonOrOrg: getListsContainingPersonOrOrg,
    getListSummariesForUser(userId) {
      return http.get('/list/summary/user/' + userId).then((response) => {
        return response.data
      })
    },
    getListsForUser(userId, filter) {
      return http
        .get('/list/user/' + encodeURIComponent(userId), { params: filter })
        .then((response) => {
          return response.data
        })
    },
    moveListItem(listId, listItemId, newSeq) {
      return http
        .put('/list/' + listId + '/move/' + listItemId, { newSeq: newSeq })
        .then((response) => {
          return response.data
        })
    },
    removeItemFromListByTargetId(listId, type, targetId) {
      return http
        .delete('/list/' + listId + '/remove/' + type + '/' + targetId)
        .then((response) => {
          return response.data
        })
    },
    removeItemFromList(listId, listItemId) {
      return http.delete('/list/' + listId + '/remove/' + listItemId).then((response) => {
        return response.data
      })
    },
    updateListItemNote(listId, listItemId, listItem) {
      return http.put('/list/' + listId + '/items/' + listItemId, listItem).then((response) => {
        return response.data
      })
    },
    addUrlItemToList(listId, target, reason) {
      var request = {
        reason: reason,
        target: target,
      }

      return http.post('/list/' + listId + '/addUrlItem', request).then((response) => {
        return response.data
      })
    },
    addItemsToLists(targets, lists, reason) {
      var request = {
        reason: reason,
        items: lists,
        targets: targets,
      }

      return http.post('/list/addItems', request).then((response) => {
        return response.data
      })
    },
    updateList(listId, request) {
      return http.post('/list/' + listId, request).then((response) => {
        return response.data
      })
    },
    getAvailableThumbnails(listId, start, size) {
      return http
        .get('/list/' + listId + '/availableThumbnails?start=' + start + '&size=' + size)
        .then((response) => {
          return response.data
        })
    },
    updateListThumbnail(listId, thumbnailUrl) {
      return http
        .post('/list/' + listId + '/thumbnail', { thumbnailUrl: thumbnailUrl })
        .then((response) => {
          return response.data
        })
    },
    deleteList(id) {
      return http.delete('/list/' + id).then((response) => {
        return response.data
      })
    },
    transferListOwnership(listId, newOwnerId) {
      return http.post('/list/' + listId + '/transfer/' + newOwnerId).then((response) => {
        return response.data
      })
    },
    requestCollaborationAccess(userId, listId) {
      return http
        .post('/list/collaborationRequest', { userId: userId, listId: listId })
        .then((response) => {
          return response.data
        })
    },
    getCollaborationRequests(listId) {
      return http.get('/list/' + listId + '/collaborationRequests').then((response) => {
        return response.data
      })
    },
    acceptCollaborationRequest(requestId) {
      return http.post('/list/collaborationRequest/' + requestId + '/accept').then((response) => {
        return response.data
      })
    },
    declineCollaborationRequest(requestId, block) {
      return http
        .post('/list/collaborationRequest/' + requestId + '/decline?blockUser=' + block)
        .then((response) => {
          return response.data
        })
    },
    updateCollaboratorRole(listId, collaboratorUserId, newRole) {
      return http
        .post(
          '/list/' + listId + '/collaborator/' + collaboratorUserId + '/role?newRole=' + newRole
        )
        .then((response) => {
          return response.data
        })
    },
    removeCollaborator(listId, collaboratorUserId, removeContributions, reason) {
      let url =
        '/list/' +
        listId +
        '/collaborator/' +
        collaboratorUserId +
        '?removeContributions=' +
        removeContributions
      if (reason) url += '&reason=' + reason
      return http.delete(url).then((response) => {
        return response.data
      })
    },
    unblockUserFromList(listId, userId) {
      return http
        .delete('/list/collaborationRequest/' + listId + '/unblock/' + encodeURIComponent(userId))
        .then((response) => {
          return response.data
        })
    },
    followList(listId) {
      return http.post('/list/' + listId + '/follow').then((response) => {
        return response.data
      })
    },
    stopFollowingList(listId) {
      return http.post('/list/' + listId + '/stopFollowing').then((response) => {
        return response.data
      })
    },
    trendingLists() {
      return http.get('/list/trendingLists').then((response) => {
        return response.data
      })
    },
  },
  annotations: {
    addAnnotation(type, annotation) {
      return http.post('/annotation/' + type, annotation).then((response) => {
        return response.data
      })
    },
    deleteAnnotation(id) {
      return http.delete('/annotation/' + id).then((response) => {
        return response.data
      })
    },
    updateAnnotation(type, annotation) {
      return http.post('/annotation/' + type, annotation).then((response) => {
        return response.data
      })
    },
    getAnnotationsForUser(userId, filter) {
      return http
        .get('/annotation/user/' + encodeURIComponent(userId), {
          params: filter,
        })
        .then((response) => {
          return response.data
        })
    },
    getAllAnnotations(filter) {
      return http.get('/annotation/', { params: filter }).then((response) => {
        return response.data
      })
    },
  },
  tags: {
    addTags(targets, tags) {
      var request = {
        items: tags,
        targets: targets,
      }

      return http.post('/tag', request).then((response) => {
        return response.data
      })
    },
    deleteTags(targets, tags) {
      var request = {
        items: tags,
        targets: targets,
      }

      return http.post('/tag/bulkDelete', request).then((response) => {
        return response.data
      })
    },
    deleteTag(id) {
      return http.delete('/tag/' + id).then((response) => {
        return response.data
      })
    },
    getTagsForUser(userId, filter) {
      return http
        .get('/tag/user/' + encodeURIComponent(userId), { params: filter })
        .then((response) => {
          return response.data
        })
    },
    getAllTags(filter) {
      return http.get('/tag', { params: filter }).then((response) => {
        return response.data
      })
    },
  },
  hallOfFame: {
    get(dateChoice, username, filter) {
      return http
        .get('/hallOfFame/correctionStats', {
          params: { dateChoice: dateChoice, username: username, filter: filter },
        })
        .then((response) => {
          return response.data
        })
    },
    getStatsPeriods(filter) {
      return http
        .get('/hallOfFame/statsPeriods', { params: { filter: filter } })
        .then((response) => {
          return response.data
        })
    },
    getTotalCorrections(dateChoice, username, filter) {
      return http
        .get('/hallOfFame/totalCorrections', {
          params: { dateChoice: dateChoice, username: username, filter: filter },
        })
        .then((response) => {
          return response.data
        })
    },
    getTotalCorrectors(dateChoice, filter) {
      return http
        .get('/hallOfFame/totalCorrectors', { params: { dateChoice: dateChoice, filter: filter } })
        .then((response) => {
          return response.data
        })
    },
  },
}
